// Librairies
import { faCalculator, faEye, faHeartbeat, faListAlt, faMagnifyingGlass, faMapMarkedAlt, faPencilRuler, faPlus, faTachometerAlt, faTag, faTh, faTools, faTree, faTrees } from "@fortawesome/pro-solid-svg-icons";
import i18n from "../locales/i18n";

export default class FieldsUtil {
    static getDefaultCategories() {
        return [
            { id: 'site', label: i18n.t("Emplacement"), color: 'var(--yellow-100)', icon: faMapMarkedAlt }, { id: 'composition', label: i18n.t("Composition"), color: 'var(--orange-100)', icon: faTh },
            { id: 'description', label: i18n.t("Description"), color: 'var(--orange-100)', icon: faListAlt }, { id: 'header', label: i18n.t("En-tête"), color: 'var(--primary-100)', icon: faTag },
            { id: 'dimensions', label: i18n.t("Dimensions"), color: 'var(--purple-100)', icon: faPencilRuler }, { id: 'environment', label: i18n.t("Environnement"), color: 'var(--green-100)', icon: faTree },
            { id: 'state', label: i18n.t("État"), color: 'var(--red-100)', icon: faHeartbeat }, { id: 'indicators', label: i18n.t("Indicateurs"), color: 'var(--blue-100)', icon: faTachometerAlt },
            { id: 'maintenance', label: i18n.t("Entretien"), color: '#C83030', icon: faTools }, { id: 'coefficients', label: i18n.t("Coefficients"), color: 'var(--pink-100)', icon: faCalculator },
            { id: 'observation', label: i18n.t("Observation"), color: 'var(--grey-100)', icon: faEye }, { id: 'woodedMassif', label: i18n.t("Massif arboré"), color: 'var(--primary-100)', icon: faTrees },
            { id: 'vta', label: i18n.t("VTA"), color: 'var(--brown-100)', icon: faMagnifyingGlass }, { id: 'other', label: i18n.t("Autres"), color: 'var(--grey-100)', icon: faPlus }
        ];
    }

    static getCustomFieldsTypes() {
        return [
            { id: 'boolean', label: i18n.t("Booléen") },
            { id: 'text', label: i18n.t("Texte") },
            { id: 'number', label: i18n.t("Nombre") },
            { id: 'formula', label: i18n.t("Formule") },
            { id: 'list', label: i18n.t("Liste déroulante") },
            { id: 'date', label: i18n.t("Date") },
            { id: 'url', label: i18n.t("URL") }
        ];
    }

    static getTreeFields(amenityFormulaType, projectCustomFields = [], customFields = [], defaultFieldCategories = [], isImport = false) {
        const treeOrgans = [
            { organ: 'root', label: i18n.t("racines") },
            { organ: 'collar', label: i18n.t("collet") },
            { organ: 'trunk', label: i18n.t("tronc") },
            { organ: 'branch', label: i18n.t("branches") },
            { organ: 'leaf', label: i18n.t("feuilles") }
        ];

        const fields = [
            { id: 'vernacularName', label: i18n.t("Nom vernaculaire"), fieldCategoryId: i18n.t("Description"), isGralityField: true },
            { id: 'gender', label: i18n.t("Genre"), fieldCategoryId: i18n.t("Description"), isGralityField: true },
            { id: 'species', label: i18n.t("Espèce"), fieldCategoryId: i18n.t("Description"), isGralityField: true },
            { id: 'cultivar', label: i18n.t("Cultivar"), fieldCategoryId: i18n.t("Description"), isGralityField: true },
            { id: 'creationInfos', label: i18n.t("Informations de création"), fieldCategoryId: i18n.t("En-tête"), isGralityField: true },
            { id: 'modificationInfos', label: i18n.t("Informations de modification"), fieldCategoryId: i18n.t("En-tête"), isGralityField: true },
            { id: 'isEmpty', label: i18n.t("Vide"), fieldCategoryId: i18n.t("En-tête"), isGralityField: true },
            { id: 'isDead', label: i18n.t("Mort"), fieldCategoryId: i18n.t("En-tête"), isGralityField: true },
            { id: 'isStump', label: i18n.t("Souche"), fieldCategoryId: i18n.t("En-tête"), isGralityField: true },
            { id: 'isIndexed', label: i18n.t("Classé"), fieldCategoryId: i18n.t("En-tête"), isGralityField: true },
            { id: 'isRemarkable', label: i18n.t("Remarquable"), fieldCategoryId: i18n.t("En-tête"), isGralityField: true },
            { id: 'place', label: i18n.t("Lieu"), fieldCategoryId: i18n.t("Emplacement"), isGralityField: true },
            { id: 'projectReference', label: i18n.t("Référence"), fieldCategoryId: i18n.t("En-tête"), isGralityField: true },
            { id: 'customReference', label: i18n.t("Référence personnalisée"), fieldCategoryId: i18n.t("En-tête"), isGralityField: true },
            { id: 'observation', label: i18n.t("Observation"), fieldCategoryId: i18n.t("Observation"), isGralityField: true },
            { id: 'tags', label: i18n.t("Tags"), fieldCategoryId: i18n.t("En-tête"), isGralityField: true },
            { id: 'plantationType', label: i18n.t("Type de plantation"), fieldCategoryId: i18n.t("Environnement"), isGralityField: true },
            { id: 'coverType', label: i18n.t("Type de couverture au sol"), fieldCategoryId: i18n.t("Environnement"), isGralityField: true },
            { id: 'interactions', label: i18n.t("Interactions"), fieldCategoryId: i18n.t("Environnement"), isGralityField: true },
            { id: 'microHabitats', label: i18n.t("Dendro-microhabitats"), fieldCategoryId: i18n.t("Environnement"), isGralityField: true },
            ...treeOrgans.flatMap(({ organ, label }) => [
                { id: `${organ}Symptoms`, label: `${i18n.t("Symptômes")} ${label}`, fieldCategoryId: `${i18n.t("VTA")} ${label}`, isGralityField: true },
                { id: `${organ}Pathogens`, label: `${i18n.t("Pathogènes")} ${label}`, fieldCategoryId: `${i18n.t("VTA")} ${label}`, isGralityField: true },
                { id: `${organ}Pests`, label: `${i18n.t("Ravageurs")} ${label}`, fieldCategoryId: `${i18n.t("VTA")} ${label}`, isGralityField: true },
                ['trunk', 'branch'].includes(organ) && { id: `${organ}Epiphytes`, label: `${i18n.t("Épiphytes")} ${label}`, fieldCategoryId: `${i18n.t("VTA")} ${label}`, isGralityField: true },
            ]),
            { id: 'numberOfTrunks', label: i18n.t("Nombre d'axes"), fieldCategoryId: i18n.t("Dimensions"), isGralityField: true },
            { id: 'trunkHeight', label: i18n.t("Hauteur du tronc"), fieldCategoryId: i18n.t("Dimensions"), isGralityField: true },
            { id: 'trunks', label: i18n.t("Axes"), fieldCategoryId: i18n.t("Dimensions"), isGralityField: true },
            ...(isImport ?
                [{ id: 'height', label: i18n.t("Hauteur totale axe (m)"), fieldCategoryId: i18n.t("Dimensions"), isGralityField: true },
                { id: 'circumference', label: i18n.t("Circonférence axe (cm)"), fieldCategoryId: i18n.t("Dimensions"), isGralityField: true },
                { id: 'crownDiameter', label: i18n.t("Diamètre couronne axe (m)"), fieldCategoryId: i18n.t("Dimensions"), isGralityField: true },
                { id: 'trunks', label: i18n.t("Autres axes"), fieldCategoryId: i18n.t("Dimensions"), isGralityField: true }]
                : []),
            ...[{ id: 'situationCoefficient', label: i18n.t("Coefficient de situation"), fieldCategoryId: i18n.t("Coefficients"), isGralityField: true },
            { id: 'plantationCoefficient', label: i18n.t("Coefficient de plantation"), fieldCategoryId: i18n.t("Coefficients"), isGralityField: true },
            { id: 'ontogenicStage', label: i18n.t("Stade ontogénique"), fieldCategoryId: i18n.t("Description"), isGralityField: true },
            { id: 'plantingDate', label: i18n.t("Date de plantation"), fieldCategoryId: i18n.t("Description"), isGralityField: true },
            { id: 'age', label: i18n.t("Âge"), fieldCategoryId: i18n.t("Description"), isGralityField: true },
            { id: 'vigor', label: i18n.t("Vigueur"), fieldCategoryId: i18n.t("État"), isGralityField: true },
            { id: 'risk', label: i18n.t("Risque"), fieldCategoryId: i18n.t("État"), isGralityField: true },
            { id: 'accurateRisk', label: i18n.t("Risque (Matheny & Clarck)"), fieldCategoryId: i18n.t("État"), isGralityField: true },
            { id: 'treePort', label: i18n.t("Port de l'arbre"), fieldCategoryId: i18n.t("Description"), isGralityField: true },
            { id: 'healthReview', label: i18n.t("Cote sanitaire"), fieldCategoryId: i18n.t("État"), isGralityField: true }],
            ...customFields.filter(cf => cf.category === 'Arbre').map(cf => {
                const projectCustomField = projectCustomFields.find(pcf => Number(pcf.customFieldId) === cf.id);
                return ({ id: String(cf.id), label: cf.label, isCustomField: true, fieldCategoryId: projectCustomField?.fieldCategoryId || cf.customFieldCategoryId || 'general', order: projectCustomField?.order || 0 });
            }).sort((a, b) => a.order - b.order)
        ].filter(f => f);

        if (amenityFormulaType === 'Wallonie')
            fields.splice(20, 0, { id: 'patrimonialCoefficient', label: i18n.t("Coefficient patrimonial"), fieldCategoryId: i18n.t("Coefficients") });
        if (isImport)
            fields.splice(31, 0,
                { id: 'tippingRisk', label: i18n.t("Risque de basculement/rupture"), fieldCategoryId: i18n.t("État"), isGralityField: true },
                { id: 'organCaliber', label: i18n.t("Calibre de l'organe instable"), fieldCategoryId: i18n.t("État"), isGralityField: true },
                { id: 'target', label: i18n.t("Cible"), fieldCategoryId: i18n.t("État"), isGralityField: true }
            );

        fields.forEach(field => field.fieldCategoryId = defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === field.fieldCategoryId)?.id || field.fieldCategoryId);
        return fields;
    }

    static getGreenSpaceFields(projectCustomFields = [], customFields = [], defaultFieldCategories = []) {
        const fields = [
            { id: 'place', label: i18n.t("Lieu"), fieldCategoryId: i18n.t("Emplacement"), isGralityField: true },
            { id: 'placeExtra', label: i18n.t("Libellé"), fieldCategoryId: i18n.t("Emplacement"), isGralityField: true },
            { id: 'creationInfos', label: i18n.t("Informations de création"), fieldCategoryId: i18n.t("En-tête"), isGralityField: true },
            { id: 'modificationInfos', label: i18n.t("Informations de modification"), fieldCategoryId: i18n.t("En-tête"), isGralityField: true },
            { id: 'projectReference', label: i18n.t("Référence"), fieldCategoryId: i18n.t("En-tête"), isGralityField: true },
            { id: 'customReference', label: i18n.t("Référence personnalisée"), fieldCategoryId: i18n.t("En-tête"), isGralityField: true },
            { id: 'isTreeBase', label: i18n.t("Pied d'arbre"), fieldCategoryId: i18n.t("En-tête"), isGralityField: true },
            { id: 'surface', label: i18n.t("Surface"), isChecked: true, isRequired: true, fieldCategoryId: i18n.t("Indicateurs"), isGralityField: true },
            { id: 'length', label: i18n.t("Longueur"), fieldCategoryId: i18n.t("Indicateurs"), isGralityField: true },
            { id: 'spaceFunction', label: i18n.t("Fonction de l'espace"), fieldCategoryId: i18n.t("Emplacement"), isGralityField: true },
            { id: 'spaceType', label: i18n.t("Type de surface"), fieldCategoryId: i18n.t("Emplacement"), isGralityField: true },
            { id: 'dominantComposition', label: i18n.t("Composition dominante"), fieldCategoryId: i18n.t("Composition"), isGralityField: true },
            { id: 'detailedComposition', label: i18n.t("Composition détaillée"), fieldCategoryId: i18n.t("Composition"), isGralityField: true },
            { id: 'managementClass', label: i18n.t("Classe de gestion"), fieldCategoryId: i18n.t("Entretien"), isGralityField: true },
            { id: 'annualMaintenanceFrequency', label: i18n.t("Fréquence annuelle d'entretien"), fieldCategoryId: i18n.t("Entretien"), isGralityField: true },
            { id: 'observation', label: i18n.t("Observation"), fieldCategoryId: i18n.t("Observation"), isGralityField: true },
            { id: 'density', label: i18n.t("Densité"), fieldCategoryId: i18n.t("Massif arboré"), isGralityField: true },
            { id: 'dominantEssence', label: i18n.t("Essence dominante"), fieldCategoryId: i18n.t("Massif arboré"), isGralityField: true },
            { id: 'averageHealthReview', label: i18n.t("Cote sanitaire moyenne"), fieldCategoryId: i18n.t("Massif arboré"), isGralityField: true },
            { id: 'averageHeight', label: i18n.t("Hauteur moyenne"), fieldCategoryId: i18n.t("Massif arboré"), isGralityField: true },
            { id: 'averageCircumference', label: i18n.t("Circonférence moyenne des troncs"), fieldCategoryId: i18n.t("Massif arboré"), isGralityField: true },
            { id: 'averageCrownDiameter', label: i18n.t("Diamètre moyen des couronnes"), fieldCategoryId: i18n.t("Massif arboré"), isGralityField: true },
            { id: 'tags', label: i18n.t("Tags"), fieldCategoryId: i18n.t("En-tête"), isGralityField: true },
            ...customFields.filter(cf => cf.category === 'Espace vert').map(cf => {
                const projectCustomField = projectCustomFields.find(pcf => Number(pcf.customFieldId) === cf.id);
                return ({
                    id: String(cf.id), label: cf.label, isCustomField: true, fieldCategoryId: projectCustomField?.fieldCategoryId || cf.customFieldCategoryId || 'general', order: projectCustomField?.order || 0
                });
            }).sort((a, b) => a.order - b.order)
        ];

        fields.forEach(field => field.fieldCategoryId = defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === field.fieldCategoryId)?.id || field.fieldCategoryId);
        return fields;
    }

    static getFurnitureFields(projectCustomFields = [], customFields = [], defaultFieldCategories = []) {
        const fields = [
            { id: 'place', label: i18n.t("Lieu"), fieldCategoryId: i18n.t("Emplacement"), isGralityField: true },
            { id: 'creationInfos', label: i18n.t("Informations de création"), fieldCategoryId: i18n.t("En-tête"), isGralityField: true },
            { id: 'modificationInfos', label: i18n.t("Informations de modification"), fieldCategoryId: i18n.t("En-tête"), isGralityField: true },
            { id: 'projectReference', label: i18n.t("Référence"), fieldCategoryId: i18n.t("En-tête"), isGralityField: true },
            { id: 'customReference', label: i18n.t("Référence personnalisée"), fieldCategoryId: i18n.t("En-tête"), isGralityField: true },
            { id: 'type', label: i18n.t("Type"), fieldCategoryId: i18n.t("Description"), isGralityField: true },
            { id: 'description', label: i18n.t("Description"), fieldCategoryId: i18n.t("Description"), isGralityField: true },
            { id: 'condition', label: i18n.t("État"), fieldCategoryId: i18n.t("État"), isGralityField: true },
            { id: 'tags', label: i18n.t("Tags"), fieldCategoryId: i18n.t("En-tête"), isGralityField: true },
            ...customFields.filter(cf => cf.category === 'Mobilier').map(cf => {
                const projectCustomField = projectCustomFields.find(pcf => Number(pcf.customFieldId) === cf.id);
                return ({
                    id: String(cf.id), label: cf.label, isCustomField: true, fieldCategoryId: projectCustomField?.fieldCategoryId || cf.customFieldCategoryId || 'general', order: projectCustomField?.order || 0
                });
            }).sort((a, b) => a.order - b.order)
        ];

        fields.forEach(field => field.fieldCategoryId = defaultFieldCategories.find(dfc => dfc.category === 'Mobilier' && dfc.label === field.fieldCategoryId)?.id || field.fieldCategoryId);
        return fields;
    }

    static getStationFields(projectCustomFields = [], customFields = [], defaultFieldCategories = []) {
        const fields = [
            { id: 'label', label: i18n.t("Label"), fieldCategoryId: i18n.t("Description"), isGralityField: true },
            { id: 'surface', label: i18n.t("Surface"), fieldCategoryId: i18n.t("Description"), isGralityField: true },
            ...customFields.filter(cf => cf.category === 'Station').map(cf => {
                const projectCustomField = projectCustomFields.find(pcf => Number(pcf.customFieldId) === cf.id);
                return ({
                    id: String(cf.id), label: cf.label, isCustomField: true, fieldCategoryId: projectCustomField?.fieldCategoryId || cf.userCustomFieldCategoryId || 'general', order: projectCustomField?.order || 0
                });
            }).sort((a, b) => a.order - b.order)
        ];

        fields.forEach(field => field.fieldCategoryId = defaultFieldCategories.find(dfc => dfc.category === 'Station' && dfc.label === field.fieldCategoryId)?.id || field.fieldCategoryId);
        return fields;
    }

    static getCategoryOrder(config, elementType, category, exactMatch = true) {
        config = JSON.parse(config || '{}');
        let index = exactMatch
            ? config?.[elementType]?.indexOf?.(category)
            : config?.[elementType]?.findIndex(cat => cat.includes(category));
        if ((!index && index !== 0) || index === -1) index = 999;
        return index;
    }
}