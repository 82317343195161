import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProjectEventCreationForm from './ProjectEventCreationForm';
import InputPopupForm from '../../Utils/InputPopupForm';
import ProjectEventLinkingForm from './ProjectEventLinkingForm';
import ProjectEventModificationForm from './ProjectEventModificationForm';
/*     Editors     */
import CommentEditor from '../../Tables/Editors/CommentEditor';
/*     Filters     */
import TextFilter from '../../Tables/Filters/TextFilter';
import NumberFilter from '../../Tables/Filters/NumberFilter';
import Woops from '../../Utils/Woops';
// Librairies
import DataGrid, { Row as GridRow } from 'react-data-grid';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import i18n from '../../../locales/i18n';
import { faCheck, faTimesCircle, faLink, faPenToSquare, faUnlink, faPlus, faCalendar } from '@fortawesome/pro-solid-svg-icons';
import { isMobile, isMobileOnly } from 'react-device-detect';
// Redux
import { connect } from 'react-redux';
import { setCurrentAction, setRequest } from '../../../actionCreators/appActions';
import { setProjectEvents } from '../../../actionCreators/projectsActions';
// Semantic UI
import { Button, Segment, Grid, Message, Dimmer, Dropdown, Icon } from 'semantic-ui-react';
// Services
import EventsService from '../../../services/EventsService';
// Styles
import '../../../styles/react-contextmenu.css';
import '../../../styles/rdg.css';
// Utils
import DatesUtil from '../../../utils/DatesUtil';
import WebSocketUtil from '../../../utils/WebSocketUtil';
import StylesUtil from '../../../utils/StylesUtil';
import FormattersUtil from '../../../utils/FormattersUtil';
import RightsUtil from '../../../utils/RightsUtil';

const initialFilters = {
    label: '',
};

class EventForm extends Component {
    state = {
        data: {
            columns: [],
            rows: []
        },
        sortColumn: null,
        sortDirection: 'NONE',
        enableFilterRow: false,
        filters: initialFilters,
        isUnlinkLoading: false,
        isLoading: true,
        projectEventToEdit: null,
        nbElementsAffected: 0,
        rowToEdit: null,
        peToUnlink: null,
        isAdding: false,
        isLinking: false,
        eventToAdd: null,
    };

    render() {
        const { rights, isDarkTheme, project, events } = this.props;
        const {
            data, sortColumn, sortDirection, enableFilterRow, filters, rowToEdit, peToUnlink, isAdding, isLinking, eventToAdd,
            isLoading, isOverflowing, isUnlinkLoading, projectEventToEdit, nbElementsAffected
        } = this.state;
        const rows = this.getFilteredRows();
        const category = this.props.layer[0]?.feature?.properties.category;

        return (
            <>
                <div className='modal-content'>
                    {!events ?
                        <Woops />
                        :
                        <>
                            {rowToEdit &&
                                <InputPopupForm
                                    title={i18n.t("Date de réalisation")} value={new Date(rowToEdit.validationDate.valueOf())}
                                    inputType='date' showCurrentValue={false} submitButtonIcon={faCheck} submitButtonLabel={i18n.t("Valider")} disabled={!this.props.isOnline}
                                    submit={this.handleValidationDateSubmit} cancel={() => this.setState({ rowToEdit: null })}
                                />}
                            <Dimmer active={peToUnlink} style={StylesUtil.getMapStyles().dimmerStyle}>
                                <Message compact className='tableConfirmation'>
                                    <Message.Header>{i18n.t("Êtes vous certain de vouloir délier cette évènement ?")}</Message.Header>
                                    <Message.Content style={{ marginTop: '10px' }}>
                                        <Button color='grey' onClick={() => this.setState({ peToUnlink: null })} disabled={isUnlinkLoading}>
                                            <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                        </Button>
                                        <Button color='red' onClick={this.handleUnlinkConfirmation} disabled={isUnlinkLoading} loading={isUnlinkLoading}>
                                            <FontAwesomeIcon icon={faUnlink} style={{ marginRight: '10px' }} />{i18n.t("Délier")}
                                        </Button>
                                    </Message.Content>
                                </Message>
                            </Dimmer>
                            <div className='modal-content-header' style={{ display: 'flex', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row', marginBottom: '4px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: isMobileOnly ? 'column' : 'row', width: '100%', flex: 1, order: isMobile ? 2 : 1 }}>
                                    <div style={{ marginTop: isMobileOnly && '5px' }}>
                                        <Button.Group style={{ marginRight: '3.5px' }}>
                                            <Button
                                                title={enableFilterRow ? i18n.t("Désactiver les filtres") : i18n.t("Activer les filtres")}
                                                className={enableFilterRow ? 'button--secondary' : null} color={!enableFilterRow ? 'grey' : null} icon='filter'
                                                onClick={this.toggleFilters}
                                            />
                                            <Button
                                                title={i18n.t("Réinitialiser les filtres")} className='button--secondary' icon='dont'
                                                onClick={this.clearFilters} disabled={!this.areFiltersApplied()}
                                            />
                                        </Button.Group>
                                    </div>
                                </div>
                                {!isMobileOnly &&
                                    <div style={{ marginLeft: 'auto', order: isMobile ? 1 : 2, marginBottom: isMobile && '5px' }}>
                                        {project.type === 'project' && !isMobile && RightsUtil.canWrite(this.props.rights?.events) && this.renderNewEventButton()}
                                    </div>}
                            </div>
                            <div className='modal-content-body'>
                                <Segment id='action-table__segment' style={{ display: 'flex', flexFlow: 'column', padding: 0, width: '100%', height: '100%' }}>
                                    {data?.columns &&
                                        <>
                                            <DataGrid
                                                ref={this.gridRef} className={isDarkTheme ? 'rdg-dark' : 'rdg-light'}
                                                columns={data.columns}
                                                rows={rows} rowRenderer={this.rowRenderer} rowClass={(row) => 'parent-row'}
                                                defaultColumnOptions={{ sortable: true, resizable: true }}
                                                cellNavigationMode='LOOP_OVER_ROW'
                                                sortColumn={sortColumn} sortDirection={sortDirection}
                                                onSort={this.handleSort} enableFilterRow={enableFilterRow}
                                                filters={filters} onFiltersChange={filters => this.setState({ filters: filters })}
                                                emptyRowsRenderer={() => (<div style={{ textAlign: 'center' }}>
                                                    <span>{isLoading ? i18n.t("Chargement en cours...") : i18n.t("Aucun résultat trouvé")}</span>
                                                </div>)}
                                                onSelectedCellChange={({ idx, rowIdx }) => { this.selectedRow = rows[rowIdx]; this.selectedColumn = data.columns[idx]; }}
                                                onRowsChange={this.handleRowsChange}
                                                style={{ height: (72 + (35 * rows.length) + (enableFilterRow ? 45 : 0) + (isOverflowing ? 10 : 0)) + 'px' }}
                                            />
                                            {project.type === 'project' &&
                                                <>
                                                    <ContextMenu id='grid-parent-context-menu'>
                                                        <MenuItem disabled={!this.props.isOnline} onClick={this.editProjectEvent}>{i18n.t("Modifier")}</MenuItem>
                                                        {RightsUtil.canWrite(rights?.events) && <MenuItem onClick={this.handleUnlink}>{i18n.t("Délier")}</MenuItem>}
                                                    </ContextMenu>
                                                </>}
                                        </>}
                                </Segment>
                            </div>
                            {(isAdding || isLinking || projectEventToEdit || isMobile) &&
                                <div className='modal-content-footer'>
                                    {isMobile && !isAdding && !isLinking && this.renderNewEventButton(isMobile)}
                                    {(isAdding || isLinking || projectEventToEdit) &&
                                        <Segment style={{ marginTop: '12px' }}>
                                            {isAdding &&
                                                <ProjectEventCreationForm
                                                    eventToAdd={eventToAdd} category={category} treesLayer={this.props.treesLayer}
                                                    cancel={this.handleCancel} submit={this.handleAddConfirmation}
                                                />}
                                            {isLinking &&
                                                <ProjectEventLinkingForm
                                                    updateLegend={this.props.updateLegend}
                                                    treesLayer={this.props.treesLayer} greenSpacesLayer={this.props.greenSpacesLayer} furnituresLayer={this.props.furnituresLayer}
                                                    cancel={this.handleCancel} submit={this.handleLinkConfirmation}
                                                />}
                                            {projectEventToEdit &&
                                                <ProjectEventModificationForm
                                                    projectEvent={projectEventToEdit} cancel={() => this.setState({ projectEventToEdit: null })}
                                                    submit={(projectEventToEdit) => {
                                                        const nbElementsAffected = projectEventToEdit.projectEventElements.length - 1;
                                                        this.setState({ projectEventToEdit, nbElementsAffected }, () => {
                                                            if (!this.state.nbElementsAffected) this.handleModificationConfirmation();
                                                        })
                                                    }}
                                                />}
                                        </Segment>}
                                </div>}
                        </>}
                </div>
                {nbElementsAffected > 0 &&
                    <Dimmer active style={{ ...StylesUtil.getMapStyles().dimmerStyle, position: 'fixed' }}>
                        <Grid style={{ height: '100%' }}>
                            <Grid.Row style={{ height: '100%' }} verticalAlign='middle'>
                                <Grid.Column>
                                    <Message compact className='tableConfirmation'>
                                        <Message.Content style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Message icon error style={{ textAlign: 'left' }}>
                                                <Icon name='trash' />
                                                <Message.Content>
                                                    <Message.Header style={{ marginBottom: '5px' }}>
                                                        {i18n.t("Êtes-vous certain de vouloir modifier cette évènement ?")}
                                                    </Message.Header>
                                                    {nbElementsAffected > 0 && (nbElementsAffected > 1 ? i18n.t("Attention modification portant sur {{count}} autres sujets.", { count: nbElementsAffected }) : i18n.t("Attention modification portant sur un autre sujet."))}
                                                </Message.Content>
                                            </Message>
                                            <div style={{ marginLeft: 'auto' }}>
                                                <Button color='red' onClick={this.handleModificationCancel}>
                                                    {i18n.t("Annuler")}
                                                </Button>
                                                <Button color='green' onClick={this.handleModificationConfirmation}>
                                                    {i18n.t("Valider")}
                                                </Button>
                                            </div>
                                        </Message.Content>
                                    </Message>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid >
                    </Dimmer >}
            </>
        );
    }

    componentDidMount = async () => {
        window.addEventListener('resize', this.handleResize);

        this.loadData();

        this.gridRef = React.createRef();
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentDidUpdate = (prevProps) => {
        if ((prevProps.layer[0].feature.id !== this.props.layer[0].feature.id) || (JSON.stringify(prevProps.projectEvents) !== JSON.stringify(this.props.projectEvents)))
            this.loadData();

        const { isOverflowing, data } = this.state;
        if (isOverflowing === undefined && data?.columns) {
            const gridElement = document.getElementsByClassName('rdg')[0];
            if (gridElement) {
                const width = data.columns.reduce((previousValue, column) => previousValue + column.width, 0);
                this.setState({ isOverflowing: gridElement.clientWidth < width });
            }
        }
    }

    componentWillUnmount = () => {
        document.removeEventListener('keydown', this.handleKeyDown);
        document.removeEventListener('resize', this.handleResize);
        // TODO
        // this.props.resetActionToShow();
    }

    renderNewEventButton = (isMobile) => {
        return RightsUtil.canWrite(this.props.rights?.events) ? (
            <>
                {isMobile ?
                    <Grid>
                        <Grid.Row columns={isMobileOnly ? 1 : 2}>
                            <Grid.Column style={!isMobileOnly ? { paddingRight: 0 } : null}>
                                <Button
                                    color='green' className='form-button' onClick={this.handleAdd}
                                    style={!isMobileOnly
                                        ? { textAlign: 'center', fontWeight: 'bold', width: '100%', borderTopRightRadius: 0, borderBottomRightRadius: 0 }
                                        : { textAlign: 'center', fontWeight: 'bold', width: '100%' }
                                    }
                                >
                                    <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px' }} />{i18n.t("Ajouter un nouvel évènement")}
                                </Button>
                            </Grid.Column>
                            <Grid.Column style={!isMobileOnly ? { paddingLeft: 0 } : null}>
                                <Button
                                    color='blue' className='form-button' onClick={this.handleLink}
                                    style={!isMobileOnly
                                        ? { textAlign: 'center', fontWeight: 'bold', width: '100%', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }
                                        : { textAlign: 'center', fontWeight: 'bold', width: '100%' }
                                    }
                                >
                                    <FontAwesomeIcon icon={faLink} style={{ marginRight: '10px' }} />{i18n.t("Lier l'élément à un évènement")}
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    :
                    <Dropdown
                        text={i18n.t("Nouvel évènement")} item floating button direction='left' className={`button--primary${isMobile ? ' form-button' : ''}`}
                        style={{ textAlign: isMobile && 'center', marginLeft: 'auto' }}
                    >
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={this.handleAdd}>
                                <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px' }} />{i18n.t("Créer")}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={this.handleLink}>
                                <FontAwesomeIcon icon={faLink} style={{ marginRight: '10px' }} />{i18n.t("Lier")}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>}
            </>
        ) : null;
    }

    renderDropdownText = (label, condition, value, icon1, icon2) => {
        return (<div style={{ display: 'flex', width: '100%' }}>
            <div style={{ marginRight: '30px' }}>{label}</div>
            <div style={{ marginLeft: 'auto' }}>{condition === value ? icon1 : icon2}</div>
        </div>);
    }

    rowRenderer = (props) => (
        <ContextMenuTrigger id='grid-parent-context-menu' collect={() => ({ rowIdx: props.rowIdx })}>
            <GridRow {...props} key={props.row.id} />
        </ContextMenuTrigger>
    );

    handleRowsChange = (newRows) => {
        this.setState(prevState => {
            let rows = prevState.data.rows;
            newRows.forEach(newRow => {
                const index = rows.findIndex(row => row.id === newRow.id);
                rows[index] = newRow;
            });
            return { data: { columns: prevState.data.columns, rows: rows } };
        });
    }

    getRows = () => {
        const feature = this.props.layer[0].feature;
        const projectEvents = this.props.projectEvents.filter(pe => pe.projectEventElements.find(pee => pee.elementId === feature.id));
        const rows = projectEvents.map(pe => {
            const category = ({ 'Arbre': 'trees', 'Espace vert': 'greenSpaces', 'Mobilier': 'furnitures' })[feature.properties.category];

            return {
                id: pe.id,
                category,
                label: pe.event.label,
                date: pe.date,
                comment: pe.projectEventElements.find(pee => pee.elementId === feature.id).comment,
                event: pe.event,
            };
        });

        return rows.sort((a, b) => new Date(a.date) - new Date(b.date));
    }

    loadData = ({ rows } = {}) => new Promise(resolve => {
        const data = {
            columns: [],
            rows: rows || []
        };

        data.columns = [
            {
                name: i18n.t("Libellé"), key: 'label', width: 300, sortable: true,
                formatter: (props) => {
                    const row = props.row;
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                {row.label}
                            </div>
                        </div>);
                },
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Date"), key: 'date', width: 325, sortable: true,
                formatter: (props) => {
                    const row = props.row;
                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'flex' }}>
                                <div title={i18n.t("Date")} style={{ width: '110px' }}>
                                    <FontAwesomeIcon icon={faCalendar} style={{ marginRight: '5px' }} />
                                    {DatesUtil.getFormattedLocaleDateString(row.date)}
                                </div>
                            </div>
                        </div>
                    );
                },
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Commentaire"), key: 'comment', width: 600,
                sortable: true,
                formatter: (props) => {
                    const row = props.row;
                    return row.comment || <i style={{ color: 'var(--grey-100)' }}>Ajouter un commentaire</i>;
                },
                filterRenderer: (props) => <NumberFilter p={props} />,
                editable: () => this.props.project.type === 'project' && RightsUtil.canWrite(this.props.rights?.events),
                editor: ({ row, onRowChange, onClose }) => <CommentEditor row={row} onRowChange={onRowChange} onClose={onClose} updateProjectEventElement={this.updateProjectEventElement} />
            }
        ];

        if (this.props.project.type === 'project' && RightsUtil.canWrite(this.props.rights?.events))
            data.columns.splice(0, 0, {
                name: '', key: 'events', width: 110,
                colSpan: () => 1,
                formatter: (props) => <>
                    <Button
                        color='blue' title={i18n.t("Délier l'évènement")} style={{ padding: 0, height: '25px', width: '25px' }}
                        onClick={() => this.handleUnlink(props.row.id)}
                    >
                        <FontAwesomeIcon icon={faUnlink} />
                    </Button>
                    <Button color='yellow' title={i18n.t("Modifier")} style={{ padding: 0, height: '25px', width: '25px' }} disabled={!this.props.isOnline} onClick={() => this.editProjectEvent(null, props)}>
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </Button>
                </>
            });

        if (!rows) {
            data.rows = this.getRows();

            const peeList = this.props.projectEvents.flatMap(pe => pe.projectEventElements);
            const initialOrder = [];
            data.rows.forEach(row => initialOrder.push(row.id));
            this.setState({ data, initialOrder, peeList, isLoading: false }, () => resolve());
        } else resolve(data);
    });

    setPeriod = (period) => this.setState({ period });
    toggleSelection = (property, element) => {
        const { filters } = this.state;
        if (filters[property].includes(element)) filters[property] = filters[property].filter(e => e !== element);
        else filters[property].push(element);
        this.setState({ filters });
    }

    updateProjectEventElement = (peId, peeToUpdate) => {
        let pee = this.state.peeList.find(pee => pee.projectEventId === peeToUpdate.id);
        if (pee.comment === peeToUpdate.comment) return;
        pee.comment = peeToUpdate.comment;

        EventsService.updateProjectEventElements(this.props.project.id, peId, [pee]).then(projectEventElements => {
            if (projectEventElements) WebSocketUtil.updateProjectEventElements(this.props.webSocketHubs, this.props.project.id, projectEventElements);

            // Mise à jour des rows
            this.setState(prevState => {
                const rows = [...prevState.data.rows];
                let row = rows.find(row => row.id === peeToUpdate.id);
                row.comment = pee.comment;
                return { data: { columns: prevState.data.columns, rows }, rowToEdit: null };
            });
        });
    }

    handleAdd = (event = null) => this.setState({ isLinking: false, isAdding: true, eventToAdd: event });
    handleAddConfirmation = (elementEvent) => {
        const { layer } = this.props;

        this.setState({ isLinking: false, isAdding: false });

        const categories = {
            'Arbre': {
                legendName: i18n.t("Arbres"),
                getStyle: (layer) => StylesUtil.getTreeActiveStyle(this.props.treesLayer, this.props.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            },
            'Espace vert': {
                legendName: i18n.t("Espaces verts"),
                getStyle: (layer) => StylesUtil.getGreenSpaceActiveStyle(this.props.greenSpacesLayer.activeChild, this.props.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            },
            'Mobilier': {
                legendName: i18n.t("Mobilier urbain"),
                getStyle: (layer) => StylesUtil.getFurnitureActiveStyle(this.props.furnituresLayer.activeChild, this.props.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            }
        };

        const category = layer[0].feature.properties.category;
        const { getStyle, legendName } = categories[category];

        // TODO
        // Mise à jour style/légende au cas où carte thématique sur évènements
        // this.props.layer.forEach(layer => layer.setStyle(getStyle(layer)));
        // this.props.updateLegend(legendName);
    }

    handleLink = () => this.setState({ isLinking: true, isAdding: false });
    handleLinkConfirmation = (projectEvent, link = false) => {
        const linkPE = (pe) => {
            // On ajoute l'évènement dans redux
            pe.event = this.props.events.find(x => x.id === pe.eventId);
            const projectEvents = [...this.props.projectEvents.filter(pe => pe.id !== projectEvent.id), projectEvent];
            this.props.setProjectEvents(projectEvents).then(this.loadData);
            WebSocketUtil.updateProjectEvents(this.props.webSocketHubs, this.props.project.id, [pe]);
        };

        if (!link) linkPE(projectEvent);
        else {
            const peeToAdd = [{ elementId: this.props.layer[0].feature.id, elementType: this.props.layer[0].feature.properties.category, comment: null }];
            EventsService.updateProjectEvent(projectEvent, this.props.project.id, { peeToAdd, successToast: 'event_linked_single', errorToast: 'connection_failed' }).then(response => {
                if (response) {
                    linkPE(response);
                    projectEvent.projectEventElements = response.projectEventElements;
                }
            });
        }
    }

    handleUnlink = (projectEventId) => this.setState({ isLinking: false, isAdding: false, peToUnlink: projectEventId });
    handleUnlinkConfirmation = () => {
        const { peToUnlink } = this.state;
        this.setState({ isUnlinkLoading: true });

        const categories = {
            'Arbre': {
                legendName: i18n.t("Arbres"),
                getStyle: (layer) => StylesUtil.getTreeActiveStyle(this.props.treesLayer, this.props.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            },
            'Espace vert': {
                legendName: i18n.t("Espaces verts"),
                getStyle: (layer) => StylesUtil.getGreenSpaceActiveStyle(this.props.greenSpacesLayer.activeChild, this.props.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            },
            'Mobilier': {
                legendName: i18n.t("Mobilier urbain"),
                getStyle: (layer) => StylesUtil.getFurnitureActiveStyle(this.props.furnituresLayer.activeChild, this.props.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            }
        };

        const projectEvent = this.props.projectEvents.find(projectEvent => projectEvent.id === peToUnlink);
        const peeToRemove = projectEvent.projectEventElements.filter(pee => pee.elementId === this.props.layer[0].feature.id);
        EventsService.updateProjectEvent(projectEvent, this.props.project.id, { peeToRemove, successToast: 'event_linked_single', errorToast: 'connection_failed' }).then(response => {
            if (response) {
                projectEvent.projectEventElements = response.projectEventElements;
                WebSocketUtil.updateProjectEvents(this.props.webSocketHubs, this.props.project.id, [projectEvent]);

                this.setState(prevState => ({ data: { ...prevState.data, rows: prevState.data.rows.filter(row => row.id !== peToUnlink) } }));
                this.props.setProjectEvents([...this.props.projectEvents]);
            }

            this.setState({ isUnlinkLoading: false, peToUnlink: null });
        });
    }

    handleCancel = () => this.setState({ isLinking: false, isAdding: false });
    handleModificationCancel = () => this.setState({ projectEventToEdit: this.props.projectEvents.find(pe => pe.id === this.state.projectEventToEdit.id), nbElementsAffected: 0 });
    handleModificationConfirmation = () => {
        EventsService.updateProjectEvent(this.state.projectEventToEdit, this.props.project.id).then(projectEvent => {
            const projectEvents = JSON.parse(JSON.stringify(this.props.projectEvents));
            const index = projectEvents.findIndex(pe => pe.id === projectEvent.id);
            if (index !== -1) {
                projectEvents[index] = projectEvent;
                this.props.setProjectEvents(projectEvents);
                WebSocketUtil.updateProjectEvents(this.props.webSocketHubs, this.props.project.id, [projectEvent]);
            }

            this.setState({ projectEventToEdit: null, nbElementsAffected: 0 });
        });
    }

    // Filtres
    areFiltersApplied = () => {
        if (!this.state.enableFilterRow) return false;
        let filtersApplied = false;
        for (const property in this.state.filters)
            if (this.state.filters[property] && property !== 'elements') filtersApplied = true;
        return filtersApplied;
    }

    toggleFilters = () => this.setState(prevState => ({ enableFilterRow: !prevState.enableFilterRow }));
    clearFilters = () => this.setState({ filters: initialFilters });

    getFilteredRows = () => {
        const filters = this.state.filters;
        let rows = [...this.state.data.rows];

        const $ = (str) => FormattersUtil.getNormalizedString(str);
        const rowMatchesFilters = (row) => {
            return (filters.label ? $(row.label)?.includes($(filters.label)) : true)
                && (filters.date ? $(DatesUtil.getFormattedLocaleDateString(row.date))?.includes($(filters.date)) : true)
                && (filters.comment ? $(row.comment)?.includes($(filters.comment)) : true)
        };

        return rows.filter(row => { // On retourne le résultat correspondant aux filtres
            return !this.state.enableFilterRow
                || rowMatchesFilters(row);
        });
    }

    // Tri
    handleSort = (columnKey, direction) => this.setState({ sortColumn: columnKey, sortDirection: direction }, this.sortRows);
    sortRows = () => {
        const { sortColumn, sortDirection, initialOrder, data } = this.state;

        const sort = (rows) => {
            let sortedRows = [...rows];
            sortedRows = sortedRows.sort((a, b) => (a[sortColumn] || '').localeCompare(b[sortColumn] || ''));
            if (sortDirection === 'DESC') sortedRows.reverse()
            return sortedRows;
        };

        let rows;
        if (sortDirection === 'NONE') {
            rows = [...data.rows];
            let replaceIndex = 0;
            initialOrder.forEach(elementId => {
                let index = rows.findIndex(row => `${row.id}` === `${elementId}`);
                if (index === -1)
                    index = rows.findIndex(row => {
                        const ids = elementId.split?.('|');
                        if (!ids) return false;
                        return `${row.id}` === `${ids[1]}`;
                    });
                if (index !== -1) {
                    let temp = rows[replaceIndex];
                    rows[replaceIndex] = rows[index];
                    rows[index] = temp;
                    replaceIndex++;
                }
            });
        } else rows = sort([...data.rows]);

        this.setState(prevState => ({ data: { ...prevState.data, rows } }));
    }

    handleKeyDown = (e) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'c' && this.selectedColumn && this.selectedRow)
            navigator.clipboard.writeText(this.selectedRow[this.selectedColumn.key] || '');
    }

    handleResize = () => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            const { isOverflowing, data } = this.state;
            const gridElement = document.getElementsByClassName('rdg')[0];
            if (gridElement && data?.columns) {
                const width = data.columns.reduce((previousValue, column) => previousValue + column.width, 0);
                if (isOverflowing && gridElement.clientWidth >= width) this.setState({ isOverflowing: false });
                if (!isOverflowing && gridElement.clientWidth < width) this.setState({ isOverflowing: true });
            }
        }, 100);
    }

    editProjectEvent = (_, { rowIdx }) => {
        const filteredRows = this.getFilteredRows();
        const projectEventToEdit = this.props.projectEvents.find(pe => pe.id === filteredRows[rowIdx].id);
        this.setState(prevState => ({
            projectEventToEdit: !prevState.projectEventToEdit || prevState.projectEventToEdit?.id !== projectEventToEdit.id ? JSON.parse(JSON.stringify(projectEventToEdit)) : null
        }));
    }
}

const mapStateToProps = (state) => {
    return {
        webSocketHubs: state.webSocketHubs,
        layer: state.layer,
        project: state.project,
        projectCollaborators: state.projectCollaborators,
        isDarkTheme: state.isDarkTheme,
        isOnline: state.isOnline,
        activeOrganization: state.activeOrganization,
        rights: state.rights,
        events: state.events,
        projectEvents: state.projectEvents,
    };
};

const mapDispatchToProps = {
    setCurrentAction,
    setRequest,
    setProjectEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventForm);