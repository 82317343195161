import React, { Component } from 'react';
// Composants
import NewWindow from 'react-new-window';
import SSOForm from './SSOForm';
// Librairies
import i18n from '../../../locales/i18n';

const height = 600;
const width = 500;

class SSOButton extends Component {
    state = {
        isFormOpened: false,
        window: null
    };

    render() {
        const { isFormOpened, OAuthUrl } = this.state;
        let left = (window.screen.width / 2) - (width / 2);
        let top = (window.screen.height / 2) - (height / 2);

        return (
            <>
                <a href='#' onClick={this.handleClick} style={{ marginTop: '10px' }}>{i18n.t("Se connecter avec un SSO")}</a>
                {isFormOpened &&
                    <NewWindow
                        url={OAuthUrl} features={{ top, left, height, width }} title={`${i18n.t("Connexion")} - SSO`}
                        onOpen={this.handleOpen} onUnload={() => { this.setState({ isFormOpened: false }); clearInterval(this.redirectChecker); }}
                    >
                        <SSOForm
                            window={window} isLinking={this.props.submit ? true : false}
                            setOAuthUrl={this.setOAuthUrl} submit={(response) => this.submit(response)}
                        />
                    </NewWindow>}
            </>
        );
    }

    componentWillUnmount = () => clearInterval(this.redirectChecker);

    handleClick = (e) => {
        e.preventDefault();
        if (!this.state.isFormOpened) this.setState({ isFormOpened: true });
    }

    handleOpen = (window) => {
        this.setState({ window });
    }

    setOAuthUrl = (url) => {
        this.setState({ isFormOpened: false, OAuthUrl: url }, () => {
            this.redirectChecker = setInterval(() => { // Permet de détecter le redirect du portail OAuth2 pour rediriger dans la fenêtre principale plutôt que le popup
                try { // Tant qu'on est sur le portail d'authentification, ça déclenche une exception (cross-origin)
                    if (![this.state.OAuthUrl, 'about:blank'].includes(this.state.window.location.href)) {
                        clearInterval(this.redirectChecker);
                        window.location.href = this.state.window.location.href;
                        this.state.window.close();
                    }
                } catch { }
            }, 100);
            this.setState({ isFormOpened: true });
        });
    }

    submit = (response) => {
        this.state.window.close();
        this.setState({ isFormOpened: false });
        this.props.submit(response);
    }
}

export default SSOButton;