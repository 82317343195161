import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Grid, Select, TextArea } from 'semantic-ui-react';
// Librairies
import i18n from '../../../locales/i18n';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { connect } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
// Redux
import { setActionHistory } from '../../../actionCreators/elementsActions';
// Services
import EventsService from '../../../services/EventsService';
// Utils
import DatesUtil from '../../../utils/DatesUtil';
import FormattersUtil from '../../../utils/FormattersUtil';

const initialNewEvent = {
    eventId: 0,
    date: null,
}

const initialError = {
    hidden: true,
    messages: [],
    eventId: false,
    date: false
}

class ProjectEventLinkingForm extends Component {
    state = {
        projectEvents: [], // Liste des projectEvents du select
        newEvent: {
            ...initialNewEvent,
            projectId: this.props.project.id
        },
        comment: null,
        error: initialError,
        isLoading: false
    }

    render() {
        const { projectEvents, newEvent, comment, error, isLoading } = this.state;
        const isOk = this.state.newEvent.eventId;

        return (
            <Form onSubmit={this.handleSubmit} loading={isLoading}>
                <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                    <Grid.Row stretched  style={{ paddingTop: 0 }}>
                        <Grid.Column>
                            <Form.Field
                                name='eventId' label={`${i18n.t("Évènement")} : `} placeholder={i18n.t("Sélectionnez un évènement")}
                                control={Select} options={projectEvents || []}
                                noResultsMessage={i18n.t("Aucun résultat trouvé")} search={FormattersUtil.searchList} clearable selectOnBlur={false}
                                value={newEvent.id || ''} onChange={this.handleSelectChange}
                                error={error.eventId}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ paddingTop: 0 }}>
                        <Grid.Column>
                            <Form.Field
                                style={{ minHeight: isMobileOnly ? '200px' : '100px' }}
                                control={TextArea} placeholder={i18n.t("Ajouter un commentaire")}
                                name='comment' value={comment || ''} label={`${i18n.t("Commentaire")} : `}
                                onChange={(_, { value }) => this.setState({ comment: value })}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Button type='button' color='red' onClick={this.props.cancel}>
                                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                            </Button>
                            <Button type='submit' color='green' disabled={!isOk || isLoading}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        );
    }

    componentDidMount = () => this.loadProjectEvents();

    componentDidUpdate = (prevProps) => {
        let prevPEList = []
        prevProps.projectEvents.forEach(pe => {
            if (pe.projectEventElements.filter(pee => pee.elementId !== this.props.layer[0].feature.id))
                prevPEList.push(pe);
        })
        let currentPEList = []
        this.props.projectEvents.forEach(pe => {
            if (pe.projectEventElements.filter(pee => pee.elementId !== this.props.layer[0].feature.id))
                currentPEList.push(pe);
        })

        if (JSON.stringify(currentPEList) !== JSON.stringify(prevPEList)) this.loadProjectEvents();
    }

    loadProjectEvents = () => {
        if (this.props.projectEvents) {
            let projectEvents = [];
            if (this.props.projectEvents) {
                this.props.projectEvents.forEach(pe => {
                    const found = !(!pe.projectEventElements.find(pee => pee.elementId === this.props.layer[0].feature.id));
                    if (!found) {
                        const text = pe.event.label + ' | ' + DatesUtil.getFormattedLocaleDateString(pe.date);
                        projectEvents.push({ text: text, value: pe.id });
                    }
                });
            }

            this.setState({ projectEvents });
        }
    }

    handleSubmit = () => {
        const { layer, project } = this.props;
        const { newEvent, comment } = this.state;

        // TODO
        // const categories = {
        //     'Arbre': {
        //         legendName: i18n.t("Arbres"),
        //         getStyle: (layer) => StylesUtil.getTreeActiveStyle(this.props.treesLayer, this.props.fieldList, layer.feature.properties, this.props.project.thematicMaps)
        //     },
        //     'Espace vert': {
        //         legendName: i18n.t("Espaces verts"),
        //         getStyle: (layer) => StylesUtil.getGreenSpaceActiveStyle(this.props.greenSpacesLayer.activeChild, this.props.fieldList, layer.feature.properties, this.props.project.thematicMaps)
        //     },
        //     'Mobilier': {
        //         legendName: i18n.t("Mobilier urbain"),
        //         getStyle: (layer) => StylesUtil.getFurnitureActiveStyle(this.props.furnituresLayer.activeChild, this.props.fieldList, layer.feature.properties, this.props.project.thematicMaps)
        //     }
        // };
        const category = layer[0].feature.properties.category;
        // const { legendName, getStyle } = categories[category];

        if (newEvent.id) { // Si une évènement a bien été sélectionné
            this.setState({ isLoading: true });
            const peeToAdd = [{ elementId: layer[0].feature.id, elementType: category, comment }];

            EventsService.updateProjectEvent(newEvent, project.id, { peeToAdd, successToast: 'event_linked_single', errorToast: 'connection_failed' }).then(projectEvent => {
                if (projectEvent) {
                    const projectEvents = this.state.projectEvents.filter(x => x.value !== newEvent.id); // On le retire de la liste des évènements qu'on peut ajouter
                    
                    // TODO
                    // layer.forEach(layer => layer.setStyle(getStyle(layer)));
                    // this.props.updateLegend(legendName);

                    this.props.submit(projectEvent);
                    this.setState({
                        projectEvents,
                        newEvent: { ...initialNewEvent, projectId: this.props.project.id },
                        comment: null,
                        error: initialError
                    });
                }

                this.setState({ isLoading: false });
            });
        } else this.setState({ // Si aucun évènement n'a été sélectionné, on affiche un message d'erreur
            error: {
                hidden: false,
                messages: [i18n.t("Veuillez sélectionner un évènement")],
                eventId: true,
            }
        });
    }

    handleSelectChange = (_, { name, value }) => {
        if (value !== -1) {
            var newEvent = this.props.projectEvents.find(x => x.id === value);
            this.setState(prevState => ({ newEvent, error: { ...prevState.error, [name]: false } }));
        }
    }
}

const mapStateToProps = (state) => {
    return {
        layer: state.layer,
        project: state.project,
        projectEvents: state.projectEvents,
        webSocketHubs: state.webSocketHubs
    };
};

const mapDispatchToProps = {
    setActionHistory
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectEventLinkingForm);