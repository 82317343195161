import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// Composants
import LoginForm from '../Forms/Users/LoginForm';
import ExternalAuthenticationForm from '../Forms/Users/ExternalAuthenticationForm';
// Librairies
import { connect } from 'react-redux';
import { setProject } from '../../actionCreators/projectsActions';
import i18n from '../../locales/i18n';
import { isMobileOnly } from 'react-device-detect';
// Ressources
import logoMonoWhite from '../../resources/images/logo-mono-white.jpg';
// Semantic UI
import { Divider, Grid, Header, Image, Segment } from 'semantic-ui-react'
// Styles;
import 'semantic-ui-css/semantic.min.css';
import AzureADLogin from '../Forms/Users/AzureADLogin';

class Login extends Component {
    render() {
        const { isOnline } = this.props;

        return (
            <Grid textAlign='center' style={{ margin: '0', flexGrow: 1 }} verticalAlign='middle' className='grality-background'>
                <Grid.Column style={{ maxWidth: 650 }}>
                    <Header as='h2' className='text-light' textAlign='center'>
                        <Image style={{ borderRadius: '30px', marginTop: 0, marginRight: '3px' }} src={logoMonoWhite} /> {i18n.t("Connexion")}
                    </Header>
                    <Segment style={{ display: 'flex', flexDirection: 'column' }}>
                        <LoginForm {...this.props} history={this.props.history} logUser={this.props.logUser} changeActiveItem={this.props.changeActiveItem} />
                        {isOnline &&
                            <ExternalAuthenticationForm
                                title={i18n.t("S'authentifier avec")} history={this.props.history}
                                logUser={this.props.logUser} changeActiveItem={this.props.changeActiveItem}
                            />}
                    </Segment>
                    <Segment>
                        <div>{i18n.t("Pas encore inscrit ?")} <Link to='/signup'>{i18n.t("S'inscrire")}</Link></div>
                        <div>{i18n.t("Mot de passe oublié") + ' ?'} <Link to='/forgotPassword'>{i18n.t("Réinitialiser")}</Link></div>
                    </Segment>
                </Grid.Column>
            </Grid>
        );
    }

    componentDidMount = () => {
        this.props.changeActiveItem('login');
        this.props.setProject(null);
    }

    componentDidUpdate = () => { // Si l'utilisateur est déjà connecté, on l'empêche d'accéder au composant login
        if (this.props.logged !== false) {
            this.props.changeActiveItem('home');
            this.props.history.push('/');
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline
    };
}

const mapDispatchToProps = {
    setProject
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);