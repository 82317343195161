import React, { Component } from 'react';
// Composants
import DatePicker from '../../Utils/DatePicker';
import { Button, Form, Grid, Message, Select, TextArea } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import i18n from '../../../locales/i18n';
import { connect } from 'react-redux';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { faCheck, faLink, faTimes, faWarning } from '@fortawesome/pro-solid-svg-icons';
// Utils
import FormattersUtil from '../../../utils/FormattersUtil';
import DatesUtil from '../../../utils/DatesUtil';
import EventsService from '../../../services/EventsService';
import { setProjectEvents } from '../../../actionCreators/projectsActions';
import { isSameDay } from 'date-fns';

const initialNewProjectEvent = {
    propertiesId: null,
    event: null,
    eventId: 0,
    projectId: 0,
    date: null,
    comment: null
}

const initialError = {
    hidden: true,
    messages: [],
    evnetId: false,
    date: false,
}

class ProjectEventCreationForm extends Component {
    state = {
        eventList: [],
        newProjectEvent: { ...initialNewProjectEvent, projectId: this.props.project.id },
        error: initialError,
        isLoading: false
    }

    render() {
        const { selectElementsToLink } = this.props;
        const { newProjectEvent, eventList, error, isLoading } = this.state;
        const isValid = !newProjectEvent.eventId || !newProjectEvent.date ? false : true;

        const category = this.props.category.toLowerCase() === 'arbre' ? i18n.t("arbres")
            : this.props.category.toLowerCase() === 'espace vert' ? i18n.t("espaces verts")
                : i18n.t("mobiliers");

        return (
            <Form onSubmit={this.handleSubmit} loading={isLoading} error>
                <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                    <Grid.Row columns={isMobileOnly ? 1 : isTablet ? 2 : 3} style={{ padding: 0, marginBottom: '5px' }}>
                        <Grid.Column>
                            <h3>{i18n.t("Nouvel évènement ({{category}})", { category })}</h3>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={isMobileOnly ? 1 : 2} style={{ padding: 0 }}>
                        <Grid.Column computer={5} tablet={5} mobile={16}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field
                                        control={Select} options={eventList} search={FormattersUtil.searchList} clearable
                                        name='eventId' value={newProjectEvent.eventId || ''}
                                        label={`${i18n.t("Évènement")} : `} placeholder={i18n.t("Sélectionnez un évènement")}
                                        selectOnBlur={false} error={error.evnetId} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                        onChange={this.handleEventChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <DatePicker
                                        label={`${i18n.t("Date")} :`} name='date' value={newProjectEvent.date}
                                        error={error.date} onChange={this.handleDateChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid.Column>
                        <Grid.Column computer={11} tablet={11} mobile={16}>
                            <Form.Field
                                style={{ minHeight: isMobileOnly ? '200px' : '100px' }}
                                control={TextArea} placeholder={i18n.t("Ajouter un commentaire")} disabled={selectElementsToLink && !newProjectEvent.projectEventElements?.length}
                                name='comment' value={(!selectElementsToLink || newProjectEvent.projectEventElements?.length) ? newProjectEvent.comment || '' : ''} label={`${i18n.t("Commentaire")} : `}
                                onChange={this.handleChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {selectElementsToLink &&
                        <Grid.Row>
                            <Grid.Column>
                                <Button className='form-button' type='button' color='blue' disabled={!newProjectEvent.eventId} onClick={() => selectElementsToLink(newProjectEvent)} style={{ marginTop: '10px' }}>
                                    <FontAwesomeIcon icon={faLink} style={{ marginRight: '10px' }} />{i18n.t("Sélectionner les éléments ({{nbElements}})", { nbElements: newProjectEvent.projectEventElements?.length ?? 0 })}
                                </Button>
                            </Grid.Column>
                        </Grid.Row>}
                    {!error.hidden &&
                        <Grid.Row>
                            <Grid.Column>
                                <Message
                                    error hidden={error.hidden} style={{ textAlign: 'left' }}
                                    header='Erreur' list={error.messages}
                                />
                            </Grid.Column>
                        </Grid.Row>}
                    <Grid.Row>
                        <Grid.Column style={!isMobileOnly ? { display: 'flex', gap: '5px' } : {}}>
                            <Button className='form-button' type='button' color='red' onClick={this.props.cancel}>
                                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                            </Button>
                            <Button className='form-button' type='submit' color='green' id='rigRXCCA' disabled={!isValid || isLoading}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                            </Button>
                            {this.checkIfElementExists() &&
                                <div className='form-button' style={{ display: 'flex', alignItems: 'center', justifyContent: isMobileOnly ? 'center' : 'flex-start', borderRadius: '5px', backgroundColor: 'var(--yellow-100)', padding: isMobileOnly ? '10px' : '0 10px' }}>
                                    <FontAwesomeIcon icon={faWarning} style={{ marginRight: '10px' }} />
                                    {i18n.t("Un évènement similaire existe déjà")}
                                </div>}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        );
    }

    componentDidMount = () => {
        const { projectEvent } = this.props;
        if (this.state.eventList.length === 0) this.createEventList(); // Chargement de la liste des actions disponibles
        if (projectEvent) this.setState({ newProjectEvent: projectEvent });
    }

    componentDidUpdate = (prevProps) => {
        const eventToAdd = this.props.eventToAdd;
        const prevEventToAdd = prevProps.eventToAdd;

        if (eventToAdd && prevEventToAdd && eventToAdd.id !== prevEventToAdd.id)
            this.setState(prevState => ({
                newProjectEvent: {
                    ...prevState.newProjectEvent,
                    event: eventToAdd,
                    eventId: eventToAdd.id
                }
            }));

        if (prevProps.projectEvent && this.props.projectEvent && (JSON.stringify(prevProps.projectEvent.projectEventElements) !== JSON.stringify(this.props.projectEvent.projectEventElements)))
            this.setState(prevState => ({ newProjectEvent: { ...prevState.newProjectEvent, projectEventElements: this.props.projectEvent.projectEventElements } }));
        if (prevProps.projectEvent && !this.props.projectEvent) this.setState({ newProjectEvent: initialNewProjectEvent });
        if (prevProps.category !== this.props.category) this.createEventList();
        if (prevProps.date !== this.props.date) this.setState(prevState => ({ newProjectEvent: { ...prevState.newProjectEvent, date: this.props.date } }));
    }

    createEventList = () => {
        let events = this.props.events; // TODO .filter(x => x.category === this.props.category)
        events = events.map(x => { // On map les évènements pour qu'elles soient utilisables par le Select
            return { text: <span>{x.label}</span>, rawText: x.label, value: x.id }
        });

        events = FormattersUtil.sortByAlphabeticalOrder(events);
        this.setState({ eventList: events }); // Options du Select
    }

    handleChange = (_, { name, value }) => {
        if (value === '') value = 0;
        this.setState(prevState => ({ newProjectEvent: { ...prevState.newProjectEvent, [name]: value } }));
    }

    handleEventChange = (_, { name, value }) => {
        const event = this.props.events.find(event => event.id === value);
        this.setState(prevState => ({
            newProjectEvent: { ...prevState.newProjectEvent, [name]: value, event },
            error: { ...prevState.error, [name]: false }
        }));
    }

    handleDateChange = (_, { name, value }) => {
        let error = false; let hidden = true;
        let messages = [];
        const addError = (message) => {
            value = null; error = true; hidden = false;
            messages.push(message);
        }

        if (value && value.getFullYear().toString().length !== 4)
            addError(i18n.t("Veuillez saisir une date valide"));

        this.setState({
            newProjectEvent: { ...this.state.newProjectEvent, [name]: value },
            error: { ...this.state.error, [name]: error, messages, hidden }
        });
    }

    handleSubmit = () => {
        const stateNewProjectEvent = this.state.newProjectEvent;
        let flag = true, eventError = false, dateError = false, messagesError = [];
        if (!stateNewProjectEvent.eventId) {
            messagesError = [...(messagesError || []), i18n.t("Veuillez sélectionner un évènement")];
            eventError = true;
            flag = false;
        }

        if (stateNewProjectEvent.date?.getFullYear().toString().length !== 4) {
            messagesError = [...(messagesError || []), i18n.t("Veuillez saisir une ddate valide")];
            dateError = true;
            flag = false;
        }

        if (flag) {
            const projectEventElements = stateNewProjectEvent.projectEventElements?.length
            ? stateNewProjectEvent.projectEventElements
            : this.props.layer?.length ? [
                {
                    elementType: this.props.layer[0].feature.properties.category,
                    elementId: this.props.layer[0].feature.id,
                    comment: stateNewProjectEvent.comment,
                }
            ] : [];

            projectEventElements.forEach(pee => pee.comment = stateNewProjectEvent.comment);

            const projectEvent = {
                projectId: stateNewProjectEvent.projectId,
                eventId: stateNewProjectEvent.eventId,
                event: stateNewProjectEvent.event,
                date: DatesUtil.getUTCDate(stateNewProjectEvent.date),
                projectEventElements
            };

            this.setState({ isLoading: true });

            EventsService.addProjectEvent(projectEvent, projectEvent.projectId)
                .then(response => {
                    if (response) {
                        response.event = this.props.events.find(x => x.id === response.eventId);
                        this.props.setProjectEvents([...this.props.projectEvents, response]);
                        this.props.submit(response);
                    }
                    this.setState({
                        newProjectEvent: { ...initialNewProjectEvent, projectId: this.props.project.id },
                        error: initialError,
                        isLoading: false
                    });
                });
        } else {
            this.setState(prevState => ({ // On affiche les messages d'erreurs correspondants
                error: {
                    ...prevState.error,
                    hidden: false,
                    messages: messagesError,
                    eventId: eventError,
                    date: dateError
                }
            }));
        }
    }

    checkIfElementExists = () => {
        const { projectEvents } = this.props;
        const { newProjectEvent } = this.state;
        const { eventId, date } = newProjectEvent;

        if (!eventId || !date) return false;
        return projectEvents.find(pe => pe.eventId === eventId && isSameDay(new Date(pe.date), date) && isSameDay(new Date(pe.date), date));
    }
}

const mapStateToProps = (state) => {
    return {
        events: state.events,
        projectEvents: state.projectEvents,
        project: state.project,
        webSocketHubs: state.webSocketHubs,
        layer: state.layer
    };
};

const mapDispatchToProps = {
    setProjectEvents
}


export default connect(mapStateToProps, mapDispatchToProps)(ProjectEventCreationForm);