export const setEssences = (essences) => async (dispatch, getState) => {
  dispatch({ type: 'SET_ESSENCES', data: essences });
  return Promise.resolve();
};

export const setCoverTypes = (essences) => async (dispatch, getState) => {
  dispatch({ type: 'SET_COVER_TYPES', data: essences });
  return Promise.resolve();
};

export const setInteractions = (interactions) => async (dispatch, getState) => {
  dispatch({ type: 'SET_INTERACTIONS', data: interactions });
  return Promise.resolve();
};

export const setEvents = (events) => async (dispatch, getState) => {
  dispatch({ type: 'SET_EVENTS', data: events });
  return Promise.resolve();
};

export const setMicroHabitats = (microHabitatss) => async (dispatch, getState) => {
  dispatch({ type: 'SET_MICROHABITATS', data: microHabitatss });
  return Promise.resolve();
};

export const setRootSymptoms = (rootSymptoms) => async (dispatch, getState) => {
  dispatch({ type: 'SET_ROOT_SYMPTOMS', data: rootSymptoms });
  return Promise.resolve();
};

export const setCollarSymptoms = (collarSymptoms) => async (dispatch, getState) => {
  dispatch({ type: 'SET_COLLAR_SYMPTOMS', data: collarSymptoms });
  return Promise.resolve();
};

export const setTrunkSymptoms = (trunkSymptoms) => async (dispatch, getState) => {
  dispatch({ type: 'SET_TRUNK_SYMPTOMS', data: trunkSymptoms });
  return Promise.resolve();
};

export const setBranchSymptoms = (branchSymptoms) => async (dispatch, getState) => {
  dispatch({ type: 'SET_BRANCH_SYMPTOMS', data: branchSymptoms });
  return Promise.resolve();
};

export const setLeafSymptoms = (leafSymptoms) => async (dispatch, getState) => {
  dispatch({ type: 'SET_LEAF_SYMPTOMS', data: leafSymptoms });
  return Promise.resolve();
};

export const setPathogens = (pathogens) => async (dispatch, getState) => {
  dispatch({ type: 'SET_PATHOGENS', data: pathogens });
  return Promise.resolve();
};

export const setPests = (pests) => async (dispatch, getState) => {
  dispatch({ type: 'SET_PESTS', data: pests });
  return Promise.resolve();
};

export const setEpiphytes = (epiphytes) => async (dispatch, getState) => {
  dispatch({ type: 'SET_EPIPHYTES', data: epiphytes });
  return Promise.resolve();
};

export const setVigors = (vigors) => async (dispatch, getState) => {
  dispatch({ type: 'SET_VIGORS', data: vigors });
  return Promise.resolve();
};

export const setHealthReviews = (healthReviews) => async (dispatch, getState) => {
  dispatch({ type: 'SET_HEALTH_REVIEWS', data: healthReviews });
  return Promise.resolve();
};

export const setOntogenicStages = (ontogenicStages) => async (dispatch, getState) => {
  dispatch({ type: 'SET_ONTOGENIC_STAGES', data: ontogenicStages });
  return Promise.resolve();
};

export const setRisks = (risks) => async (dispatch, getState) => {
  dispatch({ type: 'SET_RISKS', data: risks });
  return Promise.resolve();
};

export const setTippingRisks = (tippingRisks) => async (dispatch, getState) => {
  dispatch({ type: 'SET_TIPPING_RISKS', data: tippingRisks });
  return Promise.resolve();
};

export const setOrganCalibers = (organCalibers) => async (dispatch, getState) => {
  dispatch({ type: 'SET_ORGAN_CALIBERS', data: organCalibers });
  return Promise.resolve();
};

export const setTargets = (targets) => async (dispatch, getState) => {
  dispatch({ type: 'SET_TARGETS', data: targets });
  return Promise.resolve();
};

export const setTreePorts = (treePorts) => async (dispatch, getState) => {
  dispatch({ type: 'SET_TREE_PORTS', data: treePorts });
  return Promise.resolve();
};

export const setPlantationTypes = (plantationTypes) => async (dispatch, getState) => {
  dispatch({ type: 'SET_PLANTATION_TYPES', data: plantationTypes });
  return Promise.resolve();
};

export const setPlantationCoefficients = (plantationCoefficients) => async (dispatch, getState) => {
  dispatch({ type: 'SET_PLANTATION_COEFFICIENTS', data: plantationCoefficients });
  return Promise.resolve();
};

export const setSituationCoefficients = (situationCoefficients) => async (dispatch, getState) => {
  dispatch({ type: 'SET_SITUATION_COEFFICIENTS', data: situationCoefficients });
  return Promise.resolve();
};

export const setPatrimonialCoefficients = (patrimonialCoefficients) => async (dispatch, getState) => {
  dispatch({ type: 'SET_PATRIMONIAL_COEFFICIENTS', data: patrimonialCoefficients });
  return Promise.resolve();
};