import React, { Component } from 'react';
// Composants
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import DataGrid, { Row as GridRow } from 'react-data-grid';
/*     Editors     */
import BooleanEditor from '../Administration/Editors/BooleanEditor';
import DateEditor from '../Administration/Editors/DateEditor';
import DropDownEditor from '../Administration/Editors/DropDownEditor';
import NumberEditor from '../Administration/Editors/NumberEditor';
import TextEditor from '../Administration/Editors/TextEditor';
/*     Filters     */
import NumberFilter from '../Tables/Filters/NumberFilter';
import DropDownFilter from '../Tables/Filters/DropDownFilter';
import TextFilter from '../Tables/Filters/TextFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dimmer, Loader, Form, Button, Menu, Input, Grid, Select, Icon } from 'semantic-ui-react';
// Librairies
import i18n from '../../locales/i18n';
import { isMobileOnly } from 'react-device-detect';
import { faCheck, faFlowerTulip, faTimes, faTree } from '@fortawesome/pro-solid-svg-icons';
import { connect } from 'react-redux';
import { setActions } from '../../actionCreators/projectsActions';
// Services
import PriceListsService from '../../services/PriceListsService';
// Styles
import '../../styles/react-contextmenu.css';
import '../../styles/rdg.css';
// Utils
import FormattersUtil from '../../utils/FormattersUtil';
import StylesUtil from '../../utils/StylesUtil';
import BooleanFilter from './Filters/BooleanFilter';

const initialFilters = {
    label: '',
    isPriceFixed: '',
    price1: '',
    price2: '',
    price3: '',
    price4: '',
    price5: '',
    price6: '',
    calculationUnit: ''
};

class PriceListTable extends Component {
    state = {
        priceList: {
            label: '',
            currencyId: 1,
            prices: []
        },
        category: 'Arbre',
        labelError: false,
        data: {
            columns: [],
            rows: []
        },
        elementsToModify: [],
        modificationsHistory: [],
        modificationsHistoryIndex: 0,
        rowIndex: 0,
        sortColumn: null,
        sortDirection: 'NONE',
        enableFilterRow: false,
        filters: initialFilters,
        isLoading: true,
        isUpdating: false
    }

    render() {
        const { isCreating } = this.props;
        const {
            data, modificationsHistory, modificationsHistoryIndex, priceList, labelError, category,
            sortColumn, sortDirection, enableFilterRow, filters, rowIndex, selectedRow, selectedColumn, isUpdating, isLoading
        } = this.state;
        const rows = this.getFilteredRows();

        const currencyOptions = this.props.currencies.map(currency => ({ text: `${currency.code}${currency.symbol ? ` (${currency.symbol})` : ''}`, value: currency.id }))

        return (
            <>
                <Dimmer active={isUpdating} style={StylesUtil.getMapStyles().dimmerStyle}>
                    <Loader content={isCreating ? i18n.t("Ajout de la liste de prix en cours...") : i18n.t("Mise à jour de la liste de prix en cours...")} />
                </Dimmer>
                {data?.columns &&
                    <>
                        <Form style={{ marginBottom: '10px' }}>
                            <Grid>
                                <Grid.Column width={8}>
                                    <Form.Field
                                        control={Input} label={`${i18n.t("Libellé")}* :`} placeholder='Ex : Tarifs 1' style={{ marginBottom: isMobileOnly && '5px' }}
                                        name='label' value={priceList?.label || ''} onChange={this.handleLabelChange} error={labelError}
                                    />
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Form.Field
                                        control={Select} label={`${i18n.t("Devise")}* :`} selectOnBlur={false}
                                        name='currencyId' options={currencyOptions} value={priceList.currencyId} onChange={this.handleCurrencyChange}
                                    />
                                </Grid.Column>
                            </Grid>
                        </Form>
                        <Menu attached='top' tabular style={{ margin: 0, flexWrap: 'wrap' }}>
                            <Menu.Item style={{ paddingTop: 0 }}>
                                <Form.Field
                                    control={Input} type='number' step='1' placeholder={i18n.t("Numéro de ligne")}
                                    value={rowIndex || ''} onChange={(e, { value }) => this.setState({ rowIndex: value })}
                                />
                                <Button
                                    className='button--secondary' icon='arrow down' style={{ marginLeft: '10px' }}
                                    onClick={() => { if (this.gridRef.current) this.gridRef.current.scrollToRow(rowIndex - 1) }}
                                />
                            </Menu.Item>
                            <Menu.Item style={{ paddingTop: 0 }}>
                                <Button.Group>
                                    <Button
                                        title={enableFilterRow ? i18n.t("Désactiver les filtres") : i18n.t("Activer les filtres")}
                                        className={enableFilterRow ? 'button--secondary' : null} color={!enableFilterRow ? 'grey' : null} icon='filter'
                                        onClick={this.toggleFilters}
                                    />
                                    <Button
                                        title={i18n.t("Réinitialiser les filtres")} className='button--secondary' icon='dont'
                                        onClick={this.clearFilters} disabled={!this.areFiltersApplied()}
                                    />
                                    <>
                                        <Button
                                            title={i18n.t("Annuler la dernière modification")} className='button--secondary' icon='undo'
                                            onClick={this.restorePreviousModification} disabled={modificationsHistoryIndex < 1}
                                        />
                                        <Button
                                            title={i18n.t("Rétablir la modification suivante")} className='button--secondary' icon='redo'
                                            disabled={modificationsHistoryIndex === modificationsHistory.length}
                                            onClick={this.restoreNextModification}
                                        />
                                    </>
                                </Button.Group>
                            </Menu.Item>
                            <Menu.Item style={{ paddingTop: 0 }}>
                                <Button.Group>
                                    <Button
                                        title={i18n.t("Arbres")} className='button--secondary' style={{ padding: '11px' }} disabled={category === 'Arbre'}
                                        onClick={() => { this.setState({ category: 'Arbre' }, () => this.loadData(this.props.priceList?.prices, data?.rows)); }}
                                    >
                                        <FontAwesomeIcon icon={faTree} style={{ height: '12px' }} />
                                    </Button>
                                    <Button
                                        title={i18n.t("Espaces verts")} className='button--secondary' style={{ padding: '11px' }} disabled={category === 'Espace vert'}
                                        onClick={() => { this.setState({ category: 'Espace vert' }, () => this.loadData(this.props.priceList?.prices, data?.rows)); }}
                                    >
                                        <FontAwesomeIcon icon={faFlowerTulip} style={{ height: '12px' }} />
                                    </Button>
                                </Button.Group>
                            </Menu.Item>
                            <Menu.Item style={{ paddingTop: 0 }}>
                                <Button
                                    title={i18n.t("Exporter les données")} className='button--secondary' icon='download' style={{ position: 'relative' }}
                                    disabled={!this.props.isOnline} onClick={() => this.props.exportPriceListAsXLSX(priceList, { exportTrees: category === 'Arbre', exportGreenSpaces: category === 'Espace vert' })}
                                />
                            </Menu.Item>
                            <Menu.Item style={{ width: '100%', padding: '1px 0', border: 'none', height: '32px' }} className='full-width-input-item'>
                                {(selectedColumn?.editable === true || (typeof selectedColumn?.editable === 'function' && selectedColumn.editable(selectedRow))) ?
                                    selectedColumn.editor({
                                        row: selectedRow, column: selectedColumn,
                                        onRowChange: this.handleRowChange,
                                        onClose: (commitChanges) => { if (commitChanges) this.handleRowChange(selectedRow) }
                                    })
                                    : <Input disabled placeholder={i18n.t("Sélectionnez une cellule éditable")} />}
                                <div style={{ position: 'absolute', width: '150px', right: 0, paddingRight: '10px', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                    <span style={{ borderLeft: 'solid 1px var(--grey-100)', paddingLeft: '10px', fontWeight: 'bold' }}>{rows?.length}</span>
                                    {rows?.length !== data?.rows?.length && <span style={{ marginLeft: '4px' }}>{` / ${data?.rows?.length}`}</span>}
                                </div>
                            </Menu.Item>
                        </Menu>
                        <DataGrid
                            ref={this.gridRef} className={this.props.isDarkTheme ? 'rdg-dark' : 'rdg-light'}
                            style={{ flex: '1 1 auto' }}
                            columns={data.columns}
                            rows={rows} rowRenderer={this.rowRenderer}
                            defaultColumnOptions={{ sortable: true, resizable: true }}
                            cellNavigationMode='LOOP_OVER_ROW'
                            sortColumn={sortColumn} sortDirection={sortDirection}
                            onSort={this.handleSort} onFill={this.handleFill} enableFilterRow={enableFilterRow}
                            filters={filters} onFiltersChange={filters => this.setState({ filters: filters })}
                            emptyRowsRenderer={() => (<div style={{ textAlign: 'center' }}>
                                <span>{isLoading ? i18n.t("Chargement en cours...") : i18n.t("Aucun résultat trouvé")}</span>
                            </div>)}
                            onSelectedCellChange={({ idx, rowIdx }) => this.setState({ selectedRow: rows[rowIdx], selectedColumn: data.columns[idx] })}
                            onRowsChange={this.handleRowsChange}
                        />
                        <ContextMenu id='grid-context-menu'>
                            <MenuItem onClick={this.resetRow}>{i18n.t("Réinitialiser")}</MenuItem>
                        </ContextMenu>
                        <Grid.Row style={{ paddingTop: '14px' }}>
                            <Grid.Column>
                                <Button type='button' className='form-button' color='red' onClick={this.props.cancel}>
                                    <FontAwesomeIcon icon={faTimes} style={{ marginLeft: 0, marginRight: '10px' }} />{i18n.t("Annuler")}
                                </Button>
                                <Button type='button' className='form-button' color='green' disabled={!this.props.isOnline} onClick={() => this.handleSubmit(false)}>
                                    <FontAwesomeIcon icon={faCheck} style={{ marginLeft: 0, marginRight: '10px' }} />{i18n.t("Valider")}
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </>}
            </>
        );
    }

    componentDidMount = () => {
        this.gridRef = React.createRef();
        this.setState(prevState => ({ priceList: { ...prevState.priceList, ...this.props.priceList, organizationId: this.props.activeOrganization.id } }), () => {
            this.loadData(this.props.priceList?.prices);
        });

        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount = () => document.removeEventListener('keydown', this.handleKeyDown);

    rowRenderer = (props) => {
        return (
            <ContextMenuTrigger id='grid-context-menu' collect={() => ({ rowIdx: props.rowIdx })}>
                <GridRow {...props} />
            </ContextMenuTrigger>
        );
    }

    updateSelectedRow = (row) => this.setState({ selectedRow: row });

    handleRowChange = (row) => {
        const { data, selectedRow } = this.state;
        if (selectedRow) {
            const updatedRows = [...data.rows];
            const index = updatedRows.findIndex(row => row.id === selectedRow.id);
            updatedRows[index] = row;
            this.handleRowsChange(updatedRows);
        }
    };

    handleRowsChange = (newRows) => {
        this.setState(prevState => {
            let rows = prevState.data.rows;
            newRows.forEach(newRow => {
                const index = rows.findIndex(row => row.id === newRow.id);
                rows[index] = newRow;
            });
            return { data: { columns: prevState.data.columns, rows: rows } };
        });
    }

    loadData = (prices, rows) => {
        const { category } = this.state;

        let data = {
            columns: [],
            rows: [...(rows || [])]
        };

        const getEditor = (type, row, column, onRowChange, onClose, config = {}) => {
            const props = {
                elements: this.state.elements, elementsToModify: this.state.elementsToModify, ...config,
                row: row, column: column, onRowChange: onRowChange, onClose: onClose, updateSelectedRow: this.updateSelectedRow,
                pushToModificationsHistory: this.pushToModificationsHistory, changeElementsToModify: this.changeElementsToModify
            };
            switch (type) {
                case 'boolean': return <BooleanEditor {...props} />;
                case 'date': return <DateEditor {...props} />;
                case 'dropdown': return <DropDownEditor {...props} />;
                case 'number': return <NumberEditor {...props} />;
                case 'text': return <TextEditor {...props} />;
                default: return;
            }
        }

        // Définition des colonnes
        data.columns = [
            {
                name: i18n.t("Libellé"), key: 'label', width: 250, sortable: true,
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Prix") + (category === 'Arbre' ? ' (< 5m) ou fixe' : ''), key: 'price1', width: 150,
                sortable: true, editable: true,
                formatter: (props) => {
                    const currency = this.props.currencies.find(currency => currency.id === this.state.priceList.currencyId);
                    return props.row.price1 + (currency?.symbol || currency?.code || '');
                },
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: 0, step: 0.01 })
            }
        ];

        const units = [{ label: i18n.t("Unité"), value: 'unit' }, { label: i18n.t("Mètre carré"), value: 'sqmt' }];
        if (category === 'Arbre') {
            data.columns.splice(1, 0, {
                name: i18n.t("Prix fixe"), key: 'isPriceFixed', width: 80,
                sortable: true, editable: true,
                formatter: (props) => <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                    {props.row.isPriceFixed === i18n.t("Oui")
                        ? <Icon name='check' color='green' />
                        : <Icon name='times' color='red' />}
                </div>,
                filterRenderer: (props) => <BooleanFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('boolean', row, column, onRowChange, onClose),
                editorOptions: { editOnClick: true }
            });

            data.columns.push(
                {
                    name: `${i18n.t("Prix")} (5-10m)`, key: 'price2', width: 110,
                    sortable: true, editable: (props) => props.isPriceFixed !== i18n.t("Oui"),
                    formatter: (props) => {
                        const currency = this.props.currencies.find(currency => currency.id === this.state.priceList.currencyId);
                        return props.row.isPriceFixed === i18n.t("Oui")
                            ? <div className='disabled'></div>
                            : props.row.price2 + (currency?.symbol || currency?.code || '');
                    },
                    filterRenderer: (props) => <NumberFilter p={props} />,
                    editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: 0, step: 0.01 })
                },
                {
                    name: `${i18n.t("Prix")} (10-15m)`, key: 'price3', width: 110,
                    sortable: true, editable: (props) => props.isPriceFixed !== i18n.t("Oui"),
                    formatter: (props) => {
                        const currency = this.props.currencies.find(currency => currency.id === this.state.priceList.currencyId);
                        return props.row.isPriceFixed === i18n.t("Oui")
                            ? <div className='disabled'></div>
                            : props.row.price3 + (currency?.symbol || currency?.code || '');
                    },
                    filterRenderer: (props) => <NumberFilter p={props} />,
                    editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: 0, step: 0.01 })
                },
                {
                    name: `${i18n.t("Prix")} (15-20m)`, key: 'price4', width: 110,
                    sortable: true, editable: (props) => props.isPriceFixed !== i18n.t("Oui"),
                    formatter: (props) => {
                        const currency = this.props.currencies.find(currency => currency.id === this.state.priceList.currencyId);
                        return props.row.isPriceFixed === i18n.t("Oui")
                            ? <div className='disabled'></div>
                            : props.row.price4 + (currency?.symbol || currency?.code || '');
                    },
                    filterRenderer: (props) => <NumberFilter p={props} />,
                    editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: 0, step: 0.01 })
                },
                {
                    name: `${i18n.t("Prix")} (20-25m)`, key: 'price5', width: 110,
                    sortable: true, editable: (props) => props.isPriceFixed !== i18n.t("Oui"),
                    formatter: (props) => {
                        const currency = this.props.currencies.find(currency => currency.id === this.state.priceList.currencyId);
                        return props.row.isPriceFixed === i18n.t("Oui")
                            ? <div className='disabled'></div>
                            : props.row.price5 + (currency?.symbol || currency?.code || '');
                    },
                    filterRenderer: (props) => <NumberFilter p={props} />,
                    editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: 0, step: 0.01 })
                },
                {
                    name: `${i18n.t("Prix")} (> 25m)`, key: 'price6', width: 110,
                    sortable: true, editable: (props) => props.isPriceFixed !== i18n.t("Oui"),
                    formatter: (props) => {
                        const currency = this.props.currencies.find(currency => currency.id === this.state.priceList.currencyId);
                        return props.row.isPriceFixed === i18n.t("Oui")
                            ? <div className='disabled'></div>
                            : props.row.price6 + (currency?.symbol || currency?.code || '');
                    },
                    filterRenderer: (props) => <NumberFilter p={props} />,
                    editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: 0, step: 0.01 })
                }
            );
        } else {
            data.columns.push({
                name: i18n.t("Méthode de calcul"), key: 'calculationUnit', width: 180, sortable: true, editable: true,
                filterRenderer: p => <DropDownFilter p={p} propertyOptions={units} isNullable />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('dropdown', row, column, onRowChange, onClose, { options: units }),
                editorOptions: { editOnClick: true }
            });
        }

        if (!rows) {
            data.rows = JSON.parse(JSON.stringify(this.props.actions.map(action => ({
                ...action,
                isPriceFixed: action.isPriceFixed ? i18n.t("Oui") : i18n.t("Non"),
                calculationUnit: units.find(unit => unit.value === action.calculationUnit)?.label || null
            }))));
            const elements = JSON.parse(JSON.stringify(this.props.actions.map(({ id, isPriceFixed, price1, price2, price3, price4, price5, price6, calculationUnit }) => (
                { id, isPriceFixed, price1, price2, price3, price4, price5, price6, calculationUnit }
            ))));

            if (prices) {
                prices.forEach(price => {
                    const row = data.rows.find(row => row.id === price.actionId);
                    if (row) {
                        row.priceId = price.id;
                        row.isPriceFixed = price.isPriceFixed ? i18n.t("Oui") : i18n.t("Non");
                        for (let i = 1; i <= 6; i++) row['price' + i] = price['price' + i];
                        row.calculationUnit = units.find(unit => unit.value === price.calculationUnit)?.label;
                    }
                    const element = elements.find(element => element.id === price.actionId);
                    if (element) {
                        element.priceId = price.id;
                        element.isPriceFixed = price.isPriceFixed;
                        for (let i = 1; i <= 6; i++) element['price' + i] = price['price' + i];
                        element.calculationUnit = price.calculationUnit;
                    }
                })
            }

            const initialOrder = data.rows.map(row => row.id);
            this.setState({ elements, initialOrder });
        }

        this.setState({ data, isLoading: false });
    }

    // Filtres
    areFiltersApplied = () => {
        if (!this.state.enableFilterRow) return false;
        let filtersApplied = false;
        for (const property in this.state.filters)
            if (this.state.filters[property]) filtersApplied = true;
        return filtersApplied;
    }

    toggleFilters = () => this.setState(prevState => ({ enableFilterRow: !prevState.enableFilterRow }));
    clearFilters = () => this.setState({ filters: initialFilters });

    getFilteredRows = () => {
        const filters = this.state.filters;
        let rows = [...this.state.data.rows];

        const $ = (str) => FormattersUtil.getNormalizedString(str);
        return rows.filter(r => {
            return r.categories.includes(this.state.category) && (
                !this.state.enableFilterRow || (
                    (filters.label ? $(r.label)?.includes($(filters.label)) : true)
                    && (filters.isPriceFixed ? r.isPriceFixed === filters.isPriceFixed : true)
                    && (filters.price1 ? r.price1 === Number(filters.price1) : true)
                    && (filters.price2 ? r.price2 === Number(filters.price2) : true)
                    && (filters.price3 ? r.price3 === Number(filters.price3) : true)
                    && (filters.price4 ? r.price4 === Number(filters.price4) : true)
                    && (filters.price5 ? r.price5 === Number(filters.price5) : true)
                    && (filters.price6 ? r.price6 === Number(filters.price6) : true)
                    && (filters.calculationUnit ? r.calculationUnit === filters.calculationUnit : true)
                )
            );
        });
    }

    // Tri
    handleSort = (columnKey, direction) => this.setState({ sortColumn: columnKey, sortDirection: direction }, this.sortRows);
    sortRows = () => {
        const sortDirection = this.state.sortDirection;
        let rows = [...this.state.data.rows];
        if (sortDirection === 'NONE') {
            for (let i = 0; i < this.state.initialOrder.length; i++) {
                let temp = rows[i];
                const index = rows.findIndex(row => row.id === this.state.initialOrder[i]);
                rows[i] = rows[index];
                rows[index] = temp;
            }

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: rows
                }
            }));
        } else {
            const sortColumn = this.state.sortColumn;
            if (['price1', 'price2', 'price3', 'price4', 'price5', 'price6'].includes(sortColumn))
                rows = rows.sort((a, b) => (a[sortColumn] || 0) - (b[sortColumn] || 0));
            else rows = rows.sort((a, b) => (a[sortColumn] || '').localeCompare(b[sortColumn] || ''));

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: sortDirection === 'DESC' ? rows.reverse() : rows
                }
            }));
        }
    }

    // Gestion des modifications
    changeElementsToModify = (elementsToModify) => this.setState({ elementsToModify: elementsToModify });
    changeElementsToModifyLocally = (id, property, value, elementsToModify) => {
        const index = elementsToModify.findIndex(element => element.id === id);
        elementsToModify[index][property] = value;
        return elementsToModify;
    }

    /*     Historique     */
    pushToModificationsHistory = (modifications) => {
        let modificationsHistory = this.state.modificationsHistory;
        modificationsHistory = modificationsHistory.slice(0, this.state.modificationsHistoryIndex);
        modificationsHistory.push(modifications);
        this.setState(prevState => ({
            modificationsHistory: modificationsHistory,
            modificationsHistoryIndex: prevState.modificationsHistoryIndex + 1
        }));
    }

    handleKeyDown = (e) => {
        if (e.ctrlKey && e.key === 'z') this.restorePreviousModification();
        else if (e.ctrlKey && e.key === 'y') this.restoreNextModification();
        else if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
            const { selectedColumn, selectedRow } = this.state;
            if (selectedColumn && selectedRow) navigator.clipboard.writeText(selectedRow[selectedColumn.key] || '');
        }
    }

    restorePreviousModification = () => {
        const index = this.state.modificationsHistoryIndex;
        const previousModification = this.state.modificationsHistory[index - 1];

        if (previousModification) {
            let data = {
                columns: [...this.state.data.columns],
                rows: [...this.state.data.rows]
            };
            let { elementsToModify } = this.state;

            let modificationsToCreate = [], previousElementsId = [], previousElementsProperties = [];
            previousModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;

                previousElementsId.push(elementId);
                previousElementsProperties.push(property);

                let row = data.rows.find(row => row.id === elementId);
                modificationsToCreate.push({ property: property, elementId: elementId, oldValue: row[property] });
                row[property] = oldValue;
                if (row.id === this.state.selectedRow?.id) this.updateSelectedRow(row);
                elementsToModify = this.changeElementsToModifyLocally(elementId, property, oldValue, elementsToModify);
            });

            let modificationsHistory;
            if (index === this.state.modificationsHistory.length) {
                modificationsHistory = this.state.modificationsHistory;
                modificationsHistory.push(modificationsToCreate);
            } else {
                let actualElementsId = [], actualElementsProperties = [];

                this.state.modificationsHistory[index].forEach(modification => actualElementsId.push(modification.elementId));
                this.state.modificationsHistory[index].forEach(modification => actualElementsProperties.push(modification.property));

                if (JSON.stringify(previousElementsId) !== JSON.stringify(actualElementsId)
                    || JSON.stringify(previousElementsProperties) !== JSON.stringify(actualElementsProperties)) {
                    modificationsHistory = this.state.modificationsHistory;
                    modificationsHistory[index] = modificationsToCreate;
                }
            }

            this.setState(prevState => ({
                data: data,
                elementsToModify: elementsToModify,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index - 1,
            }));
        }
    }

    restoreNextModification = () => {
        const index = this.state.modificationsHistoryIndex;
        const nextModification = this.state.modificationsHistory[index + 1];

        if (nextModification) {
            let data = {
                columns: [...this.state.data.columns],
                rows: [...this.state.data.rows]
            };
            let { elementsToModify } = this.state;

            let modificationsToCreate = [], nextElementsId = [], nextElementsProperties = [];
            nextModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;

                nextElementsId.push(elementId);
                nextElementsProperties.push(property);

                let row = data.rows.find(row => row.id === elementId);
                modificationsToCreate.push({ property: property, elementId: elementId, oldValue: row[property] });
                row[property] = oldValue;
                if (row.id === this.state.selectedRow?.id) this.updateSelectedRow(row);
                elementsToModify = this.changeElementsToModifyLocally(elementId, property, oldValue, elementsToModify);
            });

            let modificationsHistory, actualElementsId = [], actualElementsProperties = [];

            this.state.modificationsHistory[index].forEach(modification => actualElementsId.push(modification.elementId));
            this.state.modificationsHistory[index].forEach(modification => actualElementsProperties.push(modification.property));

            if (JSON.stringify(nextElementsId) !== JSON.stringify(actualElementsId)
                || nextElementsProperties !== actualElementsProperties) {
                modificationsHistory = this.state.modificationsHistory;
                modificationsHistory[index] = modificationsToCreate;
            }

            if (index === this.state.modificationsHistory.length - 2)
                modificationsHistory = this.state.modificationsHistory.slice(0, this.state.modificationsHistory.length - 1);

            this.setState(prevState => ({
                data: data,
                elementsToModify: elementsToModify,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index + 1
            }));
        }
    }

    /*     Remplissage     */
    handleFill = ({ columnKey, sourceRow, targetRows }) => {
        let elementsToModify = this.state.elementsToModify;

        let rowsUpdated = false;
        targetRows.forEach(row => {
            rowsUpdated = true;
            const value = sourceRow[columnKey];;
            const index = elementsToModify.findIndex(element => element.id === row.id);
            if (index === -1) {
                let element = JSON.parse(JSON.stringify(this.state.elements.find(element => element.id === row.id)));
                element[columnKey] = value;
                elementsToModify.push(element);
            } else elementsToModify[index][columnKey] = value;
        });
        if (rowsUpdated) this.changeElementsToModify(elementsToModify);

        let modificationsToCreate = [];
        const newRows = targetRows.map(row => {
            modificationsToCreate.push({ property: columnKey, elementId: row.id, oldValue: row[columnKey] });
            return { ...row, [columnKey]: sourceRow[columnKey] };
        });
        this.pushToModificationsHistory(modificationsToCreate);

        return newRows;
    }

    /*     Réinitialisation     */
    resetRow = (e, { rowIdx }) => {
        let elementsToModify = this.state.elementsToModify;
        let data = {
            columns: [...this.state.data.columns],
            rows: [...this.state.data.rows]
        };

        // On reset les données de la ligne sélectionnée
        let filteredRows = this.getFilteredRows();
        const index = elementsToModify.findIndex(element => element.id === filteredRows[rowIdx].id);
        if (index !== -1) {
            const initialElement = this.state.elements.find(element => filteredRows[rowIdx].id === element.id);
            elementsToModify[index] = JSON.parse(JSON.stringify(initialElement));
            const newDisplayedData = { ...filteredRows[rowIdx], ...this.getRowValue(initialElement) };
            let modificationsToCreate = [];
            let rowIndex = data.rows.findIndex(row => row.id === filteredRows[rowIdx].id);
            for (const property in newDisplayedData)
                if (data.rows[rowIndex][property] !== newDisplayedData[property])
                    modificationsToCreate.push({ property: property, elementId: data.rows[rowIndex].id, oldValue: data.rows[rowIndex][property] });
            if (modificationsToCreate.length > 0)
                this.pushToModificationsHistory(modificationsToCreate);
            data.rows[rowIndex] = newDisplayedData;
            this.updateSelectedRow(newDisplayedData);
        }

        this.setState({
            data: data,
            elementsToModify: elementsToModify
        });
    }

    getRowValue = (element) => {
        return {
            ...element,
            isPriceFixed: element.isPriceFixed ? i18n.t("Oui") : i18n.t("Non")
        };
    }

    handleLabelChange = (_, { value }) => {
        this.setState(prevState => ({
            priceList: { ...prevState.priceList, label: value },
            labelError: false
        }));
    };

    handleCurrencyChange = (_, { value }) => {
        this.setState(prevState => ({
            priceList: { ...prevState.priceList, currencyId: value },
            data: { columns: [...prevState.data.columns], rows: [...prevState.data.rows] }
        }));
    };

    handleSubmit = () => {
        const { isCreating } = this.props;
        let { elementsToModify, priceList } = this.state;

        if (!priceList.label?.trim().length) {
            this.setState({ labelError: true });
            return;
        }

        const elementsNotToModifyAnymore = [];
        elementsToModify.forEach(elementToModify => {
            if (JSON.stringify(elementToModify) === JSON.stringify(this.state.elements.find(element => element.id === elementToModify.id)))
                elementsNotToModifyAnymore.push(elementToModify);
            else {
                elementToModify.actionId = elementToModify.id;
                if (elementToModify.priceId) elementToModify.id = elementToModify.priceId;
                else elementToModify.id = 0;
            }
        });
        elementsToModify = elementsToModify.filter(element => !elementsNotToModifyAnymore.includes(element));

        this.setState({ isUpdating: true });
        if (isCreating) {
            priceList.prices = elementsToModify;

            PriceListsService.addPriceList(priceList).then(response => {
                this.setState({ isUpdating: false }, () => {
                    if (response) {
                        this.props.updatePriceListList(response);
                        this.props.cancel();
                    }
                });
            })
        } else {
            priceList.prices = [...priceList.prices.filter(price => !elementsToModify.find(el => el.actionId === price.actionId)), ...elementsToModify];

            PriceListsService.updatePriceList(priceList).then(response => {
                this.setState({ isUpdating: false }, () => {
                    if (response) {
                        this.props.updatePriceListList(response);
                        this.props.cancel();
                    }
                });
            });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme,
        activeOrganization: state.activeOrganization,
        actions: state.actions,
        currencies: state.currencies,
        isOnline: state.isOnline
    };
};

const mapDispatchToProps = {
    setActions
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceListTable);