import React, { Component } from 'react';
// Composants
import { GoogleLogin } from 'react-google-login';
import MicrosoftLogin from "react-microsoft-login";
import 'semantic-ui-css/semantic.min.css';
import { Button, Divider, Icon } from 'semantic-ui-react';
import UrbasenseButton from './UrbasenseButton';
import SSOButton from './SSOButton';
// Libraries
import Cookies from 'universal-cookie';
// Services
import AppSettings from '../../../AppSettings';
import UsersService from '../../../services/UsersService';
// Utils
import FormattersUtil from '../../../utils/FormattersUtil';

class ExternalAuthenticationForm extends Component {
    state = {
        isLoading: false,
        externalAuthentication: []
    }

    render() {
        const { isLoading, externalAuthentication } = this.state;
        const title = FormattersUtil.checkString(this.props.title) ? this.props.title : '';

        return (
            <>
                {externalAuthentication.length > 0 &&
                    <>
                        {title && <Divider horizontal>{title}</Divider>}
                        <div className='external-auth-buttons'>
                            {this.renderExternalAuthentication()}
                            <UrbasenseButton disabled={isLoading} history={this.props.history} logUser={this.props.logUser} changeActiveItem={this.props.changeActiveItem} />
                        </div>
                        <SSOButton disabled={isLoading} submit={this.handleSubmit} />
                    </>}
            </>
        );
    }

    componentDidMount = () => {
        UsersService.getExternalAuthentications().then(response => {
            if (response) this.setState({ externalAuthentication: response });
        });
    }

    renderExternalAuthentication = () => {
        let fields = [];
        const { externalAuthentication, isLoading } = this.state;
        externalAuthentication.forEach(provider => {
            switch (provider.name.toUpperCase()) {
                case 'GOOGLE':
                    fields.push(
                        <div>
                            <GoogleLogin
                                key={provider.name} clientId={AppSettings.getGoogleClientId()} cookiePolicy={'single_host_origin'}
                                onSuccess={(response) => this.handleResponseGoogle(response, provider)}
                                onFailure={(response) => this.handleResponseGoogle(response, provider)}
                                render={renderProps => (
                                    <Button
                                        key={provider.name} color='google plus' style={{ padding: '10px' }}
                                        onClick={renderProps.onClick} disabled={isLoading}
                                    >
                                        <Icon name='google' style={{ margin: 0 }} /> Google
                                    </Button>
                                )}
                            />
                        </div>
                    );
                    break;
                case 'MICROSOFT':
                    fields.push(
                        <MicrosoftLogin
                            key={provider.name} clientId={AppSettings.getMicrosoftClientId()} prompt='select_account'
                            authCallback={(_, data) => this.handleResponseMicrosoft(data, provider)}
                            children={
                                <Button key={provider.name} color='blue' disabled={isLoading} style={{ padding: '10px' }}>
                                    <Icon name='microsoft' style={{ margin: 0 }} /> Microsoft
                                </Button>}
                        />
                    );
                    break;
                default: break;
            }
        });
        return fields;
    }

    handleResponseGoogle = (response, provider) => {
        if (response?.tokenId && response?.profileObj) {
            let externalAuthentication = {
                TokenId: response.tokenId,
                FirstName: response.profileObj.givenName,
                LastName: response.profileObj.familyName,
                LoginProvider: provider.name
            }
            this.handleSubmit(externalAuthentication);
        }
    }

    handleResponseMicrosoft = (response, provider) => {
        const account = response?.account;
        if (response?.accessToken && account) {
            let externalAuthentication = {
                TokenId: response.accessToken,
                FirstName: account.name.split(' ')[0],
                LastName: account.name.replace(account.name.split(' ')[0] + ' ', ''),
                LoginProvider: provider.name
            }

            const sessionStorage = window.sessionStorage;
            if (sessionStorage) sessionStorage.removeItem('msal.idtoken'); // Fix le bug du spam
            this.handleSubmit(externalAuthentication);
        }
    }

    handleSubmit = (externalAuthentication) => {
        this.setState({ isLoading: true });
        externalAuthentication = { ...externalAuthentication, invitationId: new Cookies().get('invitation')?.id || null }
        UsersService.externalAuthentication(externalAuthentication).then(res => {
            if (res) {
                this.props.logUser(res.token);
                this.props.changeActiveItem('home');
                this.props.history.push('/');
            }
            this.setState({ isLoading: false });
        });
    }
}

export default ExternalAuthenticationForm;