import React, { Component } from 'react';
// Composants
import { Button, Divider, Form, Header, Message } from 'semantic-ui-react';
// Librairies
import { PublicClientApplication } from "@azure/msal-browser";
import i18n from '../../../locales/i18n';
// Services
import UsersService from '../../../services/UsersService';
// Utils
import FormattersUtil from '../../../utils/FormattersUtil';

const initialErrorState = {
    hidden: true,
    messages: [],
    name: false,
};

class SSOForm extends Component {
    state = {
        isLoading: false,
        SSOName: '',
        email: '',
        error: initialErrorState,
    };

    render() {
        const { isLoading, SSOName, email, error } = this.state;

        return (
            <div style={{ display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Header as='h1'>{i18n.t("Connexion")} - SSO</Header>
                <Form className='sso-form' size='large' error style={{ width: '60%' }}>
                    <Form.Input
                        fluid icon='mail' iconPosition='left' placeholder={i18n.t("Adresse e-mail")}
                        name='email' value={email} error={error.name} onChange={this.handleChange}
                        style={{ border: '1px solid grey', borderRadius: '5px' }}
                    />
                    <Divider content={i18n.t("ou")} horizontal style={{ margin: '10px 0' }} />
                    <Form.Input
                        fluid placeholder={i18n.t("Nom du SSO (ex: Grality)")}
                        name='SSOName' value={SSOName} error={error.name} onChange={this.handleChange}
                        style={{ border: '1px solid grey', borderRadius: '5px' }}
                    />
                    <Message
                        error style={{ textAlign: 'left' }} hidden={error.hidden}
                        header='Erreur' list={error.messages}
                    />
                    <Button color='green' fluid size='large' loading={isLoading} disabled={isLoading} style={{ color: 'white', marginTop: '10px' }} onClick={this.handleSubmit}>
                        {i18n.t("Continuer")}
                    </Button>
                </Form>
            </div>
        );
    }

    handleChange = (_, { name, value }) => {
        this.setState({
            [name]: value,
            error: initialErrorState
        });
    };

    handleSubmit = async () => {
        const { SSOName, email } = this.state;
        let isValid = true;
        let messages = [];
        let errors = { ...initialErrorState };

        const addError = (property, message) => {
            messages = [...messages, message];
            errors[property] = true;
            isValid = false;
        };

        let value;
        if (!SSOName?.trim()?.length && !email?.trim()?.length) addError('name', i18n.t("Veuillez saisir une adresse e-mail ou un nom de SSO."));
        else value = SSOName?.trim()?.length ? SSOName : FormattersUtil.checkEmail(email) ? email.split('@')[1] : null;

        let SSOConfiguration;
        if (value) {
            SSOConfiguration = await UsersService.getSSOConfiguration(value);
            if (!SSOConfiguration) addError('name', i18n.t("Aucun SSO trouvé."));
        }

        if (isValid) {
            this.setState({ isLoading: true });
            try {
                if (SSOConfiguration.type === 'OAuth2') {
                    const stateInfo = { provider: SSOConfiguration.name };
                    const encodedState = btoa(JSON.stringify(stateInfo)); // Encodage en Base64
                    this.props.setOAuthUrl(SSOConfiguration.url
                        + `?client_id=${SSOConfiguration.clientId}`
                        + `&redirect_uri=${window.location.origin}/login`
                        + '&response_type=code'
                        + `&scope=${SSOConfiguration.scopes.join('%20')}`
                        + `&state=${encodedState}`
                    );
                }

                if (SSOConfiguration.type === 'ADFS') {
                    const msalInstance = new PublicClientApplication({
                        auth: {
                            redirectUri: `${window.location.origin}/login`,
                            authority: SSOConfiguration.url,
                            clientId: SSOConfiguration.clientId
                        }
                    });

                    await msalInstance.initialize();
                    const response = await msalInstance.loginPopup({ scopes: SSOConfiguration.scopes });

                    this.setState({ isLoading: false });
                    const externalAuthentication = {
                        TokenId: response.accessToken,
                        FirstName: response.account.name.split(' ')[0],
                        LastName: response.account.name.replace(response.account.name.split(' ')[0] + ' ', ''),
                        LoginProvider: SSOConfiguration.name,
                        Type: SSOConfiguration.type
                    }

                    this.props.submit(externalAuthentication);
                }
            } catch (error) {
                this.setState({
                    isLoading: false,
                    error: { hidden: false, messages: [i18n.t("Échec de la connexion. Vérifiez le domaine et réessayez.")] }
                });
            }
        } else {
            this.setState({ error: { hidden: false, messages, SSOName: errors.SSOName } });
        }
    };
}

export default SSOForm;
