import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Composants
import { Button, Card, Dimmer, Divider, Form, Input, Loader, Menu, Message, Search, Segment, Select } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconPicker from '../../Utils/IconPicker';
import MapPreview from '../../Utils/MapPreview';
import ExitFormPopup from '../../Utils/ExitFormPopup';
import ContextMenu from '../../Utils/ContextMenu';
// Librairies
import i18n from '../../../locales/i18n';
import { connect } from 'react-redux';
import reactCSS from 'reactcss';
import { polygon } from '@turf/turf';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { setProject, setProjects, setProjectCollaborators } from '../../../actionCreators/projectsActions';
import { setRoleTemplates } from '../../../actionCreators/usersActions';
import { setExitFormWithChanges } from '../../../actionCreators/componentsActions';
import { faArrowLeft, faCheck, faCircleCheck, faClipboard, faClockRotateLeft, faCopy, faDownload, faEye, faPen, faPlus, faTimes, faTimesCircle, faTrash, faTrashAlt, faUpload, faUser, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { faPlus as faPlusLight } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { SketchPicker } from 'react-color';
import { contextMenu } from 'react-contexify';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
// Services
import RolesService from '../../../services/RolesService';
// Utils
import { showToast } from '../../../utils/ToastsUtil';
import StylesUtil from '../../../utils/StylesUtil';
import FormattersUtil from '../../../utils/FormattersUtil';
import WebSocketUtil from '../../../utils/WebSocketUtil';
import ProjectsUtil from '../../../utils/ProjectsUtil';

const RIGHTS = {
    elements: [
        { name: 'trees', label: i18n.t("Arbres"), permissions: 'RWEI', description: i18n.t("Accédez au module \"Arbres\" de l'application, à travers les différents écrans (carte, fiche arbre, tableau de données). Les boutons ci-contre déterminent vos niveaux d'accès : Aucun accès / Lecture seule / Écriture et autorisation d'importation ou d'exportation de données.") },
        { name: 'greenSpaces', label: i18n.t("Espaces verts"), permissions: 'RWEI', description: i18n.t("Accédez au module \"Espaces Verts\" de l'application, à travers les différents écrans (carte, fiche arbre, tableau de données). Les Espaces Verts correspondent aux ensembles végétaux linéaires ou surfaciques, tels que les haies, massifs fleuris, massifs arbustifs, pelouses, prairies, massifs arborés, etc. Les boutons ci-contre définissent vos niveaux d'accès : Aucun accès / Lecture seule / Écriture et autorisation d'importation ou d'exportation de données.") },
        { name: 'furnitures', label: i18n.t("Mobiliers"), permissions: 'RWEI', description: i18n.t("Accédez au module \"Mobiliers\" de l'application, à travers les différents écrans (carte, fiche arbre, tableau de données). Les Mobiliers correspondent aux objets non végétaux que vous souhaitez suivre dans l'application, tels que des bacs, jardinières, bancs, lampadaires, capteurs, compteurs d'eau, nichoirs, pièges à chenille processionnaire, etc. Les boutons ci-contre définissent vos niveaux d'accès : Aucun accès / Lecture seule / Écriture et autorisation d'importation ou d'exportation de données.") },
        { name: 'markers', label: i18n.t("Repères"), permissions: 'RW', description: i18n.t("Accédez au module \"Repères\" de l'application, à travers les différents écrans (carte, fiche détail).") },
        { name: 'stations', label: i18n.t("Stations"), permissions: 'RWEI', description: i18n.t("Accédez au module \"Stations\" de l'application, à travers les différents écrans (carte, fiche arbre, tableau de données). Les Stations correspondent aux contours délimitant les différents secteurs de votre projet, qu'il s'agisse de quartiers, de sous-quartiers, d'alignements, d'esplanades, de parcs ou de chantiers. Les boutons ci-contre définissent vos niveaux d'accès : Aucun accès / Lecture seule / Écriture et autorisation d'importation ou d'exportation de données.") },
        { name: 'backgroundImages', label: i18n.t("Calques"), permissions: 'RW', description: i18n.t("Accédez au module \"Calques\" de l'application, à travers les différents écrans (carte, fiche arbre, tableau de données). Les Calques s'ajoutent en tant que fonds de carte pour enrichir la vue \"Carte\" de votre projet. Par exemple, vous pouvez ajouter une orthophoto (raster) qui constitue un fond de carte haute définition, ou afficher un plan de plantations sur lequel les équipes travaillent. Les boutons ci-contre définissent vos niveaux d'accès : Aucun accès / Lecture seule / Écriture et autorisation d'importation ou d'exportation de données.") },
    ],
    features: [
        { name: 'filters', label: i18n.t("Filtres"), permissions: 'RW', description: i18n.t("Accédez à l'outil \"Filtres\" : dans les Outils Avancés, les filtres vous permettent de n'afficher que les sujets correspondant aux critères définis. Le filtre actif s'applique de la même manière à l'ensemble du projet : vue Carte, Tableau de Données, Tableau de bord, Exports. Les boutons ci-contre définissent vos niveaux d'accès : Aucun accès / Lecture seule / Écriture.") },
        { name: 'tables', label: i18n.t("Tableau de données"), permissions: 'RW', description: i18n.t("Accédez à la vue \"Tableau de Données\" : dans les Outils Avancés, le Tableau de données affiche les données de votre projet sous forme de tableau, similaire à Excel, pour les Arbres, les Espaces Verts ou les Mobiliers. Les boutons ci-contre définissent vos niveaux d'accès : Aucun accès / Lecture seule / Écriture.") },
        { name: 'charts', label: i18n.t("Graphiques"), permissions: 'RW', description: i18n.t("Accédez à l'outil \"Graphiques\" : dans les Outils Avancés / Statistiques, les Graphiques sont pré-définis ou peuvent être créés par l'utilisateur à partir des données du projet. Les boutons ci-contre définissent vos niveaux d'accès : Aucun accès / Lecture seule / Écriture.") },
        { name: 'statistics', label: i18n.t("Ligne du temps"), permissions: 'R', description: i18n.t("Accédez à l'outil \"Ligne du Temps\" : dans les Outils Avancés, la ligne du temps permet de simuler la croissance des végétaux au fil du temps. Par exemple : où en serai-je dans 15 ans ? Les indicateurs de performance environnementale sont évalués en fonction de la situation projetée. Les boutons ci-contre définissent vos niveaux d'accès : Aucun accès / Lecture seule / Écriture.") },
        { name: 'mapExport', label: i18n.t("Export cartographique"), permissions: 'R', description: i18n.t("Accédez à l'outil \"Export Cartographique\" : dans les Outils Avancés, l'Export Cartographique permet d'exporter sous forme de carte les informations de votre projet, en tenant compte de la modalité d'affichage retenue par l'utilisateur. Les boutons ci-contre définissent vos niveaux d'accès : Aucun accès / Lecture seule / Écriture.") },
        { name: 'actions', label: i18n.t("Gestion des actions"), permissions: 'RWE', description: i18n.t("Accédez à l'outil \"Gestion des Actions\" : dans les Outils Avancés, la Gestion des Actions vous offre une vue globale des Actions d'entretien associées aux végétaux ou aux mobiliers, telles que l'élagage, la fauche, l'inspection de sécurité, et bien plus encore. À partir de cet écran, vous avez également la possibilité de définir et de suivre votre plan de gestion pour un ensemble de sujets. Les boutons ci-contre définissent vos niveaux d'accès : Aucun accès / Lecture seule / Écriture.") },
        { name: 'events', label: i18n.t("Gestion des évènements"), permissions: 'RW', description: i18n.t("Accédez à l'outil \"Gestion des Évènements\" : dans les Outils Avancés, la Gestion des Évènements vous offre une vue globale des Évènements associées aux végétaux ou aux mobiliers, telles que la foudre, la grêle, l'incendie, et bien plus encore. Les boutons ci-contre définissent vos niveaux d'accès : Aucun accès / Lecture seule / Écriture.") },
        { name: 'projectHistory', label: i18n.t("Historique du projet"), permissions: 'RE', description: i18n.t("Accédez à l'outil \"Historique du Projet\" : dans les Outils Avancés, l'historique enregistre tous les changements apportés au projet, assurant une traçabilité optimale pour savoir qui a effectué quelles actions et quand. L'historique est équipé d'un moteur de filtrage et de tri pour une gestion efficace des données. Les boutons ci-contre définissent vos niveaux d'accès : Aucun accès / Lecture seule / Écriture.") },
        { name: 'thematicMaps', label: i18n.t("Cartes thématiques"), permissions: 'RW', description: i18n.t("Accédez à l'outil \"Cartes thématiques\" : Situé dans le coin inférieur gauche de la vue de la carte, cet outil vous permet de configurer de manière conviviale les informations affichées. Par exemple, vous pouvez générer des cartes des arbres en fonction de leur genre, des Espaces Verts en tenant compte de leurs actions en cours ou planifiées, et choisir le fond de carte, qu'il s'agisse de photos aériennes ou de plans IGN. Les boutons ci-contre définissent les niveaux d'accès : Aucun accès / Consultation seule / Écriture.") },
        { name: 'wfsServices', label: i18n.t("Flux WFS"), permissions: 'W', description: i18n.t("Créez un flux WFS avec les données du projet. ATTENTION : le flux WFS généré comprendra les données de l'ensemble du projet même si l'utilisateur n'a accès qu'à une partie de ce dernier.") }
    ],
    managing: [
        { name: 'parameters', label: i18n.t("Paramètres du projet"), permissions: 'W', description: i18n.t("Accédez aux \"Paramètres du projet\" : dans les paramètres du projet, sous l'onglet \"Général\", vous permet de définir le périmètre du projet, de choisir une identité visuelle (logo), ou encore de modifier le propriétaire du projet. Les boutons ci-contre définissent vos niveaux d'accès : Aucun accès / Accès.") },
        { name: 'formulas', label: i18n.t("Formules"), permissions: 'W', description: i18n.t("Accédez aux \"Formules\" : dans les paramètres du projet, sous les onglets \"Général\" et \"Champs\", vous avez la possibilité de configurer les paramètres de calcul du projet. Les boutons ci-contre définissent vos niveaux d'accès : Aucun accès / Accès.") },
        { name: 'requiredFields', label: i18n.t("Champs"), permissions: 'W', description: i18n.t("Accédez aux \"Champs personnalisés\" : dans les paramètres du projet, sous l'onglet \"Champs\", vous avez la possibilité d'adapter le modèle de données en ajoutant des champs personnalisés pour les modules du projet, que ce soit pour les Arbres, les Espaces Verts, ... Les boutons ci-contre définissent vos niveaux d'accès : Aucun accès / Accès.") },
        { name: 'publicFields', label: i18n.t("Accessibilité"), permissions: 'W', description: i18n.t("Accédez à \"l'Accessibilité du projet\" : dans les paramètres du projet, sous l'onglet \"Accessibilité\", vous pouvez déclarer un projet comme \"Public\" et définir quelles informations seront accessibles aux utilisateurs tiers, c'est-à-dire ceux qui ne sont pas des utilisateurs invités au projet. Les boutons ci-contre définissent vos niveaux d'accès : Aucun accès / Accès.") },
        { name: 'collaborators', label: i18n.t("Gestion des collaborateurs"), permissions: 'W', description: i18n.t("Accédez à la \"Gestion des Collaborateurs\" : depuis le menu du projet, vous pouvez inviter un utilisateur, qu'il s'agisse d'un collègue, d'un prestataire ou d'un décideur, et définir ses niveaux d'habilitation sur le projet en fonction des Rôles, que ce soit en lecture seule, en écriture, ou en tant que propriétaire du projet. Les boutons ci-contre définissent vos niveaux d'accès : Aucun accès / Accès.") },
        { name: 'rights', label: i18n.t("Gestion des droits"), permissions: 'W', description: i18n.t("Accédez à la \"Gestion des Rôles\" : depuis le menu du projet, vous avez la possibilité de créer ou de modifier les Rôles dont vous aurez besoin, que ce soit en lecture seule, en écriture, ou en tant que propriétaire du projet. Cette fonctionnalité vous permet notamment de restreindre l'accès des utilisateurs à des secteurs spécifiques, par exemple, en permettant à un prestataire d'accéder à son chantier sans voir le reste du projet. Les boutons ci-contre définissent vos niveaux d'accès : Aucun accès / Accès.") }
    ]
};

const defaultStyles = {
    'default': {
        color: {
            width: '27px',
            height: '18px',
            borderRadius: '3px'
        },
        swatch: {
            padding: '2px',
            marginLeft: '1px',
            background: '#fff',
            borderRadius: '3px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer'
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
            zIndex: 1
        }
    }
};

const resultRenderer = ({ userId, title, description, role, subscription }) => (
    <div key={userId} style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>{title}</div>
            <div style={{ color: role?.color }}>
                <FontAwesomeIcon icon={role?.icon} style={{ marginRight: '5px' }} />
                {role?.label}
            </div>
        </div>
        <div style={{ display: 'flex', marginTop: '5px' }}>
            <div style={{ flex: 1 }}>{description}</div>
            <div style={{ background: subscription?.color }}>{subscription?.name}</div>
        </div>
    </div>
);

resultRenderer.propTypes = {
    userId: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    role: PropTypes.object,
    subscription: PropTypes.object
};

class RoleForm extends Component {
    state = {
        isLoading: false,
        isSaving: false,
        newTemplateLabel: '',
        isSearching: false,
        showExitForm: false,
        search: null,
        results: []
    };

    render() {
        const { projectRoles, initialProjectRoles, projectRole, stations, activeItem, roleTemplates } = this.props;
        const { isLoading, showExitForm, isSaving, newTemplateLabel, templateToRemove, isDeleting } = this.state;
        const hasChanged = JSON.stringify(projectRoles) !== JSON.stringify(initialProjectRoles);

        const roleTemplateOptions = roleTemplates?.map(template => ({
            text: <div style={{ display: 'flex' }}>
                <span>{template.label}</span>
                <FontAwesomeIcon
                    icon={faTimes} color='var(--red-100)' style={{ marginLeft: 'auto' }} title={i18n.t("Supprimer le template")}
                    onClick={() => this.setState({ templateToRemove: template.id })}
                />
            </div>,
            value: template.id
        })) || [];

        return (
            <>
                <div style={{ display: 'flex', height: '100%' }}>
                    {!isMobile &&
                        <div style={{ minWidth: !isMobile ? '250px' : '175px', borderRight: '1px solid var(--black-90)' }}>
                            <div style={{ position: 'relative' }}>
                                <FontAwesomeIcon
                                    icon={faArrowLeft} title={i18n.t("Retour")} onClick={() => { if (hasChanged) this.setState({ showExitForm: true }, () => this.props.setExitFormWithChanges({ submit: this.handleSubmit, cancel: () => this.props.hideForm(false) })); else this.props.cancel() }}
                                    style={{ position: 'absolute', left: '10px', top: '5px', cursor: 'pointer' }}
                                />
                                <h3 style={{ textAlign: 'center', margin: '7px 0' }}>{i18n.t("Rôles")}</h3>
                            </div>
                            <Divider style={{ margin: 0, marginBottom: '7px' }} />
                            <div className='role-list'>{this.renderRoles()}</div>
                        </div>}
                    {projectRole &&
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <Dimmer active={isLoading} style={StylesUtil.getMapStyles().dimmerStyle}>
                                <Loader content={i18n.t("Mise à jour des rôles en cours...")} />
                            </Dimmer>
                            <Dimmer active={templateToRemove && !isDeleting} style={StylesUtil.getMapStyles().dimmerStyle}>
                                <Message compact className='tableConfirmation'>
                                    <Message.Header>{i18n.t("Êtes-vous certain de vouloir supprimer ce template ?")}</Message.Header>
                                    <Message.Content style={{ marginTop: '10px' }}>
                                        <Button color='grey' onClick={() => this.setState({ templateToRemove: null })}>
                                            <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                        </Button>
                                        <Button color='red' onClick={() => this.removeRoleTemplate(templateToRemove)}>
                                            <FontAwesomeIcon icon={faTrash} style={{ marginRight: '10px' }} />{i18n.t("Supprimer")}
                                        </Button>
                                    </Message.Content>
                                </Message>
                            </Dimmer>
                            <Menu pointing secondary style={{ margin: 0 }}>
                                <Menu.Item active={activeItem === 'main'} onClick={() => this.props.setActiveItem('main')}>{i18n.t("Général")}</Menu.Item>
                                <Menu.Item active={activeItem === 'areas'} disabled={projectRole.type === 'owner'} onClick={() => { this.props.setActiveItem('areas'); if (!stations) this.props.loadStations(); }}>{i18n.t("Restrictions géographiques")}</Menu.Item>
                                {!isMobileOnly &&
                                    <Form style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: '50px' }}>
                                        <Select
                                            className='template-select' placeholder={i18n.t("Templates sauvegardés")} style={{ marginRight: '5px' }} disabled={!roleTemplates || projectRole.type === 'owner'} loading={!roleTemplates} selectOnBlur={false}
                                            options={roleTemplateOptions} value={this.state.roleTemplateId || ''} onChange={this.restoreRoleTemplate} selectOnNavigation={false} onClick={() => contextMenu.hideAll()}
                                        />
                                        <Input
                                            action placeholder={i18n.t("Nom du template")} value={newTemplateLabel || ''}
                                            onChange={(_, { value }) => this.setState({ newTemplateLabel: value.length > 30 ? value.substring(0, 30) : value })}
                                        >
                                            <input disabled={projectRole.type === 'owner'} />
                                            <Button
                                                title={i18n.t("Sauvegarder le template")} icon='save' color='blue'
                                                disabled={isSaving || projectRole.type === 'owner'} loading={isSaving} onClick={this.saveRoleTemplate}
                                            />
                                        </Input>
                                    </Form>}
                            </Menu>
                            {activeItem === 'main' ? this.renderMain() : this.renderGeographicRestrictions()}
                            <div style={{ display: isMobileOnly && 'flex', margin: isMobileOnly ? '10px 0 0 0' : '10px 0 0 10px' }}>
                                <Button color='red' onClick={() => { if (hasChanged) this.setState({ showExitForm: true }, () => this.props.setExitFormWithChanges({ submit: this.handleSubmit, cancel: () => this.props.hideForm(false) })); else this.props.cancel() }}>
                                    <FontAwesomeIcon icon={faTimes} style={{ marginRight: isMobileOnly ? '5px' : '10px' }} />{i18n.t("Annuler")}
                                </Button>
                                <Button color='blue' disabled={!hasChanged} onClick={this.props.reset}>
                                    <FontAwesomeIcon icon={faClockRotateLeft} style={{ marginRight: isMobileOnly ? '5px' : '10px' }} />{i18n.t("Réinitialiser")}
                                </Button>
                                <Button color='green' disabled={!hasChanged} onClick={this.handleSubmit}>
                                    <FontAwesomeIcon icon={faCheck} style={{ marginRight: isMobileOnly ? '5px' : '10px' }} />{i18n.t("Valider")}
                                </Button>
                            </div>
                        </div>}
                    {showExitForm && <ExitFormPopup close={() => this.setState({ showExitForm: false })} hideForm={() => this.props.cancel()} />}
                </div>
                <ContextMenu
                    id='context-menu'
                    items={[
                        {
                            icon: faCopy,
                            label: i18n.t("Dupliquer"),
                            isVisible: () => this.props.canEditProjectRole(this.contextMenuElement),
                            onClick: () => this.props.duplicate(this.contextMenuElement)
                        }
                    ]} />
            </>
        );
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.projectRole?.id !== this.props.projectRole?.id)
            this.setState({ isSearching: false, search: null });
    }

    renderRoles = () => {
        const { projectToEdit } = this.props;
        const projectRoleInProps = this.props.projectRole;
        const projectRoles = FormattersUtil.sortRoles(JSON.parse(JSON.stringify(this.props.projectRoles)));
        const defaultRole = projectRoles.find(pr => pr.type === 'default');

        return (
            [
                ...projectRoles
                    .filter(pr => !pr.deletionDate && pr.baseProjectId === projectToEdit?.id)
                    .map(projectRole => {
                        const canEdit = this.props.canEditProjectRole(projectRole);
                        return (
                            <div
                                className={projectRoleInProps?.id === projectRole.id ? 'selected' : ''} style={{ display: 'flex', alignItems: 'center', width: '250px' }}
                                onClick={() => this.props.changeRole(projectRole)} onContextMenu={canEdit ? (event) => this.handleContextMenu(event, projectRole) : null}
                            >
                                <FontAwesomeIcon icon={projectRole.icon} style={{ marginRight: '10px', color: projectRole.color }} />
                                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {projectRole.type ? i18n.t(projectRole.label) : projectRole.label}
                                </div>
                                {isNaN(projectRole.id) &&
                                    <FontAwesomeIcon
                                        icon={faTimes} style={{ marginLeft: 'auto', color: 'var(--red-100)' }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.props.handleRemoveRole(projectRole.id);
                                            if (projectRoleInProps.id === projectRole.id) this.props.changeRole(defaultRole);
                                        }}
                                    />}
                            </div>
                        )
                    }),
                <>
                    {!this.props.loginAsData?.readOnly &&
                        <div style={{ border: 'dashed 1px var(--grey-100)' }} onClick={this.props.handleAddRole}>
                            <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px' }} />
                            {i18n.t("Ajouter un rôle")}
                        </div>}
                </>
            ]
        );
    }

    renderMain = () => {
        const { projectRole, loginAsData } = this.props;
        const { showColorPicker, isSearching, search, results } = this.state;

        let styles = { ...defaultStyles };
        styles = reactCSS(styles);
        const isEditable = projectRole.type !== 'owner' && !loginAsData?.readOnly;

        return (
            <div style={{ flex: 1, display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: '10px', width: '100%', maxHeight: '100%', overflow: 'auto', ...(isMobileOnly ? {} : { flexWrap: 'wrap', padding: '10px 10px 0 10px' }) }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', margin: 0, ...(isMobileOnly ? { minWidth: '100%' } : { flexBasis: 1, flexGrow: 1, maxHeight: '100%', minWidth: '400px', overflow: 'hidden' }) }}>
                    <Segment style={{ margin: 0 }}>
                        <h3>{i18n.t("Affichage")}</h3>
                        <Divider />
                        <Form>
                            <Form.Field control={Input} label={i18n.t("Libellé") + ' :'} placeholder={i18n.t("Libellé")} name='label' value={projectRole.label} disabled={!isEditable || projectRole.type} onChange={this.handleChange} />
                            {showColorPicker &&
                                <div>
                                    <div style={styles.cover} onClick={() => this.setState({ showColorPicker: false })} />
                                    <div style={{ zIndex: 4, position: 'absolute', width: 'fit-content' }}>
                                        <SketchPicker id='theme-form__color-picker' color={projectRole.color}
                                            onChange={color => {
                                                color = 'rgba(' + color.rgb.r + ', ' + color.rgb.g + ', ' + color.rgb.b + ', ' + color.rgb.a + ')';
                                                this.props.updateRole({ ...projectRole, color });
                                            }}
                                        />
                                    </div>
                                </div>}
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '14px' }}>
                                <label style={{ fontSize: '.92857143em', marginRight: '10px' }}><b>{i18n.t("Couleur")} :</b></label>
                                <div style={{ ...styles.swatch, zIndex: 3, cursor: (isEditable || projectRole.type) ? 'pointer' : 'default' }} onClick={() => { if (isEditable) this.setState(prevState => ({ showColorPicker: !prevState.showColorPicker })) }}>
                                    <div style={{ ...styles.color, backgroundColor: projectRole.color }} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '14px' }}>
                                <label style={{ fontSize: '.92857143em', marginRight: '10px' }}><b>{i18n.t("Icône")} :</b></label>
                                <IconPicker
                                    iconName={projectRole.icon} direction='bottomright' closeOnSelect={true} disabled={!isEditable}
                                    handleIconChange={({ iconName: icon }) => this.props.updateRole({ ...projectRole, icon })}
                                />
                            </div>
                        </Form>
                    </Segment>
                    <Segment style={{ flex: 1, display: 'flex', flexDirection: 'column', margin: 0, overflow: 'auto' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <h3 style={{ flex: 1, margin: 0 }}>{i18n.t("Membres")} {projectRole?.userBaseProjects?.length > 0 && `(${projectRole.userBaseProjects.length})`}</h3>
                            {projectRole.type !== 'owner' &&
                                <Search
                                    icon='add user' className='custom-search' placeholder={i18n.t("Ajouter un membre...")} size='mini'
                                    onSearchChange={this.handleMembersSearchChanged} onResultSelect={this.handleResultSelect}
                                    resultRenderer={resultRenderer} results={results} loading={isSearching} value={search || ''}
                                    noResultsMessage={i18n.t("Aucun résultat trouvé")} disabled={loginAsData?.readOnly ? true : false}
                                    aligned='right'
                                />}
                        </div>
                        <Divider />
                        {this.renderMembers()}
                    </Segment>
                </div>
                <Segment style={{ flex: 1, display: 'flex', flexDirection: 'column', margin: 0, ...(isMobileOnly ? {} : { flexBasis: 1, flexGrow: 1, maxHeight: '100%', minWidth: '400px' }) }}>
                    <div>
                        <h3>{i18n.t("Permissions ({{nbRights}})", { nbRights: RIGHTS.features.length + RIGHTS.elements.length + RIGHTS.managing.length })}</h3>
                        <Divider />
                    </div>
                    {this.renderRights(isEditable)}
                </Segment>
            </div>
        );
    }

    renderMembers = () => {
        const { projectRole, loginAsData } = this.props;

        const userBaseProjects = projectRole?.type === 'owner'
            ? this.props.userProjects?.length ? [this.props.userProjects.find(ubp => ubp.projectRole.type === 'owner')] : []
            : [...(projectRole?.userBaseProjects || [])];

        if (!userBaseProjects.length)
            return (
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', top: 0, left: 0, height: '100%', width: '100%', pointerEvents: 'none' }}>
                    <FontAwesomeIcon icon={faUsers} size='5x' style={{ marginTop: 'auto' }} />
                    <h4 style={{ marginBottom: 'auto' }}>{i18n.t("Aucun membre trouvé")}</h4>
                </div>
            );

        const isEditable = projectRole.type !== 'owner' && !loginAsData?.readOnly;
        return userBaseProjects.map((userBaseProject, index) => {
            return (
                <>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon icon={faUser} style={{ marginRight: '10px' }} />
                        <span>
                            {`${FormattersUtil.formatLastNameAndFirstName(userBaseProject.user.lastName, userBaseProject.user.firstName)} (${userBaseProject.user.email})`}
                        </span>
                        <Button
                            type='button' title={i18n.t("Copier l'adresse mail")} size='mini' color='blue'
                            style={{ marginLeft: 'auto', padding: '4px 6px' }} disabled={!isEditable}
                            onClick={() => { navigator.clipboard.writeText(userBaseProject.user.email); showToast('email_copied'); }}
                        >
                            <FontAwesomeIcon icon={faClipboard} />
                        </Button>
                        {!['default', 'owner'].includes(projectRole.type) &&
                            <Button
                                type='button' title={i18n.t("Retirer du rôle")} size='mini' color='red' style={{ padding: '4px 6px' }}
                                disabled={!isEditable} onClick={() => this.removeMember(userBaseProject)}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>}
                    </div>
                    {index < userBaseProjects.length - 1 && <Divider style={{ margin: '7px 0' }} />}
                </>
            );
        });
    }

    renderRights = (areEditable) => {
        return (
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', maxHeight: '100%', overflowY: 'auto', paddingRight: '15px' }}>
                <h3>{i18n.t("Permissions des éléments")}</h3>
                {RIGHTS.elements.map(right => this.renderRight(right, !areEditable))}
                <h3>{i18n.t("Permissions des fonctionnalités")}</h3>
                {RIGHTS.features.map(right => this.renderRight(right, !areEditable))}
                <h3>{i18n.t("Permissions de gestion")}</h3>
                {RIGHTS.managing.map(right => this.renderRight(right, !areEditable))}
            </div>
        );
    }

    renderRight = ({ name, label, description, permissions }, disabled) => {
        const buttonStyle = { padding: 0, width: '25px', height: '25px' };
        const state = this.props.projectRole[name];

        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ flex: 1, margin: 0 }}>{label}</p>
                        {['E', 'I'].some(p => permissions.includes(p)) &&
                            <>
                                {permissions.includes('E') &&
                                    <Button
                                        title={i18n.t("Export")} color={state?.includes('E') ? 'green' : 'grey'} style={buttonStyle}
                                        disabled={disabled} onClick={() => this.handleRightChange(name, 'E')}
                                    >
                                        <FontAwesomeIcon icon={faDownload} />
                                    </Button>}
                                {permissions.includes('I') &&
                                    <Button
                                        title={i18n.t("Import")} color={state?.includes('I') ? 'green' : 'grey'} style={{ ...buttonStyle, marginRight: '7px' }}
                                        disabled={disabled} onClick={() => this.handleRightChange(name, 'I')}
                                    >
                                        <FontAwesomeIcon icon={faUpload} />
                                    </Button>}
                            </>}
                        {['R', 'W'].some(p => permissions.includes(p)) &&
                            <Button.Group>
                                <Button title={i18n.t("Désactivé")} color={!['R', 'W'].some(p => state?.includes(p)) ? 'red' : 'grey'} active={!['R', 'W'].some(p => state?.includes(p))} style={buttonStyle}
                                    disabled={disabled} onClick={() => this.handleRightChange(name, null)}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                {permissions.includes('R') &&
                                    <Button
                                        title={i18n.t("Lecture")} color={state?.includes('R') && !state?.includes('W') ? 'blue' : 'grey'} active={state?.includes('R') && !state?.includes('W')} style={buttonStyle}
                                        disabled={disabled} onClick={() => this.handleRightChange(name, 'R')}
                                    >
                                        <FontAwesomeIcon icon={faEye} />
                                    </Button>}
                                {permissions.includes('W') &&
                                    <>
                                        {permissions.length > 1 ?
                                            <Button
                                                title={i18n.t("Écriture")} color={state?.includes('W') ? 'yellow' : 'grey'} active={state?.includes('W')} style={buttonStyle}
                                                disabled={disabled} onClick={() => this.handleRightChange(name, 'W')}
                                            >
                                                <FontAwesomeIcon icon={faPen} />
                                            </Button>
                                            :
                                            <Button
                                                title={i18n.t("Activé")} color={state?.includes('W') ? 'green' : 'grey'} active={state?.includes('W')} style={buttonStyle}
                                                disabled={disabled} onClick={() => this.handleRightChange(name, 'W')}
                                            >
                                                <FontAwesomeIcon icon={faCheck} />
                                            </Button>}
                                    </>}
                            </Button.Group>}
                    </div>
                    <small style={{ flex: 1, color: 'var(--grey-60)', maxWidth: '75%' }}>{description}</small>
                </div>
                <Divider />
            </>
        );
    }

    renderGeographicRestrictions = () => {
        const { projectRole, stations } = this.props;

        let styles = { ...defaultStyles };
        styles = reactCSS(styles);
        const isEditable = projectRole.type !== 'owner';

        return (
            <div style={{ flex: 1, display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: '10px', width: '100%', maxHeight: '100%', overflow: 'auto', ...(isMobileOnly ? {} : { flexWrap: 'wrap', padding: '10px 10px 0 10px' }) }}>
                <Segment style={{ flex: 1, display: 'flex', flexDirection: 'column', margin: 0, ...(isMobileOnly ? {} : { flexBasis: 1, flexGrow: 1, maxHeight: '100%', minWidth: '400px' }) }}>
                    <h3 style={{ margin: 0 }}>{i18n.t("Stations ({{nbStations}}/{{nbMax}})", { nbStations: projectRole.projectRoleStations?.length || 0, nbMax: stations?.length || 0 })}</h3>
                    <Divider />
                    {this.renderStations()}
                </Segment>
                <Segment style={{ flex: 1, display: 'flex', flexDirection: 'column', margin: 0, ...(isMobileOnly ? {} : { flexBasis: 1, flexGrow: 1, maxHeight: '100%', minWidth: '400px' }) }}>
                    <h3 style={{ margin: 0 }}>{i18n.t("Zones personnalisées ({{nbAreas}})", { nbAreas: projectRole.areas?.length || 0 })}</h3>
                    <Divider />
                    {this.renderAreas()}
                </Segment>
            </div>
        );
    }

    renderStations = () => {
        const { projectRole, stations } = this.props;

        const handleStationClick = (stationId) => {
            let { projectRoleStations } = this.props.projectRole;
            if (projectRoleStations?.find(prs => prs.stationId === stationId)) projectRoleStations = projectRoleStations.filter(prs => prs.stationId !== stationId);
            else projectRoleStations = [...(projectRoleStations || []), { projectRoleId: !isNaN(projectRole.id) ? projectRole.id : 0, stationId }];
            if (!projectRoleStations.length) projectRoleStations = null;
            this.props.updateRole({ ...projectRole, projectRoleStations });
        };

        return (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', padding: '4px 1px 1px 1px', overflow: 'auto' }}>
                <Dimmer active={!stations} style={StylesUtil.getMapStyles().dimmerStyle}>
                    <Loader content={i18n.t("Chagement des stations en cours...")} />
                </Dimmer>
                {stations?.map((station, index) => (
                    <Card style={{ width: '48%', maxWidth: '245px', margin: 0 }} onClick={() => handleStationClick(station.id)}>
                        <MapPreview
                            hideAttribution id={station.id + index} style={{ height: '120px', width: '100%' }} features={[station]} elementStyle={{ station: StylesUtil.getStationStyle() }}
                        />
                        <Card.Content style={{ padding: '7px', display: 'flex' }}>
                            <span style={{ fontSize: '12pt', fontWeight: 'bold' }}>{station.properties.label}</span>
                            <div style={{ marginLeft: 'auto' }}>
                                {projectRole.projectRoleStations?.find(prs => prs.stationId === station.id)
                                    ? <FontAwesomeIcon icon={faCircleCheck} style={{ fontSize: '13.5pt', borderRadius: '50%', backgroundColor: 'white' }} color='var(--blue-100)' />
                                    : <FontAwesomeIcon icon={faCircle} style={{ fontSize: '13.5pt' }} />}
                            </div>
                        </Card.Content>
                    </Card>
                ))}
            </div >
        );
    }

    renderAreas = () => {
        const { projectRole } = this.props;

        const removeArea = (area) => {
            const areas = projectRole.areas.filter(a => a !== area);
            this.props.updateRole({ ...projectRole, areas: areas.length > 0 ? areas : null });
        };

        return (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }}>
                {[
                    ...(projectRole.areas?.map((area, index) => (
                        <Card style={{ width: '200px', margin: 0 }}>
                            <MapPreview
                                hideAttribution id={index} style={{ height: '120px', width: '100%' }} features={[polygon(area)]} elementStyle={{ area: StylesUtil.getStationStyle() }}
                            />
                            <Card.Content style={{ padding: '7px', display: 'flex' }}>
                                <span style={{ fontSize: '12pt', fontWeight: 'bold' }}>{`${i18n.t("Zone")} ${index + 1}`}</span>
                                <div style={{ marginLeft: 'auto' }}>
                                    <FontAwesomeIcon icon={faTrash} style={{ fontSize: '12pt', cursor: 'pointer' }} color='var(--red-100)' onClick={() => removeArea(area)} />
                                </div>
                            </Card.Content>
                        </Card>
                    )) || []),
                    <Card style={{ width: '200px', margin: 0, boxShadow: 'none', border: 'dashed 1px var(--grey-100)', backgroundColor: 'transparent' }} className='transparent' onClick={() => this.props.handleAddArea()}>
                        <Card.Content style={{ height: '140px', padding: '7px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <FontAwesomeIcon icon={faPlusLight} color='white' size='3x' />
                            <span style={{ fontSize: '10pt', fontWeight: 'bold', textAlign: 'center' }}>{i18n.t("Ajouter une zone personnalisée")}</span>
                        </Card.Content>
                    </Card>
                ]}
            </div >
        );
    }

    getMembersResults = () => {
        const { projectRoles } = this.props;
        const { search } = this.state;
        if (!search) return [];

        //TODO Si on accède via la liste des projets, up.user.subscriptions vaut null
        const members = this.props.projectRole.userBaseProjects;
        const ownerRole = this.props.projectRoles.find(pr => pr.type === 'owner')
        const userProjectsFiltered = projectRoles.map(pr => pr.userBaseProjects).flat().filter(up => up.projectRoleId !== ownerRole.id && !members.find(m => m.userId === up.userId) &&
            (FormattersUtil.getNormalizedString(up.user.lastName).includes(search)
                || FormattersUtil.getNormalizedString(up.user.firstName).includes(search)
                || FormattersUtil.getNormalizedString(up.user.email).includes(search)));
        return userProjectsFiltered.map(up => ({
            userId: up.userId, title: FormattersUtil.formatLastNameAndFirstName(up.user.lastName, up.user.firstName),
            description: up.user.email, role: projectRoles.find(pr => pr.id === up.projectRoleId),
            subscription: up.user.subscriptions && up.user.subscriptions[0].subscription
        }));
    }

    handleMembersSearchChanged = (_, data) => {
        this.setState({ search: data.value });
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => this.setState({ results: this.getMembersResults() }), 300);
    }

    handleResultSelect = (_, data) => this.props.changeMemberRole(data.result.role.id, this.props.projectRole.id, data.result.userId);
    handleChange = (_, { name, value }) => this.props.updateRole({ ...this.props.projectRole, [name]: value });
    handleRightChange = (name, right) => {
        const permissions = Object.keys(RIGHTS).flatMap(name => RIGHTS[name]).find(right => right.name === name)?.permissions;
        let value = this.props.projectRole[name];
        if (right) {
            const isIncluded = value?.includes(right);
            if (right === 'R') value = value?.replace('W', '') + (!isIncluded ? 'R' : '');
            if (right === 'W') value = (value || '') + (permissions?.includes('R') && !value?.includes('R') ? 'R' : '') + (!isIncluded ? 'W' : '');
            if (['E', 'I'].includes(right)) value = isIncluded ? value.replace(right, '') : (value || '') + right;
        } else value = value?.replace('R', '').replace('W', '');
        this.props.updateRole({ ...this.props.projectRole, [name]: value || null });
    }

    removeMember = (userBaseProject) => {
        const defaultRole = this.props.projectRoles.find(pr => pr.type === 'default');
        this.props.changeMemberRole(this.props.projectRole.id, defaultRole.id, userBaseProject.userId);
    }

    handleSubmit = () => {
        const { projectRoles, initialProjectRoles } = this.props;

        this.setState({ isLoading: true });

        // Récupération des rôles créés/modifiés
        const projectRolesToUpdate = [], projectRolesToAdd = [];
        projectRoles.forEach(pr => {
            const initialProjectRole = initialProjectRoles.find(ipr => ipr.id === pr.id);
            if (!initialProjectRole) {
                delete pr.id;
                if (pr.userBaseProjects?.length) pr.userBaseProjects.forEach(ubp => delete ubp.projectRoleId);
                projectRolesToAdd.push(pr);
            } else if (JSON.stringify(pr) !== JSON.stringify(initialProjectRole))
                projectRolesToUpdate.push(pr);
        });

        let allProjectRoles = [];
        const submitDone = (roles) => {
            allProjectRoles = [...(allProjectRoles || []), ...(roles || [])];
            if (allProjectRoles.length === projectRolesToAdd.length + projectRolesToUpdate.length) {
                const selectedRole = allProjectRoles.find(apr => (isNaN(this.props.projectRole.id) && apr.label === this.props.projectRole.label && apr.color === this.props.projectRole.color && apr.icon === this.props.projectRole.icon) || apr.id === this.props.projectRole.id);
                if (selectedRole) this.props.updateRole(selectedRole);

                const newInitialProjectRoles = [...JSON.parse(JSON.stringify(this.props.projectRoles)).filter(pr => !allProjectRoles.find(apr => apr.id === pr.id)), ...allProjectRoles].filter(pr => pr.id);
                newInitialProjectRoles.forEach(pr => {
                    if (!pr.userBaseProjects) pr.userBaseProjects = [];
                });

                const userBaseProjects = newInitialProjectRoles.flatMap(pr => pr.userBaseProjects.map(ubp => ({ ...ubp, projectRole: newInitialProjectRoles.find(ipr => ipr.id === ubp.projectRoleId) })));
                const projectCollaborators = [...userBaseProjects];
                this.props.projectToEdit.userBaseProjects.forEach(ubp => {
                    if (!projectCollaborators.find(pc => pc.userId === ubp.userId))
                        projectCollaborators.push(ubp);
                });

                if (this.props.projects || this.props.project?.id === this.props.projectToEdit.id) {
                    let project = JSON.parse(JSON.stringify(this.props.projectToEdit));
                    project = { ...project, userBaseProjects: projectCollaborators, projectRoles: newInitialProjectRoles };
                    ProjectsUtil.updateProjectsInProps(project, this.props.projects, this.props.formulas, this.props.project, this.props.setProjects, this.props.setProject)
                    this.props.setProjectCollaborators(projectCollaborators);
                }

                this.props.setInitialProjectRoles(newInitialProjectRoles);
                const usersToAlert = this.props.userProjects
                    .filter(up => up.user.id !== jwtDecode(new Cookies().get('token')).id)
                    .map(up => up.user.id);
                WebSocketUtil.updateProjectRoles(this.props.webSocketHubs, usersToAlert, newInitialProjectRoles, this.props.projectToEdit.path);
                WebSocketUtil.updateUserBaseProjects(this.props.webSocketHubs, usersToAlert, userBaseProjects, this.props.projectToEdit.path);

                this.setState({ isLoading: false });
            }
        }

        if (projectRolesToAdd.length) RolesService.addRoles(projectRolesToAdd).then(roles => submitDone(roles));
        if (projectRolesToUpdate.length) RolesService.updateRoles(projectRolesToUpdate).then(roles => submitDone(roles));
    }

    saveRoleTemplate = () => {
        const { projectRole } = this.props;
        const { newTemplateLabel } = this.state;

        const roleTemplate = { ...projectRole, label: newTemplateLabel };
        delete roleTemplate.id;
        this.setState({ isSaving: true });
        RolesService.addTemplate(roleTemplate).then(roleTemplate => {
            if (roleTemplate) {
                if (this.props.roleTemplates)
                    this.props.setRoleTemplates([...this.props.roleTemplates, roleTemplate]);
            }

            this.setState({ newTemplateLabel: '', isSaving: false });
        });
    }

    restoreRoleTemplate = (_, { value }) => {
        const { id, label, userId, user, ...roleTemplate } = this.props.roleTemplates.find(rt => rt.id === value);
        this.props.updateRole({ ...this.props.projectRole, ...roleTemplate });
    }

    removeRoleTemplate = (templateId) => {
        this.setState({ isDeleting: true });
        RolesService.removeTemplate(templateId).then(() => {
            const { roleTemplates } = this.props;
            if (roleTemplates) this.props.setRoleTemplates(roleTemplates.filter(template => template.id !== templateId));
            this.setState({ isDeleting: false, templateToRemove: null });
        });
    }

    handleContextMenu = (event, projectRole) => {
        this.contextMenuElement = projectRole;
        contextMenu.show({ event, id: 'context-menu' });
        this.forceUpdate();
    }
}

const mapStateToProps = (state) => {
    return {
        project: state.project,
        projects: state.projects,
        formulas: state.formulas,
        isOnline: state.isOnline,
        isDarkTheme: state.isDarkTheme,
        userProjects: state.userProjects,
        loginAsData: state.loginAsData,
        roleTemplates: state.roleTemplates,
        webSocketHubs: state.webSocketHubs,
    };
};

const mapDispatchToProps = {
    setProject,
    setProjects,
    setExitFormWithChanges,
    setRoleTemplates,
    setProjectCollaborators
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleForm);