import React, { Component } from 'react';
// Composants
import { Table, Icon } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GaugeProgressBar from '../Utils/GaugeProgressBar';
// Librairies
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';
import { faFileAlt, faFlowerTulip, faTablePicnic, faTree, faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { isMobileOnly } from 'react-device-detect';
// Utils
import FormattersUtil from '../../utils/FormattersUtil';

class SubscriptionRightList extends Component {
    render() {
        const subscriptionFeatures = this.getSubscriptionFeatures(this.props.activeOrganization);

        return (
            <>
                <div style={{ display: 'flex', flexDirection: isMobileOnly ? 'column' : 'row', gap: !isMobileOnly && '10px', width: '100%' }}>
                    {this.renderGauge(i18n.t("Projets"), faFileAlt, 'nbProjects')}
                    {this.renderGauge(i18n.t("Arbres"), faTree, 'nbTrees')}
                </div>
                <div style={{ display: 'flex', flexDirection: isMobileOnly ? 'column' : 'row', gap: !isMobileOnly && '10px', width: '100%' }}>
                    {this.renderGauge(i18n.t("Espaces verts"), faFlowerTulip, 'nbGreenSpaces')}
                    {this.renderGauge(i18n.t("Mobiliers"), faTablePicnic, 'nbFurnitures')}
                </div>
                <Table celled>
                    <Table.Body>{this.renderSubscriptionFeatures(subscriptionFeatures)}</Table.Body>
                </Table>
            </>
        );
    }

    renderSubscriptionFeatures = (subscriptionFeatures) => {
        return subscriptionFeatures.map(feature => {
            const { label, value } = feature;
            return (
                <Table.Row key={label}>
                    <Table.Cell>{label}</Table.Cell>
                    <Table.Cell textAlign='center'>{value}</Table.Cell>
                </Table.Row>
            );
        });
    }

    renderGauge = (label, icon, propertyName) => {
        const { activeOrganization, userSubscriptionConsumptions } = this.props;

        const total = userSubscriptionConsumptions?.length ? userSubscriptionConsumptions.reduce((accumulator, value) => accumulator += value[propertyName], 0) : 0;
        const subscriptionMaxValue = activeOrganization.subscription[propertyName];
        const isUnlimited = subscriptionMaxValue === -1;
        const isExceeding = total > subscriptionMaxValue;
        let maxValue = subscriptionMaxValue;
        if (isUnlimited || isExceeding) maxValue = userSubscriptionConsumptions?.length ? total : 0;
        const users = userSubscriptionConsumptions?.length ? activeOrganization.linkedUsers.filter(user => userSubscriptionConsumptions.find(usc => usc.userId === user.id && usc[propertyName])) : [];
        const isCloseToLimit = !isUnlimited && total >= (maxValue * 0.95);

        return (
            <div style={{ flex: 1 }}>
                <h4 style={{ margin: '10px 0 5px 0' }}>
                    <FontAwesomeIcon icon={icon} style={{ marginRight: '10px' }} />
                    {label} ({`${total}/${isUnlimited ? i18n.t("Illimité") : !isExceeding ? maxValue : subscriptionMaxValue}`})
                    {isCloseToLimit && <FontAwesomeIcon icon={faTriangleExclamation} style={{ marginLeft: '10px', color: total >= subscriptionMaxValue ? 'var(--red-100)' : 'var(--yellow-100)' }} />}
                </h4>
                <GaugeProgressBar
                    values={users.map((user) => {
                        const value = userSubscriptionConsumptions?.length
                            ? userSubscriptionConsumptions.find(usc => usc.userId === user.id)[propertyName]
                            : 0;
                        const formattedPercent = (value / maxValue * 100) % 1 ? ((value / maxValue * 100).toFixed(2)) : (value / maxValue * 100);
                        return ({
                            value,
                            maxValue,
                            popupContent: (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div>{i18n.t("Utilisateur")} : {FormattersUtil.formatLastNameAndFirstName(user.lastName, user.firstName)}</div>
                                    <div>{i18n.t("Quantité")} : {value}{!isUnlimited && ` (${formattedPercent}%)`}</div>
                                </div>
                            )
                        });
                    })}
                />
            </div>
        );
    }

    getSubscriptionFeatures = (activeOrganization) => {
        const subscription = activeOrganization?.subscription;

        if (subscription) {
            return [
                ...[
                    { label: i18n.t("Nombre maximum de collaborateurs par projet"), property: 'nbCollaborators' },
                    { label: i18n.t("Nombre maximum d'utilisateurs"), property: 'nbUsers' }
                ].map(field => ({ label: field.label, value: subscription[field.property] !== -1 ? subscription[field.property] : i18n.t("Illimité").toLowerCase() })),
                ...[
                    { label: i18n.t("Actions"), property: 'actions' },
                    { label: i18n.t("Évènements"), property: 'events' },
                    { label: i18n.t("Projets personnalisés"), property: 'customProjects' },
                    { label: i18n.t("Export de données"), property: 'export' },
                    { label: i18n.t("Statistiques"), property: 'statistics' },
                    { label: i18n.t("Graphiques"), property: 'charts' },
                    { label: i18n.t("Indicateur de canopée"), property: 'canopyFormula' },
                    { label: i18n.t("Stock carbone"), property: 'carbonStockFormula' },
                    { label: i18n.t("Valeur d'agrément"), property: 'amenityFormula' },
                    { label: i18n.t("Filtres"), property: 'filters' },
                    { label: i18n.t("Indicateur de rafraîchissement"), property: 'coolingFormula' },
                    { label: i18n.t("Rappels par mail"), property: 'reminderMails' },
                    { label: i18n.t("Partage de fichiers"), property: 'filesSharing' },
                    { label: i18n.t("Import de données"), property: 'import' },
                    { label: i18n.t("Estimation des coûts"), property: 'costEstimation' },
                    { label: i18n.t("Tableau de données"), property: 'dataTable' },
                    { label: i18n.t("Graphiques personnalisés"), property: 'customCharts' },
                    { label: i18n.t("Champs personnalisés"), property: 'customFields' },
                    { label: i18n.t("Thèmes"), property: 'branding' },
                    { label: i18n.t("Support technique priorisé"), property: 'helpPriority' }
                ].map(field => ({
                    label: field.label,
                    value: <Icon name={subscription[field.property] ? 'check' : 'times'} color={subscription[field.property] ? 'green' : 'red'} />
                }))
            ];
        } else return [];
    }
}

const mapStateToProps = (state) => {
    return {
        activeOrganization: state.activeOrganization
    };
}

export default connect(mapStateToProps)(SubscriptionRightList);