import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';
import i18n from '../locales/i18n';
// Utils
import OfflineUtil from '../utils/OfflineUtil';
import { showLoadingToast, showToast } from '../utils/ToastsUtil';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class TreesService {
    static addTree(tree, action, webSocketHubs) {
        tree.id = uuidv4();
        tree.properties.treeId = tree.id;
        tree.properties.dimensions.propertiesId = tree.id;
        const promise = Axios.post(BACKEND_ENDPOINT + 'trees/' + webSocketHubs?.elementsHub?.connectionId, tree, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            tree.properties = response.data.properties;
            return response;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            else {
                if (tree.projectId) {
                    OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'trees/isMaxReached/' + tree.projectId, (body) => {
                        if (body.state) showToast('trees_limit_reached');
                        else if (body.maxElement > -1 && (body.maxElements - (body.nbElements + 1)) <= 10) {
                            body.nbElements++;
                            showToast('trees_limit_almost_reached', '(' + body.nbElements + '/' + body.maxElements + ')');
                            if (body.maxElements === body.nbElements) body.state = true;
                        } else body.nbElements++;
                        return body;
                    });
                }
                else if (tree.projectId) showToast('trees_limit_verification_failed');
            }
            return Promise.reject();
        });

        const toastId = 'tree_' + (action === 'copyPasting' ? 'copy_pasting' : action);
        const successId = 'tree_' + (action === 'copyPasting' ? 'copy_pasted' : action.replace('ing', 'ed'));
        const errorId = 'tree_' + (action === 'adding' ? 'addition' : 'copy_paste') + '_failed';
        if (navigator.onLine) showLoadingToast(toastId, successId, errorId, promise);
        else showToast('will_sync');
        return promise;
    }

    static addTrees(trees, action, webSocketHubs) {
        trees.forEach(t => {
            t.id = uuidv4();
            t.properties.treeId = t.id;
            t.properties.dimensions.propertiesId = t.id;
        });
        const promise = Axios.post(BACKEND_ENDPOINT + 'trees/bulk/' + webSocketHubs?.elementsHub?.connectionId, trees, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            response.data.forEach(t => {
                let tree = trees.find(x => x.id === t.id);
                tree.properties = t.properties;
            });
            return response;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            else {
                if (trees[0].projectId) {
                    OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'trees/isMaxReached/' + trees[0].projectId, (body) => {
                        if (body.state) showToast('trees_limit_reached');
                        else if (body.maxElement > -1 && (body.maxElements - (body.nbElements + trees.length)) <= 10) {
                            body.nbElements += trees.length;
                            showToast('trees_limit_almost_reached', '(' + body.nbElements + '/' + body.maxElements + ')');
                            if (body.maxElements === body.nbElements) body.state = true;
                        } else body.nbElements += trees.length;
                        return body;
                    });
                }
                else if (trees[0].projectId) showToast('trees_limit_verification_failed');
            }
            return Promise.reject();
        });

        const prefix = trees.length > 1 ? 'trees_' : 'tree_';
        const toastId = prefix + (action === 'copyPasting' ? 'copy_pasting' : action);
        const successId = prefix + (action === 'copyPasting' ? 'copy_pasted' : action.replace('ing', 'ed'));
        const errorId = prefix + (action === 'adding' ? 'addition' : action === 'copyPasting' ? 'copy_paste' : 'duplication') + '_failed';
        if (navigator.onLine) showLoadingToast(toastId, successId, errorId, promise, trees.length);
        else showToast('will_sync');
        return promise;
    }

    static getTrees(projectId, data) {
        return Axios.post((!BACKEND_ENDPOINT.includes('localhost') && (data?.page || data?.page === 0)
            ? BACKEND_ENDPOINT.replace('://', '://' + (Math.floor(Math.random() * 10) + 1) + '.')
            : BACKEND_ENDPOINT) + 'trees/projects/' + projectId, data, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine && !data?.cacheKey && !data?.cursor) showToast('trees_loading_failed');
            return;
        });
    }

    static getProjectAmenityValue(id) {
        return Axios.get(BACKEND_ENDPOINT + 'trees/getProjectAmenityValue/' + id).then(response => {
            return response.data;
        }, (error) => {
            if (navigator.onLine && error?.response) {
                if (error.response.data === 'Project is importing data') showToast('import_in_progress');
                else showToast('amenity_value_update_failed');
            }
            return;
        });
    }

    static removeTrees(trees, webSocketHubs) {
        const promise = Axios.delete(BACKEND_ENDPOINT + 'trees/' + webSocketHubs?.elementsHub?.connectionId, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` },
            data: trees
        }).then(response => {
            return response;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            else {
                if (trees[0].projectId !== 0) {
                    OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'trees/isMaxReached/' + trees[0].projectId, (body) => {
                        body.nbElements -= trees.length;
                        if (body.maxElement > -1 && (body.maxElements - body.nbElements) <= 10) body.state = false;
                        return body;
                    });
                }
            }
            return Promise.reject();
        });

        const prefixe = trees.length > 1 ? 'trees' : 'tree';
        if (navigator.onLine) showLoadingToast(prefixe + '_deleting', prefixe + '_deleted', prefixe + '_deletion_failed', promise);
        else showToast('will_sync');
        return promise;
    }

    static updateTree(id, tree, history, action, replanting, webSocketHubs) {
        if (tree.properties.toCutDown === 4) { // Si on modifie un arbre abattu, c'est qu'on le replante
            OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'actions/projectActions/' + tree.projectId, (body) => {
                body.forEach(projectAction => projectAction.projectActionElements = projectAction.projectActionElements.filter(x => x.elementId !== id));
                return body;
            });
        }

        const promise = Axios.put(BACKEND_ENDPOINT + 'trees/?connectionId=' + webSocketHubs?.elementsHub?.connectionId + '&id=' + id + '&history=' + history + '&replanting=' + replanting, tree, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            if (replanting) tree.properties.toCutDown = 0;
            tree.properties = { ...tree.properties, ...response.data.tree.properties };

            return response;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            return Promise.reject();
        });

        const toastId = 'tree_' + action;
        const successId = 'tree_' + action.replace('ing', 'ed');
        const errorId = 'tree_' + (action === 'updating' ? 'update'
            : action === 'restoring' ? 'restoration'
                : action) + '_failed';
        if (navigator.onLine) showLoadingToast(toastId, successId, errorId, promise);
        else showToast('will_sync');
        return promise;
    }

    static bulkUpdateTrees(trees, action, webSocketHubs) {
        const promise = Axios.put(BACKEND_ENDPOINT + 'trees/bulk/' + webSocketHubs?.elementsHub?.connectionId, trees, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            if (Array.isArray(response?.data)) {
                response.data.forEach(feature => {
                    let tree = trees.find(tree => tree.id === feature.id);
                    if (tree) tree.properties = { ...tree.properties, ...feature.properties };
                });
            }
            return response;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            return Promise.reject();
        });

        const toastId = 'trees_' + action;
        const successId = 'trees_' + action.replace('ing', 'ed');
        const errorId = 'trees_' + (action === 'updating' ? 'update'
            : action === 'restoring' ? 'restoration'
                : action) + '_failed';
        if (navigator.onLine) showLoadingToast(toastId, successId, errorId, promise);
        else showToast('will_sync');
        return promise;
    }

    static getTreeHistory(id) {
        return Axios.get(BACKEND_ENDPOINT + 'trees/history/?id=' + id, {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) if (navigator.onLine) showToast('element_history_loading_failed');
            return;
        });
    }

    static getTreeCharts(id) {
        return Axios.get(BACKEND_ENDPOINT + 'trees/charts/?id=' + id, {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) if (navigator.onLine) showToast('element_charts_loading_failed'); // TODO
            return;
        });
    }

    static getEssences() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/essences', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, (e) => {
            if (navigator.onLine) showToast('essences_loading_failed');
            return;
        });
    }

    static getCoverTypes() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/coverTypes', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('cover_types_loading_failed');
            return;
        });
    }

    static getInteractions() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/interactions', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('interactions_loading_failed');
            return;
        });
    }

    static getMicroHabitats() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/microHabitats', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('micro_habitats_loading_failed');
            return;
        });
    }

    static getRootSymptoms() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/rootSymptoms', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('root_symptoms_loading_failed');
            return;
        });
    }

    static getCollarSymptoms() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/collarSymptoms', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('collar_symptoms_loading_failed');
            return;
        });
    }

    static getTrunkSymptoms() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/trunkSymptoms', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('trunk_symptoms_loading_failed');
            return;
        });
    }

    static getBranchSymptoms() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/branchSymptoms', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('branch_symptoms_loading_failed');
            return;
        });
    }

    static getLeafSymptoms() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/leafSymptoms', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('leaf_symptoms_loading_failed');
            return;
        });
    }

    static getPathogens() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/pathogens', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('pathogens_loading_failed');
            return;
        });
    }

    static getPests() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/pests', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('pests_loading_failed');
            return;
        });
    }

    static getEpiphytes() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/epiphytes', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('epiphytes_loading_failed');
            return;
        });
    }

    static getVigors() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/vigors', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('vigors_loading_failed');
            return;
        });
    }

    static getHealthReviews() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/healthReviews', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('health_reviews_loading_failed');
            return;
        });
    }

    static getOntogenicStages() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/ontogenicStages', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('ontogenic_stages_loading_failed');
            return;
        });
    }

    static getRisks() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/risks', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('risks_loading_failed');
            return;
        });
    }

    static getTippingRisks() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/tippingRisks', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('tipping_risks_loading_failed');
            return;
        });
    }

    static getOrganCalibers() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/organCalibers', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('organ_calibers_loading_failed');
            return;
        });
    }

    static getTargets() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/targets', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('targets_loading_failed');
            return;
        });
    }

    static getTreePorts() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/treePorts', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('tree_ports_loading_failed');
            return;
        });
    }

    static getPlantationTypes() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/plantationTypes', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('plantation_types_loading_failed');
            return;
        });
    }

    static getPlantationCoefficients() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/plantationCoefficients', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('plantation_coefficients_loading_failed');
            return;
        });
    }

    static getSituationCoefficients() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/situationCoefficients', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('situation_coefficients_loading_failed');
            return;
        });
    }

    static getPatrimonialCoefficients() {
        return Axios.get(BACKEND_ENDPOINT + 'trees/patrimonialCoefficients', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('patrimonial_coefficients_loading_failed');
            return;
        });
    }

    static exportTreesFromProjectAsExcel(name, id, elementIds = [], projection) {
        return Axios.post(BACKEND_ENDPOINT + 'trees/exportXLSX/' + id, { elementIds, projection }, {
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`
            }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${id} - ${name} (${i18n.t("Arbres").toLowerCase()}).xlsx`);
            document.getElementById('modal-content').appendChild(link);
            link.click();
            document.getElementById('modal-content').removeChild(link);
        }, () => {
            if (navigator.onLine) showToast('elements_xlsx_export_failed');
        });
    }

    static exportTreeAsPDF(tree, image, photosId, colorless, toastCustomTexts) {
        return Axios.post(BACKEND_ENDPOINT + 'trees/exportPDF/' + tree.id, { image, photosId, colorless }, {
            responseType: 'arraybuffer',
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`,
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            let filename = tree.properties.projectReference ? `${i18n.t("Fiche arbre")}_${tree.properties.projectReference}` : i18n.t("Fiche arbre");
            if (tree.properties.customReference) filename += '_' + tree.properties.customReference;
            filename += '.pdf';
            link.setAttribute('download', filename);
            document.getElementById('tasksStatus').appendChild(link);
            link.click();
            document.getElementById('tasksStatus').removeChild(link);
            showToast('pdf_export_completed', ...toastCustomTexts);
        }, () => {
            if (navigator.onLine) showToast('elements_pdf_export_failed');
        });
    }

    static exportTreesAsPDF(projectId, elementIds, { projection, colorless = false, addPhotos = true } = {}) {
        return Axios.post(BACKEND_ENDPOINT + 'trees/exportAllPDF/' + projectId, { elementIds, projection, colorless, addPhotos }, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response;
        }, () => {
            if (navigator.onLine) showToast('elements_pdf_export_failed');
            return;
        });
    }

    static exportTreesAsSHP(projectLabel, projectId, elementIds, projection) {
        return Axios.post(BACKEND_ENDPOINT + 'trees/exportSHP/' + projectId, { elementIds, projection }, {
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`
            }
        }).then(response => {
            if (response.data?.size > 0) {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${projectId} - ${projectLabel} (${i18n.t("Arbres").toLowerCase()}).zip`);
                document.getElementById('modal-content').appendChild(link);
                link.click();
                document.getElementById('modal-content').removeChild(link);
            }
        }, () => {
            if (navigator.onLine) showToast('elements_shp_export_failed');
        });
    }

    static addEssence(essence) {
        return Axios.post(BACKEND_ENDPOINT + 'admin/trees/essences/', essence, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('essence_added');
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('essence_addition_failed');
            return;
        });
    }

    static updateEssences(essences) {
        return Axios.put(BACKEND_ENDPOINT + 'admin/trees/essences/', essences, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('essences_updated');
            return response.status;
        }, () => {
            if (navigator.onLine) showToast('essences_update_failed');
            return;
        });
    }

    static deleteEssences(ids) {
        return Axios.delete(BACKEND_ENDPOINT + 'admin/trees/essences/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` },
            data: { ids: ids }
        }).then(response => {
            return response.status;
        }, () => {
            showToast(ids.length > 1 ? 'essences_deletion_failed' : 'essence_deletion_failed');
            return;
        });
    }
}
