import React, { Component } from 'react';
// Composants
import { Button, Form, Label, Segment, Select } from 'semantic-ui-react';
import ImportElementsDropZone from '../../../Dropzones/ImportElementsDropZone';
// Librairies
import i18n from '../../../../locales/i18n';
// Utils
import FormattersUtil from '../../../../utils/FormattersUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { isMobileOnly } from 'react-device-detect';

class ImportElementsForm extends Component {
    render() {
        const { category, config } = this.props;
        const { format, duplicates, errors } = config;
        const extension = format === 'excel' ? 'xlsx' : 'zip';
        const sampleLink = category === 'trees' ? `https://gralityblob.blob.core.windows.net/blobs/Exemple de fichier d\'import d\'arbres.${extension}`
            : category === 'greenSpaces' ? `https://gralityblob.blob.core.windows.net/blobs/Exemple de fichier d\'import d\'espaces verts.${extension}`
                : category === 'furnitures' ? `https://gralityblob.blob.core.windows.net/blobs/Exemple de fichier d\'import du mobilier urbain.${extension}`
                :`https://gralityblob.blob.core.windows.net/blobs/Exemple de fichier d\'import de stations.${extension}`;

        return (
            <div className='modal-content-body'>
                <Segment style={{ height: '100%' }}>
                    <Form loading={this.props.isLoading} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <Form.Field
                            name='format' label={`${i18n.t("Format de fichier")} :`} placeholder={i18n.t("Sélectionnez un format")}
                            value={format} style={{ marginBottom: '10px' }}
                            control={Select} selectOnBlur={false} options={this.props.formats} onChange={this.props.handleConfigChange}
                        />
                        {category !== 'stations' &&
                            <Form.Field
                                name='duplicates' label={`${i18n.t("Gestion des références de projet")} :`} placeholder={i18n.t("Sélectionnez une action")}
                                value={duplicates || ''} style={{ marginBottom: '10px' }} search={FormattersUtil.searchList} control={Select} selectOnBlur={false} options={this.props.duplicates}
                                onChange={this.props.handleConfigChange}
                            />}
                        <Form.Field
                            name='errors' label={`${i18n.t("Gestion des erreurs")} :`} placeholder={i18n.t("Sélectionnez une action")}
                            value={errors || ''} style={{ marginBottom: '10px' }} control={Select} search={FormattersUtil.searchList} selectOnBlur={false} options={this.props.errors}
                            onChange={this.props.handleConfigChange}
                        />
                        <div style={{ display: 'flex', flexDirection: !isMobileOnly ? 'row' : 'column', gap: '10px', flex: 1 }}>
                            <ImportElementsDropZone category={this.props.category} format={format} files={config.files} handleConfigChange={this.props.handleConfigChange} />
                            <div style={{ display: 'flex', flexDirection: 'column', minWidth: !isMobileOnly && '450px', minHeight: isMobileOnly && '150px', border: '1px solid white', borderRadius: '5px', padding: '10px' }}>
                                <h4>Fichier(s) sélectionné(s)</h4>
                                {config?.files?.length
                                    ? <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>{this.renderFiles()}</div>
                                    : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>{i18n.t("Aucun fichier sélectionné.")}</div>}
                            </div>
                        </div>
                        <a href={sampleLink} style={{ marginTop: '5px' }}>
                            <small>{i18n.t("Télécharger un exemple de fichier")}</small>
                        </a>
                    </Form>
                </Segment>
            </div>
        );
    }

    renderFiles = () => {
        return this.props.config.files.map(file => {
            const { name, size } = file;
            const extension = name.split('.')[name.split('.').length - 1];

            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label>{name.replace(`.${extension}`, '')}</label>
                    <Label title={i18n.t("Extension du fichier")} style={{ marginLeft: 'auto' }}>{extension.toUpperCase()}</Label>
                    <Label title={i18n.t("Taille du fichier")} style={{ marginLeft: '5px', marginRight: '5px' }}>{FormattersUtil.numberPrettyBytesSI(size)}</Label>
                    <Button color='red' title={i18n.t("Supprimer le fichier")} onClick={() => this.removeFile(name)} style={{ padding: 0, height: '100%', aspectRatio: '1 / 1' }}>
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </div>
            );
        });
    }

    removeFile = (fileName) => {
        const files = this.props.config.files;
        this.props.handleConfigChange(null, { name: 'files', value: files.filter(file => file.name !== fileName) });
    }
}

export default ImportElementsForm;