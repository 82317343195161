import React, { Component } from 'react';
// Librairies
import L from 'leaflet';
import { connect } from 'react-redux';
import { setWebSocketHubs } from '../../actionCreators/appActions';
import { setProject, setProjectActions, setProjectEvents, setProjects } from '../../actionCreators/projectsActions';
import { setLayer, setElementHistory, setActionHistory, setLockedElements, setPhotosGalleries } from '../../actionCreators/elementsActions';
// Utils
import WebSocketUtil from '../../utils/WebSocketUtil';
import GeometriesUtil from '../../utils/GeometriesUtil';
import ActionsUtil from '../../utils/ActionsUtil';
import ProjectsUtil from '../../utils/ProjectsUtil';
import UsersUtil from '../../utils/UsersUtil';

class ProjectWebSocket extends Component {
    render() {
        return (<></>);
    }

    componentDidMount = () => {
        this.startHubs();

        window.addEventListener('online', () => this.startHubs());
        window.addEventListener('offline', () => this.stopHubs());
    }

    componentDidUpdate = () => this.startHubs();
    componentWillUnmount = () => this.stopHubs();
    startHubs = () => {
        if (!this.props.logged && this.props.project && !this.props.project.isPublic) return;
        if (!this.elementsHub && (this.props.publicFields?.main.trees || this.props.publicFields?.main.greenSpaces || this.props.publicFields?.main.images))
            this.handleElementsHub();
        if (!this.actionsHub && this.props.publicFields?.main.actions) this.handleActionsHub();
        if (!this.eventsHub && this.props.publicFields?.main.events) this.handleEventsHub();
    }

    stopHubs = () => {
        if (this.elementsHub) this.elementsHub.stop().then(() => this.elementsHub = null);
        if (this.actionsHub) this.actionsHub.stop().then(() => this.actionsHub = null);
        if (this.eventsHub) this.eventsHub.stop().then(() => this.eventsHub = null);
    }

    handleElementsHub = () => {
        this.elementsHub = WebSocketUtil.createConnection('elements');
        if (this.props.project?.id) {
            const handleHub = () => {
                this.props.setWebSocketHubs({ ...this.props.webSocketHubs, elementsHub: this.elementsHub });

                // Invokers
                this.elementsHub.invoke('JoinGroup', this.props.project.id.toString(), UsersUtil.getDevice());
                if (ProjectsUtil.isUserInProject(this.props.projectCollaborators)) this.elementsHub.invoke('GetLockedElements', this.props.project.id.toString());

                // Events
                this.elementsHub.on('SendElements', this.handleSendElements);
                this.elementsHub.on('UpdateElements', this.handleUpdateElements);
                this.elementsHub.on('SendElementsHistories', this.handleSendHistories);
                this.elementsHub.on('SendActionsHistories', this.handleSendActionHistories);
                this.elementsHub.on('RemoveElements', this.handleRemoveElements);
                this.elementsHub.on('ToggleElementsLock', this.handleElementsLock);
                this.elementsHub.on('GetLockedElements', connectionId => {
                    if (this.elementsHub.connectionId !== connectionId)
                        WebSocketUtil.sendLockedElementsToMember(this.props.webSocketHubs, connectionId, this.props.lockedElements);
                });
                this.elementsHub.on('SendLockedElements', lockedElements => {
                    const lockedElementsParsed = JSON.parse(lockedElements);
                    this.props.setLockedElements(lockedElementsParsed?.length ? lockedElementsParsed : []);
                });
                this.elementsHub.on('MemberDisconnected', ({ userId }) => {
                    if (!ProjectsUtil.isUserInProject(this.props.projectCollaborators)) return;
                    let lockedElements = JSON.parse(JSON.stringify(this.props.lockedElements));
                    this.props.setLockedElements(lockedElements.filter(le => le.userId !== userId));
                });
            };

            this.elementsHub.start().then(handleHub);
            this.elementsHub.onreconnected(handleHub);
        }
    }

    handleActionsHub = () => {
        this.actionsHub = WebSocketUtil.createConnection('actions');
        if (this.props.project?.id) {
            const handleHub = () => {
                this.props.setWebSocketHubs({ ...this.props.webSocketHubs, actionsHub: this.actionsHub });
                // Invokers
                this.actionsHub.invoke('JoinGroup', this.props.project.id.toString());

                // Events
                this.actionsHub.on('SendProjectActions', projectActions => {
                    const projectActionsParsed = JSON.parse(projectActions);
                    projectActions = this.props.projectActions ? JSON.parse(JSON.stringify(this.props.projectActions)) : [];
                    projectActionsParsed.forEach(projectAction => {
                        projectAction.action = this.props.actions.find(a => a.id === projectAction.actionId);
                        projectActions = ActionsUtil.pushProjectActionElements(projectAction, projectActions, true);
                    });
                    this.props.setProjectActions(projectActions);
                    this.updateTreesToCutDown(projectActions);
                });

                this.actionsHub.on('UpdateProjectActions', projectActions => {
                    const projectActionsParsed = JSON.parse(projectActions);
                    projectActions = this.props.projectActions ? JSON.parse(JSON.stringify(this.props.projectActions)) : [];

                    let recurrenceIds = [];
                    projectActionsParsed.forEach(projectAction => {
                        if (projectAction.projectActionElements.length) {
                            projectAction.action = this.props.actions.find(a => a.id === projectAction.actionId);
                            const index = projectActions.findIndex(pa => pa.id === projectAction.id);
                            if (index !== -1) {
                                const projectActionInRedux = projectActions[index];
                                // Modification des liaisons de la projectAction
                                if (JSON.stringify(projectAction.projectActionElements.map(x => x.elementId).sort()) !== JSON.stringify(projectActionInRedux.projectActionElements.map(x => x.elementId).sort())) {
                                    recurrenceIds = [...recurrenceIds, ...projectActionInRedux.projectActionElements.filter(pae => !projectAction.projectActionElements.find(pae2 => pae2.elementId === pae.elementId)).flatMap(pae => pae.projectActionElementRecurrences.map(paer => paer.id))];
                                    projectActions = ActionsUtil.pushProjectActionElements(projectAction, projectActions, false);
                                }
                                else { // Modification des informations de la projectAction
                                    projectAction.projectActionElements.forEach(pae => {
                                        if (!pae.projectActionElementRecurrences) {
                                            const projectActionElements = projectActions[index].projectActionElements.find(pae2 => pae2.elementId === pae.elementId);
                                            if (projectActionElements?.projectActionElementRecurrences) pae.projectActionElementRecurrences = projectActionElements.projectActionElementRecurrences;
                                        }
                                    });

                                    projectActions[index] = projectAction;
                                    recurrenceIds = [...recurrenceIds, ...projectActions[index].projectActionElements.filter(pae => !projectAction.projectActionElements.find(pae2 => pae2.elementId === pae.elementId)).flatMap(pae => pae.projectActionElementRecurrences.map(paer => paer.id))];
                                }
                            } else projectActions.push(projectAction);
                        }
                    });
                    this.props.setPhotosGalleries(this.props.photosGalleries.filter(photo => !recurrenceIds.includes(photo.recurrenceId)));
                    this.props.setProjectActions(projectActions);
                    this.updateTreesToCutDown(projectActions);
                });

                this.actionsHub.on('RemoveProjectActions', ids => {
                    const idsParsed = JSON.parse(ids);
                    const projectActions = this.props.projectActions ? JSON.parse(JSON.stringify(this.props.projectActions)) : [];
                    const recurrenceIds = projectActions.filter(pa => ids.includes(pa.id)).flatMap(pa => pa.projectActionElements.flatMap(pae => pae.projectActionElementRecurrences.map(paer => paer.id)));
                    this.props.setPhotosGalleries(this.props.photosGalleries.filter(photo => !recurrenceIds.includes(photo.recurrenceId)));
                    this.props.setProjectActions(projectActions.filter(x => !idsParsed.includes(x.id)));
                });

                this.actionsHub.on('UpdateRecurrences', recurrences => {
                    const recurrencesParsed = JSON.parse(recurrences);
                    let projectActions = JSON.parse(JSON.stringify(this.props.projectActions));
                    projectActions.forEach(pa => {
                        pa.projectActionElements.forEach(pae => {
                            const index = pae.projectActionElementRecurrences.findIndex(paer => recurrencesParsed.find(r => r.id === paer.id));
                            if (index !== -1) {
                                const updatedRecurrence = recurrencesParsed.find(r => r.id === pae.projectActionElementRecurrences[index].id);
                                pae.projectActionElementRecurrences[index] = updatedRecurrence;
                            }
                        });
                    });
                    this.props.setProjectActions(projectActions);
                    this.updateTreesToCutDown(projectActions);
                });
            };

            this.actionsHub.start().then(handleHub);
            this.actionsHub.onreconnected(handleHub);
        }
    }

    handleEventsHub = () => {
        this.eventsHub = WebSocketUtil.createConnection('events');
        if (this.props.project?.id) {
            const handleHub = () => {
                this.props.setWebSocketHubs({ ...this.props.webSocketHubs, eventsHub: this.eventsHub });
                // Invokers
                this.eventsHub.invoke('JoinGroup', this.props.project.id.toString());

                // Events
                this.eventsHub.on('SendProjectEvents', projectEvents => {
                    const projectEventsParsed = JSON.parse(projectEvents);
                    projectEvents = this.props.projectEvents ? JSON.parse(JSON.stringify(this.props.projectEvents)) : [];
                    projectEventsParsed.forEach(projectEvent => {
                        projectEvent.event = this.props.events.find(e => e.id === projectEvent.EventId);
                        projectEvents = [...projectEvents, projectEvent];
                    });
                    this.props.setProjectEvents(projectEvents);
                });

                this.eventsHub.on('UpdateProjectEvents', projectEvents => {
                    const projectEventsParsed = JSON.parse(projectEvents);
                    projectEvents = this.props.projectEvents ? JSON.parse(JSON.stringify(this.props.projectEvents)) : [];

                    let recurrenceIds = [];
                    projectEventsParsed.forEach(projectEvent => {
                        if (projectEvent.projectEventElements.length) {
                            projectEvent.event = this.props.events.find(e => e.id === projectEvent.eventId);
                            const index = projectEvents.findIndex(pe => pe.id === projectEvent.id);
                            if (index !== -1) {
                                const projectEventInRedux = projectEvents[index];
                                // Modification des liaisons de la projectEvent
                                if (JSON.stringify(projectEvent.projectEventElements.map(x => x.elementId).sort()) !== JSON.stringify(projectEventInRedux.projectEventElements.map(x => x.elementId).sort()))
                                    projectEvents = [...projectEvents, projectEvent];
                                else projectEvents[index] = projectEvent; // Modification des informations de la projectEvent
                            } else projectEvents.push(projectEvent);
                        }
                    });
                    this.props.setProjectEvents(projectEvents);
                    this.updateTreesToCutDown(projectEvents);
                });

                this.eventsHub.on('RemoveProjectEvents', ids => {
                    const idsParsed = JSON.parse(ids);
                    const projectEvents = this.props.projectEvents ? JSON.parse(JSON.stringify(this.props.projectEvents)) : [];
                    this.props.setProjectEvents(projectEvents.filter(x => !idsParsed.includes(x.id)));
                });

                this.eventsHub.on('UpdateProjectEventElements', projectEventElements => {
                    const projectEventElementsParsed = JSON.parse(projectEventElements);
                    let projectEvents = this.props.projectEvents ? JSON.parse(JSON.stringify(this.props.projectEvents)) : [];
                    projectEvents.forEach(pe => {
                        const index = pe.projectEventElements.findIndex(pee => projectEventElementsParsed.find(peep => peep.projectEventId === pe.id));
                        if (index !== -1) {
                            const updatedProjectEventElement = projectEventElementsParsed.find(peep => peep.projectEventId === pe.id);
                            if (updatedProjectEventElement) pe.projectEventElements[index] = updatedProjectEventElement;
                        }
                    });
                    this.props.setProjectEvents(projectEvents);
                    this.updateTreesToCutDown(projectEvents);
                });
            };

            this.eventsHub.start().then(handleHub);
            this.eventsHub.onreconnected(handleHub);
        }
    }

    handleSendElements = (elements) => {
        const elementsParsed = JSON.parse(elements);
        const type = elementsParsed[0].properties.category;
        const currentYear = new Date().getUTCFullYear();
        if ((type === 'Arbre' && !this.props.publicFields?.main.trees) || (type === 'Espace vert' && !this.props.publicFields?.main.greenSpaces)
            || (type === 'BackgroundImage' && !this.props.publicFields?.main.images))
            return;

        elementsParsed.forEach(element => {
            if (['Arbre', 'Mobilier'].includes(type)) {
                const marker = new L.marker(JSON.parse(JSON.stringify(element.geometry.coordinates)).reverse());
                if (this.props.checkIfInsideSurroundings('marker', marker)) {
                    if (!this.props.isTimelineVisible && !this.props.layerContainers.treesLayer.getLayers().find(x => x.feature.id === element.id))
                        this.props.addMarker(type, { _latlng: { lat: element.geometry.coordinates[1], lng: element.geometry.coordinates[0] } }, element);
                    else if (this.props.isTimelineVisible && type === 'Arbre' && !this.props.timelineYears[currentYear].trees.find(x => x.id === element.id))
                        this.props.setTimelineYears(currentYear, { ...this.props.timelineYears[currentYear], trees: [...this.props.timelineYears[currentYear].trees, element] });
                    else if (this.props.isTimelineVisible && type === 'Mobilier' && !this.props.timelineYears[currentYear].furnitures.find(x => x.id === element.id))
                        this.props.setTimelineYears(currentYear, { ...this.props.timelineYears[currentYear], furnitures: [...this.props.timelineYears[currentYear].furnitures, element] });
                }
            } else if (type === 'Espace vert') {
                const polygon = new L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(element.geometry.coordinates));
                if (this.props.checkIfInsideSurroundings('polygon', polygon)) {
                    if (!this.props.isTimelineVisible && !this.props.layerContainers.greenSpacesLayer.getLayers().find(x => x.feature.id === element.id))
                        this.props.addGreenSpace(new L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(element.geometry.coordinates)), element);
                    else if (this.props.isTimelineVisible && !this.props.timelineYears[currentYear].greenSpaces.find(x => x.id === element.id))
                        this.props.setTimelineYears(currentYear, { ...this.props.timelineYears[currentYear], greenSpaces: [...this.props.timelineYears[currentYear].greenSpaces, element] });
                }
            } else if (type === 'Station') {
                const polygon = new L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(element.geometry.coordinates));
                if (this.props.checkIfInsideSurroundings('polygon', polygon)) {
                    if (!this.props.layerContainers.stationsLayer.getLayers().find(x => x.feature.id === element.id))
                        this.props.addStation(new L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(element.geometry.coordinates)), element);
                }
            } else if (type === 'BackgroundImage') {
                const button = this.props.findButton('showImages');
                this.props.addBackgroundImagesToLayer(elementsParsed, elementsParsed.length - 1, button?.button?.state || false);
            }
        });
    }

    handleUpdateElements = (elements) => {
        const elementsParsed = JSON.parse(elements);
        const type = elementsParsed[0].properties.category;
        if ((type === 'Arbre' && !this.props.publicFields?.main.trees) || (type === 'Espace vert' && !this.props.publicFields?.main.greenSpaces)
            || (type === 'Mobilier' && !this.props.publicFields?.main.furnitures) || (type === 'Station' && !this.props.publicFields?.main.stations)
            || (type === 'BackgroundImage' && !this.props.publicFields?.main.images))
            return;

        if (type !== 'BackgroundImage') {
            const treesLayers = this.props.layerContainers.treesLayer.getLayers();
            const treesLayerNotClustered = this.props.layerContainers.treesLayerNotClustered && this.props.layerContainers.treesLayerNotClustered.getLayers();
            const greenSpacesLayer = this.props.layerContainers.greenSpacesLayer && this.props.layerContainers.greenSpacesLayer.getLayers();
            const furnituresLayer = this.props.layerContainers.furnituresLayer.getLayers();
            const furnituresLayerNotClustered = this.props.layerContainers.furnituresLayerNotClustered && this.props.layerContainers.furnituresLayerNotClustered.getLayers();
            const stationsLayer = this.props.layerContainers.stationsLayer && this.props.layerContainers.stationsLayer.getLayers();
            const openedLayer = Array.isArray(this.props.layer) ? this.props.layer[0] : this.props.layer;
            const currentYear = new Date().getUTCFullYear();
            const timelineElements = { ...this.props.timelineYears[currentYear] };
            elementsParsed.forEach(element => {
                if (!this.props.isTimelineVisible) {
                    let layer = type === 'Arbre' ? treesLayers.find(x => x.feature.id === element.id)
                        : type === 'Mobilier' ? furnituresLayer.find(x => x.feature.id === element.id)
                            : type === 'Espace vert' ? greenSpacesLayer.find(x => x.feature.id === element.id)
                                : stationsLayer.find(x => x.feature.id === element.id);
                    if (layer) {
                        const coordinates = ['Arbre', 'Mobilier'].includes(type) ? { lat: element.geometry.coordinates[1], lng: element.geometry.coordinates[0] }
                            : GeometriesUtil.convertPolygonCoordinatesToLatLngs(element.geometry.coordinates);
                        layer.feature = element;
                        if (['Arbre', 'Mobilier'].includes(type)) {
                            layer.setLatLng(coordinates);
                            let layerNotClustered = type === 'Arbre' ? treesLayerNotClustered.find(x => x.feature.id === element.id) : furnituresLayerNotClustered.find(x => x.feature.id === element.id);
                            if (layerNotClustered) {
                                layerNotClustered.setLatLng(coordinates);
                                layerNotClustered.feature = element;
                                if (openedLayer && element.id === openedLayer.feature.id)
                                    this.props.setLayer([layer, layerNotClustered]);
                            }
                        } else {
                            layer.setLatLngs(coordinates);
                            if (openedLayer && element.id === openedLayer.feature.id)
                                this.props.setLayer([layer]);
                        }
                    }
                } else {
                    const category = type === 'Arbre' ? 'trees'
                        : type === 'Mobilier' ? 'furnitures'
                            : type === 'Espace vert' ? 'greenSpaces'
                                : 'stations';
                    const index = timelineElements[category].findIndex(e => e.id === element.id);
                    if (index !== -1) timelineElements[category][index] = element;
                }
            });

            if (this.props.isTimelineVisible) this.props.setTimelineYears(currentYear, timelineElements);
        } else {
            const backgroundImages = this.props.layerContainers.backgroundImagesLayer.getLayers();
            const orderChanged = backgroundImages.find(bi => elementsParsed.find(e => e.id === bi.feature.id && e.properties.index !== bi.feature.properties.index));
            if (!orderChanged) {
                elementsParsed.forEach(element => {
                    let layer = backgroundImages.find(x => x.feature.id === element.id);
                    layer.feature = element;
                    layer.setCorners(element.geometry.coordinates.map(coords => ([coords[1], coords[0]])));
                });
            }
            else {
                const button = this.props.findButton('showImages');
                this.props.layerContainers.backgroundImagesLayer.clearLayers();
                elementsParsed.sort((a, b) => b.properties.index - a.properties.index);
                this.props.addBackgroundImagesToLayer(elementsParsed, elementsParsed.length - 1, button?.button?.state || false);
            }
        }
    }

    handleRemoveElements = (type, ids) => {
        if ((type === 'Arbre' && !this.props.publicFields?.main.trees) || (type === 'Espace vert' && !this.props.publicFields?.main.greenSpaces)
            || (type === 'Mobilier' && !this.props.publicFields?.main.furnitures) || (type === 'Station' && !this.props.publicFields?.main.stations)
            || (type === 'BackgroundImage' && !this.props.publicFields?.main.images))
            return;

        const { project, projects, formulas, projectActions, projectEvents } = this.props;
        const idsParsed = JSON.parse(ids);
        if (!this.props.isTimelineVisible) {
            const layerContainers = type === 'Arbre' ? [this.props.layerContainers.treesLayer, this.props.layerContainers.treesLayerNotClustered]
                : type === 'Espace vert' ? [this.props.layerContainers.greenSpacesLayer]
                    : type === 'Station' ? [this.props.layerContainers.stationsLayer]
                        : type === 'Mobilier' ? [this.props.layerContainers.furnituresLayer, this.props.layerContainers.furnituresLayerNotClustered]
                            : [this.props.layerContainers.backgroundImagesLayer];
            layerContainers.forEach(layerContainer => {
                const layers = layerContainer.getLayers();
                idsParsed.forEach(id => {
                    const layer = layers.find(x => x.feature?.id === id);
                    if (layer) layerContainer.removeLayer(layer);
                });
            });

            if (type !== 'BackgroundImage') {
                this.props.updateLegend(type === 'Arbre' ? 'Arbres' : type === 'Espace vert' ? 'Espaces verts' : 'Mobilier urbain');
                if (type === 'Arbre') this.props.updateHeatmaps();
                const openedLayer = Array.isArray(this.props.layer) ? this.props.layer[0] : this.props.layer;
                const modalContentType = type === 'Arbre' ? 'TreeDetail' : type === 'Espace vert' ? 'GreenSpaceDetail' : 'FurnitureDetail';
                if (this.props.modalContentType === modalContentType && openedLayer && idsParsed.includes(openedLayer.feature.id)) this.props.hideForm(false);
            }
        } else {
            const currentYear = new Date().getUTCFullYear();
            const timelineElements = { ...this.props.timelineYears[currentYear] };
            idsParsed.forEach(id => {
                timelineElements.trees = timelineElements.trees.filter(tree => tree.id !== id);
                timelineElements.furnitures = timelineElements.furnitures.filter(furniture => furniture.id !== id);
                timelineElements.greenSpaces = timelineElements.greenSpaces.filter(greenSpace => greenSpace.id !== id);
            });
            this.props.setTimelineYears(currentYear, timelineElements);
        }

        // Suppression des liens
        if (project.linkedElements?.length) {
            let updatedProject = JSON.parse(JSON.stringify(project));
            updatedProject.linkedElements = updatedProject.linkedElements.filter(le => !idsParsed.includes(le.elementId) && !idsParsed.includes(le.linkedElementId));
            if (updatedProject.linkedElements.length !== project.linkedElements.length)
                ProjectsUtil.updateProjectsInProps(updatedProject, projects, formulas, project, this.props.setProjects, this.props.setProject);
        }

        // Suppression des actions
        if (projectActions.length) {
            const updatedProjectsActions = [...projectActions];
            updatedProjectsActions.forEach(pa => {
                pa.projectActionElements = pa.projectActionElements.filter(pae => !idsParsed.includes(pae.elementId));
            });
            this.props.setProjectActions(updatedProjectsActions);
        }

        // Suppression des actions
        if (projectEvents.length) {
            const updatedProjectsEvents = [...projectEvents];
            updatedProjectsEvents.forEach(pe => {
                pe.projectEventElements = pe.projectEventElements.filter(pee => !idsParsed.includes(pee.elementId));
            });
            this.props.setProjectEvents(updatedProjectsEvents);
        }
    }

    handleElementsLock = (ids, areLocked, userId) => {
        if (!ProjectsUtil.isUserInProject(this.props.projectCollaborators)) return;

        const idsParsed = JSON.parse(ids);
        let lockedElements = JSON.parse(JSON.stringify(this.props.lockedElements));
        if (lockedElements.find(le => idsParsed.includes(le.id))) {
            if (areLocked) {
                idsParsed.forEach(id => {
                    const index = lockedElements.findIndex(le => le.id === id);
                    if (index !== -1) lockedElements[index].userId = userId;
                    else lockedElements.push({ id, userId });
                });
            }
            else lockedElements = lockedElements.filter(le => !idsParsed.includes(le.id));
        } else if (areLocked)
            idsParsed.forEach(id => lockedElements.push({ id, userId }));
        this.props.setLockedElements(lockedElements);
    }

    handleSendHistories = (histories) => {
        const historiesParsed = JSON.parse(histories);
        const elementType = historiesParsed[0].hasOwnProperty('tree') ? 'tree' : historiesParsed[0].hasOwnProperty('greenSpace') ? 'greenSpace' : 'furniture';
        if ((elementType === 'tree' && !this.props.publicFields?.main.trees) || (elementType === 'greenSpace' && !this.props.publicFields?.main.greenSpaces)
            || (elementType === 'furniture' && !this.props.publicFields?.main.furnitures))
            return;

        if (this.props.elementHistory.find(x => historiesParsed.find(y => y[elementType].id === x[elementType].id)))
            this.props.setElementHistory([...this.props.elementHistory || [], ...historiesParsed]);
    }

    handleSendActionHistories = (histories) => {
        if (!this.props.publicFields?.main.actions || !this.props.actionHistory?.length) return;
        const historiesParsed = JSON.parse(histories);

        const filteredHistories = historiesParsed.filter(history => history.elementId === this.props.actionHistory[0].elementId);
        if (filteredHistories.length > 0) this.props.setActionHistory([...this.props.actionHistory || [], ...filteredHistories]);
    }

    updateTreesToCutDown = (projectActions) => {
        const treesToCutDown = this.props.getTreesToCutDown(projectActions);
        this.props.updateToCutDown(this.props.layerContainers.treesLayer, treesToCutDown);
        this.props.updateToCutDown(this.props.layerContainers.treesLayerNotClustered, treesToCutDown);
        this.props.updateLegend('Arbres');
    }
}

const mapStateToProps = (state) => {
    return {
        actions: state.actions,
        events: state.events,
        project: state.project,
        projects: state.projects,
        projectCollaborators: state.projectCollaborators,
        formulas: state.formulas,
        layer: state.layer,
        elementHistory: state.elementHistory,
        actionHistory: state.actionHistory,
        projectActions: state.projectActions,
        projectEvents: state.projectEvents,
        lockedElements: state.lockedElements,
        webSocketHubs: state.webSocketHubs,
        photosGalleries: state.photosGalleries
    };
};

const mapDispatchToProps = {
    setProject,
    setProjects,
    setLayer,
    setElementHistory,
    setActionHistory,
    setProjectActions,
    setProjectEvents,
    setLockedElements,
    setWebSocketHubs,
    setPhotosGalleries
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectWebSocket);