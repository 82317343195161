import React, { Component } from 'react';
// Composants
import { Button, Divider, Header, Loader, Popup } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { connect } from 'react-redux';
import i18n from '../../../../locales/i18n';
import { faCheckCircle, faDownload, faRotateRight, faTimesCircle, faWarning } from '@fortawesome/pro-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';
// Services
import FilesService from '../../../../services/FilesService';
import AppSettings from '../../../../AppSettings';

class ImportResult extends Component {
    state = {
        importStatus: 0,
        canRedoImport: false,
        estimatedTime: i18n.t("Indéterminé"),
        elapsedTime: 0,
        steps: [
            {
                id: 1, status: 0, loadingMessage: i18n.t("Envoi du fichier ({{percentage}}%)...", { percentage: 0 }), successMessage: i18n.t("Fichier envoyé"),
                errorMessage: i18n.t("Impossible d'envoyer le fichier")
            },
            {
                id: 2, status: 0, loadingMessage: i18n.t("Récupération des données..."), successMessage: i18n.t("Données récupérées ({{nbMax}})", { nbMax: 0 }),
                errorMessage: i18n.t("Impossible de récupérer les données")
            },
            {
                id: 3, status: 0, nbSuccess: 0, nbWarnings: 0, nbErrors: 0,
                loadingMessage: i18n.t("Traitement des données ({{nb}})...", { nb: 0 }),
                successMessage: i18n.t("Données traitées ({{nb}})", { nb: 0 }), errorMessage: i18n.t("Impossible de traiter les données"), isSubscriptionLimitReached: false
            },
            {
                id: 4, status: 0, loadingMessage: i18n.t("Ajout des données ({{nb}})...", { nb: 0 }), successMessage: i18n.t("Données ajoutées ({{nb}})", { nb: 0 }),
                errorMessage: i18n.t("Impossible d'ajouter les données"), isSubscriptionLimitReached: false
            }
        ]
    };

    render() {
        const { category } = this.props;
        const { importStatus, canRedoImport } = this.state;

        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '40%', minWidth: '800px', height: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div style={{ position: 'relative', width: '100%', textAlign: 'center', marginBottom: '10px' }}>
                    <div>
                        <FontAwesomeIcon icon={faDownload} size='6x' />
                        <Header as='h1'>{!importStatus ? i18n.t("Import en cours") : importStatus === 1 ? i18n.t("Import réussi") : i18n.t("Import échoué")}</Header>
                    </div>
                    <Button color='blue' disabled={!canRedoImport} style={{ position: 'absolute', right: 0, bottom: 0 }} onClick={this.props.redoImport}>
                        <FontAwesomeIcon icon={faRotateRight} style={{ marginRight: '10px' }} />{i18n.t("Refaire un import")}
                    </Button>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    {this.renderConfig()}
                    {this.renderImportSteps()}
                </div>
                <small style={{ color: 'var(--grey-100)', marginTop: '10px' }}>{i18n.t("Vous pouvez consulter l'état d'avancement de l'import depuis l'historique du projet, ainsi que via votre boîte mail.")}</small>
                {category === 'Photos' && <small style={{ color: 'var(--grey-100)', margin: 0 }}>{i18n.t("L'import de photos ne prend pas en charge la détection de doublons.")}</small>}
            </div>
        );
    }

    componentDidMount = () => {
        if (this.props.category !== 'Photos')
            this.setState(prevState => ({
                steps: [
                    { ...prevState.steps[0], status: 1 },
                    ...prevState.steps.filter(step => step.id !== 1)
                ]
            }))

        this.handleFormsHub();
        this.handleSubmit();

        this.lastImportInfosProcess = null;
        this.lastFileUploadDate = null;
        this.lastElementsProcessAndUploadDate = null;
        this.shouldEstimateTime = true;
        this.intervalId = setInterval(() => {
            let { importStatus } = this.state;
            this.setState(prevState => ({ elapsedTime: prevState.elapsedTime + 1000 }));
            if (importStatus) clearInterval(this.intervalId);
        }, 1000);
    }

    componentWillUnmount = () => {
        if (this.props.webSocketHubs?.formsHub) this.props.webSocketHubs.formsHub.stop();
        if (this.intervalId) clearInterval(this.intervalId);
    }

    renderConfig = () => {
        const { category, config, formats, projections } = this.props;
        const format = formats.find(f => f.value === config.format);
        const projection = projections.find(p => p.value === config.projectionId);
        const duplicates = this.props.duplicates.find(d => d.value === config.duplicates);
        const errors = this.props.errors.find(e => e.value === config.errors);

        return (
            <div style={{ padding: '20px', marginRight: '10px', border: '1px solid var(--grey-100)', borderRadius: '5px' }}>
                <Header as='h2'>{i18n.t("Configuration")}</Header>
                <Divider />
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: category === 'Photos' && '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>{i18n.t("Format")}</div>
                            <div style={{ width: '100%', marginBottom: '5px', padding: '4px 10px', backgroundColor: 'rgba(255, 255, 255, 0.1)', border: '1px dashed var(--grey-100)', borderRadius: '5px' }}>{category !== 'Photos' ? format.text : 'Zip'}</div>
                        </div>
                        {category !== 'Photos' &&
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                                <div>{i18n.t("Projection source")}</div>
                                <div style={{ padding: '4px 10px', backgroundColor: 'rgba(255, 255, 255, 0.1)', border: '1px dashed var(--grey-100)', borderRadius: '5px' }}>{projection.text}</div>
                            </div>}
                    </div>
                    {category !== 'Photos' &&
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>{i18n.t("Gestion des doublons")}</div>
                                <div style={{ marginBottom: '5px', padding: '4px 10px', backgroundColor: 'rgba(255, 255, 255, 0.1)', border: '1px dashed var(--grey-100)', borderRadius: '5px' }}>{duplicates.text}</div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                                <div>{i18n.t("Gestion des erreurs")}</div>
                                <div style={{ padding: '4px 10px', backgroundColor: 'rgba(255, 255, 255, 0.1)', border: '1px dashed var(--grey-100)', borderRadius: '5px' }}>{errors.text}</div>
                            </div>
                        </div>}
                </div>
            </div>
        );
    }

    renderImportStep = (step) => {
        const index = this.state.steps.findIndex(s => s.id === step.id);
        const status = step.status;

        return (
            <div key={step.id} style={{ display: 'flex', alignItems: 'center', marginTop: index && '15px' }}>
                {!status ?
                    <Loader className={!this.props.isDarkTheme && 'import-step-loader'} active inline size='small' style={{ marginRight: '10px' }} />
                    : status === 1 ?
                        <FontAwesomeIcon icon={faCheckCircle} color='green' style={{ fontSize: '24px', marginRight: '10px' }} />
                        : <FontAwesomeIcon icon={faTimesCircle} color='red' style={{ fontSize: '24px', marginRight: '10px' }} />}
                <p style={{ fontSize: '16px', margin: 0 }}>{!status ? step.loadingMessage : status === 1 ? step.successMessage : step.errorMessage}</p>
                {step.id === 3 && this.props.category !== 'Photos' &&
                    <>
                        <FontAwesomeIcon icon={faCheckCircle} color='green' style={{ marginLeft: '10px', marginRight: '5px' }} />{step.nbSuccess}
                        <Popup
                            content={i18n.t("Éléments ayant des problèmes mais pouvant quand même être importés")} position='left center'
                            trigger={<div style={{ marginLeft: '10px' }}>
                                <FontAwesomeIcon icon={faWarning} color='orange' style={{ marginRight: '5px' }} />{step.nbWarnings}
                            </div>}
                        />
                        <Popup
                            content={i18n.t("Éléments ayant des problèmes ne permettant pas l'import")} position='left center'
                            trigger={<div style={{ marginLeft: '10px' }}>
                                <FontAwesomeIcon icon={faTimesCircle} color='red' style={{ marginRight: '5px' }} />{step.nbErrors}
                            </div>}
                        />
                    </>}
                {step.id === 4 && step.isSubscriptionLimitReached
                    && <Popup
                        content={i18n.t("L'abonnement ayant atteint sa limite, seuls certains éléments ont pu être importés.")} position='left center'
                        trigger={<FontAwesomeIcon icon={faWarning} color='orange' style={{ marginLeft: '10px' }} />}
                    />}
            </div>
        );
    }

    renderImportSteps = () => {
        const { estimatedTime, elapsedTime } = this.state;
        const estimatedTimeLeftAsString = typeof estimatedTime !== 'string' ? new Date(estimatedTime).toISOString().slice(11, 19) : estimatedTime;
        const elapsedTimeAsString = new Date(elapsedTime).toISOString().slice(11, 19);
        const stepstoRender = this.props.category === 'Photos' ? this.state.steps : this.state.steps.filter(step => step.id !== 1);

        return (
            <div style={{ flex: 1, padding: '20px', border: '1px solid var(--grey-100)', borderRadius: '5px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Header as='h2' style={{ margin: 0 }}>{i18n.t("Avancement de l'import")}</Header>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <small style={{ color: 'var(--grey-100)', marginLeft: 'auto' }}>{i18n.t("Écoulé")} : {elapsedTimeAsString}</small>
                        <small style={{ color: 'var(--grey-100)', marginLeft: 'auto' }}>{i18n.t("Estimé")} : {estimatedTimeLeftAsString}</small>
                    </div>
                </div>
                <Divider />
                {stepstoRender.map(step => this.renderImportStep(step))}
            </div>
        );
    }

    updateStepStatus = (id, status) => {
        const importStatus = status === 2 ? status : this.state.importStatus;
        const steps = JSON.parse(JSON.stringify(this.state.steps));
        const index = steps.findIndex(s => s.id === id);
        if (status === 1) steps[index].status = status;
        else
            for (let i = index; i < steps.length; i++)
                steps[i].status = status;
        this.setState({ importStatus, steps });
    }

    handleSubmit = () => {
        const { category, config } = this.props;
        config.formData.append('importId', this.props.importId);
        if (category === 'Photos') {
            const axiosOptions = {
                onUploadProgress: (processEvent) => {
                    const { loaded, total } = processEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    let { estimatedTime } = this.state;
                    if (percent !== this.props.fileUpload) {
                        if (this.shouldEstimateTime) {
                            if (this.lastFileUploadDate) {
                                estimatedTime = ((new Date().getTime() - this.lastFileUploadDate.getTime()) / (percent - this.props.fileUpload)) * (100 - percent);
                                estimatedTime = estimatedTime >= 0 ? estimatedTime : 0;
                                this.shouldEstimateTime = false;
                            }
                            if (!this.lastFileUploadDate) this.lastFileUploadDate = new Date();
                        }
                        this.props.setFileUpload(percent);
                        const steps = JSON.parse(JSON.stringify(this.state.steps));
                        const index = steps.findIndex(step => step.id === 1);
                        steps[index].loadingMessage = i18n.t("Envoi du fichier ({{percentage}}%)...", { percentage: percent });
                        this.setState({ steps, estimatedTime }, () => {
                            if (percent === 100) {
                                this.shouldEstimateTime = true;
                                this.updateStepStatus(1, 1);
                            }
                        });
                    }
                }
            }

            FilesService.importPhotosInProject(config.formData, axiosOptions).then(response => {
                const firstStep = this.state.steps[0];
                if (response?.status !== 200 && firstStep.status === 0)
                    this.setState({ canRedoImport: true, }, () => this.updateStepStatus(firstStep.id, 2));
            });
        } else {
            const importConfig = this.formDataToObject(config.formData);
            FilesService.importElementsInProject(importConfig).then(response => {
                const firstStep = this.state.steps[0];
                if (response?.status !== 200 && firstStep.status === 0)
                    this.setState({ canRedoImport: true, }, () => this.updateStepStatus(firstStep.id, 2));
            });
        }
    }

    formDataToObject = (formData) => {
        const obj = {};

        formData.forEach((value, key) => {
            if (key === "ProjectId") obj[key] = Number(value);
            else if (key === "Mapping") obj[key] = JSON.parse(value);
            else obj[key] = value;
        });

        return {
            elementsType: obj.elementsType || null,
            importType: obj.importType || null,
            importId: obj.importId || null,
            projectId: obj.projectId || 0,
            projection: obj.projection ? JSON.parse(obj.projection) : null,
            duplicates: obj.duplicates || null,
            errors: obj.errors || null,
            mapping: obj.mapping ? JSON.parse(obj.mapping) : null
        };
    };

    handleFormsHub = () => {
        if (this.props.webSocketHubs?.formsHub && this.props.webSocketHubs.formsHub.state === 'Disconnected') {
            const handleHub = () => {
                // Invokers
                this.props.webSocketHubs.formsHub.invoke('JoinGroup', this.props.importId);

                // Events
                this.props.webSocketHubs.formsHub.on('SendImportInfos', async (importInfos) => {
                    const process = async (importInfos) => {
                        await new Promise((resolve) => {
                            const importInfosParsed = JSON.parse(importInfos);
                            let steps = JSON.parse(JSON.stringify(this.state.steps));
                            let { estimatedTime, importStatus, canRedoImport } = this.state;

                            const stepIndex = steps.findIndex(step => step.id === importInfosParsed.stepId);
                            if (stepIndex !== -1) {
                                const updateStep = (step) => {
                                    step.status = importInfosParsed.status;
                                    step.nbElements = importInfosParsed.nbElements;
                                    step.nbMaxElements = importInfosParsed.nbMaxElements;
                                    step.nbSuccess = importInfosParsed.nbSuccess;
                                    step.nbWarnings = importInfosParsed.nbWarnings;
                                    step.nbErrors = importInfosParsed.nbErrors;
                                    step.isSubscriptionLimitReached = importInfosParsed.isSubscriptionLimitReached;

                                    const messages = {
                                        1: {
                                            loading: i18n.t("Envoi du fichier ({{percentage}}%)...", { percentage: 0 }),
                                            success: i18n.t("Fichier envoyé"),
                                            error: i18n.t("Impossible d'envoyer le fichier")
                                        },
                                        2: {
                                            loading: i18n.t("Récupération des données..."),
                                            success: i18n.t("Données récupérées ({{nbMax}})", { nbMax: step.nbMaxElements }),
                                            error: i18n.t("Impossible de récupérer les données")
                                        },
                                        3: {
                                            loading: i18n.t("Traitement des données ({{nb}})...", { nb: step.nbElements }),
                                            success: i18n.t("Données traitées ({{nb}})", { nb: step.nbElements }),
                                            error: i18n.t("Impossible de traiter les données"),
                                        },
                                        4: {
                                            loading: i18n.t("Ajout des données ({{nb}})...", { nb: step.nbElements }),
                                            success: i18n.t("Données ajoutées ({{nb}})", { nb: step.nbElements }),
                                            error: i18n.t("Impossible d'ajouter les données"),
                                        }
                                    };

                                    if (messages[step.id]) {
                                        if (!step.status) step.loadingMessage = messages[step.id].loading;
                                        else if (step.status === 1) step.successMessage = messages[step.id].success;
                                    }
                                    if (step.status === 2 || (step.id === 4 && step.status === 1)) canRedoImport = true;
                                };

                                if (importInfosParsed.status === 2)
                                    for (let i = stepIndex; i < steps.length; i++) updateStep(steps[i]);
                                else updateStep(steps[stepIndex]);
                            }

                            if (importInfosParsed.stepId === 4) importStatus = importInfosParsed.status;

                            // Estimation du temps
                            if (this.shouldEstimateTime && importInfosParsed.stepId === 2 && importInfosParsed.status === 1) {
                                const estimatedTimePerItem = this.props.category === 'Photos'
                                    ? 340
                                    : AppSettings.getBackendUrl().includes('localhost')
                                        ? 2.36
                                        : 3.44;
                                estimatedTime = (!isNaN(estimatedTime) ? estimatedTime : 0) + estimatedTimePerItem * importInfosParsed.nbMaxElements;
                                this.shouldEstimateTime = false;
                            }

                            if (importInfosParsed.status === 2) clearInterval(this.intervalId);
                            this.setState({ steps, estimatedTime, canRedoImport, importStatus }, resolve);
                        });
                    };

                    if (!this.lastImportInfosProcess) this.lastImportInfosProcess = process(importInfos);
                    else this.lastImportInfosProcess = this.lastImportInfosProcess.then(() => process(importInfos));
                });
            };

            this.props.webSocketHubs.formsHub.start().then(handleHub);
            this.props.webSocketHubs.formsHub.onreconnected(handleHub);
        }
    }
}

const mapStateToProps = (state) => {
    return {
        webSocketHubs: state.webSocketHubs,
        isDarkTheme: state.isDarkTheme
    };
};

export default connect(mapStateToProps)(ImportResult);