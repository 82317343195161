import React, { Component } from 'react';
// Composants
import { Button, Card, Checkbox, Form, Header, List, Message, Select } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfoIcon from '../../../Utils/InfoIcon';
import Masonry from 'react-masonry-css';
// Librairies
import { connect } from 'react-redux';
import { faInfoCircle, faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import i18n from '../../../../locales/i18n';
// Utils
import ProjectsUtil from '../../../../utils/ProjectsUtil';
import FieldsUtil from '../../../../utils/FieldsUtil';
import FormattersUtil from '../../../../utils/FormattersUtil';

const customFieldsTypes = FieldsUtil.getCustomFieldsTypes();
const breakpointColumnsObj = {
    default: 4,
    1300: 3,
    900: 1
};

class ImportMapping extends Component {
    state = {
        confirmation: false,
        categories: []
    }

    render() {
        const { confirmation, config } = this.props;
        const { duplicates, mapping } = config;
        const isMapped = Object.keys(mapping.mapping).map(field => mapping.mapping[field]).filter(value => value);

        console.log(duplicates, confirmation)

        return (
            <>
                <div className='modal-content-body'>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            <Header as='h3' style={{ margin: '0px' }}>{i18n.t("Mapping des champs du fichier")}</Header>
                            <p style={{ margin: '0px' }}>{i18n.t("Le mapping des champs vous permet de faire facilement correspondre la structure de vos données à la notre.")}</p>
                            <p style={{ margin: '0px' }}>{i18n.t("Ci-dessous vous pourrez attribuer une des colonnes de votre Excel à une des propriétés.")}</p>
                        </div>
                        <div style={{ alignSelf: 'flex-end' }}>
                            <Button color='blue' disabled={!isMapped.length} onClick={this.resetMapping}>
                                <FontAwesomeIcon icon={faTrash} style={{ marginRight: '10px' }} />Réinitialiser
                            </Button>
                        </div>
                    </div>
                    <Form id='detail-grid' loading={this.props.isLoading}>
                        {this.renderCategories()}
                    </Form>
                </div >
                <div className='modal-content-footer'>
                    {duplicates === 'overwrite' && !confirmation &&
                        <Message
                            size='small' icon='warning circle' negative header='Avertissement'
                            content={
                                <>
                                    <p>{i18n.t("Cette action entrainera l'écrasement des données des éléments de Grality par celles de l'Excel.")}</p>
                                    <Form.Field
                                        name='confirmation' label={i18n.t("Êtes-vous certain de vouloir écraser les doublons ?")}
                                        control={Checkbox} checked={confirmation}
                                        error onChange={this.props.handleConfirmationChange}
                                    />
                                </>
                            }
                        />}
                </div>
            </>
        );
    }

    componentDidMount = () => this.setCategories();
    componentDidUpdate = (prevProps) => {
        if (prevProps.mapping && JSON.stringify(prevProps.mapping) !== JSON.stringify(this.props.mapping))
            this.setCategories();
    }

    renderCategories = () => {
        return (
            <Masonry breakpointCols={breakpointColumnsObj} className='detail-masonry' columnClassName='detail-masonry__column' style={{ padding: '10px 5px' }}>
                {this.state.categories.filter(category => category.fields.length).map(category =>
                    <Card style={{ borderBottom: `solid 4px ${category.color}`, width: '98%' }}>
                        <Card.Header style={{ color: category.color }}>
                            <FontAwesomeIcon icon={category.icon} />
                            {category.label}
                        </Card.Header>
                        <Card.Content>
                            {category.fields.map((field, index) =>
                                <Form.Field
                                    key={field.label} name={field.label} value={field.value}
                                    placeholder={i18n.t("Sélectionnez un champs")} label={<label style={{ marginTop: index && '5px' }}>{field.longName} {field.infoIcon} :</label>}
                                    clearable search={FormattersUtil.searchList} selectOnBlur={false} control={Select} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                    options={this.props.listValues} onChange={this.props.handleMappingChange}
                                />)}
                        </Card.Content>
                    </Card>)
                }
            </Masonry>
        );
    }

    renderCustomFieldInfo = (customField) => {
        const isLinked = this.props.project.projectCustomFields?.length ? this.props.project.projectCustomFields.find(pcf => pcf.customFieldId === customField.id) : false;
        return (<List>
            <List.Header><FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '7px' }} />{i18n.t("Informations")}</List.Header>
            <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Id")} :</span> {customField.id}</List.Item>
            <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Global")} :</span> {customField.isGlobal ? i18n.t("Oui") : i18n.t("Non")}</List.Item>
            <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Lié au projet")} :</span> {isLinked ? i18n.t("Oui") : i18n.t("Non")}</List.Item>
            <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Type")} :</span> {customFieldsTypes.find(cft => cft.id === customField.type).label}</List.Item>
            {customField.type === 'number' && <>
                <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Minimum")} :</span> {typeof customField.min === 'number' ? customField.min : i18n.t("Aucun")}</List.Item>
                <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Maximum")} :</span> {typeof customField.max === 'number' ? customField.max : i18n.t("Aucun")}</List.Item>
                <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Étape")} :</span> {typeof customField.step === 'number' ? customField.step : i18n.t("Aucune")}</List.Item>
            </>}
            {customField.type === 'string' && <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Longueur maximale")} :</span> {typeof customField.maxLength === 'number' ? customField.maxLength : i18n.t("Aucune")}</List.Item>}
            {customField.type === 'list' && <>
                <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Multiple")} :</span> {customField.isMultiple ? i18n.t("Oui") : i18n.t("Non")}</List.Item>
                <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Numérique")} :</span> {customField.isMultiple ? i18n.t("Oui") : i18n.t("Non")}</List.Item>
            </>}
            {customField.category === 'Arbre' && <>
                <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Vide")} :</span> {customField.forEmpty ? 'Oui' : 'Non'}</List.Item>
                <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Mort")} :</span> {customField.forDead ? 'Oui' : 'Non'}</List.Item>
                <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Souche")} :</span> {customField.forStump ? 'Oui' : 'Non'}</List.Item>
            </>}
        </List>);
    }

    setCategories = () => {
        let mapping = JSON.parse(JSON.stringify(this.props.config.mapping));
        const category = this.props.category === 'trees' ? 'Arbre' : this.props.category === 'greenSpaces' ? 'Espace vert' : this.props.category === 'furnitures' ? 'Mobilier' : 'Station';
        const defaultFieldCategories = this.props.defaultFieldCategories.filter(dfc => dfc.category === category);
        const categories = defaultFieldCategories.map(category => ({ ...category, fields: [] }));
        const requiredFields = ProjectsUtil.getProjectRequiredFields(this.props.project);
        const projectCustomFields = this.props.project.projectCustomFields || [];
        const amenityFormulaType = this.props.project.projectFormulaVersions.find(pfv => pfv.formulaId === 4)?.formulaType;
        const elementsFields = this.props.category === 'trees'
            ? [...FieldsUtil.getTreeFields(amenityFormulaType, projectCustomFields, this.props.customFields, defaultFieldCategories, true)]
            : this.props.category === 'greenSpaces'
                ? [...FieldsUtil.getGreenSpaceFields(projectCustomFields, this.props.customFields, defaultFieldCategories)]
                : this.props.category === 'furnitures' ? [...FieldsUtil.getFurnitureFields(projectCustomFields, this.props.customFields, defaultFieldCategories)]
                    : [...FieldsUtil.getStationFields(projectCustomFields, this.props.customFields, defaultFieldCategories)];
        if (['trees', 'furnitures'].includes(this.props.category)) {
            elementsFields.push({ id: 'latitude', label: i18n.t("Latitude (y)"), fieldCategoryId: defaultFieldCategories.find(dfc => dfc.category === category && dfc.label === i18n.t("Emplacement"))?.id, isGralityField: true });
            elementsFields.push({ id: 'longitude', label: i18n.t("Longitude (x)"), fieldCategoryId: defaultFieldCategories.find(dfc => dfc.category === category && dfc.label === i18n.t("Emplacement"))?.id, isGralityField: true });
        } else if (this.props.category === 'greenSpaces') elementsFields.push({ id: 'coordinates', label: i18n.t("Coordonnées"), fieldCategoryId: defaultFieldCategories.find(dfc => dfc.category === category && dfc.label === i18n.t("Emplacement"))?.id, isGralityField: true });
        if (['trees', 'greenSpaces', 'furnitures'].includes(this.props.category)) elementsFields.push({ id: 'actions', label: i18n.t("Actions"), fieldCategoryId: defaultFieldCategories.find(dfc => dfc.category === category && dfc.label === i18n.t("Autres"))?.id, isGralityField: true });


        for (const fieldName in mapping.mapping) {
            let shouldRender = fieldName.toLowerCase().includes('customfield');
            let isCustomField = shouldRender;
            if (!shouldRender) {
                for (const rf in requiredFields[this.props.category])
                    if (fieldName.toUpperCase() === rf.toUpperCase()) {
                        shouldRender = requiredFields[this.props.category][rf];
                        if (!shouldRender) mapping.mapping[fieldName] = null;
                        break;
                    }
            }

            if (['LABEL', 'SURFACE'].includes(fieldName) && this.props.category === 'stations') shouldRender = true;
            if (['HEIGHT', 'CIRCUMFERENCE', 'CROWNDIAMETER'].includes(fieldName) && requiredFields[this.props.category].trunks) shouldRender = true;
            if ((fieldName === 'PATRIMONIALCOEFFICIENT' && amenityFormulaType !== 'Wallonie')) shouldRender = false;
            if (['TIPPINGRISK', 'ORGANCALIBER', 'TARGET'].includes(fieldName) && requiredFields[this.props.category].accurateRisk) shouldRender = true;

            if (shouldRender) {
                let customField;
                if (isCustomField) {
                    const id = parseInt(fieldName.toLowerCase().replace('customfield', ''));
                    customField = this.props.customFields.find(cf => cf.id === id);
                }

                let elementField = mapping.elementFields.find(ef => ef.id === fieldName);
                const fieldCategoryId = elementsFields?.find(ef => [elementField.id.toLowerCase(), elementField.id.replace('CUSTOMFIELD', '')].includes(ef.id.toLowerCase()))?.fieldCategoryId;
                const category = categories.find(c => c.id === fieldCategoryId);

                if (category)
                    category.fields.push({
                        label: fieldName, longName: elementField.longName, value: mapping.mapping[fieldName],
                        infoIcon: isCustomField && <InfoIcon content={() => this.renderCustomFieldInfo(customField)} iconStyle={{ marginLeft: '3px' }} />
                    });
                else if (!isNaN(fieldCategoryId)) {
                    let category = this.props.project.fieldCategories.find(fc => fc.id === fieldCategoryId);
                    if (category) {
                        category.icon = faPlus
                        category.fields = [{
                            label: fieldName, longName: elementField.longName, value: mapping.mapping[fieldName],
                            infoIcon: isCustomField && <InfoIcon content={() => this.renderCustomFieldInfo(customField)} iconStyle={{ marginLeft: '3px' }} />
                        }];
                        categories.push(category);
                    }
                }
            }
        }

        this.setState({ categories }, () => this.props.setMapping(mapping));
    }

    resetMapping = () => {
        let mapping = JSON.parse(JSON.stringify(this.props.config.mapping));
        Object.keys(mapping.mapping).forEach(field => mapping.mapping[field] = null);
        this.props.setMapping(mapping);
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        defaultFieldCategories: state.defaultFieldCategories,
        projectCollaborators: state.projectCollaborators,
        customFields: [...state.customFields, ...state.organizationCustomFields || [], ...(state.projectsCustomFields[state.project?.id] || [])]
    };
};

export default connect(mapStateToProps)(ImportMapping);