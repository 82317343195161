import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Label, Card } from 'semantic-ui-react';
import Masonry from 'react-masonry-css';
import MapPreview from '../Utils/MapPreview';
// Librairies
import { isMobileOnly } from 'react-device-detect';
import i18n from '../../locales/i18n';
import { withSize } from 'react-sizeme';
import { setPhotosGalleries } from '../../actionCreators/elementsActions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { booleanPointInPolygon } from '@turf/turf';
// Ressources
import { faMapMarkedAlt, faTag, faImage, faPenToSquare } from '@fortawesome/pro-solid-svg-icons';
// Styles
import '../../styles/details.css';
// Utils
import ProjectsUtil from '../../utils/ProjectsUtil';
import GeoJsonUtil from '../../utils/GeoJsonUtil';
import StylesUtil from '../../utils/StylesUtil';

const initialState = {
    feature: null,
    properties: null,
    stationLabels: []
};

const breakpointColumnsObj = {
    default: 3,
    1300: 2,
    900: 1
};

class MarkerDetail extends Component {
    state = { ...initialState, id: this.props.layer[0].feature.id };

    render() {
        const { project, layer, photosGalleries } = this.props;
        const { id, publicFields, properties, stationLabels } = this.state;

        const labelStyle = { margin: '0 5px', display: 'flex' };
        const width = this.props.size.width;

        if (properties && publicFields) {
            let coordinates = layer[0].feature.geometry.coordinates;
            if (layer && layer[0]) coordinates = GeoJsonUtil.projectMarkerCoordinates(coordinates, this.props.projections, project?.projectionId);

            const photos = photosGalleries.filter(photo => photo.elementId === id);
            photos.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            const previewPhoto = photos.length ? (photos.find(photo => photo.blobName === properties.previewBlobName) || photos[0]) : null;

            return (
                <div className='modal-content'>
                    <div id='detail-grid' className='modal-content-body'>
                        {isMobileOnly && properties.projectReference > 0 &&
                            <div style={{ width: '100%', textAlign: 'center', marginBottom: '2px' }}>
                                {i18n.t("Référence")} : {properties.projectReference}{properties.customReference && (' | "' + properties.customReference) + '"'}
                            </div>}
                        <div id='tags'>
                            {properties.tags.map(tag => (
                                <Label key={tag} className='label--primary' style={{ ...labelStyle, whiteSpace: 'nowrap' }}>
                                    <FontAwesomeIcon icon={faTag} style={{ marginRight: '5px' }} />{tag}
                                </Label>
                            ))}
                        </div>
                        <Masonry breakpointCols={breakpointColumnsObj} className='detail-masonry' columnClassName='detail-masonry__column'>
                            {publicFields.main.photos && previewPhoto &&
                                <Card id='684F879A' title={i18n.t("Accéder à la galerie photos du repère")} style={{ borderBottom: 'solid 4px var(--grey-100)' }} onClick={() => this.props.changeModalContentType('PhotosGallery', i18n.t("Photos"))}>
                                    <Card.Header style={{ color: 'var(--grey-100)' }}>
                                        <FontAwesomeIcon icon={faImage} />
                                        {i18n.t("Aperçu")}
                                    </Card.Header>
                                    <Card.Content style={{ padding: 0, overflow: 'hidden', height: '50vh', display: 'flex', justifyContent: 'center', backgroundColor: '#111111' }}>
                                        <img src={previewPhoto.url} alt='img' style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
                                    </Card.Content>
                                </Card>}
                            <Card style={{ borderBottom: 'solid 4px var(--yellow-100)' }}>
                                <Card.Header style={{ color: 'var(--yellow-100)' }}>
                                    <FontAwesomeIcon icon={faMapMarkedAlt} />{i18n.t("Emplacement")}
                                </Card.Header>
                                <Card.Content>
                                    <Grid.Row><label>{i18n.t("Latitude (y)")} :</label><span>{coordinates[1]}</span></Grid.Row>
                                    <Grid.Row><label className='unselectable'>{i18n.t("Longitude (x)")} :</label><span>{coordinates[0]}</span></Grid.Row>
                                    {stationLabels?.length > 0 && <Grid.Row><label>{i18n.t("Stations")} :</label><span>{stationLabels.join(', ')}</span></Grid.Row>}
                                </Card.Content>
                                <Card.Content style={{ padding: '1px' }}>
                                    <MapPreview
                                        id={layer[0].feature.id} style={{ height: '100px', width: '100%' }} features={[layer[0].feature]} elementStyle={{ marker: StylesUtil.getMarkerStyle() }}
                                        title='Voir sur la carte' onClick={() => this.props.showElement(properties.category, layer[0].feature, null, { highlight: false, blink: true })}
                                    />
                                </Card.Content>
                            </Card>
                            {(properties.title || properties.description) &&
                                <Card style={{ borderBottom: 'solid 4px var(--grey-100)' }}>
                                    <Card.Header style={{ color: 'var(--grey-100)' }}>
                                        <FontAwesomeIcon icon={faPenToSquare} />{properties.title || i18n.t("Description")}
                                    </Card.Header>
                                    {properties.description &&
                                        <Card.Content>
                                            <Grid.Row>
                                                <span style={{ whiteSpace: 'pre-line' }}>{properties.description}</span>
                                            </Grid.Row>
                                        </Card.Content>}
                                </Card>}
                        </Masonry>
                    </div>
                </div >
            );
        }
        else return (<p>Chargement...</p>);
    }

    componentDidMount = () => {
        if (!this.state.requiredFields) {
            const requiredFields = ProjectsUtil.getProjectRequiredFields(this.props.project, this.props.layer[0].feature.projectId);
            const publicFields = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators);
            this.setState({ requiredFields: requiredFields, publicFields: publicFields }, () => this.setProperties());
        } else this.setProperties();
    }

    componentDidUpdate = () => { // Permet d'update les infos lorsqu'on passe au repère suivant ou précédent dans un projet
        if (this.props.layer[0].feature.id !== this.state.id || JSON.stringify(this.props.layer[0].feature) !== JSON.stringify(this.state.feature))
            this.setState(prevState => ({
                ...prevState.requiredFields, ...prevState.publicFields,
                id: this.props.layer[0].feature.id,
                feature: this.props.layer[0].feature
            }), () => this.setProperties());
    }

    setProperties = () => {
        const { project } = this.props;
        const properties = this.props.layer[0].feature.properties;
        // Tags
        let tags = [];
        if (properties.tagId && this.state.requiredFields.trees.tags && project?.tags) {
            properties.tagId.forEach(tId => {
                const tag = project.tags.find(x => x.id === tId)?.label;
                tags.push(tag);
            });
        }
        // Stations
        const stationsLayer = this.props.layers.find(l => l.label === i18n.t("Stations"));
        const layers = stationsLayer ? stationsLayer.getLayers() : [];
        const stationLabels = layers.filter(l => booleanPointInPolygon(this.props.layer[0].feature, l.feature)).map(l => l.feature.properties.label);

        this.setState({ properties: { ...properties, tags }, stationLabels });
    }
}

const mapStateToProps = (state) => {
    return {
        layer: state.layer,
        project: state.project,
        projectCollaborators: state.projectCollaborators,
        projections: state.projections,
        photosGalleries: state.photosGalleries
    };
};

const mapDispatchToProps = {
    setPhotosGalleries
};

export default compose(withSize(), connect(mapStateToProps, mapDispatchToProps))(MarkerDetail);