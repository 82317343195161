import React, { Component } from 'react';
// Composants
import { Button, Form, Grid, Message, Select } from 'semantic-ui-react';
import DatePicker from '../../Utils/DatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import i18n from '../../../locales/i18n';
import { connect } from 'react-redux';
import { isMobileOnly, isTablet } from 'react-device-detect';
// Ressources
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
// Utils
import FormattersUtil from '../../../utils/FormattersUtil';

const initialError = {
    hidden: true,
    messages: [],
    date: false
}

class ProjectEventModificationForm extends Component {
    state = {
        event: null,
        projectEvent: null,
        error: initialError,
        isLoading: false
    }

    render() {
        const { projectEvent, event, error, isLoading } = this.state;
        const peChanged = JSON.stringify(projectEvent) !== JSON.stringify(this.props.projectEvent);
        const isValid = peChanged && projectEvent?.date ? true : false;
        const date = projectEvent?.date ? new Date(projectEvent.date.valueOf()) : null;
        
        return (
            <Form onSubmit={this.handleSubmit} loading={isLoading} error>
                <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                    <Grid.Row columns={isMobileOnly ? 1 : isTablet ? 2 : 3} style={{ padding: 0 }}>
                        <Grid.Column>
                            <Grid.Row>
                                <Grid.Column computer={8} tablet={8} mobile={16}>
                                    <Form.Field
                                        control={Select} options={event ? [event] : []} search={FormattersUtil.searchList} clearable disabled
                                        name='eventId' value={projectEvent?.eventId || ''}
                                        label={`${i18n.t("Évènement")} : `} placeholder={i18n.t("Sélectionnez un évènement")}
                                        selectOnBlur={false} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid.Column>
                        <Grid.Column>
                            <Grid.Row>
                                <Grid.Column>
                                    <DatePicker label={i18n.t("Date")} name='date' value={date} onChange={this.handleDateChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid.Column>
                    </Grid.Row>
                    {!error.hidden &&
                        <Grid.Row>
                            <Grid.Column>
                                <Message
                                    error hidden={error.hidden} style={{ textAlign: 'left' }}
                                    header='Erreur' list={error.messages}
                                />
                            </Grid.Column>
                        </Grid.Row>}
                    <Grid.Row style={{ padding: '0px', marginTop: '15px' }}>
                        <Grid.Column>
                            <Button className='form-button' type='button' color='red' onClick={this.props.cancel}>
                                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                            </Button>
                            <Button className='form-button' type='submit' color='green' id='rigRXCCA' disabled={!isValid || isLoading || !this.props.isOnline}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        );
    }

    componentDidMount = () => {
        const { projectEvent } = this.props;
        const event = this.props.events.find(event => event.id === projectEvent.eventId);
        this.setState({ projectEvent: JSON.parse(JSON.stringify(projectEvent)), event: { text: event.label, value: event.id } });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.projectEvent && this.props.projectEvent && prevProps.projectEvent.id !== this.props.projectEvent.id) {
            const { projectEvent } = this.props;
            const event = this.props.events.find(event => event.id === projectEvent.eventId);
            this.setState({ projectEvent: JSON.parse(JSON.stringify(projectEvent)), event: { text: event.label, value: event.id } });
        }
    }

    handleDateChange = (_, { name, value }) => {
        let error = false; let hidden = true;
        let messages = [];
        const addError = (message) => {
            value = null; error = true; hidden = false;
            messages.push(message);
        }

        if (value && value.getFullYear().toString().length !== 4)
            addError(i18n.t("Veuillez saisir une date valide"));

        this.setState(prevState => ({
            projectEvent: { ...prevState.projectEvent, [name]: value },
            error: { ...prevState.error, [name]: error, messages: messages, hidden: hidden }
        }));
    }

    handleSubmit = () => {
        const projectEvent = JSON.parse(JSON.stringify(this.state.projectEvent));
        this.props.submit(projectEvent);
    }
}

const mapStateToProps = (state) => {
    return {
        events: state.events,
        projectEvents: state.projectEvents,
        project: state.project,
        webSocketHubs: state.webSocketHubs,
        isOnline: state.isOnline
    };
};


export default connect(mapStateToProps)(ProjectEventModificationForm);