import React, { Component } from 'react';
// Composants
import InfoIcon from '../Utils/InfoIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Label, Card, Button } from 'semantic-ui-react';
import Masonry from 'react-masonry-css';
import MapPreview from '../Utils/MapPreview';
// Librairies
import { isMobileOnly } from 'react-device-detect';
import i18n from '../../locales/i18n';
import { setPhotosGalleries } from '../../actionCreators/elementsActions';
import { connect } from 'react-redux';
import { booleanIntersects, booleanContains } from '@turf/turf';
// Ressources
import { faDotCircle, faEye, faMapMarkedAlt, faTachometerAlt, faTag, faTh, faTools, faTrees, faArrowUpRightFromSquare, faImage, faUserHelmetSafety, faCalendarClock, faSquareCheck, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
// Styles
import '../../styles/details.css';
// Utils
import ProjectsUtil from '../../utils/ProjectsUtil';
import GreenSpacesUtil from '../../utils/GreenSpacesUtil';
import StylesUtil from '../../utils/StylesUtil';
import FormulasUtil from '../../utils/FormulasUtil';
import DatesUtil from '../../utils/DatesUtil';
import FormattersUtil from '../../utils/FormattersUtil';
import RightsUtil from '../../utils/RightsUtil';

const initialState = {
    requiredFields: null,
    publicFields: null,
    feature: null,
    properties: null,
    customFields: [],
    stationLabels: []
};

const breakpointColumnsObj = {
    default: 2,
    900: 1
};

class GreenSpaceDetail extends Component {
    state = { ...initialState, id: this.props.layer[0].feature.id };

    render() {
        const { project, layer, photosGalleries, rights } = this.props;
        const { id, requiredFields, publicFields, properties, customFields, stationLabels } = this.state;
        const projectSubscription = project.organization.subscription;

        const iconStyle = { position: 'relative', top: 0, right: 0, marginLeft: '3px' };
        const labelStyle = { margin: '0 5px', display: 'flex' };

        if (properties && requiredFields) {
            const greenSpacesRF = requiredFields.greenSpaces;
            const greenSpacesPF = publicFields.greenSpaces;
            const defaultFieldCategories = this.props.defaultFieldCategories.filter(dfc => dfc.category === 'Espace vert');
            const SR = { // shouldRender
                creationInfos: greenSpacesRF.creationInfos && properties.creationDate,
                modificationInfos: greenSpacesRF.modificationInfos && properties.modificationDate,
                isTreeBase: greenSpacesRF.isTreeBase && greenSpacesPF.isTreeBase && properties.isTreeBase,
                tags: greenSpacesRF.tags && greenSpacesPF.tags && properties.tagId?.length > 0 && this.props.project.tags,
                place: greenSpacesRF.place && greenSpacesPF.place && properties.place,
                placeExtra: greenSpacesRF.placeExtra && greenSpacesPF.placeExtra && properties.placeExtra,
                spaceType: greenSpacesRF.spaceType && greenSpacesPF.spaceType && properties.spaceType,
                spaceFunction: greenSpacesRF.spaceFunction && greenSpacesPF.spaceFunction && properties.spaceFunction,
                dominantComposition: greenSpacesRF.dominantComposition && greenSpacesPF.dominantComposition && properties.dominantComposition,
                runoffCoefficient: greenSpacesRF.dominantComposition && greenSpacesPF.runoffCoefficient && properties.runoffCoefficient,
                detailedComposition: greenSpacesRF.detailedComposition && greenSpacesPF.detailedComposition && properties.detailedComposition,
                managementClass: greenSpacesRF.managementClass && greenSpacesPF.managementClass && properties.managementClass,
                annualMaintenanceFrequency: greenSpacesRF.annualMaintenanceFrequency && greenSpacesPF.annualMaintenanceFrequency,
                density: greenSpacesRF.density && greenSpacesPF.density && properties.density > 0,
                dominantEssence: greenSpacesPF.dominantEssence && greenSpacesPF.dominantEssence && properties.dominantEssence,
                averageHealthReview: greenSpacesRF.averageHealthReview && greenSpacesPF.averageHealthReview && properties.averageHealthReview,
                averageHeight: greenSpacesRF.averageHeight && greenSpacesPF.averageHeight && properties.averageHeight > 0,
                averageCircumference: greenSpacesRF.averageCircumference && greenSpacesPF.averageCircumference && properties.averageCircumference > 0,
                averageCrownDiameter: greenSpacesRF.averageCrownDiameter && greenSpacesPF.averageCrownDiameter && !properties.isStump && properties.averageCrownDiameter > 0,
                observation: greenSpacesRF.observation && greenSpacesPF.observation && properties.observation,
                surface: greenSpacesRF.surface && greenSpacesPF.surface && properties.surface,
                length: greenSpacesRF.length && greenSpacesPF.length && properties.baseLine?.length > 0,
                carbonStock: greenSpacesRF.carbonStock && greenSpacesPF.carbonStock && properties.carbonStock > 0,
                totalCarbonStock: greenSpacesRF.carbonStock && greenSpacesPF.carbonStock && properties.totalCarbonStock > 0,
                coolingEnergyIndicator: greenSpacesRF.coolingIndicator && greenSpacesPF.coolingIndicator && properties.coolingEnergyIndicator > 0,
                coolingEconomicValue: greenSpacesRF.coolingIndicator && greenSpacesPF.coolingIndicator && properties.coolingEconomicValue > 0,
                oxygenProduction: greenSpacesRF.oxygenProduction && greenSpacesPF.carbonStock && properties.oxygenProduction > 0,
                ecopotentialIndex: greenSpacesRF.spaceType && greenSpacesPF.spaceType && properties.spaceType && properties.ecopotentialIndex > 0,
                customFields: properties.customFields && Object.keys(properties.customFields).length > 0
                    && (projectSubscription?.customFields || !projectSubscription)
                    && Object.keys(properties.customFields).some(key => properties.customFields[key] && customFields.find(cf => cf.id === Number(key)))
            };

            const siteCustomFields = SR.customFields && this.renderFields(defaultFieldCategories.find(dfc => dfc.label === 'Emplacement'));
            const compositionCustomFields = SR.customFields && this.renderFields(defaultFieldCategories.find(dfc => dfc.label === 'Composition'));
            const indicatorsCustomFields = SR.customFields && this.renderFields(defaultFieldCategories.find(dfc => dfc.label === 'Indicateurs'));
            const maintenanceCustomFields = SR.customFields && this.renderFields(defaultFieldCategories.find(dfc => dfc.label === 'Entretien'));
            const observationCustomFields = SR.customFields && this.renderFields(defaultFieldCategories.find(dfc => dfc.label === 'Observation'));
            const woodedMassifCustomFields = SR.customFields && this.renderFields(defaultFieldCategories.find(dfc => dfc.label === 'Massif arboré'));
            const photos = photosGalleries.filter(photo => photo.elementId === id);
            photos.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            const previewPhoto = photos.length ? (photos.find(photo => photo.blobName === properties.previewBlobName) || photos[0]) : null;
            const latestActionsDone = this.getLatestActionsDone();
            const actionsToDo = this.getActionsToDo();

            return (
                <div className='modal-content'>
                    <div id='detail-grid' className='modal-content-body'>
                        {isMobileOnly && properties.projectReference > 0 &&
                            <div style={{ width: '100%', textAlign: 'center', marginBottom: '2px' }}>
                                {i18n.t("Référence")} : {properties.projectReference}{properties.customReference && (' | "' + properties.customReference) + '"'}
                            </div>}
                        <div style={{ position: 'relative', minHeight: SR.creationInfos && SR.modificationInfos ? '25px' : (SR.creationInfos || SR.modificationInfos) ? '10px' : 0 }}>
                            <div style={{ position: isMobileOnly ? 'relative' : 'absolute', top: 0, left: isMobileOnly ? 0 : '10px', display: 'flex', flexDirection: 'column', color: 'var(--grey-70)', textAlign: isMobileOnly && 'center', marginBottom: isMobileOnly && '5px' }}>
                                {SR.creationInfos &&
                                    <>
                                        {properties.creatorName
                                            ? <small>{i18n.t("Créé le {{date}} par {{username}}", { date: `${DatesUtil.getFormattedLocaleDateString(properties.creationDate)}, ${DatesUtil.getFormattedLocaleTimeString(properties.creationDate)}`, username: properties.creatorName, interpolation: { escapeValue: false } })}</small>
                                            : <small>{i18n.t("Créé le {{date}}", { date: `${DatesUtil.getFormattedLocaleDateString(properties.creationDate)}, ${DatesUtil.getFormattedLocaleTimeString(properties.creationDate)}`, interpolation: { escapeValue: false } })}</small>}
                                    </>}
                                {SR.modificationInfos &&
                                    <>
                                        {properties.editorName && properties.modificationDate ?
                                            <small>{i18n.t("Modifié le {{date}} par {{username}}", { date: `${DatesUtil.getFormattedLocaleDateString(properties.modificationDate)}, ${DatesUtil.getFormattedLocaleTimeString(properties.modificationDate)}`, username: properties.editorName, interpolation: { escapeValue: false } })}</small>
                                            : properties.modificationDate && <small>{i18n.t("Modifié le {{date}}", { date: `${DatesUtil.getFormattedLocaleDateString(properties.modificationDate)}, ${DatesUtil.getFormattedLocaleTimeString(properties.modificationDate)}`, interpolation: { escapeValue: false } })}</small>}
                                    </>}
                            </div>
                            {(SR.isTreeBase || SR.tags) &&
                                <div id='tags'>
                                    {SR.isTreeBase &&
                                        <Label className='label--secondary' style={labelStyle}>
                                            <FontAwesomeIcon icon={faDotCircle} style={{ marginRight: '5px' }} />{i18n.t("Pied d'arbre")}
                                        </Label>}
                                    {SR.tags &&
                                        <>
                                            {properties.tags.map(tag => (
                                                <Label key={tag} className='label--primary' style={{ ...labelStyle, whiteSpace: 'nowrap' }}>
                                                    <FontAwesomeIcon icon={faTag} style={{ marginRight: '5px' }} />{tag}
                                                </Label>
                                            ))}
                                        </>}
                                </div>}
                        </div>
                        <Masonry breakpointCols={breakpointColumnsObj} className='detail-masonry' columnClassName='detail-masonry__column'>
                            {publicFields.main.photos && previewPhoto &&
                                <Card id='684F879A' title={i18n.t("Accéder à la galerie photos de l'espace vert")} style={{ borderBottom: 'solid 4px var(--grey-100)' }} onClick={() => this.props.changeModalContentType('PhotosGallery', i18n.t("Photos"))}>
                                    <Card.Header style={{ color: 'var(--grey-100)' }}>
                                        <FontAwesomeIcon icon={faImage} />
                                        {i18n.t("Aperçu")}
                                    </Card.Header>
                                    <Card.Content style={{ padding: 0, overflow: 'hidden', height: '50vh', display: 'flex', justifyContent: 'center', backgroundColor: '#111111' }}>
                                        <img src={previewPhoto.url} alt='img' style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
                                    </Card.Content>
                                </Card>}
                            <Card style={{ borderBottom: 'solid 4px #DAA817' }}>
                                <Card.Header style={{ color: '#DAA817' }}>
                                    <FontAwesomeIcon icon={faMapMarkedAlt} />
                                    {i18n.t("Emplacement")}
                                </Card.Header>
                                {(SR.place || SR.placeExtra || SR.spaceType || SR.spaceFunction || stationLabels.length > 0 || (siteCustomFields && siteCustomFields.length > 0)) &&
                                    <Card.Content>
                                        {SR.place &&
                                            <Grid.Row id='nLlpkBte' computer={6} tablet={6} mobile={16}>
                                                <label>{i18n.t("Lieu")} :</label>
                                                <span>{properties.place}</span>
                                            </Grid.Row>}
                                        {SR.placeExtra &&
                                            <Grid.Row id='tiEi7jkc' computer={6} tablet={6} mobile={16}>
                                                <label>{i18n.t("Libellé")} :</label>
                                                <span>{properties.placeExtra}</span>
                                            </Grid.Row>}
                                        {SR.spaceType &&
                                            <Grid.Row id='JS459YHg' computer={6} tablet={6} mobile={16}>
                                                <label>{i18n.t("Type de surface")}<InfoIcon content={i18n.t("Classification selon indice écopotentiel")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.spaceType}</span>
                                            </Grid.Row>}
                                        {SR.spaceFunction &&
                                            <Grid.Row id='2G6qXcUR' computer={5} tablet={5} mobile={16}>
                                                <label>{i18n.t("Fonction de l'espace")}<InfoIcon content={i18n.t("Usage de l'espace")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.spaceFunction}</span>
                                            </Grid.Row>}
                                        {stationLabels?.length > 0 && <Grid.Row><label>{i18n.t("Stations")} :</label><span>{stationLabels.join(', ')}</span></Grid.Row>}
                                        {siteCustomFields}
                                    </Card.Content>}
                                <Card.Content style={{ padding: '1px' }}>
                                    <MapPreview
                                        id={layer[0].feature.id} style={{ height: '100px', width: '100%' }} features={[layer[0].feature]} elementStyle={{ greenSpace: StylesUtil.getGreenSpaceStyle() }}
                                        title={i18n.t("Voir sur la carte")} onClick={() => this.props.showElement(properties.category, layer[0].feature, null, { highlight: false, blink: true })}
                                    />
                                </Card.Content>
                            </Card>
                            {(SR.dominantComposition || SR.runoffCoefficient || SR.detailedComposition || (compositionCustomFields && compositionCustomFields.length > 0)) ?
                                <Card style={{ borderBottom: 'solid 4px var(--orange-100)' }}>
                                    <Card.Header style={{ color: 'var(--orange-100)' }}>
                                        <FontAwesomeIcon icon={faTh} />
                                        {i18n.t("Composition")}
                                    </Card.Header>
                                    <Card.Content>
                                        {SR.dominantComposition &&
                                            <Grid.Row className='qP3aAaU0' computer={6} tablet={6} mobile={16}>
                                                <label>{i18n.t("Composition dominante")}<InfoIcon content={i18n.t("Composition dominante de l'espace considéré")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.dominantComposition}</span>
                                            </Grid.Row>}
                                        {SR.detailedComposition &&
                                            <Grid.Row id='sppZk2z8' computer={6} tablet={6} mobile={16}>
                                                <label>{i18n.t("Composition détaillée")}<InfoIcon content={i18n.t("Détail de la composition (végétal ou minéral)")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.detailedComposition}</span>
                                            </Grid.Row>}
                                        {SR.runoffCoefficient &&
                                            <Grid.Row className='qP3aAaU0' computer={5} tablet={5} mobile={16}>
                                                <label>{i18n.t("Coefficient de ruissellement")}<InfoIcon content={i18n.t("Représente la perméabilité de la surface considérée")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.runoffCoefficient}</span>
                                            </Grid.Row>}
                                        {compositionCustomFields}
                                    </Card.Content>
                                </Card> : <div></div>}
                            {(SR.observation || (observationCustomFields && observationCustomFields.length > 0)) ?
                                <Card id='GE8t4imw' style={{ borderBottom: 'solid 4px var(--grey-100)' }}>
                                    <Card.Header style={{ color: 'var(--grey-100)' }}>
                                        <FontAwesomeIcon icon={faEye} />
                                        {i18n.t("Observation")}
                                    </Card.Header>
                                    <Card.Content style={{ overflowWrap: 'anywhere' }}>
                                        <span style={{ whiteSpace: 'pre-line' }}>{properties.observation}</span>
                                        {observationCustomFields}
                                    </Card.Content>
                                </Card> : <div></div>}
                            {(SR.density || SR.dominantEssence || SR.averageHealthReview || SR.averageHeight || SR.averageCircumference || SR.averageCrownDiameter || (woodedMassifCustomFields && woodedMassifCustomFields.length > 0)) ?
                                <Card style={{ borderBottom: 'solid 4px var(--primary-100)' }}>
                                    <Card.Header style={{ color: 'var(--primary-100)' }}>
                                        <FontAwesomeIcon icon={faTrees} />
                                        {i18n.t("Massif arboré")}
                                    </Card.Header>
                                    <Card.Content>
                                        {SR.density &&
                                            <>
                                                <Grid.Row>
                                                    <label>{i18n.t("Nombre d'arbres")} :</label>
                                                    <span>{properties.nbTrees}</span>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <label>{i18n.t("Densité")} :</label>
                                                    <span>{properties.density}/ha</span>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <label>{i18n.t("Distance moyenne entre les troncs")} :</label>
                                                    <span>{properties.distanceBetweenTrunks}m</span>
                                                </Grid.Row>
                                            </>}
                                        {SR.dominantEssence &&
                                            <Grid.Row>
                                                <label>{i18n.t("Essence dominante")} :</label>
                                                <span>
                                                    {properties.dominantEssence.gender} {properties.dominantEssence.species} {properties.dominantEssence.cultivar} {properties.dominantEssence.order && `(${properties.dominantEssence.order})`}
                                                </span>
                                            </Grid.Row>}
                                        {SR.averageHealthReview &&
                                            <Grid.Row>
                                                <label>{i18n.t("Cote sanitaire moyenne")}<InfoIcon content={i18n.t("Note globale de l'arbre sur 10")} iconStyle={iconStyle} /> :</label>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span
                                                        className='color-circle'
                                                        style={{ backgroundColor: StylesUtil.getHealthReviewColor(properties.averageHealthReview.value) }}
                                                    ></span>
                                                    <span>{properties.averageHealthReview.value + ' (' + properties.averageHealthReview.description + ')'}</span>
                                                </div>
                                            </Grid.Row>}
                                        {SR.averageHeight &&
                                            <Grid.Row>
                                                <label>{i18n.t("Hauteur moyenne")} :</label>
                                                <span>{Math.round((properties.averageHeight / 100) * 100) / 100}m</span>
                                            </Grid.Row>}
                                        {SR.averageCircumference &&
                                            <Grid.Row>
                                                <label>{i18n.t("Circonférence moyenne des troncs")}<InfoIcon content={i18n.t("Mesure de la circonférence de l'axe à 150 cm (à 130cm pour la France)")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.averageCircumference}cm</span>
                                            </Grid.Row>}
                                        {SR.averageCrownDiameter &&
                                            <Grid.Row>
                                                <label>{i18n.t("Diamètre moyen des couronnes")}<InfoIcon content={i18n.t("Distance moyenne entre les extrémités de la couronne")} iconStyle={iconStyle} /> :</label>
                                                <span>{Math.round((properties.averageCrownDiameter / 100) * 100) / 100}m</span>
                                            </Grid.Row>}
                                        {woodedMassifCustomFields}
                                    </Card.Content>
                                </Card> : <div></div>}
                            {(SR.surface || SR.length || SR.carbonStock || SR.coolingEnergyIndicator || SR.coolingEconomicValue || SR.oxygenProduction || (indicatorsCustomFields && indicatorsCustomFields.length > 0)) ?
                                <Card style={{ borderBottom: 'solid 4px #2185D0' }}>
                                    <Card.Header style={{ color: '#2185D0' }}>
                                        <FontAwesomeIcon icon={faTachometerAlt} />
                                        {i18n.t("Indicateurs")}
                                    </Card.Header>
                                    <Card.Content>
                                        {SR.surface &&
                                            <Grid.Row id='nUJbWQsQ' computer={8} tablet={8} mobile={16}>
                                                <label>{i18n.t("Surface")}<InfoIcon content={i18n.t("La surface est mise à jour automatiquement")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.surface}m²</span>
                                            </Grid.Row>}
                                        {SR.length &&
                                            <Grid.Row computer={8} tablet={8} mobile={16}>
                                                <label>{i18n.t("Longueur")}<InfoIcon content={i18n.t("La longueur est mise à jour automatiquement")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.baseLine.length}m</span>
                                            </Grid.Row>}
                                        {properties.baseLine &&
                                            <Grid.Row computer={8} tablet={8} mobile={16}>
                                                <label>{i18n.t("Largeur")} :</label>
                                                <span>{properties.baseLine.width}m</span>
                                            </Grid.Row>}
                                        {SR.carbonStock &&
                                            <Grid.Row id='RqOXdvg3' computer={5} tablet={5} mobile={16}>
                                                <label>{i18n.t("Stockage annuel CO2")}<InfoIcon content={i18n.t("Quantité de carbone stockée par les végétaux")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.carbonStock.toFixed(2)}kg/an</span>
                                            </Grid.Row>}
                                        {SR.totalCarbonStock > 0 &&
                                            <Grid.Row id='hzbbehz2'>
                                                <label>{i18n.t("Stockage total CO2")} :</label>
                                                <span>{properties.totalCarbonStock.toFixed(2)}kg</span>
                                            </Grid.Row>}
                                        {SR.coolingEnergyIndicator &&
                                            <Grid.Row id='HAwTYx5y' computer={5} tablet={5} mobile={16}>
                                                <label>{i18n.t("Rafraîchissement") + ` (${i18n.t("énergie")})`}<InfoIcon content={i18n.t("Quantité de chaleur absorbée par l'espace vert par jour, compte tenu de l'évapo-transpiration, dans des conditions estivales standards")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.coolingEnergyIndicator.toFixed(2)}kWh/an</span>
                                            </Grid.Row>}
                                        {SR.coolingEconomicValue &&
                                            <Grid.Row id='lPcWXszc' computer={5} tablet={5} mobile={16}>
                                                <label>{i18n.t("Rafraîchissement") + ` (${i18n.t("Valeur").toLowerCase()})`}<InfoIcon content={i18n.t("Consommation électrique annuelle pour produire ce rafraîchissement s'il fallait le produire articiellement (climatisation)")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.coolingEconomicValue.toFixed(2)}€/an</span>
                                            </Grid.Row>}
                                        {SR.oxygenProduction &&
                                            <Grid.Row id='KFtvxPsZ' computer={5} tablet={5} mobile={16}>
                                                <label>{i18n.t("Production d'oxygène")}<InfoIcon content={i18n.t("Quantité annuelle d'oxygène produits par photosynthèse par l'espace vert")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.oxygenProduction.toFixed(2)}kg/an</span>
                                            </Grid.Row>}
                                        {SR.ecopotentialIndex &&
                                            <Grid.Row computer={5} tablet={5} mobile={16}>
                                                <label>{i18n.t("Potentiel de biodiversité")} :</label>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span
                                                        className='color-circle'
                                                        style={{ backgroundColor: StylesUtil.getHealthReviewColor(Math.round(properties.ecopotentialIndex * 10)) }}
                                                    ></span>
                                                    <span>{Math.round((properties.ecopotentialIndex * 100) * 100) / 100}/100</span>
                                                </div>
                                            </Grid.Row>}
                                        {indicatorsCustomFields}
                                    </Card.Content>
                                </Card> : <div></div>}
                            {(SR.managementClass || SR.annualMaintenanceFrequency || (maintenanceCustomFields && maintenanceCustomFields.length > 0)) &&
                                <Card style={{ borderBottom: 'solid 4px #C83030' }}>
                                    <Card.Header style={{ color: '#C83030' }}>
                                        <FontAwesomeIcon icon={faTools} />
                                        {i18n.t("Entretien")}
                                    </Card.Header>
                                    <Card.Content>
                                        {SR.managementClass &&
                                            <Grid.Row id='BLWqtJGq' computer={5} tablet={5} mobile={16}>
                                                <label>{i18n.t("Classe de gestion")}<InfoIcon content={i18n.t("Niveau de gestion différenciée appliqué sur 4")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.managementClass}</span>
                                            </Grid.Row>}
                                        {SR.annualMaintenanceFrequency &&
                                            <Grid.Row id='SjD3RkyK' computer={5} tablet={5} mobile={16}>
                                                <label>{i18n.t("Fréquence annuelle d'entretien")}<InfoIcon content={i18n.t("Nombre de passage annuel pour l'entretien")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.annualMaintenanceFrequency > 0 ? properties.annualMaintenanceFrequency : i18n.t("Aucune")}</span>
                                            </Grid.Row>}
                                        {maintenanceCustomFields}
                                    </Card.Content>
                                </Card>}
                            {(latestActionsDone.length > 0 || actionsToDo.length > 0) && RightsUtil.canRead(rights?.actions) &&
                                <Card id='684F879A' title={i18n.t("Accéder aux actions de l'arbre")} style={{ borderBottom: 'solid 4px var(--grey-100)' }} onClick={() => this.props.changeModalContentType('ActionForm', i18n.t("Actions"))}>
                                    <Card.Header style={{ color: 'var(--grey-100)' }}>
                                        <FontAwesomeIcon icon={faUserHelmetSafety} />
                                        {i18n.t("Actions")}
                                    </Card.Header>
                                    <Card.Content style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                        {actionsToDo.length > 0 && this.renderActionsToDo(actionsToDo)}
                                        {latestActionsDone.length > 0 && this.renderLatestActionsDone(latestActionsDone)}
                                    </Card.Content>
                                </Card>}
                            {SR.customFields && this.renderCustomFields(properties.customFields)}
                        </Masonry>
                    </div>
                </div >
            );
        }
        else return (<p>{i18n.t("Chargement...")}</p>);
    }

    componentDidMount = () => {
        if (!this.state.requiredFields) {
            const requiredFields = ProjectsUtil.getProjectRequiredFields(this.props.project, this.props.layer[0].feature.projectId);
            const publicFields = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators);
            this.setState({ requiredFields: requiredFields, publicFields: publicFields }, () => this.setProperties());
        } else this.setProperties();
    }

    componentDidUpdate = () => { // Permet d'update les infos lorsqu'on passe à l'arbre suivant ou précédent dans un projet
        if (this.props.layer[0].feature.id !== this.state.id || JSON.stringify(this.props.layer[0].feature) !== JSON.stringify(this.state.feature))
            this.setState(prevState => ({
                ...prevState.requiredFields, ...prevState.publicFields, // TODO What is this for ?
                id: this.props.layer[0].feature.id,
                feature: this.props.layer[0].feature
            }), () => this.setProperties());
    }

    renderFields = (category) => {
        const { project } = this.props;
        const customFields = this.state.customFields.map(cf => {
            const value = this.state.properties.customFields[cf.id];
            return value ? { ...cf, value } : null;
        }).filter(cf => cf);
        const projectCustomFields = project?.projectCustomFields || [];

        const fields = customFields
            .filter(cf => projectCustomFields.find(pcf => pcf.customFieldId === cf.id && pcf.fieldCategoryId === category?.id))
            .sort((a, b) => projectCustomFields.find(pcf => pcf.customFieldId === a.id && pcf.fieldCategoryId === category?.id)?.order
                - projectCustomFields.find(pcf => pcf.customFieldId === b.id && pcf.fieldCategoryId === category?.id)?.order);;
        const iconStyle = { position: 'relative', top: 0, right: 0, marginLeft: '3px' };
        const renderedFields = fields.map((cf, i) => {
            let value = cf.value && cf.type === 'boolean' ? cf.value === 'true' ? i18n.t("Oui") : i18n.t("Non")
                : cf.type === 'date' ? DatesUtil.getFormattedLocaleDateString(cf.value)
                    : cf.type === 'list' && cf.isMultiple ? cf.value.split(',').map(v => cf.dropdownCustomFieldValues.find(dcfv => String(dcfv.id) === v)?.label).filter(v => v)
                        : cf.type === 'list' ? cf.dropdownCustomFieldValues.find(dcfv => String(dcfv.id) === cf.value)?.label
                            : cf.value;
            if (cf.type === 'number' && cf.unit?.trim()) value += cf.unit;
            if (cf.type === 'formula') value = FormattersUtil.formatFormulaCustomField(cf, value);

            return (
                cf.type !== 'url' ?
                    <Grid.Row key={`extra-field-${i + 1}`} style={Array.isArray(value) ? { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' } : null}>
                        <label>{cf.label}{cf.description?.trim() && <InfoIcon content={cf.description} iconStyle={iconStyle} />} :</label>
                        {!Array.isArray(value) ? <span style={{ whiteSpace: 'pre-line' }}>{value}</span>
                            : <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                {value.map(v => (
                                    <li key={v}>{v}</li>
                                ))}
                            </ul>}
                    </Grid.Row>
                    : <Grid.Row>
                        <Button size='small' style={{ padding: 0 }} type='button' color='green'>
                            <a href={cf.value?.includes('http') ? cf.value : '//' + cf.value} target='_blank' rel='noreferrer' style={{ padding: '7px', display: 'block', height: '100%', width: '100%', color: 'inherit' }}>
                                <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ marginRight: '5px' }} />{cf.label}
                            </a>
                        </Button>
                    </Grid.Row>
            );
        });

        return renderedFields;
    }

    renderCustomFields = (elementCustomFields) => {
        const { project } = this.props;
        const customFields = this.state.customFields.map(cf => {
            const value = this.state.properties.customFields[cf.id];
            return value ? { ...cf, value } : null;
        }).filter(cf => cf);
        const projectCustomFields = project?.projectCustomFields || [];

        const othersCategory = this.props.defaultFieldCategories.find(dfc => dfc.label === 'Autres' && dfc.category === 'Espace vert');
        let categories = [{
            id: othersCategory.id, label: i18n.t("Autres"), color: othersCategory.color, icon: othersCategory.icon,
            fields: customFields.filter(cf => {
                const pcf = projectCustomFields.find(pcf => pcf.customFieldId === cf.id);
                if (pcf) return pcf.fieldCategoryId === othersCategory.id && pcf;
                return elementCustomFields[cf.id];
            })
        }];

        if (project?.fieldCategories?.length)
            categories = [...categories, ...project.fieldCategories.filter(fc => fc.category === 'Espace vert').map(fc => {
                return { ...fc, fields: customFields.filter(cf => projectCustomFields.find(pcf => cf.id === pcf.customFieldId)?.fieldCategoryId === fc.id) };
            })];
        categories = categories.filter(c => c.fields.length);

        const iconStyle = { position: 'relative', top: 0, right: 0, marginLeft: '3px' };
        return categories.map(category => (
            <Card key={category.label} style={{ borderBottom: `solid 4px ${category.color}` }}>
                <Card.Header style={{ color: category.color }}>
                    <FontAwesomeIcon icon={category.icon} />
                    {category.label}
                </Card.Header>
                <Card.Content>
                    {category.fields
                        .sort((a, b) => project.projectCustomFields.find(pcf => a.id === pcf.customFieldId && pcf.fieldCategoryId === category.id)?.order
                            - project.projectCustomFields.find(pcf => b.id === pcf.customFieldId && pcf.fieldCategoryId === category.id)?.order)
                        .map((cf, i) => {
                            let value = cf.value && cf.type === 'boolean' ? cf.value === 'true' ? i18n.t("Oui") : i18n.t("Non")
                                : cf.type === 'date' ? DatesUtil.getFormattedLocaleDateString(cf.value)
                                    : cf.type === 'list' && cf.isMultiple ? cf.value.split(',').map(v => cf.dropdownCustomFieldValues.find(dcfv => String(dcfv.id) === v)?.label).filter(v => v)
                                        : cf.type === 'list' ? cf.dropdownCustomFieldValues.find(dcfv => String(dcfv.id) === cf.value)?.label
                                            : cf.value;
                            if (cf.type === 'number' && cf.unit?.trim()) value += cf.unit;
                            if (cf.type === 'formula') value = FormattersUtil.formatFormulaCustomField(cf, value);

                            return (
                                cf.type !== 'url' ?
                                    <Grid.Row key={`extra-field-${i + 1}`} style={Array.isArray(value) ? { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' } : null}>
                                        <label>{cf.label}{cf.description?.trim() && <InfoIcon content={cf.description} iconStyle={iconStyle} />} :</label>
                                        {!Array.isArray(value) ? <span>{value}</span>
                                            : <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                                {value.map(v => (
                                                    <li key={v}>{v}</li>
                                                ))}
                                            </ul>}
                                    </Grid.Row>
                                    : <Grid.Row>
                                        <Button size='small' style={{ padding: 0 }} type='button' color='green' >
                                            <a href={cf.value?.includes('http') ? cf.value : '//' + cf.value} target='_blank' rel='noreferrer' style={{ padding: '7px', display: 'block', height: '100%', width: '100%', color: 'inherit' }}>
                                                <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ marginRight: '5px' }} />{cf.label}
                                            </a>
                                        </Button>
                                    </Grid.Row>
                            );
                        })}
                </Card.Content>
            </Card>
        ));
    }

    renderLatestActionsDone = (latestActionsDone) => {
        const { isDarkTheme } = this.props;

        return (
            <div style={{ flex: 1, marginTop: isMobileOnly && '10px' }}>
                <Grid.Row>
                    <FontAwesomeIcon icon={faSquareCheck} style={{ marginRight: '7px', color: isDarkTheme ? 'white' : 'black' }} />
                    <label>{i18n.t("Dernières réalisées")}</label>
                </Grid.Row>
                {latestActionsDone.map((recurrence, index) =>
                    <div key={index} style={{ marginLeft: '8px' }}>
                        <label>{recurrence.actionLabel} :</label> <span title={i18n.t("Date de réalisation")}>{DatesUtil.getFormattedLocaleDateString(recurrence.validationDate)}</span>
                    </div>)}
            </div>
        );
    }

    renderActionsToDo = (actionsToDo) => {
        const { isDarkTheme } = this.props;

        return (
            <div style={{ flex: 1 }}>
                <Grid.Row>
                    <FontAwesomeIcon icon={faCalendarClock} style={{ marginRight: '7px', color: isDarkTheme ? 'white' : 'black' }} />
                    <label>{i18n.t("À venir")}</label>
                </Grid.Row>
                {actionsToDo.map((recurrence, index) =>
                    <div key={index} style={{ marginLeft: '8px' }}>
                        <label>
                            {recurrence.actionLabel} :</label> <span title={i18n.t("Date planifiée")}>{DatesUtil.getFormattedLocaleDateString(recurrence.date)}
                            {DatesUtil.convertUTCDateToDate(recurrence.date) < new Date() && <FontAwesomeIcon icon={faExclamationTriangle} title={i18n.t("En retard")} style={{ marginLeft: '5px', color: 'var(--red-100)' }} />}
                        </span>
                    </div>)}
            </div>
        );
    }

    setProperties = () => {
        const { project } = this.props;
        const properties = this.props.layer[0].feature.properties;
        const dominantComposition = this.props.dominantCompositions.find(x => x.id === properties.dominantCompositionId);
        const dominantCompositionLabel = dominantComposition?.label;
        const runoffCoefficientId = dominantComposition?.runoffCoefficientId;
        const runoffCoefficient = GreenSpacesUtil.getRunoffCoefficientString(this.props.runoffCoefficients.find(x => x.id === runoffCoefficientId));
        const managementClass = this.props.managementClasses.find(x => x.id === properties.managementClassId)?.value;
        const spaceFunction = this.props.spaceFunctions.find(x => x.id === properties.spaceFunctionId)?.label;
        const spaceType = this.props.spaceTypes.find(x => x.id === properties.spaceTypeId);
        const dominantEssence = this.props.essences.find(x => x.id === properties.dominantEssenceId);
        const averageHealthReview = this.props.healthReviews.find(x => x.id === properties.averageHealthReviewId);
        // Tags
        let tags = [];
        if (properties.tagId && this.state.requiredFields.trees.tags && project?.tags) {
            properties.tagId.forEach(tId => {
                const tag = project.tags.find(x => x.id === tId)?.label;
                tags.push(tag);
            });
        }
        // Stockage total CO2
        let totalCarbonStock = 0;
        if (dominantComposition?.id === 7) totalCarbonStock = FormulasUtil.getTreeTotalCarbonStock(properties.carbonStock, properties.averageCircumference);
        // Rafraîchissement
        const coolingEconomicValue = FormulasUtil.getCoolingEconomicValue(properties.coolingEnergyIndicator);
        // Production d'oxygène
        const oxygenProduction = FormulasUtil.getOxygenProduction(properties.carbonStock);
        // Stations
        const stationsLayer = this.props.layers.find(l => l.label === i18n.t("Stations"));
        const layers = stationsLayer ? stationsLayer.getLayers() : [];
        const stationLabels = layers.filter(l => booleanContains(l.feature, this.props.layer[0].feature) || booleanIntersects(this.props.layer[0].feature, l.feature)).map(l => l.feature.properties.label);

        this.setState({
            properties: {
                ...properties, tags, runoffCoefficient, managementClass, spaceFunction,
                spaceType: spaceType?.label, dominantEssence, averageHealthReview,
                dominantComposition: dominantCompositionLabel, runoffCoefficientId,
                totalCarbonStock, coolingEconomicValue, oxygenProduction, ecopotentialIndex: spaceType?.ecopotentialIndex
            },
            customFields: this.props.customFields.filter(cf => cf.category === 'Espace vert'),
            stationLabels
        });
    }

    getLatestActionsDone = () => {
        let recurrences = [];
        this.props.projectActions
            ?.filter(pa => pa.action.categories.includes(this.state.properties?.dominantCompositionId === 7 ? 'Massif arboré' : 'Espace vert'))
            .forEach(pa => {
                const pae = pa.projectActionElements.find(x => x.elementId === this.state.id);
                if (pae?.projectActionElementRecurrences?.length)
                    recurrences.push(...pae.projectActionElementRecurrences.filter(paer => !paer.status).map(paer => ({ ...paer, actionLabel: pa.action.label })));
            });

        recurrences.sort((a, b) => new Date(b.validationDate) - new Date(a.validationDate));
        return recurrences.slice(0, 5);
    }

    getActionsToDo = () => {
        let recurrences = [];
        this.props.projectActions
            ?.filter(pa => pa.action.categories.includes(this.state.properties?.dominantCompositionId === 7 ? 'Massif arboré' : 'Espace vert'))
            .forEach(pa => {
                const pae = pa.projectActionElements.find(x => x.elementId === this.state.id);
                if (pae?.projectActionElementRecurrences?.length)
                    recurrences.push(...pae.projectActionElementRecurrences.filter(paer => !paer.validationDate).map(paer => ({ ...paer, actionLabel: pa.action.label })));
            });

        recurrences.sort((a, b) => new Date(a.date) - new Date(b.date));
        return recurrences.slice(0, 5);
    }
}

const mapStateToProps = (state) => {
    return {
        layer: state.layer,
        dominantCompositions: state.dominantCompositions,
        runoffCoefficients: state.runoffCoefficients,
        managementClasses: state.managementClasses,
        spaceFunctions: state.spaceFunctions,
        spaceTypes: state.spaceTypes,
        essences: state.essences,
        healthReviews: state.healthReviews,
        project: state.project,
        projectCollaborators: state.projectCollaborators,
        isDarkTheme: state.isDarkTheme,
        photosGalleries: state.photosGalleries,
        projectActions: state.projectActions,
        defaultFieldCategories: state.defaultFieldCategories,
        customFields: state.project
            ? [...state.customFields, ...state.organizationCustomFields || [], ...(state.projectsCustomFields[state.project?.id] || [])]
            : state.customFields,
        rights: state.rights,
    };
};

const mapDispatchToProps = {
    setPhotosGalleries
};

export default connect(mapStateToProps, mapDispatchToProps)(GreenSpaceDetail);