import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import Cookies from 'universal-cookie';
// Utils
import { showToast } from '../utils/ToastsUtil';
import OfflineUtil from '../utils/OfflineUtil';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class EventsService {
    static getEvents() {
        return Axios.get(BACKEND_ENDPOINT + 'events/', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('events_loading_failed');
            return;
        });
    }

    static getProjectEvents(projectId) {
        return Axios.get(BACKEND_ENDPOINT + 'events/projectEvents/' + projectId, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('events_loading_failed');
            return;
        });
    }

    static addProjectEvent(projectEvent, projectId) {
        projectEvent.eventId = projectEvent.event.id;
        return Axios.post(BACKEND_ENDPOINT + 'events/', projectEvent, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => { 
            return OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'events/projectEvents/' + projectId, (body) => {
                body.push(response.data);
                return body;
            }).then(() => {
                if (navigator.onLine) showToast('event_added');
                else showToast('will_sync');
                return response.data;
            });
        }, () => {
            if (navigator.onLine) showToast('connection_failed');
            return;
        });
    }

    static updateProjectEvent(projectEvent, projectId, { peeToAdd = [], peeToRemove = [], successToast = 'event_updated', errorToast = 'event_update_failed' } = {}) {
        return Axios.put(BACKEND_ENDPOINT + 'events/', { projectEvent, peeToAdd, peeToRemove }, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            const pe = response.data;
            return OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'events/projectEvents/' + projectId, (body) => {
                const index = body.findIndex(projectEvent => projectEvent.id === pe.id);
                if (index !== -1) body[index] = pe;
                else body.push(pe);
                return body;
            }).then(() => {
                if (navigator.onLine) showToast(successToast);
                else if (!navigator.onLine) showToast('will_sync');
                return pe;
            });
        }, () => {
            if (navigator.onLine) showToast(errorToast);
            else showToast('will_sync');
            return;
        });
    }

    static removeProjectEvent(id, projectId) {
        OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'events/projectEvents/' + projectId, (body) => {
            return body.filter(projectEvent => projectEvent.id !== id);
        });

        return Axios.delete(BACKEND_ENDPOINT + 'events/' + id, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('event_deleted');
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('event_deletion_failed');
            else showToast('will_sync');
            return;
        });
    }

    static updateProjectEventElements(projectId, peId, projectEventElements) {
        OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'events/projectEvents/' + projectId, (body) => {
            const projectEvent = body.find(projectEvent => projectEvent.id === peId);
            const projectEventElements = projectEvent?.projectEventElements?.flatMap(pee => pee.projectEventElements);
            if (projectEventElements)
                projectEventElements.forEach(pee => {
                    const peeInCache = projectEventElements.find(pee => pee.id === pee.id);
                    if (peeInCache) peeInCache.comment = pee.comment;
                });
            return body;
        });

        return Axios.put(BACKEND_ENDPOINT + 'events/updateProjectEventElements/?projectId=' + projectId + '&projectEventId=' + peId, projectEventElements, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('event_updated');
            return response.data;
        }, () => {
            if (navigator.onLine) {
                showToast('connection_failed');
                return;
            } else {
                showToast('will_sync');
                return;
            }
        });
    }
}