import React, { Component } from 'react';
// Composants
import DatePickerWithPeriod from '../Utils/DatePickerWithPeriod';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProjectEventCreationForm from '../Forms/Events/ProjectEventCreationForm';
import ProjectEventModificationForm from '../Forms/Events/ProjectEventModificationForm';
import InfoIcon from '../Utils/InfoIcon';
/*     Editors     */
import CommentEditor from './Editors/CommentEditor';
/*     Filters     */
import TextFilter from './Filters/TextFilter';
import NumberFilter from './Filters/NumberFilter';
import Woops from '../Utils/Woops';
// Librairies
import DataGrid, { Row as GridRow } from 'react-data-grid';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import i18n from '../../locales/i18n';
import { endOfDay, endOfMonth, startOfDay, startOfMonth } from 'date-fns';
import { faFlowerTulip, faCheck, faTree, faLink, faTrash, faTablePicnic, faPenToSquare, faEye, faCalendar } from '@fortawesome/pro-solid-svg-icons';
import { isMobile, isMobileOnly, withOrientationChange } from 'react-device-detect';
// Redux
import { connect } from 'react-redux';
import { setEditedProperties, unlockEditedProperties, setTableState } from '../../actionCreators/componentsActions';
import { setLayer } from '../../actionCreators/elementsActions';
import { setProjectEvents } from '../../actionCreators/projectsActions';
// Ressources
import { faFolder, faFolderOpen } from '@fortawesome/pro-solid-svg-icons';
// Semantic UI
import { Button, Segment, Grid, Message, Dimmer, Dropdown, List, Icon } from 'semantic-ui-react';
// Services
import EventsService from '../../services/EventsService';
// Styles
import '../../styles/react-contextmenu.css';
import '../../styles/rdg.css';
// Utils
import DatesUtil from '../../utils/DatesUtil';
import WebSocketUtil from '../../utils/WebSocketUtil';
import StylesUtil from '../../utils/StylesUtil';
import FormattersUtil from '../../utils/FormattersUtil';
import RightsUtil from '../../utils/RightsUtil';
import UrlsUtil from '../../utils/UrlsUtil';

const initialFilters = {
    label: '',
    elements: ['trees', 'greenspaces', 'furnitures'],
};

class EventTable extends Component {
    state = {
        period: 'month',
        startDate: startOfDay(startOfMonth(new Date())),
        endDate: endOfDay(endOfMonth(new Date())),
        data: {
            columns: [],
            rows: []
        },
        sortColumn: null,
        sortDirection: 'NONE',
        enableFilterRow: false,
        showZeros: false,
        filters: initialFilters,
        groupedBy: 'projectEvent',
        peToDelete: null,
        isDeleteLoading: false,
        addCategory: null,
        isLoading: true,
        newProjectEvent: null,
        projectEventToEdit: null,
        nbPeeToDelete: 0,
        rowToEdit: null,
        search: null,
    }

    render() {
        const { rights, isDarkTheme, project, projectEvents } = this.props;
        const {
            period, startDate, endDate, data, sortColumn, sortDirection, enableFilterRow, filters,
            peToDelete, isDeleteLoading, addCategory, isLoading, isOverflowing, newProjectEvent, projectEventToEdit, nbPeeToDelete
        } = this.state;
        const rows = this.getFilteredRows();

        return (
            <>
                <div className='modal-content'>
                    {!projectEvents ?
                        <Woops />
                        :
                        <>
                            <div className='modal-content-header' style={{ display: 'flex', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row', marginBottom: '4px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', flex: 1, marginBottom: !isMobileOnly && isMobile && '5px' }}>
                                        <div style={{ display: 'flex' }}>
                                            <DatePickerWithPeriod hideLabel={true} period={period} startDate={startDate} endDate={endDate} forceTime={true} setDates={this.setDates} setPeriod={this.setPeriod} />
                                        </div>
                                        {!isMobile && this.renderToolbar()}
                                        {!isMobileOnly && isMobile &&
                                            <div style={{ marginLeft: 'auto' }}>
                                                {project.type === 'project' && !isMobile && RightsUtil.canWrite(this.props.rights?.events) && this.renderNewEventButton()}
                                            </div>}
                                    </div>
                                </div>
                                {isMobile
                                    ? this.renderToolbar()
                                    : (
                                        <div style={{ marginLeft: 'auto', order: 2, marginBottom: isMobile && '5px' }}>
                                            {project.type === 'project' && !isMobile && RightsUtil.canWrite(this.props.rights?.events) && this.renderNewEventButton()}
                                        </div>
                                    )}
                            </div>
                            <div className='modal-content-body'>
                                <Segment id='action-table__segment' style={{ display: 'flex', flexFlow: 'column', padding: 0, width: '100%', height: '100%' }}>
                                    {data?.columns &&
                                        <>
                                            <DataGrid
                                                ref={this.gridRef} className={isDarkTheme ? 'rdg-dark' : 'rdg-light'}
                                                columns={data.columns}
                                                rows={rows} rowRenderer={this.rowRenderer} rowClass={(row) => row.parentId ? 'child-row' : 'parent-row'}
                                                defaultColumnOptions={{ sortable: true, resizable: true }}
                                                cellNavigationMode='LOOP_OVER_ROW'
                                                sortColumn={sortColumn} sortDirection={sortDirection}
                                                onSort={this.handleSort} enableFilterRow={enableFilterRow}
                                                filters={filters} onFiltersChange={filters => this.setState(prevState => ({ filters, search: UrlsUtil.adjustSearch(prevState.search, { filters: Object.keys(filters).filter(f => !['elements', 'status'].includes(f) && filters[f]?.trim?.()).map(f => `${f}:${filters[f].trim()}`).join(',') }) }))}
                                                emptyRowsRenderer={() => (<div style={{ textAlign: 'center' }}>
                                                    <span>{isLoading ? i18n.t("Chargement en cours...") : i18n.t("Aucun résultat trouvé")}</span>
                                                </div>)}
                                                onSelectedCellChange={({ idx, rowIdx }) => { this.selectedRow = rows[rowIdx]; this.selectedColumn = data.columns[idx]; }}
                                                onRowsChange={this.handleRowsChange}
                                                style={{ height: (72 + (35 * rows.length) + (enableFilterRow ? 45 : 0) + (isOverflowing ? 10 : 0)) + 'px' }}
                                            />
                                            {project.type === 'project' &&
                                                <>
                                                    <ContextMenu id='grid-manageable-parent-context-menu'>
                                                        {RightsUtil.canWrite(rights?.events) && <MenuItem onClick={this.manageEventElements}>{i18n.t("Attribuer à un élément")}</MenuItem>}
                                                        <MenuItem disabled={!this.props.isOnline} onClick={this.editProjectEvent}>{i18n.t("Modifier")}</MenuItem>
                                                        {RightsUtil.canWrite(rights?.events) && <MenuItem onClick={this.handleDelete}>{i18n.t("Supprimer")}</MenuItem>}
                                                    </ContextMenu>
                                                    <ContextMenu id='grid-not-manageable-parent-context-menu'>
                                                        <MenuItem onClick={this.manageEventElements}>{i18n.t("Voir")}</MenuItem>
                                                    </ContextMenu>
                                                </>}
                                        </>}
                                </Segment>
                            </div>
                            {(addCategory || isMobile || projectEventToEdit) &&
                                <div className='modal-content-footer'>
                                    {isMobile && !addCategory && this.renderNewEventButton()}
                                    {(addCategory || projectEventToEdit) &&
                                        <Segment style={{ marginTop: '12px' }}>
                                            {addCategory &&
                                                <ProjectEventCreationForm
                                                    category={addCategory} projectEvent={newProjectEvent} selectElementsToLink={this.selectElementsToLink}
                                                    cancel={() => this.setState({ addCategory: null, showWorkload: false, newProjectEvent: null })} submit={() => this.setState({ addCategory: null, showWorkload: false, newProjectEvent: null })}

                                                />}
                                            {projectEventToEdit &&
                                                <ProjectEventModificationForm
                                                    projectEvent={projectEventToEdit} cancel={() => this.setState({ projectEventToEdit: null })}
                                                    submit={(projectEventToEdit) => this.setState({ projectEventToEdit }, this.handleModificationConfirmation)}
                                                />}
                                        </Segment>}
                                </div>}
                        </>}
                </div>
                {(peToDelete || nbPeeToDelete > 0) &&
                    <Dimmer active style={{ ...StylesUtil.getMapStyles().dimmerStyle, position: 'fixed' }}>
                        <Grid style={{ height: '100%' }}>
                            <Grid.Row style={{ height: '100%' }} verticalAlign='middle'>
                                <Grid.Column>
                                    <Message compact className='tableConfirmation'>
                                        <Message.Content style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Message icon error style={{ textAlign: 'left' }}>
                                                <Icon name='trash' />
                                                <Message.Content>
                                                    <Message.Header>
                                                        {peToDelete
                                                            ? i18n.t("Êtes-vous certain de vouloir supprimer cette évènement ?")
                                                            : i18n.t("Êtes-vous certain de vouloir modifier cette évènement ?")}
                                                    </Message.Header>
                                                    {i18n.t("Cela engendrera")}
                                                    <List as='ul' style={{ marginTop: 0 }}>
                                                        {peToDelete && <List.Item as='li' className='error'>{nbPeeToDelete > 1 ? i18n.t("La suppression de {{count}} liaisons existantes.", { count: nbPeeToDelete }) : i18n.t("La suppression d'une liaison existante.")}</List.Item>}
                                                    </List>
                                                </Message.Content>
                                            </Message>
                                            <div style={{ marginLeft: 'auto' }}>
                                                <Button color='red' disabled={isDeleteLoading} onClick={peToDelete ? this.handleDeleteCancel : this.handleModificationCancel}>
                                                    {i18n.t("Annuler")}
                                                </Button>
                                                <Button color='green' disabled={isDeleteLoading} loading={isDeleteLoading} onClick={peToDelete ? this.handleDeleteConfirmation : this.handleModificationConfirmation}>
                                                    {i18n.t("Valider")}
                                                </Button>
                                            </div>
                                        </Message.Content>
                                    </Message>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Dimmer>}
            </>
        );
    }

    componentDidMount = async () => {
        window.addEventListener('resize', this.handleResize);
        this.props.unlockEditedProperties(); // On débloque la sauvegarde des propriétés dans le store Redux
        if (this.props.tableState) {
            const { elements, data: oldData, ...state } = this.props.tableState;
            this.elements = elements;
            const data = await this.loadData({ groupedBy: state.groupedBy, rows: oldData.rows });
            this.setState({ data, ...state });
            this.props.setTableState(null);
        } else {
            this.elements = {};

            const editedProperties = this.props.editedProperties ? { ...this.props.editedProperties } : null;
            if (editedProperties) { // Si un state a été sauvegardé, on le restore
                this.setState({ ...this.state, ...editedProperties }, () => {
                    this.props.setEditedProperties(null).then(() => this.props.unlockEditedProperties());

                    if (this.props.layersToLink?.length || this.props.layersToUnlink?.length) { // Si des layers ont été sélectionné
                        if (editedProperties.addCategory) {
                            this.setState(prevState => ({
                                newProjectEvent: {
                                    ...prevState.newProjectEvent, projectEventElements: [
                                        ...(prevState.newProjectEvent.projectEventElements || []).filter(pee => !this.props.layersToUnlink.find(layer => layer.feature.id === pee.elementId)),
                                        ...this.props.layersToLink.map(layer => ({ elementId: layer.feature.id, elementType: layer.feature.properties.category }))
                                    ]
                                }
                            }));
                        } else {
                            const projectEvent = this.state.projectEvent;
                            const peeToRemove = projectEvent.projectEventElements.filter(pee => this.props.layersToUnlink.find(layer => layer.feature.id === pee.elementId));
                            const peeToAdd = this.props.layersToLink
                                .filter(layer => !projectEvent.projectEventElements.find(pee => pee.elementId === layer.feature.id))
                                .map(layer => ({ elementId: layer.feature.id, elementType: layer.feature.properties.category }));

                            if (peeToAdd.length > 0 || peeToRemove.length > 0)
                                EventsService.updateProjectEvent(projectEvent, this.props.project.id, { peeToAdd, peeToRemove, successToast: 'event_linked_several', errorToast: 'connection_failed' }).then(response => {
                                    projectEvent.projectEventElements = response.projectEventElements;
                                    const expandedRows = this.state.data.rows.filter(row => row.isExpanded).map(row => row.id);
                                    this.loadData().then(() => { this.toggleSubRows(expandedRows) });
                                    WebSocketUtil.updateProjectEvents(this.props.webSocketHubs, this.props.project.id, [projectEvent]);
                                    this.setState({ projectEvent: null });
                                    this.props.setLayer(null);
                                });
                        }
                    }
                });
            } else {
                const { period, startDate, endDate, enableFilterRow, showZeros, filters, groupedBy, elements, toggledRows } = UrlsUtil.getSearchParams(this.props.location?.search);
                this.setState(prevState => ({
                    period: period || prevState.period,
                    startDate: startDate ? new Date(startDate) : prevState.startDate,
                    endDate: endDate ? new Date(endDate) : prevState.endDate,
                    enableFilterRow: [true, false].includes(enableFilterRow) ? enableFilterRow : prevState.enableFilterRow,
                    showZeros: [true, false].includes(showZeros) ? showZeros : prevState.showZeros,
                    filters: {
                        ...prevState.filters,
                        ...(filters?.split(',').reduce((prevValue, filter) => ({ ...prevValue, [filter.split(':')[0]]: filter.split(':')[1] }), {}) || {}),
                        elements: elements === 'none' ? [] : (elements?.split(',') || prevState.filters.elements),
                    },
                    search: this.props.location?.search || UrlsUtil.adjustSearch(prevState.search, { startDate: (startDate ? new Date(startDate) : prevState.startDate).toISOString(), endDate: (endDate ? new Date(endDate) : prevState.endDate).toISOString() })
                }), () => {
                    this.loadData({ groupedBy: groupedBy || 'projectEvent' }).then(() => {
                        if (toggledRows) this.toggleSubRows(toggledRows.split ? toggledRows.split(',').map(id => +id) : [+toggledRows]);
                    });;
                });
            }
        }

        this.gridRef = React.createRef();
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (JSON.stringify(prevProps.projectEvents) !== JSON.stringify(this.props.projectEvents)) {
            const expandedRows = this.state.data.rows.filter(row => row.isExpanded).map(row => row.id);
            this.loadData().then(() => { this.toggleSubRows(expandedRows) });
        }

        const { isOverflowing, data } = this.state;
        if (isOverflowing === undefined && data?.columns) {
            const gridElement = document.getElementsByClassName('rdg')[0];
            if (gridElement) {
                const width = data.columns.reduce((previousValue, column) => previousValue + column.width, 0);
                this.setState({ isOverflowing: gridElement.clientWidth < width });
            }
        }

        if (prevState.search?.long !== this.state.search?.long) this.props.history.push({ search: this.state.search?.long })
    }

    componentWillUnmount = () => {
        document.removeEventListener('keydown', this.handleKeyDown);
        document.removeEventListener('resize', this.handleResize);
    }

    renderToolbar = () => {
        const { project } = this.props;
        const { enableFilterRow, showZeros, groupedBy } = this.state;

        const checkIcon = <FontAwesomeIcon icon={faCheck} style={{ position: 'relative', left: '4px' }} />;

        return (
            <div style={{ marginTop: isMobileOnly && '5px', ...(isMobileOnly ? { paddingBottom: '5px', display: 'flex', maxWidth: '100%', overflow: 'overlay', alignItems: 'center' } : {}) }}>
                <Button.Group style={{ marginRight: '3.5px' }}>
                    <Button
                        title={enableFilterRow ? i18n.t("Désactiver les filtres") : i18n.t("Activer les filtres")}
                        className={enableFilterRow ? 'button--secondary' : null} color={!enableFilterRow ? 'grey' : null} icon='filter'
                        onClick={this.toggleFilters}
                    />
                    <Button
                        title={i18n.t("Réinitialiser les filtres")} className='button--secondary' icon='dont'
                        onClick={this.clearFilters} disabled={!this.areFiltersApplied()}
                    />
                    <Dropdown floating icon='group' button className='icon button--secondary' title={i18n.t("Regroupement")}>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                text={this.renderDropdownText(i18n.t("Aucun"), groupedBy, 'none', checkIcon)}
                                onClick={() => this.handleGroupChange('none')}
                            />
                            <Dropdown.Item
                                text={this.renderDropdownText(i18n.t("Évènement avec date"), groupedBy, 'projectEvent', checkIcon)}
                                onClick={() => this.handleGroupChange('projectEvent')}
                            />
                            <Dropdown.Item
                                text={this.renderDropdownText(i18n.t("Évènement"), groupedBy, 'event', checkIcon)}
                                onClick={() => this.handleGroupChange('event')}
                            />
                            <Dropdown.Item
                                text={this.renderDropdownText(i18n.t("Référence"), groupedBy, 'reference', checkIcon)}
                                onClick={() => this.handleGroupChange('reference')}
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button
                        title={showZeros ? i18n.t("Masquer les zéros") : i18n.t("Afficher les zéros")}
                        className={showZeros ? 'button--secondary' : null} color={!showZeros || groupedBy !== 'projectEvent' ? 'grey' : null} icon='eye'
                        disabled={groupedBy !== 'projectEvent'} onClick={() => this.setState(prevState => ({ showZeros: !prevState.showZeros, search: UrlsUtil.adjustSearch(prevState.search, { showZeros: prevState.showZeros ? false : null }) }))}
                    />
                </Button.Group>
                {!isMobileOnly &&
                    <Button.Group style={{ marginRight: '3.5px' }}>
                        <Button title={i18n.t("Masquer toutes les liaisons")} className='button--secondary' style={{ padding: '11px' }} onClick={this.collapseAll}>
                            <FontAwesomeIcon icon={faFolder} style={{ height: '12px' }} />
                        </Button>
                        <Button title={i18n.t("Afficher toutes les liaisons")} className='button--secondary' style={{ padding: '11px' }} onClick={this.expandAll}>
                            <FontAwesomeIcon icon={faFolderOpen} style={{ height: '12px' }} />
                        </Button>
                    </Button.Group>}
            </div>
        );
    }

    renderNewEventButton = () => {
        return (
            <Button
                onClick={() => this.setState({ addCategory: 'Arbre', projectEventToEdit: null })}
                className={`button--primary${isMobile ? ' form-button' : ''}`}
                style={{ textAlign: isMobile && 'center', marginLeft: 'auto' }}
            >
                {i18n.t("Nouvel évènement")}
            </Button>
        );
    }

    renderDropdownText = (label, condition, value, icon1, icon2) => {
        return (<div style={{ display: 'flex', width: '100%' }}>
            <div style={{ marginRight: '30px' }}>{label}</div>
            <div style={{ marginLeft: 'auto' }}>{condition === value ? icon1 : icon2}</div>
        </div>);
    }

    rowRenderer = (props) => {
        const isManageable = this.props.project.type === 'project';
        return (
            <>
                {props.row.isSubrow || (!props.row.parentId && ['event', 'reference', 'none'].includes(this.state.groupedBy)) ?
                    <GridRow {...props} />
                    :
                    <ContextMenuTrigger id={`grid-${!props.row.parentId ? `${isManageable ? 'manageable' : 'not-manageable'}-parent` : 'child'}-context-menu`} collect={() => ({ rowIdx: props.rowIdx })}>
                        <GridRow {...props} />
                    </ContextMenuTrigger>}
            </>
        );
    }

    handleRowsChange = (newRows) => {
        this.setState(prevState => {
            let rows = prevState.data.rows;
            newRows.forEach(newRow => {
                const index = rows.findIndex(row => row.id === newRow.id);
                rows[index] = newRow;
            });
            return { data: { columns: prevState.data.columns, rows: rows } };
        });
    }

    getRows = (groupedBy) => {
        let rows = [];

        const categories = { 'Arbre': 'trees', 'Espace vert': 'greenspaces', 'Mobilier': 'furnitures' };
        const layers = [...this.props.trees.getLayers(), ...this.props.greenSpaces.getLayers(), ...this.props.furnitures.getLayers()];

        switch (groupedBy) {
            case 'none':
                rows = this.props.projectEvents.flatMap(pe => (
                    pe.projectEventElements.flatMap(pee => {
                        let element = this.elements[pee.elementId];
                        if (!element) element = layers.find(layer => layer.feature.id === pee.elementId)?.feature;
                        if (!element) return null;
                        const { projectReference, customReference, place, category } = element.properties;

                        return {
                            id: `${pee.projectEventId}-${pee.elementId}`,
                            label: pe.event.label,
                            isUrgent: true,
                            event: pe.event,
                            category: categories[category],
                            projectEvent: pe,
                            elementId: pee.elementId,
                            date: pe.date,
                            reference: projectReference + (customReference ? ` (${customReference})` : ''),
                            place,
                            comment: pee.comment,
                        };
                    })
                )).filter(row => row);
                break;
            case 'projectEvent':
                rows = this.props.projectEvents.map(pe => {
                    return {
                        id: pe.id,
                        categories: ['trees'],
                        label: pe.event.label,
                        event: pe.event,
                        projectEventElements: pe.projectEventElements,
                        links: pe.projectEventElements.length,
                        date: pe.date,
                        children: pe.projectEventElements.flatMap(pee => {
                            let element = this.elements[pee.elementId];
                            if (!element) element = layers.find(layer => layer.feature.id === pee.elementId)?.feature;
                            if (element) {
                                const { projectReference, customReference, place, category } = element.properties;

                                return {
                                    id: `${pee.projectEventId}-${pee.elementId}`,
                                    category: categories[category],
                                    parentId: pe.id,
                                    projectEventId: pe.id,
                                    elementId: pee.elementId,
                                    date: pe.date,
                                    reference: projectReference + (customReference ? ` (${customReference})` : ''),
                                    place,
                                    comment: pee.comment,
                                };
                            } else return null;
                        }).filter(row => row)
                    };
                });
                break;
            case 'event':
                let id = 0;
                this.props.projectEvents.forEach(pe => {
                    const row = rows.find(row => row.label === pe.event.label);

                    if (row) {
                        row.links++;
                        row.children.push(...pe.projectEventElements
                            .map(pee => {
                                let element = this.elements[pee.elementId];
                                if (!element) element = layers.find(layer => layer.feature.id === pee.elementId)?.feature;
                                const { projectReference, customReference, place, category } = element.properties || {};

                                return element ? {
                                    id: `${pee.projectEventId}-${pee.elementId}`,
                                    parentId: row.id,
                                    elementId: pee.elementId,
                                    projectEvent: pe,
                                    category: categories[category],
                                    date: pe.date,
                                    reference: projectReference + (customReference ? ` (${customReference})` : ''),
                                    place,
                                    comment: pee.comment
                                } : null
                            })
                            .filter(child => child));
                    } else {
                        id++;
                        rows.push({
                            id,
                            label: pe.event.label,
                            links: 1,
                            children: pe.projectEventElements
                                .flatMap(pee => {
                                    let element = this.elements[pee.elementId];
                                    if (!element) element = layers.find(layer => layer.feature.id === pee.elementId)?.feature;
                                    const { projectReference, customReference, place, category } = element.properties || {};

                                    return element ? {
                                        id: `${pee.projectEventId}-${pee.elementId}`,
                                        parentId: id,
                                        elementId: pee.elementId,
                                        projectEvent: pe,
                                        category: categories[category],
                                        date: pe.date,
                                        reference: projectReference + (customReference ? ` (${customReference})` : ''),
                                        place,
                                        comment: pee.comment,
                                    } : null
                                })
                                .filter(child => child)
                        });
                    }
                });
                break;
            case 'reference':
                this.props.projectEvents.forEach(pe => {
                    pe.projectEventElements.forEach(pee => {
                        let element = this.elements[pee.elementId];
                        if (!element) element = layers.find(layer => layer.feature.id === pee.elementId)?.feature;
                        if (element) {
                            const { projectReference, customReference, place, category } = element.properties;

                            const row = rows.find(row => row.id === pee.elementId);
                            if (row) {
                                row.links++;
                                row.children.push({
                                    id: `${pee.projectEventId}-${pee.elementId}`,
                                    parentId: row.id,
                                    elementId: pee.elementId,
                                    projectEvent: pe,
                                    date: pe.date,
                                    label: pe.event.label,
                                    comment: pee.comment
                                })
                            } else rows.push({
                                id: pee.elementId,
                                category: categories[category],
                                reference: projectReference + (customReference ? ` (${customReference})` : ''),
                                place,
                                links: 1,
                                children: [{
                                    id: `${pee.projectEventId}-${pee.elementId}`,
                                    parentId: pee.elementId,
                                    elementId: pee.elementId,
                                    projectEvent: pe,
                                    date: pe.date,
                                    label: pe.event.label,
                                    comment: pee.comment
                                }]
                            });
                        }
                    });

                });
                break;
            default: break;
        }

        return rows.map(row => ({ ...row, children: row.children?.sort((a, b) => new Date(a.date) - new Date(b.date)) }));
    }

    loadData = ({ groupedBy = this.state.groupedBy, rows } = {}) => new Promise(resolve => {
        const isNone = groupedBy === 'none', isEvent = groupedBy === 'event', isRef = groupedBy === 'reference';
        const data = {
            columns: [],
            rows: rows || []
        };

        const getExpandFormatter = (childRows, value, expanded, onCellExpand) => {
            if (!childRows?.length) return value;

            const expander = { title: null, color: '#7d7d7d' };

            return (
                <div className='expandable'>
                    <div className='rdg-cell-value'>
                        <div>{value}</div>
                    </div>
                    <div className='expand-formatter'>
                        <span title={expander.title} onClick={e => { e.stopPropagation(); onCellExpand() }}>
                            <span tabIndex={-1} className='expander' style={{ color: expander.color }}>
                                {expanded ? '\u25BC' : '\u25B6'}
                            </span>
                        </span>
                    </div>
                </div>
            )
        };

        data.columns = [
            {
                name: isRef ? i18n.t("Référence") : i18n.t("Libellé"), key: isRef ? 'reference' : 'label', width: isRef ? 200 : 250, sortable: true,
                formatter: (props) => props.row.isSubrow && !isRef
                    ? <div className='subheader'>{!isRef && i18n.t("Lieu")}</div>
                    : !props.row.isSubrow && !props.row.parentId ? getExpandFormatter(
                        props.row.children,
                        <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            {isRef && <Button size='mini' color='blue' icon='eye' className='show-button' style={{ marginRight: '5px' }} onClick={() => this.showElement(props.row.id, props.row.category)} />}
                            {props.row.category &&
                                <FontAwesomeIcon
                                    icon={props.row.category === 'trees' ? faTree : props.row.category === 'greenspaces' ? faFlowerTulip : faTablePicnic}
                                    title={props.row.category === 'trees' ? i18n.t("Arbre") : props.row.category === 'greenspaces' ? i18n.t("Espace vert") : i18n.t("Mobilier")}
                                    style={{ marginRight: '7px', alignSelf: 'center' }}
                                />}
                            {props.row[isRef ? 'reference' : 'label']}
                        </div>,
                        props.row.isExpanded === true,
                        () => this.toggleSubRows([props.row.id])
                    ) : props.row.place,
                summaryFormatter: () => <div className='subheader total'>{i18n.t("Total")}</div>,
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: isEvent ? '' : isRef ? i18n.t("Lieu") : i18n.t("Date"), key: isEvent ? '' : isRef ? 'place' : 'date', width: 325, sortable: true,
                formatter: (props) => props.row.isSubrow
                    ? <div className='subheader'>{i18n.t("Date")}</div>
                    : !props.row.parentId && isEvent ? '' : isRef && !props.row.parentId ? props.row.place
                        :
                        <div style={{ display: 'flex' }}>
                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'flex' }}>
                                <div title={i18n.t("Date")}>
                                    <FontAwesomeIcon icon={faCalendar} style={{ marginRight: '5px' }} />
                                    {DatesUtil.getFormattedLocaleDateString(props.row.date)}
                                </div>
                            </div>
                        </div>,
                filterRenderer: (props) => !isEvent && <TextFilter p={props} />
            },
            {
                name: isNone ? i18n.t("Référence") : !isRef && i18n.t("Liaisons"), key: isNone ? 'reference' : !isRef && 'links', width: isRef ? 250 : 140, sortable: true,
                formatter: (props) => props.row.isSubrow
                    ? <div className='subheader'>{isRef ? i18n.t("Libellé") : i18n.t("Référence")}</div>
                    : !props.row.parentId && !isNone
                        ? <div style={{ display: 'flex', alignItems: 'center' }}>
                            {isRef
                                ? props.row.description
                                : <>
                                    {props.row.links}
                                    {!props.row.links &&
                                        <InfoIcon content={i18n.t("Aucune occurrence de cet évènement n'existe pour la période sélectionnée")} iconStyle={{ marginLeft: '7px' }} />}
                                </>}
                        </div>
                        : <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                {props.row.category &&
                                    <FontAwesomeIcon
                                        icon={props.row.category === 'trees' ? faTree : props.row.category === 'greenspaces' ? faFlowerTulip : faTablePicnic}
                                        title={props.row.category === 'trees' ? i18n.t("Arbre") : props.row.category === 'greenspaces' ? i18n.t("Espace vert") : i18n.t("Mobilier")}
                                        style={{ marginRight: '7px' }}
                                    />}
                                {props.row[isRef ? 'label' : 'reference']}
                            </div>
                            {!isRef &&
                                <Button
                                    color='blue' className='show-button' title={i18n.t("Voir sur la carte")}
                                    style={{ marginLeft: 'auto', padding: 0, height: '20px', width: '20px' }} onClick={() => this.showElement(props.row.elementId, props.row.category)}
                                >
                                    <FontAwesomeIcon icon={faEye} size='sm' />
                                </Button>}
                        </div>,
                summaryFormatter: !isNone && ((props) => <div className='subheader total'>{props.row.links}</div>),
                filterRenderer: (props) => <NumberFilter p={props} />
            }
        ];

        if (isRef)
            data.columns.splice(3, 0, {
                name: i18n.t("Liaisons"), key: 'links', width: 250, sortable: true, editable: (props) => props.parentId ? true : false,
                formatter: (props) => props.row.isSubrow
                    ? <div className='subheader'>{i18n.t("Commentaire")}</div>
                    : !props.row.parentId
                        ? <div style={{ display: 'flex', alignItems: 'center' }}>
                            {props.row.links}
                            {!props.row.links &&
                                <InfoIcon content={i18n.t("Aucune occurrence de cette évènement n'existe pour la période sélectionnée")} iconStyle={{ marginLeft: '7px' }} />}
                        </div>
                        : props.row.comment,
                summaryFormatter: (props) => <div className='subheader total'>{props.row.links}</div>,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, onRowChange, onClose }) => <CommentEditor row={row} onRowChange={onRowChange} onClose={onClose} updateProjectEventElement={this.updateProjectEventElement} />
            });

        if (['none', 'projectEvent'].includes(groupedBy) && this.props.project.type === 'project' && RightsUtil.canWrite(this.props.rights?.events))
            data.columns.splice(0, 0, {
                name: '', key: 'events', width: 110,
                formatter: (props) =>
                    !props.row.isSubrow && !props.row.parentId &&
                    <>
                        <Button color='blue' title={i18n.t("Attribuer à un élément")} style={{ padding: 0, height: '25px', width: '25px' }} onClick={() => this.manageEventElements(null, props)}>
                            <FontAwesomeIcon icon={faLink} />
                        </Button>
                        {groupedBy !== 'none' &&
                            <>
                                <Button color='yellow' title={i18n.t("Modifier")} style={{ padding: 0, height: '25px', width: '25px' }} disabled={!this.props.isOnline} onClick={() => this.editProjectEvent(null, props)}>
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                </Button>
                                <Button color='red' title={i18n.t("Supprimer")} style={{ padding: 0, height: '25px', width: '25px' }} onClick={() => this.handleDelete(null, props)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            </>}
                    </>
            });

        if (isNone || isEvent || groupedBy === 'projectEvent')
            data.columns.push({
                name: isNone ? i18n.t("Commentaire") : '', key: 'comment', width: isRef ? 140 : 250,
                sortable: true, editable: () => true,
                formatter: (props) => props.row.isSubrow
                    ? <div className='subheader'>{i18n.t("Commentaire")}</div>
                    : props.row.comment,
                filterRenderer: (props) => <TextFilter p={props} />,
                editor: ({ row, onRowChange, onClose }) => <CommentEditor row={row} onRowChange={onRowChange} onClose={onClose} updateProjectEventElement={this.updateProjectEventElement} />
            });

        if (isNone)
            data.columns.push({
                name: i18n.t("Lieu"), key: 'place', width: isRef ? 140 : 250, sortable: true,
                formatter: (props) => props.row.isSubrow
                    ? <div className='subheader'>{i18n.t("Lieu")}</div>
                    : props.row.place,
                filterRenderer: (props) => <TextFilter p={props} />,
            });

        if (!rows) {
            data.rows = this.getRows(groupedBy);

            const peeList = this.props.projectEvents.flatMap(pe => pe.projectEventElements);

            const initialOrder = [];
            data.rows.forEach(row => {
                initialOrder.push(row.id);
                if (row.children) row.children.forEach(childRow => initialOrder.push(`${row.id}|${childRow.id}`));
            });

            this.setState(prevState => ({ data, peeList, initialOrder, groupedBy, isLoading: false, search: UrlsUtil.adjustSearch(prevState.search, { groupedBy: groupedBy === 'projectEvent' ? null : groupedBy }) }), () => resolve());
        } else resolve(data);
    });

    setDates = (startDate, endDate) => this.setState(prevState => ({ startDate, endDate, search: UrlsUtil.adjustSearch(prevState.search, { startDate: startDate?.toISOString(), endDate: endDate?.toISOString() }) }));
    setPeriod = (period) => this.setState(prevState => ({ period, search: UrlsUtil.adjustSearch(prevState.search, { period: period === 'none' ? null : period }) }));
    handleGroupChange = (groupedBy) => this.loadData({ groupedBy });

    manageEventElements = (_, { rowIdx }) => { // Lorsqu'on clique pour lier des éléments à l'event*
        const { groupedBy } = this.state;
        const filteredRows = this.getFilteredRows();
        const row = filteredRows[rowIdx];
        const projectEvent = this.props.projectEvents.find(pa => pa.id === (groupedBy === 'none' ? row.projectEvent?.id : row.id));
        this.props.setEditedProperties({ ...this.state, projectEvent, addCategory: null, showWorkload: false, projectEventToEdit: null }).then(() => {
            this.props.manageEventElements(projectEvent);
        });
    }

    editProjectEvent = (_, { rowIdx }) => {
        const filteredRows = this.getFilteredRows();
        const projectEventToEdit = this.props.projectEvents.find(pa => pa.id === filteredRows[rowIdx].id);
        this.setState(prevState => ({
            addCategory: null, showWorkload: false,
            projectEventToEdit: !prevState.projectEventToEdit || prevState.projectEventToEdit?.id !== projectEventToEdit.id ? JSON.parse(JSON.stringify(projectEventToEdit)) : null
        }));
    }

    updateProjectEventElement = (_, peeToUpdate) => {
        const { projectEventId, projectEvent } = peeToUpdate;
        const peId = !isNaN(projectEventId) ? projectEventId : projectEvent && !isNaN(projectEvent.id) ? projectEvent.id : null;
        let pee = this.state.peeList.find(pee => pee.projectEventId === peId && pee.elementId === peeToUpdate.elementId);

        if (!peId || !pee || pee.comment === peeToUpdate.comment) return;
        pee.comment = peeToUpdate.comment;

        EventsService.updateProjectEventElements(this.props.project.id, pee.projectEventId, [pee]).then(projectEventElements => {
            if (projectEventElements) WebSocketUtil.updateProjectEventElements(this.props.webSocketHubs, this.props.project.id, projectEventElements);

            // Mise à jour des rows
            const isNone = this.state.groupedBy === 'none';
            this.setState(prevState => {
                const rows = [...prevState.data.rows];
                if (!isNone) {
                    const parentRow = rows.find(row => row.id === peeToUpdate.parentId);
                    const childRowInParent = parentRow.children.find(row => row.id === peeToUpdate.id);
                    childRowInParent.comment = pee.comment;
                }
                const childRow = rows.find(row => row.id === peeToUpdate.id);
                childRow.comment = pee.comment;
                return { data: { columns: prevState.data.columns, rows }, rowToEdit: null };
            });
        });
    }

    handleDelete = (_, { rowIdx }) => {
        const filteredRows = this.getFilteredRows();
        const projectEvent = this.props.projectEvents.find(pe => pe.id === filteredRows[rowIdx].id);
        this.setState({ peToDelete: projectEvent.id, nbPeeToDelete: this.state.peeList.filter(pee => pee.projectEventId === projectEvent.id).length });
    }
    handleDeleteCancel = () => this.setState({ peToDelete: null, nbPeeToDelete: 0 });
    handleDeleteConfirmation = () => {
        const { peToDelete } = this.state;
        this.setState({ isDeleteLoading: true });
        EventsService.removeProjectEvent(peToDelete, this.props.project.id).then(() => {// Mise à jour des rows
            this.props.setProjectEvents(this.props.projectEvents.filter(pe => pe.id !== peToDelete));
            WebSocketUtil.removeProjectEvents(this.props.webSocketHubs, this.props.project.id, [peToDelete])
            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: prevState.data.rows.filter(row => row.id !== peToDelete && row.parentId !== peToDelete)
                },
                peToDelete: null, isDeleteLoading: false, nbPeeToDelete: 0
            }));
        });
    }

    handleModificationCancel = () => this.setState({ projectEventToEdit: this.props.projectEvents.find(pa => pa.id === this.state.projectEventToEdit.id), nbPeeToDelete: 0 });
    handleModificationConfirmation = () => {
        EventsService.updateProjectEvent(this.state.projectEventToEdit, this.props.project.id).then((projectEvent) => {
            const projectEvents = JSON.parse(JSON.stringify(this.props.projectEvents));
            const index = projectEvents.findIndex(pe => pe.id === projectEvent.id);
            if (index !== -1) {
                projectEvents[index] = projectEvent;
                this.props.setProjectEvents(projectEvents);
                WebSocketUtil.updateProjectEvents(this.props.webSocketHubs, this.props.project.id, [projectEvent]);
            }
            this.setState({ projectEventToEdit: null }); // TODO
        });
    }

    // Filtres
    areFiltersApplied = () => {
        if (!this.state.enableFilterRow) return false;
        let filtersApplied = false;
        for (const property in this.state.filters)
            if (this.state.filters[property] && property !== 'elements') filtersApplied = true;
        return filtersApplied;
    }

    toggleFilters = () => this.setState(prevState => ({ enableFilterRow: !prevState.enableFilterRow, search: UrlsUtil.adjustSearch(prevState.search, { enableFilterRow: prevState.enableFilterRow ? null : true }) }));
    clearFilters = () => this.setState(prevState => ({ filters: initialFilters, search: UrlsUtil.adjustSearch(prevState.search, { filters: null }) }));

    getFilteredRows = () => {
        const filters = this.state.filters;
        let rows = [...this.state.data.rows];
        let { startDate, endDate } = this.state;
        startDate = startOfDay(startDate);
        endDate = endOfDay(endDate);

        const isEvent = this.state.groupedBy === 'event', isRef = this.state.groupedBy === 'reference', isNone = this.state.groupedBy === 'none';
        const $ = (str) => FormattersUtil.getNormalizedString(str);
        const rowMatchesFilters = (row) => {
            const response = (filters[isRef ? 'reference' : 'label'] ? $(row[isRef ? 'reference' : 'label'])?.includes($(filters[isRef ? 'reference' : 'label'])) : true)
                && (!isNone ? (
                    (!isEvent && filters.place ? $(row.place)?.includes($(filters.place)) : true)
                    && (filters.links ? row.links === Number(filters.links) : true)
                    && (filters.date ? DatesUtil.getFormattedLocaleDateString(row.date).includes(filters.date) : true)
                ) : (
                    (filters.reference ? $(row.reference)?.includes($(filters.reference)) : true)
                    && (filters.place ? $(row.place)?.includes($(filters.place)) : true)
                    && (filters.comment ? $(row.comment)?.includes($(filters.comment)) : true)
                    && (filters.date ? DatesUtil.getFormattedLocaleDateString(row.date).includes(filters.date) : true)
                ))

            return response;
        };
        const parentMatchesFilters = (parentId) => {
            const parentRow = rows.filter(row => row.children).find(row => row.id === parentId);
            return rowMatchesFilters(parentRow);
        };

        rows = rows
            // On retire les lignes qui ne respectent pas la catégorie et les lignes enfants affichées si elles ne sont pas dans la bonne période
            .filter(row => {
                if (!row.parentId) return isEvent || filters.elements.includes(row.category) || row.categories?.some(category => filters.elements.includes(category));

                const parentRow = rows.find(r => r.id === row.parentId);
                return filters.elements.includes((isRef ? parentRow : row).category)
                    && DatesUtil.convertUTCDateToDate(row.date) >= startDate && DatesUtil.convertUTCDateToDate(row.date) <= endDate;
            })
            .map(row => {
                if (row.parentId || !row.children) return row;

                return { // On retire les rows qui ne sont pas dans la période sélectionnée des lignes parents
                    ...row, children: row.children
                        .filter(childRow => {
                            return (!isEvent || filters.elements.includes(childRow.category))
                                && DatesUtil.convertUTCDateToDate(childRow.date) >= startDate && DatesUtil.convertUTCDateToDate(childRow.date) <= endDate
                        })
                };
            })
            .filter(row => row.parentId || row.children?.length > 0
                || (
                    this.state.groupedBy === 'none' && (DatesUtil.convertUTCDateToDate(row.date) >= startDate && DatesUtil.convertUTCDateToDate(row.date) <= endDate)
                )
                || (
                    this.state.showZeros && this.state.groupedBy === 'projectEvent' && (
                        (DatesUtil.convertUTCDateToDate(row.date) >= startDate && DatesUtil.convertUTCDateToDate(row.date) <= endDate)
                        || (DatesUtil.convertUTCDateToDate(row.date) >= startDate && DatesUtil.convertUTCDateToDate(row.date) <= endDate)
                        || (DatesUtil.convertUTCDateToDate(row.date) < startDate && DatesUtil.convertUTCDateToDate(row.date) > endDate)
                    )
                ));

        return rows.filter(row => { // On retourne le résultat correspondant aux filtres
            const isParent = row.children || isNone ? true : false;
            return !this.state.enableFilterRow
                || (isParent && rowMatchesFilters(row))
                || (!isParent && parentMatchesFilters(row.parentId));
        }).flatMap(row => { // En ajoutant les subheaders si nécessaire
            if (!row.parentId && row.isExpanded && rows.find(r => r.parentId === row.id)) return [row, { isSubrow: true }]
            else return row;
        });
    }

    // Tri
    handleSort = (columnKey, direction) => this.setState({ sortColumn: columnKey, sortDirection: direction }, this.sortRows);
    getSortPropertyValue = (row, sortColumn) => {
        let { startDate, endDate } = this.state;
        startDate = startOfDay(startDate);
        endDate = endOfDay(endDate);

        const isRef = this.state.groupedBy === 'reference', isNone = this.state.groupedBy === 'none';
        const children = row.children?.filter(childRow => (
            DatesUtil.convertUTCDateToDate(childRow.date) >= startDate && DatesUtil.convertUTCDateToDate(childRow.date) <= endDate
        ));
        switch (sortColumn) {
            case 'reference': return Number(row.reference.split(' (')[0]);
            case 'links': return [...new Set(children.map(childRow => (isRef ? childRow.projectEvent.id : childRow.elementId)))].length;
            default: return row[sortColumn];
        }
    }
    sortRows = () => {
        const { sortColumn, sortDirection, initialOrder, data, groupedBy } = this.state;

        const sort = (rows) => {
            let sortedRows = [...rows];
            if (['reference', 'links'].includes(sortColumn)) {
                sortedRows = sortedRows.sort((a, b) => {
                    const aValue = this.getSortPropertyValue(a, sortColumn);
                    const bValue = this.getSortPropertyValue(b, sortColumn);
                    return bValue - aValue;
                });
            }
            else sortedRows = sortedRows.sort((a, b) => (a[sortColumn] || '').localeCompare(b[sortColumn] || ''));
            if (sortDirection === 'DESC') sortedRows.reverse();

            return sortedRows;
        };

        let rows = [...data.rows];
        let replaceIndex = 0;
        initialOrder.forEach(elementId => {
            let index = rows.findIndex(row => !row.parentId && `${row.id}` === `${elementId}`);
            if (index === -1) index = rows.findIndex(row => {
                const ids = elementId.split('|');
                return `${row.parentId}` === `${ids[0]}` && `${row.id}` === `${ids[1]}`;
            });
            if (index !== -1) {
                let temp = rows[replaceIndex];
                rows[replaceIndex] = rows[index];
                rows[index] = temp;
                replaceIndex++;
            }
        });

        if (sortDirection !== 'NONE') {
            rows = sort(rows.filter(row => !row.parentId));

            let childRows = {};
            data.rows.filter(row => row.parentId).forEach(row => {
                if (childRows[row.parentId]) childRows[row.parentId].push(row);
                else childRows[row.parentId] = [row];
            });
            for (const parentId in childRows) {
                let children = childRows[parentId];
                let index = rows.findIndex(row => row.id === children[0].parentId);
                if (index !== -1) {
                    children.forEach(row => {
                        rows.splice(index + 1, 0, row);
                        index++;
                    });
                }
            }
        }

        this.setState(prevState => ({
            data: {
                columns: prevState.data.columns,
                rows: rows
            }
        }));
    }

    handleKeyDown = (e) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
            if (this.selectedColumn && this.selectedRow) navigator.clipboard.writeText(this.selectedRow[this.selectedColumn.key] || '');
        }
    }

    handleResize = () => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            const { isOverflowing, data } = this.state;
            const gridElement = document.getElementsByClassName('rdg')[0];
            if (gridElement && data?.columns) {
                const width = data.columns.reduce((previousValue, column) => previousValue + column.width, 0);
                if (isOverflowing && gridElement.clientWidth >= width) this.setState({ isOverflowing: false });
                if (!isOverflowing && gridElement.clientWidth < width) this.setState({ isOverflowing: true });
            }
        }, 100);
    }

    expandAll = () => {
        let elementIds = this.state.data.rows
            .filter(row => !row.parentId && !row.isExpanded)
            .map(row => row.id);
        this.toggleSubRows(elementIds);
    }

    collapseAll = () => {
        let elementIds = this.state.data.rows
            .filter(row => !row.parentId && row.isExpanded)
            .map(row => row.id);
        this.toggleSubRows(elementIds);
    }

    toggleSubRows = (elementIds) => {
        let rows = [...this.state.data.rows];

        elementIds.forEach(elementId => {
            const rowIndex = rows.findIndex(r => r.id === elementId);
            const row = rows[rowIndex];
            if (row) {
                let { children } = row;

                if (children) {
                    rows[rowIndex] = { ...row, isExpanded: !row.isExpanded };
                    if (!row.isExpanded) rows.splice(rowIndex + 1, 0, ...children);
                    else rows.splice(rowIndex + 1, children.length);
                }
            }
        });

        this.setState(prevState => ({ data: { ...prevState.data, rows }, search: UrlsUtil.adjustSearch(prevState.search, { toggledRows: rows.filter(row => row.isExpanded).map(row => row.id).join(',') }) }), this.sortRows);
    }

    getPropertyValue = (property, value) => { // Map les valeurs affichées aux valeurs réelles
        switch (property) { // Pas besoin de mapping pour les formules
            default: return value;
        }
    }

    createFromRow = (_, { rowIdx }) => {
        const row = this.getFilteredRows()[rowIdx];
        this.props.createFormulaFromTemplate({
            formulaId: row.parentId || row.id,
            label: row.parentId ? row.label : '',
            value: row.value
        });
    }

    showElement = (elementId, category) => {
        const categories = {
            trees: { layerContainer: this.props.trees, category: 'Arbres' },
            greenspaces: { layerContainer: this.props.greenSpaces, category: 'Espaces verts' },
            furnitures: { layerContainer: this.props.furnitures, category: 'Mobilier urbain' }
        };

        const { layerContainer, category: elementCategory } = categories[category];
        const layer = layerContainer.getLayers().find(layer => layer.feature?.id === elementId);
        if (layer)
            this.props.setTableState({ ...this.state, elements: this.elements }).then(() => {
                this.props.showElement(elementCategory, layer.feature, 'EventTable');
            });
    }

    selectElementsToLink = (newProjectEvent) => {
        this.setState({ newProjectEvent }, () => {
            this.props.setEditedProperties(this.state).then(() => {
                this.props.manageEventElements(newProjectEvent);
            });
        });
    }
}

const mapStateToProps = (state) => {
    return {
        webSocketHubs: state.webSocketHubs,
        layer: state.layer,
        editedProperties: state.editedProperties,
        project: state.project,
        projectEvents: state.projectEvents,
        projectCollaborators: state.projectCollaborators,
        isDarkTheme: state.isDarkTheme,
        isOnline: state.isOnline,
        activeOrganization: state.activeOrganization,
        photosGalleries: state.photosGalleries,
        rights: state.rights,
        tableState: state.tableState,
        essences: state.essences,
        dominantCompositions: state.dominantCompositions,
        furnitureTypes: state.furnitureTypes
    };
};

const mapDispatchToProps = {
    setEditedProperties,
    unlockEditedProperties,
    setProjectEvents,
    setLayer,
    setTableState
};

export default withOrientationChange(connect(mapStateToProps, mapDispatchToProps)(EventTable));