import React, { Component } from 'react';
// Librairies
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';
import { addDays, isBefore, parseISO } from 'date-fns';
import { Button } from 'semantic-ui-react';
import { faTimes, faWarning } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SubscriptionLimitsBand extends Component {
    state = {
        isVisible: true
    };

    render() {
        const { isVisible } = this.state;
        const { activeOrganization } = this.props;
        const { subscription, subscriptionHistories } = activeOrganization || {};

        // Expiration de l'abonnement
        const today = new Date();
        const expirationDate = subscription?.expirationDate ? parseISO(subscription.expirationDate) : null;
        const isSubscriptionAlmostExpired = expirationDate && isBefore(expirationDate, addDays(today, 15));
        const isSubscriptionExpired = subscription?.shortName === 'Free' && !subscription?.price && subscriptionHistories?.length ? true : false;

        // Récupération des quotas depuis l'abonnement
        const projectsLimit = subscription?.nbProjects || 0;
        const treesLimit = subscription?.nbTrees || 0;
        const greenSpacesLimit = subscription?.nbGreenSpaces || 0;
        const furnituresLimit = subscription?.nbFurnitures || 0;

        // Récupération des quantités actuelles
        const nbProjects = activeOrganization?.nbProjects || 0;
        const nbTrees = activeOrganization?.nbTrees || 0;
        const nbGreenSpaces = activeOrganization?.nbGreenSpaces || 0;
        const nbFurnitures = activeOrganization?.nbFurnitures || 0;

        // Limites atteintes
        const isLimitReached = (count, limit) => limit !== -1 && count >= limit;
        const isProjectsLimitReached = isLimitReached(nbProjects, projectsLimit);
        const isTreesLimitReached = isLimitReached(nbTrees, treesLimit);
        const isGreenSpacesLimitReached = isLimitReached(nbGreenSpaces, greenSpacesLimit);
        const isFurnituresLimitReached = isLimitReached(nbFurnitures, furnituresLimit);

        // Limites presque atteintes (95%)
        const isAlmostLimitReached = (count, limit) => limit !== -1 && count >= limit * 0.95 && count < limit;
        const isTreesAlmostLimitReached = isAlmostLimitReached(nbTrees, treesLimit);
        const isGreenSpacesAlmostLimitReached = isAlmostLimitReached(nbGreenSpaces, greenSpacesLimit);
        const isFurnituresAlmostLimitReached = isAlmostLimitReached(nbFurnitures, furnituresLimit);

        return (
            <>
                {activeOrganization && (
                    isSubscriptionExpired ||
                    isSubscriptionAlmostExpired ||
                    isProjectsLimitReached ||
                    isTreesLimitReached ||
                    isTreesAlmostLimitReached ||
                    isGreenSpacesLimitReached ||
                    isGreenSpacesAlmostLimitReached ||
                    isFurnituresLimitReached ||
                    isFurnituresAlmostLimitReached
                ) &&
                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                            backgroundColor: 'var(--red-80)',
                            alignItems: 'center',
                            padding: isVisible ? '10px 0' : 0,
                            height: isVisible ? 'auto' : 0,
                            minHeight: isVisible ? '40px' : 0,
                            visibility: isVisible ? 'visible' : 'hidden',
                        }}
                    >
                        <h4 style={{ textAlign: 'center', margin: 0 }}>
                            <FontAwesomeIcon icon={faWarning} color='orange' style={{ marginRight: '5px' }} />
                            {isSubscriptionExpired
                                ? i18n.t('Votre abonnement est expiré et vous êtes repassés en "Gratuit". Veuillez nous contacter sur info@grality.com pour le renouveler.')
                                : isTreesLimitReached
                                    ? i18n.t("Vous avez atteint la limite de votre abonnement avec {{count}} arbres. Supprimez-en ou contactez-nous sur info@grality.com pour l'augmenter.", { count: nbTrees })
                                    : isSubscriptionAlmostExpired
                                        ? i18n.t('Votre abonnement arrive à expiration sous peu. Contactez-nous à info@grality.com pour le renouveler.')
                                        : isTreesAlmostLimitReached
                                            ? i18n.t("Vous avez atteint 95% de votre quota d'arbres. Contactez-nous pour l'augmenter.")
                                            : isGreenSpacesLimitReached
                                                ? i18n.t("Vous avez atteint la limite de votre abonnement avec {{count}} espaces verts. Supprimez-en ou contactez-nous sur info@grality.com pour l'augmenter.", { count: nbGreenSpaces })
                                                : isFurnituresLimitReached
                                                    ? i18n.t("Vous avez atteint la limite de votre abonnement avec {{count}} mobiliers. Supprimez-en ou contactez-nous sur info@grality.com pour l'augmenter.", { count: nbFurnitures })
                                                    : isProjectsLimitReached
                                                        ? i18n.t("Vous avez atteint la limite de votre abonnement avec {{count}} projets. Supprimez-en ou contactez-nous sur info@grality.com pour l'augmenter.", { count: nbProjects })
                                                        : isGreenSpacesAlmostLimitReached
                                                            ? i18n.t("Vous avez atteint 95% de votre quota d'espaces verts. Contactez-nous pour l'augmenter.")
                                                            : i18n.t("Vous avez atteint 95% de votre quota de mobiliers. Contactez-nous pour l'augmenter.")}
                        </h4>
                        <Button color='red' size='tiny' onClick={() => this.setState({ isVisible: false })} style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', padding: '6px 8px', marginLeft: '5px' }}>
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                    </div>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activeOrganization: state.activeOrganization,
    };
};

export default connect(mapStateToProps)(SubscriptionLimitsBand);