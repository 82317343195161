import React, { Component } from 'react';
// Composants
import InfoIcon from '../../../Utils/InfoIcon';
import DatePicker from '../../../Utils/DatePicker';
import { Form, Select, Grid, Input, Button } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { isMobileOnly } from 'react-device-detect';
import i18n from '../../../../locales/i18n';
// Redux
import { connect } from 'react-redux';
// Ressources
import { faListAlt } from '@fortawesome/pro-solid-svg-icons';
import PlantnetLogo from '../../../../resources/pngs/plantnet.png';
// Utils
import FormattersUtil from '../../../../utils/FormattersUtil';
import FieldsUtil from '../../../../utils/FieldsUtil';

class Step2 extends Component {
    state = {
        properties: {
            essence: {
                id: 0,
                vernacularName: null,
                gender: null,
                species: null,
                cultivar: null
            },
            essenceId: 0,
            ontogenicStageId: 0,
            plantingDate: null,
            age: 0,
            treePortId: 0
        },
        error: {
            hidden: true,
            messages: [],
            gender: false,
            coverTypeId: false
        }
    };

    render() {
        const requiredFields = this.props.requiredFields.trees;
        const ontogenicStages = this.props.ontogenicStages.map(x => { return { text: x.value, value: x.id } });

        const vernacularNames = FormattersUtil.sortByAlphabeticalOrder(
            [...new Set(this.props.essences
                .map(item => item.vernacularName))]
                .filter(x => x)
                .map(x => { return { text: x, value: x } })
        );

        const genders = FormattersUtil.sortByAlphabeticalOrder(
            [...new Set(this.props.essences
                .map(item => item.gender))]
                .map(x => { return { text: x, value: x } })
        );

        const species = FormattersUtil.sortByAlphabeticalOrder(
            [...new Set(this.props.essences
                .filter(x => this.props.properties.essence && (x.gender === this.props.properties.essence.gender && x.species))
                .map(x => x.species))]
                .map(x => { return { text: x, value: x } })
        );

        const cultivars = FormattersUtil.sortByAlphabeticalOrder(
            this.props.essences
                .filter(x => this.props.properties.essence && (x.gender === this.props.properties.essence.gender
                    && (x.species === this.props.properties.essence.species || !this.props.properties.essence.species)
                    && x.cultivar))
                .map(x => this.props.properties.essence.species ? x.cultivar : `${x.cultivar}${x.species ? ` (${x.species})` : ''}`)
                .map(x => { return { text: x, value: x } })
        );

        const treePorts = this.props.treePorts.map(x => { return { text: x.label, value: x.id } });

        const columnProps = {
            stretched: true,
            computer: 8,
            tablet: 8,
            mobile: 16,
            style: isMobileOnly ? { paddingTop: '4px', paddingBottom: '4px' } : null
        };

        return (
            <Grid id='cat--description' style={{ margin: '14px 0 0 0', border: 'solid 2px var(--orange-100)', borderRadius: '6px', order: FieldsUtil.getCategoryOrder(this.props.orderConfig, 'Arbre', 'Description') }}>
                {!isMobileOnly &&
                    <Grid.Row style={{ display: 'flex', padding: '14px 14px 0 14px', color: 'var(--orange-100)', fontWeight: 'bold', fontSize: '14pt' }}>
                        <FontAwesomeIcon icon={faListAlt} style={{ alignSelf: 'center', marginRight: '5px' }} />
                        <span className='no-themed'>{i18n.t("Description")}</span>
                        <Button
                            style={{ marginLeft: 'auto', padding: '3px 10px', display: 'flex', alignItems: 'center' }} color='green'
                            onClick={this.props.redirectToPlantnet}
                        >
                            <img src={PlantnetLogo} alt='urbasense' style={{ marginLeft: '2px', marginRight: 10, width: '20px', height: '20px', filter: 'brightness(0) invert(1)' }} />
                            {i18n.t("Identifier avec Plantnet")}
                        </Button>
                    </Grid.Row>}
                {isMobileOnly &&
                    <Button
                        style={{ margin: '8px auto', padding: '3px 10px', display: 'flex', alignItems: 'center' }} color='green'
                        onClick={this.props.redirectToPlantnet}
                    >
                        <img src={PlantnetLogo} alt='urbasense' style={{ marginLeft: '2px', marginRight: 10, width: '20px', height: '20px', filter: 'brightness(0) invert(1)' }} />
                        {i18n.t("Identifier avec Plantnet")}
                    </Button>}
                {requiredFields.vernacularName &&
                    <Grid.Column {...columnProps}>
                        <Form.Field
                            control={Select} label={<label>{i18n.t("Nom vernaculaire")} <InfoIcon content={i18n.t("Nom courant donné en français")} iconStyle={{ marginLeft: '3px' }} /> :</label>} placeholder={i18n.t("Sélectionnez un nom vernaculaire")}
                            name='vernacularName' options={vernacularNames} disabled={vernacularNames.length < 1 || this.props.properties.isEmpty}
                            value={!this.props.properties.isEmpty && this.props.properties.essence ? (this.props.properties.essence.vernacularName || '') : ''}
                            selectOnBlur={false} clearable search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                            onChange={this.props.handleEssenceChange}
                        />
                    </Grid.Column>}
                <Grid.Column {...columnProps}>
                    <Form.Field
                        control={Select} label={i18n.t("Genre") + ' : '} placeholder={i18n.t("Sélectionnez un genre")}
                        name='gender' options={genders} disabled={this.props.properties.isEmpty} className='gender'
                        value={!this.props.properties.isEmpty && this.props.properties.essence ? (this.props.properties.essence.gender || '') : ''}
                        selectOnBlur={false} clearable search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                        error={this.props.error.gender} onChange={this.props.handleEssenceChange}
                    />
                </Grid.Column>
                {requiredFields.species &&
                    <Grid.Column {...columnProps}>
                        <Form.Field
                            control={Select} label={i18n.t("Espèce") + ' : '} placeholder={i18n.t("Sélectionnez une espèce")}
                            name='species' options={species} disabled={species.length < 1 || this.props.properties.isEmpty}
                            value={!this.props.properties.isEmpty && this.props.properties.essence ? (this.props.properties.essence.species || '') : ''}
                            selectOnBlur={false} clearable search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                            onChange={this.props.handleEssenceChange}
                        />
                    </Grid.Column>}
                {requiredFields.cultivar &&
                    <Grid.Column {...columnProps}>
                        <Form.Field
                            control={Select} label={i18n.t("Cultivar") + ': '} placeholder={i18n.t("Sélectionnez un cultivar")}
                            name='cultivar' options={cultivars} disabled={cultivars.length < 1 || this.props.properties.isEmpty}
                            value={!this.props.properties.isEmpty && this.props.properties.essence ? (this.props.properties.essence.cultivar || '') : ''}
                            selectOnBlur={false} clearable search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                            onChange={this.props.handleEssenceChange}
                        />
                    </Grid.Column>}
                {requiredFields.treePort &&
                    <Grid.Column {...columnProps}>
                        <Form.Field
                            control={Select} placeholder={i18n.t("Sélectionnez un type de plantation")}
                            label={<label>{i18n.t("Port de l'arbre")} <InfoIcon content={i18n.t("Mode de conduite de l'arbre (contraintes sur la formes de l'arbre)")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                            name='treePortId' options={treePorts} disabled={this.props.properties.isEmpty || this.props.properties.isStump}
                            value={!this.props.properties.isEmpty && !this.props.properties.isStump ? (this.props.properties.treePortId || '') : ''}
                            selectOnBlur={false} clearable
                            onChange={this.props.handleChange}
                        />
                    </Grid.Column>}
                {requiredFields.plantingDate &&
                    <Grid.Column {...columnProps}>
                        <DatePicker
                            label={`${i18n.t("Date de plantation")} :`} name='plantingDate' value={!this.props.properties.isEmpty ? (this.props.properties.plantingDate || '') : ''}
                            disabled={this.props.properties.isEmpty} error={this.props.error.age} onChange={this.props.handleChange} style={{ fontWeight: 'bold' }}
                        />
                    </Grid.Column>}
                {requiredFields.age &&
                    <Grid.Column {...columnProps}>
                        <Form.Field
                            control={Input} type='number' step='1' placeholder='Ex: 12'
                            label={<label>{i18n.t("Âge")} ({i18n.t("Années").toLowerCase()}) <InfoIcon content={i18n.t("L'âge est mis à jour automatiquement")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                            name='age' disabled={this.props.properties.isEmpty}
                            value={!this.props.properties.isEmpty ? (this.props.properties.age || '') : ''}
                            error={this.props.error.age} onChange={this.props.handleChange}
                        />
                    </Grid.Column>}
                {requiredFields.ontogenicStage &&
                    <Grid.Column {...columnProps}>
                        <Form.Field
                            control={Select} placeholder={i18n.t("Sélectionnez un stade")}
                            label={<label>{i18n.t("Stade ontogénique")} <InfoIcon content={i18n.t("Stade de développement de l'arbre (selon Rimbault)")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                            name='ontogenicStageId' options={ontogenicStages}
                            value={!this.props.properties.isEmpty && !this.props.properties.isStump
                                ? (this.props.properties.ontogenicStageId || '')
                                : ''}
                            disabled={this.props.properties.isEmpty || this.props.properties.isStump}
                            selectOnBlur={false} clearable
                            onChange={this.props.handleChange}
                        />
                    </Grid.Column>}
                {this.props.renderFields(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Description'))}
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        essences: state.essences,
        treePorts: state.treePorts,
        ontogenicStages: state.ontogenicStages,
        defaultFieldCategories: state.defaultFieldCategories
    };
};

export default connect(mapStateToProps)(Step2);