import React, { Component } from 'react';
// Composants
import { Select } from 'semantic-ui-react';
// Librairies
import i18n from '../../locales/i18n';
import { connect } from 'react-redux';

class ModalTitle extends Component {
    constructor(props) {
        super(props);
        const feature = props.layer?.[0]?.feature;
        let layers, showCustomReference;
        switch (feature.properties.category) {
            case 'Arbre':
                layers = props.treesLayer.getLayers();
                showCustomReference = props.requiredFields.trees.customReference;
                break;
            case 'Espace vert':
                layers = props.greenSpacesLayer.getLayers();
                showCustomReference = props.requiredFields.greenSpaces.customReference;
                break;
            case 'Mobilier':
                layers = props.furnituresLayer.getLayers();
                showCustomReference = props.requiredFields.furnitures.customReference;
                break;
            case 'Repère':
                layers = props.markersLayer.getLayers();
                showCustomReference = true;
                break;
            default: break;
        }

        const projectLabels = props.project.type !== 'project' &&
            props.project.projectLabels.reduce((prevValue, projectLabel) => ({ ...prevValue, [projectLabel.id]: projectLabel.label }), {});

        const prOptions = layers
            .map(layer => {
                const projectLabel = projectLabels ? ` (${projectLabels[layer.feature.projectId]})` : '';
                return {
                    text: `${layer.feature.properties.projectReference}${projectLabel}`,
                    reference: layer.feature.properties.projectReference,
                    value: layer.feature.id,
                    projectId: layer.feature.projectId
                };
            })
            .filter(option => option.reference)
            .sort((a, b) => {
                if (a.reference === b.reference) return a.projectId - b.projectId;
                else return a.reference > b.reference ? 1 : -1;
            });

        const crOptions = layers
            .map(layer => {
                const projectLabel = projectLabels ? ` (${projectLabels[layer.feature.projectId]})` : '';
                return {
                    text: `${layer.feature.properties.customReference}${projectLabel}`,
                    reference: layer.feature.properties.customReference,
                    value: layer.feature.id,
                    projectId: layer.feature.projectId
                };
            })
            .filter(option => option.reference)
            .sort((a, b) => {
                return (a.reference < b.reference) ? -1 : (a.reference > b.reference) ? 1 : 0
            });

        this.state = { prOptions, crOptions, showCustomReference };
    }

    render = () => {
        const { layer, publicFields } = this.props;
        const { prOptions, crOptions, showCustomReference } = this.state;

        const feature = layer?.[0]?.feature;
        return feature?.properties?.projectReference ? (
            <div id='gUT35zHR' style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                {publicFields.main.references && prOptions && crOptions &&
                    <h3 style={{ textAlign: 'center', margin: 0 }}>
                        {i18n.t("Référence")} : {this.renderPRSelect(feature)} <span>{showCustomReference && <> | {this.renderCRSelect(feature)}</>}</span>
                    </h3>}
            </div>
        ) : null;
    }

    renderPRSelect = (feature) => {
        const { prOptions } = this.state;
        return (
            <Select
                compact search style={{ fontSize: '14px', paddingLeft: '9px', minWidth: '200px' }}
                selectOnBlur={false} selectOnNavigation={false}
                options={prOptions} value={feature.id}
                onChange={(_, { value }) => {
                    const option = prOptions.find(o => o.value === value);
                    const projectId = option.projectId;
                    if (feature.properties.projectReference < option.reference || (feature.properties.projectReference === option.reference && this.props.layer[0].feature.projectId < projectId))
                        this.props.nextElement(option.reference, projectId);
                    else if (feature.properties.projectReference > option.reference || (feature.properties.projectReference === option.reference && this.props.layer[0].feature.projectId > projectId))
                        this.props.previousElement(option.reference, projectId);
                }}
                noResultsMessage={i18n.t("Aucun résultat trouvé")}
            />
        );
    }

    renderCRSelect = (feature) => {
        const { crOptions } = this.state;
        return (
            <Select
                compact search style={{ fontSize: '14px', paddingLeft: '9px', minWidth: '180px' }}
                selectOnBlur={false} selectOnNavigation={false} placeholder={i18n.t("Aucune")}
                options={crOptions} value={feature.id}
                onChange={(_, { value }) => {
                    const option = crOptions.find(o => o.value === value);
                    this.props.goToElement(option.reference, option.projectId);
                }}
                noResultsMessage={i18n.t("Aucun résultat trouvé")}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    layer: state.layer,
    project: state.project
});

export default connect(mapStateToProps)(ModalTitle);