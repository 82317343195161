import React, { Component } from 'react';
import { renderToString } from 'react-dom/server';
// Librairies
import 'heatmap.js/build/heatmap';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
import { Helmet } from 'react-helmet';
import { v4 as uuidv4 } from 'uuid';
import { isMobileOnly, isMobile, withOrientationChange, isTablet } from 'react-device-detect';
import {
    difference, booleanIntersects, lineString, polygon, circle, point, transformRotate, transformScale,
    nearestPointOnLine, booleanPointInPolygon, bearing, transformTranslate, distance, lineIntersect, featureCollection, centerOfMass,
    rhumbBearing, rhumbDistance, bbox, multiPolygon, area,
} from '@turf/turf';
import i18n from '../../locales/i18n';
import screenfull from 'screenfull';
import tinycolor from 'tinycolor2';
import { HotKeys } from 'react-hotkeys';
/*     Leaflet     */
import L from 'leaflet';
import 'leaflet.markercluster';
import '@geoman-io/leaflet-geoman-free';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet-measure/dist/leaflet-measure.fr';
import 'leaflet.locatecontrol/dist/L.Control.Locate.min';
import 'leaflet-lasso';
import 'leaflet-pegman';
import 'leaflet-bookmarks';
import HeatmapOverlay from 'heatmap.js/plugins/leaflet-heatmap/leaflet-heatmap';
import 'leaflet-distortableimage-clone';
import 'leaflet-distortableimage-clone/dist/leaflet.distortableimage.css';
import 'Leaflet.MultiOptionsPolyline';
import 'leaflet-tilelayer-wmts/src/leaflet-tilelayer-wmts';
/*     Screenshot     */
import download from 'downloadjs/download';
import jsPDF from 'jspdf';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TreeDetail from '../Details/TreeDetail';
import GreenSpaceDetail from '../Details/GreenSpaceDetail';
import FurnitureDetail from '../Details/FurnitureDetail';
import MarkerDetail from '../Details/MarkerDetail';
import TreeHistory from '../Histories/TreeHistory';
import GreenSpaceHistory from '../Histories/GreenSpaceHistory';
import FurnitureHistory from '../Histories/FurnitureHistory';
import ProjectHistory from '../Histories/ProjectHistory';
import ActionHistory from '../Histories/ActionHistory';
import TreeTable from '../Tables/TreeTable';
import GreenSpaceTable from '../Tables/GreenSpaceTable';
import FurnitureTable from '../Tables/FurnitureTable';
import ProjectDetail from '../Details/ProjectDetail';
import ProjectDetailComparison from '../Details/ProjectDetailComparison';
import PhotosGallery from '../Galleries/PhotosGallery';
import ElementFilesGallery from '../Galleries/ElementFilesGallery';
import ProjectFilesGallery from '../Galleries/ProjectFilesGallery';
import Woops from '../Utils/Woops';
import GeosearchInput from '../Utils/GeosearchInput';
import MapContextMenu from '../Utils/MapContextMenu';
import ElementContextMenu from '../Utils/ElementContextMenu';
import { contextMenu } from 'react-contexify';
import Legend from '../Utils/Legend';
import BaseLayersMenu from '../Utils/BaseLayersMenu';
import ActionTable from '../Tables/ActionTable';
import EventTable from '../Tables/EventTable';
import ConnectedUsers from '../Lists/ConnectedUsersList';
import TimelineShortcuts from '../Utils/TimelineShortcuts';
import ExitFormPopup from '../Utils/ExitFormPopup';
import ScreenshotEditor from '../Utils/ScreenshotEditor';
import ProjectWebSocket from '../Utils/ProjectWebSocket';
import AIResultMap from '../Utils/AIResultMap';
import TreeAnimation from '../../resources/TreeAnimation';
import ModalTitle from '../Utils/ModalTitle';
/*     Navbars     */
import ModalNavbar from '../Navbars/ModalNavbar';
import ChartNavbar from '../Navbars/ChartNavbar';
import Toolbar from '../Navbars/Toolbar';
/*     Forms     */
import ActionForm from '../Forms/Actions/ActionForm';
import EventForm from '../Forms/Events/EventForm';
import TreeForm from '../Forms/Elements/TreeForm/TreeForm';
import GreenSpaceForm from '../Forms/Elements/GreenSpaceForm/GreenSpaceForm';
import FurnitureForm from '../Forms/Elements/FurnitureForm/FurnitureForm';
import MarkerForm from '../Forms/Elements/MarkerForm';
import StationForm from '../Forms/Elements/StationForm';
import FieldCompletionForm from '../Forms/Elements/FieldCompletionForm';
import BackgroundImageAdditionForm from '../Forms/Elements/BackgroundImageAdditionForm';
import BackgroundImageManagementForm from '../Forms/Elements/BackgroundImageManagementForm';
import OffsetForm from '../Forms/Elements/Shapes/OffsetForm';
import LineForm from '../Forms/Elements/Shapes/LineForm';
import RectangleForm from '../Forms/Elements/Shapes/RectangleForm';
import CircleForm from '../Forms/Elements/Shapes/CircleForm';
import RemoveForm from '../Forms/Elements/RemoveForm';
import CutForm from '../Forms/Elements/CutForm';
import DuplicateForm from '../Forms/Elements/DuplicateForm';
import FilterForm from '../Forms/Tools/FilterForm';
import FolderCreationForm from '../Forms/Projects/FolderCreationForm';
import ProjectForm from '../Forms/Projects/ProjectForm/ProjectForm';
import ModifySurroundingsForm from '../Forms/Projects/ModifySurroundingsForm';
import ProjectShrinkageForm from '../Forms/Projects/ProjectShrinkageForm';
import CollaboratorsForm from '../Forms/Projects/CollaboratorsForm';
import RoleList from '../Lists/RoleList';
import LinkedElementList from '../Lists/LinkedElementList';
import ImportForm from '../Forms/Projects/ImportForm/ImportForm';
import ExportForm from '../Forms/Projects/ExportForm';
import SelectElementTypeForm from '../Forms/Elements/SelectElementTypeForm';
import ExportPdfForm from '../Forms/Elements/ExportPdfForm';
import ScreenshotForm from '../Forms/Tools/ScreenshotForm';
import TimelineForm from '../Forms/Tools/TimelineForm';
import MergeForm from '../Forms/Elements/MergeForm';
import ThematicMapForm from '../Forms/Tools/ThematicMapForm';
import LinkingElementsForm from '../Forms/Elements/LinkingElementsForm';
import WmsServiceForm from '../Forms/Tools/WmsServiceForm';
import PropertiesModificationForm from '../Forms/Elements/PropertiesModificationForm';
import ScanForm from '../Forms/Tools/ScanForm';
import CustomScanForm from '../Forms/Tools/CustomScanForm';
import ShareForm from '../Forms/Tools/ShareForm';
import SelectedElementsForm from '../Forms/Tools/SelectedElementsForm';
import MeasureForm from '../Forms/Elements/Shapes/MeasureForm';
import ReferencesForm from '../Forms/Elements/ReferencesForm';
/*     Charts     */
import GendersChart from '../Charts/GendersChart';
import HealthReviewsChart from '../Charts/HealthReviewsChart';
import VigorsChart from '../Charts/VigorsChart';
import OntogenicsChart from '../Charts/OntogenicsChart';
import ActionsChart from '../Charts/ActionsChart';
import CutDownsChart from '../Charts/CutDownsChart';
import CustomCharts from '../Charts/CustomCharts';
import EvolutionCharts from '../Histories/EvolutionCharts';
/*     Lists     */
import ProjectList from '../Lists/ProjectList';
// Redux
import { connect } from 'react-redux';
import { setProject, setProjects, setRights, setProjectActions, setProjectCustomFields, setUserProjects, setProjectCollaborators, setProjectInvitations, setViewProjectAsData, setProjectEvents } from '../../actionCreators/projectsActions';
import { setCurrentAction, setRequest, setButtonState, setRedirectURL } from '../../actionCreators/appActions'
import { setElementHistory, setActionHistory, setLayer, setPhotosGalleries, setFilesGalleries, setLockedElements, setFormulasResults } from '../../actionCreators/elementsActions';
import { setEditedProperties, setFilterFormState, setTableState, setProjectListState, setCurrentFolderState, setProjectShortcut } from '../../actionCreators/componentsActions';
// Ressources
import GDK from '../../resources/gdk'; // Google Dark Theme
import googleRoads from '../../resources/google-roads';
import googleRoadsDark from '../../resources/google-roads-dark';
import defaultMarkerIcon from '../../resources/icons/default_marker.png';
import { faBan, faBookmark, faCircle1, faCircleA, faShareFromSquare } from '@fortawesome/pro-solid-svg-icons';
// Semantic UI
import { Dimmer, Loader, Segment, Modal, TransitionablePortal, Button, Select, Progress, Label } from 'semantic-ui-react'
// Services
import GeoJsonUtil from '../../utils/GeoJsonUtil';
import TreesService from '../../services/TreesService';
import GreenSpacesService from '../../services/GreenSpacesService';
import FurnituresService from '../../services/FurnituresService';
import MarkersService from '../../services/MarkersService';
import StationsService from '../../services/StationsService';
import ActionsService from '../../services/ActionsService';
import LocationsService from '../../services/LocationsService';
import ProjectsService from '../../services/ProjectsService';
import BookmarksService from '../../services/BookmarksService';
import BackgroundImagesService from '../../services/BackgroundImagesService';
import FileInfosService from '../../services/FileInfosService';
import CustomFieldsService from '../../services/CustomFieldsService';
import AIService from '../../services/AIService';
import WmsService from '../../services/WmsService';
import EventsService from '../../services/EventsService';
// Styles
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css';
import 'leaflet-geosearch/assets/css/leaflet.css';
import 'leaflet-measure/dist/leaflet-measure.css';
import 'leaflet-pegman/leaflet-pegman.css';
import 'leaflet-bookmarks/dist/leaflet.bookmarks.css';
import 'react-contexify/ReactContexify.css';
// Utils
import { showLoadingToast, showToast } from '../../utils/ToastsUtil';
import StylesUtil from '../../utils/StylesUtil';
import UpdatesUtil from '../../utils/UpdatesUtil';
import TooltipsUtil from '../../utils/TooltipsUtil';
import DatesUtil from '../../utils/DatesUtil';
import FormattersUtil from '../../utils/FormattersUtil';
import MapsUtil from '../../utils/MapsUtil';
import GeometriesUtil from '../../utils/GeometriesUtil';
import GreenSpacesUtil from '../../utils/GreenSpacesUtil';
import ProjectsUtil from '../../utils/ProjectsUtil';
import { exportScaledMap, exportScreenshot, exportStatistics } from '../../utils/ScreenshotsUtil';
import TasksUtil from '../../utils/TasksUtil';
import AppSettings from '../../AppSettings';
import ThemesUtil from '../../utils/ThemesUtil';
import FileInfosUtil from '../../utils/FileInfosUtil';
import ShortcutsUtil from '../../utils/ShortcutsUtil';
import WebSocketUtil from '../../utils/WebSocketUtil';
import ActionsUtil from '../../utils/ActionsUtil';
import OfflineUtil from '../../utils/OfflineUtil';
import RightsUtil from '../../utils/RightsUtil';
import TreesUtil from '../../utils/TreesUtil';
import UrlsUtil from '../../utils/UrlsUtil';

class ProjectMap extends Component {
    state = {
        subscription: null,
        loader: {
            active: true,
            progress: {
                current: 0,
                end: 8
            },
            message: i18n.t("Chargement des ressources nécessaires...")
        },
        modal: { // Composant affichant les différents formulaires
            visible: false,
            title: ''
        },
        modalContentType: '', // Type de contenu à afficher dans le modal
        isFullScreen: ['true', null].includes(localStorage.getItem('isFullScreen')) ? true : false,
        currentTools: null,
        removingWithShortcut: false,
        mainFilters: null,
        projectToEdit: null,
        projectToDuplicate: null,
        highlightedElements: null,
        highlightedElementsCategory: null,
        closingTable: false,
        differentProperties: null,
        angle: 0,
        placementLine: null,
        radius: 0,
        selectedElements: [],
        elementsOutsideNewBounds: null,
        isDragFinished: false,
        showGeosearch: false,
        backgroundImageToEdit: null,
        overlays: [],
        thematicMapToEdit: null,
        wmsServiceToEdit: null,
        projectToCompareWith: null,
        areAdditionalLayersLoaded: false,
        isTimelineVisible: false,
        currentYear: new Date().getUTCFullYear(),
        layersToLink: [],
        layersToUnlink: [],
        isBuffer: false,
        showExitForm: false,
        imageToEdit: null,
        scan: {}, // bounds: null, latLngs: null, previousTrees: null, trees: null, wmsService: null, wmsLayers: null
        actionToShow: null,
        elementsToModify: null,
        drawingSegments: null,
        rectangleDimensions: {},
        references: {
            trees: null,
            greenSpaces: null,
            furnitures: null,
            markers: null
        }
    };

    render() {
        const { projectActions, isToolbarExpanded, exitFormWithChanges } = this.props;
        const {
            loader, modal, isFullScreen, modalContentType, projectToEdit, projectToDuplicate, differentProperties, currentElementTools,
            removingWithShortcut, isDragFinished, showGeosearch, elementsOutsideNewBounds, baseLayers, overlays, thematicMapToEdit, wmsServiceToEdit,
            projectToCompareWith, areAdditionalLayersLoaded, isTimelineVisible, currentYear, layersToLink, layersToUnlink,
            selectedElements, radius, isBuffer, showExitForm, highlightedElements, references,
            imageToEdit, scan, actionToShow, elementsToModify, contextMenuElement, drawingSegments, rectangleDimensions
        } = this.state;
        const isMobileLandscape = isMobileOnly && this.props.isLandscape;
        const { segmentStyle, dimmerStyle, modalMobileStyle } = StylesUtil.getMapStyles();
        const fullScreenDeactivable = !['ProjectList', 'ProjectCreationForm', 'ProjectDuplicationForm', 'ProjectModificationForm', 'CollaboratorsForm', 'RoleList', 'ImportForm', 'ExportForm', 'ProjectFilesGallery', 'TreeTable', 'GreenSpaceTable', 'FurnitureTable', 'ActionTable'].includes(modalContentType);
        const backgroundImageManagementFormId = uuidv4();

        let removeFormLayers = this.state.highlightedElements ? [...this.state.highlightedElements, ...this.state.selectedElements] : this.state.selectedElements;
        if (this.props.currentAction === 'removing') {
            const categories = { 'treeTools': 'Arbre', 'greenSpaceTools': 'Espace vert', 'furnitureTools': 'Mobilier', 'stationTools': 'Station', 'markerTools': 'Repère' };
            const name = this.toolbarRef.current.state.selectedButtons[0].name;
            const category = categories[name] || 'BackgroundImage';
            removeFormLayers = removeFormLayers.filter(l => l.feature.properties.category === category);
        }

        const layerContainers = {
            treesLayer: this.treesLayer,
            treesLayerNotClustered: this.treesLayerNotClustered,
            greenSpacesLayer: this.greenSpacesLayer,
            furnituresLayer: this.furnituresLayer,
            furnituresLayerNotClustered: this.furnituresLayerNotClustered,
            markersLayer: this.markersLayer,
            stationsLayer: this.stationsLayer,
            backgroundImagesLayer: this.backgroundImagesLayer,
            referencesLayer: this.referencesLayer
        };

        return (
            <>
                <Helmet>
                    {isMobileLandscape && <link rel='stylesheet' type='text/css' href='/mobile-landscape.css' />}
                    {(isFullScreen || !fullScreenDeactivable) && !isMobile && <link rel='stylesheet' type='text/css' href='/fullscreen-modal.css' />}
                    {['TreeTable', 'GreenSpaceTable', 'FurnitureTable', 'TreeHistory', 'GreenSpaceHistory', 'FurnitureHistory'].includes(modalContentType) && <link rel='stylesheet' type='text/css' href='/rdg-override.css' />}
                </Helmet>
                <Segment style={segmentStyle} id='map-segment'>
                    {scan.bounds && !modalContentType && !isMobileOnly &&
                        <Dimmer active={true} style={dimmerStyle} id='ai-dimmer'>
                            <TreeAnimation />
                            <span className='no-themed' style={{ fontWeight: 'bold', fontSize: '14pt' }}>{i18n.t("Notre intelligence artificielle analyse la zone...")}</span>
                        </Dimmer>}
                    {loader.progress ?
                        <Dimmer active={loader.active} style={dimmerStyle}>
                            <Loader content={
                                <Progress
                                    value={loader.progress.current} total={loader.progress.end}
                                    size='tiny' style={isMobileOnly ? { width: '200px' } : { width: '400px' }}
                                >
                                    <div style={{ color: 'white' }}>{loader.message}</div>
                                </Progress>
                            } />
                        </Dimmer>
                        :
                        <>
                            <Dimmer active={loader.active && loader.location === 'map'} style={dimmerStyle}>
                                <Loader content={loader.message} />
                            </Dimmer>
                            <TransitionablePortal open={modal.visible} transition={{ animation: 'fade up', duration: exitFormWithChanges ? 0 : 300 }} closeOnDocumentClick={false}>
                                <Modal
                                    id='mobile-modal' size={'large'} open
                                    style={isMobile || isFullScreen || !fullScreenDeactivable ? modalMobileStyle : null}
                                    closeOnDocumentClick={false} closeOnEscape={this.props.currentAction !== 'modifyingProjectSurroundings' && (modalContentType !== 'MergeForm' || this.props.currentAction !== 'cutting')}
                                    closeIcon={false} closeOnDimmerClick={this.props.currentAction !== 'modifyingProjectSurroundings'}
                                    onMount={() => { if (document.activeElement.className.includes('leaflet')) document.activeElement.blur(); }}
                                    onClose={() => {
                                        if (exitFormWithChanges) {
                                            this.setState({ showExitForm: true });
                                            return false;
                                        }

                                        if (this.state.modalContentType === 'ProjectCreationForm') this.props.setEditedProperties(null);
                                        if (this.props.projectShortcut) this.props.setProjectShortcut(null); this.hideForm(false);
                                        if (projectToEdit || projectToDuplicate) this.setState({ projectToEdit: null, projectToDuplicate: null });
                                        this.props.setUserProjects(null);
                                        this.props.setProjectInvitations(null);
                                    }}
                                >
                                    <Modal.Header style={{ display: 'flex', padding: '7px 2px 7px 11px' }}>
                                        {['ProjectModificationForm', 'ProjectDuplicationForm', 'ProjectCreationForm', 'CollaboratorsForm', 'RoleList', 'ImportForm', 'ExportForm', 'ProjectFilesGallery'].includes(modalContentType)
                                            && !this.props.projectShortcut &&
                                            <Button
                                                icon='arrow left' color='blue' size='small' id='SMRKL17r' title='Retour'
                                                style={{ marginRight: '10px' }} onClick={this.handleBackButtonClick}
                                            />}
                                        <div style={{ alignSelf: 'center' }}>
                                            {(this.props.currentAction !== 'viewingModal' || isMobileOnly) && modal.title}
                                            {(projectToEdit || projectToDuplicate || modalContentType === 'ProjectDetail') && !isMobileOnly &&
                                                <div style={{ display: 'inline-block', opacity: 0.6, marginLeft: '5px' }}>/ {(projectToEdit || projectToDuplicate || this.props.project)?.label}</div>}
                                            {modalContentType === 'AIResultMap' && <Label id='beta-label'>{i18n.t("Beta")}</Label>}
                                        </div>
                                        {!isMobileOnly && this.props.layer?.[0]?.feature?.properties && [
                                            'TreeDetail', 'TreeForm', 'TreeHistory', 'GreenSpaceDetail', 'GreenSpaceForm', 'GreenSpaceHistory', 'EvolutionCharts',
                                            'FurnitureDetail', 'FurnitureForm', 'FurnitureHistory', 'MarkerDetail', 'MarkerForm', 'ActionHistory', 'PhotosGallery', 'ElementFilesGallery', 'LinkedElementList', 'ActionForm'
                                        ].includes(modalContentType) &&
                                            <ModalTitle
                                                publicFields={this.publicFields} requiredFields={this.requiredFields}
                                                treesLayer={this.treesLayerNotClustered} greenSpacesLayer={this.greenSpacesLayer} furnituresLayer={this.furnituresLayerNotClustered} markersLayer={this.markersLayer}
                                                goToElement={this.goToElement} previousElement={this.previousElement} nextElement={this.nextElement}
                                            />}
                                        <div style={{ display: 'flex', marginLeft: 'auto', zIndex: 1 }}>
                                            {(isTimelineVisible && !['ProjectList', 'ProjectModificationForm', 'ProjectFilesGallery', 'CollaboratorsForm', 'RoleList', 'ImportForm', 'ExportForm'].includes(modalContentType)) &&
                                                <TimelineShortcuts currentYear={currentYear} setCurrentYear={(year) => this.setState({ currentYear: year })} />}
                                            {!isMobile && fullScreenDeactivable &&
                                                <Button
                                                    size='small' icon={isFullScreen ? 'compress' : 'expand'}
                                                    onClick={() => {
                                                        this.setState(prevState => ({ isFullScreen: !prevState.isFullScreen }),
                                                            () => localStorage.setItem('isFullScreen', this.state.isFullScreen));
                                                    }}
                                                />}
                                            {this.props.currentAction !== 'modifyingProjectSurroundings' && (modalContentType !== 'MergeForm' || this.props.currentAction !== 'cutting') &&
                                                <Button
                                                    icon='close' color='blue' size='small' id='kmP9ozeA'
                                                    onClick={() => {
                                                        if (exitFormWithChanges) {
                                                            this.setState({ showExitForm: true });
                                                            return;
                                                        }

                                                        if (this.state.modalContentType === 'CustomScanForm') {
                                                            this.setState({ modal: { visible: false, title: '' }, modalContentType: 'ScanForm' }); return;
                                                        } else if (this.props.currentAction === 'drawingActionsFilterSurroundings') {
                                                            this.props.setCurrentAction(null);
                                                            this.changeModalContentType('ActionTable', i18n.t("Actions"));
                                                            return;
                                                        }

                                                        if (projectToEdit || projectToDuplicate) this.setState({ projectToEdit: null, projectToDuplicate: null });
                                                        if (this.props.projectShortcut) this.props.setProjectShortcut(null);
                                                        this.hideForm(false);
                                                    }}
                                                />}
                                        </div>
                                    </Modal.Header >
                                    {this.props.currentAction === 'viewingModal' && this.props.layer &&
                                        <ModalNavbar
                                            logged={this.props.logged} changeModalContentType={this.changeModalContentType} hideForm={this.hideForm}
                                            previousElement={this.previousElement} nextElement={this.nextElement} showElement={this.showElement}
                                            showExportPdfForm={this.showExportPdfForm} modalContentType={modalContentType}
                                        />}
                                    {(['ProjectDetail', 'EvolutionCharts', 'CustomCharts'].includes(modalContentType) || modalContentType?.endsWith('Chart')) && !this.props.layer &&
                                        <ChartNavbar changeModalContentType={this.changeModalContentType} modalContentType={modalContentType} />}
                                    <Dimmer active={loader.active && loader.location === 'modal'} style={dimmerStyle}>
                                        <Loader content={loader.message} />
                                    </Dimmer>
                                    <Modal.Content
                                        id='modal-content' className='scrollable-container'
                                        style={isMobile ? { height: '0px', padding: 0 } : (isFullScreen || !fullScreenDeactivable ? { height: '0px' } : null)}
                                    >
                                        <Modal.Description style={{ height: '100%' }}>
                                            {modalContentType === 'TreeDetail' && <TreeDetail showElement={this.showElement} timelineYears={this.timelineYears} currentYear={currentYear} changeModalContentType={this.changeModalContentType} layers={[this.stationsLayer]} />}
                                            {modalContentType === 'GreenSpaceDetail' && <GreenSpaceDetail showElement={this.showElement} changeModalContentType={this.changeModalContentType} layers={[this.stationsLayer]} />}
                                            {modalContentType === 'FurnitureDetail' && <FurnitureDetail showElement={this.showElement} changeModalContentType={this.changeModalContentType} layers={[this.stationsLayer]} />}
                                            {modalContentType === 'MarkerDetail' && <MarkerDetail showElement={this.showElement} changeModalContentType={this.changeModalContentType} layers={[this.stationsLayer]} />}
                                            {modalContentType === 'TreeForm' &&
                                                <TreeForm
                                                    hideForm={this.hideForm} addMarker={this.addMarker} showRemoveForm={this.showRemoveForm}
                                                    logged={this.props.logged} updateLegend={this.updateLegend} updateHeatmaps={this.updateHeatmaps}
                                                    treesLayer={this.treesLayer} fieldList={this.fieldList} references={references} referencesLayer={this.referencesLayer}
                                                    isTabletLandscape={isTablet && this.props.isLandscape} isMobileLandscape={isMobileLandscape}
                                                />}
                                            {modalContentType === 'GreenSpaceForm' &&
                                                <GreenSpaceForm
                                                    hideForm={this.hideForm} addGreenSpace={this.addGreenSpace} showRemoveForm={this.showRemoveForm}
                                                    logged={this.props.logged} updateLegend={this.updateLegend}
                                                    greenSpacesLayer={this.greenSpacesLayer} fieldList={this.fieldList} references={references} referencesLayer={this.referencesLayer}
                                                    isTabletLandscape={isTablet && this.props.isLandscape} isMobileLandscape={isMobileLandscape}
                                                />}
                                            {modalContentType === 'FurnitureForm' &&
                                                <FurnitureForm
                                                    hideForm={this.hideForm} addMarker={this.addMarker} showRemoveForm={this.showRemoveForm}
                                                    logged={this.props.logged} updateLegend={this.updateLegend}
                                                    furnituresLayer={this.furnituresLayer} fieldList={this.fieldList} references={references} referencesLayer={this.referencesLayer}
                                                    isTabletLandscape={isTablet && this.props.isLandscape} isMobileLandscape={isMobileLandscape}
                                                />}
                                            {modalContentType === 'MarkerForm' &&
                                                <MarkerForm
                                                    hideForm={this.hideForm} addMarker={this.addMarker} showRemoveForm={this.showRemoveForm}
                                                    logged={this.props.logged} updateLegend={this.updateLegend}
                                                    markersLayer={this.markersLayer} fieldList={this.fieldList} references={references} referencesLayer={this.referencesLayer}
                                                    isTabletLandscape={isTablet && this.props.isLandscape} isMobileLandscape={isMobileLandscape}
                                                />}
                                            {modalContentType === 'FieldCompletionForm' &&
                                                <FieldCompletionForm treesLayer={this.treesLayer} fieldList={this.fieldList} updateLegend={this.updateLegend} />}
                                            {modalContentType === 'PropertiesModificationForm' &&
                                                <PropertiesModificationForm
                                                    hideForm={this.hideForm} updateLegend={this.updateLegend} updateHeatmaps={this.updateHeatmaps}
                                                    treesLayer={this.treesLayer} greenSpacesLayer={this.greenSpacesLayer} furnituresLayer={this.furnituresLayer}
                                                    elementsToModify={elementsToModify} fieldList={this.fieldList} showLoader={this.showLoader}
                                                />}
                                            {modalContentType === 'MergeForm' &&
                                                <MergeForm differentProperties={differentProperties} mergeGreenSpaces={this.mergeGreenSpaces} cancelGreenSpacesMerge={this.cancelGreenSpacesMerge} />}
                                            {modalContentType === 'ThematicMapForm' &&
                                                <ThematicMapForm
                                                    treesLayer={this.treesLayer} greenSpacesLayer={this.greenSpacesLayer} furnituresLayer={this.furnituresLayer} thematicMapToEdit={thematicMapToEdit}
                                                    hideForm={this.hideForm} updateThematicMapStyle={this.updateThematicMapStyle}
                                                    addOverlayToControlLayer={this.addOverlayToControlLayer} updateOverlayInControlLayer={this.updateOverlayInControlLayer}
                                                />}
                                            {modalContentType === 'WmsServiceForm' &&
                                                <WmsServiceForm
                                                    wmsServiceToEdit={wmsServiceToEdit} map={this.map} hideForm={this.hideForm}
                                                    addOverlayToControlLayer={this.addOverlayToControlLayer} updateOverlayInControlLayer={this.updateOverlayInControlLayer} removeOverlayFromControlLayer={this.removeOverlayFromControlLayer}
                                                    addBaseLayerToControlLayer={this.addBaseLayerToControlLayer} updateBaseLayerInControlLayer={this.updateBaseLayerInControlLayer} removeBaseLayerFromControlLayer={this.removeBaseLayerFromControlLayer}
                                                />}
                                            {modalContentType === 'BackgroundImageAdditionForm' &&
                                                <BackgroundImageAdditionForm
                                                    hideForm={this.hideForm} addBackgroundImage={this.addBackgroundImage}
                                                    layer={this.backgroundImagesLayer} surroundingsLayer={this.surroundingsLayer}
                                                />}
                                            {modalContentType === 'TreeHistory' && <TreeHistory treesLayer={this.treesLayer} fieldList={this.fieldList} changeModalContentType={this.changeModalContentType} />}
                                            {modalContentType === 'GreenSpaceHistory' && <GreenSpaceHistory greenSpacesLayer={this.greenSpacesLayer} fieldList={this.fieldList} changeModalContentType={this.changeModalContentType} />}
                                            {modalContentType === 'FurnitureHistory' && <FurnitureHistory furnituresLayer={this.furnituresLayer} fieldList={this.fieldList} changeModalContentType={this.changeModalContentType} />}
                                            {modalContentType === 'ActionHistory' && <ActionHistory showActionInForm={actionToShow => this.setState({ actionToShow }, () => this.changeModalContentType('ActionForm', i18n.t("Actions")))} changeModalContentType={this.changeModalContentType} />}
                                            {modalContentType === 'EvolutionCharts' && <EvolutionCharts />}
                                            {modalContentType === 'PhotosGallery' && <PhotosGallery project={this.props.project} />}
                                            {modalContentType === 'ElementFilesGallery' && <ElementFilesGallery project={this.props.project} />}
                                            {modalContentType === 'ProjectFilesGallery' && <ProjectFilesGallery projectToEdit={projectToEdit} changeModalContentType={this.changeModalContentType} />}
                                            {modalContentType === 'ActionForm' &&
                                                <ActionForm
                                                    treesLayer={this.treesLayer} greenSpacesLayer={this.greenSpacesLayer} furnituresLayer={this.furnituresLayer} actionToShow={actionToShow} resetActionToShow={() => this.setState({ actionToShow: null })}
                                                    fieldList={this.fieldList} updateLegend={this.updateLegend} exportActionAsPDF={this.exportActionAsPDF} exportActionTableAsPDF={this.exportActionTableAsPDF}
                                                />}
                                            {modalContentType === 'EventForm' &&
                                                <EventForm
                                                    treesLayer={this.treesLayer} greenSpacesLayer={this.greenSpacesLayer} furnituresLayer={this.furnituresLayer} actionToShow={actionToShow} resetActionToShow={() => this.setState({ actionToShow: null })}
                                                    fieldList={this.fieldList} updateLegend={this.updateLegend} exportActionAsPDF={this.exportActionAsPDF} exportActionTableAsPDF={this.exportActionTableAsPDF}
                                                />}
                                            {modalContentType === 'FilterForm' &&
                                                <FilterForm
                                                    hideForm={this.hideForm} filterLayers={this.filterLayers} removeFilters={this.removeFilters} drawFilterSurroundings={this.drawFilterSurroundings}
                                                    treesLayer={this.treesLayer} greenSpacesLayer={this.greenSpacesLayer} furnituresLayer={this.furnituresLayer} stationsLayer={this.stationsLayer}
                                                    changeModalContentType={this.changeModalContentType}
                                                />}
                                            {modalContentType === 'ProjectList' &&
                                                <ProjectList
                                                    currentFolderState={this.props.currentFolderState}
                                                    hideForm={this.hideForm} showProjectMap={this.showProjectMap}
                                                    showForm={this.showForm} changeModalContentType={this.changeModalContentType}
                                                />}
                                            {modalContentType === 'FolderCreationForm' &&
                                                <FolderCreationForm changeModalContentType={this.changeModalContentType} showForm={this.showForm} />}
                                            {modalContentType === 'ProjectCreationForm' &&
                                                <ProjectForm
                                                    showForm={this.showForm} changeModalContentType={this.changeModalContentType}
                                                    drawProjectSurroundings={this.drawProjectSurroundings} modifyProjectSurroundings={this.modifyProjectSurroundings}
                                                />}
                                            {modalContentType === 'ProjectDuplicationForm' &&
                                                <ProjectForm
                                                    showForm={this.showForm} changeModalContentType={this.changeModalContentType} projectToDuplicate={projectToDuplicate} setProjectToDuplicate={(project) => this.setState({ projectToDuplicate: project })} resetProjectToDuplicate={() => this.setState({ projectToDuplicate: null })}
                                                    finish={this.handleBackButtonClick} drawProjectSurroundings={this.drawProjectSurroundings} modifyProjectSurroundings={this.modifyProjectSurroundings}
                                                />}
                                            {modalContentType === 'ProjectModificationForm' &&
                                                <ProjectForm
                                                    changeModalContentType={this.changeModalContentType} drawProjectSurroundings={this.drawProjectSurroundings}
                                                    projectToEdit={projectToEdit} setProjectToEdit={(project) => this.setState({ projectToEdit: project })} modifyProjectSurroundings={this.modifyProjectSurroundings}
                                                />}
                                            {modalContentType === 'ProjectShrinkageForm' &&
                                                <ProjectShrinkageForm
                                                    elementsOutsideNewBounds={elementsOutsideNewBounds}
                                                    cancelSurroundingsShrinkage={this.cancelSurroundingsShrinkage}
                                                    confirmProjectShrinkage={this.confirmProjectShrinkage}
                                                />}
                                            {modalContentType === 'CollaboratorsForm' &&
                                                <CollaboratorsForm projectToEdit={projectToEdit} changeModalContentType={this.changeModalContentType} />}
                                            {modalContentType === 'RoleList' &&
                                                <RoleList
                                                    projectToEdit={projectToEdit} changeModalContentType={this.changeModalContentType} hideForm={this.hideForm} drawCustomArea={this.drawCustomArea} setMapSurroundings={this.setMapSurroundings}
                                                    layerContainers={layerContainers} map={this.map} showProjectMap={this.showProjectMap}
                                                />}
                                            {modalContentType === 'LinkedElementList' &&
                                                <LinkedElementList layerContainers={layerContainers} linkElements={this.linkElements} showElement={this.showElement} />}
                                            {modalContentType === 'ImportForm' &&
                                                <ImportForm projectToEdit={projectToEdit} changeModalContentType={this.changeModalContentType} />}
                                            {modalContentType === 'ExportForm' &&
                                                <ExportForm project={projectToEdit} changeModalContentType={this.changeModalContentType} />}
                                            {modalContentType === 'TreeTable' &&
                                                <TreeTable
                                                    linkedTrees={this.linkedTrees} treesLayer={this.treesLayer} treesLayerNotClustered={this.treesLayerNotClustered} fieldList={this.fieldList}
                                                    changeModalContentType={this.changeModalContentType} showElement={this.showElement} showElements={this.showElements} updateLegend={this.updateLegend}
                                                    updateHeatmaps={this.updateHeatmaps} showLoader={this.showLoader} closingTable={this.state.closingTable} selectedElements={selectedElements}
                                                    showExitForm={() => this.setState({ showExitForm: true })} hideForm={this.hideForm} mainFilters={this.state.mainFilters}
                                                    showModal={this.showModal} setElementLatLng={this.setElementLatLng} nbStations={this.stationsLayer?.getLayers()?.length}
                                                />}
                                            {modalContentType === 'GreenSpaceTable' &&
                                                <GreenSpaceTable
                                                    greenSpacesLayer={this.greenSpacesLayer} fieldList={this.fieldList} changeModalContentType={this.changeModalContentType}
                                                    showElement={this.showElement} showElements={this.showElements} updateLegend={this.updateLegend}
                                                    showLoader={this.showLoader} closingTable={this.state.closingTable} selectedElements={selectedElements}
                                                    showExitForm={() => this.setState({ showExitForm: true })} hideForm={this.hideForm} mainFilters={this.state.mainFilters}
                                                    nbStations={this.stationsLayer?.getLayers()?.length}
                                                />}
                                            {modalContentType === 'FurnitureTable' &&
                                                <FurnitureTable
                                                    linkedFurnitures={this.linkedFurnitures} furnituresLayer={this.furnituresLayer} furnituresLayerNotClustered={this.furnituresLayerNotClustered}
                                                    fieldList={this.fieldList} changeModalContentType={this.changeModalContentType}
                                                    showElement={this.showElement} showElements={this.showElements} updateLegend={this.updateLegend}
                                                    showLoader={this.showLoader} closingTable={this.state.closingTable} selectedElements={selectedElements}
                                                    showExitForm={() => this.setState({ showExitForm: true })} hideForm={this.hideForm} mainFilters={this.state.mainFilters}
                                                    nbStations={this.stationsLayer?.getLayers()?.length}
                                                />}
                                            {modalContentType === 'ActionTable' &&
                                                <ActionTable
                                                    {...this.props} trees={this.treesLayerNotClustered} greenSpaces={this.greenSpacesLayer} furnitures={this.furnituresLayerNotClustered} layersToLink={layersToLink} layersToUnlink={layersToUnlink}
                                                    updateTreesLayerStyle={this.updateTreesLayerStyle} updateGreenSpacesLayerStyle={this.updateGreenSpacesLayerStyle}
                                                    exportActionAsPDF={this.exportActionAsPDF} exportActionTableAsPDF={this.exportActionTableAsPDF} mainFilters={this.state.mainFilters}
                                                    manageActionElements={this.manageActionElements} hideForm={this.hideForm} showElement={this.showElement} changeModalContentType={this.changeModalContentType}
                                                />}
                                            {modalContentType === 'EventTable' &&
                                                <EventTable
                                                    {...this.props} trees={this.treesLayerNotClustered} greenSpaces={this.greenSpacesLayer} furnitures={this.furnituresLayerNotClustered} layersToLink={layersToLink} layersToUnlink={layersToUnlink}
                                                    manageEventElements={this.manageEventElements} hideForm={this.hideForm} showElement={this.showElement}
                                                />}
                                            {modalContentType === 'ProjectHistory' && <ProjectHistory layers={[this.treesLayer, this.greenSpacesLayer, this.furnituresLayer, this.markersLayer]} showElement={this.showElement} selectElements={this.selectElements} selectedElements={selectedElements} />}
                                            {modalContentType === 'ProjectDetail' &&
                                                <ProjectDetail
                                                    {...this.props} trees={this.treesLayerNotClustered} greenSpaces={this.greenSpacesLayer}
                                                    furnitures={this.furnituresLayerNotClustered} stations={this.stationsLayer} selectedElements={selectedElements}
                                                    showElement={this.showElement} exportStatisticsAsPDF={this.exportStatisticsAsPDF}
                                                    showLoader={this.showLoader} isComparison={false} compareProjectWith={this.compareProjectWith}
                                                />}
                                            {modalContentType === 'ProjectDetailComparison' &&
                                                <ProjectDetailComparison
                                                    trees={this.treesLayerNotClustered} greenSpaces={this.greenSpacesLayer}
                                                    furnitures={this.furnituresLayerNotClustered} stations={this.stationsLayer}
                                                    projectToCompareWith={projectToCompareWith} compareProjectWith={this.compareProjectWith}
                                                    exitComparison={() => this.changeModalContentType('ProjectDetail', i18n.t("Statistiques"))}
                                                />}
                                            {modalContentType === 'GendersChart' && <GendersChart layer={this.treesLayerNotClustered} />}
                                            {modalContentType === 'OntogenicsChart' && <OntogenicsChart layer={this.treesLayerNotClustered} />}
                                            {modalContentType === 'VigorsChart' && <VigorsChart layer={this.treesLayerNotClustered} />}
                                            {modalContentType === 'ActionsChart' &&
                                                <>
                                                    {!this.props.isOnline || !projectActions
                                                        ? <Woops />
                                                        : <ActionsChart />
                                                    }
                                                </>}
                                            {modalContentType === 'CutDownsChart' && <CutDownsChart layer={this.treesLayerNotClustered} />}
                                            {modalContentType === 'HealthReviewsChart' && <HealthReviewsChart layer={this.treesLayerNotClustered} />}
                                            {modalContentType === 'CustomCharts' &&
                                                <CustomCharts
                                                    match={this.props.match} history={this.props.history} treesLayer={this.treesLayer} greenSpacesLayer={this.greenSpacesLayer}
                                                    mainFilters={this.state.mainFilters} filterLayers={this.filterLayers} removeFilters={this.removeFilters}
                                                />}
                                            {modalContentType === 'ScreenshotEditor' &&
                                                <ScreenshotEditor imageToEdit={imageToEdit} downloadScreenshot={this.downloadScreenshot} config={this.screenshotConfig} />}
                                            {modalContentType === 'CustomScanForm' &&
                                                <CustomScanForm
                                                    scan={scan}
                                                    cancel={() => this.setState({ modal: { visible: false, title: '' }, modalContentType: 'ScanForm' })}
                                                    submit={(url) => this.setState(prevState => ({ modal: { visible: false, title: '' }, modalContentType: 'ScanForm', scan: { ...prevState.scan, customAPI: url } }))}
                                                />}
                                            {modalContentType === 'AIResultMap' &&
                                                <AIResultMap scan={scan} finishAddWithAI={this.finishAddWithAI} cancelAddWithAI={this.cancelAddWithAI} />}
                                        </Modal.Description>
                                    </Modal.Content>
                                </Modal >
                            </TransitionablePortal >
                            {modalContentType === 'ModifySurroundingsForm' &&
                                <ModifySurroundingsForm
                                    finishSurroundingsModification={this.finishSurroundingsModification} finishSurroundingsUpdate={this.finishSurroundingsUpdate}
                                />
                            }
                            {modalContentType === 'ScreenshotForm' &&
                                <ScreenshotForm takeRawScreenshot={this.takeRawScreenshot} takeScreenshot={this.takeScreenshot} hideForm={this.hideForm} map={this.map} />}
                            {isTimelineVisible &&
                                <TimelineForm
                                    hideForm={this.hideForm} addMarker={this.addMarker} addGreenSpace={this.addGreenSpace} showLoader={this.showLoader}
                                    layers={[this.treesLayer, this.treesLayerNotClustered, this.greenSpacesLayer, this.furnituresLayer, this.furnituresLayerNotClustered]}
                                    timelineYears={this.timelineYears} setTimelineYears={(year, elements) => this.timelineYears[year] = elements}
                                    updateLegend={this.updateLegend} updateHeatmaps={this.updateHeatmaps} filteredLayers={this.filteredLayers} filterLayers={this.filterLayers}
                                    clearFilteredLayers={() => this.filteredLayers = {}} mainFilters={this.state.mainFilters} map={this.map}
                                    currentYear={currentYear} setCurrentYear={(year) => this.setState({ currentYear: year })}
                                />}
                            {['ScanForm', 'CustomScanForm'].includes(modalContentType) &&
                                <ScanForm
                                    scan={scan} map={this.map} hideForm={this.hideForm} addTreesWithAI={this.addTreesWithAI} stations={this.stationsLayer.getLayers()}
                                    handleChange={wmsService => this.setState(prevState => ({ scan: { ...prevState.scan, wmsService } }))}
                                    changeModalContentType={this.changeModalContentType}
                                />}
                            {modalContentType === 'OffsetForm' &&
                                <OffsetForm
                                    isBuffer={isBuffer} offsetLayer={this.offsetLayer} isDragFinished={isDragFinished}
                                    setPolygonDimensions={this.setPolygonDimensions} handleIsBufferChange={isBuffer => this.setState({ isBuffer })} pushActionHistory={this.pushActionHistory}
                                />}
                            {modalContentType === 'LineForm' &&
                                <LineForm
                                    drawingSegments={drawingSegments}
                                    changeModalContentType={this.changeModalContentType} hideForm={this.hideForm}
                                    setLineDimensions={this.setLineDimensions} pushActionHistory={this.pushActionHistory}
                                />}
                            {modalContentType === 'RectangleForm' &&
                                <RectangleForm
                                    rectangleDimensions={rectangleDimensions}
                                    changeModalContentType={this.changeModalContentType} hideForm={this.hideForm}
                                    setRectangleDimensions={this.setRectangleDimensions} checkIfInsideSurroundings={this.checkIfInsideSurroundings}
                                    angle={this.state.angle} setAngle={this.setAngle} originalLatLngs={this.stickyRotateStartLatLngs}
                                    currentElementTools={currentElementTools}
                                />}
                            {modalContentType === 'CircleForm' &&
                                <CircleForm
                                    radius={radius} currentElementTools={currentElementTools} handleRadiusChange={(radius) => this.setState({ radius })}
                                    changeModalContentType={this.changeModalContentType} hideForm={this.hideForm} setCircleDimensions={this.setCircleDimensions}
                                />}
                            {modalContentType === 'RemoveForm' &&
                                <RemoveForm
                                    layersToRemove={removeFormLayers} layerContainers={layerContainers}
                                    greenSpacesLayer={this.greenSpacesLayer} fieldList={this.fieldList}
                                    updateLegend={this.updateLegend} updateHeatmaps={this.updateHeatmaps} hideRemoveForm={this.hideRemoveForm}
                                    changeModalContentType={this.changeModalContentType} removingWithShortcut={removingWithShortcut} resetActionHistory={this.resetActionHistory}
                                    unselectElements={this.unselectElements} checkHistoryButtons={this.checkHistoryButtons}
                                />}
                            {modalContentType === 'StationForm' &&
                                <StationForm
                                    hideForm={this.hideForm} addStation={this.addStation}
                                    logged={this.props.logged} updateLegend={this.updateLegend} stationsLayer={this.stationsLayer}
                                    isTabletLandscape={isTablet && this.props.isLandscape} isMobileLandscape={isMobileLandscape}
                                />}
                            {modalContentType === 'ShareForm' &&
                                <ShareForm {...this.props} hideForm={this.hideForm} mapSearch={this.mapSearch.long} />}
                            {modalContentType === 'SelectElementTypeForm' &&
                                <SelectElementTypeForm hideForm={this.hideForm} performAdminTask={this.performAdminTask} />}
                            {modalContentType === 'ExportPdfForm' &&
                                <ExportPdfForm hideExportPdfForm={this.hideExportPdfForm} exportElementAsPdf={this.exportElementAsPdf} />}
                            {modalContentType === 'CutForm' &&
                                <CutForm
                                    mergedPolygon={this.mergedPolygon} cancelGreenSpacesCut={this.cancelGreenSpacesCut}
                                    cutGreenSpaces={this.cutGreenSpaces} hideForm={this.hideForm}
                                />}
                            {modalContentType === 'DuplicateForm' &&
                                <DuplicateForm
                                    changeModalContentType={this.changeModalContentType} hideForm={this.hideForm}
                                    addMarker={this.addMarker} addGreenSpace={this.addGreenSpace}
                                    map={this.map} layerContainers={layerContainers}
                                    updateLegend={this.updateLegend} updateHeatmaps={this.updateHeatmaps} checkIfInsideSurroundings={this.checkIfInsideSurroundings}
                                    drawPlacementLine={() => { this.map.pm.setGlobalOptions({ allowSelfIntersection: false }); this.map.pm.enableDraw('Line'); }}
                                    removePlacementLine={() => this.setState({ placementLine: null })} placementLine={this.state.placementLine}
                                />}
                            {modalContentType === 'BackgroundImageManagementForm' &&
                                <BackgroundImageManagementForm
                                    backgroundImagesLayer={this.backgroundImagesLayer} updateBackgroundImagesLayer={this.updateBackgroundImagesLayer}
                                    pushActionHistory={this.pushActionHistory} changeBackgroundImageVisibility={this.changeBackgroundImageVisibility}
                                    id={backgroundImageManagementFormId}
                                />}
                            {modalContentType === 'LinkElementsForm' &&
                                <LinkingElementsForm
                                    selectedElements={highlightedElements} toggleElementsHighlight={this.toggleElementsHighlight}
                                    layerContainers={layerContainers} fieldList={this.fieldList} changeModalContentType={this.changeModalContentType}
                                    toolbarRef={this.toolbarRef}
                                />}
                            {selectedElements?.length > 0 && this.props.currentAction !== 'drawingScanZone' &&
                                <SelectedElementsForm selectedElements={selectedElements} unselectElements={this.unselectElements} />}
                            {drawingSegments?.length > 0 && ['addingPolygon', 'addingLine'].includes(this.props.currentAction) &&
                                <MeasureForm drawingSegments={drawingSegments} />}
                            {modalContentType === 'ReferencesForm' &&
                                <ReferencesForm
                                    hideForm={this.hideForm} references={references} overlays={overlays}
                                    renderMarkersReferences={this.renderMarkersReferences} renderGreenSpacesReferences={this.renderGreenSpacesReferences}
                                />}
                            {this.props.project?.type === 'project' &&
                                <Button
                                    title={i18n.t("Partager la vue")} onClick={() => this.setState({
                                        modal: { visible: false, title: i18n.t("Partage de la vue") }, modalContentType: 'ShareForm'
                                    })}
                                    color='blue' style={{ position: 'absolute', bottom: '25px', right: '7px', zIndex: 1000, borderRadius: '50%', padding: '10px' }}
                                >
                                    <FontAwesomeIcon icon={faShareFromSquare} />
                                </Button>}
                        </>}
                    <div style={{ height: '100%' }}>
                        <HotKeys keyMap={this.keyMap} handlers={this.handlers}>
                            <Toolbar ref={this.toolbarRef}
                                publicFields={this.publicFields} logged={this.props.logged} selectedElements={this.state.selectedElements}
                                handleToolbarButtonClick={this.handleToolbarButtonClick}
                            />
                            <div id='projectMapContainer' className={this.props.project?.type} onContextMenu={this.handleContextMenu} style={{ left: isToolbarExpanded ? '300px' : '40px', transition: 'left 500ms' }}>
                                <Legend
                                    ref={this.legendRef} map={this.map} fieldList={this.fieldList} baseLayers={baseLayers} overlays={overlays}
                                    treesLayer={this.treesLayer} greenSpacesLayer={this.greenSpacesLayer} furnituresLayer={this.furnituresLayer}
                                    markersLayer={this.markersLayer} stationsLayer={this.stationsLayer}
                                    naturaBirdsLayer={this.naturaBirdsLayer} naturaHabitatsLayer={this.naturaHabitatsLayer} project={this.props.project}
                                />
                            </div>
                        </HotKeys>
                        <MapContextMenu
                            handleContextMenuButtonClick={this.handleContextMenuButtonClick} isButtonClickable={this.toolbarRef?.current?.isButtonClickable}
                            findButton={this.toolbarRef?.current?.findButton} rerenderContextMenu={this.state.rerenderContextMenu} references={references}
                            map={this.map} treesLayer={this.treesLayer} greenSpacesLayer={this.greenSpacesLayer} furnituresLayer={this.furnituresLayer}
                            markersLayer={this.markersLayer} stationsLayer={this.stationsLayer} carbonStockLayer={this.carbonStockLayer} coolingLayer={this.coolingLayer}
                            naturaBirdsLayer={this.naturaBirdsLayer} naturaHabitatsLayer={this.naturaHabitatsLayer}
                            googleLayerRoadmap={this.googleLayerRoadmap} googleLayerRoadmapDark={this.googleLayerRoadmapDark}
                            googleLayerHybrid={this.googleLayerHybrid} OSMLayer={this.OSMLayer} planLayer={this.planLayer}
                            urbisLayer={this.urbisLayer} ignLayerOrtho={this.ignLayerOrtho} ignLayerPlan={this.ignLayerPlan}
                            cadastreLayer={this.cadastreLayer}
                        />
                        <ElementContextMenu contextMenuElement={contextMenuElement} selectedElements={selectedElements} rerenderContextMenu={this.state.rerenderContextMenu} handleContextMenuButtonClick={this.handleContextMenuButtonClick} />
                    </div>
                    <div id='add-tree-area'></div>
                </Segment>
                {showExitForm && <ExitFormPopup close={() => this.setState({ showExitForm: false })} hideForm={this.hideForm} />}
                {showGeosearch &&
                    <GeosearchInput map={this.map} closeInput={() => {
                        this.setState({ showGeosearch: false }, () => this.toolbarRef.current.setButtonState('searchLocation', 0, 1));
                    }} />}
                <ProjectWebSocket
                    layerContainers={layerContainers} logged={this.props.logged} checkIfInsideSurroundings={this.checkIfInsideSurroundings}
                    isTimelineVisible={isTimelineVisible} timelineYears={this.timelineYears} setTimelineYears={(year, elements) => this.timelineYears[year] = elements}
                    addMarker={this.addMarker} addGreenSpace={this.addGreenSpace} addStation={this.addStation} addBackgroundImage={this.addBackgroundImage} hideForm={this.hideForm}
                    addBackgroundImagesToLayer={this.addBackgroundImagesToLayer} getTreesToCutDown={this.getTreesToCutDown}
                    updateLegend={this.updateLegend} updateHeatmaps={this.updateHeatmaps} updateToCutDown={this.updateToCutDown}
                    findButton={this.toolbarRef?.current?.findButton} publicFields={this.publicFields} modalContentType={this.state.modalContentType}
                />
                {this.isUserInProject && <ConnectedUsers />}
                <BaseLayersMenu
                    map={this.map} baseLayers={baseLayers} overlays={overlays} setOverlays={(newOverlays) => this.setState({ overlays: newOverlays })}
                    surroundingsLayer={this.surroundingsLayer} toggleOverlay={this.toggleOverlay} toggleLegend={this.toggleLegend} changeModalContentType={this.changeModalContentType}
                    removeOverlayFromControlLayer={this.removeOverlayFromControlLayer} removeBaseLayerFromControlLayer={this.removeBaseLayerFromControlLayer}
                    areAdditionalLayersLoaded={areAdditionalLayersLoaded} showBaseLayer={this.showBaseLayer} originalSearch={this.originalSearch}
                    setThematicMapToEdit={(thematicMap) => this.setState({ thematicMapToEdit: thematicMap }, () => {
                        this.changeModalContentType('ThematicMapForm', i18n.t("Création d'une carte thématique"));
                    })}
                    setWmsServiceToEdit={(wmsService) => this.setState({ wmsServiceToEdit: wmsService }, () => {
                        this.changeModalContentType('WmsServiceForm', i18n.t("Création d'une carte thématique"));
                    })}
                />
                {this.props.currentAction === 'drawingScanZone' &&
                    <Label color='green' size='large' style={{ transform: 'translateX(-50%)', left: `calc(${(isToolbarExpanded ? 305 : 45) / 2}px + 50%)`, zIndex: '1000', bottom: '20px', position: 'absolute' }}>
                        {i18n.t("Veuillez dessiner la zone à scanner")}
                    </Label>}
            </>
        );
    }

    async componentDidMount() {
        this.toolbarRef = React.createRef();
        this.legendRef = React.createRef();

        // Réinitialisation state Redux
        this.props.showProjectList(false, { renderDefaultMap: false });
        this.props.setCurrentAction('');
        this.props.setTableState(null);
        this.props.setFilterFormState(null);
        this.props.setLayer(null); // Layer qu'on ajoute ou modifie

        // Chargement & initialisation du projet lorsqu'on arrive via URL
        let project, isUserAuthorized = false;
        const initialRights = RightsUtil.getRights();
        let rights = { ...initialRights };
        let projectCollaborators = this.props.projectCollaborators;

        if (!this.props.project?.id || !this.props.rights) {
            if (this.props.project) project = this.props.project;
            else {
                project = await ProjectsService.getProject(this.props.match.params.pid);
                if (project) {
                    projectCollaborators = project.userBaseProjects;
                    this.props.setProjectCollaborators(projectCollaborators);
                    ProjectsUtil.assignProjectFormulaVersionsType(project, this.props.formulas);
                }
            }

            if (project) {
                const token = new Cookies().get('token');
                const id = token ? jwtDecode(token).id : null;
                let found = false;
                if (id && projectCollaborators) {
                    for (let i = 0; i < projectCollaborators.length && !found; i++) {
                        if (projectCollaborators[i].userId === id) {
                            found = true;
                            isUserAuthorized = true;
                            rights = RightsUtil.getRights(projectCollaborators[i].projectRole, this.props.loginAsData?.readOnly || RightsUtil.isRestricted(this.props.activeOrganization?.subscription, projectCollaborators[i].projectRole));
                        }
                    }
                }
            }
        } else isUserAuthorized = true;

        if (project?.isPublic) isUserAuthorized = true;
        if (!isUserAuthorized) {
            this.props.setProject(null);
            this.props.changeActiveItem('home');
            if (!this.props.logged) {
                this.props.setRedirectURL(window.location.pathname);
                this.props.history.push('/login');
            } else this.props.history.push('/');
            return;
        } else this.props.changeActiveItem('projectMap'); // Sélectionne l'onglet projectMap dans la Navbar quand la map est affichée

        if (!this.props.project && project) {
            this.props.setRights(rights);
            this.props.setProject(project);
            return;
        }

        // Initialisation de variables globales
        const { viewId } = UrlsUtil.getSearchParams(this.props.location?.search);
        this.mapSearch = this.props.location?.search && { short: this.props.location?.search, long: this.props.location?.search };
        this.originalSearch = viewId ? this.props.project?.projectSharings?.find(sharing => sharing.id === viewId)?.searchParams : this.props.location?.search;
        this.requiredFields = ProjectsUtil.getProjectRequiredFields(this.props.project);
        this.publicFields = ProjectsUtil.getProjectPublicFields(this.props.project, projectCollaborators);
        this.isUserInProject = ProjectsUtil.isUserInProject(projectCollaborators);
        this.shape = ''; // Type du layer qu'on ajoute (polygone ou marker)
        this.filteredLayers = {};
        this.linkedTrees = [];
        this.linkedFurnitures = [];
        this.helpLayers = [];
        this.actionLinkingCategories = ['Arbre'];
        this.eventLinkingCategories = ['Arbre'];
        this.actionHistory = { index: 0, history: [] };
        this.stickyRotation = false;
        this.areBackgroundImagesLoaded = false;
        this.shouldUpdateBackgroundImagesLayer = false;
        this.keyMap = ShortcutsUtil.getMapShortcuts();
        this.shiftKey = false;
        this.timelineYears = {};
        this.projectLabels = null;

        // Application du thème
        if (this.props.project?.organization.subscription.branding)
            ThemesUtil.applyTheme(this.props.project.theme);

        // Définition du marker par défaut
        delete L.Icon.Default.prototype._getIconUrl;
        L.Icon.Default.mergeOptions({
            iconRetinaUrl: defaultMarkerIcon,
            iconUrl: defaultMarkerIcon,
            iconSize: [25, 25],
            shadowSize: [0, 0],
            iconAnchor: [12, 12],
            shadowAnchor: [12, 6],
            popupAnchor: [0, 0],
            className: 'leaflet-default-marker-icon'
        });

        // Définition des layers
        L.GridLayer.PlanLayer = L.GridLayer.extend({
            createTile: () => {
                var tile = document.createElement('div');
                tile.style.outline = '1px solid grey';
                tile.style.backgroundColor = this.props.isDarkTheme ? 'var(--black-100)' : 'var(--white-100)';
                return tile;
            }
        });

        this.googleLayerRoadmap = L.gridLayer.googleMutant({
            maxZoom: 22,
            type: 'roadmap',
            styles: [
                { elementType: 'labels', stylers: [{ visibility: 'off' }] }, // On masque tous les labels
                { featureType: 'road', elementType: 'labels.text', stylers: [{ visibility: 'on' }] }, // Sauf les noms des routes
                { featureType: 'administrative', elementType: 'labels.text', stylers: [{ visibility: 'on' }] } // Et des communes/provinces
            ]
        });

        this.googleLayerRoadmapDark = L.gridLayer.googleMutant({
            maxZoom: 22,
            type: 'roadmap',
            styles: GDK
        });

        this.googleLayerHybrid = L.gridLayer.googleMutant({
            maxZoom: 22,
            type: 'hybrid',
            styles: [
                { elementType: 'labels', stylers: [{ visibility: 'off' }] },
                { featureType: 'road', elementType: 'labels.text', stylers: [{ visibility: 'on' }] },
                { featureType: 'administrative', elementType: 'labels.text', stylers: [{ visibility: 'on' }] }
            ]
        });

        this.OSMLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            maxNativeZoom: 19,
            maxZoom: 22
        });

        this.planLayer = new L.GridLayer.PlanLayer({ maxZoom: 22 });

        const favoriteTiles = UrlsUtil.getSearchParams(this.originalSearch).baseLayer || OfflineUtil.getProjectViewValue(this.props.project?.id, 'favoriteTiles');
        const baseLayers = [
            { label: 'Google Roadmap', name: 'Roadmap', layer: this.googleLayerRoadmap, isShown: favoriteTiles === 'Roadmap' || (!favoriteTiles && !this.props.isDarkTheme) },
            { label: 'Google Roadmap', name: 'Roadmap Dark', layer: this.googleLayerRoadmapDark, isShown: favoriteTiles === 'Roadmap Dark' || (!favoriteTiles && this.props.isDarkTheme) },
            { label: 'Google Hybrid', name: 'Hybrid', layer: this.googleLayerHybrid, isShown: favoriteTiles === 'Hybrid' },
            { label: 'OSM', name: 'OSM', layer: this.OSMLayer, isShown: favoriteTiles === 'OSM' },
            { label: i18n.t("Plan"), name: 'Plan', layer: this.planLayer, isShown: favoriteTiles === 'Plan' }
        ];
        this.setState({ baseLayers });

        /* Création de la carte */
        // Réinitialisation du container de la map
        let projectMapContainer = document.getElementById('projectMapContainer');
        if (!projectMapContainer) return;
        projectMapContainer.innerHTML = '<div id="projectMap"></div>';
        // Création & initialisation
        this.map = L.map('projectMap', {
            zoomControl: false,
            layers: baseLayers.find(baseLayer => baseLayer.isShown)?.layer || (this.props.isDarkTheme ? this.googleLayerRoadmapDark : this.googleLayerRoadmap), // Layer par défaut
            minZoom: 3,
            maxBoundsViscosity: 1.0
        });
        this.panes = [
            this.map.getPane('overlayPane'),
            ...['stations', 'greenSpaces', 'markers', 'furnitures', 'trees', 'surroundings'].map(pane => this.map.createPane(pane, this.map.getPane('mapPane')))
        ];
        this.resetPanesIndexes(true);

        /* Ajout des contrôles & éléments d'interface */
        // Copyright Grality
        if (this.props.project.theme?.logoName?.length || AppSettings.isUrbasenseUrl()) {
            L.Control.gralityAttribution = L.Control.extend({
                onAdd: () => {
                    const text = L.DomUtil.create('div');
                    text.id = 'grality-attribution';
                    text.innerHTML = '<strong>Grality</strong>'
                    return text;
                }
            });
            new L.Control.gralityAttribution({ position: 'topright' }).addTo(this.map);
        }

        // Lasso
        this.lasso = L.lasso(this.map, {
            polygon: {
                color: 'var(--primary-100)',
                weight: 2,
            }
        });
        // Bookmarks
        if (this.isUserInProject) {
            const bookmarkControl = new L.Control.Bookmarks({
                emptyMessage: i18n.t("Aucun bookmark"),
                addBookmarkMessage: i18n.t("Nouveau bookmark"),
                popupOnShow: false,
                animateDuration: 0,
                defaultBookmarkOptions: {
                    editable: false,
                    removable: true
                },
                storage: {
                    setItem: (_, value, callback) => {
                        delete value.id;
                        value.lat = value.latlng[0];
                        value.lng = value.latlng[1];
                        // Pour récupérer dans App.js en cas d'offline
                        value.userId = jwtDecode(new Cookies().get('token')).id;
                        value.projectId = this.props.project.id;
                        BookmarksService.addBookmark(value, this.props.project.id).then(callback);
                    },
                    removeItem: (id, callback) => {
                        BookmarksService.removeBookmark(id, this.props.project.id).then((bookmark) => {
                            callback(bookmark || { id: id });
                        });
                    },
                    getAllItems: (callback) => {
                        if (this.props.projects) { // Mise à jour de la date d'ouverture
                            const baseProjects = JSON.parse(JSON.stringify(this.props.projects));
                            const pathSplit = this.props.project.path?.split('/').filter(id => id).map(id => Number(id)) || [];
                            const baseProjectInRedux = baseProjects.find(bp => bp.id === this.props.project.id || pathSplit.includes(bp.id));

                            const userBaseProject = baseProjectInRedux?.userBaseProjects.find(userBaseProject => userBaseProject.user.id === jwtDecode(new Cookies().get('token')).id);
                            if (userBaseProject) {
                                if (!userBaseProject.openings) userBaseProject.openings = {};
                                userBaseProject.openings[this.props.project.id] = new Date().toUTCString();
                                pathSplit.forEach(id => userBaseProject.openings[id] = new Date().toUTCString());
                                this.props.setProjects(baseProjects);
                            }
                        }

                        if (this.props.project.type === 'project') BookmarksService.getBookmarks(this.props.project.id).then(callback);
                        else {
                            const bookmarks = [...this.props.project.bookmarks];
                            bookmarks.forEach(bookmark => {
                                bookmark.latlng = [bookmark.lat, bookmark.lng];
                                bookmark.id = String(bookmark.id) //! Important car leaflet-bookmarks compare l'id sous forme de string pour trouver le bon bookmark
                            });
                            callback(bookmarks);
                        }
                    }
                },
                formPopup: {
                    popupClass: 'bookmarks-popup',
                    templateOptions: {
                        formClass: 'leaflet-bookmarks-form',
                        inputClass: 'leaflet-bookmarks-form-input',
                        inputErrorClass: 'has-error',
                        idInputClass: 'leaflet-bookmarks-form-id',
                        coordsClass: 'leaflet-bookmarks-form-coords',
                        submitClass: 'leaflet-bookmarks-form-submit',
                        inputPlaceholder: 'Nom du bookmark',
                        cancelClass: 'leaflet-bookmarks-form-cancel',
                        submitTextCreate: '+',
                        submitTextEdit: '<span class="icon-checkmark"></span>'
                    }
                }
            }).addTo(this.map);

            L.DomEvent.off(bookmarkControl._container, 'click', bookmarkControl._onClick, bookmarkControl);
            L.DomEvent.on(bookmarkControl._container, 'mouseover', bookmarkControl.expand, bookmarkControl);
            L.DomEvent.on(bookmarkControl._container, 'mouseout', bookmarkControl.collapse, bookmarkControl);
        }
        // Légende
        const patterns = StylesUtil.getPatterns();
        patterns.forEach(pattern => { pattern.addTo(this.map); });
        // Mesure
        if (!isMobileOnly) {
            L.Control.Measure.include({ // Workaround pour éviter le bug de déplacement de la carte dans la nouvelle version de Leaflet
                _setCaptureMarkerIcon: function () {
                    this._captureMarker.options.autoPanOnFocus = false;
                    this._captureMarker.setIcon(
                        L.divIcon({
                            iconSize: this._map.getSize().multiplyBy(2)
                        })
                    );
                },
            });

            L.control.measure({
                primaryLengthUnit: 'meters',
                secondaryLengthUnit: 'kilometers',
                primaryAreaUnit: 'sqmeters',
                secondaryAreaUnit: 'hectares',
                activeColor: 'var(--primary-100)',
                completedColor: 'var(--primary-100)'
            }).addTo(this.map);
        }
        // Échelle
        L.control.scale({ position: 'bottomleft', imperial: false }).addTo(this.map);
        // Localisation
        this.locateControl = L.control.locate({
            locateOptions: { enableHighAccuracy: true }
        }).addTo(this.map);
        // Street view
        new L.Control.Pegman({
            position: 'topright',
            theme: 'leaflet-pegman-v3-small',
        }).addTo(this.map);

        // Assignation des évenements à la carte
        this.assignMapEvents();

        // Chargement des éléments du projet
        this.setLayers();
        this.loadPhotos();
        this.loadFiles();
        this.loadLinkedElements();
        this.loadBackgroundImages();

        const projectIds = this.props.project.type === 'folder' ? this.props.project.projectLabels.map(pl => pl.id) : [this.props.project.id];

        // Preload des éléments
        this.preloadPromise = [];
        projectIds.forEach(projectId => this.preloadElements(projectId));
        Promise.all(this.preloadPromise).then(() => {
            if (this.treesPreloadLayer && this.state.overlays.find(overlay => overlay.label === i18n.t("Arbres"))?.isShown) this.map.addLayer(this.treesPreloadLayer);
            if (this.furnituresPreloadLayer && this.state.overlays.find(overlay => overlay.label === i18n.t("Mobilier urbain"))?.isShown) this.map.addLayer(this.furnituresPreloadLayer);
            if (this.greenSpacesPreloadLayer && this.state.overlays.find(overlay => overlay.label === i18n.t("Espaces verts"))?.isShown) this.map.addLayer(this.greenSpacesPreloadLayer);
        });

        Promise.all([
            this.loadProjectCustomFields(),
            ...projectIds.map(projectId => ProjectsService.getCaches(projectId))
        ]).then(([_, ...cacheResults]) => {
            const loadedCustomFields = [...this.props.customFields, ...(this.props.organizationCustomFields || []), ...(this.props.projectsCustomFields[this.props.project.id] || [])].map(cf => cf.id).filter(cf => cf);
            this.missingCustomFieldIds = [];

            this.treesLoadingPromise = []; this.greenSpacesLoadingPromise = []; this.furnituresLoadingPromise = [];
            projectIds.forEach((projectId, index) => {
                this.loadTrees(projectId, loadedCustomFields, cacheResults[index]);
                this.loadGreenSpaces(projectId, loadedCustomFields, cacheResults[index]);
                this.loadFurnitures(projectId, loadedCustomFields, cacheResults[index]);
            });
            this.treesLoadingPromise = Promise.all(this.treesLoadingPromise);
            this.greenSpacesLoadingPromise = Promise.all(this.greenSpacesLoadingPromise);
            this.furnituresLoadingPromise = Promise.all(this.furnituresLoadingPromise);

            this.loadMarkers();
            this.loadStations(favoriteTiles);
            this.loadActions(); //! Doit être fait après this.loadTrees pour que la promise de chargement des arbres soit initialisée
            this.loadProjectEvents();
            this.loadMissingCustomFields();

            [
                { promise: this.treesLoadingPromise, layer: this.treesLayer, label: i18n.t("Arbres"), preloadLayer: 'treesPreloadLayer', customProcess: () => this.updateHeatmaps() },
                { promise: this.greenSpacesLoadingPromise, layer: this.greenSpacesLayer, label: i18n.t("Espaces verts"), preloadLayer: 'furnituresPreloadLayer', customProcess: () => this.greenSpacesLayer.getLayers().sort((a, b) => a.feature.properties.zIndex - b.feature.properties.zIndex).forEach(layer => layer.bringToFront()) },
                { promise: this.furnituresLoadingPromise, layer: this.furnituresLayer, label: i18n.t("Mobilier urbain"), preloadLayer: 'greenSpacesPreloadLayer' }
            ].forEach(({ promise, layer, preloadLayer, label, customProcess }) => {
                promise.then(() => {
                    const isLayerShown = this.state.overlays.find(overlay => overlay.label === label)?.isShown;
                    if (isLayerShown) {
                        this.toggleLayer({ layer });
                        this.updateLegend(label);
                    }
                    if (this[preloadLayer]) {
                        this.map.removeLayer(this[preloadLayer]);
                        this[preloadLayer] = null;
                    }
                    if (customProcess) customProcess();
                });
            });
        });

        // Affichage des noms des projets
        this.renderProjectLabels();

        this.handlers = this.getShortcutHandlers();
        if (!isMobile) {
            document.addEventListener('keydown', this.handleKeyDown);
            document.addEventListener('keyup', this.handleKeyUp);
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { projectCollaborators, logged, projectActions } = this.props;

        if (prevProps.projectActions !== projectActions) this.fieldList.projectActions = this.props.projectActions;

        if (!logged && this.props.project && !this.props.project.isPublic) {
            this.props.setProject(null);
            this.props.changeActiveItem('home');
            this.props.setRedirectURL(window.location.pathname);
            this.props.history.push('/login');
        }

        if (this.state.projectToEdit?.id && JSON.stringify(prevProps.projects) !== JSON.stringify(this.props.projects)) {
            const project = ProjectsUtil.getBaseProject(this.state.projectToEdit.id, this.props.projects);
            if (project) this.setState({ projectToEdit: project });
        } else if (this.state.projectToDuplicate?.id && JSON.stringify(prevProps.projects) !== JSON.stringify(this.props.projects)) {
            const project = ProjectsUtil.getBaseProject(this.state.projectToDuplicate.id, this.props.projects);
            if (project) this.setState({ projectToDuplicate: project });
        }

        if (prevProps.project && this.props.project && prevProps.project.id === this.props.project.id && JSON.stringify(prevProps.project) !== JSON.stringify(this.props.project)) {
            if (prevProps.project.surroundings !== this.props.project.surroundings) {
                const rawSurroundings = JSON.parse(this.props.project.surroundings);
                const surroundingsPolygon = rawSurroundings.geometry.type === 'Polygon'
                    ? polygon(rawSurroundings.geometry.coordinates)
                    : multiPolygon(rawSurroundings.geometry.coordinates);
                const boundsPolygon = bbox(surroundingsPolygon);
                const scaledCoordinates = GeometriesUtil.getScaledBbox(boundsPolygon, this.map.getBounds());
                this.maxBounds = L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(scaledCoordinates)).getBounds();
                this.map.setMaxBounds(this.maxBounds);
                this.surroundingsLayer.setLatLngs(GeometriesUtil.convertPolygonCoordinatesToLatLngs(GeometriesUtil.invertPolygon(surroundingsPolygon).geometry.coordinates));
            }

            if (!this.props.project.isPublic && !ProjectsUtil.isUserInProject(this.props.userProjects)) {
                this.props.setProject(null);
                this.props.changeActiveItem('home');
                this.props.history.push('/');
            }
        }
        if ((prevProps.project?.themeId !== this.props.project?.themeId || prevState.modal.visible !== this.state.modal.visible)
            && ![i18n.t("Mes projets"), i18n.t("Paramètres du projet")].includes(this.state.modal.title) && this.state.subscription.branding)
            ThemesUtil.applyTheme(this.props.project.theme);
        if (this.surroundingsLayer && prevProps.isDarkTheme !== this.props.isDarkTheme) {
            const favoriteTiles = UrlsUtil.getSearchParams(this.originalSearch).baseLayer || localStorage.getItem('favoriteTiles');
            this.surroundingsLayer.setStyle(this.props.isDarkTheme
                ? StylesUtil.getSurroundingsDarkStyle(favoriteTiles === i18n.t("Plan") && { fillOpacity: 0.2, opacity: 0.2 })
                : StylesUtil.getSurroundingsLightStyle(favoriteTiles === i18n.t("Plan") && { fillOpacity: 0.2, opacity: 0.2 }));
            if (favoriteTiles === 'Plan') this.planLayer.redraw();

            // Mise à jour du layer "Routes"
            const areRoadsVisible = this.map.hasLayer(this.googleLayerRoads);
            if (areRoadsVisible) this.map.removeLayer(this.googleLayerRoads);
            this.googleLayerRoads = L.gridLayer.googleMutant({
                maxZoom: 22,
                type: 'roadmap',
                styles: this.props.isDarkTheme ? googleRoadsDark : googleRoads,
                pane: 'overlayPane'
            });
            this.updateOverlayInControlLayer(i18n.t("Routes"), this.googleLayerRoads);
            if (areRoadsVisible) this.map.addLayer(this.googleLayerRoads);
        }

        if (this.props.projectListVisible && ![
            'ProjectList', 'ProjectModificationForm', 'ProjectDuplicationForm', 'ProjectCreationForm', 'CollaboratorsForm', 'RoleList', 'ImportForm', 'ExportForm', 'ProjectFilesGallery'
        ].includes(this.state.modalContentType)) {
            this.changeModalContentType('ProjectList', i18n.t("Mes projets"));
        } else if (this.props.project && !this.state.subscription) {
            // Récupération de la subscription du owner du projet
            const subscription = this.props.project?.organization.subscription;
            this.setState({ subscription });
        }

        if (this.props.project && JSON.stringify(prevProps.projectCollaborators) !== JSON.stringify(this.props.projectCollaborators)) {
            const token = new Cookies().get('token');
            const id = token ? jwtDecode(token).id : null;
            const index = projectCollaborators.findIndex(up => up.userId === id);
            if (index !== -1) {
                const projectRole = projectCollaborators[index].projectRole;
                this.props.setRights(RightsUtil.getRights(projectRole, this.props.loginAsData?.readOnly || this.props.currentTools === 'timeline' || RightsUtil.isRestricted(this.props.activeOrganization?.subscription, projectRole))).then(() => {
                    if (!this.isOpeningProject) {
                        const isGeographicallyLimited = (projectRole.projectRoleStations?.length || projectRole.areas?.length) ? true : false;
                        this.setMapSurroundings({ stations: this.stationsLayer.getLayers().map(l => l.feature), isGeographicallyLimited });
                    }
                });
            } else this.props.setRights(RightsUtil.getRights());
        }

        if (!prevProps.formulasResults.length && this.props.formulasResults.length) {
            const treesFormulasResults = this.props.formulasResults.filter(x => x.type === 'Arbre');
            const greenSpacesFormulasResults = this.props.formulasResults.filter(x => x.type === 'Espace vert');

            if (treesFormulasResults.length) {
                const trees = this.treesLayerNotClustered.getLayers();
                const treesNotClustered = this.treesLayerNotClustered.getLayers();
                treesFormulasResults.forEach(formulaResults => {
                    let layer = trees.find(l => l.feature.id === formulaResults.id);
                    let layerNotClustered = treesNotClustered.find(l => l.feature.id === formulaResults.id);
                    if (layer) {
                        layer.feature.properties.carbonStock = formulaResults.carbonStock;
                        layer.feature.properties.coolingIndicator = formulaResults.coolingIndicator;
                        layer.feature.properties.biodiversityIndex = formulaResults.biodiversityIndex;
                        layer.feature.properties.amenityValue = formulaResults.amenityValue;
                    }
                    if (layerNotClustered) {
                        layerNotClustered.feature.properties.carbonStock = formulaResults.carbonStock;
                        layerNotClustered.feature.properties.coolingIndicator = formulaResults.coolingIndicator;
                        layerNotClustered.feature.properties.biodiversityIndex = formulaResults.biodiversityIndex;
                        layerNotClustered.feature.properties.amenityValue = formulaResults.amenityValue;
                    }
                });
            }

            if (greenSpacesFormulasResults.length) {
                const greenSpaces = this.greenSpacesLayer.getLayers();
                greenSpacesFormulasResults.forEach(formulaResults => {
                    let layer = greenSpaces.find(l => l.feature.id === formulaResults.id);
                    if (layer) layer.feature.properties.carbonStock = formulaResults.carbonStock;
                });
            }

            this.updateHeatmaps({ updateBaseRadius: true });
            this.props.setFormulasResults([]);
        }

        if (this.props.projectShortcut && !prevProps.projectShortcut) {
            const { formType, title, project } = this.props.projectShortcut;
            project.userBaseProjects = project.userBaseProjects?.filter(ubp => ubp.baseProjectId === project.id || project.path.includes(ubp.baseProjectId)) || [];

            if (['CollaboratorsForm', 'RoleList', 'ProjectFilesGallery', 'ProjectModificationForm'].includes(formType)) {
                if (formType === 'CollaboratorsForm') this.props.setProjectInvitations(project.invitations);
                this.setState({ isLoading: false }, () => {
                    this.props.setUserProjects(this.props.projectCollaborators).then(() => {
                        this.showForm(project, formType, title)
                    });
                });
            } else {
                this.props.setUserProjects(this.props.projectCollaborators).then(() =>
                    this.showForm(project, formType, title)
                );
            }

            this.props.setProjectShortcut(null);
        }

        if (prevState.overlays !== this.state.overlays) {
            const overlaysParam = UrlsUtil.getOverlaysParam(this.state.overlays);
            if (overlaysParam) {
                this.mapSearch = UrlsUtil.adjustSearch(this.mapSearch, { overlays: overlaysParam });
                if (!this.props.match.params.path1) this.props.history.push({ search: this.mapSearch.short });
            }
        }
    }

    componentWillUnmount = () => {
        if (!isMobile) {
            document.removeEventListener('keydown', this.handleKeyDown);
            document.removeEventListener('keyup', this.handleKeyUp);
        }

        ThemesUtil.applyTheme(null);
        this.props.setLockedElements([]);
        this.props.setCurrentFolderState(null);
    }

    setMapSurroundings = ({ stations, favoriteTiles, isGeographicallyLimited, restoreLastBounds } = {}) => {
        if (this.surroundingsLayer && this.map.hasLayer(this.surroundingsLayer)) this.map.removeLayer(this.surroundingsLayer);
        const rawSurroundings = isGeographicallyLimited
            ? multiPolygon([
                ...(this.props.rights?.projectRoleStations || []).map(prs => stations.find(station => station.id === prs.stationId)?.geometry.coordinates),
                ...(this.props.rights?.areas || [])
            ])
            : JSON.parse(this.props.project.surroundings);
        const surroundingsPolygon = rawSurroundings.geometry.type === 'Polygon'
            ? polygon(rawSurroundings.geometry.coordinates)
            : multiPolygon(rawSurroundings.geometry.coordinates);
        this.setExternalLayers(surroundingsPolygon);
        const boundsPolygon = bbox(surroundingsPolygon);
        //! Obligé de faire ce process une première fois pour pas que le fitBounds crash
        let scaledCoordinates = GeometriesUtil.getScaledBbox(boundsPolygon);
        this.maxBounds = L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(scaledCoordinates)).getBounds();

        if (restoreLastBounds) {
            const { x, y, zoom } = UrlsUtil.getSearchParams(this.originalSearch);
            const lastPosition = x && y ? { lat: y, lng: x } : OfflineUtil.getProjectViewValue(this.props.project.id, 'lastPosition');
            const lastZoom = zoom || OfflineUtil.getProjectViewValue(this.props.project.id, 'lastZoom');
            if (lastPosition) this.map.setView(lastPosition, lastZoom, { animate: false });
            else if (lastZoom) this.map.setZoom(lastZoom, { animate: false });
            else this.map.fitBounds(this.maxBounds);
        } else this.map.fitBounds(this.maxBounds);

        this.mapSearch = UrlsUtil.adjustSearch(this.mapSearch, { x: this.map.getCenter().lng, y: this.map.getCenter().lat, zoom: this.map.getZoom() });
        if (!this.props.match.params.path1) this.props.history.push({ search: this.mapSearch.short });

        scaledCoordinates = GeometriesUtil.getScaledBbox(boundsPolygon, this.map.getBounds()); //! Pour que le getBounds() fonctionne, il faut définir une vue intiale sur la map
        this.maxBounds = L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(scaledCoordinates)).getBounds();
        this.map.setMaxBounds(this.maxBounds);

        const reversedSurroundings = GeometriesUtil.invertPolygon(surroundingsPolygon).geometry.coordinates;
        const isArray = Array.isArray(reversedSurroundings[0][0][0]);
        this.surroundingsLayer = L.polygon(
            GeometriesUtil.convertPolygonCoordinatesToLatLngs(!isArray ? reversedSurroundings : reversedSurroundings[0]), {
            ...(this.props.isDarkTheme
                ? StylesUtil.getSurroundingsDarkStyle(favoriteTiles === i18n.t("Plan") && { fillOpacity: 0.2, opacity: 0.2 })
                : StylesUtil.getSurroundingsLightStyle(favoriteTiles === i18n.t("Plan") && { fillOpacity: 0.2, opacity: 0.2 })), pmIgnore: true, pane: 'surroundings'
        }).addTo(this.map);
        return surroundingsPolygon;
    }

    setExternalLayers = (surroundingsPolygon) => {
        ProjectsService.getMapAreas().then(mapAreas => {
            const projectsViewInCache = localStorage.getItem('projectsView');
            const projectsView = projectsViewInCache ? JSON.parse(projectsViewInCache) : [];
            const overlays = projectsView.find(view => view.id === this.props.project?.id)?.overlays ?? [];
            const favoriteTiles = UrlsUtil.getSearchParams(this.originalSearch).baseLayer || OfflineUtil.getProjectViewValue(this.props.project?.id, 'favoriteTiles');;
            let urlOverlays = UrlsUtil.getSearchParams(this.originalSearch).overlays;
            if (urlOverlays) urlOverlays = urlOverlays.split(',');

            const brusselsPolygon = mapAreas.find(municipality => municipality.properties.name === 'Bruxelles | Brussels (Région)');
            if (brusselsPolygon && booleanIntersects(surroundingsPolygon, brusselsPolygon)) {
                this.urbisLayer = L.tileLayer.wms('http://geoservices-urbis.irisnet.be/geoserver/ows?/', {
                    layers: 'urbisFR',
                    format: 'image/png',
                    transparent: true,
                    attribution: 'Realized by means of Brussels UrbIS © ®, <a href="http://cirb.brussels/" target="_blank">© CIRB-CIBG</a>',
                    maxZoom: 22
                });
                this.addBaseLayerToControlLayer('UrbIS', this.urbisLayer).then(() => { if (favoriteTiles === 'UrbIS') this.showBaseLayer(this.urbisLayer) });
            }

            const francePolygon = mapAreas.find(municipality => municipality.properties.name === 'France');
            if (francePolygon && booleanIntersects(surroundingsPolygon, francePolygon)) {
                this.ignLayerPlan = L.tileLayer('https://data.geopf.fr/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=normal&TILEMATRIXSET=PM_0_19&FORMAT=image/png&LAYER=GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}', {
                    attribution: 'IGN-F/Geoportail',
                    maxNativeZoom: 19,
                    maxZoom: 22
                });

                this.ignLayerOrtho = L.tileLayer('https://data.geopf.fr/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=normal&TILEMATRIXSET=PM&FORMAT=image/jpeg&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}', {
                    attribution: 'IGN-F/Geoportail',
                    maxNativeZoom: 19,
                    maxZoom: 22
                });

                this.addBaseLayerToControlLayer('IGN Plan', this.ignLayerPlan).then(() => { if (favoriteTiles === 'IGN Plan') this.showBaseLayer(this.ignLayerPlan) });
                this.addBaseLayerToControlLayer('IGN Ortho', this.ignLayerOrtho).then(() => { if (favoriteTiles === 'IGN Ortho') this.showBaseLayer(this.ignLayerOrtho) });
            }

            const belgiumPolygon = mapAreas.find(municipality => municipality.properties.name === 'Belgique');
            if (belgiumPolygon && booleanIntersects(surroundingsPolygon, belgiumPolygon)) {
                this.cadastreLayer = L.tileLayer.wms('http://ccff02.minfin.fgov.be/geoservices/arcgis/services/WMS/Cadastral_Layers/MapServer/WMSServer?', {
                    format: 'image/png',
                    layers: 'Cadastral_parcel,Cadastral_division',
                    pane: 'overlayPane',
                    transparent: true,
                    maxNativeZoom: 18,
                    maxZoom: 22
                });
                this.addOverlayToControlLayer(i18n.t("Parcelles cadastrales"), { originalLabel: 'Parcelles cadastrales', layer: this.cadastreLayer, showToggleLegend: false })
                    .then(() => {
                        if (urlOverlays
                            ? urlOverlays.find(overlay => overlay === 'Parcelles cadastrales')
                            : overlays.find(overlay => overlay.label === i18n.t("Parcelles cadastrales"))?.isShown)
                            this.toggleLayer({ layer: this.cadastreLayer })
                    });
            }

            const swissPolygon = mapAreas.find(municipality => municipality.properties.name === 'Suisse' && municipality.properties.isMapArea);
            if (swissPolygon && booleanIntersects(surroundingsPolygon, swissPolygon)) {
                this.swissLayer = L.tileLayer('https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg', {
                    pane: 'overlayPane',
                    transparent: true,
                    maxNativeZoom: 20,
                    maxZoom: 22
                });
                this.addBaseLayerToControlLayer(i18n.t("Swiss Map Geo"), this.swissLayer).then(() => { if (favoriteTiles === 'Swiss Map Geo') this.showBaseLayer(this.swissLayer) });
            }

            const projectView = projectsView ? projectsView.find(view => view.id === this.props.project.id) : null;
            this.loadWmsServices(favoriteTiles, projectView, urlOverlays);
            this.setState({ areAdditionalLayersLoaded: true });
        });
    }

    getShortcutHandlers = () => {
        const clickContextMenuButton = (e, buttonName, isAlwaysClickable = false) => {
            e.preventDefault();
            if (isAlwaysClickable || this.toolbarRef.current.isButtonClickable(buttonName))
                this.handleContextMenuButtonClick(buttonName);
        }

        return {
            addImage: (e) => clickContextMenuButton(e, 'addImage'),
            addBookmark: (e) => clickContextMenuButton(e, 'addBookmark', true),
            addTree: (e) => clickContextMenuButton(e, 'addTree'),
            dragTrees: (e) => clickContextMenuButton(e, 'dragTrees'),
            removeTrees: (e) => clickContextMenuButton(e, 'removeTrees'),
            addPolygon: (e) => clickContextMenuButton(e, 'addPolygon'),
            addLine: (e) => clickContextMenuButton(e, 'addLine'),
            addRectangle: (e) => clickContextMenuButton(e, 'addRectangle'),
            addCircle: (e) => clickContextMenuButton(e, 'addCircle'),
            dragGreenSpaces: (e) => clickContextMenuButton(e, 'dragGreenSpaces'),
            rotateGreenSpaces: (e) => clickContextMenuButton(e, 'rotateGreenSpaces'),
            mergeGreenSpaces: (e) => clickContextMenuButton(e, 'mergeGreenSpaces'),
            splitGreenSpaces: (e) => clickContextMenuButton(e, 'splitGreenSpaces'),
            subtractGreenSpaces: (e) => clickContextMenuButton(e, 'subtractGreenSpaces'),
            editGreenSpaces: (e) => clickContextMenuButton(e, 'editGreenSpaces'),
            cutGreenSpaces: (e) => clickContextMenuButton(e, 'cutGreenSpaces'),
            removeGreenSpaces: (e) => clickContextMenuButton(e, 'removeGreenSpaces'),
            addFurniture: (e) => clickContextMenuButton(e, 'addFurniture'),
            dragFurnitures: (e) => clickContextMenuButton(e, 'dragFurnitures'),
            removeFurnitures: (e) => clickContextMenuButton(e, 'removeFurnitures'),
            addMarker: (e) => clickContextMenuButton(e, 'addMarker'),
            dragMarkers: (e) => clickContextMenuButton(e, 'dragMarkers'),
            removeMarkers: (e) => clickContextMenuButton(e, 'removeMarkers'),
            dragStations: (e) => clickContextMenuButton(e, 'dragStations'),
            rotateStations: (e) => clickContextMenuButton(e, 'rotateStations'),
            editStations: (e) => clickContextMenuButton(e, 'editStations'),
            removeStations: (e) => clickContextMenuButton(e, 'removeStations'),
            deleteElements: (e) => {
                e.preventDefault();
                if (this.props.logged && this.state.selectedElements.length && !this.props.currentAction && this.props.project.type === 'project') {
                    if ([...new Set(
                        this.state.selectedElements.map(element => ({ 'Arbre': 'trees', 'Espace vert': 'greenSpaces', 'Mobilier': 'furnitures', 'Repère': 'markers', 'Station': 'stations' }[element.feature.properties.category]))
                    )].every(category => RightsUtil.canWrite(this.props.rights?.[category])))
                        this.setState({ removingWithShortcut: true }, () => this.showRemoveForm());
                }
            },
            copy: (e) => e.preventDefault(),
            paste: (e) => e.preventDefault(),
            quit: (e) => {
                e.preventDefault();
                const { index } = this.actionHistory;
                if (index < 1) this.disableDrawing();
            },
            undo: (e) => { e.preventDefault(); this.undoAction(); },
            redo: (e) => { e.preventDefault(); this.redoAction(); },
            save: (e) => { e.preventDefault(); this.confirmAction(); },
            history: (e) => clickContextMenuButton(e, 'history'),
            actions: (e) => clickContextMenuButton(e, 'actions'),
            events: (e) => clickContextMenuButton(e, 'events'),
            table: (e) => clickContextMenuButton(e, 'table'),
            filters: (e) => clickContextMenuButton(e, 'filters'),
            statistics: (e) => clickContextMenuButton(e, 'statistics'),
            printer: (e) => clickContextMenuButton(e, 'printer')
        };
    }

    handleKeyDown = (event) => {
        const { currentAction } = this.props;
        const lassoNotAllowed = ['cutting', 'rotating', 'editing', 'duplicating', 'splitting', 'merging', 'subtracting'];
        if (!['addingBackgroundImage', 'orderingBackgroundImages'].includes(currentAction)) {
            if ((event.ctrlKey || event.metaKey) && (!this.props.layer || ['managingActionElements', 'managingEventElements', 'linkingElements'].includes(this.props.currentAction))) {
                if (['Control', 'Meta'].includes(event.key)) {
                    if (currentAction === '' || (!currentAction.includes('adding') && !lassoNotAllowed.includes(currentAction))) {
                        this.lasso.enable();
                        this.props.setButtonState('lasso', 1);
                    } else if (!this.stickyRotation) this.stickyRotation = true;
                } else if (event.key === 'Shift' && (!currentAction.includes('adding') && !lassoNotAllowed.includes(currentAction))) {
                    if (!this.lasso.enabled()) this.lasso.enable();
                    this.shiftKey = true;
                }
            }
            else if (event.shiftKey) this.shiftKey = true;
        }
    }

    handleKeyUp = (event) => {
        if (!event.ctrlKey && !event.metaKey && ['Control', 'Meta'].includes(event.key)) {
            if (this.props.currentAction === '' || !this.props.currentAction.includes('adding')) {
                this.lasso.disable();
                this.props.setButtonState('lasso', 0);
            } else if (this.stickyRotation) this.stickyRotation = false;
        } else if (!event.shiftKey && event.key === 'Shift') this.shiftKey = false;
    }

    handleBackButtonClick = () => this.setState({ projectToEdit: null, projectToDuplicate: null }, () => this.changeModalContentType('ProjectList', i18n.t("Mes projets")));
    disableDrawing = (resetToolbarSelection = true) => {
        if (this.props.currentAction !== 'modifyingProjectSurroundings') {
            if (['addingRectangle', 'addingCircle'].includes(this.props.currentAction) && this.props.layer)
                this.props.layer.pm.disableRotate();
            this.exitSubTool(resetToolbarSelection);

            if (['merging', 'subtracting'].includes(this.props.currentAction) && this.props.layer) {
                const layer = this.props.layer;
                layer.setStyle(StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps));
                this.props.setLayer(null);
            } else if (this.props.currentAction === 'splitting') this.hideDrawLines();
            this.map.pm.setGlobalOptions({ allowSelfIntersection: true });
            if (!this.map.pm.globalCutModeEnabled()) this.map.pm.disableDraw();
            else {
                this.isEditingAfterToggle = true;
                this.map.pm.toggleGlobalCutMode();
                this.isEditingAfterToggle = false;
            }
            this.props.setCurrentAction('');
            if (this.rotatingLayer) {
                this.rotatingLayer.pm.disableRotate();
                this.rotatingLayer = null;
            }
            if (this.editingLayer) {
                this.editingLayer.pm.disable();
                this.editingLayer = null;
            }
            if (this.map.pm.globalDragModeEnabled()) this.map.pm.toggleGlobalDragMode();
        }
    }

    handleContextMenu = (event) => {
        let contextMenuElement;
        if (this.overedElement && (this.overedElement.feature.properties.category === 'Espace vert' || this.state.selectedElements.find(element => element.feature.id === this.overedElement.feature.id)))
            contextMenuElement = this.overedElement;
        contextMenu.show({ event, id: contextMenuElement ? 'element-context-menu' : 'map-context-menu' });
        this.setState(prevState => ({ contextMenuElement, rerenderContextMenu: !prevState.rerenderContextMenu }));
    }

    handleContextMenuButtonClick = (name) => {
        const { buttonStates } = this.props;
        const { contextMenuElement } = this.state;

        switch (name) {
            case 'bringToFront':
                if (contextMenuElement) {
                    contextMenuElement.bringToFront();
                    GreenSpacesService.updateZIndex(contextMenuElement.feature.id, true);
                }
                break;
            case 'bringToBack':
                if (contextMenuElement) {
                    contextMenuElement.bringToBack();
                    GreenSpacesService.updateZIndex(contextMenuElement.feature.id, false);
                }
                break;
            case 'modifyProperties':
                if (contextMenuElement)
                    this.toolbarRef.current.clickButton(
                        { 'Arbre': 'modifyTrees', 'Espace vert': 'modifyGreenSpaces', 'Mobilier': 'modifyFurnitures' }[contextMenuElement.feature.properties.category]
                    );
                break;
            case 'addTree': this.toolbarRef.current.clickButton('addTree'); break;
            case 'dragTrees': this.toolbarRef.current.clickButton('dragTrees'); break;
            case 'removeTrees': this.toolbarRef.current.clickButton('removeTrees'); break;
            case 'addPolygon': this.toolbarRef.current.clickButton('addGreenSpace'); break;
            case 'addLine': this.toolbarRef.current.clickButton('addLine'); break;
            case 'addRectangle': this.toolbarRef.current.clickButton('addRectangle'); break;
            case 'addCircle': this.toolbarRef.current.clickButton('addCircle'); break;
            case 'dragGreenSpaces': this.toolbarRef.current.clickButton('dragGreenSpaces'); break;
            case 'rotateGreenSpaces': this.toolbarRef.current.clickButton('rotateGreenSpaces'); break;
            case 'splitGreenSpaces': this.toolbarRef.current.clickButton('splitGreenSpaces'); break;
            case 'mergeGreenSpaces': this.toolbarRef.current.clickButton('mergeGreenSpaces'); break;
            case 'subtractGreenSpaces': this.toolbarRef.current.clickButton('subtractGreenSpaces'); break;
            case 'editGreenSpaces': this.toolbarRef.current.clickButton('editGreenSpaces'); break;
            case 'cutGreenSpaces': this.toolbarRef.current.clickButton('cutGreenSpaces'); break;
            case 'removeGreenSpaces': this.toolbarRef.current.clickButton('removeGreenSpaces'); break;
            case 'addFurniture': this.toolbarRef.current.clickButton('addFurniture'); break;
            case 'dragFurnitures': this.toolbarRef.current.clickButton('dragFurnitures'); break;
            case 'removeFurnitures': this.toolbarRef.current.clickButton('removeFurnitures'); break;
            case 'addMarkers': this.toolbarRef.current.clickButton('addMarkers'); break;
            case 'dragMarkerss': this.toolbarRef.current.clickButton('dragMarkerss'); break;
            case 'removeMarkerss': this.toolbarRef.current.clickButton('removeMarkerss'); break;
            case 'dragStations': this.toolbarRef.current.clickButton('dragStations'); break;
            case 'rotateStations': this.toolbarRef.current.clickButton('rotateStations'); break;
            case 'editStations': this.toolbarRef.current.clickButton('editStations'); break;
            case 'removeStations': this.toolbarRef.current.clickButton('removeStations'); break;
            case 'addImage': this.toolbarRef.current.clickButton('addImage'); break;
            case 'addBookmark': this.map.fire('bookmark:new', { latlng: this.map.getCenter() }); break;
            case 'paste': break;
            case 'googleRoadmap': this.showBaseLayer(this.googleLayerRoadmap); break;
            case 'googleRoadmapDark': this.showBaseLayer(this.googleLayerRoadmapDark); break;
            case 'googleHybrid': this.showBaseLayer(this.googleLayerHybrid); break;
            case 'OSM': this.showBaseLayer(this.OSMLayer); break;
            case 'plan': this.showBaseLayer(this.planLayer); break;
            case 'urbis': this.showBaseLayer(this.urbisLayer); break;
            case 'ignPlan': this.showBaseLayer(this.ignLayerPlan); break;
            case 'ignOrtho': this.showBaseLayer(this.ignLayerOrtho); break;
            case 'noTreeLayer': this.toggleLayer({ parentLayerLabel: i18n.t("Arbres"), layerLabel: i18n.t("Aucun") }); break;
            case 'healthReviews': this.toggleLayer({ parentLayerLabel: i18n.t("Arbres"), layerLabel: i18n.t("Cotes sanitaires") }); break;
            case 'vigors': this.toggleLayer({ parentLayerLabel: i18n.t("Arbres"), layerLabel: i18n.t("Vigueurs") }); break;
            case 'risks': this.toggleLayer({ parentLayerLabel: i18n.t("Arbres"), layerLabel: i18n.t("Risques") }); break;
            case 'ontogenicStages': this.toggleLayer({ parentLayerLabel: i18n.t("Arbres"), layerLabel: i18n.t("Stades ontogéniques") }); break;
            case 'crownDiameters': this.toggleLayer({ parentLayerLabel: i18n.t("Arbres"), layerLabel: i18n.t("Diamètre des couronnes") }); break;
            case 'ages': this.toggleLayer({ parentLayerLabel: i18n.t("Arbres"), layerLabel: i18n.t("Âges") }); break;
            case 'toCutDown': this.toggleLayer({ parentLayerLabel: i18n.t("Arbres"), layerLabel: i18n.t("Abattages") }); break;
            case 'noGreenSpaceLayer': this.toggleLayer({ parentLayerLabel: i18n.t("Espaces verts"), layerLabel: i18n.t("Aucun") }); break;
            case 'dominantCompositions': this.toggleLayer({ parentLayerLabel: i18n.t("Espaces verts"), layerLabel: i18n.t("Compositions dominantes") }); break;
            case 'noFurnitureLayer': this.toggleLayer({ parentLayerLabel: i18n.t("Mobilier urbain"), layerLabel: i18n.t("Aucun") }); break;
            case 'stationsLayer': this.toggleLayer({ layerLabel: i18n.t("Stations") }); break;
            case 'treesActionsUrgency': this.toggleLayer({ parentLayerLabel: i18n.t("Arbres"), layerLabel: i18n.t("Actions") }); break;
            case 'greenSpacesActionsUrgency': this.toggleLayer({ parentLayerLabel: i18n.t("Espaces verts"), layerLabel: i18n.t("Actions") }); break;
            case 'furnituresActionsUrgency': this.toggleLayer({ parentLayerLabel: i18n.t("Mobilier urbain"), layerLabel: i18n.t("Actions") }); break;
            case 'carbonStock': this.toggleLayer({ parentLayerLabel: i18n.t("Services écosystémiques"), layerLabel: i18n.t("Stock carbone") }); break;
            case 'cooling': this.toggleLayer({ parentLayerLabel: i18n.t("Services écosystémiques"), layerLabel: i18n.t("Rafraîchissement") }); break;
            case 'birds': this.toggleLayer({ parentLayerLabel: i18n.t("Natura 2000"), layerLabel: i18n.t("Oiseaux") }); break;
            case 'habitats': this.toggleLayer({ parentLayerLabel: i18n.t("Natura 2000"), layerLabel: i18n.t("Habitats") }); break;
            case 'cadastre': this.toggleLayer({ layerLabel: i18n.t("Parcelles cadastrales") }); break;
            case 'noReferenceTrees': this.renderMarkersReferences('Arbre', null, true); break;
            case 'projectReferenceTrees': this.renderMarkersReferences('Arbre', 'projectReference', true); break;
            case 'customReferenceTrees': this.renderMarkersReferences('Arbre', 'customReference', true); break;
            case 'noReferenceFurnitures': this.renderMarkersReferences('Mobilier', null, true); break;
            case 'projectReferenceFurnitures': this.renderMarkersReferences('Mobilier', 'projectReference', true); break;
            case 'customReferenceFurnitures': this.renderMarkersReferences('Mobilier', 'customReference', true); break;
            case 'noReferenceMarkers': this.renderMarkersReferences('Repère', null, true); break;
            case 'projectReferenceMarkers': this.renderMarkersReferences('Repère', 'projectReference', true); break;
            case 'customReferenceMarkers': this.renderMarkersReferences('Repère', 'customReference', true); break;
            case 'noReferenceGreenSpaces': this.renderGreenSpacesReferences(null, true); break;
            case 'projectReferenceGreenSpaces': this.renderGreenSpacesReferences('projectReference', true); break;
            case 'customReferenceGreenSpaces': this.renderGreenSpacesReferences('customReference', true); break;
            case 'legend': this.handleToolbarButtonClick('legend', buttonStates.legend); break;
            case 'fullscreen': this.handleToolbarButtonClick('fullscreen', buttonStates.fullscreen); break;
            case 'lasso': this.handleToolbarButtonClick('lasso', buttonStates.lasso); break;
            case 'geolocation': this.handleToolbarButtonClick('geolocation', buttonStates.geolocation); break;
            case 'filters': this.handleToolbarButtonClick('filters', buttonStates.filters); break;
            case 'table': this.handleToolbarButtonClick('table', buttonStates.table); break;
            case 'statistics': this.handleToolbarButtonClick('statistics', buttonStates.statistics); break;
            case 'printer': this.handleToolbarButtonClick('printer', buttonStates.printer); break;
            case 'actions': this.handleToolbarButtonClick('actions', buttonStates.actions); break;
            case 'events': this.handleToolbarButtonClick('events', buttonStates.events); break;
            case 'history': this.handleToolbarButtonClick('history', buttonStates.history); break;
            default: break;
        }
    }

    handleToolbarButtonClick = (name, state) => {
        const { currentTools, currentElementTools } = this.state;

        switch (name) {
            case 'adminTools': case 'basicTools': case 'advancedTools':
                if (currentTools !== name) this.setState({ currentTools: name });
                else this.setState({ currentTools: null });
                break;
            case 'treeTools': case 'greenSpaceTools': case 'furnitureTools': case 'markerTools': case 'stationTools': case 'imageTools':
                if (currentTools !== name) {
                    if (currentElementTools !== name) {
                        this.resetActionHistory(true);
                        this.disableDrawing();
                    }
                    this.setState({ currentTools: name, currentElementTools: name });
                } else {
                    this.resetActionHistory(true);
                    this.disableDrawing();
                    this.setState({ currentTools: null });
                }
                if (name !== 'imageTools' || this.props.currentAction === '') this.toggleBackgroundImages(false);
                break;
            case 'printPdfs': case 'updateReferences':
                this.setState({
                    adminTask: name,
                    modal: { visible: false, title: '' },
                    modalContentType: 'SelectElementTypeForm'
                });
                break;
            case 'zoomIn': this.map.zoomIn(); break;
            case 'zoomOut': this.map.zoomOut(); break;
            case 'lasso':
                switch (state) {
                    case 0:
                        this.lasso.enable();
                        this.props.setButtonState('lasso', 1);
                        break;
                    case 1:
                        this.lasso.disable();
                        this.props.setButtonState('lasso', 0);
                        break;
                    default: break;
                }
                break;
            case 'searchLocation':
                switch (state) {
                    case 0: this.setState({ showGeosearch: true }); break;
                    case 1: this.setState({ showGeosearch: false }); break;
                    default: break;
                }
                break;
            case 'fullscreen':
                if (screenfull.isEnabled) {
                    switch (state) {
                        case 0:
                            screenfull.request();
                            this.props.setButtonState('fullscreen', 1);
                            break;
                        case 1:
                            screenfull.exit();
                            this.props.setButtonState('fullscreen', 0);
                            break;
                        default: break;
                    }
                }
                break;
            case 'projectLabels':
                switch (state) {
                    case 0:
                        this.props.setButtonState('projectLabels', 1);
                        break;
                    case 1:
                        this.props.setButtonState('projectLabels', 0);
                        break;
                    default: break;
                }

                this.toggleProjectLabels();
                break;
            case 'references':
                if (this.state.modalContentType !== 'ReferencesForm') {
                    this.setState({
                        modal: { visible: false, title: '' },
                        modalContentType: 'ReferencesForm'
                    });
                } else this.hideForm();
                break;
            case 'legend':
                switch (state) {
                    case 0:
                        this.legendRef.current.toggleLegendVisibility(false);
                        this.props.setButtonState('legend', 1);
                        break;
                    case 1:
                        this.legendRef.current.toggleLegendVisibility(true);
                        this.props.setButtonState('legend', 0);
                        break;
                    default: break;
                }
                break;
            case 'geolocation':
                switch (state) {
                    case 0:
                        this.locateControl.start();
                        this.props.setButtonState('geolocation', 1);
                        break;
                    case 1:
                        this.locateControl.stop();
                        this.props.setButtonState('geolocation', 0);
                        break;
                    default: break;
                }
                break;
            case 'table':
                this.exitSubTool(true);
                if (this.state.highlightedElements) { // Si l'option de surbrillance est déjà activée
                    this.state.highlightedElements.forEach(layer => {
                        let style;
                        switch (layer.feature.properties.category) {
                            case 'Arbre': style = StylesUtil.getTreeActiveStyle(this.treesLayer, this.fieldList, layer.feature.properties, this.props.project.thematicMaps); break;
                            case 'Espace vert': style = StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps); break;
                            case 'Mobilier': style = StylesUtil.getFurnitureActiveStyle(this.furnituresLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps); break;
                            default: break;
                        }
                        layer.setStyle(style); // On rétablit son style
                    });

                    this.viewState = false;
                    this.toolbarRef.current.setButtonsIsDisabled(['statistics', 'filters', 'actions', 'events', 'history'], false);

                    this.props.setButtonState('table', 0);
                    this.setState({ highlightedElements: null }, () => {
                        if (!['managingActionElements', 'managingEventElements'].includes(this.props.currentAction)) this.disableDrawing();
                        this.changeModalContentType(
                            this.state.highlightedElementsCategory === 'Arbres' ? 'TreeTable'
                                : this.state.highlightedElementsCategory === 'Espaces verts' ? 'GreenSpaceTable'
                                    : 'FurnitureTable',
                            i18n.t("Tableau de données")
                        );
                        this.setState({ highlightedElementsCategory: null });
                    });
                } else {
                    if (this.treesLayer.getLayers().length > 0 || (this.greenSpacesLayer.getLayers().length < 1 && this.furnituresLayer.getLayers().length < 1))
                        this.changeModalContentType('TreeTable', i18n.t("Tableau de données")); // Sinon on affiche le tableau
                    else if (this.greenSpacesLayer.getLayers().length > 0 || this.furnituresLayer.getLayers().length < 1)
                        this.changeModalContentType('GreenSpaceTable', i18n.t("Tableau de données"));
                    else this.changeModalContentType('FurnitureTable', i18n.t("Tableau de données"));
                }
                break;
            case 'statistics':
                if (this.state.highlightedElements) { // Si l'option de surbrillance est déjà activée
                    this.state.highlightedElements.forEach(layer => {
                        let style;
                        switch (layer.feature.properties.category) {
                            case 'Arbre': style = StylesUtil.getTreeActiveStyle(this.treesLayer, this.fieldList, layer.feature.properties, this.props.project.thematicMaps); break;
                            case 'Espace vert': style = StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps); break;
                            case 'Mobilier': style = StylesUtil.getFurnitureActiveStyle(this.furnituresLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps); break;
                            default: break;
                        }
                        layer.setStyle(style); // On rétablit son style
                    });

                    this.toolbarRef.current.setButtonsIsDisabled(['table', 'filters', 'actions', 'events', 'history'], false);

                    this.props.setButtonState('statistics', 0);
                    this.setState({ highlightedElements: null });
                    if (!['managingActionElements', 'managingEventElements'].includes(this.props.currentAction)) this.disableDrawing();
                    this.changeModalContentType('ProjectDetail', i18n.t("Statistiques"));
                } else {
                    if (this.publicFields.main.statistics) this.changeModalContentType('ProjectDetail', i18n.t("Statistiques")); // Sinon on affiche les statistiques
                    else this.changeModalContentType('GendersChart', i18n.t("Graphique des genres")); // Sinon on affiche les statistiques
                }
                break;
            case 'filters': this.changeModalContentType('FilterForm', i18n.t("Filtres")); break;
            case 'timeline':
                const token = new Cookies().get('token');
                const id = token ? jwtDecode(token).id : null;
                const projectCollaborator = this.props.projectCollaborators.find(up => up.user.id === id);

                switch (state) {
                    case 0:
                        this.props.setRights(RightsUtil.getRights(projectCollaborator.projectRole, true));
                        this.props.setButtonState('timeline', 1);
                        break;
                    case 1:
                        this.disableDrawing(false);
                        this.props.setRights(RightsUtil.getRights(projectCollaborator.projectRole, this.props.loginAsData?.readOnly || RightsUtil.isRestricted(this.props.activeOrganization?.subscription, projectCollaborator.projectRole)));
                        this.props.setButtonState('timeline', 0);
                        break;
                    default: break;
                }

                this.setState(prevState => ({ isTimelineVisible: !prevState.isTimelineVisible }));
                break;
            case 'printer':
                if (this.state.modalContentType !== 'ScreenshotForm') {
                    this.disableDrawing(false);
                    this.setState({
                        currentElementTools: null,
                        modal: { visible: false, title: '' },
                        modalContentType: 'ScreenshotForm'
                    });
                } else this.hideForm(false);
                break;
            case 'actions':
                if (this.props.currentAction === 'managingActionElements') { // Si on sort du mode 'liaison'
                    const projectAction = this.props.editedProperties.newProjectAction || this.props.editedProperties.projectAction;
                    const updateToCutDown = (properties) => {
                        if (properties.tmpToCutDown) {
                            if (properties.tmpToCutDown === -1 && properties.toCutDown === projectAction.action.id)
                                delete properties.toCutDown;
                            else if (!properties.toCutDown || properties.toCutDown < properties.tmpToCutDown)
                                properties.toCutDown = properties.tmpToCutDown;
                            delete properties.tmpToCutDown;
                        }
                    };

                    if (this.props.layer)
                        this.props.layer.forEach(layer => {
                            let style;
                            switch (layer.feature.properties.category) {
                                case 'Arbre':
                                    updateToCutDown(layer.feature.properties);
                                    style = StylesUtil.getTreeActiveStyle(this.treesLayer, this.fieldList, layer.feature.properties, this.props.project.thematicMaps);
                                    break
                                case 'Espace vert': style = StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps); break;
                                case 'Mobilier': style = StylesUtil.getFurnitureActiveStyle(this.furnituresLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps); break;
                                default: break;
                            }
                            layer.setStyle(style); // On rétablit son style
                        });

                    const projectActions = this.props.projectActions.filter(pa => pa.id !== projectAction.id);
                    this.initialLinkedElements.forEach(layer => {
                        if (!this.props.layer.includes(layer)) {
                            layer.feature.properties.actionsUrgency = ActionsUtil.getElementActionsUrgency(
                                layer.feature.id, projectActions, layer.feature.properties.category
                            );
                            if (this.actionLinkingCategories.includes('Arbre') && projectAction.action.id < 4)
                                updateToCutDown(layer.feature.properties);
                        }
                    });

                    if (this.actionLinkingCategories.includes('Arbre') && projectAction.action.id < 4 && this.treesLayer.activeChild === 'Abattages')
                        this.updateTreesLayerStyle();

                    this.initialLinkedElements = null;

                    this.props.setCurrentAction('');
                    showToast('link_mode_deactivated');
                    this.toolbarRef.current.setButtonsIsDisabled(['treeTools', 'greenSpaceTools', 'furnitureTools', 'markerTools', 'stationTools', 'imageTools', 'statistics', 'table', 'events'], false);

                    // On réaffiche le treesLayer avec clustering
                    this.updateOverlayInControlLayer(i18n.t("Arbres"), this.treesLayer);
                    this.updateOverlayInControlLayer(i18n.t("Mobilier urbain"), this.furnituresLayer);
                    this.setState({ highlightedElements: null });
                } else this.disableDrawing(); // Si on entre dans le mode 'liaison'

                this.toolbarRef.current.setButtonsIsDisabled(['table', 'filters', 'statistics', 'history', 'events'], false);
                this.props.setButtonState('actions', 0);

                this.unselectElements();
                this.changeModalContentType('ActionTable', i18n.t("Actions")); // On affiche la liste d'actions
                break;
            case 'events':
                if (this.props.currentAction === 'managingEventElements') { // Si on sort du mode 'liaison'
                    if (this.props.layer)
                        this.props.layer.forEach(layer => {
                            let style;
                            switch (layer.feature.properties.category) {
                                case 'Arbre':
                                    style = StylesUtil.getTreeActiveStyle(this.treesLayer, this.fieldList, layer.feature.properties, this.props.project.thematicMaps);
                                    break
                                case 'Espace vert': style = StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps); break;
                                case 'Mobilier': style = StylesUtil.getFurnitureActiveStyle(this.furnituresLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps); break;
                                default: break;
                            }
                            layer.setStyle(style); // On rétablit son style
                        });

                    this.initialLinkedElements = null;

                    this.props.setCurrentAction('');
                    showToast('link_mode_deactivated');
                    this.toolbarRef.current.setButtonsIsDisabled(['treeTools', 'greenSpaceTools', 'furnitureTools', 'markerTools', 'stationTools', 'imageTools', 'statistics', 'table', 'actions'], false);

                    // On réaffiche le treesLayer avec clustering
                    this.updateOverlayInControlLayer(i18n.t("Arbres"), this.treesLayer);
                    this.updateOverlayInControlLayer(i18n.t("Mobilier urbain"), this.furnituresLayer);
                    this.setState({ highlightedElements: null });
                } else this.disableDrawing(); // Si on entre dans le mode 'liaison'

                this.toolbarRef.current.setButtonsIsDisabled(['table', 'filters', 'statistics', 'history', 'actions'], false);
                this.props.setButtonState('events', 0);

                this.unselectElements();
                this.changeModalContentType('EventTable', i18n.t("Évènements")); // On affiche la liste d'actions
                break;
            case 'history':
                this.exitSubTool(true);
                if (this.state.highlightedElements) { // Si l'option de surbrillance est déjà activée
                    this.state.highlightedElements.forEach(layer => {
                        let style;
                        switch (layer.feature.properties.category) {
                            case 'Arbre': style = StylesUtil.getTreeActiveStyle(this.treesLayer, this.fieldList, layer.feature.properties, this.props.project.thematicMaps); break;
                            case 'Espace vert': style = StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps); break;
                            case 'Mobilier': style = StylesUtil.getFurnitureActiveStyle(this.furnituresLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps); break;
                            default: break;
                        }
                        layer.setStyle(style); // On rétablit son style
                    });

                    this.viewState = false;
                    this.toolbarRef.current.setButtonsIsDisabled(['statistics', 'filters', 'actions', 'events', 'table'], false);

                    this.props.setButtonState('history', 0);
                    this.setState({ highlightedElements: null }, () => {
                        if (!['managingActionElements', 'managingEventElements'].includes(this.props.currentAction)) this.disableDrawing();
                        this.changeModalContentType('ProjectHistory', i18n.t("Historique du projet"));
                        this.setState({ highlightedElementsCategory: null });
                    });
                } else if (this.props.currentAction === 'elementsSelection') {
                    this.viewState = false;
                    this.toolbarRef.current.setButtonsIsDisabled(['statistics', 'filters', 'actions', 'events', 'table'], false);

                    this.props.setCurrentAction('');
                    this.props.setButtonState('history', 0);

                    this.props.setTableState({ ...this.props.tableState, selectedElements: [...this.state.selectedElements] }).then(() => {
                        this.changeModalContentType('ProjectHistory', i18n.t("Historique du projet"));
                        this.unselectElements(this.state.selectedElements);
                    });
                } else this.changeModalContentType('ProjectHistory', i18n.t("Historique du projet"));
                break
            case 'addTree': case 'addFurniture': case 'addMarker':
                const type = name.replace('add', '');
                if (this.props.currentAction !== `adding${type}`) {
                    this.props.setCurrentAction(`adding${type}`);
                    this.bringPaneToFront(name);
                    this.map.pm.enableDraw('Marker');
                } else {
                    this.props.setCurrentAction('');
                    this.map.pm.disableDraw();
                }
                break;
            case 'addTreeWithGps': case 'addFurnitureWithGps': case 'addMarkerWithGps':
                this.addWithGps(name.replace('add', '').replace('WithGps', '')); break;
            case 'addTreesWithAI':
                if (this.state.modalContentType !== 'ScanForm') {
                    if (!localStorage.getItem('aiWarningClosed')) this.props.showAIWarning(true);
                    this.disableDrawing(false);
                    this.setState({
                        modal: { visible: false, title: '' },
                        modalContentType: 'ScanForm'
                    });
                } else this.hideForm(false);
                break;
            case 'modifyTrees': case 'modifyGreenSpaces': case 'modifyFurnitures':
                const categories = {
                    'modifyTrees': { category: 'Arbre', toast: 'select_trees', getElements: (selectedElements) => selectedElements.map(element => this.linkedTrees.find(layers => layers[0].feature.id === element.feature.id)) },
                    'modifyGreenSpaces': { category: 'Espace vert', toast: 'select_greenSpaces', getElements: (selectedElements) => selectedElements.map(element => [element]) },
                    'modifyFurnitures': { category: 'Mobilier', toast: 'select_furnitures', getElements: (selectedElements) => selectedElements.map(element => this.linkedFurnitures.find(layers => layers[0].feature.id === element.feature.id)) }
                };
                const { category: elementCategory, toast, getElements } = categories[name];
                const selectedElements = this.state.selectedElements?.filter(element => element.feature.properties.category === elementCategory);
                if (!selectedElements?.length) { showToast(toast); return; }
                this.setState({ elementsToModify: getElements(selectedElements) }, () => {
                    this.changeModalContentType('PropertiesModificationForm', i18n.t("Modification de propriétés"));
                });
                break;
            case 'copyTrees': case 'copyGreenSpaces': case 'copyFurnitures':
                if (!['managingActionElements', 'managingEventElements'].includes(this.props.currentAction)) {
                    if (!['copyPasting', 'duplicating'].includes(this.props.currentAction)) {
                        if (['copyTrees', 'copyFurnitures'].includes(name)) this.showClusteredLayer(name === 'copyTrees' ? 'Arbre' : 'Mobilier', false);
                        this.props.setCurrentAction('copyPasting').then(() => this.useSelectionAsCopy(name === 'copyTrees' ? 'Arbre' : name === 'copyGreenSpaces' ? 'Espace vert' : 'Mobilier'));
                        this.bringPaneToFront(name);
                    } else {
                        this.map.pm.disableDraw();
                        if (['copyTrees', 'copyFurnitures'].includes(name)) this.showClusteredLayer(name === 'copyTrees' ? 'Arbre' : 'Mobilier', true);
                        this.exitSubTool(true);
                        this.props.setLayer(null);
                        this.props.setCurrentAction('');
                    }
                } else showToast('quit_link_mode');
                break;
            case 'copyPasteTrees': case 'copyPasteGreenSpaces': case 'copyPasteFurnitures':
                if (this.props.currentAction !== 'copyPasting') {
                    this.exitSubTool(false);
                    this.props.setCurrentAction('copyPasting').then(() => {
                        this.useSelectionAsCopy(name === 'copyPasteTrees' ? 'Arbre' : name === 'copyPasteGreenSpaces' ? 'Espace vert' : 'Mobilier')
                    });
                    this.bringPaneToFront(name);
                }
                break;
            case 'duplicateTrees': case 'duplicateGreenSpaces': case 'duplicateFurnitures':
                if (this.props.currentAction !== 'duplicating') {
                    this.map.pm.disableDraw();
                    this.exitSubTool(false);
                    this.props.setLayer(null);
                    this.props.setCurrentAction('duplicating');
                    this.bringPaneToFront(name);
                    this.toolbarRef.current.setButtonsIsDisabled(['lasso'], true);
                    this.setState({
                        modal: { visible: false, title: '' },
                        modalContentType: 'DuplicateForm'
                    });
                }
                break;
            case 'reset': this.resetSelectionAsCopy(true); break;
            case 'cancel':
                if (this.state.selectedElements.length) this.selectElements(this.state.selectedElements);
                this.resetActionHistory(true);
                if (this.props.currentAction === 'addingBackgroundImage') this.exitSubTool(true);
                break;
            case 'undo': this.undoAction(); break;
            case 'redo': this.redoAction(); break;
            case 'confirm': this.confirmAction(); break;
            case 'dragTrees': case 'dragGreenSpaces': case 'dragFurnitures': case 'dragMarkers': case 'dragStations':
                if (this.props.currentAction !== 'dragging') {
                    this.props.setCurrentAction('dragging');
                    this.bringPaneToFront(name);
                    if (['dragTrees', 'dragFurnitures'].includes(name)) this.showClusteredLayer(name === 'dragTrees' ? 'Arbre' : 'Mobilier', false);
                    const layersToReInit = [name !== 'dragGreenSpaces' && this.greenSpacesLayer, name !== 'dragStations' && this.stationsLayer, name !== 'dragMarkers' && this.markersLayer].filter(layer => layer);
                    layersToReInit.forEach(layerContainer => {
                        layerContainer.eachLayer(layer => {
                            layer.setStyle({ pmIgnore: true });
                            L.PM.reInitLayer(layer);
                        });
                    });
                    this.map.pm.toggleGlobalDragMode();
                } else {
                    this.map.pm.toggleGlobalDragMode();
                    this.exitSubTool(true);
                    this.props.setCurrentAction('');
                }
                break;
            case 'removeTrees': case 'removeGreenSpaces': case 'removeFurnitures': case 'removeMarkers': case 'removeStations': case 'removeImages':
                const category = {
                    'removeTrees': 'Arbre', 'removeGreenSpaces': 'Espace vert', 'removeFurnitures': 'Mobilier', 'removeMarkers': 'Repère',
                    'removeStations': 'Station', 'removeImages': 'BackgroundImage'
                }[name];
                if (this.props.currentAction !== 'removing') {
                    if (this.state.selectedElements.filter(l => l.feature.properties.category === category).length) {
                        this.props.setCurrentAction('removing');
                        this.showRemoveForm();
                    } else this.props.setCurrentAction('removing');
                    this.bringPaneToFront(name);
                    if (['removeTrees', 'removeFurnitures'].includes(name) && !this.state.selectedElements.filter(l => l.feature.properties.category !== category).length)
                        this.showClusteredLayer(category, false);
                    else if (name === 'removeImages') {
                        const hasChanged = this.toolbarRef.current.setButtonState('showImages', 1, 0);
                        if (hasChanged) this.changeBackgroundImagesVisibility(0);
                        this.backgroundImagesLayer.getLayers().forEach(layer => {
                            layer.getElement().classList.remove('disabled');
                        });
                    }
                } else {
                    if (['removeTrees', 'removeFurnitures'].includes(name)) this.showClusteredLayer(category, true);
                    this.exitSubTool(true);
                    this.props.setCurrentAction('');
                }
                break;
            case 'addGreenSpace': case 'addStation':
                if (!['addingPolygon', 'addingLine', 'addingRectangle', 'addingCircle'].includes(this.props.currentAction)) {
                    this.props.setCurrentAction('addingPolygon').then(() => {
                        this.map.pm.setGlobalOptions({ allowSelfIntersection: false });
                        this.map.pm.enableDraw('Polygon');
                    });
                    this.bringPaneToFront(name);
                } else {
                    this.map.pm.setGlobalOptions({ allowSelfIntersection: true });
                    this.map.pm.disableDraw();
                    this.exitSubTool(true);
                    this.props.setCurrentAction('');
                }
                break;
            case 'addPolygon':
                if (this.props.currentAction !== 'addingPolygon') {
                    this.props.setCurrentAction('addingPolygon').then(() => {
                        this.map.pm.enableDraw('Polygon');
                    });
                    this.exitSubTool(false);
                }
                break;
            case 'addLine': case 'addRectangle': case 'addCircle':
                const shape = name.charAt(3) + name.slice(4);
                if (this.props.currentAction !== `adding${shape}`) {
                    this.exitSubTool(false);
                    this.props.setCurrentAction(`adding${shape}`).then(() => {
                        this.map.pm.enableDraw(shape);
                    });
                    this.setState({
                        modal: { visible: false, title: '' },
                        modalContentType: `${shape}Form`
                    });
                }
                break;
            case 'rotateGreenSpaces': case 'rotateStations':
                if (this.props.currentAction !== 'rotating') {
                    this.props.setCurrentAction('rotating');
                    this.bringPaneToFront(name);
                    this.toolbarRef.current.setButtonsIsDisabled(['lasso'], true);
                } else {
                    this.exitSubTool(true);
                    this.props.setCurrentAction('');
                }
                break;
            case 'editGreenSpaces': case 'editStations':
                if (this.props.currentAction !== 'editing') {
                    this.props.setCurrentAction('editing');
                    this.bringPaneToFront(name);
                    if (name === 'editGreenSpaces') {
                        this.greenSpacesLayer.eachLayer(layer => {
                            if (layer.feature.properties.baseLine) {
                                layer.setStyle({ pmIgnore: true });
                                L.PM.reInitLayer(layer);
                            }
                        });
                        this.showDrawLines();
                    }
                    this.toolbarRef.current.setButtonsIsDisabled(['lasso'], true);
                } else {
                    this.exitSubTool(true);
                    this.props.setCurrentAction('');
                }
                break;
            case 'cutGreenSpaces':
                if (this.props.currentAction !== 'cutting') {
                    this.props.setCurrentAction('cutting');
                    this.bringPaneToFront(name);
                    this.stationsLayer.eachLayer(layer => {
                        layer.setStyle({ pmIgnore: true });
                        L.PM.reInitLayer(layer);
                    });
                    this.map.pm.toggleGlobalCutMode();
                    this.toolbarRef.current.setButtonsIsDisabled(['lasso'], true);
                } else {
                    this.isEditingAfterToggle = true;
                    this.map.pm.toggleGlobalCutMode();
                    this.isEditingAfterToggle = false;
                    this.exitSubTool(true);
                    this.props.setCurrentAction('');
                }
                break;
            case 'splitGreenSpaces':
                if (this.props.currentAction !== 'splitting') {
                    this.props.setCurrentAction('splitting');
                    this.bringPaneToFront(name);
                    this.map.pm.setGlobalOptions({ allowSelfIntersection: false });
                    this.showDrawLines(true);
                    this.map.pm.enableDraw('Line');
                    this.toolbarRef.current.setButtonsIsDisabled(['lasso'], true);
                } else {
                    this.map.pm.setGlobalOptions({ allowSelfIntersection: true });
                    this.map.pm.disableDraw();
                    this.exitSubTool(true);
                    this.props.setCurrentAction('');
                }
                break;
            case 'mergeGreenSpaces':
                if (this.props.currentAction !== 'merging') {
                    this.props.setCurrentAction('merging');
                    this.bringPaneToFront(name);
                    this.toolbarRef.current.setButtonsIsDisabled(['lasso'], true);
                } else {
                    if (this.props.layer) {
                        const layer = this.props.layer;
                        layer.setStyle(StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps));
                        this.props.setLayer(null);
                    }
                    this.exitSubTool(true);
                    this.props.setCurrentAction('');
                }
                break;
            case 'subtractGreenSpaces':
                if (this.props.currentAction !== 'subtracting') {
                    this.props.setCurrentAction('subtracting');
                    this.bringPaneToFront(name);
                    this.toolbarRef.current.setButtonsIsDisabled(['lasso'], true);
                } else {
                    if (this.props.layer) {
                        const layer = this.props.layer;
                        layer.setStyle(StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps));
                        this.props.setLayer(null);
                    }
                    this.exitSubTool(true);
                    this.props.setCurrentAction('');
                }
                break;
            case 'addImage':
                if (this.props.currentAction !== 'addingBackgroundImage') {
                    this.props.setCurrentAction('addingBackgroundImage');
                    this.bringPaneToFront(name);
                    this.changeModalContentType('BackgroundImageAdditionForm', i18n.t("Ajout d'un calque"));
                } else {
                    this.exitSubTool(true);
                    this.props.setCurrentAction('');
                }
                break;
            case 'showImages': this.changeBackgroundImagesVisibility(state); break;
            case 'orderImages':
                if (this.props.currentAction !== 'orderingBackgroundImages') {
                    this.props.setCurrentAction('orderingBackgroundImages');
                    this.bringPaneToFront(name);
                    const hasChanged = this.toolbarRef.current.setButtonState('showImages', 1, 0);
                    if (hasChanged) {
                        this.changeBackgroundImagesVisibility(0);
                        this.toggleBackgroundImages(false);
                    } else this.toggleBackgroundImages(false);
                    this.setState({
                        modal: { visible: false, title: '' },
                        modalContentType: 'BackgroundImageManagementForm'
                    });
                } else {
                    this.exitSubTool(true);
                    this.props.setCurrentAction('');
                }
                break;
            case 'editImages':
                if (this.props.currentAction !== 'editingBackgroundImages') {
                    this.props.setCurrentAction('editingBackgroundImages');
                    this.bringPaneToFront(name);
                    const hasChanged = this.toolbarRef.current.setButtonState('showImages', 1, 0);
                    if (hasChanged) {
                        this.changeBackgroundImagesVisibility(0);
                        this.toggleBackgroundImages(true);
                    } else this.toggleBackgroundImages(true);
                } else {
                    this.exitSubTool(true);
                    this.props.setCurrentAction('');
                }
                break;
            default: break;
        }
    }

    assignMapEvents = () => {
        this.map.pm.setLang('fr');
        this.map.pm.setGlobalOptions({
            snapDistance: 5, tooltips: false,
            templineStyle: { color: 'var(--primary-100)', fillColor: 'var(--secondary-100)', fillOpacity: 0.7 },
            hintlineStyle: { color: 'var(--primary-100)', dashArray: [5, 5] },
            pathOptions: { color: 'var(--primary-100)', fillColor: 'var(--secondary-100)', fillOpacity: 0.7 },
            removeVertexValidation: GeometriesUtil.removeVertexValidation
        });

        this.map.on('lasso.finished', (e) => {
            let layers = [];
            const { currentTools } = this.state;
            const category = {
                'treeTools': 'Arbre', 'greenSpaceTools': 'Espace vert', 'furnitureTools': 'Mobilier', 'markerTools': 'Repère',
                'stationTools': 'Station', 'imageTools': 'Calque'
            }[currentTools];
            e.layers.forEach(layer => {
                if (!isNaN(layer._corner) && !layer.feature && !layer.elementId && layer.getLatLng()) {
                    const backgroundImage = this.backgroundImagesLayer.getLayers().find(bi => bi.getCorners().includes(layer.getLatLng()));
                    if (!layers.includes(backgroundImage)) layers.push(backgroundImage);
                } else if (layer.feature && (!this.props.currentAction !== 'removing' || !category || layer.feature?.properties.category === category)) layers.push(layer);
            });

            if (this.props.currentAction === 'removing') {
                layers = layers.filter(layer => !this.state.highlightedElements?.includes(layer));
                if (layers.length) {
                    this.toggleElementsHighlight(layers);
                    this.pushActionHistory(layers.map(layer => ({ layer })));
                }
            } else if (['managingActionElements', 'managingEventElements', 'linkingElements'].includes(this.props.currentAction)) {
                layers.forEach(layer => {
                    if (!this.props.layer?.find(l => l === layer)) {
                        if (layer.feature?.properties.category === 'Arbre' && (this.actionLinkingCategories.includes('Arbre') || this.eventLinkingCategories.includes('Arbre')))
                            this.handleLinkingClick(layer, StylesUtil.getTreeActiveStyle(this.treesLayer, this.fieldList, layer.feature.properties, this.props.project.thematicMaps), StylesUtil.getTreeHighlightStyle(this.treesLayer, this.fieldList, layer.feature.properties));
                        else if (layer.feature?.properties.category === 'Espace vert' && (this.actionLinkingCategories.includes(layer.feature?.properties?.dominantCompositionId === 7 ? 'Massif arboré' : 'Espace vert') || this.eventLinkingCategories.includes(layer.feature?.properties?.dominantCompositionId === 7 ? 'Massif arboré' : 'Espace vert')))
                            this.handleLinkingClick(layer, StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps), StylesUtil.getGreenSpaceHighlightStyle());
                        else if (layer.feature?.properties.category === 'Mobilier' && (this.actionLinkingCategories.includes('Mobilier') || this.eventLinkingCategories.includes('Mobilier')))
                            this.handleLinkingClick(layer, StylesUtil.getFurnitureActiveStyle(this.furnituresLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps), StylesUtil.getFurnitureHighlightStyle(this.fieldList));
                    }
                });
            } else if (['drawingFilterSurroundings', 'drawingActionsFilterSurroundings'].includes(this.props.currentAction)) {
                if (this.props.currentAction === 'drawingFilterSurroundings') this.props.setCurrentAction('');
                this.props.setLayer(L.polygon(e.latLngs));
                this.changeModalContentType('FilterForm', i18n.t("Filtres"));
            } else {
                if (!this.shiftKey) this.unselectElements();
                this.selectElements(layers, !this.shiftKey);
            }

            this.checkHistoryButtons();
            setTimeout(() => {
                if (!this.lasso.enabled()) this.props.setButtonState('lasso', 0);
            }, 100);
        });

        this.map.on('click', () => {
            if (this.props.currentAction === 'rotating' && !this.clickAfterRotate) {
                if (this.rotatingLayer) {
                    this.rotatingLayer.pm.disableRotate();
                    this.rotatingLayer = null;
                }
            } else this.clickAfterRotate = false;
            if (this.props.currentAction === 'editing' && !this.clickAfterEdit) {
                if (['LineForm', 'OffsetForm'].includes(this.state.modalContentType)) {
                    this.setState({ modalContentType: '' });
                    this.props.setLayer(null);
                }
                if (this.editingLayer) {
                    this.editingLayer.pm.disable();
                    this.editingLayer = null;
                }
            } else this.clickAfterEdit = false;

            if (!this.clickBeforeDragend && !this.shiftKey && (this.props.currentAction !== 'copyPasting' || !this.props.layer) && this.state.selectedElements.length)
                this.unselectElements();
            if (this.state.backgroundImageToEdit) this.setState({ backgroundImageToEdit: null });
        });

        this.map.on('moveend', () => {
            if (this.mapMoveEndTimeout) clearTimeout(this.mapMoveEndTimeout);
            this.mapMoveEndTimeout = setTimeout(async () => {
                await this.toggleReferences(this.state.references);
                OfflineUtil.updateProjectView(this.props.project.id, 'lastPosition', this.map.getCenter());
                this.mapSearch = UrlsUtil.adjustSearch(this.mapSearch, { x: this.map.getCenter().lng, y: this.map.getCenter().lat, zoom: this.map.getZoom() });
                if (!this.props.match.params.path1) this.props.history.push({ search: this.mapSearch.short });
            }, 1000);
        });

        this.map.on('zoomstart', () => {
            if (!this.heatmapsVisibility) {
                this.heatmapsVisibility = {
                    carbonStockLayer: this.map.hasLayer(this.carbonStockLayer),
                    coolingLayer: this.map.hasLayer(this.coolingLayer)
                };

                if (this.heatmapsVisibility?.carbonStockLayer) this.toggleLayer({ layer: this.carbonStockLayer, toggleInMenu: false });
                if (this.heatmapsVisibility?.coolingLayer) this.toggleLayer({ layer: this.coolingLayer, toggleInMenu: false });
            }
        });

        this.map.on('zoomend', () => { // Permet de charger/décharger les layers en fonction du zoom actif sur la carte
            const zoomLevel = this.map.getZoom();

            if (!['drawingProjectSurroundings', 'modifyingProjectSurroundings', 'drawingCustomArea'].includes(this.props.currentAction)) {
                const rawSurroundings = JSON.parse(this.props.project.surroundings);
                const surroundingsPolygon = rawSurroundings.geometry.type === 'Polygon'
                    ? polygon(rawSurroundings.geometry.coordinates)
                    : multiPolygon(rawSurroundings.geometry.coordinates);
                const boundsPolygon = bbox(surroundingsPolygon);
                const scaledCoordinates = GeometriesUtil.getScaledBbox(boundsPolygon, this.map.getBounds());
                this.maxBounds = L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(scaledCoordinates)).getBounds();
                this.map.setMaxBounds(this.maxBounds);
            }

            this.fieldList.zoomLevel = zoomLevel;
            if ([this.treesLayer, this.treesLayerNotClustered].some(layerContainer => this.map.hasLayer(layerContainer))
                && ((isMobile && zoomLevel >= 17) || this.treesLayer.activeOptions?.includes(i18n.t("Diamètre des couronnes"))))
                [this.treesLayer, this.treesLayerNotClustered].forEach(layerContainer => {
                    layerContainer.eachLayer(layer => {
                        if (this.treesLayer.activeOptions?.includes(i18n.t("Diamètre des couronnes"))) {
                            const biggestTrunk = TreesUtil.getBiggestTrunk(layer.feature.properties.trunks);
                            const radius = biggestTrunk ? (biggestTrunk.crownDiameter / 20000000) * Math.pow(2, zoomLevel) : 0;
                            layer.setRadius(radius < 5 || layer.feature.properties.toCutDown === 4 ? 5 : radius);
                        } else layer.setRadius(5 + ((zoomLevel - 17) * 2));
                    });
                });

            const radius = (6 + ((zoomLevel - 17) * 2)) * 0.6, weight = (6 + ((zoomLevel - 17) * 2)) * 0.4;
            if (isMobile && zoomLevel >= 17 && [this.furnituresLayer, this.furnituresLayerNotClustered].some(layerContainer => this.map.hasLayer(layerContainer)))
                [this.furnituresLayer, this.furnituresLayerNotClustered].forEach(layerContainer => {
                    layerContainer.eachLayer(layer => layer.setStyle({ radius, weight }));
                });

            if (isMobile && zoomLevel >= 17 && this.map.hasLayer(this.markersLayer))
                this.markersLayer.eachLayer(layer => layer.setStyle({ radius, weight }));

            clearTimeout(this.updateHeatmapsTimeout);
            this.updateHeatmapsTimeout = setTimeout(() => {
                if (this.heatmapsVisibility?.carbonStockLayer) this.toggleLayer({ layer: this.carbonStockLayer, toggleInMenu: false });
                if (this.heatmapsVisibility?.coolingLayer) this.toggleLayer({ layer: this.coolingLayer, toggleInMenu: false });
                this.heatmapsVisibility = null;
            }, 500);


            const center = this.map.getCenter();
            OfflineUtil.updateProjectView(this.props.project.id, 'lastPosition', center);
            OfflineUtil.updateProjectView(this.props.project.id, 'lastZoom', zoomLevel);
            this.mapSearch = UrlsUtil.adjustSearch(this.mapSearch, { x: center.lng, y: center.lat, zoom: zoomLevel });
            if (!this.props.match.params.path1) this.props.history.push({ search: this.mapSearch.short });
            setTimeout(() => this.toggleReferences(this.state.references), 50);
        });

        const removeBookmarkMarker = (e) => {
            this.map.eachLayer(layer => {
                if (layer.getLatLng) {
                    const { lat, lng } = layer.getLatLng();
                    if (lat === e.data.latlng[0] && lng === e.data.latlng[1])
                        this.map.removeLayer(layer);
                }
            });
        };
        this.map.on('bookmark:add', removeBookmarkMarker);
        this.map.on('bookmark:show', removeBookmarkMarker);

        // Gestion des événements de la toolbar
        this.map.on('mousemove', (e) => {
            if (this.mouseMoveTimeout) {
                clearTimeout(this.mouseMoveTimeout);
                this.mouseMoveTimeout = null;
            }
            this.mouseMoveTimeout = setTimeout(() => {
                if (this.props.currentAction === 'copyPasting' && this.helpLayers)
                    this.updateHelpLayersPosition(this.map.mouseEventToLatLng(e.originalEvent));
                if (['addingPolygon', 'addingLine'].includes(this.props.currentAction) && this.lastVertex) {
                    const lastVertexDistance = distance(this.lastVertex, point([e.latlng.lng, e.latlng.lat]), { units: 'meters' });
                    this.setState(prevState => {
                        const drawingSegments = [...(prevState.drawingSegments || [])];
                        drawingSegments[(drawingSegments.length || 1) - 1] = lastVertexDistance;
                        return { drawingSegments };
                    });
                }
            }, 1);
        });

        this.map.on('pm:drawstart', (e) => {
            if (e.shape === 'Circle') // Mise à jour du rayon lors du tracé
                e.workingLayer.on('pm:centerplaced', () => {
                    e.workingLayer.on('pm:change', ({ layer }) => this.setState({ radius: Number(layer.getRadius().toFixed(0)) }));
                });
            if (['addingPolygon', 'addingLine'].includes(this.props.currentAction))
                e.workingLayer.on("pm:vertexadded", (e) => {
                    this.lastVertex = point([e.latlng.lng, e.latlng.lat]);
                    this.setState(prevState => ({ drawingSegments: [...(prevState.drawingSegments || []), 0] }));
                });
            if (this.props.currentAction === 'addingRectangle') {
                e.workingLayer.on('pm:change', ({ layer }) => {
                    if (this.pmChangeTimeout) {
                        clearTimeout(this.pmChangeTimeout);
                        this.pmChangeTimeout = null;
                    }
                    this.pmChangeTimeout = setTimeout(() => {
                        const latLngs = layer.getLatLngs();
                        if (latLngs[0]?.length > 3) {
                            const width = distance(point([latLngs[0][0].lng, latLngs[0][0].lat]), point([latLngs[0][1].lng, latLngs[0][1].lat]), { units: 'meters' });
                            const length = distance(point([latLngs[0][1].lng, latLngs[0][1].lat]), point([latLngs[0][2].lng, latLngs[0][2].lat]), { units: 'meters' });
                            this.setState({ rectangleDimensions: { width: Math.round(width * 100) / 100, length: Math.round(length * 100) / 100 } });
                        }
                    }, 1);
                });
            }

            this.toolbarRef.current.setButtonsIsDisabled(['lasso'], true);
            if (this.toolbarRef.current.state.selectedButtons?.find(x => x?.name?.includes('copyPaste')))
                this.map.removeLayer(e.workingLayer);
        });

        this.map.on('pm:drawend', (e) => {
            this.toolbarRef.current.setButtonsIsDisabled(['lasso'], false);
            this.setState({ drawingSegments: null });
            this.lastVertex = null;
        });

        this.map.on('pm:create', e => {
            const { currentAction, layer } = this.props;
            const { currentElementTools } = this.state;

            if (currentAction === 'placingElement') {
                if (this.checkIfInsideSurroundings(e.shape.toLowerCase(), e.layer)) {
                    this.props.setCurrentAction('');
                    layer[0].setLatLng(e.layer.getLatLng());
                    this.map.removeLayer(e.layer);

                    UpdatesUtil.updateTree(layer[0], layer[0].feature.properties, layer, false, this.fieldList, this.treesLayer, 'updating', this.props.project.id, this.props.webSocketHubs, { thematicMaps: this.props.project.thematicMaps })
                        .then(() => {
                            if (!this.arrowShortcutsEnabled) {
                                this.arrowShortcutsEnabled = true;
                                document.addEventListener('keydown', this.handleArrowShortcuts);
                            }
                            this.props.setCurrentAction('viewingModal');
                            this.setState({
                                modal: { visible: true, title: i18n.t("Modification d'un élément") },
                                modalContentType: 'TreeForm'
                            });
                        });
                } else {
                    showToast('element_addition_not_allowed');
                    this.map.removeLayer(e.layer);
                }
            } else if (['drawingProjectSurroundings', 'modifyingProjectSurroundings'].includes(currentAction)) { // Si on dessine la zone géographique d'un projet                
                this.map.removeLayer(e.layer);
                this.props.setLayer(e.layer).then(() => {
                    const surroundings = GeoJsonUtil.generatePolygonFeature(null, e.layer._latlngs);
                    if (surroundings) {
                        const latLngs = GeometriesUtil.convertPolygonCoordinatesToLatLngs(surroundings.geometry.coordinates, surroundings.geometry.type === 'MultiPolygon');
                        this.surroundingsDottedLayer = L.polygon(latLngs, { color: "#000000", opacity: 0.4, fillOpacity: 0, weight: 4, dashArray: '10 10', pmIgnore: true, pane: 'surroundings' }).addTo(this.map);
                        const surroundingsPolygon = surroundings.geometry.type === 'Polygon'
                            ? polygon(surroundings.geometry.coordinates)
                            : multiPolygon(surroundings.geometry.coordinates);
                        const invertedSurroundings = GeometriesUtil.invertPolygon(surroundingsPolygon);
                        const invertedLatLngs = GeometriesUtil.convertPolygonCoordinatesToLatLngs(invertedSurroundings.geometry.coordinates);
                        this.tmpSurroundingsLayer = L.polygon(invertedLatLngs, this.props.isDarkTheme ? StylesUtil.getSurroundingsDarkStyle() : StylesUtil.getSurroundingsLightStyle()).addTo(this.map);
                        this.tmpSurroundingsLayer.pm.enable({ removeVertexValidation: GeometriesUtil.removeVertexValidation });
                    }
                });
            } else if (currentAction === 'drawingCustomArea') {
                this.map.removeLayer(e.layer);
                this.props.setLayer(e.layer).then(() => {
                    this.props.setCurrentAction('').then(() => {
                        const basicToolsButton = document.getElementById('uhRCkpxG');
                        if (basicToolsButton) basicToolsButton.click();
                        this.changeModalContentType('RoleList', 'Gestion des rôles', this.props.project);
                        Object.keys(this.layersVisibility).forEach(layerName => {
                            if (this.layersVisibility[layerName] && !this.map.hasLayer(this[layerName]))
                                this.toggleLayer({ layer: this[layerName] });
                        });

                        if (this.state.projectToEdit?.id !== this.props.project.id) {
                            this.map.addLayer(this.surroundingsLayer);
                            this.map.removeLayer(this.tmpSurroundingsLayer);
                            this.map.setMaxBounds(this.maxBounds);
                            this.map.fitBounds(this.initialBounds, { animate: false });
                            this.initialBounds = null;
                        }
                    });
                });
            } else if (currentAction === 'drawingScanZone') {
                this.props.setLayer(e.layer);
                if (this.checkIfInsideSurroundings(e.shape.toLowerCase(), e.layer)) {
                    this.props.setCurrentAction('');
                    this.setState(prevState => ({ scan: { ...prevState.scan, bounds: e.layer.getBounds(), latLngs: e.layer.getLatLngs() } }));
                } else {
                    showToast('scan_not_allowed');
                    setTimeout(() => this.map.pm.enableDraw('Polygon'), 100);
                }
            } else if (currentAction === 'splitting') { // Division des espaces verts 
                this.splitGreenSpaces(e.layer.toGeoJSON());
                this.map.removeLayer(e.layer);
                setTimeout(() => this.map.pm.enableDraw('Line'), 100);
            } else if (currentAction === 'duplicating') {
                let placementLine = e.layer;
                placementLine.setStyle({ color: "#000000", opacity: 0.4, fillOpacity: 0, weight: 4, dashArray: '10 10', pmIgnore: true });
                this.setState({ placementLine });
            } else if (currentAction === 'copyPasting' || this.checkIfInsideSurroundings(e.shape.toLowerCase(), e.layer)) {
                let shape = e.shape.toLowerCase();

                const addElement = () => {
                    if (currentAction !== 'copyPasting') { // Sinon si on ajoute un nouvel élément
                        this.props.setLayer(e.layer); // On récupère le layer créé
                        let type = '', visible = true;

                        if (currentAction === 'addingTree') type = 'TreeForm';
                        else if (currentAction === 'addingFurniture') type = 'FurnitureForm';
                        else if (currentAction === 'addingMarker') type = 'MarkerForm';
                        else if (currentAction === 'addingPolygon') {
                            type = currentElementTools === 'greenSpaceTools' ? 'GreenSpaceForm' : 'StationForm';
                            if (type === 'StationForm') visible = false;
                        } else if (['line', 'rectangle', 'circle'].includes(shape)) {
                            if (shape === 'rectangle') {
                                e.layer.pm.enableRotate();
                                this.stickyRotateStartLatLngs = e.layer.getLatLngs();
                            }
                            type = this.state.modalContentType;
                            visible = false;
                        }

                        if (visible) {
                            this.map.pm.disableDraw();
                            this.toolbarRef.current.resetButtonSelection(1);
                        }
                        this.setState({
                            modal: { visible, title: 'Ajout d\'un nouvel élément' },
                            modalContentType: type
                        }, () => {
                            if (this.props.tutorialTour) this.props.tutorialTour.next();
                        });
                    } else { // Sinon c'est qu'on copie un élément déjà existant
                        const category = (Array.isArray(layer) && layer[0].feature.properties.category) || layer.feature.properties.category;
                        /*Le if est nécessaire pour ne pas effectuer ces actions la première fois qu'on passe dans le pm:create lors du copier/coller
                        car le plugin geoman crée un marker sur le polygone dès qu'on clique dessus pour retenir le polygone à copier/coller*/
                        if (this.shouldCopy) {
                            const categories = {
                                'Arbre': { addFunction: TreesService.addTrees, isMarker: true },
                                'Mobilier': { addFunction: FurnituresService.addFurnitures, isMarker: true },
                                'Espace vert': { addFunction: GreenSpacesService.addGreenSpaces, isMarker: false }
                            };

                            const { addFunction, isMarker } = categories[category];

                            const initialLayers = Array.isArray(layer) ? layer : [layer];
                            const elementsToAdd = [];
                            let nbElementsOutsideSurroundings = 0;

                            const elements = initialLayers.map(layer => {
                                const feature = {
                                    ...JSON.parse(JSON.stringify(layer.feature)),
                                    geometry: (isMarker ? point([layer.getLatLng().lng, layer.getLatLng().lat]) : polygon(GeometriesUtil.convertPolygonLatLngsToCoordinates(layer.getLatLngs()))).geometry
                                };
                                feature.properties.customReference = null;
                                return feature;
                            });

                            const initialCenter = centerOfMass(featureCollection(elements));
                            const finalCenter = point([e.layer.getLatLng().lng, e.layer.getLatLng().lat]);
                            const bearing = rhumbBearing(initialCenter, finalCenter); // bearing angle
                            const distance = rhumbDistance(initialCenter, finalCenter); // bearing distance

                            for (const element of elements) {
                                let translatedElement = transformTranslate(element, distance, bearing);
                                translatedElement = { ...JSON.parse(JSON.stringify(element)), geometry: translatedElement.geometry };
                                translatedElement.id = uuidv4();

                                if (category === 'Arbre') (translatedElement.properties.trunks || []).forEach(trunk => trunk.id = uuidv4());
                                else if (category === 'Espace vert') translatedElement.properties.greenSpaceId = translatedElement.id;
                                if (translatedElement.properties.baseLine) {
                                    translatedElement.properties.baseLine.propertiesId = translatedElement.id;
                                    const translatedBaseLine = transformTranslate(lineString(translatedElement.properties.baseLine.coordinates), distance, bearing);
                                    translatedElement.properties.baseLine.coordinates = translatedBaseLine.geometry.coordinates;
                                }

                                if (this.checkIfInsideSurroundings(translatedElement.geometry.type.toLowerCase(), isMarker
                                    ? new L.marker(JSON.parse(JSON.stringify(translatedElement.geometry.coordinates)).reverse())
                                    : new L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(translatedElement.geometry.coordinates))
                                ))
                                    elementsToAdd.push(translatedElement);
                                else nbElementsOutsideSurroundings++;
                            }

                            if (nbElementsOutsideSurroundings) showToast(nbElementsOutsideSurroundings > 1 ? 'elements_outside_surroundings' : 'element_outside_surroundings', nbElementsOutsideSurroundings);
                            if (elementsToAdd.length > 0) {
                                // On rempli le champs 'Commune' avec le lieu récupérer depuis les coordonnées
                                LocationsService.getPlace(e.layer.getLatLng().lat, e.layer.getLatLng().lng).then(place => {
                                    for (const element of elementsToAdd) {
                                        if (place) element.properties.place = place;
                                        if (isMarker) this.addMarker(category, new L.marker({ lat: element.geometry.coordinates[1], lng: element.geometry.coordinates[0] }), element, { showContainer: true });
                                        else {
                                            const polygon = new L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(element.geometry.coordinates));
                                            this.addGreenSpace(polygon, element, { showContainer: true });
                                        }
                                    }
                                    addFunction(elementsToAdd, currentAction, this.props.webSocketHubs);
                                });
                            }
                        } else this.shouldCopy = true;
                        this.map.removeLayer(e.layer);
                    }
                };

                if (layer?.[0].feature.properties.category === 'Station' || this.state.currentElementTools === 'stationTools'
                    || layer?.[0].feature.properties.category === 'Repère' || this.state.currentElementTools === 'markerTools')
                    addElement();
                else {
                    let type, text;
                    if (layer?.[0].feature.properties.category === 'Arbre' || this.props.currentAction.includes('Tree')) {
                        type = 'trees';
                        text = i18n.t("Arbres").toLowerCase();
                    } else if (['polygon', 'line', 'rectangle', 'circle'].includes(shape) || layer?.[0].feature.properties.category === 'Espace vert' || this.props.currentAction.includes('GreenSpace')) {
                        type = 'greenSpaces';
                        text = i18n.t("Espaces verts").toLowerCase();
                    } else if (layer?.[0].feature.properties.category === 'Mobilier' || this.props.currentAction.includes('Furniture')) {
                        type = 'furnitures';
                        text = i18n.t("Mobilier urbain").toLowerCase();
                    }

                    const { project, isOnline } = this.props;
                    ProjectsService.isMaxElementsReached(project, type, text).then(response => {
                        if (response) {
                            response.nbElements += Array.isArray(layer) ? layer.length : 1;
                            if (response.maxElements !== -1 && response.nbElements >= response.maxElements)
                                showToast('elements_limit_reached', text);
                            else if (response.state) {
                                this.hideForm(false);
                                showToast('elements_limit_reached', text);
                                return;
                            } else if (response.maxElements !== -1 && response.nbElements >= (response.maxElements * 0.95))
                                showToast('element_limit_almost_reached', text, '(' + response.nbElements + ' / ' + response.maxElements + ')');
                        } else if (!response && isOnline) {
                            this.map.removeLayer(e.layer);
                            this.hideForm(false);
                            return;
                        }

                        addElement();
                    });
                }
            } else {
                this.map.removeLayer(e.layer);
                if (!['addingTree', 'addingFurniture', 'copyPasting', 'duplicating'].includes(currentAction)) this.disableDrawing();
                showToast('element_addition_not_allowed');
            }
        });

        this.map.on('pm:globalcutmodetoggled', ({ enabled }) => {
            if (!enabled)
                if (!this.isEditingAfterToggle) this.map.pm.toggleGlobalCutMode(); // On le réactive si désactivé après avoir coupé aucun EV
                else this.stationsLayer.eachLayer(layer => {
                    layer.setStyle({ pmIgnore: false });
                    L.PM.reInitLayer(layer);
                });
        });

        this.map.on('pm:globaldragmodetoggled', ({ enabled }) => {
            if (!enabled) {
                const { currentElementTools } = this.state;
                [currentElementTools !== 'greenSpaceTools' && this.greenSpacesLayer, currentElementTools !== 'stationTools' && this.stationsLayer, currentElementTools !== 'markerTools' && this.markersLayer]
                    .filter(layer => layer).forEach(layerContainer => {
                        layerContainer.eachLayer(layer => {
                            layer.setStyle({ pmIgnore: false });
                            L.PM.reInitLayer(layer);
                        });
                    });
            }
        });

        this.map.on('pm:cut', ({ layer, originalLayer }) => { // Lorsqu'on a découpé un polygone
            if (layer?.feature) {
                const originalShape = polygon(originalLayer.feature.geometry.coordinates);
                const finalShape = layer.feature.geometry.type === 'Polygon'
                    ? polygon(layer.feature.geometry.coordinates)
                    : multiPolygon(layer.feature.geometry.coordinates);
                const removedShape = difference(featureCollection([originalShape, finalShape]));

                if (!this.cutLayers) this.cutLayers = [{ originalLayer, layer, removedShape }];
                else this.cutLayers.push({ originalLayer, layer, removedShape });

                if (ProjectsUtil.isElementLocked(this.props.lockedElements, originalLayer.feature, this.props.project, this.props.projectCollaborators, [])) {
                    this.cancelGreenSpacesCut();
                    setTimeout(() => {
                        if (!this.map.pm.globalCutModeEnabled()) this.map.pm.toggleGlobalCutMode()
                    }, 100);
                } else if (layer.feature) {
                    layer.feature.id = originalLayer.feature.id;
                    layer.action = 'cutting';
                    if (this.showCutFormTimeout) clearTimeout(this.showCutFormTimeout);
                    this.showCutFormTimeout = setTimeout(() => {
                        if (this.cutLayers.some(({ removedShape }) => !removedShape)) {
                            this.cancelGreenSpacesCut();
                            return;
                        }

                        // Si on découpe exactement 2 layers et que les formes découpées ne sont pas des multipolygones, on calcule la fusion pour voir si elle est possible
                        if (this.cutLayers.length === 2 && this.cutLayers.every(({ removedShape }) => removedShape.geometry.type === 'Polygon'))
                            this.mergedPolygon = GeometriesUtil.mergePolygons(this.cutLayers.map(({ removedShape }) => removedShape));
                        else this.mergedPolygon = null;
                        this.showCutForm();
                        this.showCutFormTimeout = null;
                    }, 100);
                }
            } else {
                if (this.greenSpacesLayer.hasLayer(originalLayer)) this.greenSpacesLayer.removeLayer(originalLayer);
                this.pushActionHistory([{ layer: originalLayer, isDeletion: true }]);
            }
            setTimeout(() => {
                if (!this.map.pm.globalCutModeEnabled()) this.map.pm.toggleGlobalCutMode()
            }, 100);
        });

        this.map.on('pm:rotatestart', ({ layer }) => {
            if (!layer.feature) { // Rectangles & cercles
                layer.feature = layer.toGeoJSON();
                layer.feature.properties.category = this.state.currentElementTools === 'greenSpaceTools' ? 'Espace vert' : 'Station';
                layer.feature.geometry.coordinates = GeometriesUtil.convertPolygonLatLngsToCoordinates(layer.getLatLngs());
            }
            if (!['addingCircle', 'addingRectangle'].includes(this.props.currentAction)) this.pushActionHistory([{ layer }]);
        });

        this.map.on('pm:rotate', ({ layer }) => {
            if (layer.feature && ProjectsUtil.isElementLocked(this.props.lockedElements, layer.feature)) {
                layer.setLatLngs(GeometriesUtil.convertPolygonCoordinatesToLatLngs(layer.feature.geometry.coordinates));
                layer.pm.disableRotate();
                layer.pm.enableRotate();
            }
        });

        this.map.on('pm:rotateend', ({ layer, angle, startAngle, originLatLngs }) => {
            const isLocked = layer.feature && ProjectsUtil.isElementLocked(this.props.lockedElements, layer.feature) ? true : false;
            if (!isLocked) {
                let newAngle, angleToSet;
                if (this.stickyRotateStartLatLngs) {
                    newAngle = angle - startAngle;
                    angleToSet = this.state.angle + angle - startAngle;
                    layer.setLatLngs(originLatLngs);
                } else {
                    newAngle = angle - startAngle;
                    angleToSet = newAngle;
                    layer.setLatLngs(originLatLngs);
                }

                const center = layer.getCenter();
                const pivot = [center.lng, center.lat];
                newAngle = newAngle > 360 ? newAngle - 360 : newAngle < 0 ? newAngle + 360 : newAngle;
                angleToSet = angleToSet > 360 ? angleToSet - 360 : angleToSet < 0 ? angleToSet + 360 : angleToSet;
                if (this.stickyRotation) {
                    const stickyValue = 45;
                    newAngle = newAngle >= 0 ? newAngle + stickyValue / 2 - (newAngle + stickyValue / 2) % stickyValue : newAngle - stickyValue / 2 - (newAngle - stickyValue / 2) % stickyValue;
                    angleToSet = angleToSet >= 0 ? angleToSet + stickyValue / 2 - (angleToSet + stickyValue / 2) % stickyValue : angleToSet - stickyValue / 2 - (angleToSet - stickyValue / 2) % stickyValue;
                    this.setAngle(angleToSet);
                } else this.setAngle(angleToSet);

                const rotatedPolygon = transformRotate(polygon(GeometriesUtil.convertPolygonLatLngsToCoordinates(layer.getLatLngs())), newAngle, { pivot: pivot });
                layer.setLatLngs(GeometriesUtil.convertPolygonCoordinatesToLatLngs(rotatedPolygon.geometry.coordinates));
                if (this.checkIfInsideSurroundings('polygon', layer) && layer.feature) {
                    if (layer.feature.properties.baseLine) {
                        const rotatedBaseLine = transformRotate(lineString(layer.feature.properties.baseLine.coordinates), newAngle, { pivot: pivot });
                        layer.feature.properties.baseLine.coordinates = rotatedBaseLine.geometry.coordinates;
                    }
                } else if (layer.feature) {
                    showToast('element_drag_not_allowed');
                    this.cancelLastAction();
                }

                if (layer.pm.rotateEnabled()) {
                    layer.pm.disableRotate();
                    layer.pm.enableRotate();
                }
            }
        });
    }

    setAngle = (angle) => this.setState({ angle: angle });
    updateLegend = (layerName) => {
        if (this.legendRef?.current)
            switch (layerName) {
                case i18n.t("Arbres"): this.legendRef.current.updateLegend(this.treesLayer, { forceUpdateHiddenChildren: true }); break;
                case i18n.t("Espaces verts"): this.legendRef.current.updateLegend(this.greenSpacesLayer, { forceUpdateHiddenChildren: true }); break;
                case i18n.t("Mobilier urbain"): this.legendRef.current.updateLegend(this.furnituresLayer, { forceUpdateHiddenChildren: true }); break;
                case i18n.t("Repères"): this.legendRef.current.updateLegend(this.markersLayer, { forceUpdateHiddenChildren: true }); break;
                case i18n.t("Stations"): this.legendRef.current.updateLegend(this.stationsLayer, { forceUpdateHiddenChildren: true }); break;
                default: break;
            }
    }

    updateHeatmaps = async ({ updateBaseRadius = false, updateLatLngs = false } = {}) => {
        if (!this.map.hasLayer(this.carbonStockLayer) && !this.map.hasLayer(this.coolingLayer)) return;

        const zoom = this.map.getZoom();
        const layers = await this.treesLayerNotClustered.getLayers();

        const updateHeatmapLayerValues = (heatmapLayer, heatmapValues, getBaseRadius) => {
            if (heatmapValues) {
                heatmapValues.forEach(heatmapValue => {
                    const layer = layers.find(layer => layer.feature.id === heatmapValue.id);
                    if (layer) {
                        if (updateBaseRadius) heatmapValue.baseRadius = getBaseRadius(layer.feature);
                        heatmapValue.radius = heatmapValue.baseRadius * Math.pow(2, zoom);
                        if (updateLatLngs) {
                            heatmapValue.lat = layer.feature.geometry.coordinates[1];
                            heatmapValue.lng = layer.feature.geometry.coordinates[0];
                        }
                    } else heatmapValue.radius = 0;
                });
                heatmapLayer.setData({ data: heatmapValues.filter(heatmapValue => heatmapValue.radius >= 0.5) });
            }
        };

        // Rafraîchissement
        if (this.map.hasLayer(this.coolingLayer))
            updateHeatmapLayerValues(this.coolingLayer, this.coolingLayerValues, (feature) => (feature.properties.coolingIndicator / 200000).toFixed(10));
        // Stock carbones
        if (this.map.hasLayer(this.carbonStockLayer))
            updateHeatmapLayerValues(this.carbonStockLayer, this.carbonStockLayerValues, (feature) => (feature.properties.carbonStock / 100000).toFixed(10));
    }

    copyPrevOverlays = (prevOverlays) => {
        return prevOverlays.map(prevOverlay => {
            const overlay = { ...prevOverlay };
            if (overlay.children) overlay.children = overlay.children.map(childOverlay => ({ ...childOverlay }));
            return overlay;
        });
    }

    // Ajout d'un élément dans le control layer
    addOverlayToControlLayer = (label, { layer = null, originalLabel = null, parentLabel = null, selectAllCheckbox = false, customMap = null, isDefault = false, onlyOne = true, toggle = false, showToggleLayer = true, showToggleLegend = true, divider = false, buttons = [] } = {}) => {
        return new Promise((resolve) => {
            if (layer) {
                layer.label = label;
                layer.parentLabel = parentLabel;
            }

            this.setState(prevState => {
                const overlays = this.copyPrevOverlays(prevState.overlays);

                if (!parentLabel) overlays.push({ label, originalLabel, layer, onlyOne, showToggleLayer, showToggleLegend, id: customMap?.id });
                else {
                    let newChild = { label, originalLabel, parentLabel, layer, toggle, divider, buttons };
                    if (customMap) newChild.id = customMap.id;
                    if (!selectAllCheckbox && !toggle) newChild.radioGroup = parentLabel;

                    const index = overlays.findIndex(overlay => overlay.label === parentLabel);
                    if (index !== -1) {
                        if (isDefault) overlays[index].activeChild = newChild.label;
                        overlays[index].children = [...(overlays[index].children || []), newChild];
                    } else {
                        const newParent = { label: parentLabel, children: [newChild], onlyOne, showToggleLayer, showToggleLegend };
                        if (isDefault) newParent.activeChild = newChild.label;
                        overlays.push(newParent);
                    }

                    newChild = { ...newChild };
                }

                return { overlays };
            }, resolve);
        });
    }

    removeOverlayFromControlLayer = (customOverlayId) => {
        this.setState(prevState => {
            let overlays = this.copyPrevOverlays(prevState.overlays);
            const parentOverlay = overlays.find(overlay => overlay.id === customOverlayId);
            if (parentOverlay) {
                overlays = overlays.filter(overlay => overlay !== parentOverlay);
                if (parentOverlay.layer && this.map.hasLayer(parentOverlay.layer)) this.map.removeLayer(parentOverlay.layer);
            } else {
                overlays.forEach(overlay => {
                    if (overlay.children && overlay.children.find(childOverlay => childOverlay.id === customOverlayId)) {
                        if (overlay.activeChild === customOverlayId) {
                            if (overlay.label === i18n.t("Arbres")) {
                                this.treesLayer.activeChild = i18n.t("Aucun");
                                this.treesLayerNotClustered.activeChild = i18n.t("Aucun");
                                this.updateTreesLayerStyle(this.state.highlightedElements);
                            } else if (overlay.label === i18n.t("Espaces verts")) {
                                this.greenSpacesLayer.activeChild = i18n.t("Aucun");
                                this.updateGreenSpacesLayerStyle();
                            } else if (overlay.label === i18n.t("Mobilier urbain")) {
                                this.furnituresLayer.activeChild = i18n.t("Aucun");
                                this.furnituresLayerNotClustered.activeChild = i18n.t("Aucun");
                                this.updateFurnituresLayerStyle(this.state.highlightedElements);
                            }

                            overlay.activeChild = i18n.t("Aucun");
                        }

                        overlay.children = overlay.children.filter(childOverlay => childOverlay.id !== customOverlayId);
                    }
                });
            }
            return { overlays };
        });
    }

    updateOverlayInControlLayer = (label, layer, customOverlayId = null) => { // Si on fournit un customOverlayId, on update juste le libellé de la carte custom
        this.setState(prevState => {
            const overlays = this.copyPrevOverlays(prevState.overlays);
            const index = overlays.findIndex(overlay => customOverlayId ? overlay.id === customOverlayId : overlay.label === label);
            if (index !== -1) {
                layer.label = customOverlayId ? label : overlays[index].layer.label || label;
                layer.parentLabel = overlays[index].layer.parentLabel;
                layer.activeChild = overlays[index].layer.activeChild;
                layer.activeOptions = overlays[index].layer.activeOptions;
                const isVisible = this.map.hasLayer(overlays[index].layer);
                if (isVisible) this.map.removeLayer(overlays[index].layer);

                overlays[index].layer = layer;
                if (overlays[index].showToggleLayer === undefined) overlays[index].showToggleLayer = true;
                if (overlays[index].showToggleLegend === undefined) overlays[index].showToggleLegend = true;
                if (customOverlayId) overlays[index].label = label;
                if (isVisible) this.map.addLayer(layer);
            }

            return { overlays };
        });
    }

    toggleOverlay = (overlay, toggleInMenu = true, toggledOption = false) => {
        const { overlays } = this.state;
        if (overlay.parentLabel) {
            const parentOverlay = overlays.find(parentOverlay => parentOverlay.label === overlay.parentLabel);
            if (parentOverlay) {
                if (parentOverlay.layer) {
                    if (toggledOption) { // Ex : Diamètre des couronnes
                        this.setState(prevState => {
                            const overlays = this.copyPrevOverlays(prevState.overlays);
                            const parentOverlay = overlays.find(parentOverlay => parentOverlay.label === overlay.parentLabel)
                            const isToggled = parentOverlay.activeOptions?.includes(overlay.label);
                            parentOverlay.activeOptions = isToggled
                                ? (parentOverlay.activeOptions || []).filter(option => option !== overlay.label)
                                : [...(parentOverlay.activeOptions || []), overlay.label];
                            if (toggleInMenu && !isToggled) parentOverlay.isShown = true;

                            this.treesLayer.activeOptions = parentOverlay.activeOptions;
                            this.treesLayerNotClustered.activeOptions = parentOverlay.activeOptions;

                            return { overlays };
                        }, () => this.updateTreesLayerStyle(this.state.highlightedElements));
                    } else if (parentOverlay.activeChild === (overlay.id || overlay.label) && this.map.hasLayer(parentOverlay.layer)) {
                        this.map.removeLayer(parentOverlay.layer);
                        if (toggleInMenu)
                            this.setState(prevState => {
                                const overlays = this.copyPrevOverlays(prevState.overlays);
                                const parentOverlay = overlays.find(parentOverlay => parentOverlay.label === overlay.parentLabel)
                                parentOverlay.isShown = false;
                                return { overlays };
                            });
                    } else {
                        if (!this.map.hasLayer(parentOverlay.layer)) this.map.addLayer(parentOverlay.layer);

                        if (parentOverlay.label === i18n.t("Arbres")) {
                            this.treesLayer.activeChild = overlay.id || overlay.label;
                            this.treesLayerNotClustered.activeChild = overlay.id || overlay.label;
                            this.updateTreesLayerStyle(this.state.highlightedElements);
                        } else if (parentOverlay.label === i18n.t("Espaces verts")) {
                            this.greenSpacesLayer.activeChild = overlay.id || overlay.label;
                            this.updateGreenSpacesLayerStyle();
                        } else if (parentOverlay.label === i18n.t("Mobilier urbain")) {
                            this.furnituresLayer.activeChild = overlay.id || overlay.label;
                            this.furnituresLayerNotClustered.activeChild = overlay.id || overlay.label;
                            this.updateFurnituresLayerStyle(this.state.highlightedElements);
                        }

                        this.setState(prevState => {
                            const overlays = this.copyPrevOverlays(prevState.overlays);
                            const parentOverlay = overlays.find(parentOverlay => parentOverlay.label === overlay.parentLabel)
                            parentOverlay.activeChild = overlay.id || overlay.label;
                            if (toggleInMenu) parentOverlay.isShown = true;
                            return { overlays };
                        });
                    }
                } else if (overlay.layer) {
                    const isShown = this.map.hasLayer(overlay.layer);
                    if (isShown) this.map.removeLayer(overlay.layer);
                    else {
                        if (parentOverlay.onlyOne) {
                            const activeChildOverlay = parentOverlay.children.find(childOverlay => parentOverlay.activeChild === childOverlay.label);
                            if (activeChildOverlay && this.map.hasLayer(activeChildOverlay.layer)) this.map.removeLayer(activeChildOverlay.layer);
                        }
                        this.map.addLayer(overlay.layer);
                    }

                    if (parentOverlay.label === i18n.t("Services écosystémiques")) this.updateHeatmaps();

                    if (parentOverlay.onlyOne || toggleInMenu)
                        this.setState(prevState => {
                            const overlays = this.copyPrevOverlays(prevState.overlays);
                            const parentOverlay = overlays.find(parentOverlay => parentOverlay.label === overlay.parentLabel);
                            const childOverlay = parentOverlay.children.find(childOverlay => childOverlay.label === overlay.label);
                            if (parentOverlay.onlyOne) {
                                const activeChildOverlay = parentOverlay.children.find(childOverlay => parentOverlay.activeChild === childOverlay.label);
                                if (activeChildOverlay !== childOverlay) {
                                    if (activeChildOverlay && toggleInMenu) activeChildOverlay.isShown = false;
                                    parentOverlay.activeChild = childOverlay.label;
                                }
                            }
                            if (toggleInMenu) {
                                childOverlay.isShown = !isShown;
                                parentOverlay.isShown = parentOverlay.children.some(childOverlay => childOverlay.isShown);
                            }

                            return { overlays };
                        });
                }
            }
        } else {
            if (overlay.layer) {
                const isShown = this.map.hasLayer(overlay.layer);
                if (isShown) this.map.removeLayer(overlay.layer);
                else this.map.addLayer(overlay.layer);

                // Références
                if (overlay.originalLabel === 'Arbres') this.renderMarkersReferences('Arbre', this.state.references.trees, !isShown);
                else if (overlay.originalLabel === 'Mobilier urbain') this.renderMarkersReferences('Mobilier', this.state.references.furnitures, !isShown);
                else if (overlay.originalLabel === 'Repères') this.renderMarkersReferences('Repère', this.state.references.furnitures, !isShown);
                else if (overlay.originalLabel === 'Espaces verts') this.renderGreenSpacesReferences(this.state.references.greenSpaces, !isShown);

                if (isMobile && !isShown)
                    if (overlay.label === i18n.t("Arbres"))
                        this.updateTreesLayerStyle(this.state.highlightedElements);
                    else if (overlay.label === i18n.t("Mobilier urbain"))
                        this.updateFurnituresLayerStyle(this.state.highlightedElements);

                if (toggleInMenu)
                    this.setState(prevState => {
                        const overlays = this.copyPrevOverlays(prevState.overlays);
                        const parentOverlay = overlays.find(parentOverlay => overlay.id ? parentOverlay.id === overlay.id : parentOverlay.label === overlay.label);
                        parentOverlay.isShown = !isShown;
                        return { overlays };
                    });
            } else {
                if (overlay.isShown) {
                    if (overlay.onlyOne) {
                        const activeChildOverlay = overlay.children.find(childOverlay => overlay.activeChild === childOverlay.label);
                        if (this.map.hasLayer(activeChildOverlay.layer)) this.map.removeLayer(activeChildOverlay.layer);
                    } else overlay.children.forEach(childOverlay => {
                        if (this.map.hasLayer(childOverlay.layer)) this.map.removeLayer(childOverlay.layer);
                    })
                } else {
                    if (overlay.onlyOne) {
                        const activeChildOverlay = overlay.children.find(childOverlay => overlay.activeChild === childOverlay.label);
                        if (!this.map.hasLayer(activeChildOverlay.layer)) this.map.addLayer(activeChildOverlay.layer);
                    } else overlay.children.forEach(childOverlay => {
                        if (!this.map.hasLayer(childOverlay.layer)) this.map.addLayer(childOverlay.layer);
                    });
                }

                if (overlay.label === i18n.t("Services écosystémiques")) this.updateHeatmaps();
                if (toggleInMenu)
                    this.setState(prevState => {
                        const overlays = this.copyPrevOverlays(prevState.overlays);
                        const parentOverlay = overlays.find(parentOverlay => parentOverlay.label === overlay.label);
                        parentOverlay.isShown = !parentOverlay.isShown;
                        if (parentOverlay.onlyOne) {
                            const activeChildOverlay = parentOverlay.children.find(childOverlay => parentOverlay.activeChild === childOverlay.label);
                            activeChildOverlay.isShown = parentOverlay.isShown;
                        } else parentOverlay.children.forEach(childOverlay => childOverlay.isShown = parentOverlay.isShown);
                        return { overlays };
                    });
            }
        }
    }

    toggleLegend = (overlay) => {
        this.setState(prevState => {
            const overlays = this.copyPrevOverlays(prevState.overlays);
            const parentOverlay = overlays.find(parentOverlay => overlay.id ? parentOverlay.id === overlay.id : parentOverlay.label === overlay.label);
            parentOverlay.isLegendShown = !parentOverlay.isLegendShown;
            this.legendRef.current.toggleLegend(this.legendRef.current.getLegendType(parentOverlay), parentOverlay.isLegendShown);
            return { overlays };
        });
    }

    showBaseLayer = (layerToShow) => {
        const baseLayerToShow = this.state.baseLayers.find(baseLayer => baseLayer.layer === layerToShow);
        const baseLayerToHide = this.state.baseLayers.find(baseLayer => baseLayer.layer !== layerToShow && this.map.hasLayer(baseLayer.layer));
        if (baseLayerToHide) {
            this.map.removeLayer(baseLayerToHide.layer);
            this.map.addLayer(baseLayerToShow.layer);
        }

        this.mapSearch = UrlsUtil.adjustSearch(this.mapSearch, { baseLayer: baseLayerToShow?.name });
        if (!this.props.match.params.path1) this.props.history.push({ search: this.mapSearch.short });

        this.setState(prevState => ({
            baseLayers: prevState.baseLayers.map(baseLayer => ({ ...baseLayer, isShown: baseLayer.layer === layerToShow }))
        }));

        return baseLayerToHide?.layer;
    }

    copyPrevBaseLayers = (prevBaseLayers) => {
        return prevBaseLayers.map(prevBaseLayer => {
            const baseLayer = { ...prevBaseLayer };
            return baseLayer;
        });
    }

    addBaseLayerToControlLayer = (label, layer, customMap = null) => {
        return new Promise((resolve) => {
            this.setState(prevState => {
                const baseLayers = this.copyPrevBaseLayers(prevState.baseLayers);
                baseLayers.push({ label, name: customMap?.id || label, layer, id: customMap?.id });
                return { baseLayers };
            }, resolve);
        });
    }

    updateBaseLayerInControlLayer = (baseLayerId, label, layer) => {
        this.setState(prevState => {
            const baseLayers = this.copyPrevBaseLayers(prevState.baseLayers);
            const baseLayer = baseLayers.find(baseLayer => baseLayer.id === baseLayerId);
            baseLayer.label = label;
            const isVisible = this.map.hasLayer(baseLayer.layer);
            if (isVisible) this.map.removeLayer(baseLayer.layer);
            baseLayer.layer = layer;
            if (isVisible) this.map.addLayer(layer);

            return { baseLayers };
        });
    }

    removeBaseLayerFromControlLayer = (baseLayerId) => {
        const baseLayer = this.state.baseLayers.find(baseLayer => baseLayer.id === baseLayerId);
        if (baseLayer.isShown) {
            this.showBaseLayer(this.props.isDarkTheme ? this.googleLayerRoadmapDark : this.googleLayerRoadmap);
            OfflineUtil.updateProjectView(this.props.project?.id || 0, 'favoriteTiles', this.props.isDarkTheme ? 'Roadmap Dark' : 'Roadmap');
        }

        this.setState(prevState => {
            let baseLayers = this.copyPrevBaseLayers(prevState.baseLayers);
            baseLayers = baseLayers.filter(baseLayer => baseLayer.id !== baseLayerId);
            return { baseLayers };
        });
    }


    toggleLayer = ({ parentLayer = null, parentLayerLabel = null, layer = null, layerLabel = null, toggleInMenu = true } = {}) => {
        const { overlays } = this.state;

        if (parentLayer || parentLayerLabel) { // Si on a précisé un layer parent
            const parentOverlay = overlays.find(overlay => (parentLayer && overlay.layer === parentLayer) || (parentLayerLabel && overlay.label === parentLayerLabel));
            if (parentOverlay) {
                if (layer || layerLabel) { // Si on a précisé un layer, on le cherche dans les enfants de l'overlay trouvé
                    const childOverlay = parentOverlay.children.find(overlay => (layer && overlay.layer === layer) || (layerLabel && overlay.label === layerLabel));
                    if (childOverlay) this.toggleOverlay(childOverlay, toggleInMenu);
                } else this.toggleOverlay(parentOverlay, toggleInMenu) // Sinon on toggle l'overlay parent
            }
        } else if (layer || layerLabel) { // Si on a pas précisé de layer parent
            const parentOverlay = overlays.find(overlay => (layer && overlay.layer === layer) || (layerLabel && overlay.label === layerLabel));
            if (parentOverlay) this.toggleOverlay(parentOverlay, toggleInMenu) // Si le layer est trouvé dans les layers parents, on le toggle
            else { // Sinon on recherche un overlay enfant correspondant & on le toggle si trouvé
                let childOverlay;
                overlays.forEach(overlay => {
                    if (!childOverlay && overlay.children) {
                        childOverlay = overlay.children.find(childOverlay => (layer && childOverlay.layer === layer) || (layerLabel && childOverlay.label === layerLabel));
                        if (childOverlay) this.toggleOverlay(childOverlay, toggleInMenu);
                    }
                });
            }
        }
    }

    updateThematicMapStyle = (thematicMap) => {
        const { overlays } = this.state;
        const overlay = overlays.find(overlay => overlay.activeChild === thematicMap.id);
        if (overlay) { // Si la carte thématique modifiée était active sur l'un des overlays parents
            if (overlay.label === i18n.t("Arbres")) this.updateTreesLayerStyle(this.state.highlightedElements);
            else if (overlay.label === i18n.t("Espaces verts")) this.updateGreenSpacesLayerStyle();
            else if (overlay.label === i18n.t("Mobilier urbain")) this.updateFurnituresLayerStyle(this.state.highlightedElements);
            if ([i18n.t("Arbres"), i18n.t("Espaces verts"), i18n.t("Mobilier urbain")].includes(overlay.label))
                this.updateLegend(overlay.label);
        }
    }

    showClusteredLayer = (category, toShow) => {
        const selectedElements = this.state.selectedElements;
        const layerName = category === 'Arbre' ? i18n.t("Arbres") : i18n.t("Mobilier urbain");
        const layer = category === 'Arbre' ? this.treesLayer : this.furnituresLayer;
        const layerNotClustered = category === 'Arbre' ? this.treesLayerNotClustered : this.furnituresLayerNotClustered;
        if (toShow) this.updateOverlayInControlLayer(layerName, layer);
        else this.updateOverlayInControlLayer(layerName, layerNotClustered);

        if (selectedElements.length) {
            let newSelectedElements = [];
            selectedElements.forEach(selectedElement => {
                if (selectedElement.feature.properties.category === category) {
                    if ((toShow && !layer.hasLayer(selectedElement)) || (!toShow && !layerNotClustered.hasLayer(selectedElement))) {
                        const layers = !layerNotClustered.hasLayer(selectedElement) ? layerNotClustered.getLayers() : layer.getLayers();
                        const otherElement = layers.find(layer => layer.feature.id === selectedElement.feature.id);
                        if (otherElement) newSelectedElements.push(otherElement);
                    } else newSelectedElements.push(selectedElement);
                } else newSelectedElements.push(selectedElement);
            });

            if (newSelectedElements.length) this.selectElements(newSelectedElements, true);
        }
    }

    showLoader = (active, location, message = '') => this.setState({ loader: { active, location, message } });
    changeModalContentType = (type, title, resetElements = false, fromRedirect = false) => { // Permet de repasser de l'historique au formulaire fourni en paramètres
        const modalPath = UrlsUtil.getModalPath(type, this.props.layer?.[0]?.feature);
        if (modalPath) this.props.history.push({ pathname: fromRedirect ? window.location.pathname : `/projects/${this.props.project.id}/${modalPath}`, search: fromRedirect ? this.props.location?.search : null });
        this.setState({
            modal: { visible: type !== 'StationForm', title },
            modalContentType: type
        });
    }

    handleArrowShortcuts = e => {
        if (document.activeElement.className.includes('dimmable dimmed')) {
            if (e.keyCode === 37) this.previousElement();
            if (e.keyCode === 39) this.nextElement();
        }
    }

    showModal = (layers, shouldReset = false) => { // Affiche le modal si on est pas en mode drag
        if (!this.map.pm.globalDragModeEnabled()) {
            const layer = layers[0];
            const category = layer.feature.properties.category;

            if (category !== 'Station' && !this.arrowShortcutsEnabled) {
                this.arrowShortcutsEnabled = true;
                document.addEventListener('keydown', this.handleArrowShortcuts);
            }

            this.props.setLayer(layers).then(() => {
                const defaultContentType = category === 'Arbre' ? 'TreeDetail'
                    : category === 'Espace vert' ? 'GreenSpaceDetail'
                        : category === 'Mobilier' ? 'FurnitureDetail'
                            : category === 'Repère' ? 'MarkerDetail'
                                : 'StationForm';
                this.changeModalContentType(
                    (shouldReset ? '' : this.state.modalContentType) || defaultContentType,
                    (shouldReset ? '' : this.state.modal.title) || i18n.t("Détails")
                );
                this.props.setCurrentAction('viewingModal');
            });
        }
    }

    previousElement = (refToSelect, projectId = 0) => {
        let previousElement;
        const currentElement = this.props.layer[0];
        const category = currentElement.feature.properties.category;
        projectId = projectId || this.props.project.id;

        const selectPreviousElement = (layers) => {
            layers.sort((a, b) => {
                if (a.feature.properties.projectReference === b.feature.properties.projectReference)
                    return a.feature.projectId - b.feature.projectId;
                return a.feature.properties.projectReference > b.feature.properties.projectReference ? 1 : -1;
            });

            if (!refToSelect) {
                const index = layers.findIndex(l => l.feature.id === currentElement.feature.id);
                if (index === 0) previousElement = layers[layers.length - 1];
                else previousElement = layers[index - 1];
            } else previousElement = layers.find(l => l.feature.properties.projectReference === refToSelect && l.feature.projectId === projectId);
        }

        switch (category) {
            case 'Arbre': selectPreviousElement(this.treesLayerNotClustered.getLayers()); break;
            case 'Espace vert': selectPreviousElement(this.greenSpacesLayer.getLayers()); break;
            case 'Mobilier': selectPreviousElement(this.furnituresLayerNotClustered.getLayers()); break;
            case 'Repère': selectPreviousElement(this.markersLayer.getLayers()); break;
            default: break;
        }

        this.props.setEditedProperties(null).then(() => previousElement.fireEvent('click'));
    }

    nextElement = (refToSelect = null, projectId = 0) => {
        let nextElement;
        const currentElement = this.props.layer[0];
        const category = currentElement.feature.properties.category;
        projectId = projectId || this.props.project.id;

        const selectNextElement = (layers) => {
            layers.sort((a, b) => {
                if (a.feature.properties.projectReference === b.feature.properties.projectReference)
                    return a.feature.projectId - b.feature.projectId;
                return a.feature.properties.projectReference > b.feature.properties.projectReference ? 1 : -1;
            });

            if (!refToSelect) {
                const index = layers.findIndex(l => l.feature.id === currentElement.feature.id);
                if (index === layers.length - 1) nextElement = layers[0];
                else nextElement = layers[index + 1];
            } else nextElement = layers.find(l => l.feature.properties.projectReference === refToSelect && l.feature.projectId === projectId);
        }

        switch (category) {
            case 'Arbre': selectNextElement(this.treesLayerNotClustered.getLayers()); break;
            case 'Espace vert': selectNextElement(this.greenSpacesLayer.getLayers()); break;
            case 'Mobilier': selectNextElement(this.furnituresLayerNotClustered.getLayers()); break;
            case 'Repère': selectNextElement(this.markersLayer.getLayers()); break;
            default: break;
        }

        this.props.setEditedProperties(null).then(() => nextElement.fireEvent('click'));
    }

    goToElement = (customReference, projectId = 0) => {
        let elementToShow;
        const category = this.props.layer[0].feature.properties.category;
        projectId = projectId || this.props.project.id;

        const selectElement = (layers) => {
            elementToShow = layers.find(l => l.feature.properties.customReference === customReference && l.feature.projectId === projectId);
        }

        switch (category) {
            case 'Arbre': selectElement(this.treesLayerNotClustered.getLayers()); break;
            case 'Espace vert': selectElement(this.greenSpacesLayer.getLayers()); break;
            case 'Mobilier': selectElement(this.furnituresLayerNotClustered.getLayers()); break;
            case 'Repère': selectElement(this.markersLayer.getLayers()); break;
            default: break;
        }

        if (elementToShow) this.props.setEditedProperties(null).then(() => elementToShow.fireEvent('click'));
    }

    hideForm = (success, { resetLayer = true } = {}) => { // Lorsqu'on clique sur le bouton annuler dans un formulaires
        this.resetPanesIndexes();
        this.setState({ modal: { visible: false } }, () => {
            if (this.props.userProjects) this.props.setUserProjects(null);
            if (this.props.projectListState) this.props.setProjectListState(null);
            document.getElementById('projectMap').focus();
            if (this.arrowShortcutsEnabled) {
                this.arrowShortcutsEnabled = false;
                document.removeEventListener('keydown', this.handleArrowShortcuts);
            }
            if (this.props.currentAction === 'duplicating') {
                this.showClusteredLayer('Arbre', true);
                this.showClusteredLayer('Mobilier', true);
            }
            if (this.stickyRotateStartLatLngs) this.stickyRotateStartLatLngs = null;
            if (['addingRectangle', 'addingCircle', 'addingBackgroundImage'].includes(this.props.currentAction) && !success) this.exitSubTool(true);
            if (this.state.modalContentType !== 'FilterForm' && !success
                && this.props.layer && !this.props.layer[0]) // Si on est pas en mode édition et que l'ajout a échoué
                this.map.removeLayer(this.props.layer); // On retire le layer affiché sur la map
            if (['ProjectList', 'ProjectModificationForm', 'ProjectDuplicationForm', 'ProjectCreationForm', 'CollaboratorsForm', 'RoleList', 'ImportForm', 'ExportForm', 'ProjectFilesGallery'].includes(this.state.modalContentType))
                this.props.showProjectList(false);
            if (!['', 'managingActionElements', 'managingEventElements', 'modifyingProjectSurroundings'].includes(this.props.currentAction))
                this.props.setEditedProperties(null);
            if (!['removing', 'merging', 'cutting', 'modifyingProjectSurroundings', 'addingBackgroundImage'].includes(this.props.currentAction)) {
                if (!['managingActionElements', 'managingEventElements', 'linkingElements'].includes(this.props.currentAction)) this.props.setCurrentAction('');
                this.map.pm.setGlobalOptions({ allowSelfIntersection: true });
                this.map.pm.disableDraw(); // On désactive le mode 'dessin'
                this.toolbarRef.current.resetButtonSelection(1);
            }

            // Modification de l'URL
            this.props.history.push({ pathname: '/projects/' + this.props.project.id, search: this.mapSearch.short });

            this.setState({
                modalContentType: '',
                closingTable: false,
                removingWithShortcut: false,
                thematicMapToEdit: null,
                wmsServiceToEdit: null,
                scan: {}
            });
            if (!['managingActionElements', 'managingEventElements', 'linkingElements'].includes(this.props.currentAction) && resetLayer) this.props.setLayer(null);
            this.props.setElementHistory(null);
            this.props.setActionHistory(null);
        });
    }

    hideRemoveForm = (success, type) => {
        if (['Arbre', 'Mobilier'].includes(type)) this.showClusteredLayer(type, true);
        this.exitSubTool(true);
        this.props.setCurrentAction('');
        this.hideForm(success);
    }

    showRemoveForm = () => { // Attribue l'event d'affichage du formulaire de suppression aux layers fournis en paramètres
        this.setState({
            modal: { visible: false, title: '' },
            modalContentType: 'RemoveForm'
        });
    }

    showCutForm = () => {
        this.setState({
            modal: { visible: false, title: '' },
            modalContentType: 'CutForm'
        });
    }

    showProjectMap = (project) => {
        this.isOpeningProject = true;
        if (project.type === 'folder') {
            const { projectCollaborators } = this.props;
            const token = new Cookies().get('token');
            const id = token ? jwtDecode(token).id : null;
            const index = projectCollaborators.findIndex(up => up.user.id === id);
            this.props.setRights(RightsUtil.getRights(projectCollaborators[index].projectRole, true));
        }
        this.props.setUserProjects(null);
        this.props.history.push('/projects/' + project.id);
        setTimeout(() => this.props.setProject(project), 100); //! Assure qu'on modifie l'historique avant de changer de projet
    }

    showForm = (project, form, title) => {
        if (form === 'ProjectDuplicationForm') this.setState({ projectToDuplicate: project, projectToEdit: null }, () => this.changeModalContentType(form, title));
        else this.setState({ projectToEdit: project, projectToDuplicate: null }, () => this.changeModalContentType(form, title));
    }

    removeFilters = (applyMainFilters, filters = null) => {
        if (!this.state.mainFilters && !filters) return;
        let categories = (this.state.mainFilters || filters).find(property => property.name === 'category')?.filters?.flatMap(filter => filter.value).filter(category => category);
        if (!categories || categories.length < 1) categories = ['Arbre', 'Espace vert', 'Mobilier'];

        const categoriesProps = {
            'Arbre': { layerContainers: [this.treesLayer, this.treesLayerNotClustered], legendName: i18n.t("Arbres") },
            'Espace vert': { layerContainers: [this.greenSpacesLayer], legendName: i18n.t("Espaces verts") },
            'Mobilier': { layerContainers: [this.furnituresLayer, this.furnituresLayerNotClustered], legendName: i18n.t("Mobilier urbain") }
        };

        categories.forEach(category => {
            const { layerContainers, legendName } = categoriesProps[category];
            layerContainers.forEach(layerContainer => {
                if (this.filteredLayers[layerContainer.filterId])
                    this.filteredLayers[layerContainer.filterId].forEach(layer => {
                        if (layer) layerContainer.addLayer(layer);
                    });
            });
            this.updateLegend(legendName);
            if (category === 'Arbre') this.updateHeatmaps();
        });

        if (!applyMainFilters) { // Si le remove ne provient pas des graphiques personnalisés
            ['Arbre', 'Espace vert', 'Mobilier'].filter(category => !categories.includes(category)).forEach(category => {
                const { layerContainers } = categoriesProps[category];
                layerContainers.forEach(layerContainer => {
                    if (this.filteredLayers[layerContainer.filterId]?.wasRemoved && !this.map.hasLayer(layerContainer))
                        this.toggleLayer({ layer: layerContainer });
                });
            });

            this.mapSearch = UrlsUtil.adjustSearch(this.mapSearch, { filters: null });
            if (!this.props.match.params.path1) this.props.history.push({ search: this.mapSearch.short });
        }

        this.filteredLayers = {};
        if (applyMainFilters && this.state.mainFilters) this.filterLayers(this.state.mainFilters);
    }

    filterLayers = (properties, isMainFilters = false, zoomOnElements = true) => {
        this.unselectElements();
        if (isMainFilters) {
            this.setState({ mainFilters: properties });
            this.mapSearch = UrlsUtil.adjustSearch(this.mapSearch, { filters: JSON.stringify(properties) });
            if (!this.props.match.params.path1) this.props.history.push({ search: this.mapSearch.short });
        }

        let categories = properties.find(property => property.name === 'category')?.filters?.flatMap(filter => filter.value).filter(category => category);
        if (!categories || categories.length < 1) categories = ['Arbre', 'Espace vert', 'Mobilier', 'Repère'];

        const getPropertyValue = (propertyName, layerValue, layer = null) => {
            if (layerValue !== undefined && layerValue !== null && !Array.isArray(layerValue)) {
                if (isNaN(layerValue))
                    layerValue = layerValue.toLowerCase();
                else if (typeof layerValue !== 'boolean')
                    layerValue = Number(layerValue);
            }
            if (['projectActionId', 'actionId', 'actionDate', 'nbActions', 'projectEventId', 'eventId', 'eventDate', 'nbEvents', 'nbPhotos', 'nbFiles'].includes(propertyName)) layerValue = layer.feature.id;
            if (['localisation', 'station'].includes(propertyName)) layerValue = layer.getLatLng ? layer.getLatLng() : layer.getLatLngs();
            if (['creationDate', 'modificationDate'].includes(propertyName)) layerValue = DatesUtil.convertUTCDateToDate(layer.feature.properties[propertyName]);
            if (['fruitProduction', 'isFruit', 'vernacularName', 'gender', 'species', 'cultivar'].includes(propertyName))
                layerValue = layer.feature.properties.essenceId;
            if (['dominantVernacularName', 'dominantGender', 'dominantSpecies', 'dominantCultivar'].includes(propertyName))
                layerValue = layer.feature.properties.dominantEssenceId;

            if (layerValue || propertyName === 'toCutDown') {
                switch (propertyName) {
                    case 'toCutDown': return layerValue || 0;
                    case 'treePortId': return this.props.treePorts.find(x => x.id === layerValue)?.label.toLowerCase();
                    case 'coverTypeId': return this.props.coverTypes.find(x => x.id === layerValue)?.label.toLowerCase();
                    case 'vigorId': return this.props.vigors.find(x => x.id === layerValue)?.label.toLowerCase();
                    case 'healthReviewId': case 'averageHealthReviewId': return this.props.healthReviews.find(x => x.id === layerValue)?.value;
                    case 'ontogenicStageId': return this.props.ontogenicStages.find(x => x.id === layerValue)?.value;
                    case 'riskId': return this.props.risks.find(x => x.id === layerValue)?.label.toLowerCase();
                    case 'tippingRiskId': return this.props.tippingRisks.find(x => x.id === layerValue)?.value;
                    case 'organCaliberId': return this.props.organCalibers.find(x => x.id === layerValue)?.value;
                    case 'targetId': return this.props.targets.find(x => x.id === layerValue)?.value;
                    case 'plantationTypeId': return this.props.plantationTypes.find(x => x.id === layerValue)?.label.toLowerCase();
                    case 'numberOfTrunks': return layerValue;
                    case 'plantationCoefficientId': return this.props.plantationCoefficients.find(x => x.id === layerValue)?.value;
                    case 'situationCoefficientId': return this.props.situationCoefficients.find(x => x.id === layerValue)?.value;
                    case 'patrimonialCoefficientId': return this.props.patrimonialCoefficients.find(x => x.id === layerValue)?.value;
                    case 'fruitProduction': return this.props.essences.find(x => x.id === layerValue)?.fruitProduction;
                    case 'isFruit': return this.props.essences.find(x => x.id === layerValue)?.fruitProduction > 0 ? true : false;
                    case 'vernacularName': case 'gender': case 'species': case 'cultivar':
                        return this.props.essences.find(x => x.id === layerValue)?.[propertyName]?.toLowerCase();
                    case 'dominantVernacularName': return this.props.essences.find(x => x.id === layerValue)?.vernacularName?.toLowerCase();
                    case 'dominantGender': return this.props.essences.find(x => x.id === layerValue)?.gender?.toLowerCase();
                    case 'dominantSpecies': return this.props.essences.find(x => x.id === layerValue)?.species?.toLowerCase();
                    case 'dominantCultivar': return this.props.essences.find(x => x.id === layerValue)?.cultivar?.toLowerCase();
                    case 'spaceFunctionId': return this.props.spaceFunctions.find(x => x.id === layerValue)?.label.toLowerCase();
                    case 'spaceTypeId': return this.props.spaceTypes.find(x => x.id === layerValue)?.label.toLowerCase();
                    case 'dominantCompositionId': return this.props.dominantCompositions.find(x => x.id === layerValue)?.label.toLowerCase();
                    case 'managementClassId': return this.props.managementClasses.find(x => x.id === layerValue)?.value;
                    case 'conditionId': return this.props.conditions.find(x => x.id === layerValue)?.label.toLowerCase();
                    case 'typeId': return this.props.furnitureTypes.find(x => x.id === layerValue)?.label.toLowerCase();
                    case 'runoffCoefficientId':
                        const dominantComposition = this.props.dominantCompositions.find(x => x.id === layerValue);
                        let runoffCoefficient = this.props.runoffCoefficients.find(x => x.id === dominantComposition?.runoffCoefficientId);
                        runoffCoefficient = GreenSpacesUtil.getRunoffCoefficientString(runoffCoefficient);
                        if (!isNaN(runoffCoefficient)) runoffCoefficient = Number(runoffCoefficient);
                        return runoffCoefficient;
                    case 'interactionId': return layerValue.map(lv => this.props.interactions.find(x => x.id === lv)?.label).filter(lv => lv);
                    case 'microHabitatId': return layerValue.map(lv => this.props.microHabitats.find(x => x.id === lv)?.label).filter(lv => lv);
                    case 'rootSymptomId': return layerValue.map(lv => this.props.rootSymptoms.find(x => x.id === lv)?.label).filter(lv => lv);
                    case 'collarSymptomId': return layerValue.map(lv => this.props.collarSymptoms.find(x => x.id === lv)?.label).filter(lv => lv);
                    case 'trunkSymptomId': return layerValue.map(lv => this.props.trunkSymptoms.find(x => x.id === lv)?.label).filter(lv => lv);
                    case 'branchSymptomId': return layerValue.map(lv => this.props.branchSymptoms.find(x => x.id === lv)?.label).filter(lv => lv);
                    case 'leafSymptomId': return layerValue.map(lv => this.props.leafSymptoms.find(x => x.id === lv)?.label).filter(lv => lv);
                    case 'rootPathogenId': case 'collarPathogenId': case 'trunkPathogenId': case 'branchPathogenId': case 'leafPathogenId':
                        return layerValue.map(lv => this.props.pathogens.find(x => x.id === lv)?.label).filter(lv => lv);
                    case 'rootPestId': case 'collarPestId': case 'trunkPestId': case 'branchPestId': case 'leafPestId':
                        return layerValue.map(lv => this.props.pests.find(x => x.id === lv)?.label).filter(lv => lv);
                    case 'trunkEpiphyteId': case 'branchEpiphyteId':
                        return layerValue.map(lv => this.props.epiphytes.find(x => x.id === lv)?.label).filter(lv => lv);
                    case 'actionId':
                        let actions = [];
                        this.props.projectActions
                            .filter(projectAction => categories.some(category => projectAction.action.categories.includes(category)))
                            .forEach(projectAction => {
                                if (projectAction.projectActionElements.find(pae => pae.elementId === layerValue)) actions.push(projectAction.action.label);
                            });
                        return actions;
                    case 'actionDate':
                        const actionRecurrences = this.props.projectActions
                            .filter(projectAction => categories.some(category => projectAction.action.categories.includes(category)))
                            .flatMap(projectAction => projectAction.projectActionElements.find(pae => pae.elementId === layerValue)?.projectActionElementRecurrences?.map(paer => ({ label: projectAction.action.label, date: paer.date })))
                            .filter(paer => paer);
                        return actionRecurrences;
                    case 'projectActionId':
                        let projectActions = [];
                        this.props.projectActions
                            .filter(projectAction => categories.some(category => projectAction.action.categories.includes(category)))
                            .forEach(projectAction => {
                                if (projectAction.projectActionElements.find(pae => pae.elementId === layerValue)) projectActions.push(projectAction.id);
                            });
                        return projectActions;
                    case 'nbActions': return this.props.projectActions.filter(projectAction => categories.some(category => projectAction.action.categories.includes(category)) && projectAction.projectActionElements.find(pae => pae.elementId === layerValue)).length || 0;
                    case 'eventId':
                        let events = [];
                        this.props.projectEvents
                            .forEach(projectEvent => {
                                if (projectEvent.projectEventElements.find(pee => pee.elementId === layerValue)) events.push(projectEvent.event.label);
                            });
                        return events;
                    case 'eventDate':
                        const projectEventElements = this.props.projectEvents
                            .flatMap(projectEvent => projectEvent.projectEventElements.filter(pee => pee.elementId === layerValue).map(pee => ({ label: projectEvent.event.label, date: projectEvent.date })))
                            .filter(pee => pee);
                        return projectEventElements;
                    case 'projectEventId':
                        let projectEvents = [];
                        this.props.projectEvents
                            .forEach(projectEvent => {
                                if (projectEvent.projectEventElements.find(pee => pee.elementId === layerValue)) projectEvents.push(projectEvent.id);
                            });
                        return projectEvents;
                    case 'nbEvents': return this.props.projectEvents.filter(projectEvent => projectEvent.projectEventElements.find(pee => pee.elementId === layerValue)).length || 0;
                    case 'nbPhotos': return this.props.photosGalleries?.filter(fileInfo => fileInfo.elementId === layerValue).length || 0;
                    case 'nbFiles': return this.props.filesGalleries?.filter(fileInfo => fileInfo.elementId === layerValue).length || 0;
                    default: return layerValue;
                }
            } else return layerValue;
        }

        let remainingLayers = [];
        const filter = (layer, layerContainer) => {
            const filteredProperties = properties.filter(property => (!property.category || property.category === layer.feature?.properties.category) && (!property.categories || property.categories.includes(layer.feature?.properties.category)));
            filteredProperties.forEach(property => { // Pour chaque propriété dans les filtres
                if (property.filters?.length > 0) { // Si la propriété contient une liste de valeurs
                    let propertyRoot = layer.feature.properties; // On détermine l'endroit où chercher la propriété dans le layer
                    if (['height', 'circumference', 'crownDiameter'].includes(property.name)) {
                        const biggestTrunk = TreesUtil.getBiggestTrunk(layer.feature.properties.trunks);
                        propertyRoot = biggestTrunk || {};
                    }

                    if (property.name === 'trunkHeight') propertyRoot = layer.feature.properties.dimensions;
                    if (property.customField) propertyRoot = layer.feature.properties.customFields || {};
                    let flag = false;
                    let numberOfUndefined = 0;
                    let numberOfEmpty = 0;
                    property.filters.forEach(filter => { // Pour chaque filtre dans la liste de filtres de la propriété
                        if (filter.value !== undefined || ['empty', 'not_empty'].includes(filter.condition)) {
                            let layerValue = propertyRoot[property.name === 'runoffCoefficientId' ? 'dominantCompositionId' : property.name];
                            layerValue = getPropertyValue(property.name, layerValue, layer);
                            if (property.customField?.isNumeric && !property.customField?.isMultiple) {
                                layerValue = property.customField.dropdownCustomFieldValues.find(dcfv => dcfv.id === layerValue)?.value;
                                if (layerValue) layerValue = Number(layerValue);
                            }
                            if (property.customField?.type === 'date' && layerValue) layerValue = new Date(layerValue);
                            let filterValue = filter.value;
                            if (!['empty', 'not_empty'].includes(filter.condition) && !Array.isArray(filter.value) && !['boolean', 'object'].includes(typeof filter.value)) {
                                if (isNaN(filter.value) || String(filter.value).trim() === '')
                                    filterValue = filter.value.toLowerCase()
                                else if (!isNaN(filter.value))
                                    filterValue = Number(filter.value);
                            }

                            if (filter) { // Si la valeur est définie
                                if (((layerValue !== undefined || property.name === 'localisation') && layerValue !== null) || ['!=', 'empty', 'not_empty'].includes(filter.condition)) {
                                    switch (filter.condition) { // On fait la vérification en fonction de la condition associée à la valeur
                                        case '=':
                                            const fv = filterValue;

                                            const checkValue = (value) => {
                                                if (Array.isArray(fv)) {
                                                    if (fv.includes('empty')) { if (!value?.length) flag = true; }
                                                    else {
                                                        if (property.isMultiple) {
                                                            if (property.customField && typeof value !== 'string') value = String(value);
                                                            if (fv.every(x => value?.includes(x)) && (property.customField ? value?.split(',').length : value.length) === fv.length) flag = true;
                                                        } else fv.forEach(x => {
                                                            x = isNaN(x) || String(x).trim() === '' ? x.toLowerCase?.() : Number(x);
                                                            if (value === x) flag = true;
                                                        });
                                                    }
                                                }
                                                else if (Array.isArray(value) && value.some(v => v === fv)) flag = true;
                                                else if (value === fv) flag = true;
                                            }

                                            checkValue(layerValue);
                                            break;
                                        case '!=': {
                                            const fv = filterValue;

                                            const checkValue = (value) => {
                                                if (Array.isArray(fv)) {
                                                    if (fv.includes('empty')) { if (value?.length) flag = true; }
                                                    else {
                                                        if (property.isMultiple) {
                                                            if (property.customField && typeof value !== 'string') value = String(value);
                                                            if (fv.some(x => !value?.includes(x)) || (property.customField ? value?.split(',').length : value.length) !== fv.length) flag = true;
                                                        } else if (fv.every(x => {
                                                            x = isNaN(x) || String(x).trim() === '' ? x.toLowerCase() : Number(x);
                                                            return value !== x;
                                                        })) flag = true;
                                                    }
                                                }
                                                else if (Array.isArray(value)) flag = value.some(v => v !== fv);
                                                else if (value !== fv) flag = true;
                                            }

                                            checkValue(layerValue);
                                            break;
                                        }
                                        case 'c': {
                                            const fv = filterValue;

                                            const checkValue = (value) => {
                                                if (Array.isArray(fv) && property.isMultiple) {
                                                    if (property.customField && typeof value !== 'string') value = String(value);
                                                    if (fv.every(x => value?.includes(x))) flag = true;
                                                } else if (String(value).includes(fv)) flag = true;
                                            }

                                            checkValue(layerValue);
                                            break;
                                        }
                                        case 'sw': if (String(layerValue).startsWith(filterValue)) flag = true; break;
                                        case 'ew': if (String(layerValue).endsWith(filterValue)) flag = true; break;
                                        case '<':
                                            if (DatesUtil.isISOString(filterValue)) filterValue = new Date(filterValue);
                                            if (filterValue instanceof Date) filterValue.setHours(0, 0, 0, 0);
                                            if (['actionDate', 'eventDate'].includes(property.name)) {
                                                if (Array.isArray(layerValue)) {
                                                    const labelFilters = filteredProperties.find(property => ['actionId', 'eventId'].includes(property.name))?.filters?.map(filter => filter.value || []);
                                                    if (labelFilters) {
                                                        if (labelFilters.some(actionLabels => actionLabels.every(actionLabel => layerValue.find(({ label, date }) => label === actionLabel && DatesUtil.convertUTCDateToDate(date) < filterValue)))) flag = true;
                                                    } else if (layerValue.some(({ date }) => DatesUtil.convertUTCDateToDate(date) < filterValue)) flag = true;
                                                }
                                            } else if (layerValue < filterValue && (layerValue > 0 || ['healthReviewId', 'averageHealthReviewId', 'nbPhotos', 'nbFiles'].includes(property.name))) flag = true;
                                            break;
                                        case '>':
                                            if (DatesUtil.isISOString(filterValue)) filterValue = new Date(filterValue);
                                            if (filterValue instanceof Date) filterValue.setHours(23, 59, 59, 999);
                                            if (['actionDate', 'eventDate'].includes(property.name)) {
                                                if (Array.isArray(layerValue)) {
                                                    const labelFilters = filteredProperties.find(property => ['actionId', 'eventId'].includes(property.name))?.filters?.map(filter => filter.value || []);
                                                    if (labelFilters) {
                                                        if (labelFilters.some(actionLabels => actionLabels.every(actionLabel => layerValue.find(({ label, date }) => label === actionLabel && DatesUtil.convertUTCDateToDate(date) > filterValue)))) flag = true;
                                                    } else if (layerValue.some(({ date }) => DatesUtil.convertUTCDateToDate(date) > filterValue)) flag = true;
                                                }
                                            } else if (layerValue > filterValue) flag = true;
                                            break;
                                        case '<.<':
                                            if (filterValue.startDate || filterValue.endDate) {
                                                if (filterValue.startDate) {
                                                    if (DatesUtil.isISOString(filterValue.startDate))
                                                        filterValue.startDate = new Date(filterValue.startDate);
                                                    filterValue.startDate.setHours(0, 0, 0, 0);
                                                }
                                                if (filterValue.endDate) {
                                                    if (DatesUtil.isISOString(filterValue.endDate))
                                                        filterValue.endDate = new Date(filterValue.endDate);
                                                    filterValue.endDate.setHours(23, 59, 59, 999);
                                                }
                                                if (['actionDate', 'eventDate'].includes(property.name)) {
                                                    if (Array.isArray(layerValue)) {
                                                        const labelFilters = filteredProperties.find(property => ['actionId', 'eventId'].includes(property.name))?.filters?.map(filter => filter.value || []);
                                                        if (labelFilters) {
                                                            if (labelFilters.some(actionLabels => actionLabels.every(actionLabel => layerValue.find(({ label, date }) => label === actionLabel && (DatesUtil.convertUTCDateToDate(date) > filterValue.startDate && (!filterValue.endDate || DatesUtil.convertUTCDateToDate(date) < filterValue.endDate)))))) flag = true;
                                                        } else if (layerValue.some(({ date }) => DatesUtil.convertUTCDateToDate(date) > filterValue.startDate && (!filterValue.endDate || DatesUtil.convertUTCDateToDate(date) < filterValue.endDate))) flag = true;
                                                    }
                                                } else if (layerValue > filterValue.startDate && (!filterValue.endDate || layerValue < filterValue.endDate)) flag = true;
                                            } else if (Object.hasOwn(filterValue, 'min') || Object.hasOwn(filterValue, 'max'))
                                                if (((!filterValue.min && filterValue.min !== 0) || filterValue.min <= layerValue) && (layerValue <= filterValue.max || (!filterValue.max && filterValue.max !== 0)))
                                                    flag = true;
                                            break;
                                        case 'fd': case 'gs': case 's=': case 's!=': {
                                            const fv = (Array.isArray(filterValue) ? filterValue : [filterValue]).map(value => (
                                                filter.condition === 'fd' ? JSON.parse(value)
                                                    : filter.condition === 'gs' ? this.greenSpacesLayer.getLayers().find(layer => layer.feature.id === value)?.getLatLngs()?.[0]
                                                        : this.stationsLayer.getLayers().find(layer => layer.feature.id === value)?.getLatLngs()?.[0]
                                            )).filter(value => value);
                                            if (filter.condition !== 's!=' && fv.some(value => GeometriesUtil.checkIfIsInsidePolygon(layerValue, value))) flag = true;
                                            if (filter.condition === 's!=' && fv.every(value => !GeometriesUtil.checkIfIsInsidePolygon(layerValue, value))) flag = true;
                                            break;
                                        }
                                        case 'empty': case 'not_empty':
                                            const isValueEmpty = !layerValue || (typeof layerValue === 'string' && !layerValue.trim());
                                            flag = (filter.condition === 'empty' && isValueEmpty) || (filter.condition === 'not_empty' && !isValueEmpty);
                                            break;
                                        default: flag = true; break;
                                    }
                                }
                            }
                            if (String(filter.value).trim() === '') numberOfEmpty++;
                        } else numberOfUndefined++;
                    });
                    if (property.filters.length !== (numberOfUndefined + numberOfEmpty) && !flag && layerContainer.hasLayer(layer)) {
                        if (!layerContainer.filterId) layerContainer.filterId = uuidv4();
                        if (!this.filteredLayers[layerContainer.filterId]) this.filteredLayers[layerContainer.filterId] = [];
                        this.filteredLayers[layerContainer.filterId].push(layer);
                        layerContainer.removeLayer(layer); // Si le layer ne respecte aucun des critères pour cette propriété, on le supprime de son layer parent
                        this.removeReference(layer);
                    } else if (flag)
                        remainingLayers.push(layer);
                }
            });
        }

        const categoriesProps = {
            'Arbre': { layerContainers: [this.treesLayer, this.treesLayerNotClustered], legendName: i18n.t("Arbres") },
            'Espace vert': { layerContainers: [this.greenSpacesLayer], legendName: i18n.t("Espaces verts") },
            'Mobilier': { layerContainers: [this.furnituresLayer, this.furnituresLayerNotClustered], legendName: i18n.t("Mobilier urbain") },
            'Repère': { layerContainers: [this.markersLayer], legendName: i18n.t("Repères") }
        };

        categories.forEach(category => {
            let flag = true;
            const { layerContainers, legendName } = categoriesProps[category];
            layerContainers.forEach((layerContainer, i) => { // Pour chaque layer concernant les arbres, on applique les filtres
                layerContainer.eachLayer((layer) => filter(layer, layerContainer));
                if (this.map.hasLayer(layerContainer)) flag = false; // On vérifie si un layer est déjà affiché
            });

            if (isMainFilters) {
                if (flag) this.toggleLayer({ layer: layerContainers[0] }); // On affiche le layer principal
                if (legendName) this.updateLegend(legendName);
                if (category === 'Arbre') this.updateHeatmaps();
            }
        });

        if (isMainFilters) {
            ['Arbre', 'Espace vert', 'Mobilier', 'Repère'].filter(category => !categories.includes(category)).forEach(category => {
                const { layerContainers } = categoriesProps[category];
                layerContainers.forEach(layerContainer => {
                    if (this.map.hasLayer(layerContainer)) {
                        if (!layerContainer.filterId) layerContainer.filterId = uuidv4();
                        this.filteredLayers[layerContainer.filterId] = { wasRemoved: true };
                        this.toggleLayer({ layer: layerContainer });
                    }
                });
            });

            if (zoomOnElements) {
                remainingLayers = remainingLayers.filter(layer => layer.feature.geometry.coordinates.every(coord => coord));
                if (remainingLayers.length > 0) this.map.fitBounds(L.featureGroup(remainingLayers).getBounds());
                if (remainingLayers.length === 1 && remainingLayers[0].getLatLng) this.map.setZoom(18, { animate: false });
                this.props.setButtonState('filters', 1);

                if (this.props.currentAction !== 'drawingActionsFilterSurroundings') {
                    this.hideForm(true);
                    showToast('filters_applied');
                } else {
                    this.props.setCurrentAction(null);
                    this.changeModalContentType('ActionTable', i18n.t("Actions"));
                }
            }
        }
    }

    drawFilterSurroundings = () => {
        this.unselectElements();
        if (this.props.layer) {
            this.map.removeLayer(this.props.layer);
            this.props.setLayer(null);
        }
        this.setState({
            modal: { visible: false },
            modalContentType: ''
        });
        this.lasso.enable();
    }

    handleLinkingClick = (layer, style, highlightStyle) => {
        if (this.props.layer) {
            const { layersToLink, layersToUnlink } = this.state;
            let layerToUnlink, layerToLink = layersToLink.find(l => l.feature.id === layer.feature.id);
            const linkedLayer = this.props.layer.find(l => l.feature.id === layer.feature.id);
            if (layerToLink) this.setState(prevState => ({ layersToLink: prevState.layersToLink.filter(l => l !== layerToLink) }));
            else {
                layerToUnlink = layersToUnlink.find(l => l.feature.id === layer.feature.id);
                if (layerToUnlink) this.setState(prevState => ({ layersToUnlink: prevState.layersToUnlink.filter(l => l !== layerToUnlink) }));
                else {
                    if (linkedLayer) this.setState(prevState => ({ layersToUnlink: [...prevState.layersToUnlink, layer] }));
                    else this.setState(prevState => ({ layersToLink: [...prevState.layersToLink, layer] }));
                }
            }

            this.props.setLayer(linkedLayer ? this.props.layer.filter(layer => layer !== linkedLayer) : [...this.props.layer, layer]);

            if (this.props.currentAction === 'managingActionElements') {
                const projectAction = this.props.editedProperties.newProjectAction || this.props.editedProperties.projectAction;
                if (layerToLink || linkedLayer) { // S'il est trouvé
                    this.setState(prevState => ({ highlightedElements: prevState.highlightedElements.filter(x => x !== layer) }));
                    if (projectAction && projectAction.action.id < 4 && this.actionLinkingCategories.includes('Arbre'))
                        layer.feature.properties.tmpToCutDown = -1;
                    layer.setStyle(style);
                } else { // S'il n'est pas trouvé
                    this.setState(prevState => ({ highlightedElements: [...prevState.highlightedElements, layer] }));
                    if (projectAction && projectAction.action.id < 4 && this.actionLinkingCategories.includes('Arbre'))
                        layer.feature.properties.tmpToCutDown = projectAction.action.id
                    layer.setStyle(highlightStyle);
                }
            } else if (this.props.currentAction === 'managingEventElements') {
                if (layerToLink || linkedLayer) { // S'il est trouvé
                    this.setState(prevState => ({ highlightedElements: prevState.highlightedElements.filter(x => x !== layer) }));
                    layer.setStyle(style);
                } else { // S'il n'est pas trouvé
                    this.setState(prevState => ({ highlightedElements: [...prevState.highlightedElements, layer] }));
                    layer.setStyle(highlightStyle);
                }
            }
        }
    }

    splitGreenSpaces = (line, layers = null, scaleLine = true) => {
        const layersToPush = [];
        const affectedLayers = layers || this.greenSpacesLayer.getLayers().filter(layer => layer.feature && !layer.feature.properties.baseLine && booleanIntersects(layer.feature.geometry, line.geometry));

        const adjustLayerBaseline = (layer) => {
            const coordinates = layer.feature.properties.baseLine.coordinates;
            for (let i = 0; i < coordinates.length; i++) {
                const pointCircle = circle(point(coordinates[i]), 0.0001);
                const nextPointCircle = coordinates[i + 1] ? circle(point(coordinates[i + 1]), 0.0001) : null;
                if (!booleanIntersects(pointCircle, layer.feature)) {
                    if (i === 0 && !booleanIntersects(nextPointCircle, layer.feature)) {
                        if (coordinates.length > 2) {
                            coordinates.splice(i, 1);
                            i--;
                        }
                    } else {
                        const scaledCoordinates = GeometriesUtil.getElongatedLine(coordinates, layer.feature.properties.baseLine.width / 2);
                        const intersectionPoints = lineIntersect(lineString([scaledCoordinates[i], scaledCoordinates[i !== 0 ? i - 1 : i + 1]]), layer.feature);
                        const distances = [];
                        intersectionPoints.features.forEach(feature => { // Obligé de faire ça car on ne connait pas l'index du point d'intersection à ajouter
                            let distanceToNearestPoint = distance(feature, point(coordinates[i]));
                            const secondDistance = distance(feature, point(coordinates[i !== 0 ? i - 1 : i + 1]));
                            if (secondDistance < distanceToNearestPoint) distanceToNearestPoint = secondDistance;
                            distances.push(distanceToNearestPoint);
                        });
                        // Le bon est celui le plus éloigné des deux points de référence car l'autre est presque identique à un point existant
                        const intersectionIndex = distances.indexOf(Math.max(...distances));
                        coordinates.splice(i, 1, intersectionPoints.features[intersectionIndex].geometry.coordinates);
                        if (i > 0) coordinates.length = i + 1;
                    }
                }
            }
        };

        const referencesLayers = this.referencesLayer.getLayers();
        affectedLayers.forEach(layer => {
            if (!ProjectsUtil.isElementLocked(this.props.lockedElements, layer.feature, this.props.project, this.props.projectCollaborators, [])) {
                const polygons = GeometriesUtil.splitPolygon(layer.feature.geometry, line.geometry, scaleLine);
                if (polygons) {
                    const referenceLayerToRemove = referencesLayers.find(l => l.elementId === layer.feature.id && l.category === layer.feature.properties.category);
                    if (referenceLayerToRemove) this.referencesLayer.removeLayer(referenceLayerToRemove);
                    this.greenSpacesLayer.removeLayer(layer);
                    layersToPush.push({ originalLayer: layer, newLayer: layer, isDeletion: true });

                    for (let i = 0; i < polygons.features.length; i++) {
                        const newLayer = new L.Polygon(
                            GeometriesUtil.convertPolygonCoordinatesToLatLngs(polygons.features[i].geometry.coordinates),
                            StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps)
                        );
                        newLayer.feature = GeoJsonUtil.generatePolygonFeature(
                            JSON.parse(JSON.stringify({ ...layer.feature.properties, fetchId: 0 })),
                            newLayer.getLatLngs(), layer.feature.projectId
                        );
                        newLayer.feature.id = uuidv4();
                        if (newLayer.feature.properties.baseLine) {
                            adjustLayerBaseline(newLayer);
                            newLayer.feature.properties.baseLine.propertiesId = newLayer.feature.id;
                        }

                        const newPolygon = this.addGreenSpace(newLayer, newLayer.feature);
                        layersToPush.push({ originalLayer: newPolygon, newLayer: newPolygon, isAddition: true });
                    }
                }
            }
        });

        if (layers) { // Obligé pour générer les drawLines des nouveaux layers
            this.hideDrawLines();
            this.showDrawLines(true);
        }

        if (layersToPush.length > 0) {
            this.pushActionHistory(layersToPush.map(layers => ({ layer: layers.originalLayer, isAddition: layers.isAddition, isDeletion: layers.isDeletion })));
            layersToPush.forEach(layers => {
                this.actionHistory.history.forEach(modifications => { // On fait pointer les anciens historiques vers le nouveau layer
                    modifications.forEach(modification => {
                        if (modification.oldFeature.id === layers.newLayer.feature.id)
                            modification.layer = layers.newLayer;
                    });
                });
            });
        }
    }

    cancelGreenSpacesMerge = () => {
        this.setState({ differentProperties: null });
        this.hideForm(false);
    }
    mergeGreenSpaces = (propertiesToKeep = {}) => {
        const { firstLayer, secondLayer } = this.greenSpacesToMerge;
        const referencesLayers = this.referencesLayer.getLayers();

        if (this.greenSpacesLayer.hasLayer(firstLayer) && this.greenSpacesLayer.hasLayer(secondLayer)) { // En cas de découpe, les layers ne sont pas sur la map
            this.layersToPush = [
                { originalLayer: firstLayer, newLayer: firstLayer, isDeletion: true },
                { originalLayer: secondLayer, newLayer: secondLayer, isDeletion: true }
            ];
            this.greenSpacesLayer.removeLayer(firstLayer);
            this.greenSpacesLayer.removeLayer(secondLayer);
            const referenceLayersToRemove = referencesLayers.filter(l =>
                l.elementId === firstLayer.feature.id && l.category === firstLayer.feature.properties.category ||
                l.elementId === secondLayer.feature.id && l.category === secondLayer.feature.properties.category
            );
            if (referenceLayersToRemove.length) referenceLayersToRemove.forEach(rltr => this.referencesLayer.removeLayer(rltr));
        }

        const properties = {}, customFields = {};
        for (const property in propertiesToKeep)
            if (isNaN(property)) properties[property] = propertiesToKeep[property];
            else customFields[property] = propertiesToKeep[property];
        const newProperties = { ...firstLayer.feature.properties, ...properties, customFields: { ...firstLayer.feature.properties.customFields, ...customFields } };
        for (const customField in newProperties.customFields)
            if (!newProperties.customFields[customField])
                delete newProperties.customFields[customField];
        const newLayer = new L.Polygon(
            GeometriesUtil.convertPolygonCoordinatesToLatLngs(this.mergedPolygon.geometry.coordinates),
            StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, newProperties, this.props.project.thematicMaps)
        );
        newLayer.feature = GeoJsonUtil.generatePolygonFeature(
            JSON.parse(JSON.stringify(newProperties)),
            newLayer.getLatLngs(), firstLayer.feature.projectId
        );
        newLayer.feature.id = uuidv4();
        newLayer.feature.properties.greenSpaceId = `${firstLayer.feature.properties.greenSpaceId},${secondLayer.feature.properties.greenSpaceId}`;
        if (this.mergedPolygon.properties?.baseLine) {
            newLayer.feature.properties.baseLine = this.mergedPolygon.properties.baseLine;
            newLayer.feature.properties.baseLine.propertiesId = newLayer.feature.id;
        }

        const newPolygon = this.addGreenSpace(newLayer, newLayer.feature);
        this.layersToPush.push({ originalLayer: newPolygon, newLayer: newPolygon, isAddition: true });

        this.pushActionHistory(this.layersToPush.map(layers => ({ layer: layers.originalLayer, isAddition: layers.isAddition, isDeletion: layers.isDeletion })));
        this.layersToPush.forEach(layers => {
            this.actionHistory.history.forEach(modifications => { // On fait pointer les anciens historiques vers le nouveau layer
                modifications.forEach(modification => {
                    if (modification.oldFeature.id === layers.newLayer.feature.id)
                        modification.layer = layers.newLayer;
                });
            });
        });
        this.layersToPush = null;

        this.greenSpacesToMerge = null; this.mergedPolygon = null;
        this.setState({ differentProperties: null });
        this.hideForm(true);
    }

    handleMergingClick = (layer) => {
        if (layer !== this.props.layer) {
            if (this.props.layer) {
                const firstLayer = this.props.layer, secondLayer = layer;
                const firstProperties = firstLayer.feature.properties;
                const secondProperties = secondLayer.feature.properties;

                firstLayer.setStyle(StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, firstProperties, this.props.project.thematicMaps));
                const mergedPolygon = GeometriesUtil.mergePolygons([firstLayer.feature, secondLayer.feature]);
                if (!mergedPolygon || (
                    (firstProperties.baseLine && !secondProperties.baseLine) ||
                    (!firstProperties.baseLine && secondProperties.baseLine)
                )) showToast('greenspace_merge_not_allowed');
                else {
                    const propertiesToIgnore = ['greenSpaceId', 'fetchId', 'projectReference', 'baseLine', 'surface', 'carbonStock', 'coolingEnergyIndicator', 'customFields', 'actionsUrgency', 'creationDate', 'modificationDate', 'zIndex'];
                    const differentProperties = {};
                    for (const property in firstProperties)
                        if (!propertiesToIgnore.includes(property) && JSON.stringify(firstProperties[property]) !== JSON.stringify(secondProperties[property]))
                            differentProperties[property] = [firstProperties[property], secondProperties[property]];
                    for (const customField in firstProperties.customFields)
                        if (JSON.stringify(firstProperties.customFields[customField]) !== JSON.stringify(secondProperties.customFields[customField]))
                            differentProperties[customField] = [firstProperties.customFields[customField] || null, secondProperties.customFields[customField] || null];

                    for (const property in secondProperties)
                        if (!propertiesToIgnore.includes(property) && JSON.stringify(firstProperties[property]) !== JSON.stringify(secondProperties[property])
                            && differentProperties[property] === undefined) // 'undefined' à laisser car certains enfants utilise '' pour override le parent
                            differentProperties[property] = [firstProperties[property], secondProperties[property]];
                    for (const customField in secondProperties.customFields)
                        if (JSON.stringify(firstProperties.customFields[customField]) !== JSON.stringify(secondProperties.customFields[customField]))
                            differentProperties[customField] = [firstProperties.customFields[customField] || null, secondProperties.customFields[customField] || null];

                    this.greenSpacesToMerge = { firstLayer, secondLayer };
                    this.mergedPolygon = mergedPolygon;
                    if (Object.keys(differentProperties).length > 0) {
                        this.setState({
                            differentProperties,
                            modal: { visible: true, title: i18n.t("Fusion d'éléments") },
                            modalContentType: 'MergeForm'
                        });
                    } else this.mergeGreenSpaces();
                }
                this.props.setLayer(null);
            } else {
                this.props.setLayer(layer);
                layer.setStyle(StylesUtil.getGreenSpaceHighlightStyle());
            }
        } else {
            this.props.setLayer(null);
            layer.setStyle(StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps));
        }
    }

    handleSubtractingClick = (layer) => {
        if (layer !== this.props.layer) {
            if (this.props.layer) {
                const firstLayer = this.props.layer, secondLayer = layer;
                const firstProperties = firstLayer.feature.properties;
                const secondProperties = secondLayer.feature.properties;

                firstLayer.setStyle(StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, firstProperties, this.props.project.thematicMaps));
                const mergedPolygon = GeometriesUtil.mergePolygons([firstLayer.feature, secondLayer.feature]);
                if (!mergedPolygon || firstProperties.baseLine || secondProperties.baseLine) showToast('greenspace_subtraction_not_allowed');
                else {
                    this.pushActionHistory([{ layer: secondLayer }]);
                    const newFeature = difference(featureCollection([secondLayer.feature, firstLayer.feature]));
                    if (newFeature) {
                        secondLayer.feature.geometry = newFeature.geometry;
                        secondLayer.setLatLngs(GeometriesUtil.convertPolygonCoordinatesToLatLngs(newFeature.geometry.coordinates));
                    }
                }
                this.props.setLayer(null);
            } else {
                this.props.setLayer(layer);
                layer.setStyle(StylesUtil.getGreenSpaceHighlightStyle());
            }
        } else {
            this.props.setLayer(null);
            layer.setStyle(StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps));
        }
    }

    cancelGreenSpacesCut = () => {
        this.cutLayers.forEach(({ originalLayer, layer }) => {
            this.addGreenSpace(new L.polygon(originalLayer._latlngs), originalLayer.feature); // On recrée le polygone de base dans le greenspace layer
            this.greenSpacesLayer.removeLayer(originalLayer);
            this.map.removeLayer(layer);
        });
        this.cutLayers = null;
    }
    cutGreenSpaces = (action) => {
        if (!this.layersToPush) this.layersToPush = [];

        this.cutLayers.forEach(({ originalLayer, layer, removedShape }) => {
            const shapesToAdd = [];
            if (layer.feature.geometry.type === 'MultiPolygon') {
                shapesToAdd.push(...layer.feature.geometry.coordinates);
                this.layersToPush.push({ originalLayer, newLayer: originalLayer, isDeletion: true });
            } else {
                const updatedLayer = new L.Polygon(
                    GeometriesUtil.convertPolygonCoordinatesToLatLngs(layer.feature.geometry.coordinates),
                    StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, originalLayer.feature.properties, this.props.project.thematicMaps)
                );
                updatedLayer.feature = JSON.parse(JSON.stringify(originalLayer.feature));
                updatedLayer.feature.geometry = layer.feature.geometry;
                const updatedPolygon = this.addGreenSpace(updatedLayer, updatedLayer.feature);

                this.layersToPush.push({ originalLayer, newLayer: updatedPolygon });
            }

            this.greenSpacesLayer.removeLayer(originalLayer);
            this.map.removeLayer(layer);

            if (action === 'keep') {
                if (removedShape.geometry.type === 'MultiPolygon')
                    shapesToAdd.push(...removedShape.geometry.coordinates)
                else shapesToAdd.push(removedShape.geometry.coordinates);
            }

            shapesToAdd.forEach(shape => {
                const newLayer = new L.Polygon(
                    GeometriesUtil.convertPolygonCoordinatesToLatLngs(shape),
                    StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, originalLayer.feature.properties, this.props.project.thematicMaps)
                );
                newLayer.feature = GeoJsonUtil.generatePolygonFeature(
                    JSON.parse(JSON.stringify(originalLayer.feature.properties)),
                    newLayer.getLatLngs(), originalLayer.feature.projectId
                );
                newLayer.feature.id = uuidv4();

                const newPolygon = this.addGreenSpace(newLayer, newLayer.feature);
                this.layersToPush.push({ originalLayer: newPolygon, newLayer: newPolygon, isAddition: true });
            });
        });

        if (this.pushTimeout) clearTimeout(this.pushTimeout);
        this.pushTimeout = setTimeout(() => {
            if (action === 'merge') {
                const firstLayer = this.cutLayers[0].originalLayer, secondLayer = this.cutLayers[1].originalLayer;
                const firstProperties = firstLayer.feature.properties;
                const secondProperties = secondLayer.feature.properties;
                const propertiesToIgnore = ['greenSpaceId', 'projectReference', 'baseLine', 'surface', 'carbonStock', 'coolingEnergyIndicator', 'customFields', 'actionsUrgency', 'creationDate', 'modificationDate', 'zIndex'];
                const differentProperties = {};
                for (const property in firstProperties)
                    if (!propertiesToIgnore.includes(property) && JSON.stringify(firstProperties[property]) !== JSON.stringify(secondProperties[property]))
                        differentProperties[property] = [firstProperties[property], secondProperties[property]];
                for (const customField in firstProperties.customFields)
                    if (JSON.stringify(firstProperties.customFields[customField]) !== JSON.stringify(secondProperties.customFields[customField]))
                        differentProperties[customField] = [firstProperties.customFields[customField], secondProperties.customFields[customField]];

                for (const property in secondProperties)
                    if (!propertiesToIgnore.includes(property) && JSON.stringify(firstProperties[property]) !== JSON.stringify(secondProperties[property])
                        && differentProperties[property] === undefined) // 'undefined' à laisser car certains enfants utilise '' pour override le parent
                        differentProperties[property] = [firstProperties[property], secondProperties[property]];
                for (const customField in secondProperties.customFields)
                    if (JSON.stringify(firstProperties.customFields[customField]) !== JSON.stringify(secondProperties.customFields[customField]))
                        differentProperties[customField] = [firstProperties.customFields[customField], secondProperties.customFields[customField]];

                this.greenSpacesToMerge = { firstLayer, secondLayer };
                if (Object.keys(differentProperties).length > 0) {
                    this.setState({
                        differentProperties,
                        modal: { visible: true, title: i18n.t("Fusion d'éléments") },
                        modalContentType: 'MergeForm'
                    });
                } else this.mergeGreenSpaces();
            } else {
                this.pushActionHistory(this.layersToPush.map(layers => ({ layer: layers.originalLayer, isAddition: layers.isAddition, isDeletion: layers.isDeletion })));
                this.layersToPush.forEach(layers => {
                    this.actionHistory.history.forEach(modifications => { // On fait pointer les anciens historiques vers le nouveau layer
                        modifications.forEach(modification => {
                            if (modification.oldFeature.id === layers.newLayer.feature.id)
                                modification.layer = layers.newLayer;
                        });
                    });
                });
                this.layersToPush = null;
            }

            this.cutLayers = null; this.pushTimeout = null;
        }, 100);
    }

    toggleElementsHighlight = (elements = []) => {
        elements = elements.filter(e => e.feature);

        if (Array.isArray(elements) && elements?.length > 0) {
            let highlightedElements = this.state.highlightedElements;
            if (!highlightedElements) highlightedElements = [];
            let elementsToLock = [], elementsToUnlock = [];
            const userId = jwtDecode(new Cookies().get('token')).id;

            const categories = {
                'Arbre': {
                    getStyle: (element) => StylesUtil.getTreeActiveStyle(this.treesLayer, this.fieldList, element.feature.properties, this.props.project.thematicMaps),
                    getHighlightedStyle: (element) => StylesUtil.getTreeHighlightStyle(this.treesLayer, this.fieldList, element.feature.properties),
                    layerContainers: [this.treesLayer, this.treesLayerNotClustered]
                },
                'Espace vert': {
                    getStyle: (element) => StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, element.feature.properties, this.props.project.thematicMaps),
                    getHighlightedStyle: () => StylesUtil.getGreenSpaceHighlightStyle(),
                    layerContainers: [this.greenSpacesLayer]
                },
                'Mobilier': {
                    getStyle: (element) => StylesUtil.getFurnitureActiveStyle(this.furnituresLayer.activeChild, this.fieldList, element.feature.properties, this.props.project.thematicMaps),
                    getHighlightedStyle: () => StylesUtil.getFurnitureHighlightStyle(this.fieldList),
                    layerContainers: [this.furnituresLayer, this.furnituresLayerNotClustered]
                },
                'Repère': {
                    getStyle: () => StylesUtil.getMarkerActiveStyle(this.fieldList),
                    getHighlightedStyle: () => StylesUtil.getMarkerHighlightStyle(this.fieldList),
                    layerContainers: [this.markersLayer]
                },
                'Station': {
                    getStyle: () => StylesUtil.getStationActiveStyle(),
                    getHighlightedStyle: () => StylesUtil.getStationHighlightStyle(),
                    layerContainers: [this.stationsLayer]
                }
            };

            elements.forEach(element => {
                const category = element.feature.properties.category;
                const style = categories[category]?.getStyle(element);
                const highlightedStyle = categories[category]?.getHighlightedStyle(element);

                let addElement = true;
                if (highlightedElements.find(hlElement => hlElement.feature.id === element.feature.id)) {
                    highlightedElements = highlightedElements.filter(hlElement => hlElement.feature.id !== element.feature.id);
                    addElement = false;
                }

                const layerContainers = categories[category]?.layerContainers || [this.backgroundImagesLayer];
                layerContainers.forEach(layerContainer => {
                    const layer = layerContainer.getLayers().find(layer => layer.feature?.id === element.feature.id);
                    if (layer) {
                        if (!ProjectsUtil.isElementLocked(this.props.lockedElements, layer.feature)) {
                            if (layer.feature.properties.category !== 'BackgroundImage') {
                                if (addElement) {
                                    layer.setStyle(highlightedStyle); // On le met en évidence
                                    elementsToLock.push(layer.feature.id);
                                } else {
                                    layer.setStyle(style);
                                    elementsToUnlock.push(layer.feature.id);
                                }
                            } else {
                                if (addElement) {
                                    layer.getElement().classList.add('highlightedBackgroundImage');
                                    elementsToLock.push(layer.feature.id);
                                }
                                else {
                                    layer.getElement().classList.remove('highlightedBackgroundImage');
                                    elementsToUnlock.push(layer.feature.id);
                                }
                            }
                        }
                    }
                });

                if (addElement) highlightedElements.push(element);
            });

            if (elementsToLock.length)
                WebSocketUtil.toggleElementsLock(this.props.webSocketHubs, this.props.project.id, [...new Set(elementsToLock)], true, userId);
            if (elementsToUnlock.length)
                WebSocketUtil.toggleElementsLock(this.props.webSocketHubs, this.props.project.id, [...new Set(elementsToUnlock)], false, userId);
            this.setState({ highlightedElements });
        }
    }

    showElements = (category, elements) => {
        let highlightedElements = [];

        const hideModal = () => this.setState({
            modal: { visible: false },
            modalContentType: '',
            highlightedElementsCategory: category
        });

        const highlightElements = (layerContainer, getHighlightStyle) => {
            layerContainer.eachLayer(layer => { // Pour chaque élément dans le layer contenant
                let flag = false;
                for (let i = 0; i < elements.length; i++) // Pour chaque élément
                    if (layer.feature.id === elements[i].elementId) flag = true; // Si il est présent dans la liste des éléments à mettre en évidence
                if (flag) {
                    layer.setStyle(getHighlightStyle(layer)); // On le met en évidence
                    highlightedElements.push(layer);
                }
            });
        }

        switch (category) {
            case 'Arbres':
                highlightElements(this.treesLayerNotClustered, (layer) => StylesUtil.getTreeHighlightStyle(this.treesLayer, this.fieldList, layer.feature.properties));
                this.updateOverlayInControlLayer(i18n.t("Arbres"), this.treesLayerNotClustered);
                if (!this.map.hasLayer(this.treesLayerNotClustered)) this.toggleLayer({ layer: this.treesLayerNotClustered });
                break;
            case 'Espaces verts':
                highlightElements(this.greenSpacesLayer, () => StylesUtil.getGreenSpaceHighlightStyle());
                if (!this.map.hasLayer(this.greenSpacesLayer)) this.toggleLayer({ layer: this.greenSpacesLayer });
                break;
            case 'Mobilier urbain':
                highlightElements(this.furnituresLayerNotClustered, () => StylesUtil.getFurnitureHighlightStyle(this.fieldList));
                this.updateOverlayInControlLayer(i18n.t("Mobilier urbain"), this.furnituresLayerNotClustered);
                if (!this.map.hasLayer(this.furnituresLayerNotClustered)) this.toggleLayer({ layer: this.furnituresLayerNotClustered });
                break;
            default: break;
        }
        if (highlightedElements.length > 0) this.map.fitBounds(L.featureGroup(highlightedElements).getBounds());
        if (highlightedElements.length === 1 && ['Arbres', 'Mobilier urbain'].includes(category)) this.map.setZoom(18, { animate: true });
        hideModal();
        this.setState({ highlightedElements });

        // On modifie le bouton tableaux
        this.props.setButtonState('table', 1);
        this.viewState = true;
        this.toolbarRef.current.setButtonsIsDisabled(['statistics', 'filters', 'actions', 'events', 'history'], true);
    }

    showElement = (category, element, originModal, { highlight = true, blink = false } = {}) => {
        let highlightedElements = [];

        const hideModal = () => this.setState({
            modal: { visible: false },
            modalContentType: '',
            highlightedElementsCategory: category
        });

        const blinkElement = (layer, style, highlightStyle) => {
            const interval = 500, maxInterval = 3000;
            let state = true, currentInterval = 0;
            const intervalId = setInterval(() => {
                layer.setStyle(state ? highlightStyle : style);
                state = !state;
                currentInterval += interval;
                if (currentInterval > maxInterval) {
                    layer.setStyle(style);
                    clearInterval(intervalId);
                }
            }, interval);
        };

        if (['Arbres', 'Arbre', 'Mobilier urbain', 'Mobilier'].includes(category)) {
            const categories = {
                trees: {
                    layerName: i18n.t("Arbres"), layerClustered: this.treesLayer, layerNotClustered: this.treesLayerNotClustered,
                    getStyle: (layer) => StylesUtil.getTreeActiveStyle(this.treesLayer, this.fieldList, layer.feature.properties, this.props.project.thematicMaps),
                    getHighlightStyle: (layer) => StylesUtil.getTreeHighlightStyle(this.treesLayer, this.fieldList, layer.feature.properties)
                },
                furnitures: {
                    layerName: i18n.t("Mobilier urbain"), layerClustered: this.furnituresLayer, layerNotClustered: this.furnituresLayerNotClustered,
                    getStyle: (layer) => StylesUtil.getFurnitureActiveStyle(this.furnituresLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps),
                    getHighlightStyle: () => StylesUtil.getFurnitureHighlightStyle(this.fieldList)
                }
            };

            const {
                layerName, layerClustered, layerNotClustered, getStyle, getHighlightStyle
            } = categories[['Arbres', 'Arbre'].includes(category) ? 'trees' : 'furnitures'];
            if (highlight) {
                const layer = layerNotClustered.getLayers().find(layer => layer.feature.id === element.id);
                if (layer) {
                    layer.setStyle(getHighlightStyle(layer));
                    highlightedElements.push(layer);
                }
                this.updateOverlayInControlLayer(layerName, layerNotClustered);
                if (!this.map.hasLayer(layerNotClustered)) this.toggleLayer({ layer: layerNotClustered });
            } else if (blink) {
                layerClustered.eachLayer(layer => {
                    if (layer.feature.id === element.id)
                        blinkElement(layer, getStyle(layer), getHighlightStyle(layer))
                });
                this.updateOverlayInControlLayer(layerName, layerClustered);
                if (!this.map.hasLayer(layerClustered)) this.toggleLayer({ layer: layerClustered });
            } else if (!this.map.hasLayer(layerClustered) && !this.map.hasLayer(layerNotClustered))
                this.toggleLayer({ layer: layerClustered });
            // Déplacement du centre de la carte
            const latLng = {
                lat: element.geometry.coordinates[1],
                lng: element.geometry.coordinates[0]
            }
            this.map.setView(latLng, 18, { animate: true });
        } else if (['Repères', 'Repère'].includes(category)) {
            if (highlight) {
                const layer = this.markersLayer.getLayers().find(layer => layer.feature.id === element.id);
                if (layer) {
                    layer.setStyle(StylesUtil.getMarkerHighlightStyle(this.fieldList));
                    highlightedElements.push(layer);
                }
                if (!this.map.hasLayer(this.markersLayer)) this.toggleLayer({ layer: this.markersLayer });
            } else if (blink) {
                this.markersLayer.eachLayer(layer => {
                    if (layer.feature.id === element.id)
                        blinkElement(layer, StylesUtil.getMarkerActiveStyle(this.fieldList), StylesUtil.getMarkerHighlightStyle(this.fieldList))
                });
                if (!this.map.hasLayer(this.markersLayer)) this.toggleLayer({ layer: this.markersLayer });
            } else if (!this.map.hasLayer(this.markersLayer))
                this.toggleLayer({ layer: this.markersLayer });
            // Déplacement du centre de la carte
            const latLng = {
                lat: element.geometry.coordinates[1],
                lng: element.geometry.coordinates[0]
            };
            this.map.setView(latLng, 18, { animate: true });
        } else if (['Espaces verts', 'Espace vert'].includes(category)) {
            let bounds;
            let layer = this.greenSpacesLayer.getLayers().find(layer => layer.feature.id === element.id);
            if (layer) {
                // On le met en évidence
                if (blink) blinkElement(layer, StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps), StylesUtil.getGreenSpaceHighlightStyle());
                else {
                    layer.setStyle(StylesUtil.getGreenSpaceHighlightStyle());
                    highlightedElements.push(layer);
                }
                bounds = layer.getBounds();
            }
            if (!this.map.hasLayer(this.greenSpacesLayer)) this.toggleLayer({ layer: this.greenSpacesLayer });
            if (bounds) this.map.fitBounds(bounds);
        } else if (['Stations', 'Station'].includes(category)) {
            let bounds;
            let layer = this.stationsLayer.getLayers().find(layer => layer.feature.id === element.id);
            if (layer) {
                // On le met en évidence
                if (blink) blinkElement(layer, StylesUtil.getStationActiveStyle(this.stationsLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps), StylesUtil.getStationHighlightStyle());
                else {
                    layer.setStyle(StylesUtil.getStationHighlightStyle());
                    highlightedElements.push(layer);
                }
                bounds = layer.getBounds();
            }
            if (!this.map.hasLayer(this.stationsLayer)) this.toggleLayer({ layer: this.stationsLayer });
            if (bounds) this.map.fitBounds(bounds);
        }

        if (highlight) {
            this.setState({ highlightedElements });
            hideModal();
        } else this.hideForm(true);

        if (highlight) {
            if (originModal === 'ProjectDetail') {
                this.props.setButtonState('statistics', 1);
                this.toolbarRef.current.setButtonsIsDisabled(['table', 'actions', 'events', 'history'], true);
            } else if (['TreeTable', 'GreenSpaceTable', 'FurnitureTable'].includes(originModal)) {
                this.props.setButtonState('table', 1);
                this.viewState = true;
                this.toolbarRef.current.setButtonsIsDisabled(['statistics', 'actions', 'events', 'history'], true);
            } else if (originModal === 'ActionTable') {
                this.props.setButtonState('actions', 1);
                this.viewState = true;
                this.toolbarRef.current.setButtonsIsDisabled(['statistics', 'table', 'history', 'events'], true);
            } else if (originModal === 'ProjectHistory') {
                this.props.setButtonState('history', 1);
                this.viewState = true;
                this.toolbarRef.current.setButtonsIsDisabled(['table', 'statistics', 'actions', 'events'], true);
            }
            this.toolbarRef.current.setButtonsIsDisabled(['filters'], true);
        }
    }

    setElementLatLng = () => {
        this.props.setCurrentAction('placingElement').then(() => {
            this.setState({
                modal: { visible: false },
                modalContentType: ''
            });
            this.map.pm.enableDraw('Marker');
        });
    }

    highlightLinkedElements = (projectElements = []) => {
        let highlightedElements = [];

        const categories = {
            'Arbre': { layerContainer: this.treesLayerNotClustered, getHighlightStyle: (layer) => StylesUtil.getTreeHighlightStyle(this.treesLayer, this.fieldList, layer.feature.properties) },
            'Espace vert': { layerContainer: this.greenSpacesLayer, getHighlightStyle: () => StylesUtil.getGreenSpaceHighlightStyle() },
            'Mobilier': { layerContainer: this.furnituresLayerNotClustered, getHighlightStyle: () => StylesUtil.getFurnitureHighlightStyle(this.fieldList) }
        };

        const highlightIfLinked = (layer) => {
            var index = -1;
            projectElements.forEach((pe, i) => {
                if (pe.elementId === layer.feature.id) index = i;
            });

            if (index !== -1) {
                const { layerContainer, getHighlightStyle } = categories[projectElements[index].elementType || 'Arbre'];
                if (!this.map.hasLayer(layerContainer)) this.toggleLayer({ layer: layerContainer });
                layer.setStyle(getHighlightStyle(layer));
                highlightedElements.push(layer);
            }
        }

        [this.treesLayerNotClustered, this.greenSpacesLayer, this.furnituresLayerNotClustered].forEach(layerContainer => {
            layerContainer.eachLayer(layer => highlightIfLinked(layer));
        });
        this.initialLinkedElements = highlightedElements;
        return highlightedElements;
    }

    manageActionElements = (projectAction) => {
        this.actionLinkingCategories = projectAction.action.categories;
        const layers = {
            'Arbre': () => this.updateOverlayInControlLayer(i18n.t("Arbres"), this.treesLayerNotClustered),
            'Espace vert': () => { if (!this.map.hasLayer(this.greenSpacesLayer)) this.toggleLayer({ layer: this.greenSpacesLayer }) },
            'Mobilier': () => this.updateOverlayInControlLayer(i18n.t("Mobilier urbain"), this.furnituresLayerNotClustered)
        };

        if (this.map.pm.globalDragModeEnabled()) this.map.pm.toggleGlobalDragMode();
        this.props.setCurrentAction('managingActionElements');

        this.actionLinkingCategories.forEach(category => {
            if (category === 'Massif arboré') { if (!this.actionLinkingCategories.includes('Espace vert')) layers['Espace vert']?.() }
            else layers[category]?.()
        });

        const highlightedElements = this.highlightLinkedElements(projectAction.projectActionElements || projectAction.projectEventElements);
        this.props.setLayer(highlightedElements);

        this.setState({
            layersToLink: [],
            layersToUnlink: [],
            modal: { visible: false },
            modalContentType: '',
            highlightedElements
        });

        this.props.setButtonState('actions', 1);
        this.toolbarRef.current.setButtonsIsDisabled(['treeTools', 'greenSpaceTools', 'furnitureTools', 'markerTools', 'stationTools', 'imageTools', 'statistics', 'table', 'events'], true);

        showToast('link_mode_activated');
    }

    manageEventElements = (projectEvent) => {
        this.eventLinkingCategories = ['Arbre'];
        const layers = {
            'Arbre': () => this.updateOverlayInControlLayer(i18n.t("Arbres"), this.treesLayerNotClustered),
            'Espace vert': () => { if (!this.map.hasLayer(this.greenSpacesLayer)) this.toggleLayer({ layer: this.greenSpacesLayer }) },
            'Mobilier': () => this.updateOverlayInControlLayer(i18n.t("Mobilier urbain"), this.furnituresLayerNotClustered)
        };

        if (this.map.pm.globalDragModeEnabled()) this.map.pm.toggleGlobalDragMode();
        this.props.setCurrentAction('managingEventElements');

        this.eventLinkingCategories.forEach(category => {
            if (category === 'Massif arboré') { if (!this.eventLinkingCategories.includes('Espace vert')) layers['Espace vert']?.() }
            else layers[category]?.()
        });

        const highlightedElements = this.highlightLinkedElements(projectEvent.projectEventElements);
        this.props.setLayer(highlightedElements);

        this.setState({
            layersToLink: [],
            layersToUnlink: [],
            modal: { visible: false },
            modalContentType: '',
            highlightedElements
        });

        this.props.setButtonState('events', 1);
        this.toolbarRef.current.setButtonsIsDisabled(['treeTools', 'greenSpaceTools', 'furnitureTools', 'markerTools', 'stationTools', 'imageTools', 'statistics', 'table', 'actions'], true);

        showToast('link_mode_activated');
    }

    linkElements = (selectedElements = []) => {
        if (this.map.pm.globalDragModeEnabled()) this.map.pm.toggleGlobalDragMode();
        this.props.setCurrentAction('linkingElements');

        this.setState({
            layersToLink: selectedElements,
            layersToUnlink: [],
            modal: { visible: false },
            modalContentType: 'LinkElementsForm',
            highlightedElements: []
        }, () => this.toggleElementsHighlight(selectedElements));

        this.toolbarRef.current.resetButtonSelection(0);

        // TODO Events
        this.toolbarRef.current.setButtonsIsDisabled(['advancedTools', 'treeTools', 'greenSpaceTools', 'furnitureTools', 'markerTools', 'stationTools', 'imageTools', 'statistics', 'table'], true);
        showToast('link_mode_activated');
    }

    hideExportPdfForm = () => this.setState(prevState => ({ ...prevState.oldModalState, oldModalState: null }));
    showExportPdfForm = () => {
        this.setState(prevState => ({
            modal: { visible: false, title: '' },
            modalContentType: 'ExportPdfForm',
            oldModalState: {
                modal: prevState.modal,
                modalContentType: prevState.modalContentType
            }
        }));
    }
    performAdminTask = async (category) => {
        this.hideForm(true, { resetLayer: false });
        const linkedLayers = category === 'Arbres' ? this.linkedTrees.filter(layers => this.treesLayer.hasLayer(layers[0]))
            : category === 'Espaces verts' ? this.greenSpacesLayer.getLayers().map(layer => [layer])
                : category === 'Mobilier urbain' ? this.linkedFurnitures.filter(layers => this.furnituresLayer.hasLayer(layers[0]))
                    : this.markersLayer.getLayers().map(layer => [layer]);
        switch (this.state.adminTask) {
            case 'printPdfs':
                this.showLoader(true, 'map', i18n.t("Export des fiches en cours..."));
                for (const layers of linkedLayers)
                    await this.props.setLayer(layers).then(async () => {
                        const photosId = this.props.photosGalleries?.filter(photo => photo.elementId === layers[0].feature.id)?.map(photo => photo.id);
                        await this.exportElementAsPdf(photosId, true, false, false);
                    });
                setTimeout(() => this.showLoader(false), 500);
                break;
            case 'updateReferences':
                this.showLoader(true, 'map', i18n.t("Mise à jour des références en cours..."));
                ProjectsService.updateReferences(this.props.project.id, category).then(references => {
                    if (references)
                        Object.keys(references).forEach(id => {
                            const layers = linkedLayers.find(layers => layers[0].feature.id === id);
                            if (layers) layers.forEach(layer => layer.feature.properties.projectReference = references[id]);
                        });
                    this.showLoader(false);
                });
                break;
            default: break;
        }
    };
    exportElementAsPdf = async (photosId, addOverview, colorless, showLoader = true) => new Promise(resolve => {
        const element = this.props.layer[0];
        const category = element.feature.properties.category;
        const { projectReference, customReference } = element.feature.properties;
        const reference = FormattersUtil.checkString(customReference) ? projectReference + ' / ' + customReference : projectReference;

        const categories = {
            'Arbre': { functionName: 'exportTreePDF', customText: i18n.t("Arbre").toLowerCase(), layerContainer: this.treesLayerNotClustered },
            'Espace vert': { functionName: 'exportGreenSpacePDF', customText: i18n.t("Espace vert").toLowerCase(), layerContainer: this.greenSpacesLayer },
            'Mobilier': { functionName: 'exportFurniturePDF', customText: i18n.t("Mobilier").toLowerCase(), layerContainer: this.furnituresLayerNotClustered },
            'Repère': { functionName: 'exportMarkerPDF', customText: i18n.t("Repère").toLowerCase(), layerContainer: this.markersLayer }
        };

        const { functionName, customText, layerContainer } = categories[category];

        const createRequest = (image) => {
            const customProps = {
                toastCustomTexts: [customText, reference],
                data: { image, photosId, colorless, element: element.feature }
            };
            this.props.setRequest(TasksUtil.createRequest({
                functionName, toastId: 'pdf_exporting', customProps,
                processMessageId: i18n.t("Export de la fiche de l'__ __ en cours..."),
                successMessageId: i18n.t("L'export de la fiche de l'__ __ est terminé"),
                errorMessageId: i18n.t("L'export de la fiche a échoué")
            }));
            this.hideExportPdfForm();
        };

        if (addOverview) {
            if (showLoader) this.showLoader(true, 'map', i18n.t("Préparation de l'export..."));

            const layersToHide = [
                this.treesLayer, this.treesLayerNotClustered, this.greenSpacesLayer, this.furnituresLayer, this.furnituresLayerNotClustered, this.coolingLayer, this.carbonStockLayer
            ].filter(layer => layer !== layerContainer);
            const hiddenLayers = [];
            layersToHide.forEach((layerToHide, i) => {
                if (this.map.hasLayer(layerToHide)) {
                    this.map.removeLayer(layerToHide);
                    hiddenLayers[i] = true;
                } else hiddenLayers[i] = false;
            });

            // On retire les autres layers
            const wasVisible = this.map.hasLayer(layerContainer);
            if (!wasVisible) this.map.addLayer(layerContainer);
            let removedElements = [];
            layerContainer.eachLayer(layer => {
                if (layer?.feature.id !== this.props.layer[0].feature.id) {
                    layerContainer.removeLayer(layer);
                    removedElements.push(layer);
                }
            });

            setTimeout(() => {
                this.takeScreenshot({ elements: [element], showLoader }).then(image => {
                    removedElements.forEach(layer => layerContainer.addLayer(layer));

                    if (!wasVisible && this.map.hasLayer(layerContainer)) this.map.removeLayer(layerContainer);
                    hiddenLayers.forEach((hiddenLayer, i) => { // On réaffiche les layers qu'on avait masqués
                        if (hiddenLayer && !this.map.hasLayer(layersToHide[i]))
                            this.map.addLayer(layersToHide[i]);
                    });

                    createRequest(image);
                });
                resolve();
            }, 3500);
        } else {
            createRequest();
            resolve();
        }
    });

    exportActionAsPDF = async (projectAction) => {
        const createRequest = (image) => {
            const customProps = {
                toastCustomTexts: ['action', `"${projectAction.action.label}"`],
                data: { projectAction, image }
            };
            this.props.setRequest(TasksUtil.createRequest({
                functionName: 'exportActionPDF', toastId: 'pdf_exporting', customProps,
                processMessageId: i18n.t("Export de la fiche de l'__ __ en cours..."),
                successMessageId: i18n.t("L'export de la fiche de l'__ __ est terminé"),
                errorMessageId: i18n.t("L'export de la fiche de l'action a échoué")
            }));
        }

        if (projectAction.projectActionElements.length > 0) {
            this.showLoader(true, 'modal', i18n.t("Préparation de l'export..."));
            const categories = projectAction.action.categories;
            const containers = { 'Arbre': this.treesLayerNotClustered, 'Espace vert': this.greenSpacesLayer, 'Massif arboré': this.greenSpacesLayer, 'Mobilier': this.furnituresLayerNotClustered };
            const layerContainers = categories.filter(category => category !== 'Massif arboré' || !categories.includes('Espace vert')).map(category => containers[category]);

            let linkedElements = [];
            projectAction.projectActionElements.forEach(pae => {
                const layers = layerContainers.flatMap(layerContainer => layerContainer.getLayers());
                layers.forEach(layer => {
                    if (layer.feature.id === pae.elementId && layer.feature.geometry.coordinates.every(coord => coord))
                        linkedElements.push(layer);
                });
            });

            const layersToHide = [
                this.treesLayer, this.treesLayerNotClustered, this.greenSpacesLayer, this.furnituresLayer, this.furnituresLayerNotClustered, this.coolingLayer, this.carbonStockLayer
            ].filter(layer => !layerContainers.includes(layer));
            let hiddenLayers = [];
            layersToHide.forEach((layerToHide, i) => {
                if (this.map.hasLayer(layerToHide)) {
                    this.toggleLayer({ layer: layerToHide });
                    hiddenLayers[i] = true;
                } else hiddenLayers[i] = false;
            });

            // On surligne les éléments liés à l'action et on retire les autres
            const visibleLayers = layerContainers.filter(layerContainer => this.map.hasLayer(layerContainer));
            layerContainers.forEach(layerContainer => {
                if (!visibleLayers.includes(layerContainer))
                    this.map.addLayer(layerContainer);
            });

            let highlightedElements = this.highlightLinkedElements(projectAction.projectActionElements);
            let removedElements = [...Array(layerContainers.length)].map(() => []);
            layerContainers.forEach((layerContainer, i) => layerContainer.eachLayer(layer => {
                if (!highlightedElements.includes(layer)) {
                    layerContainer.removeLayer(layer);
                    removedElements[i].push(layer);
                }
            }));

            setTimeout(() => {
                this.takeScreenshot({ elements: linkedElements, avoidLegendUpdate: true }).then(image => {
                    // On retire les highlights
                    highlightedElements.forEach(highlightedElement => {
                        let style;
                        switch (highlightedElement.feature.properties.category) {
                            case 'Arbre': style = StylesUtil.getTreeActiveStyle(this.treesLayer, this.fieldList, highlightedElement.feature.properties, this.props.project.thematicMaps); break;
                            case 'Espace vert': style = StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, highlightedElement.feature.properties, this.props.project.thematicMaps); break;
                            case 'Mobilier': style = StylesUtil.getFurnitureActiveStyle(this.furnituresLayer.activeChild, this.fieldList, highlightedElement.feature.properties, this.props.project.thematicMaps); break;
                            default: break;
                        }
                        highlightedElement.setStyle(style);
                    });

                    removedElements.forEach((layers, index) => layers.forEach(layer => layerContainers[index].addLayer(layer)));

                    layerContainers.forEach(layerContainer => {
                        if (!visibleLayers.includes(layerContainer) && this.map.hasLayer(layerContainer))
                            this.map.removeLayer(layerContainer);
                    });

                    hiddenLayers.forEach((hiddenLayer, i) => { // On réaffiche les layers qu'on avait masqués
                        if (hiddenLayer && !this.map.hasLayer(layersToHide[i]))
                            this.toggleLayer({ layer: layersToHide[i] });
                    });

                    createRequest(image);
                });
            }, 3500);
        } else createRequest();
    }

    exportActionTableAsPDF = async (rows, { startDate, endDate, groupedBy, reference } = {}) => {
        const createRequest = (image) => {
            const customProps = {
                toastCustomTexts: groupedBy ? [startDate, endDate] : [this.props.layer[0].feature.properties.category, reference],
                data: { startDate, endDate, groupedBy, image, rows, baseProjectId: this.props.project.id, reference }
            };
            this.props.setRequest(TasksUtil.createRequest({
                functionName: 'exportBacklogPDF', toastId: 'backlog_pdf_exporting', customProps,
                processMessageId: i18n.t("Export de la fiche des actions (__ - __) en cours..."),
                successMessageId: i18n.t("L'export de la fiche des actions (__ - __) est terminé"),
                errorMessageId: i18n.t("L'export de la fiche des actions (__ - __) a échoué")
            }));
        };

        const linkedTrees = [], linkedGreenSpaces = [], linkedFurnitures = []
        const categories = {
            'Arbre': {
                layerContainer: this.treesLayerNotClustered, getHighlightStyle: (layer) => StylesUtil.getTreeHighlightStyle(this.treesLayer, this.fieldList, layer.feature.properties), linkedElements: linkedTrees,
                getStyle: (layer) => StylesUtil.getTreeActiveStyle(this.treesLayer, this.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            },
            'Espace vert': {
                layerContainer: this.greenSpacesLayer, getHighlightStyle: () => StylesUtil.getGreenSpaceHighlightStyle(), linkedElements: linkedGreenSpaces,
                getStyle: (layer) => StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            },
            'Mobilier': {
                layerContainer: this.furnituresLayerNotClustered, getHighlightStyle: () => StylesUtil.getFurnitureHighlightStyle(this.fieldList), linkedElements: linkedFurnitures,
                getStyle: (layer) => StylesUtil.getFurnitureActiveStyle(this.furnituresLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            }
        };

        const elements = [...new Set(rows.filter(row => row.elementId && row.category).map(row => ({ id: row.elementId, type: row.category })))];
        if (elements.length > 0) {
            this.showLoader(true, 'modal', i18n.t("Préparation de l'export..."));

            const removedElements = [], highlightedElements = [], addedLayerContainer = [];
            const checkLayerContainerVisibility = (layerContainer) => {
                if (!this.map.hasLayer(layerContainer)) {
                    this.map.addLayer(layerContainer);
                    addedLayerContainer.push(layerContainer);
                }
            };
            const highlightElement = (layer, style) => {
                layer.setStyle(style);
                highlightedElements.push(layer);
            };

            // On surligne les éléments liés à l'action et on retire les autres
            elements.forEach(element => {
                const { layerContainer, getHighlightStyle, linkedElements } = categories[element.type];
                const layer = layerContainer.getLayers().find(layer => layer.feature.id === element.id);
                if (layer) {
                    checkLayerContainerVisibility(layerContainer);
                    if (layer.feature.id === element.id) {
                        if (!linkedElements.includes(layer) && layer.feature.geometry.coordinates.every(coord => coord)) linkedElements.push(layer);
                        highlightElement(layer, getHighlightStyle(layer));
                    }
                }
            });

            // On retire les layers inutiles
            const layersToHide = [this.treesLayer, this.furnituresLayer, this.coolingLayer, this.carbonStockLayer];
            const removeLayerContainerOrLayers = (layerContainer, linkedElements) => {
                if (!linkedElements.length) layersToHide.unshift(layerContainer);
                else {
                    layerContainer.getLayers()
                        .filter(layer => !highlightedElements.includes(layer))
                        .forEach(layer => {
                            layerContainer.removeLayer(layer);
                            removedElements.push(layer);
                        });
                }
            };
            ['Arbre', 'Espace vert', 'Mobilier'].forEach(elementType => {
                const { layerContainer, linkedElements } = categories[elementType];
                removeLayerContainerOrLayers(layerContainer, linkedElements);
            });
            const hiddenLayers = [];
            layersToHide.forEach((layerToHide, i) => {
                if (this.map.hasLayer(layerToHide)) {
                    this.map.removeLayer(layerToHide);
                    hiddenLayers[i] = true;
                } else hiddenLayers[i] = false;
            });

            setTimeout(() => {
                this.takeScreenshot({ elements: [...linkedTrees, ...linkedGreenSpaces, ...linkedFurnitures], avoidLegendUpdate: true, referencesToShow: 'projectReference' }).then(image => {
                    // On retire les highlights
                    highlightedElements.forEach(highlightedElement => {
                        const style = categories[highlightedElement.feature.properties.category]?.getStyle(highlightedElement);
                        if (style) highlightedElement.setStyle(style);
                    });

                    removedElements.forEach(removedElement => {
                        const layerContainer = categories[removedElement.feature.properties.category].layerContainer;
                        layerContainer.addLayer(removedElement);
                    });

                    addedLayerContainer.forEach(layerContainer => {
                        if (this.map.hasLayer(layerContainer))
                            this.map.removeLayer(layerContainer);
                    });
                    hiddenLayers.forEach((hiddenLayer, i) => { // On réaffiche les layers qu'on avait masqués
                        if (hiddenLayer && !this.map.hasLayer(layersToHide[i]))
                            this.map.addLayer(layersToHide[i]);
                    });

                    createRequest(image);
                });
            }, 3500);
        } else createRequest();
    }

    checkIfInsideSurroundings = (shape, layer, surroundings = null) => ProjectsUtil.checkIfInsideSurroundings(shape, layer, this.surroundingsLayer, surroundings);
    updateLoader = (message) => {
        this.setState(prevState => {
            if (prevState.loader.progress.current === prevState.loader.progress.end)
                return { loader: { active: false, progress: null } };
            else return {
                loader: {
                    ...prevState.loader, message,
                    progress: { ...prevState.loader.progress, current: prevState.loader.progress.current + 1 }
                }
            };
        }, () => {
            if (this.state.loader.progress === null && !this.loadingEnd) {
                this.loadingEnd = true; // Permet d'éviter de passer 2 fois dans ce code
                if (this.treesLoadingPromise) showLoadingToast('trees_loading', 'trees_loaded', 'trees_loading_failed', this.treesLoadingPromise);
                if (this.greenSpacesLoadingPromise) showLoadingToast('greenspaces_loading', 'greenspaces_loaded', 'greenspaces_loading_failed', this.greenSpacesLoadingPromise);
                if (this.furnituresLoadingPromise) showLoadingToast('furnitures_loading', 'furnitures_loaded', 'furnitures_loading_failed', this.furnituresLoadingPromise);
                this.redirectViaURL();
                let { filters } = UrlsUtil.getSearchParams(this.originalSearch);
                if (filters)
                    try {
                        filters = JSON.parse(filters);
                        this.filterLayers(filters, true, false);
                        if (RightsUtil.canRead(this.props.rights?.filters) && this.props.activeOrganization?.subscription?.filters && this.publicFields?.main.filters) {
                            this.toolbarRef.current.clickButton('advancedTools');
                            this.props.setButtonState('filters', 1);
                        }
                    } catch { }

                setTimeout(() => {
                    if (this.props.viewProjectAsData) {
                        const layerContainers = {
                            treesLayer: this.treesLayer,
                            treesLayerNotClustered: this.treesLayerNotClustered,
                            greenSpacesLayer: this.greenSpacesLayer,
                            furnituresLayer: this.furnituresLayer,
                            furnituresLayerNotClustered: this.furnituresLayerNotClustered,
                            markersLayer: this.markersLayer,
                            stationsLayer: this.stationsLayer
                        };

                        const viewProjectAsData = {
                            ...this.props.viewProjectAsData,
                            back: () => this.setState({ projectToEdit: this.props.project }, () => this.changeModalContentType('RoleList', 'Gestion des rôles')),
                            setMapSurroundings: this.setMapSurroundings
                        };

                        this.props.setViewProjectAsData(viewProjectAsData).then(() => {
                            this.props.setRights({ ...viewProjectAsData.rightsToApply }).then(() => {
                                viewProjectAsData.apply(this.map, layerContainers);
                            });
                        });
                    }

                    delete this.loadingEnd;
                });
            }
        });
    }

    removeOutsideElements = (removedArea) => {
        [
            { layerContainer: this.treesLayer, legendName: i18n.t("Arbres") }, { layerContainer: this.treesLayerNotClustered, legendName: i18n.t("Arbres") },
            { layerContainer: this.greenSpacesLayer, legendName: i18n.t("Espaces verts") }, { layerContainer: this.markersLayer, legendName: i18n.t("Repères") }, { layerContainer: this.stationsLayer },
            { layerContainer: this.furnituresLayer, legendName: i18n.t("Mobilier urbain") }, { layerContainer: this.furnituresLayerNotClustered, legendName: i18n.t("Mobilier urbain") }
        ].forEach(({ layerContainer, legendName }) => {
            const layersToRemove = layerContainer.getLayers().filter(layer => (
                this.checkIfInsideSurroundings([this.greenSpacesLayer, this.stationsLayer].includes(layerContainer) ? 'polygon' : 'marker', layer, removedArea)
            ));
            if (layersToRemove.length > 0) {
                layersToRemove.forEach(layer => layerContainer.removeLayer(layer));
                if (legendName) this.updateLegend(legendName);
            }
        });
    }

    drawProjectSurroundings = (isModification = false, surroundings = null) => {
        this.disableDrawing();
        this.props.showProjectList(false);
        this.props.setCurrentAction(isModification ? 'modifyingProjectSurroundings' : 'drawingProjectSurroundings');
        this.toolbarRef.current.clickButton('basicTools');

        this.layersVisibility = {
            treesLayer: this.map.hasLayer(this.treesLayer) && (!isModification || this.props.project.id !== this.state.projectToEdit?.id),
            greenSpacesLayer: this.map.hasLayer(this.greenSpacesLayer) && (!isModification || this.props.project.id !== this.state.projectToEdit?.id),
            furnituresLayer: this.map.hasLayer(this.furnituresLayer) && (!isModification || this.props.project.id !== this.state.projectToEdit?.id),
            markersLayer: this.map.hasLayer(this.markersLayer) && (!isModification || this.props.project.id !== this.state.projectToEdit?.id),
            stationsLayer: this.map.hasLayer(this.stationsLayer) && (!isModification || this.props.project.id !== this.state.projectToEdit?.id),
            coolingLayer: this.map.hasLayer(this.coolingLayer),
            carbonStockLayer: this.map.hasLayer(this.carbonStockLayer)
        };

        Object.keys(this.layersVisibility).forEach(layerName => {
            if (this.layersVisibility[layerName] && this.map.hasLayer(this[layerName]))
                this.toggleLayer({ layer: this[layerName] });
        });

        if (this.props.layer) {
            this.map.removeLayer(this.props.layer);
            this.props.setLayer(null);
        }

        this.map.removeLayer(this.surroundingsLayer);
        this.map.setMaxBounds(null);
        this.initialBounds = this.map.getBounds();

        this.setState({
            modal: { visible: false, title: '' },
            modalContentType: 'ModifySurroundingsForm'
        });

        if (!isModification || !surroundings) this.map.pm.enableDraw('Polygon');
        else this.tmpSurroundingsLayer.pm.enable({ removeVertexValidation: GeometriesUtil.removeVertexValidation });
    }

    cancelSurroundingsShrinkage = () => {
        this.setState({
            elementsOutsideNewBounds: null,
            modal: { visible: false, title: '' },
            modalContentType: 'ModifySurroundingsForm'
        });
    }

    finishSurroundingsUpdate = (layer = null) => { // Aussi utilisée pour l'annulation
        const { projectToDuplicate, projectToEdit } = this.state;
        let type = projectToDuplicate ? 'duplication' : projectToEdit ? 'modification' : 'creation';
        if (!this.props.editedProperties.project.id) type = 'creation';

        this.props.setCurrentAction('').then(() => {
            const basicToolsButton = document.getElementById('uhRCkpxG');
            if (basicToolsButton) basicToolsButton.click();
            if (this.tmpSurroundingsLayer) this.tmpSurroundingsLayer.pm.disable();
            this.props.setLayer(layer).then(() => {
                this.changeModalContentType(type === 'modification' ? 'ProjectModificationForm' : type === 'duplication' ? 'ProjectDuplicationForm' : 'ProjectCreationForm',
                    type === 'modification' ? i18n.t("Paramètres du projet") : type === 'duplication' ? i18n.t("Duplication du projet") : i18n.t("Création d'un projet"));
                if (this.tmpSurroundingsLayer) this.map.removeLayer(this.tmpSurroundingsLayer);
                if (this.surroundingsDottedLayer) this.map.removeLayer(this.surroundingsDottedLayer);
                Object.keys(this.layersVisibility).forEach(layerName => {
                    if (this.layersVisibility[layerName] && !this.map.hasLayer(this[layerName]))
                        this.toggleLayer({ layer: this[layerName] });
                });

                this.map.addLayer(this.surroundingsLayer);
                this.map.setMaxBounds(this.maxBounds);
                if (this.initialBounds) this.map.fitBounds(this.initialBounds, { animate: false });
                this.initialBounds = null;
            });
        });
    };

    finishSurroundingsModification = () => {
        if (this.props.editedProperties.project && !this.props.editedProperties.project.id) {
            this.props.setEditedProperties({ ...this.props.editedProperties, project: { ...this.props.editedProperties.project, surroundings: JSON.stringify(GeoJsonUtil.generatePolygonFeature(null, this.tmpSurroundingsLayer._latlngs)) } });
            this.finishSurroundingsUpdate(this.tmpSurroundingsLayer);
            return;
        }

        const initialProject = !this.props.projects && this.props.editedProperties.project.id === this.props.project.id
            ? this.props.project
            : ProjectsUtil.getBaseProject(this.props.editedProperties.project.id, this.props.projects);
        const initialProjectGeometry = JSON.parse(initialProject.surroundings).geometry;
        const invertedSurroundings = GeometriesUtil.invertPolygon(polygon(GeometriesUtil.convertPolygonLatLngsToCoordinates(this.tmpSurroundingsLayer.getLatLngs())));
        const removedArea = difference(featureCollection([ // Il faut que le nouveau polygone recouvre entièrement l'ancien
            initialProjectGeometry.type === 'MultiPolygon' ? multiPolygon(initialProjectGeometry.coordinates) : polygon(initialProjectGeometry.coordinates),
            invertedSurroundings
        ]));

        if (!removedArea) {
            this.props.setEditedProperties({ ...this.props.editedProperties, project: { ...this.props.editedProperties.project, surroundings: JSON.stringify(GeoJsonUtil.generatePolygonFeature(null, this.tmpSurroundingsLayer._latlngs)) } });
            this.finishSurroundingsUpdate(this.tmpSurroundingsLayer);
        } else if (this.state.projectToEdit.id === this.props.project.id) {
            const removedTrees = this.treesLayer.getLayers().filter(layer => this.checkIfInsideSurroundings('marker', layer, removedArea)).map(layer => layer.feature);
            const removedGreenSpaces = this.greenSpacesLayer.getLayers().filter(layer => this.checkIfInsideSurroundings('polygon', layer, removedArea)).map(layer => layer.feature);
            const removedFurnitures = this.furnituresLayer.getLayers().filter(layer => this.checkIfInsideSurroundings('marker', layer, removedArea)).map(layer => layer.feature);
            const removedMarkers = this.markersLayer.getLayers().filter(layer => this.checkIfInsideSurroundings('marker', layer, removedArea)).map(layer => layer.feature);
            const removedStations = this.stationsLayer.getLayers().filter(layer => this.checkIfInsideSurroundings('polygon', layer, removedArea)).map(layer => layer.feature);
            if (!removedTrees.length && !removedGreenSpaces.length && !removedFurnitures.length && !removedMarkers.length && !removedStations.length) {
                this.props.setEditedProperties({ ...this.props.editedProperties, project: { ...this.props.editedProperties.project, surroundings: JSON.stringify(GeoJsonUtil.generatePolygonFeature(null, this.tmpSurroundingsLayer._latlngs)) } });
                this.finishSurroundingsUpdate(this.tmpSurroundingsLayer);
            } else {
                this.confirmProjectShrinkage = () => {
                    this.props.setEditedProperties({ ...this.props.editedProperties, project: { ...this.props.editedProperties.project, surroundings: JSON.stringify(GeoJsonUtil.generatePolygonFeature(null, this.tmpSurroundingsLayer._latlngs)) } });
                    const finishSurroundingsUpdate = this.finishSurroundingsUpdate.bind(null, this.tmpSurroundingsLayer);
                    finishSurroundingsUpdate();
                }

                this.setState({ elementsOutsideNewBounds: { trees: removedTrees, greenSpaces: removedGreenSpaces, furnitures: removedFurnitures, markers: removedMarkers, stations: removedStations } }, () => {
                    this.changeModalContentType('ProjectShrinkageForm', i18n.t("Redimensionnement de la zone géographique d'un projet"));
                });
            }
        } else {
            const promises = [];
            this.showLoader(true, 'map', i18n.t("Récupération des éléments à supprimer en cours..."));
            promises.push(TreesService.getTrees(this.props.editedProperties.project.id, { bounds: JSON.stringify(removedArea.geometry) }));
            promises.push(GreenSpacesService.getGreenSpaces(this.props.editedProperties.project.id, { bounds: JSON.stringify(removedArea.geometry) }));
            promises.push(FurnituresService.getFurnitures(this.props.editedProperties.project.id, { bounds: JSON.stringify(removedArea.geometry) }));
            promises.push(MarkersService.getMarkers(this.props.editedProperties.project.id, { bounds: JSON.stringify(removedArea.geometry) }));
            promises.push(StationsService.getStations(this.props.editedProperties.project.id, { bounds: JSON.stringify(removedArea.geometry) }));
            Promise.all(promises).then(([treesData, greenSpacesData, furnituresData, markers, stations]) => {
                this.showLoader(false);
                if (!treesData?.trees || !greenSpacesData?.greenSpaces || !furnituresData?.furnitures || !markers || !stations) showToast('elements_inside_bounds_loading_failed');
                else if (!treesData.trees.length && !greenSpacesData.greenSpaces.length && !furnituresData.furnitures.length && !markers.length && !stations.length) {
                    this.props.setEditedProperties({ ...this.props.editedProperties, project: { ...this.props.editedProperties.project, surroundings: JSON.stringify(GeoJsonUtil.generatePolygonFeature(null, this.tmpSurroundingsLayer._latlngs)) } });
                    this.finishSurroundingsUpdate(this.tmpSurroundingsLayer);
                } else {
                    this.confirmProjectShrinkage = () => {
                        this.props.setEditedProperties({ ...this.props.editedProperties, project: { ...this.props.editedProperties.project, surroundings: JSON.stringify(GeoJsonUtil.generatePolygonFeature(null, this.tmpSurroundingsLayer._latlngs)) } });
                        const finishSurroundingsUpdate = this.finishSurroundingsUpdate.bind(null, this.tmpSurroundingsLayer);
                        finishSurroundingsUpdate();
                    }

                    this.setState({ elementsOutsideNewBounds: { trees: treesData.trees, greenSpaces: greenSpacesData.greenSpaces, furnitures: furnituresData.furnitures, markers, stations } }, () => {
                        this.changeModalContentType('ProjectShrinkageForm', i18n.t("Redimensionnement de la zone géographique d'un projet"));
                    });
                }
            });
        }
    }

    modifyProjectSurroundings = () => {
        const project = this.props.editedProperties.project;
        const surroundings = JSON.parse(project.surroundings);
        if (surroundings) {
            const latLngs = GeometriesUtil.convertPolygonCoordinatesToLatLngs(surroundings.geometry.coordinates, surroundings.geometry.type === 'MultiPolygon');
            this.surroundingsDottedLayer = L.polygon(latLngs, { color: "#000000", opacity: 0.4, fillOpacity: 0, weight: 4, dashArray: '10 10', pmIgnore: true, pane: 'surroundings' }).addTo(this.map);
            const surroundingsPolygon = surroundings.geometry.type === 'Polygon'
                ? polygon(surroundings.geometry.coordinates)
                : multiPolygon(surroundings.geometry.coordinates);
            const invertedSurroundings = GeometriesUtil.invertPolygon(surroundingsPolygon);
            const invertedLatLngs = GeometriesUtil.convertPolygonCoordinatesToLatLngs(invertedSurroundings.geometry.coordinates);
            this.tmpSurroundingsLayer = L.polygon(invertedLatLngs, this.props.isDarkTheme ? StylesUtil.getSurroundingsDarkStyle() : StylesUtil.getSurroundingsLightStyle()).addTo(this.map);
        }

        this.drawProjectSurroundings(true, surroundings);
        if (surroundings) this.map.fitBounds(this.surroundingsDottedLayer.getBounds());
    }

    drawCustomArea = () => {
        const basicToolsButton = document.getElementById('uhRCkpxG');
        if (basicToolsButton) basicToolsButton.click();

        this.props.showProjectList(false);
        this.layersVisibility = {
            treesLayer: this.map.hasLayer(this.treesLayer),
            greenSpacesLayer: this.map.hasLayer(this.greenSpacesLayer),
            furnituresLayer: this.map.hasLayer(this.furnituresLayer),
            markersLayer: this.map.hasLayer(this.markersLayer),
            stationsLayer: this.map.hasLayer(this.stationsLayer),
            coolingLayer: this.map.hasLayer(this.coolingLayer),
            carbonStockLayer: this.map.hasLayer(this.carbonStockLayer)
        };

        Object.keys(this.layersVisibility).forEach(layerName => {
            if (this.layersVisibility[layerName] && this.map.hasLayer(this[layerName]))
                this.toggleLayer({ layer: this[layerName] });
        });

        if (this.props.layer) {
            this.map.removeLayer(this.props.layer);
            this.props.setLayer(null);
        }

        if (this.state.projectToEdit?.id !== this.props.project.id) {
            this.map.removeLayer(this.surroundingsLayer);
            this.initialBounds = this.map.getBounds();

            const surroundings = JSON.parse(this.state.projectToEdit.surroundings);
            const surroundingsPolygon = surroundings.geometry.type === 'Polygon'
                ? polygon(surroundings.geometry.coordinates)
                : multiPolygon(surroundings.geometry.coordinates);
            const boundsPolygon = bbox(surroundingsPolygon);
            const scaledCoordinates = GeometriesUtil.getScaledBbox(boundsPolygon, this.map.getBounds());
            this.props.setCurrentAction('drawingCustomArea').then(() => {
                this.map.fitBounds(L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(surroundingsPolygon.geometry.coordinates, surroundingsPolygon.geometry.type === 'MultiPolygon')).getBounds());
                this.map.setMaxBounds(L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(scaledCoordinates)).getBounds());
            });
            this.tmpSurroundingsLayer = L.polygon(
                GeometriesUtil.convertPolygonCoordinatesToLatLngs(GeometriesUtil.invertPolygon(surroundingsPolygon).geometry.coordinates),
                this.props.isDarkTheme ? StylesUtil.getSurroundingsDarkStyle() : StylesUtil.getSurroundingsLightStyle()
            ).addTo(this.map);
        } else this.props.setCurrentAction('drawingCustomArea');

        this.setState({ modal: { visible: false, title: '' } });
        this.map.pm.enableDraw('Polygon');
    }

    setLayers = () => {
        return new Promise((resolve) => {
            const { project, activeOrganization } = this.props;
            const projectSubscription = project.organization.subscription;

            this.fieldList = {
                projectActions: this.props.projectActions,
                healthReviews: this.props.healthReviews,
                vigors: this.props.vigors,
                risks: this.props.risks,
                ontogenicStages: this.props.ontogenicStages,
                essences: this.props.essences,
                dominantCompositions: this.props.dominantCompositions,
                runoffCoefficients: this.props.runoffCoefficients,
                furnitureTypes: this.props.furnitureTypes,
                zoomLevel: this.map.getZoom()
            };

            const markerClusterOptions = {
                disableClusteringAtZoom: 17,
                spiderfyOnMaxZoom: false,
                removeOutsideVisibleBounds: true
            };

            this.referencesLayer = L.layerGroup(null, { pane: 'references' }).addTo(this.map);

            // Layer 'Arbres'
            this.treesLayer = L.markerClusterGroup({
                ...markerClusterOptions, pane: 'trees',
                polygonOptions: { fillColor: 'var(--secondary-100)', color: 'var(--primary-100)' },
                iconCreateFunction: (cluster) => {
                    const childCount = cluster.getChildCount();
                    const backgroundColor = tinycolor(document.body.style.getPropertyValue('--green-100'));
                    backgroundColor.setAlpha(0.6);
                    const fontColor = backgroundColor.getBrightness() < 100 ? 'white' : 'black';

                    return new L.DivIcon({ html: `<div style="background-color: ${backgroundColor.toString()};"><span style='color: ${fontColor} !important;'>` + childCount + '</span></div>', className: 'marker-cluster', iconSize: new L.Point(40, 40) });
                }
            });
            this.treesLayerNotClustered = L.layerGroup(null, { pane: 'trees' });
            this.treesLayerNotClustered.label = i18n.t("Arbres");

            // Layer 'Espaces verts'
            this.greenSpacesLayer = L.layerGroup(null, { pane: 'greenSpaces' });

            // Layer 'Mobilier urbain'
            this.furnituresLayer = L.markerClusterGroup({
                ...markerClusterOptions, pane: 'furnitures',
                polygonOptions: { fillColor: 'var(--blue-70)', color: 'var(--blue-100)' },
                iconCreateFunction: (cluster) => {
                    const childCount = cluster.getChildCount();
                    const backgroundColor = tinycolor(document.body.style.getPropertyValue('--blue-100'));
                    backgroundColor.setAlpha(0.6);
                    const fontColor = backgroundColor.getBrightness() < 100 ? 'white' : 'black';

                    return new L.DivIcon({ html: `<div style="background-color: ${backgroundColor.toString()};"><span style='color: ${fontColor} !important;'>` + childCount + '</span></div>', className: 'marker-cluster', iconSize: new L.Point(40, 40) });
                }
            });
            this.furnituresLayerNotClustered = L.layerGroup(null, { pane: 'furnitures' });
            this.furnituresLayerNotClustered.label = i18n.t("Mobilier urbain");

            // Layer 'Repères'
            this.markersLayer = L.layerGroup(null, { pane: 'markers' });

            // Layer 'Stations'
            this.stationsLayer = L.layerGroup(null, { pane: 'stations' });

            // Layer 'Images'
            this.backgroundImagesLayer = L.distortableCollection();
            this.backgroundImagesLayer.label = 'BackgroundImages';
            this.backgroundImagesLayer.addTo(this.map);

            // Overlays
            const overlays = [];
            const projectsView = localStorage.getItem('projectsView');
            const projectView = projectsView ? JSON.parse(projectsView).find(view => view.id === this.props.project.id) : null;
            let urlOverlays = UrlsUtil.getSearchParams(this.originalSearch).overlays;
            if (urlOverlays) urlOverlays = urlOverlays.split(',');
            let treesLayerActiveChild = urlOverlays ? i18n.t(urlOverlays.find(overlay => overlay.split(':')[0] === 'Arbres')?.split(':')[1]) : projectView?.overlays?.find(overlay => overlay.label === i18n.t("Arbres"))?.activeChild;
            if (!isNaN(treesLayerActiveChild)) treesLayerActiveChild = +treesLayerActiveChild;
            const treesLayerActiveOptions = urlOverlays ? urlOverlays.find(overlay => overlay.split(':')[0] === 'Arbres')?.split(':').slice(2).map(option => i18n.t(option)) : projectView?.overlays?.find(overlay => overlay.label === i18n.t("Arbres"))?.activeOptions;
            let furnituresLayerActiveChild = urlOverlays ? i18n.t(urlOverlays.find(overlay => overlay.split(':')[0] === 'Mobilier urbain')?.split(':')[1]) : projectView?.overlays?.find(overlay => overlay.label === i18n.t("Mobilier urbain"))?.activeChild;
            if (!isNaN(furnituresLayerActiveChild)) furnituresLayerActiveChild = +furnituresLayerActiveChild;
            let greenSpacesLayerActiveChild = urlOverlays ? i18n.t(urlOverlays.find(overlay => overlay.split(':')[0] === 'Espaces verts')?.split(':')[1]) : projectView?.overlays?.find(overlay => overlay.label === i18n.t("Espaces verts"))?.activeChild;
            if (!isNaN(greenSpacesLayerActiveChild)) greenSpacesLayerActiveChild = +greenSpacesLayerActiveChild;

            if (this.publicFields.main.trees) overlays.push({ label: i18n.t("Arbres"), originalLabel: 'Arbres', layer: this.treesLayer, isShown: urlOverlays ? (urlOverlays.find(overlay => overlay.split(':')[0] === 'Arbres') ? true : false) : (projectView?.overlays?.find(overlay => overlay.label === i18n.t("Arbres"))?.isShown ?? true), isLegendShown: projectView?.overlays?.find(overlay => overlay.label === i18n.t("Arbres"))?.isLegendShown ?? true, activeChild: treesLayerActiveChild, activeOptions: treesLayerActiveOptions || [] });
            if (this.publicFields.main.greenSpaces) overlays.push({ label: i18n.t("Espaces verts"), originalLabel: 'Espaces verts', layer: this.greenSpacesLayer, isShown: urlOverlays ? (urlOverlays.find(overlay => overlay.split(':')[0] === 'Espaces verts') ? true : false) : (projectView?.overlays?.find(overlay => overlay.label === i18n.t("Espaces verts"))?.isShown ?? true), isLegendShown: projectView?.overlays?.find(overlay => overlay.label === i18n.t("Espaces verts"))?.isLegendShown ?? true, activeChild: greenSpacesLayerActiveChild });
            if (this.publicFields.main.furnitures) overlays.push({ label: i18n.t("Mobilier urbain"), originalLabel: 'Mobilier urbain', layer: this.furnituresLayer, isShown: urlOverlays ? (urlOverlays.find(overlay => overlay.split(':')[0] === 'Mobilier urbain') ? true : false) : (projectView?.overlays?.find(overlay => overlay.label === i18n.t("Mobilier urbain"))?.isShown ?? true), isLegendShown: projectView?.overlays?.find(overlay => overlay.label === i18n.t("Mobilier urbain"))?.isLegendShown ?? true, activeChild: furnituresLayerActiveChild });
            if (this.publicFields.main.markers) overlays.push({ label: i18n.t("Repères"), originalLabel: 'Repères', layer: this.markersLayer, isShown: urlOverlays ? (urlOverlays.find(overlay => overlay.split(':')[0] === 'Repères') ? true : false) : (projectView?.overlays?.find(overlay => overlay.label === i18n.t("Repères"))?.isShown ?? true), isLegendShown: projectView?.overlays?.find(overlay => overlay.label === i18n.t("Repères"))?.isLegendShown ?? true });
            if (this.publicFields.main.stations) overlays.push({ label: i18n.t("Stations"), originalLabel: 'Stations', layer: this.stationsLayer, isShown: urlOverlays ? (urlOverlays.find(overlay => overlay.split(':')[0] === 'Stations') ? true : false) : (projectView?.overlays?.find(overlay => overlay.label === i18n.t("Stations"))?.isShown ?? true), showToggleLegend: false });
            this.setState({ overlays });

            // Heatmap
            this.coolingLayerValues = [];
            this.coolingLayer = new HeatmapOverlay({
                useLocalExtrema: true,
                maxOpacity: 0.8,
                minOpacity: 0,
                gradient: { 0: '#ff3300', 0.2: '#67e0fe', 1: '#01cbfe' }
            });

            // Stock carbone
            this.carbonStockLayerValues = [];
            this.carbonStockLayer = new HeatmapOverlay({
                useLocalExtrema: true,
                maxOpacity: 0.8,
                minOpacity: 0,
                gradient: {
                    0.5: '#21d2fe', 0.55: '#21d0fd',
                    0.6: '#2fbffc', 0.65: '#3aaefb',
                    0.7: '#4a9dfd', 0.75: '#588bfe',
                    0.8: '#657cfe', 0.85: '#736bfe',
                    0.90: '#8259ff', 0.95: '#8259ff',
                    1: '#a136fe'
                }
            });
            this.carbonStockLayer.setData({ data: this.carbonStockLayerValues.filter(coord => coord.radius >= 0.5) });

            this.treesLayer.activeChild = treesLayerActiveChild || i18n.t("Aucun");
            this.treesLayer.activeOptions = treesLayerActiveOptions || [];
            this.updateOverlayInControlLayer(i18n.t("Arbres"), this.treesLayer);
            if (this.publicFields.main.trees) {
                this.addOverlayToControlLayer(i18n.t("Références"), {
                    originalLabel: 'Références', parentLabel: i18n.t("Arbres"), onlyOne: false,
                    buttons: [
                        { name: null, icon: faBan, label: i18n.t("Aucune"), isActive: true, onClick: () => this.renderMarkersReferences('Arbre', null, false) },
                        { name: 'projectReference', icon: faCircle1, label: i18n.t("Références auto-incrémentées"), isActive: false, onClick: () => this.renderMarkersReferences('Arbre', 'projectReference', true, false, true) },
                        { name: 'customReference', icon: faCircleA, label: i18n.t("Références personnalisées"), isActive: false, onClick: () => this.renderMarkersReferences('Arbre', 'customReference', true, false, true) }
                    ]
                });
                if (this.publicFields.trees.trunks) this.addOverlayToControlLayer(i18n.t("Diamètre des couronnes"), { originalLabel: 'Diamètre des couronnes', parentLabel: i18n.t("Arbres"), toggle: true, divider: true });
                this.addOverlayToControlLayer(i18n.t("Aucun"), { originalLabel: 'Aucun', parentLabel: i18n.t("Arbres"), isDefault: !treesLayerActiveChild });
                if (this.publicFields.trees.healthReview) this.addOverlayToControlLayer(i18n.t("Cotes sanitaires"), { originalLabel: 'Cotes sanitaires', parentLabel: i18n.t("Arbres") });
                if (this.publicFields.trees.vigor) this.addOverlayToControlLayer(i18n.t("Vigueurs"), { originalLabel: 'Vigueurs', parentLabel: i18n.t("Arbres") });
                if (this.publicFields.trees.risk) this.addOverlayToControlLayer(i18n.t("Risques"), { originalLabel: 'Risques', parentLabel: i18n.t("Arbres") });
                if (this.publicFields.trees.ontogenicStage) this.addOverlayToControlLayer(i18n.t("Stades ontogéniques"), { originalLabel: 'Stades ontogéniques', parentLabel: i18n.t("Arbres") });
                if (this.publicFields.trees.age) this.addOverlayToControlLayer(i18n.t("Âges"), { originalLabel: 'Âges', parentLabel: i18n.t("Arbres") });
                if (this.publicFields.trees.toCutDown) this.addOverlayToControlLayer(i18n.t("Abattages"), { originalLabel: 'Abattages', parentLabel: i18n.t("Arbres") });
                if (this.publicFields.main.actions) this.addOverlayToControlLayer(i18n.t("Actions"), { originalLabel: 'Actions', parentLabel: i18n.t("Arbres") });

                if (projectSubscription?.thematicMaps && this.publicFields.main.thematicMaps && project.thematicMaps)
                    project.thematicMaps.filter(thematicMap => thematicMap.category === 'Arbre').forEach(thematicMap => {
                        this.addOverlayToControlLayer(thematicMap.label, { parentLabel: i18n.t("Arbres"), customMap: thematicMap });
                    });

                const ecosystemServicesOverlay = projectView?.overlays?.find(overlay => overlay.label === i18n.t("Services écosystémiques"));
                if (this.requiredFields.trees.carbonStock && activeOrganization?.subscription.carbonStockFormula
                    && this.publicFields.trees.carbonStock)
                    this.addOverlayToControlLayer(i18n.t("Stock carbone"), { originalLabel: 'Stock carbone', parentLabel: i18n.t("Services écosystémiques"), layer: this.carbonStockLayer, isDefault: !ecosystemServicesOverlay?.activeChild || ecosystemServicesOverlay.activeChild === i18n.t("Stock carbone"), showToggleLegend: false })
                        .then(() => {
                            if (urlOverlays
                                ? i18n.t(urlOverlays.find(overlay => overlay.split(':')[0] === 'Services écosystémiques')?.split(':')[1]) === 'Stock carbone'
                                : ecosystemServicesOverlay?.isShown && ecosystemServicesOverlay?.activeChild === i18n.t("Stock carbone"))
                                this.toggleLayer({ layer: this.carbonStockLayer })
                        });
                if (this.requiredFields.trees.trunks && this.requiredFields.trees.trunks && activeOrganization?.subscription.coolingFormula
                    && this.publicFields.trees.coolingIndicator)
                    this.addOverlayToControlLayer(i18n.t("Rafraîchissement"), { originalLabel: 'Rafraîchissement', parentLabel: i18n.t("Services écosystémiques"), layer: this.coolingLayer, isDefault: ecosystemServicesOverlay?.activeChild === i18n.t("Rafraîchissement") })
                        .then(() => {
                            if (urlOverlays
                                ? i18n.t(urlOverlays.find(overlay => overlay.split(':')[0] === 'Services écosystémiques')?.split(':')[1]) === 'Rafraîchissement'
                                : ecosystemServicesOverlay?.isShown && ecosystemServicesOverlay?.activeChild === i18n.t("Rafraîchissement"))
                                this.toggleLayer({ layer: this.coolingLayer })
                        });
            }

            if (this.publicFields.main.greenSpaces) {
                this.addOverlayToControlLayer(i18n.t("Références"), {
                    originalLabel: 'Références', parentLabel: i18n.t("Espaces verts"), onlyOne: false, divider: true,
                    buttons: [
                        { name: null, icon: faBan, label: i18n.t("Aucune"), isActive: true, onClick: () => this.renderGreenSpacesReferences(null, false) },
                        { name: 'projectReference', icon: faCircle1, label: i18n.t("Références auto-incrémentées"), isActive: false, onClick: () => this.renderGreenSpacesReferences('projectReference', true, false, true) },
                        { name: 'customReference', icon: faCircleA, label: i18n.t("Références personnalisées"), isActive: false, onClick: () => this.renderGreenSpacesReferences('customReference', true, false, true) }
                    ]
                });
                this.greenSpacesLayer.activeChild = greenSpacesLayerActiveChild || i18n.t("Aucun");
                this.updateOverlayInControlLayer(i18n.t("Espaces verts"), this.greenSpacesLayer);
                this.addOverlayToControlLayer(i18n.t("Aucun"), { originalLabel: 'Aucun', parentLabel: i18n.t("Espaces verts"), isDefault: !greenSpacesLayerActiveChild });
                if (this.publicFields.greenSpaces.dominantComposition) this.addOverlayToControlLayer(i18n.t("Compositions dominantes"), { originalLabel: 'Compositions dominantes', parentLabel: i18n.t("Espaces verts") });
                if (this.publicFields.main.actions) this.addOverlayToControlLayer(i18n.t("Actions"), { originalLabel: 'Actions', parentLabel: i18n.t("Espaces verts") });

                if (projectSubscription?.thematicMaps && this.publicFields.main.thematicMaps && project.thematicMaps)
                    project.thematicMaps.filter(thematicMap => thematicMap.category === 'Espace vert').forEach(thematicMap => {
                        this.addOverlayToControlLayer(thematicMap.label, { parentLabel: i18n.t("Espaces verts"), customMap: thematicMap });
                    });
            }

            if (this.publicFields.main.furnitures) {
                this.addOverlayToControlLayer(i18n.t("Références"), {
                    originalLabel: 'Références', parentLabel: i18n.t("Mobilier urbain"), onlyOne: false, divider: true,
                    buttons: [
                        { name: null, icon: faBan, label: i18n.t("Aucune"), isActive: true, onClick: () => this.renderMarkersReferences('Mobilier', null, false) },
                        { name: 'projectReference', icon: faCircle1, label: i18n.t("Références auto-incrémentées"), isActive: false, onClick: () => this.renderMarkersReferences('Mobilier', 'projectReference', true, false, true) },
                        { name: 'customReference', icon: faCircleA, label: i18n.t("Références personnalisées"), isActive: false, onClick: () => this.renderMarkersReferences('Mobilier', 'customReference', true, false, true) }
                    ]
                });
                this.furnituresLayer.activeChild = furnituresLayerActiveChild || i18n.t("Aucun");
                this.updateOverlayInControlLayer(i18n.t("Mobilier urbain"), this.furnituresLayer);
                this.addOverlayToControlLayer(i18n.t("Aucun"), { originalLabel: 'Aucun', parentLabel: i18n.t("Mobilier urbain"), isDefault: !furnituresLayerActiveChild });
                if (this.publicFields.main.actions) this.addOverlayToControlLayer(i18n.t("Actions"), { originalLabel: 'Actions', parentLabel: i18n.t("Mobilier urbain") });

                if (projectSubscription?.thematicMaps && this.publicFields.main.thematicMaps && project.thematicMaps)
                    project.thematicMaps.filter(thematicMap => thematicMap.category === 'Mobilier').forEach(thematicMap => {
                        this.addOverlayToControlLayer(thematicMap.label, { parentLabel: i18n.t("Mobilier urbain"), customMap: thematicMap });
                    });
            }

            if (this.publicFields.main.markers) {
                this.addOverlayToControlLayer(i18n.t("Références"), {
                    originalLabel: 'Références', parentLabel: i18n.t("Repères"), onlyOne: false,
                    buttons: [
                        { name: null, icon: faBan, label: i18n.t("Aucune"), isActive: true, onClick: () => this.renderMarkersReferences('Repère', null, false) },
                        { name: 'projectReference', icon: faCircle1, label: i18n.t("Références auto-incrémentées"), isActive: false, onClick: () => this.renderMarkersReferences('Repère', 'projectReference', true, false, true) },
                        { name: 'customReference', icon: faCircleA, label: i18n.t("Références personnalisées"), isActive: false, onClick: () => this.renderMarkersReferences('Repère', 'customReference', true, false, true) }
                    ]
                });
                this.updateOverlayInControlLayer(i18n.t("Repères"), this.markersLayer);
            }
            if (this.publicFields.main.stations) this.updateOverlayInControlLayer(i18n.t("Stations"), this.stationsLayer);

            const naturaOverlay = projectView?.overlays?.find(overlay => overlay.label === i18n.t("Natura 2000"));
            this.naturaBirdsLayer = L.tileLayer.wms('https://bio.discomap.eea.europa.eu/arcgis/services/ProtectedSites/Natura2000Sites/MapServer/WMSServer?', {
                attribution: '© EEA, Copenhagen, 2021',
                format: 'image/png',
                layers: '1',
                pane: 'overlayPane',
                transparent: true,
                maxNativeZoom: 18,
                maxZoom: 22
            });

            this.naturaHabitatsLayer = L.tileLayer.wms('https://bio.discomap.eea.europa.eu/arcgis/services/ProtectedSites/Natura2000Sites/MapServer/WMSServer?', {
                attribution: '© EEA, Copenhagen, 2021',
                format: 'image/png',
                layers: '2',
                pane: 'overlayPane',
                transparent: true,
                maxNativeZoom: 18,
                maxZoom: 22
            });

            this.addOverlayToControlLayer(i18n.t("Oiseaux"), { originalLabel: 'Oiseaux', parentLabel: i18n.t("Natura 2000"), layer: this.naturaBirdsLayer, selectAllCheckbox: true, onlyOne: false })
                .then(() => {
                    if (naturaOverlay?.isShown && naturaOverlay?.children?.find(child => child.label === i18n.t("Oiseaux"))?.isShown) this.toggleLayer({ layer: this.naturaBirdsLayer });
                });

            this.addOverlayToControlLayer(i18n.t("Habitats"), { originalLabel: 'Habitats', parentLabel: i18n.t("Natura 2000"), layer: this.naturaHabitatsLayer, selectAllCheckbox: true, onlyOne: false })
                .then(() => {
                    if (naturaOverlay?.isShown && naturaOverlay?.children?.find(child => child.label === i18n.t("Habitats"))?.isShown) this.toggleLayer({ layer: this.naturaHabitatsLayer })
                    if (naturaOverlay?.isLegendShown) this.toggleLegend(naturaOverlay);
                });

            this.googleLayerRoads = L.gridLayer.googleMutant({
                maxZoom: 22,
                type: 'roadmap',
                styles: this.props.isDarkTheme ? googleRoadsDark : googleRoads,
                pane: 'overlayPane'
            });
            this.addOverlayToControlLayer(i18n.t("Routes"), { originalLabel: 'Routes', layer: this.googleLayerRoads, showToggleLegend: false })
                .then(() => {
                    if (urlOverlays ? urlOverlays.find(overlay => overlay === 'Routes') : projectView?.overlays?.find(overlay => overlay.label === i18n.t("Routes"))?.isShown)
                        this.toggleLayer({ layer: this.googleLayerRoads });
                });

            if (this.publicFields.main.images) this.updateOverlayInControlLayer('BackgroundImages', this.backgroundImagesLayer);

            this.referencesRendering = [];
            if (this.publicFields.main.trees) {
                const overlay = projectView?.overlays?.find(overlay => overlay.label === i18n.t("Arbres"));
                const referencesOverlay = overlay?.children?.find(child => child.label === i18n.t("Références"));
                const activeReferences = referencesOverlay?.buttons?.find(b => b.isActive);
                if (activeReferences) this.referencesRendering.push(() => this.renderMarkersReferences('Arbre', activeReferences.name, activeReferences.name && overlay.isShown ? true : false));
            }

            if (this.publicFields.main.greenSpaces) {
                const overlay = projectView?.overlays?.find(overlay => overlay.label === i18n.t("Espaces verts"));
                const referencesOverlay = overlay?.children?.find(child => child.label === i18n.t("Références"));
                const activeReferences = referencesOverlay?.buttons?.find(b => b.isActive);
                if (activeReferences && overlay.isShown) this.referencesRendering.push(() => this.renderGreenSpacesReferences(activeReferences.name, activeReferences.name ? true : false));
            }

            if (this.publicFields.main.furnitures) {
                const overlay = projectView?.overlays?.find(overlay => overlay.label === i18n.t("Mobilier urbain"));
                const referencesOverlay = overlay?.children?.find(child => child.label === i18n.t("Références"));
                const activeReferences = referencesOverlay?.buttons?.find(b => b.isActive);
                if (activeReferences && overlay.isShown) this.referencesRendering.push(() => this.renderMarkersReferences('Mobilier', activeReferences.name, activeReferences.name ? true : false));
            }

            if (this.publicFields.main.markers) {
                const overlay = projectView?.overlays?.find(overlay => overlay.label === i18n.t("Repères"));
                const referencesOverlay = overlay?.children?.find(child => child.label === i18n.t("Références"));
                const activeReferences = referencesOverlay?.buttons?.find(b => b.isActive);
                if (activeReferences && overlay.isShown) this.referencesRendering.push(() => this.renderMarkersReferences('Repère', activeReferences.name, activeReferences.name ? true : false));
            }

            resolve();
        });
    }

    bringPaneToFront = (name) => {
        const elementNames = ['Tree', 'Furniture', 'Marker', 'GreenSpace', 'Station', 'Image'];
        const elementName = elementNames.find(elementName => name.includes(elementName));

        if (elementName) {
            if (elementName === 'Image') this.map.getPane('overlayPane').style.zIndex = 499;
            else {
                const pane = this.map.getPane(FormattersUtil.lowerFirstLetter(elementName) + 's');
                if (pane) pane.style.zIndex = 499;
            }
        }
    }

    resetPanesIndexes = (forceReset = false) => {
        if (forceReset || this.panes.some(pane => +pane.style.zIndex === 499)) {
            let zIndex = 400;
            this.panes.forEach(pane => { pane.style.zIndex = zIndex; zIndex++; });
        }
    }

    hideDrawLines = () => {
        this.greenSpacesLayer.getLayers().filter(layer => layer.feature && layer.drawLine).forEach(layer => {
            this.greenSpacesLayer.removeLayer(layer.drawLine);
            delete layer.drawLine;
        });
        this.greenSpacesLayer.eachLayer(layer => {
            if (!layer.feature) this.greenSpacesLayer.removeLayer(layer)
        });
    }

    showDrawLines = (isSplitting) => {
        this.greenSpacesLayer.eachLayer(layer => {
            const baseLine = layer.feature.properties.baseLine;
            if (baseLine) {
                let drawLine;

                if (isSplitting) {
                    const baseLineCoords = JSON.parse(JSON.stringify(baseLine.coordinates));
                    const unallowedPaths = [];
                    const pointsToInsert = baseLineCoords.map(() => []);

                    for (let i = 0; i < baseLineCoords.length - 2; i++) { // Parcourt chaque angle de la baseLine
                        if (baseLineCoords[i + 2]) {
                            const angleDelimitations = GeometriesUtil.getAngleDelimitations(baseLineCoords.slice(i, i + 3), baseLine.width);
                            // On insère les nouveaux points & on retient la zone qui n'est pas utilisable sur la baseLine
                            pointsToInsert[i].push(angleDelimitations[0]); pointsToInsert[i + 1].push(angleDelimitations[1]);
                            unallowedPaths.push(lineString([angleDelimitations[0], baseLineCoords[i + 1], angleDelimitations[1]]));
                        }
                    }

                    // On trie les nouveaux points dans le bon ordre & on les insère
                    pointsToInsert.forEach((pointsToInsert, index) => (
                        pointsToInsert.sort((a, b) => (
                            distance(a, baseLineCoords[index]) - distance(b, baseLineCoords[index])
                        ))
                    ));
                    for (let i = pointsToInsert.length - 1; i >= 0; i--)
                        baseLineCoords.splice(i + 1, 0, ...pointsToInsert[i]);

                    drawLine = L.multiOptionsPolyline(GeometriesUtil.convertLineCoordinatesToLatLngs(baseLineCoords), {
                        multiOptions: {
                            optionIdxFn: (latLng) => {
                                const coord = [latLng.lng, latLng.lat];
                                const pointCircle = circle(point([latLng.lng, latLng.lat]), 0.0001);
                                return unallowedPaths.some(path => (
                                    booleanIntersects(path, pointCircle) && JSON.stringify(coord) !== JSON.stringify(path.geometry.coordinates.at(-1))
                                )) ? 1 : 0
                            },
                            options: [{ color: '#3388FF' }, { color: '#FF0000' }]
                        },
                        pmIgnore: true
                    });
                    drawLine.unallowedPaths = unallowedPaths;
                } else drawLine = L.polyline(GeometriesUtil.convertLineCoordinatesToLatLngs(baseLine.coordinates));
                drawLine.linkedPolygon = layer;

                const handleVertexChange = (newPoint, index) => {
                    this.pushActionHistory([{ layer }]);
                    const baseLineCoords = layer.feature.properties.baseLine.coordinates;
                    const newBaseLineCoords = JSON.parse(JSON.stringify(baseLineCoords));
                    if (newPoint) {
                        newBaseLineCoords.splice(index, 1, newPoint);
                        if (JSON.stringify(newBaseLineCoords[index - 1]) === JSON.stringify(newBaseLineCoords[index])) {
                            const angle = bearing(newBaseLineCoords[index - 1], newBaseLineCoords[index + 1]);
                            newBaseLineCoords[index] = transformTranslate(point(newBaseLineCoords[index - 1]), 1, angle, { units: 'meters' })?.geometry.coordinates;
                        } else if (JSON.stringify(newBaseLineCoords[index + 1]) === JSON.stringify(newBaseLineCoords[index])) {
                            const angle = bearing(newBaseLineCoords[index + 1], newBaseLineCoords[index - 1]);
                            newBaseLineCoords[index] = transformTranslate(point(newBaseLineCoords[index + 1]), 1, angle, { units: 'meters' })?.geometry.coordinates;
                        }
                    } else newBaseLineCoords.splice(index, 1);

                    const lineCoordinates = GeometriesUtil.convertLineLatLngsToCoordinates(drawLine.getLatLngs());
                    layer.feature.properties.baseLine.coordinates = lineCoordinates;
                    const polygonCoordinates = GeometriesUtil.convertBufferedLineToCoordinates(lineCoordinates, layer.feature.properties.baseLine.width);
                    const newLatLngs = GeometriesUtil.convertPolygonCoordinatesToLatLngs(polygonCoordinates);
                    layer.setLatLngs(newLatLngs);
                    if (!this.checkIfInsideSurroundings('polygon', layer) || GeometriesUtil.checkIfPolygonHasSelfIntersection(layer)) {
                        if (!this.checkIfInsideSurroundings('polygon', layer)) showToast('element_drag_not_allowed');
                        else showToast('self_intersection_detected');
                        drawLine.setStyle({ color: '#3388FF' });
                        this.cancelLastAction();
                    }
                };

                if (!isSplitting) {
                    drawLine.on('pm:vertexadded', () => {
                        this.pushActionHistory([{ layer }]);
                        const lineCoordinates = GeometriesUtil.convertLineLatLngsToCoordinates(drawLine.getLatLngs());
                        layer.feature.properties.baseLine.coordinates = lineCoordinates;
                        this.vertexAddedOnLayer = layer;

                        setTimeout(() => {
                            if (!this.hasDragStarted) this.cancelLastAction();
                            else this.hasDragStarted = false;
                        }, 100);
                    });

                    drawLine.on('pm:vertexremoved', (e) => {
                        const baseLineCoords = layer.feature.properties.baseLine.coordinates;
                        const index = e.indexPath[0];
                        const newPoint = baseLineCoords[index - 1] && baseLineCoords[index + 1] && nearestPointOnLine(
                            lineString([baseLineCoords[index - 1], baseLineCoords[index + 1]]),
                            [e.marker.getLatLng().lng, e.marker.getLatLng().lat]
                        )?.geometry.coordinates;
                        handleVertexChange(newPoint, index);
                    });

                    drawLine.on('pm:markerdragstart', (e) => {
                        if (this.props.layer !== drawLine || this.state.modalContentType !== 'LineForm') {
                            this.setState({ modalContentType: '' }, () => {
                                this.setState({
                                    modal: { visible: false, title: '' },
                                    modalContentType: 'LineForm'
                                }, () => this.props.setLayer(drawLine));
                            });
                        }
                        this.hasDragStarted = true;
                        this.dragStartLatLng = e.markerEvent.target._latlng;
                        if (!this.vertexAddedOnLayer || this.vertexAddedOnLayer !== layer) this.pushActionHistory([{ layer }]);
                        this.vertexAddedOnLayer = null;
                    });

                    drawLine.on('pm:markerdrag', () => {
                        if (!this.checkIfInsideSurroundings('line', drawLine) || drawLine.pm.hasSelfIntersection())
                            drawLine.setStyle({ color: '#FF0000' });
                        else drawLine.setStyle({ color: '#3388FF' });
                    });

                    drawLine.on('pm:markerdragend', (e) => {
                        const newPoint = [e.markerEvent.target._latlng.lng, e.markerEvent.target._latlng.lat];
                        const index = e.indexPath[0];
                        handleVertexChange(newPoint, index);
                    });
                }

                drawLine.on('click', (e) => {
                    if (this.props.currentAction === 'splitting') layer.fireEvent('click', { ...e });
                    else {
                        this.clickAfterEdit = true;
                        if (this.editingLayer !== drawLine) {
                            if (this.editingLayer) this.editingLayer.pm.disable();
                            this.editingLayer = drawLine;
                            drawLine.pm.enable();
                        }
                        if (this.props.layer !== drawLine || this.state.modalContentType !== 'LineForm') {
                            this.setState({ modalContentType: '' }, () => {
                                this.setState({
                                    modal: { visible: false, title: '' },
                                    modalContentType: 'LineForm'
                                }, () => this.props.setLayer(drawLine));
                            });
                        }
                    }
                });

                layer.drawLine = drawLine;
                this.greenSpacesLayer.addLayer(drawLine);
            }
        });
    }

    addWithGps = (category) => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                const latLng = { lat: position.coords.latitude, lng: position.coords.longitude };
                const style = category === 'Tree' ? StylesUtil.getTreeStyle() : category === 'Furniture' ? StylesUtil.getFurnitureStyle() : StylesUtil.getMarkerStyle();
                const layer = L.circleMarker([latLng.lat, latLng.lng], style);
                if (this.checkIfInsideSurroundings('marker', layer)) {
                    this.map.setView(latLng, 18, { animate: true });
                    layer.addTo(this.map);
                    this.props.setLayer(layer); // On récupère le layer créé
                    this.changeModalContentType(`${category}Form`, i18n.t("Ajout d'un nouvel élément"));
                } else showToast('element_addition_not_allowed');
            }, () => showToast('gps_location_not_activated'));
        } else showToast('gps_location_not_found');
    }

    finishAddWithAI = (trees) => {
        trees.forEach(tree => {
            const latLng = { lat: tree.geometry.coordinates[1], lng: tree.geometry.coordinates[0] };
            this.addMarker('Arbre', new L.circleMarker(latLng), tree, { updateLegend: false, updateHeatmaps: false });
        });
        this.updateLegend(i18n.t("Arbres"));
        this.updateHeatmaps();
        this.hideForm(true);
        this.setState({ scan: {} });
    }
    cancelAddWithAI = () => {
        this.hideForm(false);
        this.setState({ scan: {} });
    };

    addTreesWithAI = async (stationId) => {
        const { project } = this.props;
        const { scan } = this.state;

        if (stationId) { // Si on a sélectionné une station, on s'assure que ses bounds soient la zone de scan
            const layer = this.stationsLayer.getLayers().find(station => station.feature.id === stationId);
            scan.bounds = layer.getBounds();
            scan.latLngs = layer.getLatLngs();
        }

        const scanZone = GeometriesUtil.convertPolygonLatLngsToCoordinates(scan.latLngs);
        if (area(polygon(scanZone)) > 2500000) {
            showToast('scan_zone_too_large');
            return;
        }
        this.setState({ modalContentType: '' });
        if (this.props.layer) this.map.removeLayer(this.props.layer);


        // On calcule les bounds/indexes des tiles à charger pour couvrir les bounds de la zone à scanner
        const pixelBounds = new L.Bounds(this.map.project(scan.bounds.getNorthWest(), 19), this.map.project(scan.bounds.getSouthEast(), 19));
        const tileBounds = L.bounds(pixelBounds.min.divideBy(256)._floor(), pixelBounds.max.divideBy(256)._floor());
        const pixelNW = this.map.unproject(new L.Point(tileBounds.min.x * 256, tileBounds.min.y * 256), 19);
        const pixelSE = this.map.unproject(new L.Point(tileBounds.max.x * 256, tileBounds.max.y * 256), 19);
        const bounds = {
            scanZone,
            tiles: [[pixelNW.lng, pixelSE.lat], [pixelSE.lng, pixelNW.lat]],
            tileIndexes: [[tileBounds.min.x, tileBounds.min.y], [tileBounds.max.x, tileBounds.max.y]],
        };

        const width = distance([bounds.tiles[0][0], bounds.tiles[0][1]], [bounds.tiles[1][0], bounds.tiles[0][1]], { units: 'centimeters' });
        const height = distance([bounds.tiles[0][0], bounds.tiles[0][1]], [bounds.tiles[0][0], bounds.tiles[1][1]], { units: 'centimeters' });

        if (isMobileOnly) this.showLoader(true, 'map', i18n.t("Notre intelligence artificielle analyse la zone..."));
        AIService.getPredictions({ bounds, height, width, projectId: project.id, wmsService: scan.wmsService, wmsLayers: scan.wmsLayers, customAPI: scan.customAPI }).then(predictions => {
            this.showLoader(false);
            if (predictions?.length > 0) {
                this.setState(prevState => ({
                    scan: {
                        ...prevState.scan,
                        previousTrees: this.treesLayerNotClustered.getLayers()
                            .filter(layer => GeometriesUtil.checkIfIsInsidePolygon(layer.getLatLng(), scan.latLngs[0]))
                            .map(({ feature }) => (
                                { ...GeoJsonUtil.generateMarkerFeature({ category: 'Arbre' }, { lat: feature.geometry.coordinates[1], lng: feature.geometry.coordinates[0] }, this.props.project.id), id: uuidv4() }
                            )),
                        trees: predictions
                            .map(({ coordinates, crownDiameter, probability, boundingBox }) => {
                                const treeId = uuidv4();
                                return {
                                    ...GeoJsonUtil.generateMarkerFeature({
                                        category: 'Arbre', interactionId: [], microHabitatId: [], rootSymptomId: [], rootPathogenId: [], rootPestId: [],
                                        collarSymptomId: [], collarPathogenId: [], collarPestId: [], trunkSymptomId: [], trunkPathogenId: [], trunkPestId: [], trunkEpiphyteId: [],
                                        branchSymptomId: [], branchPathogenId: [], branchPestId: [], branchEpiphyteId: [], leafSymptomId: [], leafPathogenId: [], leafPestId: [],
                                        tagId: [], dimensions: {}, numberOfTrunks: 1, trunks: [{ id: uuidv4(), propertiesId: treeId, projectId: this.props.project.id, height: null, circumference: null, crownDiameter, order: 0 }],
                                        observation: `${i18n.t("IA")} :\n- ${i18n.t("Probabilité")} : ${Math.round(probability * 10000) / 100}%\n- ${i18n.t("Coordonnées prédiction")} : ${JSON.stringify(boundingBox)}`
                                    }, { lat: coordinates[1], lng: coordinates[0] }, this.props.project.id),
                                    id: treeId,
                                    probability: probability * 100
                                };
                            })
                    }
                }), () => this.changeModalContentType('AIResultMap', i18n.t("Traitement des résultats")));
            } else {
                if (Array.isArray(predictions)) showToast('ai_no_result');
                this.setState({ scan: {} });
                this.toolbarRef.current.resetButtonSelection(1);
            }
        });
    }

    updateTreesLayerStyle = (highlightedElements = null) => {
        [this.treesLayer, this.treesLayerNotClustered].forEach(layerContainer => {
            layerContainer.eachLayer(layer => {
                if (!highlightedElements || !highlightedElements.includes(layer))
                    layer.setStyle(StylesUtil.getTreeActiveStyle(this.treesLayer, this.fieldList, layer.feature.properties, this.props.project.thematicMaps));
            });
        });
    }

    assignMarkerEvents = (category, linkedMarkers) => {
        const categories = {
            'Arbre': {
                toolButton: 'treeTools', getHighlightStyle: (layer) => StylesUtil.getTreeHighlightStyle(this.treesLayer, this.fieldList, layer.feature.properties),
                getStyle: (layer) => StylesUtil.getTreeActiveStyle(this.treesLayer, this.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            },
            'Mobilier': {
                toolButton: 'furnitureTools', getHighlightStyle: () => StylesUtil.getFurnitureHighlightStyle(this.fieldList),
                getStyle: (layer) => StylesUtil.getFurnitureActiveStyle(this.furnituresLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            },
            'Repère': {
                toolButton: 'markerTools', getHighlightStyle: () => StylesUtil.getMarkerHighlightStyle(this.fieldList),
                getStyle: (layer) => StylesUtil.getMarkerActiveStyle(this.fieldList)
            }
        };

        const { toolButton, getHighlightStyle, getStyle } = categories[category];
        linkedMarkers.forEach(linkedMarker => {
            linkedMarker.on('mouseover', () => {
                this.overedElement = linkedMarker;
                if (!isMobile && this.props.currentAction === '') {
                    if (this.elementPopupTimeout) clearTimeout(this.elementPopupTimeout);
                    this.elementPopupTimeout = setTimeout(() => {
                        let popup = L.popup({ keepInView: true, closeButton: false }).setLatLng(linkedMarker.getLatLng());

                        if (linkedMarker.feature.properties.category === 'Arbre') {
                            const essence = linkedMarker.feature.properties?.essenceId && this.props.essences.find(x => x.id === linkedMarker.feature.properties.essenceId);
                            const { trunkCircumferenceUnit } = this.props.project;
                            const circumference = FormattersUtil.getTrunkCircumference(Math.max(0, ...(linkedMarker.feature.properties.trunks || []).map(t => t.circumference)), trunkCircumferenceUnit);
                            popup = popup.setContent(renderToString(<>
                                <p>{i18n.t('Genre')} : <b style={{ color: 'var(--primary-100)' }}>{essence?.gender || i18n.t('Non spécifié')}</b></p>
                                <p>{trunkCircumferenceUnit === 'circumference' ? i18n.t('Circonférence de l\'axe') : i18n.t('Diamètre du tronc')} : <b style={{ color: 'var(--primary-100)' }}>{circumference ? `${circumference}cm` : i18n.t('Non spécifiée')}</b></p>
                            </>));
                        } else if (linkedMarker.feature.properties.category === 'Mobilier') {
                            const furnitureType = linkedMarker.feature.properties?.typeId && this.props.furnitureTypes.find(x => x.id === linkedMarker.feature.properties?.typeId);
                            popup = popup.setContent(renderToString(<>
                                <p>{i18n.t('Type')} : <b style={{ color: 'var(--primary-100)' }}>{furnitureType?.label || i18n.t('Non spécifié')}</b></p>
                            </>));
                        } else {
                            popup = popup.setContent(renderToString(<>
                                <p><b>{linkedMarker.feature.properties.title}</b></p>
                            </>));
                        }

                        linkedMarker.bindPopup(popup);
                        linkedMarker.openPopup();
                    }, 500);
                }

                if (!this.props.currentAction || this.props.currentAction === 'dragging') linkedMarker.setStyle(getHighlightStyle(linkedMarker))
            });
            linkedMarker.on('mouseout', () => {
                this.overedElement = null;
                if (!isMobile) {
                    if (this.elementPopupTimeout) clearTimeout(this.elementPopupTimeout);
                    linkedMarker.closePopup();
                    linkedMarker.unbindPopup();
                }

                const { selectedElements, highlightedElements } = this.state;
                if ((!selectedElements?.length || !selectedElements.find(se => se.feature.id === linkedMarker.feature.id))
                    && (!highlightedElements?.length || !highlightedElements.find(se => se.feature.id === linkedMarker.feature.id)))
                    linkedMarker.setStyle(getStyle(linkedMarker));
            });
            linkedMarker.on('click', () => {
                if (this.shiftKey && !['managingActionElements', 'managingEventElements', 'linkingElements'].includes(this.props.currentAction)) {
                    let selectedElements = this.state.selectedElements;
                    const element = selectedElements.find(x => x._leaflet_id === linkedMarker._leaflet_id);
                    if (!element) this.selectElements([linkedMarker], !this.shiftKey);
                    else this.unselectElements([element]);
                    this.checkHistoryButtons();
                } else if (!this.shiftKey && this.props.currentAction === 'elementsSelection') {
                    let selectedElements = this.state.selectedElements;
                    const element = selectedElements.find(x => x._leaflet_id === linkedMarker._leaflet_id);
                    if (selectedElements.length === 1 && element) this.unselectElements([element]);
                    else this.selectElements([linkedMarker], true);
                } else if (this.props.currentAction === 'copyPasting') {
                    if (this.state.currentTools === toolButton) {
                        this.resetSelectionAsCopy();
                        this.selectElements([linkedMarker], !this.shiftKey);
                        this.shouldCopy = false;
                    }
                } else if (this.props.currentAction === 'duplicating') {
                    if (this.state.currentTools === toolButton) {
                        this.shouldCopy = false;
                        this.props.setLayer(linkedMarker);
                    }
                } else if ((this.props.currentAction === 'managingActionElements' && this.actionLinkingCategories.includes(category) && this.state.subscription.actions)
                    || (this.props.currentAction === 'managingEventElements' && this.eventLinkingCategories.includes(category) && this.state.subscription.events)
                    || (this.props.currentAction === 'linkingElements' && linkedMarker.feature.id !== this.props.layer[0].feature.id)) {
                    this.handleLinkingClick(linkedMarker, getStyle(linkedMarker), getHighlightStyle(linkedMarker));
                } else if (this.props.currentAction === 'removing') {
                    if (this.state.currentTools === toolButton) {
                        this.toggleElementsHighlight([linkedMarker]);
                        this.pushActionHistory([{ layer: linkedMarker }]);
                    }
                } else if (['', 'viewingModal'].includes(this.props.currentAction))
                    this.showModal(linkedMarkers);
            });

            linkedMarker.on('pm:dragstart', () => {
                this.clickBeforeDragend = true;
                const selectedElements = this.state.selectedElements.filter(layer => layer.feature.properties.category === category);
                const referencesLayers = this.referencesLayer.getLayers();
                if (selectedElements.find(element => element.feature?.id === linkedMarker.feature?.id)) {
                    this.draggedLayers = [];
                    selectedElements.forEach(layer => {
                        let layerWithLatLngs = { layer };
                        if (layer.getLatLng) layerWithLatLngs.latLng = layer.getLatLng();
                        else if (layer.getLatLngs) layerWithLatLngs = { ...layerWithLatLngs, latLngs: layer.getLatLngs(), center: layer.getCenter() };
                        this.draggedLayers.push(layerWithLatLngs);
                        const referenceLayer = referencesLayers.find(l => l.elementId === layer.feature.id && l.category === layer.feature.properties.category);
                        if (referenceLayer) this.draggedLayers.push({ layer: referenceLayer, latLng: referenceLayer.getLatLng() });
                    });

                    this.pushActionHistory(selectedElements.map(layer => ({ layer })));
                } else {
                    this.unselectElements();
                    this.draggedLayers = [{ layer: linkedMarker, latLng: linkedMarker.getLatLng() }];
                    const referenceLayer = referencesLayers.find(l => l.elementId === linkedMarker.feature.id && l.category === linkedMarker.feature.properties.category);
                    if (referenceLayer) this.draggedLayers.push({ layer: referenceLayer, latLng: referenceLayer.getLatLng() });
                    this.pushActionHistory([{ layer: linkedMarker }]);
                }
            });
            linkedMarker.on('pm:drag', (e) => {
                const selectedElements = this.state.selectedElements.filter(layer => layer.feature.properties.category === category);
                if (selectedElements.length > 1) this.handleGroupDrag(linkedMarker, e);
                else if (ProjectsUtil.isElementLocked(this.props.lockedElements, linkedMarker.feature)) {
                    linkedMarker.setLatLng({ lat: linkedMarker.feature.geometry.coordinates[1], lng: linkedMarker.feature.geometry.coordinates[0] });
                    const referencesType = linkedMarker.feature.properties.category === 'Arbre' ? 'trees'
                        : linkedMarker.feature.properties.category === 'Mobilier' ? 'furnitures'
                            : 'markers';
                    if (this.state.references[referencesType])
                        TooltipsUtil.setMarkerTooltip(linkedMarker.feature.properties[this.state.references[referencesType]], linkedMarker, this.referencesLayer, true);
                } else if (this.draggedLayers?.length === 2) {
                    const referenceLayer = this.draggedLayers.find(dl => dl.layer.elementId === linkedMarker.feature.id)?.layer;
                    if (referenceLayer) referenceLayer.setLatLng(linkedMarker.getLatLng());
                }
            });
            linkedMarker.on('pm:dragend', this.handleDragend);
        });
    }

    addMarker = (category, layer, feature, { fromLoad = false, showContainer = false, showModal = false, showReferences = true, isCopying = false, updateLegend = true, updateHeatmaps = true, events = true, style = null } = {}) => { // Fonction permettant d'ajouter un arbre à la map et d'appeler le service qui l'ajoutera en DB
        const categories = {
            'Arbre': {
                layerContainer: this.treesLayer, layerContainerNotClustered: this.treesLayerNotClustered, legendName: i18n.t("Arbres"), globalLinkedMarkers: this.linkedTrees,
                type: 'trees',
                getStyle: (feature) => StylesUtil.getTreeActiveStyle(layerContainer, this.fieldList, feature.properties, this.props.project.thematicMaps),
                addFunction: TreesService.addTree
            },
            'Mobilier': {
                layerContainer: this.furnituresLayer, layerContainerNotClustered: this.furnituresLayerNotClustered, legendName: i18n.t("Mobilier urbain"), globalLinkedMarkers: this.linkedFurnitures,
                type: 'furnitures',
                getStyle: (feature) => StylesUtil.getFurnitureActiveStyle(layerContainer.activeChild, this.fieldList, feature.properties, this.props.project.thematicMaps),
                addFunction: FurnituresService.addFurniture
            },
            'Repère': {
                layerContainer: this.markersLayer, legendName: i18n.t("Repères"), type: 'markers',
                getStyle: () => StylesUtil.getMarkerActiveStyle(this.fieldList),
                addFunction: MarkersService.addMarker
            }
        };

        const { layerContainer, layerContainerNotClustered, legendName, globalLinkedMarkers, type, getStyle, addFunction } = categories[category];

        if (isCopying) {
            feature = GeoJsonUtil.generateMarkerFeature(feature.properties, layer._latlng, this.props.project.id);
            addFunction(feature, 'copyPasting', this.props.webSocketHubs);
        }

        if (category === 'Arbre' && feature.properties.plantingDate) feature.properties.plantingDate = DatesUtil.convertUTCDateToDate(feature.properties.plantingDate);

        // Ajout au treesLayer
        const newMarker = L.circleMarker(layer._latlng, { ...(style || getStyle(feature)), pmIgnore: category !== 'Repère', snapIgnore: true });

        newMarker.feature = feature;
        layerContainer.addLayer(newMarker);

        let newMarkerWithoutPmIgnore;
        if (layerContainerNotClustered) {
            newMarkerWithoutPmIgnore = L.circleMarker(layer._latlng, { ...(style || getStyle(feature)), snapIgnore: true });
            newMarkerWithoutPmIgnore.feature = feature;
            layerContainerNotClustered.addLayer(newMarkerWithoutPmIgnore);
        }

        if (category === 'Arbre') {
            // Ajout au coolingLayer
            if (!feature.properties.isEmpty) {
                const hBaseRadius = (feature.properties.coolingIndicator / 200000).toFixed(10);
                const hValueIndex = fromLoad ? -1 : this.coolingLayerValues.findIndex(value => value.id === feature.id);
                const hValue = {
                    id: feature.id,
                    lat: feature.geometry.coordinates[1],
                    lng: feature.geometry.coordinates[0],
                    value: 1, baseRadius: hBaseRadius,
                    radius: hBaseRadius * Math.pow(2, this.map.getZoom())
                };
                if (hValueIndex === -1) this.coolingLayerValues.push(hValue);
                else this.coolingLayerValues.splice(hValueIndex, 1, hValue);
            }

            // Ajout au carbonStockLayer
            const csBaseRadius = (feature.properties.carbonStock / 100000).toFixed(10);
            if (!feature.properties.isEmpty) {
                const csValueIndex = fromLoad ? -1 : this.carbonStockLayerValues.findIndex(value => value.id === feature.id);
                const csValue = {
                    id: feature.id,
                    lat: feature.geometry.coordinates[1],
                    lng: feature.geometry.coordinates[0],
                    value: 1, baseRadius: csBaseRadius,
                    radius: csBaseRadius * Math.pow(2, this.map.getZoom())
                };
                if (csValueIndex === -1) this.carbonStockLayerValues.push(csValue);
                else this.carbonStockLayerValues.splice(csValueIndex, 1, csValue);
            }
        }

        // Assignation des événements
        const linkedMarkers = [newMarker, newMarkerWithoutPmIgnore].filter(m => m);
        if (globalLinkedMarkers) {
            const index = fromLoad ? -1 : globalLinkedMarkers.findIndex(layer => layer[0]?.feature.id === newMarker.feature.id);
            if (index === -1) globalLinkedMarkers.push(linkedMarkers);
            else globalLinkedMarkers[index] = linkedMarkers;
        }
        if (events) this.assignMarkerEvents(category, linkedMarkers);

        this.map.removeLayer(layer); // On supprime le marker créé par geoman-leaflet
        if (showContainer) {
            let flag = true;
            const layerContainers = [layerContainer, layerContainerNotClustered].filter(lc => lc);
            layerContainers.forEach(layer => { // Si aucun layer arbre n'est affiché
                if (this.map.hasLayer(layer)) flag = false;
            });

            if (flag) this.toggleLayer({ layer: layerContainer }); // On affiche le layer principal
        }

        if (updateLegend) this.updateLegend(legendName);
        if (category === 'Arbre' && updateHeatmaps) this.updateHeatmaps();

        if (showReferences && this.state.references[type]) {
            const zoomLevel = this.map.getZoom();
            if (zoomLevel >= 17 && this.map.getBounds().contains(layer._latlng)) {
                linkedMarkers.forEach(linkedMarker => {
                    TooltipsUtil.setMarkerTooltip(linkedMarker.feature.properties[this.state.references[type]], linkedMarker, this.referencesLayer);
                });
            }
        }

        if (!['drawingProjectSurroundings', 'modifyingProjectSurroundings'].includes(this.props.currentAction)) this.props.setEditedProperties(null);
        if (showModal) this.showModal(linkedMarkers, true);

        return newMarker;
    }

    preloadElements = (projectId) => { // Fonction qui permet de charger les arbres simplifiés dans les layers
        const renderer = L.canvas({ pmIgnore: true, snapIgnore: true });
        if (!this.treesPreloadLayer && this.publicFields.main.trees)
            this.treesPreloadLayer = L.markerClusterGroup({
                disableClusteringAtZoom: 17, spiderfyOnMaxZoom: false, removeOutsideVisibleBounds: true,
                polygonOptions: { fillColor: 'var(--secondary-100)', color: 'var(--primary-100)' },
                iconCreateFunction: (cluster) => {
                    const childCount = cluster.getChildCount();
                    const backgroundColor = tinycolor(document.body.style.getPropertyValue('--green-100'));
                    backgroundColor.setAlpha(0.6);
                    const fontColor = backgroundColor.getBrightness() < 100 ? 'white' : 'black';
                    return new L.DivIcon({ html: `<div style="background-color: ${backgroundColor.toString()};"><span style='color: ${fontColor} !important;'>` + childCount + '</span></div>', className: 'marker-cluster', iconSize: new L.Point(40, 40) });
                }
            });

        if (!this.furnituresPreloadLayer && this.publicFields.main.furnitures)
            this.furnituresPreloadLayer = L.markerClusterGroup({
                disableClusteringAtZoom: 17, spiderfyOnMaxZoom: false, removeOutsideVisibleBounds: true,
                polygonOptions: { fillColor: 'var(--blue-70)', color: 'var(--blue-100)' },
                iconCreateFunction: (cluster) => {
                    const childCount = cluster.getChildCount();
                    const backgroundColor = tinycolor(document.body.style.getPropertyValue('--blue-100'));
                    backgroundColor.setAlpha(0.6);
                    const fontColor = backgroundColor.getBrightness() < 100 ? 'white' : 'black';
                    return new L.DivIcon({ html: `<div style="background-color: ${backgroundColor.toString()};"><span style='color: ${fontColor} !important;'>` + childCount + '</span></div>', className: 'marker-cluster', iconSize: new L.Point(40, 40) });
                }
            });

        if (!this.greenSpacesPreloadLayer && this.publicFields.main.greenSpaces)
            this.greenSpacesPreloadLayer = L.layerGroup();

        if (this.treesPreloadLayer || this.furnituresPreloadLayer || this.greenSpacesPreloadLayer)
            this.preloadPromise.push(
                new Promise(resolve => ProjectsService.preloadElements(projectId).then(preloadedElements => {
                    const promises = [];
                    if (this.treesPreloadLayer && preloadedElements?.trees) {
                        preloadedElements.trees = JSON.parse(preloadedElements.trees);
                        for (let i = 0; i < preloadedElements.trees.length; i += 500) {
                            const batch = preloadedElements.trees.slice(i, i + 500);
                            promises.push(new Promise((resolve) => {
                                setTimeout(() => {
                                    batch.forEach(preloadedElement => {
                                        L.circleMarker({ lat: preloadedElement.coordinates[1], lng: preloadedElement.coordinates[0] }, { ...StylesUtil.getTreeStyle(), renderer, fillColor: '#7d7d7d' }).addTo(this.treesPreloadLayer);
                                    });
                                    resolve();
                                });
                            }));
                        }
                    }

                    if (this.furnituresPreloadLayer && preloadedElements?.furnitures) {
                        preloadedElements.furnitures = JSON.parse(preloadedElements.furnitures);
                        for (let i = 0; i < preloadedElements.furnitures.length; i += 500) {
                            const batch = preloadedElements.furnitures.slice(i, i + 500);
                            promises.push(new Promise((resolve) => {
                                setTimeout(() => {
                                    batch.forEach(preloadedElement => {
                                        L.circleMarker({ lat: preloadedElement.coordinates[1], lng: preloadedElement.coordinates[0] }, { ...StylesUtil.getFurnitureStyle(), renderer, fillColor: '#7d7d7d' }).addTo(this.furnituresPreloadLayer);
                                    });
                                    resolve();
                                });
                            }));
                        }
                    }

                    if (this.greenSpacesPreloadLayer && preloadedElements?.greenSpaces) {
                        preloadedElements.greenSpaces = JSON.parse(preloadedElements.greenSpaces);
                        for (let i = 0; i < preloadedElements.greenSpaces.length; i += 500) {
                            const batch = preloadedElements.greenSpaces.slice(i, i + 500);
                            promises.push(new Promise((resolve) => {
                                setTimeout(() => {
                                    batch.forEach(preloadedElement => {
                                        new L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(preloadedElement.coordinates), { ...StylesUtil.getGreenSpaceStyle(), renderer, fillColor: '#7d7d7d' }).addTo(this.greenSpacesPreloadLayer);
                                    });
                                    resolve();
                                });
                            }));
                        }
                    }

                    Promise.all(promises).then(() => resolve());
                }))
            );
    }

    loadTrees = (projectId, loadedCustomFields, cacheResult) => { // Fonction qui permet de charger les arbres dans les layers
        if (this.publicFields.main.trees) {
            const caches = cacheResult?.caches?.filter(cache => cache.type === 'trees');
            const fetchIds = cacheResult?.fetchIds?.trees || [];
            const cachePromises = [], treeIds = new Set();
            if (caches?.length > 0) {
                const processResult = (treesData, resolve, reject, waitPromises = []) => {
                    if (Array.isArray(treesData?.missingCustomFieldIds))
                        this.missingCustomFieldIds.push(...treesData?.missingCustomFieldIds.filter(mcf => !this.missingCustomFieldIds.find(x => x.id === mcf.id)));

                    Promise.all(waitPromises).then(() => {
                        if (treesData?.trees && Array.isArray(treesData.trees)) {
                            if (treesData.trees.length > 0) {
                                const promises = [];
                                for (let i = 0; i < treesData.trees.length; i += 500) {
                                    const batch = treesData.trees.slice(i, i + 500);
                                    promises.push(new Promise((resolve) => {
                                        setTimeout(() => {
                                            batch.forEach(tree => {
                                                if (!treeIds.has(tree.fetchId)) {
                                                    treeIds.add(tree.fetchId);
                                                    if (!tree.deletionDate)
                                                        this.addMarker('Arbre', { _latlng: { lat: tree.geometry.coordinates[1], lng: tree.geometry.coordinates[0] } }, tree, { fromLoad: true, updateLegend: false, updateHeatmaps: false, showReferences: false });
                                                }
                                            });
                                            resolve();
                                        })
                                    }))
                                }
                                Promise.all(promises).then(() => resolve());
                            } else resolve();
                        } else reject();
                    });
                };

                cachePromises[0] = cacheResult.updatedElementIds.trees.length
                    ? new Promise((resolve, reject) => (
                        TreesService.getTrees(projectId, { elementIds: cacheResult.updatedElementIds.trees, loadedCustomFields }).then(treesData => { // On appelle le bon service pour récupérer les données
                            processResult(treesData, resolve, reject);
                        })
                    ))
                    : Promise.resolve();

                cachePromises[1] = Promise.all(
                    caches.filter(cache => !cache.isPrimary).map(cache => new Promise((resolve, reject) => (
                        TreesService.getTrees(projectId, { cacheKey: `${projectId}-${cache.isPrimary ? 'main' : 'sub'}-trees-${cache.index}`, loadedCustomFields, page: cache.index }).then(treesData => { // On appelle le bon service pour récupérer les données
                            processResult(treesData, resolve, reject, [cachePromises[0]]);
                        })
                    )))
                );

                cachePromises[2] = Promise.all(
                    caches.filter(cache => cache.isPrimary).map(cache => new Promise((resolve, reject) => (
                        TreesService.getTrees(projectId, { cacheKey: `${projectId}-${cache.isPrimary ? 'main' : 'sub'}-trees-${cache.index}`, loadedCustomFields, page: cache.index }).then(treesData => { // On appelle le bon service pour récupérer les données
                            processResult(treesData, resolve, reject, [cachePromises[0], cachePromises[1]]);
                        })
                    )))
                );
            }

            this.treesLoadingPromise.push(
                Promise.all(
                    caches?.length > 0
                        ? cachePromises
                        : [...Array(Math.ceil(fetchIds.length / 2500))].map((_, i) => new Promise((resolve) => (
                            TreesService.getTrees(projectId, { loadedCustomFields, page: i, cursor: i ? fetchIds[i * 2500 - 1] : -1, nbElements: fetchIds.length }).then(treesData => { // On appelle le bon service pour récupérer les données
                                if (treesData?.trees && Array.isArray(treesData.trees))
                                    treesData.trees.forEach(tree => {
                                        this.addMarker('Arbre', { _latlng: { lat: tree.geometry.coordinates[1], lng: tree.geometry.coordinates[0] } }, tree, { updateLegend: false, updateHeatmaps: false, showReferences: false });
                                    });

                                if (Array.isArray(treesData?.missingCustomFieldIds))
                                    this.missingCustomFieldIds.push(...treesData?.missingCustomFieldIds.filter(mcf => !this.missingCustomFieldIds.find(x => x.id === mcf.id)));

                                resolve();
                            })
                        )))
                )
            );
        }
    }

    updateGreenSpacesLayerStyle() {
        this.greenSpacesLayer.eachLayer(layer => {
            layer.setStyle(StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps));
        });
    }

    assignPolygonEvents = (category, layer) => {
        const categories = {
            'Espace vert': {
                toolButton: 'greenSpaceTools', getHighlightStyle: () => StylesUtil.getGreenSpaceHighlightStyle(),
                getStyle: (layer) => StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            },
            'Station': {
                toolButton: 'stationTools', getHighlightStyle: () => StylesUtil.getStationHighlightStyle(),
                getStyle: () => StylesUtil.getStationActiveStyle()
            }
        };

        const { toolButton, getHighlightStyle, getStyle } = categories[category];

        layer.on('click', e => {
            if (this.shiftKey && !['managingActionElements', 'managingEventElements', 'linkingElements'].includes(this.props.currentAction)) {
                let selectedElements = this.state.selectedElements;
                const element = selectedElements.find(x => x._leaflet_id === layer._leaflet_id);
                if (!element) this.selectElements([layer], !this.shiftKey);
                else this.unselectElements([element])
                this.checkHistoryButtons();
            }

            switch (this.props.currentAction) {
                case 'viewingModal': this.showModal([layer]); break;
                case 'copyPasting':
                    if (this.state.currentTools === 'greenSpaceTools') {
                        this.resetSelectionAsCopy();
                        this.selectElements([layer], !this.shiftKey);
                        this.shouldCopy = false;
                    }
                    break;
                case 'duplicating':
                    if (this.state.currentTools === 'greenSpaceTools') {
                        this.shouldCopy = false;
                        this.props.setLayer(layer);
                    }
                    break;
                case 'merging': if (category === 'Espace vert') this.handleMergingClick(layer); break;
                case 'subtracting': if (category === 'Espace vert') this.handleSubtractingClick(layer); break;
                case 'splitting':
                    if (category === 'Espace vert') {
                        if (layer.drawLine) {
                            const clickedLatLng = this.map.mouseEventToLatLng(e.originalEvent);
                            const clickedCoord = [clickedLatLng.lng, clickedLatLng.lat];
                            const layerGeometry = polygon(layer.feature.geometry.coordinates);
                            const baseLine = layer.feature.properties.baseLine;
                            const projOnDrawLine = nearestPointOnLine(lineString(baseLine.coordinates), clickedCoord, { units: 'meters' }); // Point le plus proche du clic sur la drawLine

                            const translateAngle = bearing(projOnDrawLine.geometry.coordinates, clickedCoord);
                            let firstPoint, secondPoint, firstTranslateDistance = 0, secondTranslateDistance = 0;
                            do {
                                firstTranslateDistance += (baseLine.width > 100 ? 1 : 0.1);
                                firstPoint = transformTranslate(projOnDrawLine, firstTranslateDistance, translateAngle, { units: 'meters' });
                            } while (booleanPointInPolygon(firstPoint.geometry.coordinates, layerGeometry));
                            do {
                                secondTranslateDistance += (baseLine.width > 100 ? 1 : 0.1);
                                secondPoint = transformTranslate(projOnDrawLine, secondTranslateDistance, translateAngle + 180, { units: 'meters' });
                            } while (booleanPointInPolygon(secondPoint.geometry.coordinates, layerGeometry));
                            const translateDistance = Math.max(firstTranslateDistance, secondTranslateDistance);
                            firstPoint = transformTranslate(projOnDrawLine, translateDistance, translateAngle, { units: 'meters' });
                            secondPoint = transformTranslate(projOnDrawLine, translateDistance, translateAngle + 180, { units: 'meters' });
                            const splitLine = lineString([firstPoint.geometry.coordinates, secondPoint.geometry.coordinates]);

                            // Le code ci-dessous est parfois bugué à cause de la précision de nearestPointOnLine (pas toujours parfaitement perpendiculaire), à réutiliser si fix
                            // let splitLine = lineString([ // Ligne perpendiculaire à la drawLine (point cliqué jusqu'à sa symétrie centrale par rapport au projOnDrawLine
                            //     [clickedLatLng.lng, clickedLatLng.lat],
                            //     [
                            //         projOnDrawLine.geometry.coordinates[0] + (projOnDrawLine.geometry.coordinates[0] - clickedLatLng.lng),
                            //         projOnDrawLine.geometry.coordinates[1] + (projOnDrawLine.geometry.coordinates[1] - clickedLatLng.lat),
                            //     ]
                            // ]);
                            // let scale = (baseLine.width * 1.001) / projOnDrawLine.properties.dist;
                            // splitLine = transformScale(splitLine, scale); // On scale la ligne perpendiculaire pour qu'elle dépasse de 5% de chaque côté de l'EV
                            let shouldSplit = translateDistance < baseLine.width * 1.5;
                            layer.drawLine.unallowedPaths.forEach(unallowedPath => {
                                if (booleanIntersects(splitLine, unallowedPath.geometry))
                                    shouldSplit = false;
                            });
                            if (shouldSplit) {
                                this.map.pm.disableDraw('Line');
                                this.splitGreenSpaces(splitLine, [layer], false);
                                setTimeout(() => this.map.pm.enableDraw('Line'), 100);
                            } else showToast('greenspace_split_area_not_allowed');
                        }
                    }
                    break;
                case 'editing':
                    this.clickAfterEdit = true;
                    if (this.state.currentElementTools === (category === 'Espace vert' ? 'greenSpaceTools' : 'stationTools')) {
                        if (layer.drawLine) { // Si on clique en mode edit & que l'EV est linéaire
                            if (this.editingLayer !== layer.drawLine) {
                                if (this.editingLayer) this.editingLayer.pm.disable();
                                this.editingLayer = layer.drawLine;
                                layer.drawLine.pm.enable();
                            }
                            if (this.props.layer !== layer.drawLine || this.state.modalContentType !== 'LineForm') {
                                this.setState({ modalContentType: '' }, () => {
                                    this.setState({
                                        modal: { visible: false, title: '' },
                                        modalContentType: 'LineForm'
                                    }, () => this.props.setLayer(layer.drawLine));
                                });
                            }
                        } else if (this.props.layer !== layer || (category === 'Espace vert' && this.state.modalContentType !== 'OffsetForm')) {
                            if (this.editingLayer !== layer) {
                                if (this.editingLayer) this.editingLayer.pm.disable();
                                this.editingLayer = layer;
                                layer.pm.enable();
                            }
                            if (category === 'Espace vert') {
                                this.offsetLayer = null;
                                this.setState({ modalContentType: '' }, () => {
                                    this.setState({
                                        modal: { visible: false, title: '' },
                                        modalContentType: 'OffsetForm',
                                        isBuffer: false
                                    }, () => this.props.setLayer(layer));
                                });
                            }
                        }
                    }
                    break;
                case 'rotating':
                    this.clickAfterRotate = true;
                    if (this.rotatingLayer !== layer && this.state.currentElementTools === (category === 'Espace vert' ? 'greenSpaceTools' : 'stationTools')) {
                        if (this.rotatingLayer) this.rotatingLayer.pm.disableRotate();
                        this.rotatingLayer = layer;
                        layer.pm.enableRotate();
                    }
                    break;
                case 'removing':
                    if (this.state.currentTools === toolButton) {
                        this.toggleElementsHighlight([layer]);
                        this.pushActionHistory([{ layer }]);
                    }
                    break;
                case 'managingActionElements':
                    if (category === 'Espace vert' && this.actionLinkingCategories.includes(layer.feature.properties.dominantCompositionId === 7 ? 'Massif arboré' : 'Espace vert') && this.state.subscription.actions)
                        this.handleLinkingClick(
                            layer, StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps), StylesUtil.getGreenSpaceHighlightStyle()
                        );
                    break;
                case 'managingEventElements':
                    if (category === 'Espace vert' && this.eventLinkingCategories.includes(layer.feature.properties.dominantCompositionId === 7 ? 'Massif arboré' : 'Espace vert') && this.state.subscription.events)
                        this.handleLinkingClick(
                            layer, StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps), StylesUtil.getGreenSpaceHighlightStyle()
                        );
                    break;
                case 'linkingElements':
                    if (layer.feature.id !== this.props.layer[0].feature.id)
                        this.handleLinkingClick(
                            layer, StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps), StylesUtil.getGreenSpaceHighlightStyle()
                        );
                    break;
                case 'elementsSelection':
                    if (!this.shiftKey) {
                        let selectedElements = this.state.selectedElements;
                        const element = selectedElements.find(x => x._leaflet_id === layer._leaflet_id);
                        if (selectedElements.length === 1 && element) this.unselectElements([element]);
                        else this.selectElements([layer], true);
                    }
                    break;
                default: if (!this.shiftKey && !this.props.currentAction && (category !== 'Station' || RightsUtil.canWrite(this.props.rights?.stations)))
                    this.showModal([layer]); break;
            }
        });
        layer.on('pm:vertexadded', e => { // Lorsqu'on crée un nouveau sommet
            this.pushActionHistory([{ layer: e.layer }], { addedVertex: [e.marker.getLatLng().lng, e.marker.getLatLng().lat] });
            this.vertexAddedOnLayer = layer;

            setTimeout(() => {
                if (!this.hasDragStarted) this.cancelLastAction();
                else this.hasDragStarted = false;
            }, 100);
        });
        layer.on('pm:vertexremoved', e => {
            const removedLatLng = JSON.parse(JSON.stringify(e.marker.getLatLng()));
            this.pushActionHistory([{ layer: e.layer }], { removedVertex: { latLng: removedLatLng, indexPath: e.indexPath } });
        });
        layer.on('pm:markerdragstart', e => {
            if (category === 'Espace vert' && (this.props.layer !== layer || this.state.modalContentType !== 'OffsetForm')) {
                this.offsetLayer = null;
                this.setState({ modalContentType: '' }, () => {
                    this.setState({
                        modal: { visible: false, title: '' },
                        modalContentType: 'OffsetForm',
                        isBuffer: false
                    }, () => this.props.setLayer(layer));
                });
            }
            this.hasDragStarted = true;
            if (!this.vertexAddedOnLayer || this.vertexAddedOnLayer !== layer) this.pushActionHistory([{ layer: e.layer }]);
            this.vertexAddedOnLayer = null;
        });
        layer.on('pm:markerdrag', e => {
            if (ProjectsUtil.isElementLocked(this.props.lockedElements, e.layer.feature)) {
                layer.setLatLngs(GeometriesUtil.convertPolygonCoordinatesToLatLngs(layer.feature.geometry.coordinates));
                this.map.pm.disableGlobalEditMode();
                this.map.pm.enableGlobalEditMode();
            } else if (!this.checkIfInsideSurroundings('polygon', layer) || e.layer.pm.hasSelfIntersection())
                e.layer.setStyle({ fillColor: '#FF0000' });
            else e.layer.setStyle(getStyle(e.layer));
        });
        layer.on('pm:markerdragend', e => {
            if (!this.checkIfInsideSurroundings('polygon', layer) || e.layer.pm.hasSelfIntersection()) {
                if (!this.checkIfInsideSurroundings('polygon', layer)) showToast('element_drag_not_allowed');
                else showToast('self_intersection_detected');
                this.cancelLastAction();
            } else {
                this.offsetLayer = null;
                layer.feature.geometry.coordinates = GeometriesUtil.convertPolygonLatLngsToCoordinates(layer.getLatLngs());
                this.setState({ isDragFinished: true }, () => this.setState({ isDragFinished: false })); // Pour ajuster basePolygon & width dans OffsetForm
            }

            e.layer.setStyle(getStyle(e.layer));
        });
        layer.on('pm:intersect', e => { e.layer.setStyle({ fillColor: '#FF0000' }); });
        layer.on('pm:dragstart', () => {
            this.clickBeforeDragend = true;
            const selectedElements = this.state.selectedElements.filter(layer => layer.feature.properties.category === category);
            const referencesLayers = this.referencesLayer.getLayers();
            if (selectedElements.find(element => element === layer)) {
                this.draggedLayers = selectedElements.forEach(layer => {
                    let layerWithLatLngs = { layer: layer };
                    if (layer.getLatLng) layerWithLatLngs.latLng = layer.getLatLng();
                    else if (layer.getLatLngs) layerWithLatLngs = { ...layerWithLatLngs, latLngs: layer.getLatLngs(), center: layer.getCenter() };
                    this.draggedLayers.push(layerWithLatLngs);
                    const referenceLayer = referencesLayers.find(l => l.elementId === layer.feature.id && l.category === layer.feature.properties.category);
                    if (referenceLayer) this.draggedLayers.push({ layer: referenceLayer, latLng: referenceLayer.getLatLng() });
                });
                this.pushActionHistory(selectedElements.map(layer => ({ layer })));
            } else {
                this.unselectElements();
                this.draggedLayers = [{ layer, latLngs: layer.getLatLngs(), center: layer.getCenter() }];
                const referenceLayer = referencesLayers.find(l => l.elementId === layer.feature.id && l.category === layer.feature.properties.category);
                if (referenceLayer) this.draggedLayers.push({ layer: referenceLayer, latLng: referenceLayer.getLatLng() });
                this.pushActionHistory([{ layer }]);
            }
        });
        layer.on('pm:drag', (e) => {
            const selectedElements = this.state.selectedElements.filter(layer => layer.feature.properties.category === category);
            if (selectedElements.length > 1) this.handleGroupDrag(layer, e);
            else if (ProjectsUtil.isElementLocked(this.props.lockedElements, layer.feature))
                layer.setLatLngs(GeometriesUtil.convertPolygonCoordinatesToLatLngs(layer.feature.geometry.coordinates));
            else if (this.draggedLayers?.length === 2) {
                const referenceLayer = this.draggedLayers.find(dl => dl.layer.elementId === layer.feature.id)?.layer;
                if (referenceLayer) referenceLayer.setLatLng(layer.getCenter());
            }
        });
        layer.on('pm:dragend', this.handleDragend);
        layer.on('mouseover', () => {
            if (category === 'Espace vert') this.overedElement = layer;
            if (!isMobile && this.props.currentAction === '') {
                if (this.elementPopupTimeout) clearTimeout(this.elementPopupTimeout);
                this.elementPopupTimeout = setTimeout(() => {
                    const dominantComposition = layer.feature.properties?.dominantCompositionId && this.props.dominantCompositions.find(x => x.id === layer.feature.properties.dominantCompositionId);
                    const popup = L.popup({ keepInView: true, closeButton: false, autoPan: false, className: 'element-popup' })
                        .setLatLng(layer.getCenter())
                        .setContent(renderToString(
                            category === 'Espace vert'
                                ? <>
                                    <p>{i18n.t("Composition dominante")} : <b style={{ color: 'var(--primary-100)' }}>{dominantComposition?.label || i18n.t("Non spécifié")}</b></p>
                                    <p>{i18n.t("Surface")} : <b style={{ color: 'var(--primary-100)' }}>{layer.feature.properties.surface}m²</b></p>
                                    {layer.feature.properties.baseLine && <p>{i18n.t("Longueur")} : <b style={{ color: 'var(--primary-100)' }}>{layer.feature.properties.baseLine.length}m</b></p>}
                                </>
                                : <>
                                    <p>{i18n.t("Libellé")} : <b style={{ color: 'var(--red-100)' }}>{layer.feature.properties?.label}</b></p>
                                    <p>{i18n.t("Surface")} : <b style={{ color: 'var(--red-100)' }}>{FormattersUtil.formatSquareMeter(layer.feature.properties.surface)}</b></p>
                                </>

                        ));

                    layer.bindPopup(popup);
                    layer.openPopup();
                }, 500);
            }

            if ((category !== 'Station' || this.state.currentTools === 'stationTools') && (!this.props.currentAction || this.props.currentAction === 'dragging'))
                layer.setStyle(getHighlightStyle())
        });
        layer.on('mouseout', () => {
            if (category === 'Espace vert') this.overedElement = null;
            if (!isMobile) {
                if (this.elementPopupTimeout) clearTimeout(this.elementPopupTimeout);
                layer.closePopup();
                layer.unbindPopup();
            }

            const { selectedElements, highlightedElements } = this.state;
            if ((!selectedElements?.length || !selectedElements.find(se => se.feature.id === layer.feature.id))
                && (!highlightedElements?.length || !highlightedElements.find(se => se.feature.id === layer.feature.id))
                && (!['merging', 'subtracting'].includes(this.props.currentAction) || this.props.layer !== layer))
                layer.setStyle(getStyle(layer));
        });
    }

    addGreenSpace = (layer, feature, { showContainer = false, showModal = false, showReferences = true, isCopying = false, updateLegend = true, events = true, style = null } = {}) => { // Fonction permettant d'ajouter un espace vert à la map et d'appeler le service qui l'ajoutera en DB
        if (isCopying) {
            feature = GeoJsonUtil.generatePolygonFeature(feature.properties, layer._latlngs, this.props.project.id);
            GreenSpacesService.addGreenSpace(feature, 'copyPasting', this.props.webSocketHubs);
        }
        // Ajout au greenSpacesLayer
        let newPolygon = L.polygon(layer._latlngs, style || StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, feature.properties, this.props.project.thematicMaps)); // On crée le layer à afficher sur la map
        newPolygon.feature = feature;
        this.greenSpacesLayer.addLayer(newPolygon); // On ajoute le layer au layer contenant les espaces verts

        // Evénément du layer principal 'Espaces verts'
        if (events) this.assignPolygonEvents('Espace vert', newPolygon);

        this.map.removeLayer(layer);
        if (showContainer && !this.map.hasLayer(this.greenSpacesLayer))
            this.toggleLayer({ layer: this.greenSpacesLayer });

        // Mise à jour de la légende
        if (updateLegend) this.updateLegend(i18n.t("Espaces verts"));

        if (showReferences && this.state.references.greenSpaces) {
            const zoomLevel = this.map.getZoom();
            if (zoomLevel >= 15) {
                TooltipsUtil.setGreenSpaceTooltip(newPolygon.feature.properties[this.state.references.greenSpaces], newPolygon, this.referencesLayer);
            }
        }

        if (!['drawingProjectSurroundings', 'modifyingProjectSurroundings'].includes(this.props.currentAction)) this.props.setEditedProperties(null);
        if (showModal) this.showModal([newPolygon], true);

        return newPolygon; // Utilisé pour stocker les polygones enfants
    }

    loadGreenSpaces = (projectId, loadedCustomFields, cacheResult) => { // Fonction qui permet de charger les arbres dans les layers
        if (this.publicFields.main.greenSpaces) {
            const caches = cacheResult?.caches?.filter(cache => cache.type === 'greenSpaces');
            const fetchIds = cacheResult?.fetchIds?.greenSpaces || [];
            const cachePromises = [], greenSpaceIds = new Set();
            if (caches?.length > 0) {
                const processResult = (greenSpacesData, resolve, reject, waitPromises = []) => {
                    if (Array.isArray(greenSpacesData?.missingCustomFieldIds))
                        this.missingCustomFieldIds.push(...greenSpacesData?.missingCustomFieldIds.filter(mcf => !this.missingCustomFieldIds.find(x => x.id === mcf.id)));

                    Promise.all(waitPromises).then(() => {
                        if (greenSpacesData?.greenSpaces && Array.isArray(greenSpacesData.greenSpaces)) {
                            if (greenSpacesData.greenSpaces.length > 0) {
                                const promises = [];
                                for (let i = 0; i < greenSpacesData.greenSpaces.length; i += 500) {
                                    const batch = greenSpacesData.greenSpaces.slice(i, i + 500);
                                    promises.push(new Promise((resolve) => {
                                        setTimeout(() => {
                                            batch.forEach(greenSpace => {
                                                if (!greenSpaceIds.has(greenSpace.fetchId)) {
                                                    greenSpaceIds.add(greenSpace.fetchId);
                                                    if (!greenSpace.deletionDate)
                                                        this.addGreenSpace(new L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(greenSpace.geometry.coordinates)), greenSpace, { updateLegend: false, showReferences: false });
                                                }
                                            });
                                            resolve();
                                        })
                                    }))
                                }
                                Promise.all(promises).then(() => resolve());
                            } else resolve();
                        } else reject();
                    });
                };

                cachePromises[0] = cacheResult.updatedElementIds.greenSpaces.length
                    ? new Promise((resolve, reject) => (
                        GreenSpacesService.getGreenSpaces(projectId, { elementIds: cacheResult.updatedElementIds.greenSpaces, loadedCustomFields }).then(greenSpacesData => { // On appelle le bon service pour récupérer les données
                            processResult(greenSpacesData, resolve, reject);
                        })
                    ))
                    : Promise.resolve();

                cachePromises[1] = Promise.all(
                    caches.filter(cache => !cache.isPrimary).map(cache => new Promise((resolve, reject) => (
                        GreenSpacesService.getGreenSpaces(projectId, { cacheKey: `${projectId}-${cache.isPrimary ? 'main' : 'sub'}-greenSpaces-${cache.index}`, loadedCustomFields, page: cache.index }).then(greenSpacesData => { // On appelle le bon service pour récupérer les données
                            processResult(greenSpacesData, resolve, reject, [cachePromises[0]]);
                        })
                    )))
                );

                cachePromises[2] = Promise.all(
                    caches.filter(cache => cache.isPrimary).map(cache => new Promise((resolve, reject) => (
                        GreenSpacesService.getGreenSpaces(projectId, { cacheKey: `${projectId}-${cache.isPrimary ? 'main' : 'sub'}-greenSpaces-${cache.index}`, loadedCustomFields, page: cache.index }).then(greenSpacesData => { // On appelle le bon service pour récupérer les données
                            processResult(greenSpacesData, resolve, reject, [cachePromises[0], cachePromises[1]]);
                        })
                    )))
                );
            }

            this.greenSpacesLoadingPromise.push(
                Promise.all(
                    caches?.length > 0
                        ? cachePromises
                        : [...Array(Math.ceil(fetchIds.length / 2500))].map((_, i) => new Promise((resolve) => (
                            GreenSpacesService.getGreenSpaces(projectId, { loadedCustomFields, page: i, cursor: i ? fetchIds[i * 2500 - 1] : -1, nbElements: fetchIds.length }).then(greenSpacesData => { // On appelle le bon service pour récupérer les données
                                if (greenSpacesData?.greenSpaces && Array.isArray(greenSpacesData.greenSpaces))
                                    greenSpacesData.greenSpaces.forEach(greenSpace => {
                                        this.addGreenSpace(new L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(greenSpace.geometry.coordinates)), greenSpace, { updateLegend: false, showReferences: false });
                                    });

                                if (Array.isArray(greenSpacesData?.missingCustomFieldIds))
                                    this.missingCustomFieldIds.push(...greenSpacesData?.missingCustomFieldIds.filter(mcf => !this.missingCustomFieldIds.find(x => x.id === mcf.id)));

                                resolve();
                            })
                        )))
                )
            );
        }
    }

    updateFurnituresLayerStyle = (highlightedElements = null) => {
        [this.furnituresLayer, this.furnituresLayerNotClustered].forEach(layerContainer => {
            layerContainer.eachLayer(layer => {
                if (!highlightedElements || !highlightedElements.includes(layer)) {
                    layer.setStyle(StylesUtil.getFurnitureActiveStyle(this.furnituresLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps));
                }
            });
        });
    }

    loadFurnitures = (projectId, loadedCustomFields, cacheResult) => { // Fonction qui permet de charger les arbres dans les layers
        if (this.publicFields.main.furnitures) {
            const caches = cacheResult?.caches?.filter(cache => cache.type === 'furnitures');
            const fetchIds = cacheResult?.fetchIds?.furnitures || [];
            const cachePromises = [], furnitureIds = new Set();
            if (caches?.length > 0) {
                const processResult = (furnituresData, resolve, reject, waitPromises = []) => {
                    if (Array.isArray(furnituresData?.missingCustomFieldIds))
                        this.missingCustomFieldIds.push(...furnituresData?.missingCustomFieldIds.filter(mcf => !this.missingCustomFieldIds.find(x => x.id === mcf.id)));

                    Promise.all(waitPromises).then(() => {
                        if (furnituresData?.furnitures && Array.isArray(furnituresData.furnitures)) {
                            if (furnituresData.furnitures.length > 0) {
                                const promises = [];
                                for (let i = 0; i < furnituresData.furnitures.length; i += 500) {
                                    const batch = furnituresData.furnitures.slice(i, i + 500);
                                    promises.push(new Promise((resolve) => {
                                        setTimeout(() => {
                                            batch.forEach(furniture => {
                                                if (!furnitureIds.has(furniture.fetchId)) {
                                                    furnitureIds.add(furniture.fetchId);
                                                    if (!furniture.deletionDate)
                                                        this.addMarker('Mobilier', { _latlng: { lat: furniture.geometry.coordinates[1], lng: furniture.geometry.coordinates[0] } }, furniture, { fromLoad: true, updateLegend: false, updateHeatmaps: false, showReferences: false });
                                                }
                                            });
                                            resolve();
                                        })
                                    }))
                                }
                                Promise.all(promises).then(() => resolve());
                            } else resolve();
                        } else reject();
                    });
                };

                cachePromises[0] = cacheResult.updatedElementIds.furnitures.length
                    ? new Promise((resolve, reject) => (
                        FurnituresService.getFurnitures(projectId, { elementIds: cacheResult.updatedElementIds.furnitures, loadedCustomFields }).then(furnituresData => { // On appelle le bon service pour récupérer les données
                            processResult(furnituresData, resolve, reject);
                        })
                    ))
                    : Promise.resolve();

                cachePromises[1] = Promise.all(
                    caches.filter(cache => !cache.isPrimary).map(cache => new Promise((resolve, reject) => (
                        FurnituresService.getFurnitures(projectId, { cacheKey: `${projectId}-${cache.isPrimary ? 'main' : 'sub'}-furnitures-${cache.index}`, loadedCustomFields, page: cache.index }).then(furnituresData => { // On appelle le bon service pour récupérer les données
                            processResult(furnituresData, resolve, reject, [cachePromises[0]]);
                        })
                    )))
                );

                cachePromises[2] = Promise.all(
                    caches.filter(cache => cache.isPrimary).map(cache => new Promise((resolve, reject) => (
                        FurnituresService.getFurnitures(projectId, { cacheKey: `${projectId}-${cache.isPrimary ? 'main' : 'sub'}-furnitures-${cache.index}`, loadedCustomFields, page: cache.index }).then(furnituresData => { // On appelle le bon service pour récupérer les données
                            processResult(furnituresData, resolve, reject, [cachePromises[0], cachePromises[1]]);
                        })
                    )))
                );
            }

            this.furnituresLoadingPromise.push(
                Promise.all(
                    caches?.length > 0
                        ? cachePromises
                        : [...Array(Math.ceil(fetchIds.length / 2500))].map((_, i) => new Promise((resolve) => (
                            FurnituresService.getFurnitures(projectId, { loadedCustomFields, page: i, cursor: i ? fetchIds[i * 2500 - 1] : -1, nbElements: fetchIds.length }).then(furnituresData => { // On appelle le bon service pour récupérer les données
                                if (furnituresData?.furnitures && Array.isArray(furnituresData.furnitures))
                                    furnituresData.furnitures.forEach(furniture => {
                                        this.addMarker('Mobilier', { _latlng: { lat: furniture.geometry.coordinates[1], lng: furniture.geometry.coordinates[0] } }, furniture, { updateLegend: false, updateHeatmaps: false, showReferences: false });
                                    });

                                if (Array.isArray(furnituresData?.missingCustomFieldIds))
                                    this.missingCustomFieldIds.push(...furnituresData?.missingCustomFieldIds.filter(mcf => !this.missingCustomFieldIds.find(x => x.id === mcf.id)));

                                resolve();
                            })
                        )))
                )
            );
        }
    }

    loadMarkers = () => { // Fonction qui permet de charger le mobilier urbain dans les layers
        if (this.publicFields.main.markers) {
            if (this.props.project.type === 'project') {
                this.markersLoadingPromise = MarkersService.getMarkers(this.props.project.id, {}).then(markers => { // On appelle le bon service pour récupérer les données
                    const isLayerShown = this.state.overlays.find(overlay => overlay.label === i18n.t("Repères"))?.isShown;
                    if (markers && Array.isArray(markers)) {
                        markers.forEach(marker => {
                            this.addMarker('Repère', { _latlng: { lat: marker.geometry.coordinates[1], lng: marker.geometry.coordinates[0] } }, marker, { updateLegend: false, updateHeatmaps: false, showReferences: false });
                        });
                        if (isLayerShown) this.updateLegend(i18n.t("Repères"));
                    }

                    this.updateLoader(i18n.t("Chargement des repères..."));
                    if (isLayerShown) this.toggleLayer({ layer: this.markersLayer });
                });
            } else {
                this.props.project.markers.forEach(marker => {
                    this.addMarker('Repère', { _latlng: { lat: marker.geometry.coordinates[1], lng: marker.geometry.coordinates[0] } }, marker, { updateLegend: false, updateHeatmaps: false, showReferences: false });
                });
                this.updateLegend(i18n.t("Repères"));
                this.updateLoader(i18n.t("Chargement des repères..."));
                this.toggleLayer({ layer: this.markersLayer });
            }
        } else this.updateLoader(i18n.t("Chargement des repères..."));
    }

    addStation = (layer, feature, { showContainer = false, updateLegend = true } = {}) => {
        // Ajout au stationsLayer
        let newPolygon = L.polygon(layer._latlngs, StylesUtil.getStationActiveStyle()); // On crée le layer à afficher sur la map
        newPolygon.feature = feature;
        this.stationsLayer.addLayer(newPolygon); // On ajoute le layer au layer contenant les stations

        // Evénément du layer principal 'Stations'
        this.assignPolygonEvents('Station', newPolygon);

        this.map.removeLayer(layer);
        if (showContainer && !this.map.hasLayer(this.stationsLayer))
            this.toggleLayer({ layer: this.stationsLayer });

        // Mise à jour de la légende
        if (updateLegend) this.updateLegend(i18n.t("Stations"));

        return newPolygon;
    }

    loadStations = (favoriteTiles) => { // Fonction de chargement des stations
        const isGeographicallyLimited = this.props.rights?.projectRoleStations?.length > 0 || this.props.rights?.areas?.length > 0;
        if (this.publicFields.main) {
            if (this.props.project.type === 'project') {
                this.stationsLoadingPromise = StationsService.getStations(this.props.project.id).then(stations => {
                    const isLayerShown = this.state.overlays.find(overlay => overlay.label === i18n.t("Stations"))?.isShown;
                    this.setMapSurroundings({ stations, favoriteTiles, isGeographicallyLimited, restoreLastBounds: true });
                    this.referencesRendering.forEach(renderFn => renderFn()); this.referencesRendering = null;
                    if (stations && Array.isArray(stations)) {
                        stations.forEach(station => {
                            const stationPolygon = new L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(station.geometry.coordinates));
                            if (!isGeographicallyLimited || this.checkIfInsideSurroundings('polygon', stationPolygon))
                                this.addStation(stationPolygon, station, { updateLegend: false });
                        });
                    }

                    this.updateLoader(i18n.t("Chargement des stations..."));
                    if (isLayerShown) this.toggleLayer({ layer: this.stationsLayer });
                });
            } else {
                this.setMapSurroundings({ stations: this.props.project.stations, favoriteTiles, isGeographicallyLimited, restoreLastBounds: true });
                this.props.project.stations.forEach(station => {
                    const stationPolygon = new L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(station.geometry.coordinates));
                    if (!isGeographicallyLimited || this.checkIfInsideSurroundings('polygon', stationPolygon))
                        this.addStation(stationPolygon, station, { updateLegend: false });
                });
                this.updateLoader(i18n.t("Chargement des stations..."));
                this.toggleLayer({ layer: this.stationsLayer });
            }
        } else this.updateLoader(i18n.t("Chargement des stations..."));
    }

    addBackgroundImage = (backgroundImage, isAdding, show, bounds = null) => {
        return new Promise((resolve) => {
            try {
                const tools = [L.ScaleAction, L.RotateAction];
                let bgImg;
                const blobInfos = AppSettings.getBlobInfos();
                let url = isAdding ? URL.createObjectURL(backgroundImage) : `${blobInfos.endpoint}${blobInfos.containers.backgroundImages}/${backgroundImage.properties.name}`;

                if (isAdding) {
                    this.toolbarRef.current.setButtonState('showImages', 1, 0);
                    bgImg = L.distortableImageOverlay(url, {
                        mode: 'scale',
                        actions: tools,
                        selected: true,
                        corners: bounds
                    });

                    const names = backgroundImage.name.split('.');
                    let layers = this.backgroundImagesLayer.getLayers();
                    layers.sort((a, b) => b.feature.properties.index - a.feature.properties.index);
                    const index = layers?.length ? layers[0].feature.properties.index + 1 : 1;
                    const properties = {
                        name: uuidv4() + '.' + names[1],
                        category: 'BackgroundImage', url: null, opacity: 100, index
                    }
                    bgImg.feature = GeoJsonUtil.generateImageFeature(properties, [], this.props.project.id);
                    bgImg.feature.id = uuidv4();
                    bgImg.feature.geometry.backgroundImageId = bgImg.feature.id;
                    bgImg.feature.geometry.projectId = this.props.project.id;
                    bgImg.feature.properties.backgroundImageId = bgImg.feature.id;
                    bgImg.feature.properties.projectId = this.props.project.id;
                } else {
                    bgImg = L.distortableImageOverlay(url, {
                        corners: backgroundImage.geometry.coordinates.map(coords => (L.latLng(coords[1], coords[0]))),
                        mode: 'scale',
                        actions: tools,
                        selected: false
                    });
                    bgImg.feature = backgroundImage;
                    bgImg.feature.properties.url = url;
                }

                // Gestion des events
                L.DomEvent.on(bgImg, 'load', () => {
                    bgImg.getElement().classList.add('background-image');
                    if (!isAdding) {
                        setTimeout(() => {
                            let layer = this.backgroundImagesLayer.getLayers().find(l => l.feature.id === bgImg.feature.id);
                            if (layer && layer.editing) layer.editing.disable();
                            resolve();
                        }, 100);
                    }

                    bgImg.on('dragstart', () => {
                        if (ProjectsUtil.isElementLocked(this.props.lockedElements, bgImg.feature, this.props.project, this.props.projectCollaborators, [])) {
                            bgImg.editing.disable();
                            bgImg.editing.enable();
                        } else if (['addingBackgroundImage', 'editingBackgroundImages'].includes(this.props.currentAction) && bgImg.editing.enabled())
                            this.pushActionHistory([{ layer: bgImg }]);
                    });

                    L.DomEvent.on(bgImg.getElement(), 'click', () => {
                        if (ProjectsUtil.isElementLocked(this.props.lockedElements, bgImg.feature, this.props.project, this.props.projectCollaborators, [])) {
                            bgImg.editing.disable();
                            bgImg.editing.enable();
                        } else if (this.props.currentAction === 'removing') {
                            this.toggleElementsHighlight([bgImg]);
                            this.pushActionHistory([{ layer: bgImg }]);
                        } else if (this.props.currentAction === 'editingBackgroundImages') this.setState({ backgroundImageToEdit: bgImg });
                    });

                    if (!show && !isAdding) bgImg.getElement().style.opacity = '0%';
                    if (isAdding) resolve();
                });

                if (isAdding) {
                    this.changeBackgroundImagesVisibility(0);
                    this.toggleBackgroundImages(false);
                    this.backgroundImagesLayer.addLayer(bgImg);
                    setTimeout(() => {
                        this.pushActionHistory([{ layer: bgImg }]);
                        let layer = this.backgroundImagesLayer.getLayers().find(l => !l.feature.properties.url?.length);
                        if (layer && layer.editing) layer.editing.enable();
                    }, 500);
                } else this.backgroundImagesLayer.addLayer(bgImg);
            } finally { resolve(); }
        });
    }

    toggleBackgroundImages = (state) => {
        const layers = this.backgroundImagesLayer.getLayers();
        for (const layer of layers) {
            const element = layer.getElement();
            if (!state) {
                layer.editing.disable();
                if (element) element.classList.add('disabled');
            }
            else {
                layer.editing.enable();
                if (element) element.classList.remove('disabled');
            }
        }
    }

    changeBackgroundImagesVisibility = (hideLayer) => {
        this.backgroundImagesLayer.getLayers().forEach(bgImg => {
            try {
                const element = bgImg.getElement();
                if (element && (hideLayer || !bgImg.feature.properties.isVisible)) {
                    element.classList.add('hidden');
                    element.style.opacity = 0;
                } else if (element) {
                    element.classList.remove('hidden');
                    element.style.opacity = `${bgImg.feature.properties.opacity}%`;
                }
            } catch (e) {
                console.log(e);
            }
        });
    }

    changeBackgroundImageVisibility = (feature) => {
        let backgroundImage = this.backgroundImagesLayer.getLayers().find(bi => bi.feature.id === feature.id)
        if (backgroundImage) {
            try {
                backgroundImage.feature = feature;
                const element = backgroundImage.getElement();
                if (element && !feature.properties.isVisible) {
                    element.classList.add('hidden');
                    element.style.opacity = 0;
                } else if (element) {
                    element.classList.remove('hidden');
                    element.style.opacity = `${backgroundImage.feature.properties.opacity}%`;
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    loadBackgroundImages = () => {
        const addBackgroundImages = (backgroundImages) => {
            if (backgroundImages && Array.isArray(backgroundImages) && backgroundImages.length) {
                backgroundImages.sort((a, b) => b.properties.index - a.properties.index);
                this.addBackgroundImagesToLayer(backgroundImages, backgroundImages.length - 1, false);
            }
            this.areBackgroundImagesLoaded = true;
            this.updateLoader(i18n.t("Chargement des calques..."));
        }

        if (this.publicFields.main.images) {
            if (this.props.project.type === 'project') {
                BackgroundImagesService.getBackgroundImages(this.props.project.id).then(addBackgroundImages);
            } else addBackgroundImages(this.props.project.backgroundImages);
        } else this.updateLoader(i18n.t("Chargement des calques..."));
    }

    addBackgroundImagesToLayer = (backgroundImages, index, showLayer, editLayer) => {
        this.addBackgroundImage(backgroundImages[index], false, showLayer)
            .then(() => {
                this.changeBackgroundImagesVisibility(showLayer ? 0 : 1);
                if (index > 0) this.addBackgroundImagesToLayer(backgroundImages, index - 1, showLayer, editLayer);
                else {
                    if (this.state.backgroundImageToEdit)
                        this.setState({ backgroundImageToEdit: this.backgroundImagesLayer.getLayers().find(bgImg => bgImg.feature.id === this.state.backgroundImageToEdit.feature.id) })
                    this.toggleBackgroundImages(editLayer);
                    setTimeout(() => this.forceUpdate(), 50);
                }
            });
    }

    updateBackgroundImagesLayer = (backgroundImages, showLayer, editLayer) => {
        this.shouldUpdateBackgroundImagesLayer = false;
        backgroundImages = backgroundImages || this.backgroundImagesLayer.getLayers().map(bgImg => bgImg.feature).sort((a, b) => b.properties.index - a.properties.index);
        // Mise à jour de l'ordre
        let i = backgroundImages.length;
        for (let backgroundImage of backgroundImages) {
            backgroundImage.properties.index = i;
            i--;
        }
        // Suppression des layers
        this.backgroundImagesLayer.eachLayer(layer => {
            this.backgroundImagesLayer.removeLayer(layer);
        });
        // Ajout dans l'ordre des layers
        this.addBackgroundImagesToLayer(backgroundImages, backgroundImages.length - 1, showLayer, editLayer);
    }

    loadWmsServices = (favoriteTiles, projectView, urlOverlays) => {
        if (this.publicFields.main.wmsServices)
            WmsService.getWmsServicesWithConfiguration((this.props.project.wmsServices || [])).then(wmsServices => {
                wmsServices = wmsServices.filter(wmsService => wmsService?.availableLayers?.length);

                const project = { ...this.props.project };
                project.wmsServices = wmsServices;
                this.props.setProject(project);

                this.setState(prevState => ({
                    baseLayers: [...prevState.baseLayers, ...wmsServices.filter(wmsService => wmsService.type === 'baseLayer').map(wmsService => ({
                        id: wmsService.id, label: wmsService.label, name: wmsService.id, isShown: favoriteTiles === wmsService.id, layer: wmsService.serviceType === 'WMTS'
                            ? L.tileLayer.wmts(wmsService.url, { layer: wmsService.availableLayers[0]?.value, maxZoom: 22, format: wmsService.format, style: wmsService.style, tilematrixSet: wmsService.tileMatrixSet, maxNativeZoom: wmsService.maxNativeZoom, bounds: wmsService.boundingBox?.[0]?.[1] && wmsService.boundingBox?.[1]?.[1] ? L.latLngBounds(L.latLng(wmsService.boundingBox[0][1], wmsService.boundingBox[0][0]), L.latLng(wmsService.boundingBox[1][1], wmsService.boundingBox[1][0])) : null })
                            : L.tileLayer.wms(wmsService.url, { layers: wmsService.availableLayers.map(layer => layer.value).join(','), maxZoom: 22, format: 'image/png', bounds: wmsService.boundingBox?.[0]?.[1] && wmsService.boundingBox?.[1]?.[1] ? L.latLngBounds(L.latLng(wmsService.boundingBox[0][1], wmsService.boundingBox[0][0]), L.latLng(wmsService.boundingBox[1][1], wmsService.boundingBox[1][0])) : null })
                    }))]
                }));

                wmsServices.filter(wmsService => wmsService.type === 'overlay').forEach(wmsService => {
                    const layer = wmsService.serviceType === 'WMTS'
                        ? L.tileLayer.wmts(wmsService.url, { layer: wmsService.availableLayers[0]?.value, maxZoom: 22, pane: 'overlayPane', transparent: true, opacity: wmsService.opacity, format: wmsService.format, style: wmsService.style, tilematrixSet: wmsService.tileMatrixSet, maxNativeZoom: wmsService.maxNativeZoom, bounds: wmsService.boundingBox?.[0]?.[1] && wmsService.boundingBox?.[1]?.[1] ? L.latLngBounds(L.latLng(wmsService.boundingBox[0][1], wmsService.boundingBox[0][0]), L.latLng(wmsService.boundingBox[1][1], wmsService.boundingBox[1][0])) : null })
                        : L.tileLayer.wms(wmsService.url, { layers: wmsService.availableLayers.map(layer => layer.value).join(','), maxZoom: 22, pane: 'overlayPane', transparent: true, opacity: wmsService.opacity, format: 'image/png', bounds: wmsService.boundingBox?.[0]?.[1] && wmsService.boundingBox?.[1]?.[1] ? L.latLngBounds(L.latLng(wmsService.boundingBox[0][1], wmsService.boundingBox[0][0]), L.latLng(wmsService.boundingBox[1][1], wmsService.boundingBox[1][0])) : null });
                    this.addOverlayToControlLayer(wmsService.label, { layer, customMap: wmsService }).then(() => {
                        if (urlOverlays ? urlOverlays.find(overlay => +overlay === wmsService.id) : projectView?.overlays?.find(overlay => overlay.label === wmsService.label)?.isShown)
                            this.toggleLayer({ layer });
                    });
                });
            });
    }

    loadActions = () => {
        const addProjectActions = (projectActions) => {
            this.updateLoader(i18n.t("Chargement des actions..."));
            if (!projectActions) return;
            // Mapping des actions
            projectActions.forEach(projectAction => {
                projectAction.action = this.props.actions.find(action => action.id === projectAction.actionId);
                if (!projectAction.projectActionElements) projectAction.projectActionElements = [];
            });
            this.props.setProjectActions(projectActions);

            const treesToCutDown = this.getTreesToCutDown(projectActions);
            const actionsUrgencies = this.getActionsUrgencies(projectActions);
            if (this.treesLoadingPromise)
                this.treesLoadingPromise.then(() => {
                    this.updateToCutDown(this.treesLayer, treesToCutDown);
                    this.updateToCutDown(this.treesLayerNotClustered, treesToCutDown);
                    this.updateActionsUrgency(this.treesLayer, actionsUrgencies, 'Arbre');
                    this.updateActionsUrgency(this.treesLayerNotClustered, actionsUrgencies, 'Arbre');
                    if (this.state.overlays.find(overlay => overlay.label === i18n.t("Arbres"))?.isShown) this.updateLegend(i18n.t("Arbres"));
                });
            if (this.greenSpacesLoadingPromise)
                this.greenSpacesLoadingPromise.then(() => {
                    this.updateActionsUrgency(this.greenSpacesLayer, actionsUrgencies, 'Espace vert');
                    if (this.state.overlays.find(overlay => overlay.label === i18n.t("Espaces verts"))?.isShown) this.updateLegend(i18n.t("Espaces verts"));
                });
            if (this.furnituresLoadingPromise)
                this.furnituresLoadingPromise.then(() => {
                    this.updateActionsUrgency(this.furnituresLayer, actionsUrgencies, 'Mobilier');
                    this.updateActionsUrgency(this.furnituresLayerNotClustered, actionsUrgencies, 'Mobilier');
                    if (this.state.overlays.find(overlay => overlay.label === i18n.t("Mobilier urbain"))?.isShown) this.updateLegend(i18n.t("Mobilier urbain"));
                });
        }

        this.props.setProjectActions(null);
        if (this.publicFields.main.actions) {
            if (this.props.project.type === 'project')
                ActionsService.getProjectActions(this.props.project.id).then(addProjectActions);
            else addProjectActions(this.props.project.projectActions);
        } else this.updateLoader(i18n.t("Chargement des actions..."));
    }

    loadProjectEvents = () => {
        const addProjectEvents = (projectEvents) => {
            this.updateLoader(i18n.t("Chargement des évènements..."));
            if (!projectEvents) return;
            // Mapping des évènements
            projectEvents.forEach(projectEvent => projectEvent.event = this.props.events.find(e => e.id === projectEvent.eventId));
            this.props.setProjectEvents(projectEvents);
        }

        this.props.setProjectEvents(null);
        if (this.publicFields.main.events) {
            if (this.props.project.type === 'project')
                EventsService.getProjectEvents(this.props.project.id).then(addProjectEvents);
            else addProjectEvents(this.props.project.projectEvents);
        } else this.updateLoader(i18n.t("Chargement des évènements..."));
    }

    loadPhotos = () => {
        if (this.publicFields.main.photos) {
            if (!this.props.project.photos) {
                FileInfosService.getProjectFileInfos(this.props.project.id, 'photo').then(photos => {
                    if (photos) { // Ajout des photos aux galeries respectives
                        photos.forEach(photo => photo.url = FileInfosUtil.getUrl(photo));
                        this.props.setPhotosGalleries(photos);
                    }
                    this.updateLoader(i18n.t("Chargement des photos..."));
                });
            } else {
                this.props.project.photos.forEach(photo => photo.url = FileInfosUtil.getUrl(photo));
                this.props.setPhotosGalleries(this.props.project.photos);
                this.updateLoader(i18n.t("Chargement des photos..."));
            }
        } else this.updateLoader(i18n.t("Chargement des photos..."));
    }

    loadFiles = () => {
        const addFiles = (files) => { // Ajout des fichiers aux galeries respectives
            this.updateLoader(i18n.t("Chargement des fichiers..."));
            if (!files?.length) return;
            files.forEach(file => file.url = FileInfosUtil.getUrl(file));
            this.props.setFilesGalleries(files.filter(x => x.elementId));
            let projects = this.props.projects ? JSON.parse(JSON.stringify(this.props.projects)) : [];
            const index = projects.findIndex(x => x.id === this.props.project.id);
            if (index !== -1) {
                projects[index].fileInfos = files.filter(x => !x.elementId);
                this.props.setProjects(projects);
            }
            let project = JSON.parse(JSON.stringify(this.props.project));
            project.fileInfos = files.filter(x => !x.elementId);
            this.props.setProject(project);
        }

        if (this.publicFields.main.files) {
            if (!this.props.project.files) {
                FileInfosService.getProjectFileInfos(this.props.project.id, 'file').then(addFiles);
            } else addFiles(this.props.project.files)
        } else this.updateLoader(i18n.t("Chargement des fichiers..."));
    }

    loadLinkedElements = () => {
        if (!this.props.project.linkedElements?.length) {
            ProjectsService.getLinkedElements(this.props.project.id).then((linkedElements) => {
                this.updateLoader(i18n.t("Chargement de la liaison des éléments..."));
                if (linkedElements) {
                    if (this.props.project.type === 'project') {
                        const project = { ...this.props.project, linkedElements };
                        ProjectsUtil.updateProjectsInProps(project, this.props.projects, this.props.formulas, this.props.project, this.props.setProjects, this.props.setProject);
                    } else {
                        let project = JSON.parse(JSON.stringify(this.props.project));
                        project.linkedElements = linkedElements;
                        this.props.setProject(project);
                    }
                }
            });
        } else this.updateLoader(i18n.t("Chargement de la liaison des éléments..."));
    }

    loadProjectCustomFields = async () => {
        return new Promise((resolve) => {
            if (!this.props.projectsCustomFields[this.props.project.id])
                if (this.props.project.type === 'project') {
                    CustomFieldsService.getProjectCustomFields(this.props.project.id).then(projectCustomFields => {
                        this.props.setProjectCustomFields(this.props.project.id, projectCustomFields).then(() => resolve());
                        this.updateLoader(i18n.t("Chargement des champs personnalisés..."));
                    });
                } else {
                    this.props.setProjectCustomFields(this.props.project.id, this.props.project.projectCustomFields).then(() => resolve());
                    this.updateLoader(i18n.t("Chargement des champs personnalisés..."));
                }
            else {
                this.updateLoader(i18n.t("Chargement des champs personnalisés..."));
                resolve();
            }
        });
    }

    loadMissingCustomFields = () => {
        const { project, projectsCustomFields } = this.props;
        if (project.type === 'project')
            Promise.all([this.treesLoadingPromise, this.furnituresLoadingPromise, this.greenSpacesLoadingPromise]).then(() => {
                if (this.missingCustomFieldIds?.length > 0)
                    CustomFieldsService.getMissingCustomFields({ projectId: project.id, missingCustomFieldIds: this.missingCustomFieldIds }).then(missingCustomFields => {
                        this.props.setProjectCustomFields(project.id, [...(projectsCustomFields[project.id] || []), ...missingCustomFields]);
                    });
            });
    }

    getTreesToCutDown = (projectActions) => {
        let treesToCutDown = [];
        projectActions.filter(pa => pa.actionId < 4).forEach(pa => { // Pour chacune des actions du projet correspondant à un abattage
            pa.projectActionElements.filter(pae => !pae.replantingDate).forEach(pae => { // Pour chacun des éléments de l'action
                if (pae.projectActionElementRecurrences?.length) {
                    const status = pae.projectActionElementRecurrences[0]?.status;
                    if (status !== 'cancelled') {
                        const index = treesToCutDown.findIndex(tree => tree.elementId === pae.elementId);
                        // On ajoute le lien élément/action dans le tableau s'il n'est pas trouvé
                        if (index === -1) treesToCutDown.push({ elementId: pae.elementId, actionId: status === 'done' ? 4 : pa.actionId });
                        // Sinon, si l'abattage est plus urgent, on remplace l'id de l'action
                        else if (pa.actionId > treesToCutDown[index].actionId || status === 'done')
                            treesToCutDown[index].actionId = status === 'done' ? 4 : pa.actionId;
                    }
                }
            });
        });
        return treesToCutDown;
    }

    updateToCutDown = (layerContainer, treesToCutDown) => {
        layerContainer.eachLayer(layer => {
            const cutDownActionId = treesToCutDown.find(ttc => ttc.elementId === layer.feature.id)?.actionId;
            if (cutDownActionId) layer.feature.properties = { ...layer.feature.properties, toCutDown: cutDownActionId }
        });
        this.updateTreesLayerStyle();
    }

    getActionsUrgencies = (projectActions) => {
        let actionsUrgencies = [];
        projectActions.forEach(pa => { // Pour chacune des actions du projet
            pa.projectActionElements.forEach(pae => { // Pour chacun des éléments de l'action
                if (pae.projectActionElementRecurrences?.length) {
                    const index = actionsUrgencies.findIndex(tree => tree.elementId === pae.elementId);
                    const actionsUrgency = ActionsUtil.getActionUrgency(actionsUrgencies[index]?.actionsUrgency || 0, pa, pae.projectActionElementRecurrences);
                    if (index === -1) actionsUrgencies.push({ elementId: pae.elementId, actionsUrgency });
                    else actionsUrgencies[index].actionsUrgency = actionsUrgency;
                }
            });
        });
        return actionsUrgencies;
    }

    updateActionsUrgency = (layerContainer, actionsUrgencies, category) => {
        const categories = {
            'Arbre': { getStyle: (actionsUrgency) => StylesUtil.getTreeActionsUrgencyStyle(this.fieldList.zoomLevel, actionsUrgency) },
            'Espace vert': { getStyle: (actionsUrgency) => StylesUtil.getGreenSpaceActionsUrgencyStyle(actionsUrgency) },
            'Mobilier': { getStyle: (actionsUrgency) => StylesUtil.getFurnitureActionsUrgencyStyle(actionsUrgency) }
        };

        layerContainer.eachLayer(layer => {
            const actionsUrgency = actionsUrgencies.find(au => au.elementId === layer.feature.id)?.actionsUrgency || 0;
            const { getStyle } = categories[category];
            layer.feature.properties = { ...layer.feature.properties, actionsUrgency };
            if (layerContainer.activeChild === i18n.t("Actions")) layer.setStyle(getStyle(actionsUrgency));
        });
    }

    exitSubTool = (resetToolbarSelection, saveChanges = true) => {
        this.resetPanesIndexes();
        this.toggleBackgroundImages(false);
        this.toolbarRef.current.setButtonsIsDisabled(['lasso'], false);
        if (this.actionHistory.history.length > 0 && !['removing', 'addingRectangle', 'addingCircle'].includes(this.props.currentAction) && saveChanges)
            this.confirmAction();
        else if (this.actionHistory.history.length > 0 && this.props.currentAction !== 'addingBackgroundImage')
            this.resetActionHistory(true);
        if (this.props.layer) {
            if (this.props.layer.pm) this.props.layer.pm.disableRotate();
            if (['addingLine', 'addingRectangle', 'addingCircle'].includes(this.props.currentAction)) this.map.removeLayer(this.props.layer);
            this.props.setLayer(null);
        }
        if (this.map.pm.globalDragModeEnabled()) this.map.pm.disableGlobalDragMode();
        if (this.rotatingLayer) {
            this.rotatingLayer.pm.disableRotate();
            this.rotatingLayer = null;
        }
        if (this.editingLayer) {
            this.editingLayer.pm.disable();
            this.editingLayer = null;
        }
        if (resetToolbarSelection) {
            this.toolbarRef.current.resetButtonSelection(1);
            this.showClusteredLayer('Arbre', true);
            this.showClusteredLayer('Mobilier', true);
        }
        if (this.props.currentAction === 'splitting') this.hideDrawLines();
        else if (this.props.currentAction === 'editing') {
            this.greenSpacesLayer.eachLayer(layer => {
                if (layer.feature?.properties.baseLine) {
                    layer.setStyle({ pmIgnore: false });
                    L.PM.reInitLayer(layer);
                }
            });
            this.hideDrawLines();
        }
        this.resetSelectionAsCopy();
        this.props.setCurrentAction('');
        this.setState({
            modal: { visible: false, title: '' },
            modalContentType: ''
        });
    }

    setPolygonDimensions = (polygonCoords, buffer, layer, shouldPush) => {
        const { isBuffer } = this.state;
        const coordinates = GeometriesUtil.getBufferedPolygon(JSON.parse(JSON.stringify(polygonCoords)), buffer);
        if (!coordinates) return false;

        const latLngs = GeometriesUtil.convertPolygonCoordinatesToLatLngs(coordinates);
        const newPolygonBounds = new L.Polygon(latLngs);
        if (!this.checkIfInsideSurroundings('polygon', newPolygonBounds) || GeometriesUtil.checkIfPolygonHasSelfIntersection(newPolygonBounds)) return false;

        let isReduced = false;
        if (isBuffer) {
            let polygonDiff = difference(featureCollection([polygon(coordinates), polygon(polygonCoords)]));
            if (!polygonDiff) { // Si pas de différence, on check s'il est rétréci
                polygonDiff = difference(featureCollection([polygon(polygonCoords), polygon(coordinates)]));
                if (polygonDiff) isReduced = true;
            }

            if (!polygonDiff) { // S'il n'est ni rétréci ni agrandi, on retire le offsetLayer si besoin
                if (this.offsetLayer) {
                    this.greenSpacesLayer.removeLayer(this.offsetLayer);
                    this.pushActionHistory([{ layer }, { layer: this.offsetLayer, isDeletion: true }]);
                    this.offsetLayer = null;
                }
            } else {
                if (this.offsetLayer) {
                    if (shouldPush) this.pushActionHistory([{ layer }, { layer: this.offsetLayer }]);
                    this.offsetLayer.feature.geometry.coordinates = polygonDiff.geometry.coordinates;
                    this.offsetLayer.setLatLngs(GeometriesUtil.convertPolygonCoordinatesToLatLngs(polygonDiff.geometry.coordinates));
                } else {
                    const newLayer = new L.Polygon(
                        GeometriesUtil.convertPolygonCoordinatesToLatLngs(polygonDiff.geometry.coordinates),
                        StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps)
                    );
                    newLayer.feature = GeoJsonUtil.generatePolygonFeature(
                        JSON.parse(JSON.stringify(layer.feature.properties)),
                        newLayer.getLatLngs(), layer.feature.projectId
                    );
                    newLayer.feature.id = uuidv4();

                    const newPolygon = this.addGreenSpace(newLayer, newLayer.feature);
                    this.offsetLayer = newPolygon;
                    this.pushActionHistory([{ layer }, { layer: this.offsetLayer, isAddition: true }]);
                }
            }
        } else {
            if (this.offsetLayer) {
                this.greenSpacesLayer.removeLayer(this.offsetLayer);
                this.pushActionHistory([{ layer }, { layer: this.offsetLayer, isDeletion: true }]);
                this.offsetLayer = null;
            } else if (shouldPush) this.pushActionHistory([{ layer }]);
        }

        layer.feature.geometry.coordinates = isReduced || !isBuffer ? coordinates : polygonCoords;
        layer.setLatLngs(GeometriesUtil.convertPolygonCoordinatesToLatLngs(isReduced || !isBuffer ? coordinates : polygonCoords));
        if (this.editingLayer === layer) {
            layer.pm.disable();
            layer.pm.enable();
        }
        return true;
    }

    setLineDimensions = (line, width, layer) => {
        let style = { color: 'var(--primary-100)', fillOpacity: 0.7 };
        let isInside = false;

        const coordinates = GeometriesUtil.convertBufferedLineToCoordinates(line, width);
        const latLngs = GeometriesUtil.convertPolygonCoordinatesToLatLngs(coordinates);
        const polygon = new L.Polygon(latLngs);

        if (!layer) {
            if (!this.checkIfInsideSurroundings('polygon', polygon) || GeometriesUtil.checkIfPolygonHasSelfIntersection(polygon))
                style = { ...style, fillColor: '#FF0000' };
            else {
                style = { ...style, fillColor: 'var(--secondary-100)' };
                isInside = true;
            }

            this.map.removeLayer(this.props.layer);
            polygon.setStyle(style);
            this.props.setLayer(polygon);
            this.map.addLayer(polygon);
        } else {
            if (!this.checkIfInsideSurroundings('polygon', polygon) || GeometriesUtil.checkIfPolygonHasSelfIntersection(polygon)) return false;
            layer.setLatLngs(latLngs);
            return true;
        }

        return isInside;
    }

    setRectangleDimensions = (length, width, angle) => {
        this.props.layer.pm.disableRotate();
        GeometriesUtil.setRectangleDimensions(this.props.layer, length, width, angle);
        const isInside = this.checkIfInsideSurroundings('rectangle', this.props.layer);
        if (!isInside) this.props.layer.setStyle({ color: 'var(--primary-100)', fillColor: 'FF0000', fillOpacity: 0.7 });
        else this.props.layer.setStyle({ color: 'var(--primary-100)', fillColor: 'var(--secondary-100)', fillOpacity: 0.7 });
        this.props.layer.pm.enableRotate();
        return isInside;
    }

    setCircleDimensions = (center, radius, steps) => {
        let style = { color: 'var(--primary-100)', fillOpacity: 0.7 };
        const circle = new L.Circle(center, { radius: radius });
        const isInside = this.checkIfInsideSurroundings('circle', circle);
        if (!isInside) style = { ...style, fillColor: '#FF0000' };
        else style = { ...style, fillColor: 'var(--secondary-100)' };

        this.map.removeLayer(this.props.layer);
        const polygon = GeometriesUtil.convertCircleToPolygon(circle, radius, steps);
        polygon.setLatLngs(polygon.getLatLngs());
        polygon.setStyle(style);
        this.props.setLayer(polygon);
        this.map.addLayer(polygon);

        return isInside;
    }

    redirectViaURL = () => {
        const { project, rights, activeOrganization, projectCollaborators, logged } = this.props;
        const { path1, path2, path3 } = this.props.match.params;

        const prevent = () => this.props.history.push({ pathname: '/projects/' + this.props.project.id, search: this.mapSearch.short });

        let content, contentType;
        Promise.all([this.treesLoadingPromise, this.greenSpacesLoadingPromise, this.furnituresLoadingPromise, this.markersLoadingPromise].filter(p => p)).then(() => {
            switch (path1) {
                case 'filters':
                    if (RightsUtil.canRead(rights?.filters) && activeOrganization?.subscription?.filters && this.publicFields?.main.filters)
                        this.changeModalContentType('FilterForm', i18n.t("Filtres"));
                    else prevent();
                    break;
                case 'tables':
                    if (RightsUtil.canRead(rights?.tables) && !isMobile && activeOrganization?.subscription?.dataTable && this.publicFields?.main.dataTable) {
                        contentType = { 'trees': 'TreeTable', 'greenSpaces': 'GreenSpaceTable', 'furnitures': 'FurnitureTable' }[path2]
                        if (contentType) this.changeModalContentType(contentType, i18n.t("Tableau de données"));
                    } else prevent();
                    break;
                case 'statistics':
                    content = {
                        'general': { type: 'ProjectDetail', title: i18n.t("Statistiques"), isVisible: () => RightsUtil.canRead(rights?.statistics) && activeOrganization?.subscription?.statistics && this.publicFields?.main?.statistics },
                        'genders': { type: 'GendersChart', title: i18n.t("Graphique des genres") },
                        'ontogenicStages': { type: 'OntogenicsChart', title: i18n.t("Graphique des stades ontogéniques") }, 'vigors': { type: 'VigorsChart', title: i18n.t("Graphique des vigueurs") },
                        'cutDowns': { type: 'CutDownsChart', title: i18n.t("Graphique des abattages") }, 'actions': { type: 'ActionsChart', title: i18n.t("Graphique des actions à réaliser") },
                        'healthReviews': { type: 'HealthReviewsChart', title: i18n.t("Graphique des cotes sanitaires") },
                        'customCharts': { type: 'CustomCharts', title: i18n.t("Graphiques personnalisés"), isVisible: () => RightsUtil.canRead(rights?.charts) && activeOrganization?.subscription?.customCharts && this.publicFields?.main?.charts }
                    };
                    content = content[path2] || content.general;

                    if (content.isVisible ? content.isVisible() : (RightsUtil.canRead(rights?.charts) && activeOrganization?.subscription?.charts && this.publicFields?.main?.charts))
                        this.changeModalContentType(content.type, content.title, false, true);
                    else prevent();
                    break;
                case 'actions':
                    if (project && RightsUtil.canRead(rights?.actions))
                        this.changeModalContentType('ActionTable', i18n.t("Actions"), false, true);
                    else prevent();
                    break;
                case 'events':
                    if (project && RightsUtil.canRead(rights?.events))
                        this.changeModalContentType('EventTable', i18n.t("Évènements"), false, true);
                    else prevent();
                    break;
                case 'history':
                    if (project && RightsUtil.canRead(rights?.projectHistory))
                        this.changeModalContentType('ProjectHistory', i18n.t("Historique du projet"));
                    else prevent();
                    break;
                case 'trees': case 'greenSpaces': case 'furnitures': case 'markers':
                    const { category, layerContainer, promise } = {
                        'trees': { category: 'Tree', layerContainer: this.linkedTrees },
                        'greenSpaces': { category: 'GreenSpace', layerContainer: this.greenSpacesLayer.getLayers().map(layer => [layer]) },
                        'furnitures': { category: 'Furniture', layerContainer: this.linkedFurnitures },
                        'markers': { category: 'Marker', layerContainer: this.markersLayer.getLayers().map(layer => [layer]) }
                    }[path1];

                    const layers = path2 && layerContainer.find(layers => layers[0].feature.id === path2);

                    if (layers) {
                        content = {
                            'detail': { type: `${category}Detail`, title: i18n.t("Détails") },
                            'form': { type: `${category}Form`, title: i18n.t("Modification d'un élément"), isVisible: () => logged && RightsUtil.canWrite(rights[path1]) },
                            'history': { type: `${category}History`, title: i18n.t("Historique"), isVisible: () => logged && RightsUtil.canWrite(rights[path1]) },
                            'charts': { type: `${category}Charts`, title: i18n.t("Graphiques") },
                            'photos': { type: "PhotosGallery", title: i18n.t("Photos"), isVisible: () => this.publicFields.main.photos },
                            'files': { type: "ElementFilesGallery", title: i18n.t("Fichiers"), isVisible: () => ProjectsUtil.isUserInProject(projectCollaborators) && project?.organization.subscription.filesSharing && this.publicFields.main.files },
                            'actions': { type: "ActionForm", title: i18n.t("Actions"), isVisible: () => this.publicFields.main.actions && RightsUtil.canRead(rights.actions) },
                            'events': { type: "EventForm", title: i18n.t("Évènements"), isVisible: () => category === 'Tree' && this.publicFields.main.events && RightsUtil.canRead(rights.events) },
                            'linkedElements': { type: "LinkedElementList", title: i18n.t("Éléments liés"), isVisible: () => projectCollaborators && ProjectsUtil.isUserInProject(projectCollaborators) },
                        };
                        content = content[path3] || content.detail;

                        if (!content.isVisible || content.isVisible()) {
                            this.arrowShortcutsEnabled = true;
                            document.addEventListener('keydown', this.handleArrowShortcuts);
                            this.props.setLayer(layers).then(() => {
                                this.changeModalContentType(content.type, content.title);
                                this.props.setCurrentAction('viewingModal');
                            });
                        } else prevent();
                    } else showToast('element_not_found');
                    break;
                default: break;
            }
        });
    }

    exportStatisticsAsPDF = () => new Promise(resolve => {
        const brandingRight = this.props.project?.organization.subscription.branding;
        exportStatistics(this.props.project.label, brandingRight && this.props.project.theme?.logoName).then(({ canvas, width, height }) => {
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'px',
                format: [width, height],
                putOnlyUsedFonts: true
            });

            const ratio = width / pdf.internal.pageSize.getWidth();
            pdf.addImage(canvas, 'JPEG', 0, 0, width / ratio, height / ratio);
            pdf.save(`${i18n.t("Statistiques").toLocaleLowerCase()}.pdf`);
            canvas.remove();
            resolve();
        });
    });

    takeRawScreenshot = async ({ format = 'JPEG', scale = 4, titles = null, shouldDownload = true } = {}) => { // Fonction permettant de prendre un screenshot
        return new Promise(resolve => this.setState({ screenshotTitles: titles }, () => {
            this.showLoader(true, 'map', i18n.t("Capture en cours..."));
            MapsUtil.toggleControls(false);
            const isTreesLayerVisible = this.map.hasLayer(this.treesLayer);
            if (isTreesLayerVisible) this.updateOverlayInControlLayer(i18n.t("Arbres"), this.treesLayerNotClustered);
            const isFurnituresLayerVisible = this.map.hasLayer(this.furnituresLayer);
            if (isFurnituresLayerVisible) this.updateOverlayInControlLayer(i18n.t("Mobilier urbain"), this.furnituresLayerNotClustered);

            exportScreenshot('projectMapContainer', scale, { titles }).then(({ canvas, url, width, height }) => {
                if (isTreesLayerVisible) this.updateOverlayInControlLayer(i18n.t("Arbres"), this.treesLayer);
                if (isFurnituresLayerVisible) this.updateOverlayInControlLayer(i18n.t("Mobilier urbain"), this.furnituresLayer);
                MapsUtil.toggleControls(true);
                this.showLoader(false);

                if (shouldDownload) {
                    switch (format) {
                        case 'JPEG': download(url, 'carte.jpeg', 'image/jpeg'); break;
                        case 'PNG': download(url, 'carte.png', 'image/png'); break;
                        case 'PDF':
                            const pdf = new jsPDF({
                                orientation: 'landscape',
                                unit: 'px',
                                format: [width, height],
                                putOnlyUsedFonts: true,
                                floatPrecision: 16
                            });

                            const ratio = width / pdf.internal.pageSize.getWidth();
                            pdf.addImage(canvas, 'JPEG', 0, 0, width / ratio, height / ratio);
                            pdf.save('carte.pdf');
                            break;
                        default: break;
                    }
                }

                resolve(url);
            });
        }));
    }

    takeScreenshot = async ({
        formats = { file: 'JPEG', paper: { id: 'A4', height: 29.7, width: 21 } }, isLandscape = true,
        overflowZoom, scale = { x: 1, y: 1 }, scaleDisplay = null, titles = null, showLegend = false,
        showSurroundings = false, referencesToShow = null, elements = null, showScreenshotEditor = false, showLoader = true, center = null, avoidLegendUpdate = false
    } = {}) => { // Fonction permettant de prendre un screenshot
        return new Promise(async (resolve) => {
            const references = JSON.parse(JSON.stringify(this.state.references));

            if (showLoader) {
                const location = ['exportingAction', 'exportingBacklog'].includes(this.props.currentAction) ? 'modal' : 'map';
                this.showLoader(true, location, i18n.t("Capture en cours..."));
            }
            const hiddenBaseLayer = this.showBaseLayer(this.OSMLayer);
            const isTreesLayerVisible = this.map.hasLayer(this.treesLayer);
            if (isTreesLayerVisible) this.updateOverlayInControlLayer(i18n.t("Arbres"), this.treesLayerNotClustered);
            const isFurnituresLayerVisible = this.map.hasLayer(this.furnituresLayer);
            if (isFurnituresLayerVisible) this.updateOverlayInControlLayer(i18n.t("Mobilier urbain"), this.furnituresLayerNotClustered);
            const initialMaxBounds = this.map.options.maxBounds;
            this.map.setMaxBounds(null);
            if (center) this.map.setView(center, this.map.getZoom(), { animate: false });
            if (!showSurroundings) this.map.removeLayer(this.surroundingsLayer);
            if (!avoidLegendUpdate) {
                if (isTreesLayerVisible) this.updateLegend(i18n.t("Arbres"), false);
                if (isFurnituresLayerVisible) this.updateLegend(i18n.t("Mobilier urbain"), false);
                if (this.map.hasLayer(this.markersLayer)) this.updateLegend("Repères", false);
                if (this.map.hasLayer(this.greenSpacesLayer)) this.updateLegend("Espaces verts", false);
            }

            await this.toggleReferences({
                trees: referencesToShow,
                greenSpaces: referencesToShow,
                furnitures: referencesToShow,
                markers: referencesToShow,
            }, true);

            const paperHeight = formats.paper.width ? formats.paper[isLandscape ? 'width' : 'height'] : 25;
            const paperWidth = formats.paper.width ? formats.paper[isLandscape ? 'height' : 'width'] : 36;
            exportScaledMap(this.map, scale, { overflowZoom, elements }).then(async ({ canvas, url, width, height }) => {
                if (!showSurroundings) this.map.addLayer(this.surroundingsLayer);
                this.map.setMaxBounds(initialMaxBounds);
                if (isTreesLayerVisible) this.updateOverlayInControlLayer(i18n.t("Arbres"), this.treesLayer);
                if (isFurnituresLayerVisible) this.updateOverlayInControlLayer(i18n.t("Mobilier urbain"), this.furnituresLayer);
                if (hiddenBaseLayer) {
                    this.map.removeLayer(this.OSMLayer);
                    this.map.addLayer(hiddenBaseLayer);
                }

                if (showScreenshotEditor) {
                    this.screenshotConfig = { formats, isLandscape, width, height, titles, paperHeight, paperWidth, scaleDisplay, showLegend };
                    this.setState({ imageToEdit: url }, () => this.changeModalContentType('ScreenshotEditor', i18n.t("Personnalisation de l'impression carte")));
                }

                if (!avoidLegendUpdate) {
                    if (isTreesLayerVisible) this.updateLegend(i18n.t("Arbres"), false);
                    if (isFurnituresLayerVisible) this.updateLegend(i18n.t("Mobilier urbain"), false);
                    if (this.map.hasLayer(this.markersLayer)) this.updateLegend("Repères", false);
                    if (this.map.hasLayer(this.greenSpacesLayer)) this.updateLegend("Espaces verts", false);
                }

                await this.toggleReferences(references);
                if (showLoader) this.showLoader(false);
                canvas.remove();
                resolve(url);
            });
        });
    }

    downloadScreenshot = (url) => {
        const { formats, isLandscape, width, height } = this.screenshotConfig;
        switch (formats.file) {
            case 'JPEG': download(url, 'carte.jpeg', 'image/jpeg'); break;
            case 'PNG': download(url, 'carte.png', 'image/png'); break;
            case 'PDF':
                const pdf = new jsPDF({
                    orientation: isLandscape ? 'landscape' : 'portrait',
                    unit: formats.paper.width ? 'cm' : 'px',
                    format: [formats.paper.width || width, formats.paper.height || height],
                    putOnlyUsedFonts: true
                });

                const ratio = width / pdf.internal.pageSize.getWidth();
                pdf.addImage(url, 'JPEG', 0, 0, width / ratio, height / ratio);
                pdf.save('carte.pdf');
                break;
            default: break;
        }

        this.setState({ modal: { visible: false }, modalContentType: '' });
    }

    toggleReferences = async (references, forceRender = false) => {
        const overlays = [...this.state.overlays];

        const getActiveButton = (label) => {
            return overlays
                .find(o => o.originalLabel === label)?.children
                .find(c => c.originalLabel === 'Références')?.buttons
                .find(b => b.isActive);
        };

        const renderOverlayReferences = async (type, referencesKey, method) => {
            const overlayButton = getActiveButton('Arbres'); // Remplacez 'Arbres' si nécessaire
            const referenceData = references ? references[referencesKey] : overlayButton?.name;
            const isShown = (references ? references[referencesKey] : overlayButton?.name) ? true : false;

            if (method === 'renderMarkersReferences') await this.renderMarkersReferences(type, referenceData, isShown, forceRender);
            else if (method === 'renderGreenSpacesReferences') this.renderGreenSpacesReferences(referenceData, isShown, forceRender);
        };

        await renderOverlayReferences('Arbre', 'trees', 'renderMarkersReferences');
        await renderOverlayReferences('Espaces verts', 'greenSpaces', 'renderGreenSpacesReferences');
        await renderOverlayReferences('Mobilier', 'furnitures', 'renderMarkersReferences');
        await renderOverlayReferences('Repère', 'markers', 'renderMarkersReferences');
    }

    renderMarkersReferences = (category, referencesType, areReferencesShowed, forceRender = false, showTip = false) => {
        return new Promise(async (resolve) => {
            if (!this.map?._loaded) {
                resolve();
                return;
            }

            const zoomLevel = this.map.getZoom();
            const showReferences = areReferencesShowed && zoomLevel >= 17;
            const bounds = this.map.getBounds(); // Stocke les limites pour éviter les appels répétés

            const categories = {
                'Arbre': { originalLabel: 'Arbres', layerContainerNotClustered: this.treesLayerNotClustered, layerContainer: this.treesLayer, type: 'trees' },
                'Mobilier': { originalLabel: 'Mobilier urbain', layerContainerNotClustered: this.furnituresLayerNotClustered, layerContainer: this.furnituresLayer, type: 'furnitures' },
                'Repère': { originalLabel: 'Repères', layerContainerNotClustered: this.markersLayer, layerContainer: null, type: 'markers' }
            };


            const { originalLabel, layerContainerNotClustered, layerContainer, type } = categories[category];
            const previousReferencesType = this.state.references[type];

            if (showTip && (areReferencesShowed || forceRender) && zoomLevel < 17)
                showToast('references_tip');

            if (!previousReferencesType && !referencesType && !areReferencesShowed) resolve();
            else if ((previousReferencesType && previousReferencesType !== referencesType) || !areReferencesShowed || (zoomLevel < 17 && !forceRender))
                this.referencesLayer.eachLayer((layer) => {
                    if (layer.category === category)
                        this.referencesLayer.removeLayer(layer);
                });
            else {
                const layersNotClustered = layerContainerNotClustered.getLayers();
                const layers = layerContainer ? layerContainer.getLayers() : null;

                const isMarkerInCluster = (marker) => layers && layers.some(layer => layer instanceof L.MarkerCluster && layer.getAllChildMarkers().includes(marker));
                const referencesMap = new Map();
                this.referencesLayer.eachLayer(refLayer => {
                    if (refLayer.category === category) {
                        if (!referencesMap.has(refLayer.elementId)) referencesMap.set(refLayer.elementId, [refLayer]);
                        else referencesMap.get(refLayer.elementId).push(refLayer);
                    }
                });

                let promises = [];
                for (let i = 0; i < layersNotClustered.length; i += 500) {
                    promises.push(new Promise(resolve => {
                        const batch = layersNotClustered.slice(i, i + 500); // Créer un batch de 500
                        batch.forEach(layer => {
                            const isInBounds = bounds.contains(layer.getLatLng());
                            const isInCluster = isMarkerInCluster(layer);
                            const referenceLayers = referencesMap.get(layer.feature.id);
                            const shouldAddReference = (forceRender || showReferences) && isInBounds && !isInCluster;
                            const referenceProperty = referencesType === 'projectReference'
                                ? layer.feature?.properties.projectReference
                                : referencesType === 'customReference'
                                    ? layer.feature?.properties.customReference
                                    : null;

                            if (referenceLayers) {
                                if (!forceRender && (!isInBounds || isInCluster)) {
                                    referenceLayers.forEach(referenceLayer => this.referencesLayer.removeLayer(referenceLayer));
                                    referencesMap.delete(layer.feature.id);
                                }
                            } else if (shouldAddReference && referenceProperty) TooltipsUtil.setMarkerTooltip(referenceProperty, layer, this.referencesLayer);
                            resolve();
                        });
                    }));
                }

                await Promise.all(promises);
            }

            // Mettre à jour l'état des overlays
            const overlays = [...this.state.overlays];
            const overlay = overlays.find(o => o.originalLabel === originalLabel);
            if (overlay?.children?.length) {
                const childOverlay = overlay.children.find(o => o.originalLabel === 'Références');
                if (childOverlay?.buttons?.length) {
                    childOverlay.buttons.forEach(b => b.isActive = (b.name === referencesType));
                    this.setState({ overlays });
                }
            }

            // Mettre à jour l'état des références
            this.setState(prevState => ({
                references: { ...prevState.references, [type]: referencesType }
            }), resolve);
        });
    }

    renderGreenSpacesReferences = (referencesType, areReferencesShowed, forceRender = false, showTip = false) => {
        if (!this.map?._loaded) return;
        const zoomLevel = this.map.getZoom();
        const showReferences = areReferencesShowed && zoomLevel >= 15;
        const previousReferencesType = this.state.references.greenSpaces;

        if (showTip && (areReferencesShowed || forceRender) && zoomLevel < 15)
            showToast('references_tip');

        if (!previousReferencesType && !referencesType && !areReferencesShowed) return;
        else if ((previousReferencesType && previousReferencesType !== referencesType) || !areReferencesShowed || (zoomLevel < 15 && !forceRender))
            this.referencesLayer.eachLayer((layer) => {
                if (layer.category === 'Espace vert')
                    this.referencesLayer.removeLayer(layer);
            });
        else {
            const bounds = this.map.getBounds();
            this.greenSpacesLayer.eachLayer((layer) => {
                if (forceRender || showReferences) {
                    let found = false;
                    const latLngs = layer.getLatLngs();
                    for (let i = 0; i < latLngs.length && !found; i++)
                        for (let j = 0; j < latLngs[i].length && !found; j++)
                            if (bounds.contains(latLngs[i][j])) found = true;

                    if (found) {
                        if (referencesType === 'projectReference' && layer.feature && layer.feature.properties.projectReference > 0)
                            TooltipsUtil.setGreenSpaceTooltip(layer.feature.properties.projectReference, layer, this.referencesLayer);
                        else if (referencesType === 'customReference' && layer.feature)
                            TooltipsUtil.setGreenSpaceTooltip(layer.feature.properties.customReference, layer, this.referencesLayer);
                    }
                }
            });
        }

        // Mettre à jour l'état des overlays
        const overlays = [...this.state.overlays];
        const overlay = overlays.find(o => o.originalLabel === 'Espaces verts');
        if (overlay?.children?.length) {
            const childOverlay = overlay.children.find(o => o.originalLabel === 'Références');
            if (childOverlay?.buttons?.length) {
                childOverlay.buttons.forEach(b => b.isActive = (b.name === referencesType));
                this.setState({ overlays });
            }
        }

        this.setState(prevState => ({ references: { ...prevState.references, greenSpaces: referencesType } }));
    }

    removeReference = (layer) => {
        if (!this.referencesLayer || !layer?.feature?.id) return;
        const referenceLayers = this.referencesLayer.getLayers();
        const referenceLayer = referenceLayers.find(referenceLayer => referenceLayer.elementId === layer.feature.id);
        if (referenceLayer) this.referencesLayer.removeLayer(referenceLayer);
    }

    // Historique des actions (déplacement, rotation, ...)
    confirmAction = () => {
        const { currentAction } = this.props;

        if (!['removing', 'addingBackgroundImage'].includes(currentAction)) {
            let features = [], featuresToAdd = [], featuresToRemove = [];
            for (let i = 0; i < this.actionHistory.index; i++) {
                for (let j = 0; j < this.actionHistory.history[i].length; j++) {
                    const modification = this.actionHistory.history[i][j];
                    if (modification.isAddition) {
                        const feature = this.getFormattedFeature(modification.layer);
                        modification.layer.feature = feature;
                        featuresToAdd.push(feature);
                    } else if (modification.isDeletion) featuresToRemove.push(this.getFormattedFeature(modification.layer))
                    else {
                        const feature = features.find(feature => feature.id === modification.oldFeature.id);
                        if (!feature) features.push(this.getFormattedFeature(modification.layer));
                    }
                }
            }
            features = features.filter(feature => !featuresToAdd.find(f => f.id === feature.id) && !featuresToRemove.find(f => f.id === feature.id));
            const featuresNotToSend = featuresToAdd.filter(feature => featuresToRemove.find(f => f.id === feature.id));
            featuresToAdd = featuresToAdd.filter(feature => !featuresNotToSend.find(f => f.id === feature.id));
            featuresToRemove = featuresToRemove.filter(feature => !featuresNotToSend.find(f => f.id === feature.id));

            let trees = [], greenSpaces = [], furnitures = [], markers = [], stations = [], backgroundImages = [];
            features.forEach(feature => {
                let layer;
                switch (feature.properties.category) {
                    case 'Arbre':
                        const linkedTrees = this.linkedTrees.find(layers => layers[0].feature.id === feature.id);
                        layer = this.treesLayerNotClustered.getLayers().find(layer => layer.feature.id === feature.id);
                        trees.push({ layer, feature, linkedMarkers: linkedTrees });
                        break;
                    case 'Espace vert':
                        layer = this.greenSpacesLayer.getLayers().find(layer => layer.feature.id === feature.id);
                        greenSpaces.push({ layer, feature });
                        break;
                    case 'Mobilier':
                        const linkedFurnitures = this.linkedFurnitures.find(layers => layers[0].feature.id === feature.id);
                        layer = this.furnituresLayerNotClustered.getLayers().find(layer => layer.feature.id === feature.id);
                        furnitures.push({ layer, feature, linkedMarkers: linkedFurnitures });
                        break;
                    case 'Repère':
                        layer = this.markersLayer.getLayers().find(layer => layer.feature.id === feature.id);
                        markers.push({ layer, feature });
                        break;
                    case 'Station':
                        layer = this.stationsLayer.getLayers().find(layer => layer.feature.id === feature.id);
                        stations.push({ layer, feature });
                        break;
                    case 'BackgroundImage':
                        layer = this.backgroundImagesLayer.getLayers().find(l => l.feature.id === feature.id);
                        backgroundImages.push({ layer, feature });
                        break;
                    default: break;
                }
            });

            // Arbres
            if (trees.length > 1) {
                const layers = trees.map(tree => tree.linkedMarkers);
                const features = trees.map(tree => tree.feature);
                UpdatesUtil.bulkUpdateTrees(layers, features, this.fieldList, this.props.project.id, this.treesLayer, currentAction, this.props.webSocketHubs, { thematicMaps: this.props.project.thematicMaps });
                if (currentAction === 'dragging') this.updateHeatmaps({ updateLatLngs: true });
            } else if (trees.length === 1) {
                UpdatesUtil.updateTree(trees[0].layer, trees[0].feature.properties, trees[0].linkedMarkers, false, this.fieldList, this.treesLayer,
                    currentAction, this.props.project.id, this.props.webSocketHubs, { thematicMaps: this.props.project.thematicMaps });
                if (currentAction === 'dragging') this.updateHeatmaps({ updateLatLngs: true });
            }

            // Espaces verts
            const prefix = !['merging', 'subtracting'].includes(currentAction) && (greenSpaces.length > 1 || (currentAction !== 'cutting'
                ? featuresToRemove.length > (currentAction === 'splitting' ? 1 : 0)
                : (greenSpaces.length + featuresToRemove.length > 1))
            ) ? 'greenspaces_' : 'greenspace_';
            const toastId = prefix + currentAction;
            const successId = prefix + (currentAction === 'cutting' ? 'cut'
                : currentAction === 'splitting' ? 'split'
                    : currentAction.replace('ing', 'ed'));
            const errorId = prefix + (['merging', 'updating'].includes(currentAction) ? currentAction.replace('ing', 'e')
                : ['cutting', 'splitting'].includes(currentAction) ? currentAction.substr(0, currentAction.length - 4)
                    : ['deleting', 'rotating', 'subtracting'].includes(currentAction) ? currentAction.replace('ing', 'ion')
                        : currentAction) + '_failed';

            const promises = [];
            if (greenSpaces.length > 1) {
                const layers = greenSpaces.map(greenSpace => (greenSpace.layer));
                const features = greenSpaces.map(greenSpace => (greenSpace.feature));
                promises.push(UpdatesUtil.bulkUpdateGreenSpaces(layers, features, this.fieldList, this.props.project.id, this.greenSpacesLayer.activeChild, currentAction, { webSocketHubs: this.props.webSocketHubs, showToast: false, thematicMaps: this.props.project.thematicMaps }));
            } else if (greenSpaces.length === 1) {
                const layers = greenSpaces.map(greenSpace => (greenSpace.layer));
                promises.push(UpdatesUtil.updateGreenSpace(greenSpaces[0].feature.id, greenSpaces[0].feature.properties, layers, false, this.fieldList, this.greenSpacesLayer.activeChild, currentAction, this.props.project.id, { webSocketHubs: this.props.webSocketHubs, showToast: false, thematicMaps: this.props.project.thematicMaps }));
            }
            // featuresToAdd reprend forcément que des EV pour l'instant
            const callback = (response) => {
                const addedPhotos = response?.addedFileInfos?.filter(fileInfo => fileInfo.type === 'photo');
                if (addedPhotos?.length > 0) {
                    addedPhotos.forEach(photo => photo.url = FileInfosUtil.getUrl(photo));
                    this.props.setPhotosGalleries([...this.props.photosGalleries, ...addedPhotos]);
                }
                const addedFiles = response?.addedFileInfos?.filter(fileInfo => fileInfo.type === 'file');
                if (addedFiles?.length > 0) {
                    addedFiles.forEach(file => file.url = FileInfosUtil.getUrl(file));
                    this.props.setFilesGalleries([...this.props.filesGalleries, ...addedFiles]);
                }
                if (response?.addedPae.length > 0) {
                    const projectActions = this.props.projectActions;
                    response.addedPae.forEach(pae => {
                        const projectAction = projectActions.find(pa => pa.id === pae.projectActionId);
                        if (projectAction) projectAction.projectActionElements.push(pae);
                    });
                    this.props.setProjectActions([...projectActions]);
                }
            };

            if (featuresToAdd.length > 1)
                promises.push(GreenSpacesService.addGreenSpaces(featuresToAdd, currentAction, this.props.webSocketHubs, false).then(callback));
            else if (featuresToAdd.length === 1)
                promises.push(GreenSpacesService.addGreenSpace(featuresToAdd[0], currentAction, this.props.webSocketHubs, false).then(callback));
            // featuresToRemove reprend forcément que des EV pour l'instant
            if (featuresToRemove.length > 0) {
                if (navigator.onLine)
                    showLoadingToast(toastId, successId, errorId, (
                        new Promise(resolve => {
                            Promise.all(promises).then(() => GreenSpacesService.removeGreenSpaces(featuresToRemove, this.props.webSocketHubs, false).then(resolve))
                        })
                    ));
                else showToast('will_sync');
            } else if (featuresToAdd.length > 0 || greenSpaces.length > 0) {
                if (navigator.onLine) showLoadingToast(toastId, successId, errorId, Promise.all(promises));
                else showToast('will_sync');
            }

            for (let i = 0; i < this.actionHistory.index; i++) {
                for (let j = 0; j < this.actionHistory.history[i].length; j++) {
                    const modification = this.actionHistory.history[i][j];
                    if (modification.isAddition && modification.layer.feature.id !== modification.layer.feature.properties.greenSpaceId)
                        modification.layer.feature.properties.greenSpaceId = modification.layer.feature.id;
                }
            }

            if (greenSpaces.length > 0) { // Au cas où l'utilisateur visualise une carte thématique sur la surface
                const thematicMap = this.props.project.thematicMaps?.find(thematicMap => thematicMap.id === this.greenSpacesLayer.activeChild);
                if (thematicMap?.property === 'surface') this.updateLegend(i18n.t("Espaces verts"));
            }

            // Mobilier urbain
            if (furnitures.length > 1) {
                const layers = furnitures.map(furniture => furniture.linkedMarkers);
                const features = furnitures.map(furniture => furniture.feature);
                UpdatesUtil.bulkUpdateFurnitures(layers, features, this.fieldList, this.props.project.id, this.furnituresLayer.activeChild, currentAction, this.props.webSocketHubs, { thematicMaps: this.props.project.thematicMaps });
            } else if (furnitures.length === 1) {
                UpdatesUtil.updateFurniture(furnitures[0].layer, furnitures[0].feature.properties, furnitures[0].linkedMarkers, false, this.fieldList, this.furnituresLayer.activeChild,
                    currentAction, this.props.project.id, this.props.webSocketHubs, { thematicMaps: this.props.project.thematicMaps });
            }

            // Repères
            if (markers.length > 1) {
                const layers = markers.map(marker => (marker.layer));
                const features = markers.map(marker => (marker.feature));
                UpdatesUtil.bulkUpdateMarkers(layers, features, this.fieldList, this.props.project.id, currentAction, { webSocketHubs: this.props.webSocketHubs });
            } else if (markers.length === 1) {
                const layers = markers.map(marker => (marker.layer));
                UpdatesUtil.updateMarker(markers[0].layer, markers[0].feature.properties, layers, this.fieldList, currentAction, this.props.project.id, { webSocketHubs: this.props.webSocketHubs });
            }

            // Stations
            if (stations.length > 1) {
                const layers = stations.map(station => (station.layer));
                const features = stations.map(station => (station.feature));
                UpdatesUtil.bulkUpdateStations(layers, features, this.props.project.id, currentAction, { webSocketHubs: this.props.webSocketHubs });
            } else if (stations.length === 1) {
                const layers = stations.map(station => (station.layer));
                UpdatesUtil.updateStation(stations[0].feature.id, stations[0].feature.properties, layers, currentAction, this.props.project.id, { webSocketHubs: this.props.webSocketHubs });
            }

            // Background images
            if (backgroundImages.length > 1) {
                const layers = backgroundImages.map(backgroundImage => (backgroundImage.layer));
                const features = backgroundImages.map(backgroundImage => (backgroundImage.feature));
                UpdatesUtil.bulkUpdateBackgroundImages(layers, features, this.props.project.id, this.props.webSocketHubs);
            } else if (backgroundImages.length === 1) {
                const layers = backgroundImages.map(backgroundImage => (backgroundImage.layer));
                UpdatesUtil.updateBackgroundImage(backgroundImages[0].feature.id, backgroundImages[0].feature.properties, layers, this.props.project.id, this.props.webSocketHubs);
            }

            if (features.length)
                WebSocketUtil.toggleElementsLock(this.props.webSocketHubs, this.props.project.id, [...new Set(features.map(f => f.id))], false, jwtDecode(new Cookies().get('token')).id);
            this.resetActionHistory(false);
        }
        else if (currentAction.includes('removing')) this.showRemoveForm();
        else if (currentAction === 'addingBackgroundImage') {
            let bgImgToAdd = this.backgroundImagesLayer.getLayers().find(l => !l.feature.properties.url);

            bgImgToAdd.feature.geometry.coordinates = GeometriesUtil.convertBackgroundImageLatLngsToCoordinates(bgImgToAdd.getCorners());
            this.toggleBackgroundImages(false);
            this.exitSubTool(true, false);
            this.resetActionHistory(false);

            fetch(bgImgToAdd._url).then(r => {
                r.blob().then(blobFile => {
                    const file = new File([blobFile], 'backgroundImage', { type: blobFile.type });
                    const formData = new FormData();
                    formData.append('backgroundImage', JSON.stringify(bgImgToAdd.feature));
                    formData.append('Image', file);

                    BackgroundImagesService.addBackgroundImage(formData, this.props.webSocketHubs).then(() => {
                        const blobInfos = AppSettings.getBlobInfos();
                        let url = `${blobInfos.endpoint}${blobInfos.containers.backgroundImages}/${bgImgToAdd.feature.properties.name}`;
                        bgImgToAdd.feature.properties.url = url;
                        WebSocketUtil.sendElements(this.props.webSocketHubs, this.props.project.id, [bgImgToAdd.feature]);
                    });
                });
            });
        }
    }

    undoAction = (shouldPush = true) => {
        let { index, history } = this.actionHistory;
        const previousModification = history[index - 1];

        if (previousModification) {
            let modificationsToCreate = [], previousFeaturesId = [];
            previousModification.forEach(modification => {
                const { layer, oldFeature, isAddition, isDeletion } = modification;
                previousFeaturesId.push(oldFeature.id);
                modificationsToCreate.push({ layer: layer, oldFeature: this.getFormattedFeature(layer), isAddition, isDeletion });
                if (this.props.currentAction === 'removing') this.toggleElementsHighlight([layer]);
                else if (isAddition) {
                    this.greenSpacesLayer.removeLayer(layer);
                    const referenceLayer = this.referencesLayer.getLayers().find(l => l.elementId === layer.feature.id);
                    if (referenceLayer) this.referencesLayer.removeLayer(referenceLayer);
                }
                else if (isDeletion) {
                    this.greenSpacesLayer.addLayer(layer);
                    if (this.state.references.greenSpaces)
                        TooltipsUtil.setGreenSpaceTooltip(layer.feature.properties[this.state.references.greenSpaces], layer, this.referencesLayer);
                }
                else this.resetElementToFeature(oldFeature);
            });

            if (this.props.currentAction === 'splitting' && previousModification.some(modification => modification.isAddition)) {
                this.hideDrawLines();
                this.showDrawLines(true);
            }

            if (shouldPush && index === history.length) history.push(modificationsToCreate);
            else {
                let actualFeaturesId = [];
                (history[index] || [])
                    .filter(modification => !modification.isAddition && !modification.isDeletion)
                    .forEach(modification => actualFeaturesId.push(modification.oldFeature.id));
                if (shouldPush && JSON.stringify(previousFeaturesId) !== JSON.stringify(actualFeaturesId))
                    history[index] = modificationsToCreate;
            }

            this.actionHistory.history = history;
            this.actionHistory.index--;
        }

        this.checkHistoryButtons();
    }

    redoAction = () => {
        let { index, history } = this.actionHistory;
        const nextModification = history[index + 1];

        if (nextModification) {
            let modificationsToCreate = [], nextFeaturesId = [];
            nextModification.forEach(modification => {
                const { layer, oldFeature, isAddition, isDeletion } = modification;
                nextFeaturesId.push(oldFeature.id);
                modificationsToCreate.push({ layer: layer, oldFeature: this.getFormattedFeature(layer), isAddition, isDeletion });
                if (this.props.currentAction === 'removing') this.toggleElementsHighlight([layer]);
                else if (isAddition) {
                    this.greenSpacesLayer.addLayer(layer);
                    if (this.state.references.greenSpaces)
                        TooltipsUtil.setGreenSpaceTooltip(layer.feature.properties[this.state.references.greenSpaces], layer, this.referencesLayer);
                }
                else if (isDeletion) {
                    this.greenSpacesLayer.removeLayer(layer);
                    const referenceLayer = this.referencesLayer.getLayers().find(l => l.elementId === layer.feature.id);
                    if (referenceLayer) this.referencesLayer.removeLayer(referenceLayer);
                }
                else this.resetElementToFeature(oldFeature);
            });

            if (this.props.currentAction === 'splitting' && nextModification.some(modification => modification.isAddition)) {
                this.hideDrawLines();
                this.showDrawLines(true);
            }

            let actualFeaturesId = [];
            history[index]
                .filter(modification => !modification.isAddition && !modification.isDeletion)
                .forEach(modification => actualFeaturesId.push(modification.oldFeature.id));

            if (JSON.stringify(nextFeaturesId) !== JSON.stringify(actualFeaturesId)) history[index] = modificationsToCreate;
            if (index === history.length - 2) history = history.slice(0, history.length - 1);

            this.actionHistory.history = history;
            this.actionHistory.index++;
        }

        this.checkHistoryButtons();
    }

    getFormattedFeature = (layer, vertexes = {}) => {
        const { addedVertex, removedVertex } = vertexes;
        // On récupère l'état à sauvegarder
        let feature, coords;
        const category = layer.feature.properties.category;
        if (['Espace vert', 'Station'].includes(category)) {
            let latLngs = JSON.parse(JSON.stringify(layer.getLatLngs()));
            if (removedVertex) latLngs[removedVertex.indexPath[0]].splice(removedVertex.indexPath[1], 0, removedVertex.latLng);
            coords = GeometriesUtil.convertPolygonLatLngsToCoordinates(latLngs);
        } else coords = ['Arbre', 'Mobilier', 'Repère'].includes(category)
            ? [layer.getLatLng().lng, layer.getLatLng().lat]
            : GeometriesUtil.convertBackgroundImageLatLngsToCoordinates(layer._corners);

        if (addedVertex) // On retire le sommet ajouté avant de stocker la feature dans l'historique (pour ne pas créer de sommets inutiles)
            for (let i = 0; i < coords.length; i++)
                coords[i] = coords[i].filter(coord => JSON.stringify(coord) !== JSON.stringify(addedVertex))

        feature = JSON.parse(JSON.stringify(layer.feature));
        feature.geometry.coordinates = coords;

        return feature;
    }

    pushActionHistory = (layers, vertexes) => {
        let modificationsToCreate = [], users = [], elementsToLock = [];
        layers.forEach(({ layer, isAddition, isDeletion }) => {
            if (!ProjectsUtil.isElementLocked(this.props.lockedElements, layer.feature, this.props.project, users)) {
                elementsToLock.push(layer);
                const oldFeature = this.getFormattedFeature(layer, vertexes);
                modificationsToCreate.push({ layer, oldFeature, isAddition, isDeletion });
            }
        });

        if (modificationsToCreate.length) {
            let { index, history } = this.actionHistory;
            history = history.slice(0, index);
            history.push(modificationsToCreate);

            this.actionHistory.history = history;
            this.actionHistory.index++;

            this.checkHistoryButtons();
        }

        if (elementsToLock.length)
            WebSocketUtil.toggleElementsLock(this.props.webSocketHubs, this.props.project.id, elementsToLock.map(se => se.feature.id), true, jwtDecode(new Cookies().get('token')).id);
    }

    cancelLastAction = () => {
        this.undoAction(false);
        this.actionHistory.history.pop();
        this.checkHistoryButtons();
    }

    resetActionHistory = (restoreElements = true) => {
        // Reset lié à la rotation stickée
        this.stickyRotateStartLatLngs = null;
        this.setState({ angle: 0 });
        if (restoreElements) { // Restore chaque élément altéré à son état initial (contenu dans l'historique)
            let features = [];
            const { history, index } = this.actionHistory;

            for (let i = index - 1; i >= 0; i--) {
                history[i].forEach(modification => {
                    if (this.props.currentAction === 'removing') this.toggleElementsHighlight([modification.layer]);
                    else if (modification.isAddition) {
                        this.greenSpacesLayer.removeLayer(modification.layer);
                        const referenceLayer = this.referencesLayer.getLayers().find(l => l.elementId === modification.layer.feature.id);
                        if (referenceLayer) this.referencesLayer.removeLayer(referenceLayer);
                    }
                    else if (modification.isDeletion) {
                        this.greenSpacesLayer.addLayer(modification.layer);
                        if (this.state.references.greenSpaces)
                            TooltipsUtil.setGreenSpaceTooltip(modification.layer.feature.properties[this.state.references.greenSpaces], modification.layer, this.referencesLayer);
                    }
                    else {
                        const featureIndex = features.findIndex(feature => feature.id === modification.oldFeature.id);
                        if (featureIndex !== -1) features[featureIndex] = modification.oldFeature;
                        else features.push(modification.oldFeature);
                    }
                });
            }

            features.forEach(feature => this.resetElementToFeature(feature));
            if (this.shouldUpdateBackgroundImagesLayer) this.updateBackgroundImagesLayer(null, true, false);
            if (features.length)
                WebSocketUtil.toggleElementsLock(this.props.webSocketHubs, this.props.project.id, [...new Set(features.map(f => f.id))], false, jwtDecode(new Cookies().get('token')).id);
        }

        // Suppression de l'image
        if (this.props.currentAction === 'addingBackgroundImage' && restoreElements) {
            let bgImgs = this.backgroundImagesLayer.getLayers();
            const layer = bgImgs.find(l => !l.feature.properties.url?.length);
            this.backgroundImagesLayer.removeLayer(layer);
            this.actionHistory = { index: 0, history: [] };
        }

        this.actionHistory = { index: 0, history: [] };
        this.checkHistoryButtons();
        if (this.state.highlightedElements?.length > 0 && !this.viewState) {
            this.toggleElementsHighlight(this.state.highlightedElements);
            this.setState({ highlightedElements: null });
        }
    }

    resetElementToFeature = (feature) => {
        switch (feature.properties.category) {
            case 'Arbre': case 'Mobilier': case 'Repère':
                const layerContainers = feature.properties.category === 'Arbre'
                    ? [this.treesLayer, this.treesLayerNotClustered]
                    : feature.properties.category === 'Mobilier'
                        ? [this.furnituresLayer, this.furnituresLayerNotClustered]
                        : [this.markersLayer];
                layerContainers.forEach(layerContainer => {
                    const layer = layerContainer.getLayers().find(layer => layer.feature?.id === feature.id);
                    if (layer) {
                        layer.feature = feature;
                        const latLng = { lat: feature.geometry.coordinates[1], lng: feature.geometry.coordinates[0] };
                        // setLatLng met à jour directement sur la carte mais retire temporairement l'élément du layer parent si il n'est pas visible
                        if (this.map.hasLayer(layer)) layer.setLatLng(latLng);
                        else layer._latlng = latLng; // On utilise donc ._latlng (màj prise en compte seulement lors de l'update de la carte) pour les layers non visibles

                        const referenceLayer = this.referencesLayer.getLayers().find(l => l.elementId === layer.feature.id);
                        if (referenceLayer) referenceLayer.setLatLng(layer.getLatLng());
                    }
                });
                break;
            case 'Espace vert': case 'Station':
                const layerContainer = feature.properties.category === 'Espace vert' ? this.greenSpacesLayer : this.stationsLayer;
                layerContainer.eachLayer(layer => {
                    if (layer.feature?.id === feature.id) {
                        layer.feature = feature;
                        layer.setLatLngs(GeometriesUtil.convertPolygonCoordinatesToLatLngs(feature.geometry.coordinates));
                        if (layer.drawLine) layer.drawLine.setLatLngs(GeometriesUtil.convertLineCoordinatesToLatLngs(feature.properties.baseLine.coordinates));

                        // Reset des helpLayers
                        if (this.props.currentAction === 'rotating' && this.rotatingLayer) {
                            this.rotatingLayer.pm.disableRotate();
                            this.rotatingLayer = null;
                        } else if (this.props.currentAction === 'editing') {
                            if (['LineForm', 'OffsetForm'].includes(this.state.modalContentType)) {
                                this.setState({ modalContentType: '' });
                                this.props.setLayer(null);
                            }
                            if (this.editingLayer) {
                                this.editingLayer.pm.disable();
                                this.editingLayer = null;
                            }
                        }

                        const referenceLayer = this.referencesLayer.getLayers().find(l => l.elementId === layer.feature.id);
                        if (referenceLayer) referenceLayer.setLatLng(layer.getCenter());
                    }
                });
                break;
            case 'BackgroundImage':
                let bgImg = this.backgroundImagesLayer.getLayers().find(bgImg => bgImg.feature.id === feature.id);
                if (bgImg) {
                    this.shouldUpdateBackgroundImagesLayer = this.props.currentAction === 'orderingBackgroundImages';
                    bgImg.setCorners(feature.geometry.coordinates.map(coords => (L.latLng(coords[1], coords[0]))));
                    bgImg.getElement().style.opacity = `${feature.properties.opacity}%`;
                    bgImg.feature = feature;
                    const backgroundImageToEdit = this.state.backgroundImageToEdit;
                    this.setState({ backgroundImageToEdit: null }, () => this.setState({ backgroundImageToEdit }));
                }
                break;
            default: break;
        }
    }

    checkHistoryButtons = () => {
        const { index, history } = this.actionHistory;

        const buttonsToDisable = [], buttonsToEnable = [];
        if (history.length < 1) buttonsToDisable.push('cancel');
        else buttonsToEnable.push('cancel');
        if (index < 1) buttonsToDisable.push('undo');
        else buttonsToEnable.push('undo');
        if (index === history.length) buttonsToDisable.push('redo');
        else buttonsToEnable.push('redo');
        if (index < 1) buttonsToDisable.push('confirm');
        else buttonsToEnable.push('confirm');

        if (this.toolbarRef.current) {
            this.toolbarRef.current.setButtonsIsDisabled(buttonsToDisable, true);
            this.toolbarRef.current.setButtonsIsDisabled(buttonsToEnable, false);
        }
    }

    handleGroupDrag = (draggedLayer) => {
        const draggedLayerWithLatLngs = this.draggedLayers.find(layerWithLatLngs => layerWithLatLngs.layer === draggedLayer);

        const startLatLng = draggedLayerWithLatLngs.center || draggedLayerWithLatLngs.latLng;
        const startCoordinates = point([startLatLng.lng, startLatLng.lat]);
        const finalLatLng = draggedLayer.getLatLng ? draggedLayer.getLatLng() : draggedLayer.getCenter();
        const finalCoordinates = point([finalLatLng.lng, finalLatLng.lat]);
        const bearing = rhumbBearing(startCoordinates, finalCoordinates);
        const distance = rhumbDistance(startCoordinates, finalCoordinates);

        this.draggedLayers.forEach(layerWithLatLngs => {
            const { layer, latLng, latLngs } = layerWithLatLngs;
            if (draggedLayer !== layer) {
                if (latLng) {
                    const newCoordinates = transformTranslate(point([latLng.lng, latLng.lat]), distance, bearing)?.geometry.coordinates;
                    layer.setLatLng({ lat: newCoordinates[1], lng: newCoordinates[0] });
                } else if (latLngs) {
                    const newCoordinates = transformTranslate(polygon(GeometriesUtil.convertPolygonLatLngsToCoordinates(latLngs)), distance, bearing)?.geometry.coordinates;
                    layer.setLatLngs(GeometriesUtil.convertPolygonCoordinatesToLatLngs(newCoordinates));
                }
            }
        });
    }

    handleDragend = () => {
        this.clickBeforeDragend = false;
        if (this.draggedLayers.every(layerWithLatLngs => this.checkIfInsideSurroundings(layerWithLatLngs.latLng ? 'marker' : 'polygon', layerWithLatLngs.layer))) {
            this.draggedLayers.forEach(layerWithLatLngs => {
                if (layerWithLatLngs.latLngs) { // Si c'est un espace vert
                    const initialCenter = layerWithLatLngs.center;
                    const finalCenter = layerWithLatLngs.layer.getCenter();
                    const lngDiff = finalCenter.lng - initialCenter.lng;
                    const latDiff = finalCenter.lat - initialCenter.lat;

                    if (layerWithLatLngs.layer.feature.properties.baseLine) {
                        const baseLineCoords = layerWithLatLngs.layer.feature.properties.baseLine.coordinates;
                        for (let i = 0; i < baseLineCoords.length; i++) {
                            baseLineCoords[i][0] = baseLineCoords[i][0] + lngDiff;
                            baseLineCoords[i][1] = baseLineCoords[i][1] + latDiff;
                        }
                    }
                }
            });
        } else {
            showToast('element_drag_not_allowed');
            this.cancelLastAction();
        }
    }

    selectElements = (elements = [], reset = false, { originModal = null } = {}) => {
        if (originModal) {
            if (originModal === 'ProjectHistory') {
                this.props.setCurrentAction('elementsSelection');
                this.props.setButtonState('history', 1);
                this.viewState = true;
                this.toolbarRef.current.setButtonsIsDisabled(['table', 'statistics', 'actions', 'events'], true);
            }
            this.toolbarRef.current.setButtonsIsDisabled(['filters'], true);
            this.setState({ modal: { visible: false }, modalContentType: '' });
        }

        const userId = jwtDecode(new Cookies().get('token')).id;
        let users = !['', 'copyPasting'].includes(this.props.currentAction) ? null : [], elementsToLock = [];
        let selectedElements = [...this.state.selectedElements];
        if (reset) {
            this.unselectElements();
            selectedElements = [];
        }

        const categories = {
            'Arbre': { getHighlightStyle: (layer) => StylesUtil.getTreeHighlightStyle(this.treesLayer, this.fieldList, layer.feature.properties), layerNotClustered: this.treesLayerNotClustered.getLayers(), layerClustered: this.treesLayer.getLayers() },
            'Espace vert': { getHighlightStyle: () => StylesUtil.getGreenSpaceHighlightStyle() },
            'Mobilier': { getHighlightStyle: () => StylesUtil.getFurnitureHighlightStyle(this.fieldList), layerNotClustered: this.furnituresLayerNotClustered.getLayers(), layerClustered: this.furnituresLayer.getLayers() },
            'Repère': { getHighlightStyle: () => StylesUtil.getMarkerHighlightStyle(this.fieldList) },
            'Station': { getHighlightStyle: () => StylesUtil.getStationHighlightStyle() }
        };

        const selectElement = (element) => {
            const category = element.feature.properties.category;
            const style = categories[category]?.getHighlightStyle(element);
            if (style) {
                if (category === 'Arbre' || category === 'Mobilier') {
                    let clusteredLayer = categories[category].layerClustered.find(layer => layer.feature.id === element.feature.id);
                    let notClusteredLayer = categories[category].layerNotClustered.find(layer => layer.feature.id === element.feature.id);
                    clusteredLayer.setStyle(style);
                    notClusteredLayer.setStyle(style);
                } else element.setStyle(style);
            } else element.getElement().classList.add('highlightedBackgroundImage');
        }

        elements.forEach(element => {
            if (element.feature) {
                const category = element.feature.properties.category;
                if (!selectedElements.find(x => x._leaflet_id === element._leaflet_id) && !ProjectsUtil.isElementLocked(this.props.lockedElements, element.feature, this.props.project, users)
                    && (category !== 'BackgroundImage' || (category === 'BackgroundImage' && this.toolbarRef.current.state.buttons.imageTools.children.showImages.state))) {
                    elementsToLock.push(element.feature.id);
                    selectElement(element);
                    selectedElements.push(element);
                }
            }
        });

        if (elementsToLock.length)
            WebSocketUtil.toggleElementsLock(this.props.webSocketHubs, this.props.project.id, [...new Set(elementsToLock)], true, userId);
        this.setState({ selectedElements }, () => {
            if (this.props.currentAction === 'copyPasting')
                this.useSelectionAsCopy(
                    this.toolbarRef.current.state.selectedButtons[0].name === 'treeTools' ? 'Arbre'
                        : this.toolbarRef.current.state.selectedButtons[0].name === 'greenSpaceTools' ? 'Espace vert'
                            : 'Mobilier'
                );
        });
    }

    unselectElements = (elements) => {
        const categories = {
            'Arbre': {
                getStyle: (layer) => StylesUtil.getTreeActiveStyle(this.treesLayer, this.fieldList, layer.feature.properties, this.props.project.thematicMaps),
                layerClustered: this.treesLayer, layerNotClustered: this.treesLayerNotClustered
            },
            'Espace vert': { getStyle: (layer) => StylesUtil.getGreenSpaceActiveStyle(this.greenSpacesLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps) },
            'Mobilier': {
                getStyle: (layer) => StylesUtil.getFurnitureActiveStyle(this.furnituresLayer.activeChild, this.fieldList, layer.feature.properties, this.props.project.thematicMaps),
                layerClustered: this.furnituresLayer, layerNotClustered: this.furnituresLayerNotClustered
            },
            'Repère': { getStyle: () => StylesUtil.getMarkerActiveStyle(this.fieldList) },
            'Station': { getStyle: () => StylesUtil.getStationActiveStyle() },
        };

        return new Promise(resolve => {
            let selectedElements = this.state.selectedElements;
            const elementsToUnselect = elements || this.state.selectedElements;
            if (!elementsToUnselect.length) resolve();

            const unselectElement = (element, category) => {
                if (category === 'BackgroundImage')
                    element.getElement().classList.remove('highlightedBackgroundImage'); // On rétablit son style
                else if (category === 'Arbre' || category === 'Mobilier') {
                    const style = categories[category].getStyle(element);
                    let clusteredElement = categories[category].layerClustered.getLayers().find(layer => layer.feature.id === element.feature.id);
                    let notClusteredElement = categories[category].layerNotClustered.getLayers().find(layer => layer.feature.id === element.feature.id);
                    clusteredElement.setStyle(style);
                    notClusteredElement.setStyle(style);
                } else {
                    const style = categories[category].getStyle(element);
                    element.setStyle(style);
                }
            }

            // Reset du style & events
            elementsToUnselect.forEach(element => {
                const category = element.feature.properties.category;
                unselectElement(element, category);
            });

            if (elementsToUnselect.length) WebSocketUtil.toggleElementsLock(this.props.webSocketHubs, this.props.project.id, elementsToUnselect.map(etu => etu.feature.id), false, jwtDecode(new Cookies().get('token')).id);
            this.setState({ selectedElements: selectedElements.filter(x => !elementsToUnselect.includes(x)) }, resolve);
        });
    }

    useSelectionAsCopy = (type) => {
        const selectedElements = this.state.selectedElements.filter(l => l.feature.properties.category === type);
        if (selectedElements.length) {
            this.shouldCopy = true;
            this.props.setLayer(selectedElements);
            this.map.pm.enableDraw('Marker');
            this.toolbarRef.current.setButtonsIsDisabled(['lasso'], true);
            this.toolbarRef.current.setButtonsIsDisabled(['reset'], false);
            selectedElements.forEach(selectedElement => {
                let layer;
                const coordinates = selectedElement.feature.geometry.coordinates;
                if (['Arbre', 'Mobilier'].includes(type))
                    layer = L.circleMarker(
                        { lat: coordinates[1], lng: coordinates[0] },
                        { ...(type === 'Arbre' ? StylesUtil.getTreeHelpStyle() : StylesUtil.getFurnitureHelpStyle()), pmIgnore: true, snapIgnore: true }
                    );
                else layer = L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(coordinates), StylesUtil.getGreenSpaceHelpStyle());
                layer.feature = { ...selectedElement.feature, id: uuidv4() }
                this.map.addLayer(layer);
                this.helpLayers.push(layer);
            });
        } else this.toolbarRef.current.setButtonsIsDisabled(['reset'], true);
    }

    resetSelectionAsCopy = (unselectElements = false) => {
        if (!this.helpLayers.length) return;
        this.helpLayers.forEach(helpLayer => this.map.removeLayer(helpLayer));
        this.helpLayers = [];
        this.toolbarRef.current.setButtonsIsDisabled(['lasso', 'reset'], true);
        if (unselectElements) this.unselectElements();
        this.disableDrawing(false);
        this.props.setCurrentAction('copyPasting');
    }

    updateHelpLayersPosition = (cursorLatlng) => {
        if (!this.helpLayers.length) return;
        const type = this.helpLayers[0].feature.properties.category;
        let elements = [];
        if (['Arbre', 'Mobilier'].includes(type))
            elements = this.helpLayers.map(x => {
                const latLng = x.getLatLng();
                let p = point([latLng.lng, latLng.lat]);
                p = { ...JSON.parse(JSON.stringify(x.feature)), geometry: p.geometry };
                return p;
            });
        else elements = this.helpLayers.map(x => {
            let p = polygon(GeometriesUtil.convertPolygonLatLngsToCoordinates(x.getLatLngs()));
            p = { ...JSON.parse(JSON.stringify(x.feature)), geometry: p.geometry };
            return p;
        });
        const startCenter = centerOfMass(featureCollection(elements));
        const finalCenter = point([cursorLatlng.lng, cursorLatlng.lat]);
        const bearing = rhumbBearing(startCenter, finalCenter); // bearing angle
        const distance = rhumbDistance(startCenter, finalCenter); // bearing distance
        for (const element of elements) {
            let translatedElement = transformTranslate(element, distance, bearing);
            translatedElement = { ...JSON.parse(JSON.stringify(element)), geometry: translatedElement.geometry };
            let helpLayer = this.helpLayers.find(x => x.feature.id === translatedElement.id);
            if (['Arbre', 'Mobilier'].includes(type)) helpLayer.setLatLng(JSON.parse(JSON.stringify(translatedElement.geometry.coordinates)).reverse());
            else helpLayer.setLatLngs(GeometriesUtil.convertPolygonCoordinatesToLatLngs(translatedElement.geometry.coordinates));
            if (!this.checkIfInsideSurroundings(translatedElement.geometry.type.toLowerCase(), helpLayer)) helpLayer.setStyle({ fillColor: 'var(--red-100)', color: 'var(--red-120)' });
            else helpLayer.setStyle(type === 'Arbre' ? StylesUtil.getTreeHelpStyle() : type === 'Espace vert' ? StylesUtil.getGreenSpaceHelpStyle() : StylesUtil.getFurnitureHelpStyle());
        }
    }

    compareProjectWith = (project) => {
        if (project.path?.length) {
            const rootBaseProjectId = project.path.split('/')[1];
            const rootProject = this.props.projects.find(p => p.id === Number(rootBaseProjectId));
            if (rootProject) {
                project = JSON.parse(JSON.stringify(project));
                project.userBaseProjects = [...(project.userBaseProjects || []), ...(rootProject.userBaseProjects || [])]

            }
        }

        this.setState({
            projectToCompareWith: project,
            modal: { visible: true, title: 'Statistiques' },
            modalContentType: 'ProjectDetailComparison'
        });
    }

    renderProjectLabels = () => {
        if (!this.props.project?.projectLabels?.length) return;
        const { projectLabels } = this.props.project;

        const THRESHOLD_DISTANCE = 0.1; // 100 mètres (en km)
        const distanceBetweenCoordinates = (coord1, coord2) =>
            distance(point(coord1), point(coord2), { units: 'kilometers' });

        this.groupedProjectLabels = [];
        projectLabels.forEach(({ coordinates, label }) => {
            const groupFound = this.groupedProjectLabels.some(group => {
                if (distanceBetweenCoordinates(group.coordinates, coordinates) < THRESHOLD_DISTANCE) {
                    group.labels.push(label);
                    return true;
                }
                return false;
            });

            if (!groupFound) this.groupedProjectLabels.push({ coordinates, labels: [label] });
        });

        // Création et ajout des tooltips
        this.groupedProjectLabels = this.groupedProjectLabels.map(group => {
            const tooltip = L.tooltip({
                permanent: true,
                direction: "center",
                className: "tooltip-project-label"
            })
                .setContent(group.labels.join(", "))
                .setLatLng(group.coordinates)
                .addTo(this.map);

            return tooltip;
        });
    };


    toggleProjectLabels = () => {
        if (this.groupedProjectLabels !== null) {
            this.groupedProjectLabels.forEach(projectLabel => this.map.removeLayer(projectLabel));
            this.groupedProjectLabels = null;
        } else this.renderProjectLabels();
    }
}

const mapStateToProps = (state) => {
    return {
        events: state.events,
        projectEvents: state.projectEvents,
        activeOrganization: state.activeOrganization,
        essences: state.essences,
        treePorts: state.treePorts,
        coverTypes: state.coverTypes,
        vigors: state.vigors,
        healthReviews: state.healthReviews,
        ontogenicStages: state.ontogenicStages,
        risks: state.risks,
        tippingRisks: state.tippingRisks,
        organCalibers: state.organCalibers,
        targets: state.targets,
        plantationTypes: state.plantationTypes,
        plantationCoefficients: state.plantationCoefficients,
        situationCoefficients: state.situationCoefficients,
        patrimonialCoefficients: state.patrimonialCoefficients,
        spaceFunctions: state.spaceFunctions,
        spaceTypes: state.spaceTypes,
        dominantCompositions: state.dominantCompositions,
        managementClasses: state.managementClasses,
        runoffCoefficients: state.runoffCoefficients,
        conditions: state.conditions,
        furnitureTypes: state.furnitureTypes,
        interactions: state.interactions,
        microHabitats: state.microHabitats,
        rootSymptoms: state.rootSymptoms,
        collarSymptoms: state.collarSymptoms,
        trunkSymptoms: state.trunkSymptoms,
        branchSymptoms: state.branchSymptoms,
        leafSymptoms: state.leafSymptoms,
        pathogens: state.pathogens,
        pests: state.pests,
        epiphytes: state.epiphytes,
        editedProperties: state.editedProperties,
        layer: state.layer,
        currentAction: state.currentAction,
        project: state.project,
        projects: state.projects,
        projectCollaborators: state.projectCollaborators,
        rights: state.rights,
        isOnline: state.isOnline,
        elementHistory: state.elementHistory,
        isDarkTheme: state.isDarkTheme,
        actions: state.actions,
        projectActions: state.projectActions,
        tutorialTour: state.tutorialTour,
        formulas: state.formulas,
        webSocketHubs: state.webSocketHubs,
        buttonStates: state.buttonStates,
        customFields: state.customFields,
        organizationCustomFields: state.organizationCustomFields,
        projectsCustomFields: state.projectsCustomFields,
        lockedElements: state.lockedElements,
        projectListState: state.projectListState,
        userProjects: state.userProjects,
        formulasResults: state.formulasResults,
        filesGalleries: state.filesGalleries,
        photosGalleries: state.photosGalleries,
        projectShortcut: state.projectShortcut,
        isToolbarExpanded: state.isToolbarExpanded,
        tableState: state.tableState,
        loginAsData: state.loginAsData,
        exitFormWithChanges: state.exitFormWithChanges,
        viewProjectAsData: state.viewProjectAsData
    };
};

const mapDispatchToProps = {
    setProjectActions,
    setProjectEvents,
    setEditedProperties,
    setLayer,
    setCurrentAction,
    setProject,
    setProjects,
    setRights,
    setElementHistory,
    setActionHistory,
    setRequest,
    setFilterFormState,
    setTableState,
    setPhotosGalleries,
    setFilesGalleries,
    setButtonState,
    setProjectCustomFields,
    setLockedElements,
    setProjectListState,
    setCurrentFolderState,
    setUserProjects,
    setProjectCollaborators,
    setFormulasResults,
    setProjectShortcut,
    setProjectInvitations,
    setRedirectURL,
    setViewProjectAsData
};

export default withOrientationChange(connect(mapStateToProps, mapDispatchToProps)(ProjectMap));