import React, { Component } from "react";
import { PublicClientApplication } from "@azure/msal-browser";

// Configuration MSAL
const msalConfig = {
  auth: {
    clientId: "3fc890c4-fdf7-4475-aff3-36a592a78798", // Remplace par ton Client ID
    authority: "https://login.microsoftonline.com/", // Remplace par ton domaine ou Tenant ID
    redirectUri: "http://localhost:3000/login", // L'URL de redirection après l'authentification
  }
};

class AzureADLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      accessToken: "",
      error: ""
    };
    // Initialiser MSAL dans le constructeur (on peut initialiser dans componentDidMount aussi)
    this.msalInstance = new PublicClientApplication(msalConfig);
  }

  async componentDidMount() {
    // Initialisation de MSAL avant d'utiliser ses méthodes
    try {
      await this.msalInstance.initialize(); // Initialiser MSAL de façon asynchrone
      this.checkAuthentication(); // Vérifie l'authentification une fois MSAL initialisé
    } catch (error) {
      console.error("MSAL Initialization failed", error);
      this.setState({ error: "Erreur lors de l'initialisation de MSAL" });
    }
  }

  // Vérifier si l'utilisateur est déjà authentifié (via le token)
  checkAuthentication = () => {
    const account = this.msalInstance.getAllAccounts()[0];
    if (account) {
      this.setState({ isAuthenticated: true });
    }
  };

  // Fonction de connexion
  login = async () => {
    try {
      const loginResponse = await this.msalInstance.loginPopup({
        scopes: ["openid", "profile", "User.Read"]
      });
      console.log("Login successful:", loginResponse);
      this.setState({
        isAuthenticated: true,
        accessToken: loginResponse.accessToken
      });
    } catch (error) {
      console.error("Login failed:", error);
      this.setState({ error: "Authentication failed" });
    }
  };

  // Fonction de déconnexion
  logout = () => {
    this.msalInstance.logoutPopup().then(() => {
      this.setState({ isAuthenticated: false, accessToken: "" });
      localStorage.removeItem("accessToken");
    }).catch((error) => {
      console.error("Logout failed:", error);
    });
  };

  render() {
    return (
      <div>
        {!this.state.isAuthenticated ? (
          <div>
            <h2>Se connecter avec Azure AD</h2>
            <button onClick={this.login}>Se connecter</button>
            {this.state.error && <p style={{ color: "red" }}>{this.state.error}</p>}
          </div>
        ) : (
          <div>
            <h2>Bienvenue !</h2>
            <p>Vous êtes authentifié. Voici votre token d'accès :</p>
            <textarea
              rows="6"
              cols="50"
              value={this.state.accessToken}
              readOnly
            ></textarea>
            <br />
            <button onClick={this.logout}>Se déconnecter</button>
          </div>
        )}
      </div>
    );
  }
}

export default AzureADLogin;
