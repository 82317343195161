import React, { Component } from 'react';
// Composants
import { Form, Header, Select, Table } from 'semantic-ui-react';
import MapPreview from '../../../Utils/MapPreview';
// Librairies
import L from 'leaflet';
import { connect } from 'react-redux';
import i18n from '../../../../locales/i18n';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
// Utils
import FormattersUtil from '../../../../utils/FormattersUtil';
import { faCheck, faQuestionCircle, faTimes, faWarning } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GeometriesUtil from '../../../../utils/GeometriesUtil';
import StylesUtil from '../../../../utils/StylesUtil';
import { isMobile, isMobileOnly, withOrientationChange } from 'react-device-detect';
import { polygon, multiPolygon } from '@turf/turf';
import GeoJsonUtil from '../../../../utils/GeoJsonUtil';
import ProjectsUtil from '../../../../utils/ProjectsUtil';

class ImportProjection extends Component {
    state = {
        status: 0,
        projectedSample: [],
        surroundings: null
    };

    render() {
        const { config, project, projectionList, isLandscape } = this.props;
        const { projectionId } = config;
        const { projectedSample, surroundings } = this.state;
        const statusInfos = this.getStatusInfos();

        return (
            <>
                <div className='modal-content-body' style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                        <Header as='h3' style={{ margin: '0px' }}>{i18n.t("Projection des coordonnées")}</Header>
                        {!isMobileOnly &&
                            <>
                                <p style={{ margin: '0px' }}>{i18n.t("Cet outil vous permet de convertir des coordonnées depuis un système de coordonnées vers le WGS84.")}</p>
                                <p style={{ margin: '0px' }}>{i18n.t("Ci-dessous vous pourrez sélectionner une des projections disponibles.")}</p>
                            </>}
                    </div>
                    <div style={{ display: 'flex', flexDirection: isMobile && !isLandscape ? 'column' : 'row', gap: (!isMobile || isLandscape) && '30px', flex: 1, marginTop: '15px', overflow: 'hidden' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: (!isMobile || isLandscape) && 1 }}>
                            <Form loading={this.props.isLoading} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Field
                                    name='projectionId' label={`${i18n.t("Projection source")} :`} placeholder={i18n.t("Sélectionnez une projection")}
                                    value={projectionId} style={{ marginBottom: '10px' }}
                                    search={FormattersUtil.searchList} control={Select} selectOnBlur={false} options={projectionList} onChange={this.handleProjectionChange}
                                />
                            </Form>
                            {(!isMobile || isLandscape) && <>{!config.sample.length ? 'Aucune donnée trouvée.' : this.renderSample()}</>}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <div style={{ display: isMobileOnly && isLandscape && 'flex', gap: isMobileOnly && isLandscape && '10px' }}>
                                <h3 style={{ marginBottom: '5px' }}>{i18n.t("Résultat")}</h3>
                                <div style={{ display: 'flex', alignItems: 'center', padding: '10px', borderRadius: '10px', marginBottom: '10px', ...statusInfos.style }}>
                                    <FontAwesomeIcon icon={statusInfos.icon} size={isMobileOnly && isLandscape ? '1x' : '2x'} style={{ marginRight: ((isMobileOnly && !isLandscape) || !isMobileOnly) && '10px' }} />{((isMobileOnly && !isLandscape) || !isMobileOnly) && statusInfos.message}
                                </div>
                            </div>
                            <div style={{ flex: 1 }}>
                                {surroundings && <MapPreview
                                    id={project?.id} style={{ height: '100%', width: '100%', borderRadius: '10px' }}
                                    elementStyle={{ tree: StylesUtil.getTreeStyle(), furniture: StylesUtil.getFurnitureStyle(), greenSpace: StylesUtil.getGreenSpaceStyle(), marker: StylesUtil.getMarkerStyle(), station: StylesUtil.getStationStyle() }}
                                    surroundings={surroundings} features={projectedSample}
                                    dragging={true} zooming={true} noMaxBounds={true} zoomOnFeatures={true}
                                />}
                            </div>
                            <small style={{ marginTop: '5px' }}>{i18n.t("Seul un échantillon de l'ensemble des données a été repris.")}</small>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    componentDidMount = () => {
        const { config } = this.props;

        let projectionId = config.projectionId;
        if (!projectionId) projectionId = this.validProjectionId() || this.props.project.projectionId || 1;
        else this.validProjectionId(projectionId);

        this.props.handleConfigChange(null, { name: 'projectionId', value: projectionId });
        this.setState({ projectedSample: this.getProjectedSample(projectionId) });
    }

    componentDidUpdate = () => {
        const { userProjects, project, stations } = this.props;
        const { surroundings } = this.state;

        if (!surroundings && project && stations) {
            const userProject = userProjects.find(up => up.userId === jwtDecode(new Cookies().get('token')).id);
            const isGeographicallyLimited = userProject.projectRole?.projectRoleStations?.length > 0 || userProject.projectRole?.areas?.length > 0;
            const surroundings = isGeographicallyLimited
                ? multiPolygon([
                    ...(userProject.projectRole?.projectRoleStations || []).map(prs => stations.find(station => station.id === prs.stationId)?.geometry.coordinates),
                    ...(userProject.projectRole?.areas || [])
                ])
                : JSON.parse(project.surroundings);
            this.setState({ surroundings });
        }
    }

    renderSample = () => {
        const { category, config } = this.props;
        const isMarker = ['trees', 'furnitures', 'markers'].includes(category);

        return (
            <Table inverted selectable columns={4} style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', marginTop: 0 }}>
                <Table.Header>
                    {isMarker &&
                        <Table.Row style={{ display: 'flex', textAlign: 'center' }}>
                            <Table.HeaderCell width={2} style={{ flex: 1 }}>{i18n.t("Coordonnées initiales")}</Table.HeaderCell>
                            <Table.HeaderCell width={2} style={{ flex: 1 }}>{i18n.t("Coordonnées projetées (WGS84)")}</Table.HeaderCell>
                        </Table.Row>}
                    <Table.Row style={{ display: 'flex', textAlign: 'center' }}>
                        {isMarker && <Table.HeaderCell style={{ flex: 1 }}>{i18n.t("Latitude (y)")}</Table.HeaderCell>}
                        <Table.HeaderCell style={{ flex: 1 }}>{isMarker ? i18n.t("Longitude (x)") : i18n.t("Coordonnées initiales")}</Table.HeaderCell>
                        <Table.HeaderCell style={{ flex: 1 }}>{isMarker ? i18n.t("Latitude (y)") : i18n.t("Coordonnées projetées (WGS84)")}</Table.HeaderCell>
                        {isMarker && <Table.HeaderCell style={{ flex: 1 }}>{i18n.t("Longitude (x)")}</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
                <Table.Body style={{ overflow: 'auto' }}>
                    {config.sample.map((element, index) => {
                        const coordinates = element.geometry.coordinates;
                        const projectedCoordinates = this.state.projectedSample.find(projectedElement => projectedElement.id === element.id)?.geometry.coordinates;

                        return (
                            <Table.Row key={index} style={{ display: 'flex', textAlign: 'center' }}>
                                {isMarker
                                    ? <>
                                        <Table.Cell style={{ flex: 1 }}>{coordinates[1].toFixed(5)}</Table.Cell>
                                        <Table.Cell style={{ flex: 1 }}>{coordinates[0].toFixed(5)}</Table.Cell>
                                        <Table.Cell style={{ flex: 1 }}>{projectedCoordinates && projectedCoordinates[1].toFixed(5)}</Table.Cell>
                                        <Table.Cell style={{ flex: 1 }}>{projectedCoordinates && projectedCoordinates[0].toFixed(5)}</Table.Cell>
                                    </>
                                    : <>
                                        <Table.Cell style={{ flex: 1 }}>{JSON.stringify(coordinates)}</Table.Cell>
                                        <Table.Cell style={{ flex: 1 }}>{JSON.stringify(projectedCoordinates)}</Table.Cell>
                                    </>}
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        );
    }

    getStatusInfos = () => {
        switch (this.state.status) {
            case 0: return { style: { backgroundColor: 'var(--grey-80)' }, icon: faQuestionCircle, message: i18n.t("Veuillez sélectionner une projection"), allowNextStep: false };
            case 1: return { style: { backgroundColor: 'var(--green-100)' }, icon: faCheck, message: i18n.t("Cela semble être la bonne projection"), allowNextStep: true };
            case 2: return { style: { backgroundColor: 'var(--orange-80)' }, icon: faWarning, message: i18n.t("Les données semblent être en dehors de votre projet"), allowNextStep: true };
            case 3: return { style: { backgroundColor: 'var(--red-80)' }, icon: faTimes, message: i18n.t("Impossible de placer les données sur la carte"), allowNextStep: false };
            case 4: return { style: { backgroundColor: 'var(--red-80)' }, icon: faTimes, message: i18n.t("L'échantillon contient des coordonnées invalides"), allowNextStep: false };
            default: break;
        }
    }

    handleProjectionChange = (_, e) => {
        const { name, value } = e;
        this.validProjectionId(value);
        this.props.handleConfigChange(null, { name, value });
        this.setState({ projectedSample: this.getProjectedSample(value) });
    }

    handleStatusChange = (status) => {
        this.setState({ status }, () => this.props.handleConfigChange(null, { name: 'isProjectionValid', value: status === 1 || status === 2 }));
    }

    getProjectedSample = (projectionId) => {
        const { config, projections } = this.props;
        const projection = projections.find(p => p.id === projectionId);
        const projectedSample = [];
        if (projection)
            config.sample
                .forEach(element => {
                    let projectedElement = JSON.parse(JSON.stringify(element));
                    const { type, coordinates } = element.geometry;
                    const isMarker = ['marker', 'point'].includes(type.toLowerCase());
                    projectedElement.geometry.coordinates = isMarker ? GeometriesUtil.projectPoint(coordinates, projection) : GeometriesUtil.projectPolygon(coordinates, projection);
                    projectedSample.push(projectedElement);
                });

        if (projectedSample.filter(e => !e.geometry.coordinates.flatMap(c => c)?.length).length) {
            this.setState({ status: 4 });
            this.props.handleConfigChange(null, { name: 'isSampleValid', value: false });
        }
        else this.props.handleConfigChange(null, { name: 'isSampleValid', value: true });

        return projectedSample;
    }

    validProjectionId = (initialProjectionId = 0) => {
        const surroundingsToRender = JSON.parse(this.props.project.surroundings);
        const surroundingsPolygon = surroundingsToRender.geometry.type === 'Polygon'
            ? polygon(surroundingsToRender.geometry.coordinates)
            : multiPolygon(surroundingsToRender.geometry.coordinates);
        const geoJson = GeoJsonUtil.generateGeoJson(GeometriesUtil.invertPolygon(surroundingsPolygon));
        const surroundingsLayer = L.geoJson(geoJson, { pmIgnore: true });

        let status = 0;
        let projectionId = 0, projectionIdStatus2 = 0;
        let found = false;

        const validSample = this.props.config.sample.filter(e => e.geometry.coordinates.flatMap(c => c)?.length);
        for (const projection of this.props.projections.filter(p => !initialProjectionId || p.id === initialProjectionId)) {
            for (const element of validSample) {
                const { type, coordinates } = element.geometry;
                const isMarker = ['marker', 'point'].includes(type.toLowerCase());
                const projectedCoordinates = isMarker ? GeometriesUtil.projectPoint(coordinates, projection) : GeometriesUtil.projectPolygon(coordinates, projection);
                const layer = isMarker ? L.circleMarker({ lat: projectedCoordinates[1], lng: projectedCoordinates[0] }) : L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(projectedCoordinates));
                const isWGS84 = isMarker ? GeometriesUtil.isLatLngWGS84(projectedCoordinates) : GeometriesUtil.areCoordinatesWGS84(projectedCoordinates);
                if (!isWGS84) {
                    projectionId = projection.id;
                    status = 3;
                }
                else if (ProjectsUtil.checkIfInsideSurroundings(type.toLowerCase(), layer, surroundingsLayer, surroundingsToRender)) {
                    projectionId = projection.id;
                    status = 1;
                    found = true;
                    break;
                } else {
                    projectionId = projection.id;
                    if (!projectionIdStatus2) projectionIdStatus2 = projectionId;
                    status = 2;
                }

                if (initialProjectionId) {
                    found = true;
                    break;
                }
            }
            if (found) break;
        }

        this.handleStatusChange(status);
        return found ? projectionId : projectionIdStatus2 || this.props.config.projectionId || this.props.project.projectionId;
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        projections: state.projections,
        userProjects: state.userProjects
    };
};

export default withOrientationChange(connect(mapStateToProps)(ImportProjection));