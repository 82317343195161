import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';
// Utils
import OfflineUtil from '../utils/OfflineUtil';
import { showLoadingToast, showToast } from '../utils/ToastsUtil';
import WebSocketUtil from '../utils/WebSocketUtil';
import i18n from '../locales/i18n';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class MarkersService {
    static addMarker(marker, action, webSocketHubs) {
        marker.id = uuidv4();
        marker.properties.markerId = marker.id;
        const promise = Axios.post(BACKEND_ENDPOINT + 'markers/' + webSocketHubs?.elementsHub?.connectionId, marker, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            marker.properties = response.data.properties;
            return response;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            return Promise.reject();
        });

        const toastId = 'marker_' + action;
        const successId = 'marker_' + action.replace('ing', 'ed');
        const errorId = 'marker_adding_failed';
        if (navigator.onLine) showLoadingToast(toastId, successId, errorId, promise);
        else showToast('will_sync');
        return promise;
    }

    static getMarkers(projectId, data) {
        return Axios.post(BACKEND_ENDPOINT + 'markers/projects/' + projectId, data, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('markers_loading_failed');
            return;
        });
    }

    static removeMarkers(markers, webSocketHubs) {
        const promise = Axios.delete(BACKEND_ENDPOINT + 'markers/' + webSocketHubs?.elementsHub?.connectionId, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` },
            data: markers
        }).then(response => {
            return response;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            return Promise.reject();
        });

        const prefixe = markers.length > 1 ? 'markers' : 'marker';
        if (navigator.onLine) showLoadingToast(prefixe + '_deleting', prefixe + '_deleted', prefixe + '_deletion_failed', promise);
        else showToast('will_sync');
        return promise;
    }

    static updateMarker(id, marker, action, webSocketHubs) {
        const promise = Axios.put(BACKEND_ENDPOINT + 'markers/?connectionId=' + webSocketHubs?.elementsHub?.connectionId + '&id=' + id, marker, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            marker.properties = { ...marker.properties, ...response.data.properties };
            return response;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            return Promise.reject();
        });

        const toastId = 'marker_' + action;
        const successId = 'marker_' + action.replace('ing', 'ed');
        const errorId = 'marker_' + (action === 'updating' ? 'update' : action) + '_failed';
        if (navigator.onLine) showLoadingToast(toastId, successId, errorId, promise);
        else showToast('will_sync');
        return promise;
    }

    static bulkUpdateMarkers(markers, action, webSocketHubs) {
        const promise = Axios.put(BACKEND_ENDPOINT + 'markers/bulk/' + webSocketHubs?.elementsHub?.connectionId, markers, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            if (Array.isArray(response?.data)) {
                response.data.forEach(feature => {
                    let marker = markers.find(marker => marker.id === feature.id);
                    if (marker) marker.properties = { ...marker.properties, ...feature.properties };
                });
            }
            return response;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            return Promise.reject();
        });

        const toastId = 'markers_' + action;
        const successId = 'markers_' + action.replace('ing', 'ed');
        const errorId = 'markers_' + (action === 'updating' ? 'update' : action) + '_failed';
        if (navigator.onLine) showLoadingToast(toastId, successId, errorId, promise);
        else showToast('will_sync');
        return promise;
    }

    static exportMarkersFromProjectAsExcel(name, id, projection) {
        return Axios.post(BACKEND_ENDPOINT + 'markers/exportXLSX/' + id, { projection }, {
            responseType: 'blob',
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${id} - ${name} (${i18n.t("Repères").toLowerCase()}).xlsx`);
            document.getElementById('modal-content').appendChild(link);
            link.click();
            document.getElementById('modal-content').removeChild(link);
        }, () => {
            if (navigator.onLine) showToast('elements_xlsx_export_failed');
        });
    }

    static exportMarkerAsPDF(marker, image, photosId, colorless, toastCustomTexts) {
        return Axios.post(BACKEND_ENDPOINT + 'markers/exportPDF/' + marker.id, { image, photosId, colorless }, {
            responseType: 'arraybuffer',
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`,
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            let filename = marker.properties.projectReference ? `${i18n.t("Fiche repère")}_${marker.properties.projectReference}` : i18n.t("Fiche repère");
            if (marker.properties.customReference) filename += '_' + marker.properties.customReference;
            filename += '.pdf';
            link.setAttribute('download', filename);
            document.getElementById('tasksStatus').appendChild(link);
            link.click();
            document.getElementById('tasksStatus').removeChild(link);
            showToast('pdf_export_completed', ...toastCustomTexts);
        }, () => {
            if (navigator.onLine) showToast('elements_pdf_export_failed');
        });
    }

    static exportMarkersAsPDF(projectId, elementIds, { projection, colorless = false, addPhotos = true } = {}) {
        return Axios.post(BACKEND_ENDPOINT + 'markers/exportAllPDF/' + projectId, { elementIds, projection, colorless, addPhotos }, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response;
        }, () => {
            if (navigator.onLine) showToast('elements_pdf_export_failed');
            return;
        });
    }

    static exportMarkersAsSHP(projectLabel, projectId, projection) {
        return Axios.post(BACKEND_ENDPOINT + 'markers/exportSHP/' + projectId, { projection }, {
            responseType: 'blob',
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            if (response.data?.size > 0) {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${projectId} - ${projectLabel} (${i18n.t("Repères").toLowerCase()}).zip`);
                document.getElementById('modal-content').appendChild(link);
                link.click();
                document.getElementById('modal-content').removeChild(link);
            }
        }, () => {
            if (navigator.onLine) showToast('elements_shp_export_failed');
        });
    }
}
