import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import Cookies from 'universal-cookie';
// Utils
import { showToast } from '../utils/ToastsUtil';
import OfflineUtil from '../utils/OfflineUtil';
import ActionsUtil from '../utils/ActionsUtil';
import i18n from '../locales/i18n';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class ActionsService {
    static addProjectAction(newAction, projectId) {
        newAction.actionId = newAction.action.id;
        return Axios.post(BACKEND_ENDPOINT + 'actions/', newAction, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            const action = {
                ...response.data,
                projectActionElements: response.data?.projectActionElements || []
            };

            return OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'actions/projectActions/' + projectId, (body) => {
                body.push(action);
                return body;
            }).then(() => {
                if (navigator.onLine) showToast('action_added');
                else showToast('will_sync');
                return action;
            });
        }, () => {
            if (navigator.onLine) showToast('connection_failed');
            return;
        });
    }

    static getProjectActions(projectId) {
        return Axios.get(BACKEND_ENDPOINT + 'actions/projectActions/' + projectId, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('action_loading_failed');
            return;
        });
    }

    static getActions() {
        return Axios.get(BACKEND_ENDPOINT + 'actions/', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('action_loading_failed');
            return;
        });
    }

    static removeProjectAction(id, projectId) {
        OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'actions/projectActions/' + projectId, (body) => {
            return body.filter(projectAction => projectAction.id !== id);
        });

        return Axios.delete(BACKEND_ENDPOINT + 'actions/' + id, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('action_deleted');
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('action_deletion_failed');
            else showToast('will_sync');
            return;
        });
    }

    static updateProjectAction(projectAction, projectId, { paeToAdd = [], paeToRemove = [], successToast = 'event_updated', errorToast = 'event_update_failed' } = {}) {
        return Axios.put(BACKEND_ENDPOINT + 'actions/', { projectAction, paeToAdd, paeToRemove }, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            const pa = response.data?.projectAction;
            if (!response.data.history) // Si retour du Service Worker
                pa.projectActionElements = [...pa.projectActionElements.filter(pae => !paeToRemove.find(p => p.elementId === pae.elementId)), ...(response.data.paeToAdd || [])];

            return OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'actions/projectActions/' + projectId, (body) => {
                const index = body.findIndex(projectAction => projectAction.id === pa.id);
                if (index !== -1) body[index] = pa;
                else body.push(pa);
                return body;
            }).then(() => {
                if (navigator.onLine) showToast(successToast);
                else if (!navigator.onLine) showToast('will_sync');
                return { projectAction: pa, history: response.data.history };
            });
        }, () => {
            if (navigator.onLine) showToast(errorToast);
            else showToast('will_sync');
            return;
        });
    }

    static updateRecurrences(projectId, paId, projectActionElementRecurrences) {
        OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'actions/projectActions/' + projectId, (body) => {
            const projectAction = body.find(projectAction => projectAction.id === paId);
            const recurrences = projectAction?.projectActionElements?.flatMap(pae => pae.projectActionElementRecurrences);
            if (recurrences)
                projectActionElementRecurrences.forEach(recurrence => {
                    const recurrenceInCache = recurrences.find(r => r.id === recurrence.id);
                    if (recurrenceInCache) {
                        recurrenceInCache.status = recurrence.status;
                        recurrenceInCache.comment = recurrence.comment;
                        recurrenceInCache.validationDate = recurrence.validationDate;
                    }
                });
            return body;
        });

        return Axios.put(BACKEND_ENDPOINT + 'actions/updateRecurrences/?projectId=' + projectId + '&projectActionId=' + paId, projectActionElementRecurrences, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('action_updated');
            return response.data;
        }, () => {
            if (navigator.onLine) {
                showToast('connection_failed');
                return;
            } else {
                showToast('will_sync');
                return;
            }
        });
    }

    static exportActionAsPDF(projectAction, image) {
        return Axios.post(BACKEND_ENDPOINT + 'actions/exportPDF/' + projectAction.id, { image: image, recurrenceInfos: ActionsUtil.getRecurrencesInfos(projectAction) }, {
            responseType: 'arraybuffer',
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`,
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            const filename = 'Fiche action_' + projectAction.action.label + '.pdf';
            link.setAttribute('download', filename);
            document.getElementById('tasksStatus').appendChild(link);
            link.click();
            document.getElementById('tasksStatus').removeChild(link);
            showToast('pdf_export_completed', 'action', `"${projectAction.action.label}"`);
        }, () => {
            if (navigator.onLine) showToast('action_pdf_export_failed');
        });
    }

    static exportBacklogAsPDF(data, toastCustomTexts) {
        return Axios.post(BACKEND_ENDPOINT + 'actions/exportPDF/backlog/', data, {
            responseType: 'arraybuffer',
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`,
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            const filename = data.groupedBy
                ? `Fiche actions_${data.startDate}_${data.endDate}.pdf`.replaceAll('/', '-')
                : `${data.reference}_Actions.pdf`;
            link.setAttribute('download', filename);
            document.getElementById('tasksStatus').appendChild(link);
            link.click();
            document.getElementById('tasksStatus').removeChild(link);
            showToast('backlog_pdf_export_completed', ...toastCustomTexts);
        }, () => {
            if (navigator.onLine) showToast('backlog_pdf_export_failed', ...toastCustomTexts);
        });
    }

    static exportBacklogAsXLSX(baseProject, rows, groupedBy) {
        return Axios.post(BACKEND_ENDPOINT + 'actions/exportXLSX/backlog/', { baseProjectId: baseProject.id, rows, groupedBy }, {
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${baseProject.label} - ${i18n.t("Actions")}.xlsx`);
            document.getElementById('modal-content').appendChild(link);
            link.click();
            document.getElementById('modal-content').removeChild(link);
        }, () => {
            if (navigator.onLine) showToast('elements_xlsx_export_failed');
        });
    }

    static addAction(action) {
        return Axios.post(BACKEND_ENDPOINT + 'admin/actions/', action, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('action_added');
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('action_addition_failed');
            return;
        });
    }

    static updateActions(actions) {
        return Axios.put(BACKEND_ENDPOINT + 'admin/actions/', actions, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('actions_updated');
            return response.status;
        }, () => {
            if (navigator.onLine) showToast('actions_update_failed');
            return;
        });
    }

    static deleteActions(ids) {
        return Axios.delete(BACKEND_ENDPOINT + 'admin/actions/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` },
            data: { ids: ids }
        }).then(response => {
            return response.status;
        }, () => {
            showToast(ids.length > 1 ? 'actions_deletion_failed' : 'action_deletion_failed');
            return;
        });
    }

    static getActionHistory(elementId) {
        return Axios.get(BACKEND_ENDPOINT + 'actions/history/?elementId=' + elementId, {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) if (navigator.onLine) showToast('element_history_loading_failed');
            return;
        });
    }
}