import React, { Component } from 'react';
// Composants
import { Form, Button, Segment, Grid, Divider, Select, Message, Input } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { isMobile } from 'react-device-detect';
import i18n from '../../../locales/i18n';
import { faFile, faFileExcel, faFilePdf, faImage, faTablePicnic, faFlowerTulip, faTree, faDownload, faLocationQuestion } from '@fortawesome/pro-solid-svg-icons';
import { faHexagon } from '@fortawesome/pro-regular-svg-icons';
import { connect } from 'react-redux';
import { setProjects, setUserProjects } from '../../../actionCreators/projectsActions';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
// Services
import TreesService from '../../../services/TreesService';
import GreenSpacesService from '../../../services/GreenSpacesService';
import FilesService from '../../../services/FilesService';
import FurnituresService from '../../../services/FurnituresService';
import StationsService from '../../../services/StationsService';
import MarkersService from '../../../services/MarkersService';
// Utils
import FormattersUtil from '../../../utils/FormattersUtil';
import RightsUtil from '../../../utils/RightsUtil';
import WmsService from '../../../services/WmsService';
import AppSettings from '../../../AppSettings';
import { showToast } from '../../../utils/ToastsUtil';
import ProjectsUtil from '../../../utils/ProjectsUtil';

const initialState = {
    isChecked: false,
    isDownloaded: false,
    trees: false,
    greenSpaces: false,
    furnitures: false,
    markers: false,
    stations: false,
    wfsUrl: null,
    isWfsLoading: false
}

const initialDownloadState = {
    excel: { ...initialState, projectionId: 1 },
    shp: { ...initialState, projectionId: 1 },
    pdfs: { ...initialState, projectionId: 1, colorless: false, addPhotos: true },
    photos: initialState
};

class ExportForm extends Component {
    state = {
        isLoading: false,
        numberOfCheckedFields: 0,
        ...initialDownloadState
    }

    render() {
        const { isLoading, excel, shp, photos, pdfs, wfsUrl, isWfsLoading } = this.state;
        const projections = this.props.projections.map(x => ({ text: x.label, value: x.id, title: x.title, description: x.description }));
        const canDownload = !((!excel.isChecked && !shp.isChecked && !photos.isChecked && !pdfs.isChecked)
            || (excel.isChecked && !excel.trees && !excel.greenSpaces && !excel.furnitures && !excel.markers && !excel.stations)
            || (shp.isChecked && !shp.trees && !shp.greenSpaces && !shp.furnitures && !shp.markers && !shp.stations)
            || (photos.isChecked && !photos.trees && !photos.greenSpaces && !photos.furnitures)
            || (pdfs.isChecked && !pdfs.trees && !pdfs.greenSpaces && !pdfs.furnitures && !pdfs.markers));
        const userProject = this.props.userProjects?.find(up => up.userId === jwtDecode(new Cookies().get('token')).id);
        const projectRole = userProject?.projectRole;

        return (
            <div className='modal-content'>
                <div className='modal-content-body'>
                    <Grid>
                        <Grid.Row style={{ paddingBottom: 0 }}>
                            <Grid.Column computer={4} tablet={8} mobile={16} style={!isMobile ? { paddingBottom: '10px' } : null} stretched>
                                <Segment style={isMobile ? { marginTop: '15px' } : null}>
                                    <h3><FontAwesomeIcon icon={faFileExcel} style={{ marginRight: '10px' }} /> {i18n.t("Données Excel")}</h3>
                                    <Divider />
                                    <div style={{ marginBottom: '5px' }}>{i18n.t("Types d'éléments")} :</div>
                                    {RightsUtil.canExport(projectRole?.trees) && this.renderButton(faTree, excel.trees, i18n.t("Arbres"), isLoading, () => this.handleChange('excel', 'trees'))}
                                    {RightsUtil.canExport(projectRole?.greenSpaces) && this.renderButton(faFlowerTulip, excel.greenSpaces, i18n.t("Espaces verts"), isLoading, () => this.handleChange('excel', 'greenSpaces'))}
                                    {RightsUtil.canExport(projectRole?.furnitures) && this.renderButton(faTablePicnic, excel.furnitures, i18n.t("Mobilier urbain"), isLoading, () => this.handleChange('excel', 'furnitures'))}
                                    {RightsUtil.canExport(projectRole?.markers) && this.renderButton(faLocationQuestion, excel.markers, i18n.t("Repères"), isLoading, () => this.handleChange('excel', 'markers'))}
                                    {RightsUtil.canExport(projectRole?.stations) && this.renderButton(faHexagon, excel.stations, i18n.t("Stations"), isLoading, () => this.handleChange('excel', 'stations'))}
                                    <div style={{ marginTop: '10px', marginBottom: '5px' }}>{i18n.t("Projection")} :</div>
                                    <Form.Field
                                        name='projectionId' control={Select} fluid placeholder={i18n.t("Sélectionnez une projection")}
                                        value={excel.projectionId || ''} options={projections} search={FormattersUtil.searchList} selectOnBlur={false}
                                        disabled={isLoading} onChange={(_, { value }) => this.handleChange('excel', 'projectionId', value)}
                                    />
                                </Segment>
                            </Grid.Column>
                            <Grid.Column computer={4} tablet={8} mobile={16} style={!isMobile ? { paddingBottom: '10px' } : null} stretched>
                                <Segment style={isMobile ? { marginTop: '15px' } : null}>
                                    <h3><FontAwesomeIcon icon={faFile} style={{ marginRight: '10px' }} /> {i18n.t("Données ShapeFiles")}</h3>
                                    <Divider />
                                    <div style={{ marginBottom: '5px' }}>{i18n.t("Types d'éléments")} :</div>
                                    {RightsUtil.canExport(projectRole?.trees) && this.renderButton(faTree, shp.trees, i18n.t("Arbres"), isLoading, () => this.handleChange('shp', 'trees'))}
                                    {RightsUtil.canExport(projectRole?.greenSpaces) && this.renderButton(faFlowerTulip, shp.greenSpaces, i18n.t("Espaces verts"), isLoading, () => this.handleChange('shp', 'greenSpaces'))}
                                    {RightsUtil.canExport(projectRole?.furnitures) && this.renderButton(faTablePicnic, shp.furnitures, i18n.t("Mobilier urbain"), isLoading, () => this.handleChange('shp', 'furnitures'))}
                                    {RightsUtil.canExport(projectRole?.markers) && this.renderButton(faLocationQuestion, shp.markers, i18n.t("Repères"), isLoading, () => this.handleChange('shp', 'markers'))}
                                    {RightsUtil.canExport(projectRole?.stations) && this.renderButton(faHexagon, shp.stations, i18n.t("Stations"), isLoading, () => this.handleChange('shp', 'stations'))}
                                    <div style={{ marginTop: '10px', marginBottom: '5px' }}>{i18n.t("Projection")} :</div>
                                    <Form.Field
                                        name='projectionId' control={Select} fluid placeholder={i18n.t("Sélectionnez une projection")}
                                        value={shp.projectionId || ''} options={projections} search={FormattersUtil.searchList} selectOnBlur={false}
                                        disabled={isLoading} onChange={(_, { value }) => this.handleChange('shp', 'projectionId', value)}
                                    />
                                </Segment>
                            </Grid.Column>
                            <Grid.Column computer={4} tablet={8} mobile={16} style={!isMobile ? { paddingBottom: '10px' } : null} stretched>
                                <Segment style={isMobile ? { marginTop: '15px' } : null}>
                                    <h3><FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '10px' }} />{i18n.t("Fiches PDF")}</h3>
                                    <Divider />
                                    <div style={{ marginBottom: '5px' }}>{i18n.t("Types d'éléments")} :</div>
                                    {RightsUtil.canExport(projectRole?.trees) && this.renderButton(faTree, pdfs.trees, i18n.t("Arbres"), isLoading, () => this.handleChange('pdfs', 'trees'))}
                                    {RightsUtil.canExport(projectRole?.greenSpaces) && this.renderButton(faFlowerTulip, pdfs.greenSpaces, i18n.t("Espaces verts"), isLoading, () => this.handleChange('pdfs', 'greenSpaces'))}
                                    {RightsUtil.canExport(projectRole?.furnitures) && this.renderButton(faTablePicnic, pdfs.furnitures, i18n.t("Mobilier urbain"), isLoading, () => this.handleChange('pdfs', 'furnitures'))}
                                    {RightsUtil.canExport(projectRole?.markers) && this.renderButton(faLocationQuestion, pdfs.markers, i18n.t("Repères"), isLoading, () => this.handleChange('pdfs', 'markers'))}
                                    <div style={{ marginTop: '10px', marginBottom: '5px' }}>{i18n.t("Projection")} :</div>
                                    <Form.Field
                                        name='projectionId' control={Select} fluid placeholder={i18n.t("Sélectionnez une projection")}
                                        value={pdfs.projectionId || ''} options={projections} search={FormattersUtil.searchList} selectOnBlur={false}
                                        disabled={isLoading} onChange={(_, { value }) => this.handleChange('pdfs', 'projectionId', value)}
                                    />
                                    <div style={{ marginTop: '10px', marginBottom: '5px' }}>{i18n.t("Configuration")} :</div>
                                    <Button.Group style={{ width: '100%' }}>
                                        <Button style={{ width: '50%' }} content='Couleurs' color={pdfs.colorless ? 'grey' : 'blue'} active={!pdfs.colorless} onClick={() => this.setState(prevState => ({ pdfs: { ...prevState.pdfs, colorless: !prevState.pdfs.colorless } }))} />
                                        <Button style={{ width: '50%' }} content='Photos' color={!pdfs.addPhotos ? 'grey' : 'blue'} active={pdfs.addPhotos} onClick={() => this.setState(prevState => ({ pdfs: { ...prevState.pdfs, addPhotos: !prevState.pdfs.addPhotos } }))} />
                                    </Button.Group>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column computer={4} tablet={8} mobile={16} style={!isMobile ? { paddingBottom: '10px' } : null} stretched>
                                <Segment style={isMobile ? { marginTop: '15px' } : null}>
                                    <h3><FontAwesomeIcon icon={faImage} style={{ marginRight: '10px' }} /> {i18n.t("Photos")}</h3>
                                    <Divider />
                                    <div style={{ marginBottom: '5px' }}>{i18n.t("Types d'éléments")} :</div>
                                    {RightsUtil.canExport(projectRole?.trees) && this.renderButton(faTree, photos.trees, i18n.t("Arbres"), isLoading, () => this.handleChange('photos', 'trees'))}
                                    {RightsUtil.canExport(projectRole?.greenSpaces) && this.renderButton(faFlowerTulip, photos.greenSpaces, i18n.t("Espaces verts"), isLoading, () => this.handleChange('photos', 'greenSpaces'))}
                                    {RightsUtil.canExport(projectRole?.furnitures) && this.renderButton(faTablePicnic, photos.furnitures, i18n.t("Mobiliers"), isLoading, () => this.handleChange('photos', 'furnitures'))}
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ padding: 0 }}>
                            <Grid.Column>
                                <Button
                                    className='form-button' type='submit' color='blue' loading={isLoading} onClick={this.handleSubmit}
                                    style={{ display: 'block' }} disabled={!canDownload || isLoading || !this.props.isOnline}
                                >
                                    <FontAwesomeIcon icon={faDownload} style={{ marginRight: '10px' }} />{i18n.t("Télécharger")}
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingBottom: 0 }}>
                            <Grid.Column>
                                <Message
                                    success hidden={!excel.isDownloaded && !shp.isDownloaded} header={i18n.t("Données")}
                                    content={i18n.t("Téléchargement des données terminé")}
                                    style={{ textAlign: "left" }}
                                />
                                <Message
                                    info hidden={!photos.isDownloaded} header={i18n.t("Photos")}
                                    content={i18n.t("Vous recevrez dans quelques instants un e-mail contenant un lien permettant de télécharger les photos")}
                                    style={{ textAlign: "left" }}
                                />
                                <Message
                                    info hidden={!pdfs.isDownloaded} header={i18n.t("Fiches")}
                                    content={i18n.t("Vous recevrez dans quelques instants un e-mail contenant un lien permettant de télécharger les fiches")}
                                    style={{ textAlign: "left" }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        {RightsUtil.canWrite(projectRole?.wfsServices) &&
                            <Grid.Row style={{ padding: 0 }}>
                                <Grid.Column stretched>
                                    <Segment>
                                        <h3><FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '10px' }} />{i18n.t("Flux WFS")}</h3>
                                        <Divider />
                                        <Form>
                                            <Form.Field control={Input} disabled={!wfsUrl} action label={i18n.t("Lien du WFS") + ' : '}>
                                                <Input name='url' placeholder={i18n.t("URL du WFS")} value={wfsUrl || ''} style={{ borderRight: 'none' }} />
                                                <Button title={i18n.t("Copier l'URL")} color='blue' style={{ marginLeft: '-3px', zIndex: 1, padding: '5px 10px' }} onClick={this.copyWfsUrl}>
                                                    {i18n.t("Copier")}
                                                </Button>
                                            </Form.Field>
                                            {!wfsUrl
                                                ? <Button type='button' disabled={isWfsLoading} loading={isWfsLoading} style={{ marginTop: '7px' }} color='blue' onClick={this.addWFS}>{i18n.t("Générer")}</Button>
                                                : <Button type='button' disabled={isWfsLoading} loading={isWfsLoading} style={{ marginTop: '7px' }} color='red' onClick={this.removeWFS}>{i18n.t("Supprimer")}</Button>}
                                        </Form>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>}
                    </Grid>
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        const { project } = this.props;
        const projectionId = project?.projectionId;
        if (projectionId)
            this.setState(prevState => ({
                excel: { ...prevState, projectionId },
                shp: { ...prevState, projectionId },
                pdfs: { ...prevState, projectionId },
            }));
        this.setState({ project, wfsUrl: project.wfsUrl });

        const projectRole = this.props.userProjects?.find(up => up.userId === jwtDecode(new Cookies().get('token')).id)?.projectRole;
        if (RightsUtil.canWrite(projectRole?.wfsServices) && !project.hasOwnProperty('wfsUrl')) {
            this.setState({ isWfsLoading: true });
            WmsService.getWfsService(project.id).then(wfsService => {
                const wfsUrl = wfsService ? AppSettings.getGeoserverUrl() + wfsService.viewId : null;
                this.setState({ wfsUrl, isWfsLoading: false });
                const { projects } = this.props;
                const baseProject = ProjectsUtil.getBaseProject(this.state.project.id, projects);
                if (baseProject) {
                    baseProject.wfsUrl = wfsUrl;
                    this.props.setProjects(JSON.parse(JSON.stringify(projects)));
                }
            });
        }
    }

    componentWillUnmount = () => {
        const { project } = this.state;
        const userProject = this.props.userProjects?.find(up => up.userId === jwtDecode(new Cookies().get('token')).id);
        if (!project.parentFolderId || userProject?.baseProjectId === project.id) this.props.setUserProjects(null);
        else if (this.props.userProjects) this.props.setUserProjects(this.props.userProjects.filter(up => up.baseProjectId !== project.id));
    }

    renderButton = (icon, isChecked, title, isDisabled, onClick) => {
        return (
            <Button color={isChecked ? 'blue' : 'grey'} title={title} disabled={isDisabled} style={{ padding: '11px 13px' }} onClick={onClick}>
                <FontAwesomeIcon icon={icon} />
            </Button>
        );
    }

    handleChange = (category, name, value) => {
        const isChecked = this.state[category][name];
        const areTreesChecked = name !== 'trees' ? this.state[category].trees : !isChecked;
        const areGreenSpacesChecked = name !== 'greenSpaces' ? this.state[category].greenSpaces : !isChecked;
        const areFurnituresChecked = name !== 'furnitures' ? this.state[category].furnitures : !isChecked;
        const areMarkersChecked = name !== 'markers' ? this.state[category].markers : !isChecked;
        const areStationsChecked = name !== 'stations' ? this.state[category].stations : !isChecked;
        const isCategoryChecked = areTreesChecked || areGreenSpacesChecked || areFurnituresChecked || areMarkersChecked || areStationsChecked;

        this.setState(prevState => ({
            excel: { ...prevState.excel, isDownloaded: false },
            shp: { ...prevState.shp, isDownloaded: false },
            pdfs: { ...prevState.pdfs, isDownloaded: false },
            photos: { ...prevState.photos, isDownloaded: false },
            [category]: { ...prevState[category], isChecked: isCategoryChecked, [name]: !value ? !isChecked : value, isDownloaded: false }
        }));
    }

    handleSubmit = () => {
        this.setState({ isLoading: true });
        const { excel, shp, photos, pdfs, numberOfCheckedFields } = this.state;
        let numberOfFinishedTasks = 0;

        const isDownloadFinished = () => {
            if (numberOfFinishedTasks >= numberOfCheckedFields) {
                this.setState(prevState => {
                    let downloadState = JSON.parse(JSON.stringify(initialDownloadState));
                    for (const property in downloadState) {
                        downloadState[property].isDownloaded = prevState[property].isChecked;
                        downloadState[property].isChecked = prevState[property].isChecked;
                        downloadState[property].trees = prevState[property].trees;
                        downloadState[property].greenSpaces = prevState[property].greenSpaces;
                        downloadState[property].furnitures = prevState[property].furnitures;
                        downloadState[property].markers = prevState[property].markers;
                        downloadState[property].stations = prevState[property].stations;
                        downloadState[property].projectionId = prevState[property].projectionId;
                    }
                    return { isLoading: false, numberOfCheckedFields: 0, ...downloadState };
                });
            }
        }

        if (excel.isChecked) {
            const projection = this.props.projections.find(x => x.id === (excel.projectionId || 1));
            if (excel.trees) {
                TreesService.exportTreesFromProjectAsExcel(this.props.project.label, this.props.project.id, [], projection).then(() => {
                    numberOfFinishedTasks++;
                    isDownloadFinished();
                });
            }
            if (excel.greenSpaces) {
                GreenSpacesService.exportGreenSpacesFromProjectAsExcel(this.props.project.label, this.props.project.id, [], projection).then(() => {
                    numberOfFinishedTasks++;
                    isDownloadFinished();
                });
            }
            if (excel.furnitures) {
                FurnituresService.exportFurnituresFromProjectAsExcel(this.props.project.label, this.props.project.id, [], projection).then(() => {
                    numberOfFinishedTasks++;
                    isDownloadFinished();
                });
            }
            if (excel.markers) {
                MarkersService.exportMarkersFromProjectAsExcel(this.props.project.label, this.props.project.id, projection).then(() => {
                    numberOfFinishedTasks++;
                    isDownloadFinished();
                });
            }
            if (excel.stations) {
                StationsService.exportStationsFromProjectAsExcel(this.props.project.label, this.props.project.id, projection).then(() => {
                    numberOfFinishedTasks++;
                    isDownloadFinished();
                });
            }
        }

        if (shp.isChecked) {
            const projection = this.props.projections.find(x => x.id === (shp.projectionId || 1));
            if (shp.trees) {
                TreesService.exportTreesAsSHP(this.props.project.label, this.props.project.id, [], projection).then(() => {
                    numberOfFinishedTasks++;
                    isDownloadFinished();
                });
            }
            if (shp.greenSpaces) {
                GreenSpacesService.exportGreenSpacesAsSHP(this.props.project.label, this.props.project.id, [], projection).then(() => {
                    numberOfFinishedTasks++;
                    isDownloadFinished();
                });
            }
            if (shp.furnitures) {
                FurnituresService.exportFurnituresAsSHP(this.props.project.label, this.props.project.id, [], projection).then(() => {
                    numberOfFinishedTasks++;
                    isDownloadFinished();
                });
            }
            if (shp.markers) {
                MarkersService.exportMarkersAsSHP(this.props.project.label, this.props.project.id, projection).then(() => {
                    numberOfFinishedTasks++;
                    isDownloadFinished();
                });
            }
            if (shp.stations) {
                StationsService.exportStationsAsSHP(this.props.project.label, this.props.project.id, projection).then(() => {
                    numberOfFinishedTasks++;
                    isDownloadFinished();
                });
            }
        }

        if (pdfs.isChecked) {
            if (pdfs.trees) {
                const projection = this.props.projections.find(x => x.id === (pdfs.projectionId || 1));
                TreesService.exportTreesAsPDF(this.props.project.id, [], { projection, colorless: pdfs.colorless, addPhotos: pdfs.addPhotos });
                setTimeout(() => {
                    numberOfFinishedTasks += 2;
                    isDownloadFinished();
                }, 1000);
            }
            if (pdfs.greenSpaces) {
                GreenSpacesService.exportGreenSpacesAsPDF(this.props.project.id, [], { colorless: pdfs.colorless, addPhotos: pdfs.addPhotos });
                setTimeout(() => {
                    numberOfFinishedTasks += 2;
                    isDownloadFinished();
                }, 1000);
            }
            if (pdfs.furnitures) {
                const projection = this.props.projections.find(x => x.id === (pdfs.projectionId || 1));
                FurnituresService.exportFurnituresAsPDF(this.props.project.id, [], { projection, colorless: pdfs.colorless, addPhotos: pdfs.addPhotos });
                setTimeout(() => {
                    numberOfFinishedTasks += 2;
                    isDownloadFinished();
                }, 1000);
            }
            if (pdfs.markers) {
                const projection = this.props.projections.find(x => x.id === (pdfs.projectionId || 1));
                MarkersService.exportMarkersAsPDF(this.props.project.id, [], { projection, colorless: pdfs.colorless, addPhotos: pdfs.addPhotos });
                setTimeout(() => {
                    numberOfFinishedTasks += 2;
                    isDownloadFinished();
                }, 1000);
            }
        }

        if (photos.isChecked) {
            const types = [];
            if (photos.trees) types.push('trees');
            if (photos.greenSpaces) types.push('greenSpaces');
            if (photos.furnitures) types.push('furnitures');

            FilesService.exportProjectPhotos(this.props.project.id, types);
            setTimeout(() => {
                numberOfFinishedTasks += 2;
                isDownloadFinished();
            }, 1000);
        }
    }

    addWFS = () => {
        this.setState({ isWfsLoading: true });
        WmsService.addWfsService(this.state.project.id).then(wfsService => {
            if (wfsService) {
                const wfsUrl = AppSettings.getGeoserverUrl() + wfsService.viewId;
                this.setState({ isWfsLoading: false, wfsUrl });
                const { projects } = this.props;
                const baseProject = ProjectsUtil.getBaseProject(this.state.project.id, projects);
                baseProject.wfsUrl = wfsUrl;
                this.props.setProjects(JSON.parse(JSON.stringify(projects)));
            }
        });
    }

    removeWFS = () => {
        this.setState({ isWfsLoading: true });
        WmsService.removeWfsService(this.state.project.id).then(() => {
            this.setState({ isWfsLoading: false, wfsUrl: null });
            const { projects } = this.props;
            const baseProject = ProjectsUtil.getBaseProject(this.state.project.id, projects);
            baseProject.wfsUrl = null;
            this.props.setProjects(JSON.parse(JSON.stringify(projects)));
        });
    }

    copyWfsUrl = () => {
        navigator.clipboard.writeText(this.state.wfsUrl);
        showToast('link_copied');
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        projections: state.projections,
        userProjects: state.userProjects,
        projects: state.projects
    };
};

const mapDispatchToProps = {
    setUserProjects,
    setProjects
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportForm);