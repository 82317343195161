import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import Cookies from 'universal-cookie';
import i18n from '../locales/i18n';
// Utils
import { showToast } from '../utils/ToastsUtil';
import DatesUtil from '../utils/DatesUtil';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class UsersService {
    static isEmailInUse(email) {
        return Axios.get(BACKEND_ENDPOINT + 'users/isEmailInUse/' + email.trim()).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('connection_failed');
            return;
        });
    }

    static getExternalAuthentications() {
        return Axios.get(BACKEND_ENDPOINT + 'users/externalAuthentications').then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('connection_failed');
            return;
        });
    }

    static getUserExternalAuthentication() {
        return Axios.get(BACKEND_ENDPOINT + 'users/userExternalAuthentications', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('connection_failed');
            return;
        });
    }

    static linkExternalAuthentication(externalAuthentication) {
        return Axios.post(BACKEND_ENDPOINT + 'users/linkExternalAuthentication', externalAuthentication, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast(response.data.toast);
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('connection_failed');
            return;
        });
    }

    static linkUrbasenseAccount(login) {
        return Axios.post(BACKEND_ENDPOINT + 'users/linkUrbasenseAccount', login, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast(response.data.toast);
            return response.data;
        }, error => {
            if (navigator.onLine && error.response.status !== 400)
                showToast('connection_failed');
            return error.response;
        });
    }

    static unlinkExternalAuthentication(loginProvider, email) {
        return Axios.get(BACKEND_ENDPOINT + 'users/unlinkExternalAuthentication?loginProvider=' + loginProvider + '&email=' + email, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            let res = response.data;
            showToast(res.toast);
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('connection_failed');
            return;
        });
    }

    static externalAuthentication(externalAuthentication) {
        return Axios.post(BACKEND_ENDPOINT + 'users/externalAuthentication', externalAuthentication).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('connection_failed');
            return;
        });
    }

    static login(login) {
        return Axios.post(BACKEND_ENDPOINT + 'users/login', login).then(response => {
            return response.data;
        }, error => {
            if (navigator.onLine && error.response.status !== 400)
                showToast('connection_failed');
            return error.response;
        });
    }

    static loginWithUrbasense(login) {
        return Axios.post(BACKEND_ENDPOINT + 'users/urbasenseLogin', login).then(response => {
            return response.data;
        }, error => {
            if (navigator.onLine && error.response.status !== 400)
                showToast('connection_failed');
            return error.response;
        });
    }

    static loginWithUrbasenseToken(token) {
        return Axios.post(BACKEND_ENDPOINT + 'users/urbasenseLogin/' + token).then(response => {
            return response.data;
        }, error => {
            if (navigator.onLine && error.response.status !== 400)
                showToast('connection_failed');
            return error.response;
        });
    }

    static loginAs(userId) {
        return Axios.post(BACKEND_ENDPOINT + 'admin/users/loginAs/' + userId, null, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, error => {
            if (navigator.onLine && error.response.status !== 400)
                showToast('connection_failed');
            return error.response;
        });
    }

    static logout() {
        return Axios.get(BACKEND_ENDPOINT + 'users/logout', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response;
        }, () => {
            if (navigator.onLine) showToast('connection_failed');
            return;
        });
    }

    static register(register) {
        return Axios.post(BACKEND_ENDPOINT + 'users/register', register).then(response => {
            return response;
        }, () => {
            return;
        });
    }

    static deleteUserAccount(password) {
        return Axios.delete(BACKEND_ENDPOINT + 'users/?password=' + password, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response;
        }, error => {
            if (error?.response?.data) showToast('wrong_password');
            else if (navigator.onLine) showToast('account_deletion_failed');
            return;
        });
    }

    static confirmEmail(confirmEmail) {
        return Axios.post(BACKEND_ENDPOINT + 'users/confirmEmail', confirmEmail).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('connection_failed');
            return;
        });
    }

    static forgotPassword(forgotPassword) {
        return Axios.post(BACKEND_ENDPOINT + 'users/forgotPassword', forgotPassword).then(response => {
            return response;
        }, () => { return; });
    }

    static resetPassword(resetPassword) {
        return Axios.post(BACKEND_ENDPOINT + 'users/resetPassword', resetPassword).then(response => {
            return response;
        }, () => { return; });
    }

    static resendActivationLink(email) {
        return Axios.post(BACKEND_ENDPOINT + 'users/resendActivationLink', { email: email }).then(response => {
            return response;
        }, () => { return; });
    }

    static getUserInfos() {
        return Axios.get(BACKEND_ENDPOINT + 'users/userInfos', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => { return; });
    }

    static updateUserInfos(userInfos) {
        return Axios.put(BACKEND_ENDPOINT + 'users/userInfos', userInfos, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('infos_updated');
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('infos_update_failed');
            return;
        });
    }

    static changePassword(changePassword) {
        return Axios.put(BACKEND_ENDPOINT + 'users/changePassword', changePassword, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('password_updated');
            return response;
        }, () => {
            if (navigator.onLine) showToast('password_update_failed');
            return;
        });
    }

    static changeLanguage(culture) {
        return Axios.put(BACKEND_ENDPOINT + 'users/changeLanguage/' + culture, null, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response;
        }, () => {
            if (navigator.onLine) showToast('language_update_failed');
            return;
        });
    }

    static updateMailsConfiguration(mailsConfiguration) {
        return Axios.put(BACKEND_ENDPOINT + 'users/mailsConfiguration', mailsConfiguration, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(_ => {
            return true;
        }, () => {
            showToast('mails_configuration_update_failed');
            return;
        });
    }

    static getUsers() {
        return Axios.get(BACKEND_ENDPOINT + 'admin/users/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('users_loading_failed');
            return;
        });
    }

    static getUserRows() {
        return Axios.get(BACKEND_ENDPOINT + 'admin/users/rows/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('users_loading_failed');
            return;
        });
    }

    static addUser(user) {
        return Axios.post(BACKEND_ENDPOINT + 'admin/users/', user, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('user_added');
            return response;
        }, () => {
            if (navigator.onLine) showToast('user_addition_failed');
            return;
        });
    }

    static updateUsers(usersToModify) {
        return Axios.put(BACKEND_ENDPOINT + 'admin/users/', usersToModify, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('users_updated');
            return response.status;
        }, () => {
            if (navigator.onLine) showToast('users_update_failed');
            return;
        });
    }

    static deleteUsers(ids) {
        return Axios.delete(BACKEND_ENDPOINT + 'admin/users/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` },
            data: { ids: ids }
        }).then(response => {
            return response.status;
        }, () => {
            showToast(ids.length > 1 ? 'users_deletion_failed' : 'user_deletion_failed');
            return;
        });
    }

    static exportUsersAsExcel(elements) {
        return Axios.post(BACKEND_ENDPOINT + 'admin/users/exportXLSX/', elements, {
            responseType: 'blob',
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${i18n.t("Utilisateurs")} - ${DatesUtil.getFormattedLocaleDateString(new Date().toISOString())}.xlsx`);
            document.getElementById('admin-panel__grid').appendChild(link);
            link.click();
            document.getElementById('admin-panel__grid').removeChild(link);
        }, () => {
            if (navigator.onLine) showToast('users_xlsx_export_failed');
        });
    }

    static transferUserData(transferData) {
        return Axios.post(BACKEND_ENDPOINT + 'admin/users/transfer/', transferData, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('data_transferred');
            return response.status;
        }, () => {
            if (navigator.onLine) showToast('data_transfer_failed');
        });
    }

    static getSSOConfiguration(value) {
        return Axios.get(BACKEND_ENDPOINT + 'users/SSOConfiguration/' + value).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('connection_failed');
            return;
        });
    }
}