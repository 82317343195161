import React, { Component } from 'react';
// Composants
import { SortableHeaderCell } from 'react-data-grid';
import { Dimmer, Form, Button, Menu, Input, Segment, Message } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Woops from '../Utils/Woops';
/*     Filters     */
import TextFilter from '../Tables/Filters/TextFilter';
import BooleanFilter from '../Tables/Filters/BooleanFilter';
import NumberFilter from '../Tables/Filters/NumberFilter';
import DropDownFilter from '../Tables/Filters/DropDownFilter';
import DropDownNumberFilter from '../Tables/Filters/DropDownNumberFilter';
import EssenceFilter from '../Tables/Filters/EssenceFilter';
// Librairies
import { faCheck, faList, faRotateLeft, faTimesCircle, faUserHelmetSafety } from '@fortawesome/pro-solid-svg-icons';
import DataGrid from 'react-data-grid';
import i18n from '../../locales/i18n';
import { connect } from 'react-redux';
import { setElementHistory } from '../../actionCreators/elementsActions';
import { setEditedProperties, unlockEditedProperties } from '../../actionCreators/componentsActions';
import { format } from 'date-fns';
// Services
import TreesService from '../../services/TreesService';
// Styles
import '../../styles/react-contextmenu.css';
import '../../styles/rdg.css';
// Utils
import { showToast } from '../../utils/ToastsUtil';
import FormattersUtil from '../../utils/FormattersUtil';
import StylesUtil from '../../utils/StylesUtil';
import ProjectsUtil from '../../utils/ProjectsUtil';
import DatesUtil from '../../utils/DatesUtil';
import UpdatesUtil from '../../utils/UpdatesUtil';
import RightsUtil from '../../utils/RightsUtil';
import TreesUtil from '../../utils/TreesUtil';
import FieldsUtil from '../../utils/FieldsUtil';

const treeOrgans = [
    { organ: 'root', label: i18n.t("racines") },
    { organ: 'collar', label: i18n.t("collet") },
    { organ: 'trunk', label: i18n.t("tronc") },
    { organ: 'branch', label: i18n.t("branches") },
    { organ: 'leaf', label: i18n.t("feuilles") }
];

const initialFilters = {
    date: '',
    action: '',
    username: '',
    customReference: '',
    place: '',
    tags: '',
    isEmpty: '',
    isDead: '',
    isStump: '',
    vernacularName: '',
    gender: '',
    species: '',
    cultivar: '',
    numberOfTrunks: '',
    height: '',
    circumference: '',
    crownDiameter: '',
    trunkHeight: '',
    treePort: '',
    ontogenicStage: '',
    plantingDate: '',
    age: '',
    plantationType: '',
    coverType: '',
    interactions: '',
    microHabitats: '',
    ...treeOrgans.reduce((prevValue, { organ }) => ({ ...prevValue, [`${organ}Symptoms`]: '', [`${organ}Pathogens`]: '', [`${organ}Pests`]: '', [`${organ}Epiphytes`]: '' }), {}),
    vigor: '',
    healthReview: '',
    risk: '',
    tippingRisk: '',
    organCaliber: '',
    target: '',
    observation: '',
    plantationCoefficient: '',
    situationCoefficient: '',
    patrimonialCoefficient: '',
    isIndexed: '',
    isRemarkable: '',
    treePort: ''
};

const initialState = {
    data: {
        columns: [],
        rows: []
    },
    rowIndex: 0,
    sortColumn: null,
    sortDirection: 'NONE',
    enableFilterRow: false,
    filters: initialFilters,
    isLoading: true,
    isRestoring: false,
    historyToRestore: 0,
    loadingFailed: false
};

class TreeHistory extends Component {
    state = { ...initialState, id: this.props.layer[0].feature.id };

    render() {
        const { data, sortColumn, sortDirection, enableFilterRow, filters, rowIndex, isLoading, isRestoring, loadingFailed, historyToRestore } = this.state;
        const rows = this.getFilteredRows();

        return (
            <>
                {loadingFailed
                    ? <Woops />
                    :
                    <Segment style={{ display: 'flex', flexFlow: 'column', padding: 0, width: '100%', height: '100%' }}>
                        <Dimmer active={historyToRestore} style={StylesUtil.getMapStyles().dimmerStyle}>
                            <Message compact className='tableConfirmation'>
                                <Message.Header>{i18n.t("Êtes vous certain de vouloir restaurer cet historique ?")}</Message.Header>
                                <Message.Content>
                                    <span>{i18n.t("Cela restaurera l'arbre à l'état sélectionné, toute modification ultérieure à celui-ci sera donc annulée")}</span>
                                </Message.Content>
                                <Message.Content style={{ marginTop: '10px' }}>
                                    <Button color='grey' disabled={isRestoring} onClick={() => this.setState({ historyToRestore: 0 })}>
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                    </Button>
                                    <Button color='green' disabled={isRestoring} loading={isRestoring} onClick={this.restoreHistory}>
                                        <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                                    </Button>
                                </Message.Content>
                            </Message>
                        </Dimmer>
                        {data?.columns &&
                            <>
                                <Menu attached='top' tabular style={{ margin: 0, flexWrap: 'wrap' }}>
                                    <Menu.Item>
                                        <Form.Field
                                            control={Input} type='number' step='1' placeholder={i18n.t("Numéro de ligne")}
                                            value={rowIndex || ''}
                                            onChange={(e, { value }) => this.setState({ rowIndex: value })}
                                        />
                                        <Button
                                            className='button--secondary' icon='arrow down' style={{ marginLeft: '10px' }}
                                            onClick={() => { if (this.gridRef.current) this.gridRef.current.scrollToRow(rowIndex - 1) }}
                                        />
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Button.Group>
                                            <Button
                                                title={enableFilterRow ? i18n.t("Désactiver les filtres") : i18n.t("Activer les filtres")}
                                                className={enableFilterRow ? 'button--secondary' : null} color={!enableFilterRow ? 'grey' : null} icon='filter'
                                                onClick={this.toggleFilters}
                                            />
                                            <Button
                                                title={i18n.t("Réinitialiser les filtres")} className='button--secondary' icon='dont'
                                                onClick={this.clearFilters} disabled={!this.areFiltersApplied()}
                                            />
                                        </Button.Group>
                                    </Menu.Item>
                                    {ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators) && RightsUtil.canRead(this.props.rights?.actions) &&
                                        <Menu.Item>
                                            <Button.Group>
                                                <Button className='button--secondary' style={{ padding: '11px' }} disabled>
                                                    <FontAwesomeIcon icon={faList} style={{ height: '12px', marginRight: '7px' }} />{i18n.t("Propriétés")}
                                                </Button>
                                                <Button
                                                    title={i18n.t("Historique des actions")} className='button--secondary' style={{ padding: '11px' }}
                                                    onClick={() => this.props.changeModalContentType('ActionHistory', i18n.t("Historique"), true)}
                                                >
                                                    <FontAwesomeIcon icon={faUserHelmetSafety} style={{ height: '12px', marginRight: '7px' }} />{i18n.t("Actions")}
                                                </Button>
                                            </Button.Group>
                                        </Menu.Item>}
                                </Menu>
                                <DataGrid
                                    ref={this.gridRef} className={this.props.isDarkTheme ? 'rdg-dark' : 'rdg-light'}
                                    style={{ flex: '1 1 auto' }}
                                    columns={data.columns.filter(column => column.visible || !column.hasOwnProperty('visible'))} rows={rows}
                                    defaultColumnOptions={{ sortable: true, resizable: true }}
                                    cellNavigationMode='LOOP_OVER_ROW'
                                    sortColumn={sortColumn} sortDirection={sortDirection}
                                    onSort={this.handleSort} enableFilterRow={enableFilterRow}
                                    filters={filters} onFiltersChange={filters => this.setState({ filters: filters })}
                                    emptyRowsRenderer={() => (<div style={{ textAlign: 'center' }}>
                                        <span>{isLoading ? i18n.t("Chargement en cours...") : i18n.t("Aucun résultat trouvé")}</span>
                                    </div>)}
                                    onSelectedCellChange={({ idx, rowIdx }) => this.setState({ selectedRow: rows[rowIdx], selectedColumn: data.columns[idx] })}
                                />
                            </>}
                    </Segment >}
            </>
        );
    }

    componentDidMount = () => {
        this.gridRef = React.createRef();
        this.loadData();

        const amenityFormulaType = this.props.project.projectFormulaVersions.find(pfv => pfv.formulaId === 4)?.formulaType;
        const propertyName = amenityFormulaType === 'Wallonie' ? 'descriptionWln'
            : amenityFormulaType === 'Bruxelles' ? 'descriptionBxl'
                : 'descriptionFr';
        const plantationCoefficients = this.props.plantationCoefficients.filter(pc => pc[propertyName]);
        const situationCoefficients = this.props.situationCoefficients.filter(sc => sc[propertyName]);

        this.setState({
            vernacularNames: [...new Set(this.props.essences
                .map(x => x.vernacularName))]
                .filter(x => x)
                .sort((a, b) => { // On trie par ordre alphabétique
                    const textA = a.toUpperCase();
                    const textB = b.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }),
            genders: [...new Set(this.props.essences
                .map(x => x.gender))]
                .sort((a, b) => { // On trie par ordre alphabétique
                    const textA = a.toUpperCase();
                    const textB = b.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }),
            species: [...new Set(this.props.essences
                .filter(x => x.species)
                .map(x => x.species))]
                .sort((a, b) => { // On trie par ordre alphabétique
                    const textA = a.toUpperCase();
                    const textB = b.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }),
            cultivars: [...new Set(this.props.essences
                .filter(x => x.cultivar)
                .map(x => x.cultivar))]
                .sort((a, b) => { // On trie par ordre alphabétique
                    const textA = a.toUpperCase();
                    const textB = b.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }),
            treePorts: this.props.treePorts.map(x => { return { label: x.label, id: x.id } }),
            coverTypes: this.props.coverTypes.map(x => { return { label: x.label, id: x.id } }),
            vigors: this.props.vigors.map(x => { return { label: x.label, id: x.id } }),
            healthReviews: this.props.healthReviews.map(x => { return { label: x.value, id: x.id } }),
            ontogenicStages: this.props.ontogenicStages.map(x => { return { label: x.value, id: x.id } }),
            risks: this.props.risks.map(x => { return { label: x.label, id: x.id } }),
            tippingRisks: this.props.tippingRisks.map(x => { return { label: x.label, id: x.id } }),
            organCalibers: this.props.organCalibers.map(x => { return { label: x.label, id: x.id } }),
            targets: this.props.targets.map(x => { return { label: x.label, id: x.id } }),
            plantationTypes: this.props.plantationTypes.map(x => { return { label: x.label, id: x.id } }),
            plantationCoefficients: plantationCoefficients.map(x => { return { label: x.value, id: x.id } }),
            situationCoefficients: situationCoefficients.map(x => { return { label: x.value, id: x.id } }),
            patrimonialCoefficients: this.props.patrimonialCoefficients.map(x => { return { label: x.value, id: x.id } })
        });

        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentDidUpdate = (prevProps) => { // Permet d'update les infos lorsqu'on passe à l'arbre suivant ou précédent dans un projet
        if (this.props.layer[0].feature.id !== this.state.id || JSON.stringify(this.props.elementHistory) !== JSON.stringify(prevProps.elementHistory))
            this.setState({ ...initialState, id: this.props.layer[0].feature.id }, this.loadData);
    }

    componentWillUnmount = () => document.removeEventListener('keydown', this.handleKeyDown);

    getHeaderRenderer = ({ column, onSort, sortColumn, sortDirection }, color) => (
        <div className={color ? 'headerCellOverride' : null} ref={(node) => { if (color) node?.style?.setProperty('background-color', color, 'important'); }}>
            <SortableHeaderCell
                column={column}
                onSort={onSort}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
            >
                {column.name}
            </SortableHeaderCell>
        </div>
    );

    loadData = () => {
        let data = {
            columns: [],
            rows: []
        };

        const trunkCircumferenceUnit = this.props.project?.trunkCircumferenceUnit;
        const requiredFields = ProjectsUtil.getProjectRequiredFields(this.props.project).trees;
        const publicFields = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators);
        const mainPF = publicFields.main;
        const treesPF = publicFields.trees;
        const amenityFormulaType = this.props.project.projectFormulaVersions.find(pfv => pfv.formulaId === 4)?.formulaType;
        const projectTags = this.props.project?.tags || [];

        // Définition des colonnes
        this.customFieldFilters = [];
        data.columns = [
            {
                name: '', key: 'restore', width: 150, order: -1,
                sortable: true, resizable: false,
                formatter: (props) => (
                    <div style={{ overflow: 'hidden' }}>
                        {props.row.isSameState ?
                            <Button color='yellow' disabled style={{ padding: '6px 20px', width: '132px' }} content={<><FontAwesomeIcon style={{ marginRight: '10px' }} />{i18n.t("État actuel")}</>} />
                            :
                            <Button
                                color='green' style={{ padding: '6px 20px' }} content={<><FontAwesomeIcon icon={faRotateLeft} style={{ marginRight: '10px' }} />{i18n.t("Restaurer")}</>}
                                onClick={() => this.setState({ historyToRestore: props.row.id })}
                            />}
                    </div>
                )
            },
            {
                name: i18n.t("Date"), key: 'date', width: 250, sortable: true, order: -1,
                formatter: (props) => props.row.date || '',
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Action"), key: 'action', width: 150, sortable: true, order: -1,
                formatter: (props) => props.row.action || '',
                filterRenderer: p => <TextFilter p={p} />
            },
            {
                name: i18n.t("Nom d'utilisateur"), key: 'username', width: 150, sortable: true, order: -1,
                formatter: (props) => props.row.username || '',
                filterRenderer: p => <TextFilter p={p} />
            },
            {
                name: i18n.t("Référence personnalisée"), key: 'customReference', width: 190, order: -1,
                sortable: true, visible: requiredFields.customReference && mainPF.references,
                formatter: (props) => this.didChange('customReference', props.row.id)
                    ? <div className='modified'>{props.row.customReference}</div>
                    : props.row.customReference || '',
                filterRenderer: p => <TextFilter p={p} />
            },
            {
                name: i18n.t("Lieu"), key: 'place', width: 250, category: 'Emplacement',
                sortable: true, visible: requiredFields.place && treesPF.place,
                formatter: (props) => this.didChange('place', props.row.id)
                    ? <div className='modified'>{props.row.place}</div>
                    : props.row.place || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Emplacement')?.color),
                filterRenderer: p => <TextFilter p={p} />
            },
            {
                name: i18n.t("Tags"), key: 'tags', width: 200, category: 'Emplacement',
                sortable: false, visible: requiredFields.tags && treesPF.tags,
                formatter: (props) => this.didChange('tags', props.row.id)
                    ? <div className='modified'>{props.row.tags}</div>
                    : props.row.tags || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Emplacement')?.color),
                filterRenderer: p => <TextFilter p={p} />
            },
            {
                name: i18n.t("Classé"), key: 'isIndexed', width: 110, category: 'Emplacement',
                sortable: true, visible: requiredFields.isIndexed && treesPF.isIndexed,
                formatter: (props) => this.didChange('isIndexed', props.row.id)
                    ? <div className='modified'>{props.row.isIndexed}</div>
                    : props.row.isIndexed || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Emplacement')?.color),
                filterRenderer: p => <BooleanFilter p={p} />
            },
            {
                name: i18n.t("Remarquable"), key: 'isRemarkable', width: 110, category: 'Emplacement',
                sortable: true, visible: requiredFields.isRemarkable && treesPF.isRemarkable,
                formatter: (props) => this.didChange('isRemarkable', props.row.id)
                    ? <div className='modified'>{props.row.isRemarkable}</div>
                    : props.row.isRemarkable || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Emplacement')?.color),
                filterRenderer: p => <BooleanFilter p={p} />
            },
            {
                name: i18n.t("Vide"), key: 'isEmpty', width: 110, category: 'Emplacement',
                sortable: true, visible: requiredFields.isEmpty && treesPF.isEmpty,
                formatter: (props) => this.didChange('isEmpty', props.row.id)
                    ? <div className='modified'>{props.row.isEmpty}</div>
                    : props.row.isEmpty || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Emplacement')?.color),
                filterRenderer: p => <BooleanFilter p={p} />
            },
            {
                name: i18n.t("Mort"), key: 'isDead', width: 110, category: 'Emplacement',
                sortable: true, visible: requiredFields.isDead && treesPF.isDead,
                formatter: (props) => this.didChange('isDead', props.row.id)
                    ? <div className='modified'>{props.row.isDead}</div>
                    : props.row.isDead || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Emplacement')?.color),
                filterRenderer: p => <BooleanFilter p={p} />
            },
            {
                name: i18n.t("Souche"), key: 'isStump', width: 110, category: 'Emplacement',
                sortable: true, visible: requiredFields.isStump && treesPF.isStump,
                formatter: (props) => this.didChange('isStump', props.row.id)
                    ? <div className='modified'>{props.row.isStump}</div>
                    : props.row.isStump || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Emplacement')?.color),
                filterRenderer: p => <BooleanFilter p={p} />
            },
            ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Emplacement')),
            {
                name: i18n.t("Nom vernaculaire"), key: 'vernacularName', width: 150, category: 'Description',
                sortable: true, visible: requiredFields.vernacularName && treesPF.vernacularName,
                formatter: (props) => this.didChange('vernacularName', props.row.id)
                    ? <div className='modified'>{props.row.vernacularName}</div>
                    : props.row.vernacularName || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Description')?.color),
                filterRenderer: p => <EssenceFilter p={p} propertyOptions={this.state.vernacularNames} />
            },
            {
                name: i18n.t("Genre"), key: 'gender', width: 150, category: 'Description',
                sortable: true, visible: requiredFields.gender && treesPF.gender,
                formatter: (props) => this.didChange('gender', props.row.id)
                    ? <div className='modified'>{props.row.gender}</div>
                    : props.row.gender || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Description')?.color),
                filterRenderer: p => <EssenceFilter p={p} propertyOptions={this.state.genders} />
            },
            {
                name: i18n.t("Espèce"), key: 'species', width: 150, category: 'Description',
                sortable: true, visible: requiredFields.species && treesPF.species,
                formatter: (props) => this.didChange('species', props.row.id)
                    ? <div className='modified'>{props.row.species}</div>
                    : props.row.species || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Description')?.color),
                filterRenderer: p => <EssenceFilter p={p} propertyOptions={this.state.species} />
            },
            {
                name: i18n.t("Cultivar"), key: 'cultivar', width: 150, category: 'Description',
                sortable: true, visible: requiredFields.cultivar && treesPF.cultivar,
                formatter: (props) => this.didChange('cultivar', props.row.id)
                    ? <div className='modified'>{props.row.cultivar}</div>
                    : props.row.cultivar || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Description')?.color),
                filterRenderer: p => <EssenceFilter p={p} propertyOptions={this.state.cultivars} />
            },

            {
                name: i18n.t("Port de l'arbre"), key: 'treePort', width: 180, category: 'Description',
                sortable: true, visible: requiredFields.treePort && treesPF.treePort,
                formatter: (props) => this.didChange('treePort', props.row.id)
                    ? <div className='modified'>{props.row.treePort}</div>
                    : props.row.treePort || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Description')?.color),
                filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.treePorts} isNullable />
            },
            {
                name: i18n.t("Date de plantation"), key: 'plantingDate', width: 155, category: 'Description',
                sortable: true, visible: requiredFields.plantingDate && treesPF.plantingDate,
                formatter: (props) => this.didChange('plantingDate', props.row.id)
                    ? <div className='modified'>{props.row.plantingDate}</div>
                    : props.row.plantingDate || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Description')?.color),
                filterRenderer: props => <TextFilter p={props} />
            },
            {
                name: i18n.t("Âge"), key: 'age', width: 100, category: 'Description',
                sortable: true, visible: requiredFields.age && treesPF.age,
                formatter: (props) => this.didChange('age', props.row.id)
                    ? <div className='modified'>{props.row.age}</div>
                    : (props.row.age || props.row.age === 0 ? props.row.age : ''),
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Description')?.color),
                filterRenderer: p => <NumberFilter p={p} step='1' />
            },
            {
                name: i18n.t("Stade ontogénique"), key: 'ontogenicStage', width: 160, category: 'Description',
                sortable: true, visible: requiredFields.ontogenicStage && treesPF.ontogenicStage,
                formatter: (props) => this.didChange('ontogenicStage', props.row.id)
                    ? <div className='modified'>{props.row.ontogenicStage}</div>
                    : props.row.ontogenicStage || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Description')?.color),
                filterRenderer: p => <DropDownNumberFilter p={p} propertyOptions={this.state.ontogenicStages} />
            },
            ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Description')),
            {
                name: i18n.t("Nombre d'axes"), key: 'numberOfTrunks', width: 150, category: 'Dimensions',
                sortable: true, visible: requiredFields.numberOfTrunks && treesPF.numberOfTrunks,
                formatter: (props) => this.didChange('numberOfTrunks', props.row.id)
                    ? <div className='modified'>{props.row.numberOfTrunks}</div>
                    : props.row.numberOfTrunks || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Dimensions')?.color),
                filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.numberOfTrunks} />
            },
            {
                name: i18n.t("Hauteur totale axe") + ' (m)', key: 'height', width: 200, category: 'Dimensions',
                sortable: true, visible: requiredFields.trunks && treesPF.trunks,
                formatter: (props) => this.didChange('height', props.row.id)
                    ? <div className='modified'>{props.row.height}</div>
                    : props.row.height || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Dimensions')?.color),
                filterRenderer: p => <NumberFilter p={p} step='0.1' />,
            },
            {
                name: (trunkCircumferenceUnit === 'circumference' ? i18n.t("Circonférence axe") : i18n.t("Diamètre axe")) + ' (cm)', key: 'circumference', width: 215, category: 'Dimensions',
                sortable: true, visible: requiredFields.trunks && treesPF.trunks,
                formatter: (props) => this.didChange('circumference', props.row.id)
                    ? <div className='modified'>{props.row.circumference}</div>
                    : props.row.circumference || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Dimensions')?.color),
                filterRenderer: p => <NumberFilter p={p} step='0.1' />,
            },
            {
                name: i18n.t("Diamètre couronne axe") + ' (m)', key: 'crownDiameter', width: 215, category: 'Dimensions',
                sortable: true, visible: requiredFields.trunks && treesPF.trunks,
                formatter: (props) => this.didChange('crownDiameter', props.row.id)
                    ? <div className='modified'>{props.row.crownDiameter}</div>
                    : props.row.crownDiameter || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Dimensions')?.color),
                filterRenderer: p => <NumberFilter p={p} step='0.1' />,
            },
            {
                name: i18n.t("Hauteur du tronc") + ' (m)', key: 'trunkHeight', width: 200, category: 'Dimensions',
                sortable: true, visible: requiredFields.trunks && treesPF.trunks,
                formatter: (props) => this.didChange('trunkHeight', props.row.id)
                    ? <div className='modified'>{props.row.trunkHeight}</div>
                    : props.row.trunkHeight || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Dimensions')?.color),
                filterRenderer: p => <NumberFilter p={p} step='0.1' />
            },
            ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Dimensions')),
            {
                name: i18n.t("Type de plantation"), key: 'plantationType', width: 150, category: 'Environnement',
                sortable: true, visible: requiredFields.plantationType && treesPF.plantationType,
                formatter: (props) => this.didChange('plantationType', props.row.id)
                    ? <div className='modified'>{props.row.plantationType}</div>
                    : props.row.plantationType || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Environnement')?.color),
                filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.plantationTypes} isNullable />
            },
            {
                name: i18n.t("Type de couverture au sol"), key: 'coverType', width: 180, category: 'Environnement',
                sortable: true, visible: requiredFields.coverType && treesPF.coverType,
                formatter: (props) => this.didChange('coverType', props.row.id)
                    ? <div className='modified'>{props.row.coverType}</div>
                    : props.row.coverType || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Environnement')?.color),
                filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.coverTypes} isNullable />
            },
            {
                name: i18n.t("Interactions"), key: 'interactions', width: 200, category: 'Environnement',
                sortable: false, visible: requiredFields.interactions && treesPF.interactions,
                formatter: (props) => this.didChange('interactions', props.row.id)
                    ? <div className='modified'>{props.row.interactions}</div>
                    : props.row.interactions || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Environnement')?.color),
                filterRenderer: p => <TextFilter p={p} />
            },
            {
                name: i18n.t("Dendro-microhabitats"), key: 'microHabitats', width: 200, category: 'Environnement',
                sortable: false, visible: requiredFields.microHabitats && treesPF.microHabitats,
                formatter: (props) => this.didChange('microHabitats', props.row.id)
                    ? <div className='modified'>{props.row.microHabitats}</div>
                    : props.row.microHabitats || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Environnement')?.color),
                filterRenderer: p => <TextFilter p={p} />
            },
            ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Environnement')),
            {
                name: i18n.t("Vigueur"), key: 'vigor', width: 110, category: 'État',
                sortable: true, visible: requiredFields.vigor && treesPF.vigor,
                formatter: (props) => this.didChange('vigor', props.row.id)
                    ? <div className='modified'>{props.row.vigor} </div>
                    : props.row.vigor || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'État')?.color),
                filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.vigors} isNullable />
            },
            {
                name: i18n.t("Cote sanitaire"), key: 'healthReview', width: 110, category: 'État',
                sortable: true, visible: requiredFields.healthReview && treesPF.healthReview,
                formatter: (props) => this.didChange('healthReview', props.row.id)
                    ? <div className='modified'>{props.row.healthReview}</div>
                    : (props.row.healthReview || props.row.healthReview === 0 ? props.row.healthReview : ''),
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'État')?.color),
                filterRenderer: p => <DropDownNumberFilter p={p} propertyOptions={this.state.healthReviews} />
            },
            {
                name: i18n.t("Risque"), key: 'risk', width: 110, category: 'État',
                sortable: true, visible: requiredFields.risk && treesPF.risk,
                formatter: (props) => this.didChange('risk', props.row.id)
                    ? <div className='modified'>{props.row.risk}</div>
                    : props.row.risk || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'État')?.color),
                filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.risks} isNullable />
            },
            {
                name: i18n.t("Risque de basculement/rupture"), key: 'tippingRisk', width: 110, category: 'État',
                sortable: true, visible: requiredFields.accurateRisk && treesPF.risk,
                formatter: (props) => this.didChange('tippingRisk', props.row.id)
                    ? <div className='modified'>{props.row.tippingRisk}</div>
                    : props.row.tippingRisk || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'État')?.color),
                filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.tippingRisks} isNullable />
            },
            {
                name: i18n.t("Calibre de l'organe instable"), key: 'organCaliber', width: 110, category: 'État',
                sortable: true, visible: requiredFields.accurateRisk && treesPF.risk,
                formatter: (props) => this.didChange('organCaliber', props.row.id)
                    ? <div className='modified'>{props.row.organCaliber}</div>
                    : props.row.organCaliber || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'État')?.color),
                filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.organCalibers} isNullable />
            },
            {
                name: i18n.t("Cible"), key: 'target', width: 110, category: 'État',
                sortable: true, visible: requiredFields.accurateRisk && treesPF.risk,
                formatter: (props) => this.didChange('target', props.row.id)
                    ? <div className='modified'>{props.row.target}</div>
                    : props.row.target || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'État')?.color),
                filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.targets} isNullable />
            },
            ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'État')),
            {
                name: i18n.t("Observation"), key: 'observation', width: 500, category: 'Observation',
                sortable: true, visible: requiredFields.observation && treesPF.observation,
                formatter: (props) => this.didChange('observation', props.row.id)
                    ? <div className='modified'>{props.row.observation}</div>
                    : props.row.observation || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Observation')?.color),
                filterRenderer: p => <TextFilter p={p} />
            },
            ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Observation')),
            {
                name: i18n.t("Coefficient de plantation"), key: 'plantationCoefficient', width: 180, category: 'Coefficients',
                sortable: true, visible: requiredFields.plantationCoefficient && treesPF.plantationCoefficient,
                formatter: (props) => this.didChange('plantationCoefficient', props.row.id)
                    ? <div className='modified'>{props.row.plantationCoefficient}</div>
                    : props.row.plantationCoefficient || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Coefficients')?.color),
                filterRenderer: p => <DropDownNumberFilter p={p} propertyOptions={this.state.plantationCoefficients} />
            },
            {
                name: i18n.t("Coefficient de situation"), key: 'situationCoefficient', width: 180, category: 'Coefficients',
                sortable: true, visible: requiredFields.situationCoefficient && treesPF.situationCoefficient,
                formatter: (props) => this.didChange('situationCoefficient', props.row.id)
                    ? <div className='modified'>{props.row.situationCoefficient}</div>
                    : props.row.situationCoefficient || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Coefficients')?.color),
                filterRenderer: p => <DropDownNumberFilter p={p} propertyOptions={this.state.situationCoefficients} />
            },
            {
                name: i18n.t("Coefficient patrimonial"), key: 'patrimonialCoefficient', width: 180, category: 'Coefficients',
                sortable: true, visible: requiredFields.patrimonialCoefficient && treesPF.patrimonialCoefficient && amenityFormulaType === 'Wallonie',
                formatter: (props) => this.didChange('patrimonialCoefficient', props.row.id)
                    ? <div className='modified'>{props.row.patrimonialCoefficient}</div>
                    : props.row.patrimonialCoefficient || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Coefficients')?.color),
                filterRenderer: p => <DropDownNumberFilter p={p} propertyOptions={this.state.patrimonialCoefficients} />
            },
            ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Coefficients')),
            ...treeOrgans.flatMap(({ organ, label }) => [
                {
                    name: `${i18n.t("Symptômes")} ${label}`, key: `${organ}Symptoms`, width: 200, category: `VTA ${label}`,
                    sortable: false, visible: requiredFields[`${organ}Symptoms`] && treesPF[`${organ}Symptoms`],
                    formatter: (props) => this.didChange(`${organ}Symptoms`, props.row.id)
                        ? <div className='modified'>{props.row[`${organ}Symptoms`]}</div>
                        : props.row[`${organ}Symptoms`] || '',
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === `VTA ${label}`)?.color),
                    filterRenderer: p => <TextFilter p={p} />
                },
                {
                    name: `${i18n.t("Pathogènes")} ${label}`, key: `${organ}Pathogens`, width: 200, category: `VTA ${label}`,
                    sortable: false, visible: requiredFields[`${organ}Pathogens`] && treesPF[`${organ}Pathogens`],
                    formatter: (props) => this.didChange(`${organ}Pathogens`, props.row.id)
                        ? <div className='modified'>{props.row[`${organ}Pathogens`]}</div>
                        : props.row[`${organ}Pathogens`] || '',
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === `VTA ${label}`)?.color),
                    filterRenderer: p => <TextFilter p={p} />
                },
                {
                    name: `${i18n.t("Ravageurs")} ${label}`, key: `${organ}Pests`, width: 200, category: `VTA ${label}`,
                    sortable: false, visible: requiredFields[`${organ}Pests`] && treesPF[`${organ}Pests`],
                    formatter: (props) => this.didChange(`${organ}Pests`, props.row.id)
                        ? <div className='modified'>{props.row[`${organ}Pests`]}</div>
                        : props.row[`${organ}Pests`] || '',
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === `VTA ${label}`)?.color),
                    filterRenderer: p => <TextFilter p={p} />
                },
                ['trunk', 'branch'].includes(organ) && {
                    name: `${i18n.t("Épiphytes")} ${label}`, key: `${organ}Epiphytes`, width: 200, category: `VTA ${label}`,
                    sortable: false, visible: requiredFields[`${organ}Epiphytes`] && treesPF[`${organ}Epiphytes`],
                    formatter: (props) => this.didChange(`${organ}Epiphytes`, props.row.id)
                        ? <div className='modified'>{props.row[`${organ}Epiphytes`]}</div>
                        : props.row[`${organ}Epiphytes`] || '',
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === `VTA ${label}`)?.color),
                    filterRenderer: p => <TextFilter p={p} />
                },
                ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === `VTA ${label}`))
            ].filter(c => c)),
            ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Indicateurs')),
            ...this.getCustomCategoriesColumns(),
            ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Autres'))
        ];

        if (this.props.project.orderConfig)
            data.columns.sort((a, b) => {
                const aOrder = a.order || FieldsUtil.getCategoryOrder(this.props.project.orderConfig, 'Arbre', a.category)
                const bOrder = b.order || FieldsUtil.getCategoryOrder(this.props.project.orderConfig, 'Arbre', b.category)
                return aOrder - bOrder;
            });

        this.setState(({ filters }) => {
            this.customFieldFilters.forEach(customFieldId => filters[customFieldId] = '');
            return { filters };
        });

        const createRows = (elements) => {
            const { project } = this.props;

            data.rows = elements.map((history) => {
                const properties = history.tree.properties;
                // Récupération des valeurs de l'arbre
                const essence = this.props.essences.find(x => x.id === properties.essenceId);
                const coverType = this.props.coverTypes.find(x => x.id === properties.coverTypeId)?.label;
                const interactions = properties.interactionId?.length > 0 ? properties.interactionId?.map(id => this.props.interactions.find(x => x.id === id)?.label)?.join(', ') : '';
                const microHabitats = properties.microHabitatId?.length > 0 ? properties.microHabitatId?.map(id => this.props.microHabitats.find(x => x.id === id)?.label)?.join(', ') : '';
                const vigor = this.props.vigors.find(x => x.id === properties.vigorId)?.label;
                const healthReview = this.props.healthReviews.find(x => x.id === properties.healthReviewId)?.value;
                const ontogenicStage = this.props.ontogenicStages.find(x => x.id === properties.ontogenicStageId)?.value;
                const risk = this.props.risks.find(x => x.id === properties.riskId)?.label;
                const tippingRisk = this.props.tippingRisks.find(x => x.id === properties.tippingRiskId)?.label;
                const organCaliber = this.props.organCalibers.find(x => x.id === properties.organCaliberId)?.label;
                const target = this.props.targets.find(x => x.id === properties.targetId)?.label;
                const treePort = this.props.treePorts.find(x => x.id === properties.treePortId)?.label;
                const plantationType = this.props.plantationTypes.find(x => x.id === properties.plantationTypeId)?.label;
                const plantationCoefficient = this.props.plantationCoefficients.find(x => x.id === properties.plantationCoefficientId)?.value;
                const situationCoefficient = this.props.situationCoefficients.find(x => x.id === properties.situationCoefficientId)?.value;
                const patrimonialCoefficient = this.props.patrimonialCoefficients.find(x => x.id === properties.patrimonialCoefficientId)?.value;
                const biggestTrunk = TreesUtil.getBiggestTrunk(properties.trunks);
                const height = biggestTrunk?.height ? Math.round(biggestTrunk.height) / 100 : 0;
                const trunkHeight = properties.dimensions ? Math.round(properties.dimensions.trunkHeight) / 100 : 0;
                const circumference = FormattersUtil.getTrunkCircumference(biggestTrunk?.circumference, project.trunkCircumferenceUnit) || 0;
                const crownDiameter = biggestTrunk?.crownDiameter ? Math.round(biggestTrunk.crownDiameter) / 100 : 0;
                const plantingDate = properties.plantingDate ? format(properties.plantingDate, 'dd/MM/yyyy') : '';

                let tags = '';
                if (properties.tagId)
                    properties.tagId.forEach(tagId => {
                        const tag = projectTags.find(x => x.id === tagId)?.label;
                        tags += tags === '' ? tag : ', ' + tag;
                    });

                const row = {
                    id: history.id,
                    date: `${DatesUtil.getFormattedLocaleDateString(history.date)}, ${DatesUtil.getFormattedLocaleTimeString(history.date)}`,
                    action: history.action,
                    username: history.username,
                    customReference: properties.customReference,
                    vernacularName: essence?.vernacularName || '',
                    gender: essence?.gender || '',
                    species: essence?.species || '',
                    cultivar: essence?.cultivar || '',
                    place: properties.place,
                    height: height || '',
                    circumference: circumference || '',
                    crownDiameter: crownDiameter || '',
                    coverType: coverType,
                    plantationType: plantationType,
                    plantingDate: plantingDate,
                    age: properties.age || '',
                    numberOfTrunks: properties.numberOfTrunks,
                    trunkHeight: trunkHeight,
                    vigor: vigor,
                    risk: risk,
                    tippingRisk: tippingRisk,
                    organCaliber: organCaliber,
                    target: target,
                    treePort: treePort,
                    healthReview: healthReview,
                    ontogenicStage: ontogenicStage,
                    interactions: interactions,
                    microHabitats: microHabitats,
                    plantationCoefficient: plantationCoefficient,
                    situationCoefficient: situationCoefficient,
                    patrimonialCoefficient: patrimonialCoefficient,
                    isEmpty: properties.isEmpty ? i18n.t("Oui") : i18n.t("Non"),
                    isDead: properties.isDead ? i18n.t("Oui") : i18n.t("Non"),
                    isStump: properties.isStump ? i18n.t("Oui") : i18n.t("Non"),
                    isIndexed: properties.isIndexed ? i18n.t("Oui") : i18n.t("Non"),
                    isRemarkable: properties.isRemarkable ? i18n.t("Oui") : i18n.t("Non"),
                    tags: tags,
                    observation: properties.observation,
                    isSameState: this.isSameState(properties),
                    ...treeOrgans.reduce((prevValue, { organ }) => ({
                        ...prevValue,
                        [`${organ}Symptoms`]: properties[`${organ}SymptomId`]?.length > 0 ? properties[`${organ}SymptomId`]?.map(id => this.props[`${organ}Symptoms`].find(x => x.id === id)?.label)?.join(', ') : '',
                        [`${organ}Pathogens`]: properties[`${organ}PathogenId`]?.length > 0 ? properties[`${organ}PathogenId`]?.map(id => this.props.pathogens.find(x => x.id === id)?.label)?.join(', ') : '',
                        [`${organ}Pests`]: properties[`${organ}PestId`]?.length > 0 ? properties[`${organ}PestId`]?.map(id => this.props.pests.find(x => x.id === id)?.label)?.join(', ') : '',
                        [`${organ}Epiphytes`]: properties[`${organ}EpiphyteId`]?.length > 0 ? properties[`${organ}EpiphyteId`]?.map(id => this.props.epiphytes.find(x => x.id === id)?.label)?.join(', ') : ''
                    }), {})
                };

                if (properties.customFields) {
                    Object.keys(properties.customFields).forEach(key => {
                        const customField = this.props.customFields.find(customField => customField.id === Number(key));
                        if (customField) {
                            row[key] = customField.type === 'boolean' ? properties.customFields[key] === 'true' ? i18n.t("Oui") : i18n.t("Non")
                                : customField.type === 'date'
                                    ? DatesUtil.getFormattedLocaleDateString(properties.customFields[key])
                                    : customField.type === 'list'
                                        ? customField.isMultiple
                                            ? properties.customFields[key].split(',').map(id => customField.dropdownCustomFieldValues.find(dcfv => dcfv.id === Number(id))?.label).join(', ')
                                            : customField.dropdownCustomFieldValues.find(dcfv => dcfv.id === Number(properties.customFields[key]))?.label
                                        : properties.customFields[key];
                        }
                    });
                }

                return row;
            }).reverse();

            const initialOrder = data.rows.map(row => row.id);
            this.setState({ data, elements, initialOrder, isLoading: false });
        }

        // Ajout des données
        this.props.unlockEditedProperties();
        const { elementHistory, layer } = this.props;
        if (!elementHistory || elementHistory[0]?.treeId !== this.state.id) {
            TreesService.getTreeHistory(layer[0].feature.id).then(histories => {
                if (histories) {
                    this.props.setElementHistory(histories);
                    createRows(histories);
                } else this.setState({ loadingFailed: true });
            });
        } else {
            const histories = [...elementHistory];
            this.props.setElementHistory(histories);
            createRows(histories);
        }
    }

    getCustomColumns = (category, isDefaultCategory = true) => {
        const { activeOrganization, customFields, project } = this.props;
        const areCustomFieldsAvailable = (project.organization || activeOrganization).subscription.customFields;
        if (!areCustomFieldsAvailable) return [];

        const projectCustomFields = [...(project.projectCustomFields || [])].sort((a, b) => a.order - b.order);
        const pcfColumns = projectCustomFields.filter(pcf => pcf.fieldCategoryId === category.id).map((projectCustomField) => {
            const customField = customFields.find(cf => cf.id === projectCustomField.customFieldId);
            if (customField) this.customFieldFilters.push(String(customField.id));

            return customField?.category === 'Arbre' ? {
                name: customField.label + (['number', 'formula'].includes(customField.type) && customField.unit?.trim() ? ` (${customField.unit})` : ''), key: customField.id, width: 180, category: isDefaultCategory ? category.label : `${category.id}`,
                sortable: true, visible: true, customField,
                formatter: (props) => (
                    <div className={this.didChange(customField.id, props.row.id) ? 'modified' : null}>
                        {(props.row.isEmpty === i18n.t("Oui") && !customField.forEmpty) ||
                            (props.row.isDead === i18n.t("Oui") && !customField.forDead) ||
                            (props.row.isStump === i18n.t("Oui") && !customField.forStump)
                            ? <div className='disabled'></div>
                            : props.row[String(customField.id)] ? (
                                customField.type === 'url'
                                    ? <a href={props.row[String(customField.id)]?.includes('http') ? props.row[String(customField.id)] : '//' + props.row[String(customField.id)]} target='_blank' rel='noreferrer'>{props.row[String(customField.id)]}</a>
                                    : customField.type === 'formula'
                                        ? FormattersUtil.formatFormulaCustomField(customField, props.row[String(customField.id)])
                                        : props.row[String(customField.id)] + (customField.type === 'number' && customField.unit?.trim() ? customField.unit : '')
                            ) : ''}
                    </div>
                ),
                headerRenderer: (props) => this.getHeaderRenderer(props, category.color),
                filterRenderer: p => (
                    customField.type === 'boolean' ? <BooleanFilter p={p} />
                        : ['text', 'url', 'date'].includes(customField.type) || (customField.type === 'list' && customField.isMultiple) ? <TextFilter p={p} />
                            : customField.type === 'number' ? <NumberFilter p={p} step={customField.step} />
                                : customField.type === 'list' ? <DropDownFilter p={p} propertyOptions={customField.dropdownCustomFieldValues.map(dcfv => ({ label: dcfv.label, id: dcfv.id }))} isNullable />
                                    : null
                )
            } : null;
        }).filter(pcf => pcf);

        return pcfColumns;
    }

    getCustomCategoriesColumns = () => {
        const { project } = this.props;
        const fieldCategories = project?.fieldCategories || [];
        const fieldCategoriesToRender = fieldCategories.filter(fieldCategory => fieldCategory.category === 'Arbre' && (project?.projectCustomFields || []).find(pcf => pcf.fieldCategoryId === fieldCategory.id));
        return fieldCategoriesToRender.flatMap(fieldCategory => this.getCustomColumns(fieldCategory, false));
    }

    // Filtres
    areFiltersApplied = () => {
        if (!this.state.enableFilterRow) return false;
        let filtersApplied = false;
        for (const property in this.state.filters)
            if (this.state.filters[property]) filtersApplied = true;
        return filtersApplied;
    }

    toggleFilters = () => this.setState(prevState => ({ enableFilterRow: !prevState.enableFilterRow }));
    clearFilters = () => this.setState({ filters: initialFilters });

    getFilteredRows = () => {
        const filters = this.state.filters;
        let rows = [...this.state.data.rows];

        const $ = (str) => FormattersUtil.getNormalizedString(str);
        return rows.filter(r => {
            return !this.state.enableFilterRow || (
                (filters.vernacularName ? (r.vernacularName === filters.vernacularName || (filters.vernacularName === 'empty' && !r.vernacularName)) : true)
                && (filters.gender ? (r.gender === filters.gender || (filters.gender === 'empty' && !r.gender)) : true)
                && (filters.species ? (r.species === filters.species || (filters.species === 'empty' && !r.species)) : true)
                && (filters.cultivar ? (r.cultivar === filters.cultivar || (filters.cultivar === 'empty' && !r.cultivar)) : true)
                && (filters.coverType ? (r.coverType === filters.coverType || (filters.coverType === 'empty' && !r.coverType)) : true)
                && (filters.vigor ? (r.vigor === filters.vigor || (filters.vigor === 'empty' && !r.vigor)) : true)
                && (filters.healthReview || filters.healthReview === 0 ? (r.healthReview === filters.healthReview || (filters.healthReview === 'empty' && !r.healthReview && r.healthReview !== 0)) : true)
                && (filters.ontogenicStage ? (r.ontogenicStage === filters.ontogenicStage || (filters.ontogenicStage === 'empty' && !r.ontogenicStage)) : true)
                && (filters.risk ? (r.risk === filters.risk || (filters.risk === 'empty' && !r.risk)) : true)
                && (filters.tippingRisk ? (r.tippingRisk === filters.tippingRisk || (filters.tippingRisk === 'empty' && !r.tippingRisk)) : true)
                && (filters.organCaliber ? (r.organCaliber === filters.organCaliber || (filters.organCaliber === 'empty' && !r.organCaliber)) : true)
                && (filters.target ? (r.target === filters.target || (filters.target === 'empty' && !r.target)) : true)
                && (filters.plantationType ? (r.plantationType === filters.plantationType || (filters.plantationType === 'empty' && !r.plantationType)) : true)
                && (filters.trunks ? r.trunks === Number(filters.trunks) : true)
                && (filters.treePort ? (r.treePort === filters.treePort || (filters.treePort === 'empty' && !r.treePort)) : true)
                && (filters.plantationCoefficient ? (r.plantationCoefficient === filters.plantationCoefficient || (filters.plantationCoefficient === 'empty' && !r.plantationCoefficient)) : true)
                && (filters.situationCoefficient ? (r.situationCoefficient === filters.situationCoefficient || (filters.situationCoefficient === 'empty' && !r.situationCoefficient)) : true)
                && (filters.patrimonialCoefficient ? (r.patrimonialCoefficient === filters.patrimonialCoefficient || (filters.patrimonialCoefficient === 'empty' && !r.patrimonialCoefficient)) : true)
                && (filters.isEmpty ? r.isEmpty === filters.isEmpty : true)
                && (filters.isDead ? r.isDead === filters.isDead : true)
                && (filters.isStump ? r.isStump === filters.isStump : true)
                && (filters.isIndexed ? r.isIndexed === filters.isIndexed : true)
                && (filters.isRemarkable ? r.isRemarkable === filters.isRemarkable : true)
                && (filters.action ? $(r.action)?.includes($(filters.action)) : true)
                && (filters.username ? $(r.username)?.includes($(filters.username)) : true)
                && (filters.date ? $(r.date).includes($(filters.date)) : true)
                && (filters.customReference ? $(r.customReference)?.includes($(filters.customReference)) : true)
                && (filters.place ? $(r.place)?.includes($(filters.place)) : true)
                && (filters.interactions ? $(r.interactions)?.includes($(filters.interactions)) : true)
                && (filters.microHabitats ? $(r.microHabitats)?.includes($(filters.microHabitats)) : true)
                && (filters.rootSymptoms ? $(r.rootSymptoms)?.includes($(filters.rootSymptoms)) : true)
                && (filters.rootPathogens ? $(r.rootPathogens)?.includes($(filters.rootPathogens)) : true)
                && (filters.rootPests ? $(r.rootPests)?.includes($(filters.rootPests)) : true)
                && (filters.collarSymptoms ? $(r.collarSymptoms)?.includes($(filters.collarSymptoms)) : true)
                && (filters.collarPathogens ? $(r.collarPathogens)?.includes($(filters.collarPathogens)) : true)
                && (filters.collarPests ? $(r.collarPests)?.includes($(filters.collarPests)) : true)
                && (filters.trunkSymptoms ? $(r.trunkSymptoms)?.includes($(filters.trunkSymptoms)) : true)
                && (filters.trunkPathogens ? $(r.trunkPathogens)?.includes($(filters.trunkPathogens)) : true)
                && (filters.trunkPests ? $(r.trunkPests)?.includes($(filters.trunkPests)) : true)
                && (filters.trunkEpiphytes ? $(r.trunkEpiphytes)?.includes($(filters.trunkEpiphytes)) : true)
                && (filters.branchSymptoms ? $(r.branchSymptoms)?.includes($(filters.branchSymptoms)) : true)
                && (filters.branchPathogens ? $(r.branchPathogens)?.includes($(filters.branchPathogens)) : true)
                && (filters.branchPests ? $(r.branchPests)?.includes($(filters.branchPests)) : true)
                && (filters.branchEpiphytes ? $(r.branchEpiphytes)?.includes($(filters.branchEpiphytes)) : true)
                && (filters.leafSymptoms ? $(r.leafSymptoms)?.includes($(filters.leafSymptoms)) : true)
                && (filters.leafPathogens ? $(r.leafPathogens)?.includes($(filters.leafPathogens)) : true)
                && (filters.leafPests ? $(r.leafPests)?.includes($(filters.leafPests)) : true)
                && (filters.tags ? $(r.tags)?.includes($(filters.tags)) : true)
                && (filters.observation ? $(r.observation)?.includes($(filters.observation)) : true)
                && (filters.plantingDate ? $(r.plantingDate)?.includes($(filters.plantingDate)) : true)
                && (filters.height ? r.height === Number(filters.height) : true)
                && (filters.trunkHeight ? r.trunkHeight === Number(filters.trunkHeight) : true)
                && (filters.crownDiameter ? r.crownDiameter === Number(filters.crownDiameter) : true)
                && (filters.circumference ? r.circumference === Number(filters.circumference) : true)
                && (filters.age ? r.age === Number(filters.age) : true)
            );
        });
    }

    // Tri
    handleSort = (columnKey, direction) => this.setState({ sortColumn: columnKey, sortDirection: direction }, this.sortRows);
    sortRows = () => {
        const sortDirection = this.state.sortDirection;
        let rows = [...this.state.data.rows];
        if (sortDirection === 'NONE') {
            for (let i = 0; i < this.state.initialOrder.length; i++) {
                let temp = rows[i];
                const index = rows.findIndex(row => row.id === this.state.initialOrder[i]);
                rows[i] = rows[index];
                rows[index] = temp;
            }

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: rows
                }
            }));
        } else {
            const sortColumn = this.state.sortColumn;
            if (['height', 'trunkHeight', 'circumference', 'crownDiameter', 'age', 'healthReview', 'trunks',
                'ontogenicStage', 'carbonStock', 'plantationCoefficient', 'situationCoefficient', 'patrimonialCoefficient'].includes(sortColumn))
                rows = rows.sort((a, b) => (a[sortColumn] || 0) - (b[sortColumn] || 0));
            else if (sortColumn === 'date')
                rows = rows.sort((a, b) => {
                    const aDate = DatesUtil.convertDateStringToDate(a[sortColumn]), bDate = DatesUtil.convertDateStringToDate(b[sortColumn]);
                    return !aDate ? -1 : !bDate ? 1 : aDate - bDate;
                });
            else rows = rows.sort((a, b) => (a[sortColumn] || '').localeCompare(b[sortColumn] || ''));

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: sortDirection === 'DESC' ? rows.reverse() : rows
                }
            }));
        }
    }

    handleKeyDown = (e) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
            const { selectedColumn, selectedRow } = this.state;
            if (selectedColumn && selectedRow) navigator.clipboard.writeText(selectedRow[selectedColumn.key] || '');
        }
    }

    didChange = (key, historyId) => {
        if (this.state.elements.length > 0) {
            const index = this.state.initialOrder.findIndex(id => id === historyId);
            if (index !== -1 && index < this.state.initialOrder.length - 1) {
                const history = this.state.data.rows.find(row => row.id === historyId);
                const prevHistoryId = this.state.initialOrder[index + 1];
                const prevHistory = this.state.data.rows.find(row => row.id === prevHistoryId);
                if (JSON.stringify(history[key]) !== JSON.stringify(prevHistory[key]))
                    return true;
            }
        }

        return false;
    }

    isSameState = (properties) => {
        const { elementHistory } = this.props;
        if (elementHistory) {
            const latestHistory = JSON.parse(JSON.stringify(elementHistory[elementHistory.length - 1].tree.properties));
            const targetHistory = JSON.parse(JSON.stringify(properties));
            delete latestHistory.id; delete latestHistory.treeSnapshotId;;
            delete targetHistory.id; delete targetHistory.treeSnapshotId;;

            [...(latestHistory.trunks || []), ...(targetHistory.trunks || [])].forEach(trunk => {
                delete trunk.id;
                delete trunk.propertiesId;
            });

            return JSON.stringify(targetHistory) === JSON.stringify(latestHistory);
        }
    }

    restoreHistory = () => {
        const { historyToRestore } = this.state;

        const history = this.state.elements.find(history => history.id === historyToRestore);
        if (history) {
            const properties = history.tree.properties;
            const { layer, elementHistory, project } = this.props;

            this.setState({ isRestoring: true });
            UpdatesUtil.updateTree(layer[0], properties, layer, true, this.props.fieldList, this.props.treesLayer, 'restoring', project.id, this.props.webSocketHubs, { thematicMaps: this.props.project?.thematicMaps })
                .then(response => {
                    if (response?.data?.history) {
                        this.setState(prevState => ({ elements: [...prevState.elements, response.data.history] }));
                        if (elementHistory) this.props.setElementHistory([...elementHistory, response.data.history]).then(this.loadData);
                    }
                })
                .finally(() => {
                    this.setState({ isRestoring: false, historyToRestore: 0 });
                    this.props.setEditedProperties(null);
                });
        }
    };
}

const mapStateToProps = (state) => {
    return {
        essences: state.essences,
        coverTypes: state.coverTypes,
        interactions: state.interactions,
        microHabitats: state.microHabitats,
        rootSymptoms: state.rootSymptoms,
        collarSymptoms: state.collarSymptoms,
        trunkSymptoms: state.trunkSymptoms,
        branchSymptoms: state.branchSymptoms,
        leafSymptoms: state.leafSymptoms,
        pathogens: state.pathogens,
        pests: state.pests,
        epiphytes: state.epiphytes,
        vigors: state.vigors,
        healthReviews: state.healthReviews,
        ontogenicStages: state.ontogenicStages,
        risks: state.risks,
        tippingRisks: state.tippingRisks,
        organCalibers: state.organCalibers,
        targets: state.targets,
        treePorts: state.treePorts,
        plantationTypes: state.plantationTypes,
        plantationCoefficients: state.plantationCoefficients,
        situationCoefficients: state.situationCoefficients,
        patrimonialCoefficients: state.patrimonialCoefficients,
        project: state.project,
        layer: state.layer,
        elementHistory: state.elementHistory,
        webSocketHubs: state.webSocketHubs,
        projectCollaborators: state.projectCollaborators,
        isDarkTheme: state.isDarkTheme,
        rights: state.rights,
        defaultFieldCategories: state.defaultFieldCategories,
        customFields: state.project
            ? [...state.customFields, ...state.organizationCustomFields || [], ...(state.projectsCustomFields[state.project?.id] || [])]
            : state.customFields
    };
};

const mapDispatchToProps = {
    setElementHistory,
    setEditedProperties,
    unlockEditedProperties
};

export default connect(mapStateToProps, mapDispatchToProps)(TreeHistory);