import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Label } from 'semantic-ui-react';
import { Rnd } from 'react-rnd';
// Librairies
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import i18n from '../../locales/i18n';
// Ressources
import naturaBirds from '../../resources/svgs/natura-birds.svg';
import naturaHabitats from '../../resources/svgs/natura-habitats.svg';
// Utils
import StylesUtil from '../../utils/StylesUtil';
import TreesUtil from '../../utils/TreesUtil';
import ProjectsUtil from '../../utils/ProjectsUtil';
import GreenSpacesUtil from '../../utils/GreenSpacesUtil';
import { isMobileOnly } from 'react-device-detect';
import DatesUtil from '../../utils/DatesUtil';

class Legend extends Component {
    state = {
        defaultPosition: null,
        showLegend: true,
        legends: {
            trees: { label: i18n.t("Arbres"), counter: true, isClickable: true },
            greenSpaces: { label: i18n.t("Espaces verts"), counter: true, isClickable: true },
            furnitures: { label: i18n.t("Mobilier urbain"), counter: true, isClickable: true },
            markers: { label: i18n.t("Repères"), counter: true, isClickable: true },
            natura2000: {
                label: 'Natura 2000',
                children: [
                    { label: i18n.t("Oiseaux"), style: { background: `url(${naturaBirds})` } },
                    { label: i18n.t("Habitats"), style: { background: `url(${naturaHabitats})` } }
                ]
            },
            ...(this.props.project?.wmsServices?.reduce((prevValue, wmsService) => ({ ...prevValue, [wmsService.id]: { label: wmsService.label, type: wmsService.type, legendURL: wmsService.legendURL } }), {}) || {})
        }
    }

    render() {
        return (
            <>
                {this.state.defaultPosition &&
                    <>
                        <div id='legend-drag-area' style={{ position: 'absolute', height: '100%', width: '100%', zIndex: 1000, pointerEvents: 'none' }}></div>
                        <Rnd
                            disableDragging={isMobileOnly}
                            position={{ x: this.state.defaultPosition?.x || 0, y: this.state.defaultPosition?.y || 0 }} enableResizing={false}
                            onDrag={() => {
                                this.isDragging = true;
                                const legendDragArea = document.getElementById('legend-drag-area');
                                legendDragArea.style.backgroundColor = this.isLegendInside() ? 'transparent' : 'rgba(255, 99, 71, 0.5)';
                            }}
                            onDragStop={this.handleDragend} style={{ zIndex: 500 }} bounds='parent'
                        >
                            <div id='legend-container'>{this.state.showLegend && this.renderLegends(this.state.legends)}</div>
                        </Rnd>
                    </>}
            </>
        );
    }

    componentDidMount = () => {
        this.hiddenChildren = [];
        this.isDragging = false;

        this.setState({ defaultPosition: this.getCachedPosition() }, () => {
            if (!this.isLegendInside()) {
                let legendContainer = document.getElementById('legend-container');
                legendContainer.style.bottom = 0;
                const newPosition = this.getCachedPosition(true);
                this.storePosition(newPosition);
                this.setState({ defaultPosition: newPosition });
            }
        });
        window.addEventListener('resize', this.updateLegendPosition);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.projectCollaborators !== this.props.projectCollaborators)
            this.publicFields = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators);

        if (prevProps.fieldList !== this.props.fieldList) {
            if (this.publicFields?.main?.trees) this.updateLegend(this.props.treesLayer);
            if (this.publicFields?.main?.greenSpaces) this.updateLegend(this.props.greenSpacesLayer);
            if (this.publicFields?.main?.furnitures) this.updateLegend(this.props.furnituresLayer);
            if (this.publicFields?.main?.markers) this.updateLegend(this.props.markersLayer);
        }

        if (prevState.defaultPosition !== this.state.defaultPosition && !prevState.defaultPosition) {
            let legendContainer = document.getElementById('legend-container');
            if (legendContainer && !this.state.defaultPosition.isNearestTop) legendContainer.style.bottom = 0;
        }

        if (prevProps.overlays !== this.props.overlays) { // Si les overlays ont changés
            const prevOverlays = [...prevProps.overlays.filter(overlay => overlay.showToggleLegend)];
            const overlays = [...this.props.overlays.filter(overlay => overlay.showToggleLegend)];

            prevOverlays.forEach((prevOverlay, index) => {
                const overlay = overlays[index];
                if (overlay && (prevOverlay.activeChild !== overlay.activeChild || prevOverlay.isShown !== overlay.isShown || prevOverlay.isLegendShown !== overlay.isLegendShown)) { // Et qu'un overlay a changé de visibilité ou d'activeChild
                    if (overlay.layer) {
                        const type = this.getLegendType(overlay.layer);
                        if (!type) return;
                        const layerName = [i18n.t("Arbres"), i18n.t("Espaces verts"), i18n.t("Mobilier urbain")].includes(overlay.layer.label) ? overlay.layer.activeChild : overlay.layer.label;

                        if (this.state.legends[type].label === layerName) this.toggleLegend(type, overlay.isLegendShown);
                        else {
                            if (this.state.legends[type].counter && this.hiddenChildren.length) this.toggleChildrenOnMap(type, null, null, true);
                            this.updateLegend(overlay.layer, { isVisible: overlay.isLegendShown ?? true }); // On update la légende de cet overlay
                        }
                    }
                }
            });
        }

        if (prevProps.project?.wmsServices !== this.props.project?.wmsServices && this.state.legends) {
            this.setState(prevState => {
                const legends = { ...prevState.legends };
                Object.keys(legends).filter(property => !isNaN(property)).forEach(wmsServiceId => {
                    const wmsService = this.props.project?.wmsServices.find(wmsService => wmsService.id === +wmsServiceId);
                    if (wmsService) {
                        legends[wmsServiceId].label = wmsService.label;
                        legends[wmsServiceId].type = wmsService.type;
                        legends[wmsServiceId].legendURL = wmsService.legendURL;
                    }
                });
                this.props.project?.wmsServices
                    ?.filter(wmsService => !this.state.legends[wmsService.id])
                    ?.forEach(wmsService => legends[wmsService.id] = { label: wmsService.label, type: wmsService.type, legendURL: wmsService.legendURL });

                return { legends };
            });
        }
    }

    componentWillUnmount = () => window.removeEventListener('resize', this.updateLegendPosition);
    renderLegends = (legends) => {
        return Object.keys(legends).map(legendName => {
            const legend = legends[legendName];
            const legendLabel = legend.label === 'Actions' ? `${legend.label} ${(legendName === 'trees' ? `(${i18n.t("Arbres").toLowerCase()})` : legendName === 'greenSpaces' ? `(${i18n.t("Espaces verts").toLowerCase()})` : `(${i18n.t("Mobilier urbain").toLowerCase()})`)}` : legend.label;
            const isVisible = isNaN(legendName) || !legend.type
                ? legend.isVisible
                : legend.legendURL && this.props[`${legend.type}s`].find(layer => layer.id === +legendName)?.isShown; // Gère les légendes des WMS customs

            if (isVisible) {
                const nbElements = this.getTotalCount(legend.children);
                return (
                    <div key={legendLabel} className='legend'>
                        <div className={`legend-title arrow${legend.isCollapsed ? ' down' : ''}`} onClick={() => this.toggleCollapseLegend(legendName)}>
                            <span style={{ marginRight: legend.counter && '5px' }}>{legendLabel}</span>
                            {legend.counter && <Label className={`legend-counter${!nbElements ? ' none' : ''}`}>{nbElements}</Label>}
                        </div>
                        <div className={`legend-body${legend.isCollapsed ? ' collapsed' : ''}`}>
                            {legend.children?.length && legend.children.map(child => {
                                if (child.isVisible === false) return null;
                                const style = {
                                    marginRight: '5px', display: 'inline-flex', position: 'relative', top: '3px', background: 'var(--black-50)', backgroundSize: 'contain',
                                    border: 'solid 1px black', width: '15px', minWidth: '15px', height: '15px', borderRadius: '10px', alignItems: 'center', justifyContent: 'center', ...child.style
                                };

                                return (
                                    <div key={child.label} className={`legend-child ${legend.isClickable && 'clickable'}`} onClick={() => { if (!this.isDragging && legend.isClickable) this.toggleChildrenOnMap(legendName, child.label, child.value); }}>
                                        {child.icon
                                            ? <FontAwesomeIcon icon={child.icon} style={{ color: 'white', width: '15px', marginRight: '5px' }} />
                                            : <div className='legend-canva' style={style}></div>}
                                        <span style={{ marginRight: legend.counter && '5px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} title={child.label}>
                                            {child.label}
                                        </span>
                                        {legend.counter && <Label className={`legend-counter${!child.count ? ' none' : ''}${child.isHidden ? ' transparent' : ''}`}>{child.count}</Label>}
                                    </div>
                                );
                            })}
                            {legend.legendURL && <img src={legend.legendURL} style={{ maxWidth: '100%' }} />}
                        </div>
                    </div>
                );
            }
            else return null;
        }).filter(l => l);
    }

    isLegendInside = () => {
        const legendContainer = document.getElementById('legend-container');
        const legendContainerBounds = legendContainer.getBoundingClientRect();
        const parent = document.getElementById('projectMapContainer');
        const parentBounds = parent.getBoundingClientRect();

        return (legendContainerBounds.x >= parentBounds.left && legendContainerBounds.x < (parentBounds.right - 5)
            && legendContainerBounds.y >= (parentBounds.top - legendContainer.clientHeight + 5) && legendContainerBounds.y <= (parentBounds.bottom - 5))
    };

    updateLegendPosition = () => this.setState({ defaultPosition: this.getCachedPosition() });
    handleDragend = (_, d) => {
        setTimeout(() => this.isDragging = false, 250);
        let legendContainer = document.getElementById('legend-container');
        let defaultPosition = { ...this.checkNearest(d.x, d.y, legendContainer), x: d.x, y: d.y };
        legendContainer.style.bottom = defaultPosition.isNearestTop ? 'auto' : 0;
        if (defaultPosition.isNearestTop !== this.state.defaultPosition.isNearestTop) {
            defaultPosition.y = defaultPosition.isNearestTop
                ? defaultPosition.y - legendContainer.getBoundingClientRect().height
                : defaultPosition.y + legendContainer.getBoundingClientRect().height;
        }

        const legendDragArea = document.getElementById('legend-drag-area');
        legendDragArea.style.backgroundColor = 'transparent';
        this.setState({ defaultPosition }, () => {
            if (!this.isLegendInside()) {
                defaultPosition = this.getCachedPosition(true);
                legendContainer.style.bottom = 0;
            }
            this.storePosition(defaultPosition);
            this.setState({ defaultPosition });
        });
    }

    toggleCollapseLegend = (type) => {
        if (!this.isDragging)
            this.setState(prevState => ({ legends: { ...prevState.legends, [type]: { ...prevState.legends[type], isCollapsed: !prevState.legends[type].isCollapsed ? true : false } } }));
    }

    toggleLegend = (type, isVisible) => this.setState(prevState => ({ legends: { ...prevState.legends, [type]: { ...prevState.legends[type], isVisible } } }));
    getLegendType = (layer) => {
        return layer.label === i18n.t("Arbres") ? 'trees'
            : layer.label === i18n.t("Espaces verts") ? 'greenSpaces'
                : layer.label === i18n.t("Mobilier urbain") ? 'furnitures'
                    : layer.label === i18n.t("Repères") ? 'markers'
                        : [layer.label, layer.parentLabel].includes('Natura 2000') ? 'natura2000'
                            : null;
    }

    updateLegend = (layer, { forceUpdateHiddenChildren = false } = {}) => {
        const thematicMaps = this.props.project?.thematicMaps || [];
        if (!layer) return;

        const nbEmpty = this.props.treesLayer.getLayers().filter(x => x.feature.properties.isEmpty || x.feature.properties.toCutDown === 4).length
        const layerName = [i18n.t("Arbres"), i18n.t("Espaces verts"), i18n.t("Mobilier urbain")].includes(layer.label) ? layer.activeChild : layer.label;
        const type = this.getLegendType(layer);
        if (!type) return;
        let children = [];
        let propertyName = null;
        const overlay = this.props.overlays.find(o => o.label === layer.label);
        const isVisible = overlay?.isLegendShown;
        let isLegendVisible = isVisible;
        let label = layerName;

        if (!isNaN(layerName)) { // Cartes thématiques personnalisées
            let thematicMap = thematicMaps.find(thematicMap => thematicMap.id === layerName);
            if (thematicMap) {
                const layerContainer = thematicMap.category === 'Arbre' ? this.props.treesLayer : thematicMap.category === 'Espace vert' ? this.props.greenSpacesLayer : this.props.furnituresLayer;

                thematicMap = JSON.parse(JSON.stringify(thematicMap));
                if (thematicMap.type === 'number' || (thematicMap.isNumeric && thematicMap.useNumericalConditions))
                    thematicMap.colors.forEach(color => {
                        color.label = ['<', '>'].some(character => color.key.includes(character)) ? color.key.slice(0, 1) + ' ' + color.key.slice(1) : color.key;
                    });
                else if (thematicMap.type === 'date')
                    thematicMap.colors.forEach(color => {
                        color.label = ['<', '>'].some(character => color.key.includes(character)) ? color.key.slice(0, 1) + ' ' + DatesUtil.getFormattedLocaleDateString(color.key.slice(1))
                            : color.key.includes('|') ? DatesUtil.getFormattedLocaleDateString(color.key.split('|')[0]) + ' - ' + DatesUtil.getFormattedLocaleDateString(color.key.split('|')[1])
                                : DatesUtil.getFormattedLocaleDateString(color.key);
                    });
                else {
                    const options = this.getPropertyOptions(thematicMap);
                    thematicMap.colors.forEach(color => {
                        const option = options.find(option => option.key === color.key);
                        if (option) color.label = option.label;
                    });
                }

                label = thematicMap.label;
                children = thematicMap.colors.filter(color => color.label).map(color => (
                    {
                        label: color.label, value: color.key,
                        count: layerContainer.getLayers().filter(l => {
                            const properties = l.feature.properties;
                            if (properties.toCutDown === 4) return false;

                            let propertyValue;
                            switch (thematicMap.property) {
                                case 'actionId':
                                    const projectActions = this.props.projectActions?.filter(pa => pa.action.categories.includes(thematicMap.category) && pa.projectActionElements.find(pae => pae.elementId === l.feature.id));
                                    let recurrences = projectActions?.flatMap(pa => (
                                        (pa.projectActionElements.find(pae => pae.elementId === l.feature.id)?.projectActionElementRecurrences || [])
                                            .filter(paer => !paer.validationDate && (thematicMap.useLateActions || new Date(paer.date) > new Date()))
                                            .map(paer => ({ ...paer, actionId: pa.action.id }))
                                    )) || [];

                                    if (recurrences.length) {
                                        recurrences.sort((a, b) => new Date(a.date) - new Date(b.date));
                                        propertyValue = recurrences[0].actionId;
                                    } else propertyValue = 0;
                                    break;
                                case 'gender': case 'species': case 'cultivar': case 'vernacularName':
                                    propertyValue = this.props.essences.find(essence => essence.id === properties.essenceId)?.[thematicMap.property]; break;
                                case 'isFruit': propertyValue = this.props.essences.find(essence => essence.id === properties.essenceId)?.fruitProduction > 0 ? true : false; break;
                                case 'toCutDown': propertyValue = [1, 2, 3].includes(properties.toCutDown); break;
                                case 'trunkHeight': propertyValue = properties.dimensions[thematicMap.property] / 100; break;
                                case 'height': case 'circumference': case 'crownDiameter':
                                    const biggestTrunk = TreesUtil.getBiggestTrunk(properties.trunks);
                                    propertyValue = biggestTrunk ? biggestTrunk[thematicMap.property] : 0;
                                    if (propertyValue && ['height', 'crownDiameter'].includes(thematicMap.property)) propertyValue /= 100;
                                    break;
                                case 'runoffCoefficientId':
                                    const dominantComposition = this.props.dominantCompositions.find(dominantComposition => dominantComposition.id === properties.dominantCompositionId);
                                    let runoffCoefficient = this.props.runoffCoefficients.find(runoffCoefficient => runoffCoefficient.id === dominantComposition?.runoffCoefficientId);
                                    propertyValue = GreenSpacesUtil.getRunoffCoefficientString(runoffCoefficient);
                                    break;
                                case 'healthReviewId': case 'ontogenicStageId': case 'plantationCoefficientId': case 'situationCoefficientId': case 'patrimonialCoefficientId': case 'managementClassId':
                                    const listName = thematicMap.property.replace('Id', thematicMap.property === 'managementClassId' ? 'es' : 's')
                                    propertyValue = thematicMap.useNumericalConditions
                                        ? this.props[listName].find(field => field.id === properties[thematicMap.property])?.value
                                        : properties[thematicMap.property];
                                    break;
                                case 'trunks':
                                    propertyValue = properties[thematicMap.property]?.length;
                                    break;
                                default: propertyValue = isNaN(thematicMap.property) ? properties[thematicMap.property] : properties.customFields?.[thematicMap.property]; break;
                            }

                            if (thematicMap.type === 'number' || (thematicMap.isNumeric && thematicMap.useNumericalConditions)) {
                                if (properties.isEmpty) return null;
                                if (color.key.includes('-')) {
                                    const values = color.key.split('-');
                                    return propertyValue >= Number(values[0]) && propertyValue <= Number(values[1]);
                                }
                                else if (color.key.includes('<')) return propertyValue < Number(color.key.slice(1));
                                else if (color.key.includes('>')) return propertyValue > Number(color.key.slice(1));
                                else return propertyValue === Number(color.key);
                            } else if (thematicMap.type === 'date') {
                                if (color.key.includes('|')) {
                                    const dates = color.key.split('|').map(isoDate => new Date(isoDate));
                                    return propertyValue
                                        && new Date(propertyValue) >= new Date(dates[0].getFullYear(), dates[0].getMonth(), dates[0].getDate(), 0, 0, 0)
                                        && new Date(propertyValue) <= new Date(dates[1].getFullYear(), dates[1].getMonth(), dates[1].getDate(), 23, 59, 59)
                                }
                                else if (color.key.includes('<')) {
                                    const date = new Date(color.key.slice(1));
                                    return propertyValue && new Date(propertyValue) < new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
                                } else if (color.key.includes('>')) {
                                    const date = new Date(color.key.slice(1));
                                    return propertyValue && new Date(propertyValue) > new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
                                }
                                else {
                                    const date = new Date(color.key), value = propertyValue && new Date(propertyValue);
                                    return date.getFullYear() === value.getFullYear() && date.getMonth() === value.getMonth() && date.getDate() === value.getDate();
                                };
                            } else return color.key === `${propertyValue}`;
                        }).length,
                        style: { background: color.value }
                    }
                ));

                const count = this.getTotalCount(children);
                const totalCount = layerContainer.getLayers().length;
                if (count + nbEmpty < totalCount) children.push({ label: i18n.t("Donnée manquante"), count: totalCount - count - nbEmpty, style: { background: StylesUtil.getMissingDataColor() } });
                if (nbEmpty) children.push({ label: i18n.t("Emplacement vide"), count: nbEmpty, style: { background: 'rgb(153,153,255)' } });
            }
        } else {
            switch (layerName) {
                case i18n.t("Aucun"): case i18n.t("Diamètre des couronnes"): case i18n.t("Repères"):
                    if ([layer.label, layer.parentLabel].includes(i18n.t("Arbres"))) {
                        label = i18n.t("Arbres");
                        children = [{ label: i18n.t("Arbres"), count: this.props.treesLayer ? this.props.treesLayer.getLayers().length : 0, style: { background: 'var(--primary-100)' } }];
                    } else if ([layer.label, layer.parentLabel].includes(i18n.t("Espaces verts"))) {
                        label = i18n.t("Espaces verts");
                        children = [{ label: i18n.t("Espaces verts"), count: this.props.greenSpacesLayer ? this.props.greenSpacesLayer.getLayers().length : 0, style: { background: 'var(--secondary-100)', borderRadius: '3px' } }];
                    } else if ([layer.label, layer.parentLabel].includes(i18n.t("Mobilier urbain"))) {
                        label = i18n.t("Mobilier urbain");
                        children = [{
                            label: i18n.t("Mobilier urbain"), count: this.props.furnituresLayer ? this.props.furnituresLayer.getLayers().length : 0,
                            style: { background: '#000', borderRadius: '50%', borderWidth: '4px', borderColor: 'var(--blue-100)' }
                        }];
                    } else if ([layer.label, layer.parentLabel].includes(i18n.t("Repères"))) {
                        label = i18n.t("Repères");
                        children = [{
                            label: i18n.t("Repères"), count: this.props.markersLayer ? this.props.markersLayer.getLayers().length : 0,
                            style: { backgroundColor: 'var(--pink-100)' }
                        }];
                    }
                    break;
                case i18n.t("Cotes sanitaires"):
                    propertyName = 'healthReviewId';
                    children = this.props.fieldList.healthReviews.map(field => (
                        {
                            label: field.description, value: field.id, count: this.props.treesLayer.getLayers().filter(x => x.feature.properties.toCutDown !== 4 && x.feature.properties.healthReviewId === field.id).length,
                            style: { background: StylesUtil.getHealthReviewColor(field.value) }
                        }
                    )).reverse();
                    children.push({ label: i18n.t("Donnée manquante"), count: this.props.treesLayer.getLayers().length - this.getTotalCount(children) - nbEmpty, style: { background: StylesUtil.getMissingDataColor() } });
                    children.push({ label: i18n.t("Emplacement vide"), count: nbEmpty, style: { background: StylesUtil.getHealthReviewColor() } });
                    break;
                case i18n.t("Vigueurs"):
                    propertyName = 'vigorId';
                    children = this.props.fieldList.vigors.map(field => (
                        {
                            label: field.label, value: field.id, count: this.props.treesLayer.getLayers().filter(x => x.feature.properties.toCutDown !== 4 && x.feature.properties.vigorId === field.id).length,
                            style: { background: StylesUtil.getVigorColor(field.label) }
                        }
                    )).reverse();
                    children.push({ label: i18n.t("Donnée manquante"), count: this.props.treesLayer.getLayers().length - this.getTotalCount(children) - nbEmpty, style: { background: StylesUtil.getMissingDataColor() } });
                    children.push({ label: i18n.t("Emplacement vide"), count: nbEmpty, style: { background: StylesUtil.getVigorColor() } });
                    break;
                case i18n.t("Risques"):
                    propertyName = 'riskId';
                    children = this.props.fieldList.risks.map(field => (
                        {
                            label: field.label, value: field.id, count: this.props.treesLayer.getLayers().filter(x => x.feature.properties.toCutDown !== 4 && x.feature.properties.riskId === field.id).length,
                            style: { background: StylesUtil.getRiskColor(field.label) }
                        }
                    )).reverse();
                    children.push({ label: i18n.t("Donnée manquante"), count: this.props.treesLayer.getLayers().length - this.getTotalCount(children) - nbEmpty, style: { background: StylesUtil.getMissingDataColor() } });
                    children.push({ label: i18n.t("Emplacement vide"), count: nbEmpty, style: { background: StylesUtil.getRiskColor() } });
                    break;
                case i18n.t("Stades ontogéniques"):
                    propertyName = 'ontogenicStageId';
                    children = this.props.fieldList.ontogenicStages.map(field => (
                        {
                            label: field.value, value: field.id, count: this.props.treesLayer.getLayers().filter(x => x.feature.properties.toCutDown !== 4 && x.feature.properties.ontogenicStageId === field.id).length,
                            style: { background: StylesUtil.getOntogenicStageColor(field.id) }
                        }
                    )).reverse();
                    const nbDead = this.props.treesLayer.getLayers().filter(x => x.feature.properties.isDead).length;
                    children.push({ label: i18n.t("Mort"), count: nbDead, style: { background: StylesUtil.getOntogenicStageColor(0) } });
                    children.push({ label: i18n.t("Donnée manquante"), count: this.props.treesLayer.getLayers().length - this.getTotalCount(children) - nbEmpty - nbDead, style: { background: StylesUtil.getMissingDataColor() } });
                    children.push({ label: i18n.t("Emplacement vide"), count: nbEmpty, style: { background: StylesUtil.getOntogenicStageColor() } });
                    break;
                case i18n.t("Âges"):
                    propertyName = 'age';
                    children = [
                        { label: i18n.t("Aucun âge"), style: { background: 'grey' } }, { label: i18n.t("{{range}} ans", { range: '0-15' }), style: { background: 'green' } },
                        { label: i18n.t("{{range}} ans", { range: '16-50' }), style: { background: 'lime' } }, { label: i18n.t("{{range}} ans", { range: '51-100' }), style: { background: 'yellow' } },
                        { label: i18n.t("{{range}} ans", { range: '101-200' }), style: { background: 'orange' } }, { label: i18n.t("{{range}} ans", { range: '201-300' }), style: { background: 'red' } },
                        { label: i18n.t("{{range}} ans", { range: '+ 300' }), style: { background: 'brown' } }
                    ].map((x, index) => ({ ...x, value: index, count: this.props.treesLayer.getLayers().filter(y => index === TreesUtil.getAgeIndex(y.feature.properties.age)).length }));
                    break;
                case i18n.t("Abattages"):
                    propertyName = 'toCutDown';
                    children = [
                        { label: i18n.t("Aucun abattage") }, { label: i18n.t("Abattage 1 - 3 ans") }, { label: i18n.t("Abattage < 1 an") },
                        { label: i18n.t("Abattage d'urgence") }, { label: i18n.t("Abattu") }
                    ].map((category, index) => ({
                        ...category, value: index,
                        count: this.props.treesLayer.getLayers().filter(layer => index === (layer.feature.properties.toCutDown || 0)).length,
                        style: { background: StylesUtil.getCutDownColor(index) }
                    })).reverse();
                    break;
                case i18n.t("Compositions dominantes"):
                    propertyName = 'dominantCompositionId';
                    children = this.props.fieldList.dominantCompositions.map(field => (
                        {
                            label: field.label, value: field.id, count: this.props.greenSpacesLayer.getLayers().filter(x => x.feature.properties.dominantCompositionId === field.id).length,
                            style: { background: StylesUtil.getDominantCompositionColor(field.label), borderRadius: '3px' }
                        }
                    ));
                    children.push({ label: i18n.t("Donnée manquante"), count: this.props.greenSpacesLayer.getLayers().length - this.getTotalCount(children), style: { background: StylesUtil.getMissingDataColor(), borderRadius: '3px' } });
                    break;
                case i18n.t("Actions"):
                    const parentName = layer.parentLabel || layer.label;
                    propertyName = 'actionsUrgency';
                    children = [
                        { label: i18n.t("Pas d'action future") }, { label: i18n.t("1 mois & +") }, { label: i18n.t("< 1 mois") }, { label: i18n.t("Urgente") }, { label: i18n.t("En retard") }
                    ].map((category, index) => {
                        let style = {
                            background: StylesUtil.getActionsUrgencyColor(index), borderRadius: parentName !== i18n.t("Arbres") ? '3px' : '10px',
                            width: '15px', height: '15px'
                        };

                        if (parentName === i18n.t("Mobilier urbain")) style = { ...style, background: '#000', borderRadius: '50%', borderWidth: '4px', borderColor: StylesUtil.getActionsUrgencyColor(index) };

                        return {
                            ...category, value: index, style,
                            count: (parentName === i18n.t("Arbres") ? this.props.treesLayer : parentName === i18n.t("Espaces verts") ? this.props.greenSpacesLayer : this.props.furnituresLayer).getLayers().filter(layer => index === (layer.feature.properties.actionsUrgency || 0)).length,
                        };
                    }).reverse();
                    break;
                case i18n.t("Stock carbone"):
                case i18n.t("Rafraîchissement"):
                case i18n.t("Services écosystémiques"):
                case 'Natura 2000': return;
                default: break;
            }
        }

        const childrenInState = this.state.legends[type].propertyName !== propertyName ? [] : this.state.legends[type].children || [];
        if (childrenInState)
            childrenInState.forEach(child => {
                let childToUpdate = children.find(x => x.id === child.id);
                if (child.isHidden) childToUpdate.isHidden = child.isHidden;
                childToUpdate.id = child.id;
            });
        else children.forEach(child => child.id = uuidv4());

        if (forceUpdateHiddenChildren) children = this.getUpdateHiddenElements(layer, children, type);

        this.setState(prevState => ({
            legends: {
                ...prevState.legends,
                [type]: { ...prevState.legends[type], label, propertyName, isVisible: isLegendVisible, children }
            }
        }));
    }

    toggleChildrenOnMap = (type, label, value, showHiddenChildren = false) => {
        const categories = {
            trees: { layer: this.props.treesLayer, getStyle: (l) => StylesUtil.getTreeActiveStyle(layer, this.props.fieldList, l.feature.properties, this.props.project?.thematicMaps) },
            greenSpaces: { layer: this.props.greenSpacesLayer, getStyle: (l) => StylesUtil.getGreenSpaceActiveStyle(layer.activeChild, this.props.fieldList, l.feature.properties, this.props.project?.thematicMaps) },
            furnitures: { layer: this.props.furnituresLayer, getStyle: (l) => StylesUtil.getFurnitureActiveStyle(layer.activeChild, this.props.fieldList, l.feature.properties, this.props.project?.thematicMaps) },
            markers: { layer: this.props.markersLayer, getStyle: (l) => StylesUtil.getMarkerActiveStyle(this.props.fieldList) }
        }
        const layer = categories[type].layer;
        const propertyName = this.state.legends[type].propertyName;
        const childrenToShow = showHiddenChildren ? this.hiddenChildren.filter(x => x.type === type) :
            this.hiddenChildren.length ? this.hiddenChildren.filter(x => x.type === type && this.elementMatchWithChild(x.layers[0], label, value, propertyName)) : [];

        if (showHiddenChildren || childrenToShow.length) {
            childrenToShow.forEach(childToShow => childToShow.layers.forEach(x => {
                const style = categories[type].getStyle(x);
                if (x.setStyle) x.setStyle(style);
                else x.setIcon(style);
                layer.addLayer(x);
            }));
            this.hiddenChildren = this.hiddenChildren.filter(x => !childrenToShow.includes(x));
        } else {
            const hiddenElement = this.hiddenChildren.find(y => y.propertyName === propertyName && y.value === value && y.type === type);
            if (!hiddenElement) this.hiddenChildren.push({ type, propertyName, value, label, layers: [] });

            layer.eachLayer(x => {
                if (this.elementMatchWithChild(x, label, value, propertyName)) {
                    layer.removeLayer(x);
                    const hiddenElements = this.hiddenChildren.find(y => y.propertyName === propertyName && y.value === value && y.type === type);
                    hiddenElements.layers.push(x);
                }
            });
        }

        let children = this.state.legends[type].children;
        let child = children.find(x => x.label === label);
        if (child) child.isHidden = child.isHidden ? false : true;

        this.setState(prevState => ({ legends: { ...prevState.legends, [type]: { ...prevState.legends[type], children } } }));
    }

    getUpdateHiddenElements = (layer, children, type) => {
        const layers = layer.getLayers();
        const propertyName = this.state.legends[type].propertyName;
        let updatedLabels = [];
        this.hiddenChildren.forEach(hiddenChild => {
            if (hiddenChild.propertyName === propertyName && hiddenChild.type === type) {
                const layersToHide = layers.filter(x => this.elementMatchWithChild(x, hiddenChild.label, hiddenChild.value, propertyName));
                if (layersToHide.length)
                    layersToHide.forEach(layerToHide => {
                        hiddenChild.layers.push(layerToHide);
                        layer.removeLayer(layerToHide);
                        if (!updatedLabels.includes(hiddenChild.label)) updatedLabels.push(hiddenChild.label);
                    });
            }

            // On met à jour les compteurs
            let childToUpdate = children.find(x => x.label === hiddenChild.label);
            if (childToUpdate) childToUpdate.count = hiddenChild.layers.length;
        });

        // On met à jour les children sur base des éléments masqués
        let childrenToUpdate = children.filter(x => updatedLabels.includes(x.label));
        if (childrenToUpdate.length && this.hiddenChildren.length)
            childrenToUpdate.forEach(childToUpdate => {
                childToUpdate.count = this.hiddenChildren.find(x => x.propertyName === propertyName && x.value === childToUpdate.value).layers.length;
                childToUpdate.isHidden = true;
            });

        return children;
    }

    elementMatchWithChild = (element, label, value, propertyName) => {
        return element ? String(element.feature.properties[propertyName]) === String(value)
            || (propertyName === 'age' && TreesUtil.getAgeIndex(element.feature.properties.age) === Number(value))
            || (propertyName === 'toCutDown' && String(value) === '0' && !element.feature.properties.toCutDown)
            || (label === 'Mort' && element.feature.properties.isDead)
            || (label === 'Vide' && (element.feature.properties.isEmpty || !element.feature.properties[propertyName])
                && !(propertyName === 'ontogenicStageId' && element.feature.properties.isDead)) : false;
    }

    getTotalCount = (children = []) => {
        let count = 0;
        children.forEach(child => count += child.count);
        return count;
    }

    getCachedPosition = (reset = false) => {
        let defaultPosition = localStorage.getItem('legendPosition');
        if (!reset && defaultPosition) {
            defaultPosition = JSON.parse(defaultPosition);
            const { isNearestTop, isNearestLeft, x, y } = defaultPosition;
            if (!isNearestTop) defaultPosition.y = window.innerHeight - y;
            if (!isNearestLeft) defaultPosition.x = window.innerWidth - x;
        } else defaultPosition = { x: window.innerWidth - 300, y: window.innerHeight - 130, isNearestTop: false, isNearestLeft: false };
        return defaultPosition;
    }

    storePosition = (defaultPosition) => {
        let finalX = defaultPosition.x, finalY = defaultPosition.y;
        if (!defaultPosition.isNearestTop) finalY = window.innerHeight - finalY;
        if (!defaultPosition.isNearestLeft) finalX = window.innerWidth - finalX;
        localStorage.setItem('legendPosition', JSON.stringify({ ...defaultPosition, x: finalX, y: finalY }));
    }

    checkNearest = (x, y, legendContainer) => {
        const top = !this.state.defaultPosition.isNearestTop ? y - legendContainer.getBoundingClientRect().height : y;
        const left = x;
        const bottom = this.state.defaultPosition.isNearestTop ? window.innerHeight - (y + legendContainer.getBoundingClientRect().height) - 60 : window.innerHeight - y;
        const right = window.innerWidth - (x + legendContainer.getBoundingClientRect().width);
        const isNearestTop = top <= bottom;
        const isNearestLeft = left <= right;
        return { isNearestTop, isNearestLeft };
    }

    toggleLegendVisibility = (visibility) => {
        this.setState({ showLegend: visibility });
    }

    getPropertyOptions = ({ property, category }) => { // Retourne la liste de choix des propriétés qui en possèdent une
        const layers = !property.includes('dominant') ? this.props.treesLayer.getLayers() : this.props.greenSpacesLayer.getLayers();
        const propertyName = !property.includes('dominant') ? 'essenceId' : 'dominantEssenceId';
        const essences = this.props.essences.filter(essence => layers.some(layer => layer.feature?.properties[propertyName] === essence.id));

        const amenityFormulaType = this.props.project.projectFormulaVersions.find(pfv => pfv.formulaId === 4)?.formulaType;
        const descriptionName = amenityFormulaType === 'Wallonie' ? 'descriptionWln'
            : amenityFormulaType === 'Bruxelles' ? 'descriptionBxl'
                : 'descriptionFr';
        const plantationCoefficients = this.props.plantationCoefficients.filter(plantationCoefficient => plantationCoefficient[descriptionName]);
        const situationCoefficients = this.props.situationCoefficients.filter(situationCoefficient => situationCoefficient[descriptionName]);

        switch (property) {
            case 'actionId':
                const actions = this.props.projectActions
                    ?.filter(projectAction => projectAction.action.categories.includes(category))
                    .reduce((prevValue, projectAction) => ({ ...prevValue, [projectAction.action.id]: projectAction.action.label }), {});
                return actions ? Object.keys(actions)
                    .map(actionId => ({ key: actionId, label: actions[actionId] }))
                    .sort((a, b) => { // On trie par ordre alphabétique
                        const textA = a.label.toUpperCase(), textB = b.label.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    }) : [];
            case 'vernacularName': case 'dominantVernacularName':
                return [...new Set(essences
                    .filter(essence => essence.vernacularName)
                    .map(essence => essence.vernacularName))]
                    .map(vernacularName => ({ key: vernacularName, label: vernacularName }))
                    .sort((a, b) => { // On trie par ordre alphabétique
                        const textA = a.key.toUpperCase(), textB = b.key.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
            case 'gender': case 'dominantGender':
                return [...new Set(essences
                    .filter(essence => essence.gender)
                    .map(essence => essence.gender))]
                    .map(gender => ({ key: gender, label: gender }))
                    .sort((a, b) => { // On trie par ordre alphabétique
                        const textA = a.key.toUpperCase(), textB = b.key.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
            case 'species': case 'dominantSpecies':
                return [...new Set(essences
                    .filter(essence => essence.species)
                    .map(essence => essence.species))]
                    .map(species => ({ key: species, label: species }))
                    .sort((a, b) => { // On trie par ordre alphabétique
                        const textA = a.key.toUpperCase(), textB = b.key.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
            case 'cultivar': case 'dominantCultivar':
                return [...new Set(essences
                    .filter(essence => essence.cultivar)
                    .map(essence => essence.cultivar))]
                    .map(cultivar => ({ key: cultivar, label: cultivar }))
                    .sort((a, b) => { // On trie par ordre alphabétique
                        const textA = a.key.toUpperCase(), textB = b.key.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
            case 'treePortId': return this.props.treePorts.map(treePort => ({ key: `${treePort.id}`, label: treePort.label }));
            case 'coverTypeId': return this.props.coverTypes.map(coverType => ({ key: `${coverType.id}`, label: coverType.label }));
            case 'vigorId': return this.props.vigors.map(vigor => ({ key: `${vigor.id}`, label: vigor.label, value: StylesUtil.getVigorColor(vigor.label) }));
            case 'healthReviewId': case 'averageHealthReviewId': return this.props.healthReviews.map(healthReview => ({ key: `${healthReview.id}`, label: `${healthReview.value} (${healthReview.description})`, value: StylesUtil.getHealthReviewColor(healthReview.value) }))
            case 'ontogenicStageId': return this.props.ontogenicStages.map(ontogenicStage => ({ key: `${ontogenicStage.id}`, label: ontogenicStage.value, value: StylesUtil.getOntogenicStageColor(ontogenicStage.value) }));
            case 'riskId': return this.props.risks.map(risk => ({ key: `${risk.id}`, label: risk.label, value: StylesUtil.getRiskColor(risk.label) }));
            case 'tippingRiskId': return this.props.tippingRisks.map(tippingRisk => ({ key: `${tippingRisk.id}`, label: tippingRisk.label }));
            case 'organCaliberId': return this.props.organCalibers.map(organCaliber => ({ key: `${organCaliber.id}`, label: organCaliber.label }));
            case 'targetId': return this.props.targets.map(target => ({ key: `${target.id}`, label: target.label }));
            case 'plantationTypeId': return this.props.plantationTypes.map(plantationType => ({ key: `${plantationType.id}`, label: plantationType.label }));
            case 'plantationCoefficientId': return plantationCoefficients.map(plantationCoefficient => ({ key: `${plantationCoefficient.id}`, label: `${plantationCoefficient.value} (${plantationCoefficient[descriptionName]})` }));
            case 'situationCoefficientId': return situationCoefficients.map(situationCoefficient => ({ key: `${situationCoefficient.id}`, label: `${situationCoefficient.value} (${situationCoefficient[descriptionName]})` }));
            case 'patrimonialCoefficientId': return this.props.patrimonialCoefficients.map(patrimonialCoefficient => ({ key: `${patrimonialCoefficient.id}`, label: `${patrimonialCoefficient.value} (${patrimonialCoefficient.description})` }));
            case 'spaceFunctionId': return this.props.spaceFunctions.map(spaceFunction => ({ key: `${spaceFunction.id}`, label: spaceFunction.label }));
            case 'spaceTypeId': return this.props.spaceTypes.map(spaceType => ({ key: `${spaceType.id}`, label: spaceType.label }));
            case 'dominantCompositionId': return this.props.dominantCompositions.map(dominantComposition => ({ key: `${dominantComposition.id}`, label: dominantComposition.label }));
            case 'runoffCoefficientId': return this.props.runoffCoefficients.map(runoffCoefficient => ({ key: GreenSpacesUtil.getRunoffCoefficientString(runoffCoefficient), label: GreenSpacesUtil.getRunoffCoefficientString(runoffCoefficient) }));
            case 'managementClassId': return this.props.managementClasses.map(managementClass => ({ key: `${managementClass.id}`, label: managementClass.label }));
            case 'conditionId': return this.props.conditions.map(condition => ({ key: `${condition.id}`, label: condition.label }));
            case 'typeId': return this.props.furnitureTypes.map(furnitureType => ({ key: `${furnitureType.id}`, label: furnitureType.label }));
            case 'isEmpty': case 'isDead': case 'isStump': case 'isIndexed': case 'isRemarkable': case 'isFruit': case 'toCutDown': case 'isTreeBase':
                return [{ key: 'true', label: i18n.t("Oui") }, { key: 'false', label: i18n.t("Non") }];
            default:
                const customField = this.props.customFields.find(cf => cf.id === +property);
                if (customField?.type === 'boolean') return [{ key: 'true', label: i18n.t("Oui") }, { key: 'false', label: i18n.t("Non") }]
                else return customField?.dropdownCustomFieldValues?.map(dcfv => ({ key: dcfv.id + '', label: dcfv.label }));
        }
    }
}

const mapStateToProps = (state) => {
    return {
        projectActions: state.projectActions,
        essences: state.essences,
        treePorts: state.treePorts,
        coverTypes: state.coverTypes,
        vigors: state.vigors,
        healthReviews: state.healthReviews,
        ontogenicStages: state.ontogenicStages,
        risks: state.risks,
        tippingRisks: state.tippingRisks,
        organCalibers: state.organCalibers,
        targets: state.targets,
        plantationTypes: state.plantationTypes,
        plantationCoefficients: state.plantationCoefficients,
        situationCoefficients: state.situationCoefficients,
        patrimonialCoefficients: state.patrimonialCoefficients,
        spaceFunctions: state.spaceFunctions,
        spaceTypes: state.spaceTypes,
        dominantCompositions: state.dominantCompositions,
        runoffCoefficients: state.runoffCoefficients,
        managementClasses: state.managementClasses,
        conditions: state.conditions,
        furnitureTypes: state.furnitureTypes,
        projectCollaborators: state.projectCollaborators,
        project: state.project,
        customFields: state.project
            ? [...state.customFields, ...state.organizationCustomFields || [], ...(state.projectsCustomFields[state.project?.id] || [])]
            : state.customFields
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Legend);