import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Label, Card, Button } from 'semantic-ui-react';
import Masonry from 'react-masonry-css';
import MapPreview from '../Utils/MapPreview';
import InfoIcon from '../Utils/InfoIcon';
// Librairies
import { isMobileOnly } from 'react-device-detect';
import i18n from '../../locales/i18n';
import { withSize } from 'react-sizeme';
import { setPhotosGalleries } from '../../actionCreators/elementsActions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { booleanPointInPolygon } from '@turf/turf';
// Ressources
import { faHeartbeat, faListAlt, faMapMarkedAlt, faTag, faArrowUpRightFromSquare, faImage, faUserHelmetSafety, faCalendarClock, faSquareCheck, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
// Styles
import '../../styles/details.css';
// Utils
import ProjectsUtil from '../../utils/ProjectsUtil';
import GeoJsonUtil from '../../utils/GeoJsonUtil';
import StylesUtil from '../../utils/StylesUtil';
import DatesUtil from '../../utils/DatesUtil';
import RightsUtil from '../../utils/RightsUtil';
import FormattersUtil from '../../utils/FormattersUtil';

const initialState = {
    requiredFields: null,
    publicFields: null,
    feature: null,
    properties: null,
    customFields: [],
    stationLabels: []
};

const breakpointColumnsObj = {
    default: 3,
    1300: 2,
    900: 1
};

class FurnitureDetail extends Component {
    state = { ...initialState, id: this.props.layer[0].feature.id };

    render() {
        const { project, layer, photosGalleries, rights } = this.props;
        const { id, requiredFields, publicFields, properties, customFields, stationLabels } = this.state;
        const projectSubscription = project.organization.subscription;

        const labelStyle = { margin: '0 5px', display: 'flex' };
        const width = this.props.size.width;

        if (properties && requiredFields && publicFields) {
            const furnituresRF = requiredFields.furnitures;
            const furnituresPF = publicFields.furnitures;
            const defaultFieldCategories = this.props.defaultFieldCategories.filter(dfc => dfc.category === 'Mobilier');
            let coordinates = layer[0].feature.geometry.coordinates;
            if (layer && layer[0]) coordinates = GeoJsonUtil.projectMarkerCoordinates(coordinates, this.props.projections, project?.projectionId);

            const SR = { // shouldRender
                creationInfos: furnituresRF.creationInfos && properties.creationDate,
                modificationInfos: furnituresRF.modificationInfos && properties.modificationDate,
                tags: furnituresRF.tags && furnituresPF.tags && properties.tags?.length > 0,
                place: furnituresRF.place && furnituresPF.place && properties.place,
                condition: furnituresRF.condition && furnituresPF.condition && properties.condition,
                type: furnituresRF.type && furnituresPF.type && properties.type,
                description: furnituresRF.description && furnituresPF.description && properties.description,
                customFields: properties.customFields && Object.keys(properties.customFields).length > 0
                    && (projectSubscription?.customFields || !projectSubscription)
                    && Object.keys(properties.customFields).some(key => properties.customFields[key] && customFields.find(cf => cf.id === Number(key)))
            };

            const descriptionCustomFields = SR.customFields && this.renderFields(defaultFieldCategories.find(dfc => dfc.label === 'Description'));
            const stateCustomFields = SR.customFields && this.renderFields(defaultFieldCategories.find(dfc => dfc.label === 'État'));
            const siteCustomFields = SR.customFields && this.renderFields(defaultFieldCategories.find(dfc => dfc.label === 'Emplacement'));
            const photos = photosGalleries.filter(photo => photo.elementId === id);
            photos.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            const previewPhoto = photos.length ? (photos.find(photo => photo.blobName === properties.previewBlobName) || photos[0]) : null;
            const latestActionsDone = this.getLatestActionsDone();
            const actionsToDo = this.getActionsToDo();

            return (
                <div className='modal-content'>
                    <div id='detail-grid' className='modal-content-body'>
                        {isMobileOnly && properties.projectReference > 0 &&
                            <div style={{ width: '100%', textAlign: 'center', marginBottom: '2px' }}>
                                {i18n.t("Référence")} : {properties.projectReference}{properties.customReference && (' | "' + properties.customReference) + '"'}
                            </div>}
                        <div style={{ position: 'relative', minHeight: SR.creationInfos && SR.modificationInfos ? '25px' : (SR.creationInfos || SR.modificationInfos) ? '10px' : 0 }}>
                            <div style={{ position: isMobileOnly ? 'relative' : 'absolute', top: 0, left: isMobileOnly ? 0 : '10px', display: 'flex', flexDirection: 'column', color: 'var(--grey-70)', textAlign: isMobileOnly && 'center', marginBottom: isMobileOnly && '5px' }}>
                                {SR.creationInfos &&
                                    <>
                                        {properties.creatorName
                                            ? <small>{i18n.t("Créé le {{date}} par {{username}}", { date: `${DatesUtil.getFormattedLocaleDateString(properties.creationDate)}, ${DatesUtil.getFormattedLocaleTimeString(properties.creationDate)}`, username: properties.creatorName, interpolation: { escapeValue: false } })}</small>
                                            : <small>{i18n.t("Créé le {{date}}", { date: `${DatesUtil.getFormattedLocaleDateString(properties.creationDate)}, ${DatesUtil.getFormattedLocaleTimeString(properties.creationDate)}`, interpolation: { escapeValue: false } })}</small>}
                                    </>}
                                {SR.modificationInfos &&
                                    <>
                                        {properties.editorName && properties.modificationDate ?
                                            <small>{i18n.t("Modifié le {{date}} par {{username}}", { date: `${DatesUtil.getFormattedLocaleDateString(properties.modificationDate)}, ${DatesUtil.getFormattedLocaleTimeString(properties.modificationDate)}`, username: properties.editorName, interpolation: { escapeValue: false } })}</small>
                                            : properties.modificationDate && <small>{i18n.t("Modifié le {{date}}", { date: `${DatesUtil.getFormattedLocaleDateString(properties.modificationDate)}, ${DatesUtil.getFormattedLocaleTimeString(properties.modificationDate)}`, interpolation: { escapeValue: false } })}</small>}
                                    </>}
                            </div>
                            {SR.tags &&
                                <div id='tags'>
                                    {properties.tags.map(tag => (
                                        <Label key={tag} className='label--primary' style={{ ...labelStyle, whiteSpace: 'nowrap' }}>
                                            <FontAwesomeIcon icon={faTag} style={{ marginRight: '5px' }} />{tag}
                                        </Label>
                                    ))}
                                </div>}
                        </div>
                        <Masonry breakpointCols={breakpointColumnsObj} className='detail-masonry' columnClassName='detail-masonry__column'>
                            {publicFields.main.photos && previewPhoto &&
                                <Card id='684F879A' title={i18n.t("Accéder à la galerie photos du mobilier")} style={{ borderBottom: 'solid 4px var(--grey-100)' }} onClick={() => this.props.changeModalContentType('PhotosGallery', i18n.t("Photos"))}>
                                    <Card.Header style={{ color: 'var(--grey-100)' }}>
                                        <FontAwesomeIcon icon={faImage} />
                                        {i18n.t("Aperçu")}
                                    </Card.Header>
                                    <Card.Content style={{ padding: 0, overflow: 'hidden', height: '50vh', display: 'flex', justifyContent: 'center', backgroundColor: '#111111' }}>
                                        <img src={previewPhoto.url} alt='img' style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
                                    </Card.Content>
                                </Card>}
                            <Card style={{ borderBottom: 'solid 4px var(--yellow-100)' }}>
                                <Card.Header style={{ color: 'var(--yellow-100)' }}>
                                    <FontAwesomeIcon icon={faMapMarkedAlt} />{i18n.t("Emplacement")}
                                </Card.Header>
                                <Card.Content>
                                    {SR.place &&
                                        <Grid.Row><label>{i18n.t("Lieu")} :</label><span>{properties.place}</span></Grid.Row>}
                                    <Grid.Row><label>{i18n.t("Latitude (y)")} :</label><span>{coordinates[1]}</span></Grid.Row>
                                    <Grid.Row><label className='unselectable'>{i18n.t("Longitude (x)")} :</label><span>{coordinates[0]}</span></Grid.Row>
                                    {stationLabels?.length > 0 && <Grid.Row><label>{i18n.t("Stations")} :</label><span>{stationLabels.join(', ')}</span></Grid.Row>}
                                    {siteCustomFields}
                                </Card.Content>
                                <Card.Content style={{ padding: '1px' }}>
                                    <MapPreview
                                        id={layer[0].feature.id} style={{ height: '100px', width: '100%' }} features={[layer[0].feature]} elementStyle={{ furniture: StylesUtil.getFurnitureStyle() }}
                                        title='Voir sur la carte' onClick={() => this.props.showElement(properties.category, layer[0].feature, null, { highlight: false, blink: true })}
                                    />
                                </Card.Content>
                            </Card>
                            {(SR.description || SR.type || (descriptionCustomFields && descriptionCustomFields.length > 0)) ?
                                <Card style={{ borderBottom: 'solid 4px var(--orange-100)' }}>
                                    <Card.Header style={{ color: 'var(--orange-100)' }}>
                                        <FontAwesomeIcon icon={faListAlt} />{i18n.t("Description")}
                                    </Card.Header>
                                    <Card.Content>
                                        {SR.description &&
                                            <Grid.Row>
                                                <label style={{ alignSelf: 'flex-start' }}>{i18n.t("Description")} :</label>
                                                <span style={{ whiteSpace: 'pre-line' }}>{properties.description}</span>
                                            </Grid.Row>}
                                        {SR.type &&
                                            <Grid.Row>
                                                <label>{i18n.t("Type")} :</label>
                                                <span>{properties.type}</span>
                                            </Grid.Row>}
                                        {descriptionCustomFields}
                                    </Card.Content>
                                </Card> : <div></div>}
                            {width > 1300 && <div></div>}
                            {width > 1300 && <div></div>}
                            {(SR.condition || (stateCustomFields && stateCustomFields.length > 0)) ?
                                <Card style={{ borderBottom: 'solid 4px var(--red-100)' }}>
                                    <Card.Header style={{ color: 'var(--red-100)' }}>
                                        <FontAwesomeIcon icon={faHeartbeat} />{i18n.t("État")}
                                    </Card.Header>
                                    <Card.Content>
                                        {SR.condition &&
                                            <Grid.Row>
                                                <label>{i18n.t("État")} :</label>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span
                                                        className='color-circle'
                                                        style={{ backgroundColor: StylesUtil.getConditionColor(properties.condition.label) }}
                                                    ></span>
                                                    <span>{properties.condition.label}</span>
                                                </div>
                                            </Grid.Row>}
                                        {stateCustomFields}
                                    </Card.Content>
                                </Card> : <div></div>}
                            {(latestActionsDone.length > 0 || actionsToDo.length > 0) && RightsUtil.canRead(rights?.actions) &&
                                <Card id='684F879A' title={i18n.t("Accéder aux actions de l'arbre")} style={{ borderBottom: 'solid 4px var(--grey-100)' }} onClick={() => this.props.changeModalContentType('ActionForm', i18n.t("Actions"))}>
                                    <Card.Header style={{ color: 'var(--grey-100)' }}>
                                        <FontAwesomeIcon icon={faUserHelmetSafety} />
                                        {i18n.t("Actions")}
                                    </Card.Header>
                                    <Card.Content style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                        {actionsToDo.length > 0 && this.renderActionsToDo(actionsToDo)}
                                        {latestActionsDone.length > 0 && this.renderLatestActionsDone(latestActionsDone)}
                                    </Card.Content>
                                </Card>}
                            {SR.customFields && this.renderCustomFields(properties.customFields)}
                        </Masonry>
                    </div>
                </div >
            );
        }
        else return (<p>Chargement...</p>);
    }

    componentDidMount = () => {
        if (!this.state.requiredFields) {
            const requiredFields = ProjectsUtil.getProjectRequiredFields(this.props.project, this.props.layer[0].feature.projectId);
            const publicFields = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators);
            this.setState({ requiredFields: requiredFields, publicFields: publicFields }, () => this.setProperties());
        } else this.setProperties();
    }

    componentDidUpdate = () => { // Permet d'update les infos lorsqu'on passe au mobilier suivant ou précédent dans un projet
        if (this.props.layer[0].feature.id !== this.state.id || JSON.stringify(this.props.layer[0].feature) !== JSON.stringify(this.state.feature))
            this.setState(prevState => ({
                ...prevState.requiredFields, ...prevState.publicFields,
                id: this.props.layer[0].feature.id,
                feature: this.props.layer[0].feature
            }), () => this.setProperties());
    }

    renderFields = (category) => {
        const { project } = this.props;
        const customFields = this.state.customFields.map(cf => {
            const value = this.state.properties.customFields[cf.id];
            return value ? { ...cf, value } : null;
        }).filter(cf => cf);
        const projectCustomFields = project?.projectCustomFields || [];

        const fields = customFields
            .filter(cf => projectCustomFields.find(pcf => pcf.customFieldId === cf.id && pcf.fieldCategoryId === category?.id))
            .sort((a, b) => projectCustomFields.find(pcf => pcf.customFieldId === a.id && pcf.fieldCategoryId === category?.id)?.order
                - projectCustomFields.find(pcf => pcf.customFieldId === b.id && pcf.fieldCategoryId === category?.id)?.order);
        const iconStyle = { position: 'relative', top: 0, right: 0, marginLeft: '3px' };
        const renderedFields = fields.map((cf, i) => {
            let value = cf.value && cf.type === 'boolean' ? cf.value === 'true' ? i18n.t("Oui") : i18n.t("Non")
                : cf.type === 'date' ? DatesUtil.getFormattedLocaleDateString(cf.value)
                    : cf.type === 'list' && cf.isMultiple ? cf.value.split(',').map(v => cf.dropdownCustomFieldValues.find(dcfv => String(dcfv.id) === v)?.label).filter(v => v)
                        : cf.type === 'list' ? cf.dropdownCustomFieldValues.find(dcfv => String(dcfv.id) === cf.value)?.label
                            : cf.value;
            if (cf.type === 'number' && cf.unit?.trim()) value += cf.unit;
            if (cf.type === 'formula') value = FormattersUtil.formatFormulaCustomField(cf, value);

            return (
                cf.type !== 'url' ?
                    <Grid.Row key={`extra-field-${i + 1}`} style={Array.isArray(value) ? { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' } : null}>
                        <label>{cf.label}{cf.description?.trim() && <InfoIcon content={cf.description} iconStyle={iconStyle} />} :</label>
                        {!Array.isArray(value) ? <span style={{ whiteSpace: 'pre-line' }}>{value}</span>
                            : <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                {value.map(v => (
                                    <li key={v}>{v}</li>
                                ))}
                            </ul>}
                    </Grid.Row>
                    : <Grid.Row>
                        <Button size='small' style={{ padding: 0 }} type='button' color='green'>
                            <a href={cf.value?.includes('http') ? cf.value : '//' + cf.value} target='_blank' rel='noreferrer' style={{ padding: '7px', display: 'block', height: '100%', width: '100%', color: 'inherit' }}>
                                <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ marginRight: '5px' }} />{cf.label}
                            </a>
                        </Button>
                    </Grid.Row>
            );
        });

        return renderedFields;
    }

    renderCustomFields = (elementCustomFields) => {
        const { project } = this.props;
        const customFields = this.state.customFields.map(cf => {
            const value = this.state.properties.customFields[cf.id];
            return value ? { ...cf, value } : null;
        }).filter(cf => cf);
        const projectCustomFields = project?.projectCustomFields || [];

        const othersCategory = this.props.defaultFieldCategories.find(dfc => dfc.label === 'Autres' && dfc.category === 'Mobilier');
        let categories = [{
            id: othersCategory.id, label: i18n.t("Autres"), color: othersCategory.color, icon: othersCategory.icon,
            fields: customFields.filter(cf => {
                const pcf = projectCustomFields.find(pcf => pcf.customFieldId === cf.id);
                if (pcf) return pcf.fieldCategoryId === othersCategory.id && pcf;
                return elementCustomFields[cf.id];
            })
        }];

        if (project?.fieldCategories?.length)
            categories = [...categories, ...project.fieldCategories.filter(fc => fc.category === 'Mobilier').map(fc => {
                return { ...fc, fields: customFields.filter(cf => projectCustomFields.find(pcf => cf.id === pcf.customFieldId)?.fieldCategoryId === fc.id) };
            })];
        categories = categories.filter(c => c.fields.length);

        const iconStyle = { position: 'relative', top: 0, right: 0, marginLeft: '3px' };
        return categories.map(category => (
            <Card key={category.label} style={{ borderBottom: `solid 4px ${category.color}` }}>
                <Card.Header style={{ color: category.color }}>
                    <FontAwesomeIcon icon={category.icon} />
                    {category.label}
                </Card.Header>
                <Card.Content>
                    {category.fields
                        .sort((a, b) => project.projectCustomFields.find(pcf => a.id === pcf.customFieldId && pcf.fieldCategoryId === category.id)?.order
                            - project.projectCustomFields.find(pcf => b.id === pcf.customFieldId && pcf.fieldCategoryId === category.id)?.order)
                        .map((cf, i) => {
                            let value = cf.value && cf.type === 'boolean' ? cf.value === 'true' ? i18n.t("Oui") : i18n.t("Non")
                                : cf.type === 'date' ? DatesUtil.getFormattedLocaleDateString(cf.value)
                                    : cf.type === 'list' && cf.isMultiple ? cf.value.split(',').map(v => cf.dropdownCustomFieldValues.find(dcfv => String(dcfv.id) === v)?.label).filter(v => v)
                                        : cf.type === 'list' ? cf.dropdownCustomFieldValues.find(dcfv => String(dcfv.id) === cf.value)?.label
                                            : cf.value;
                            if (cf.type === 'number' && cf.unit?.trim()) value += cf.unit;
                            if (cf.type === 'formula') value = FormattersUtil.formatFormulaCustomField(cf, value);

                            return (
                                cf.type !== 'url' ?
                                    <Grid.Row key={`extra-field-${i + 1}`} style={Array.isArray(value) ? { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' } : null}>
                                        <label>{cf.label}{cf.description?.trim() && <InfoIcon content={cf.description} iconStyle={iconStyle} />} :</label>
                                        {!Array.isArray(value) ? <span>{value}</span>
                                            : <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                                {value.map(v => (
                                                    <li key={v}>{v}</li>
                                                ))}
                                            </ul>}
                                    </Grid.Row>
                                    : <Grid.Row>
                                        <Button size='small' style={{ padding: 0 }} type='button' color='green' >
                                            <a href={cf.value?.includes('http') ? cf.value : '//' + cf.value} target='_blank' rel='noreferrer' style={{ padding: '7px', display: 'block', height: '100%', width: '100%', color: 'inherit' }}>
                                                <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ marginRight: '5px' }} />{cf.label}
                                            </a>
                                        </Button>
                                    </Grid.Row>
                            );
                        })}
                </Card.Content>
            </Card>
        ));
    }

    renderLatestActionsDone = (latestActionsDone) => {
        const { isDarkTheme } = this.props;

        return (
            <div style={{ flex: 1, marginTop: isMobileOnly && '10px' }}>
                <Grid.Row>
                    <FontAwesomeIcon icon={faSquareCheck} style={{ marginRight: '7px', color: isDarkTheme ? 'white' : 'black' }} />
                    <label>{i18n.t("Dernières réalisées")}</label>
                </Grid.Row>
                {latestActionsDone.map((recurrence, index) =>
                    <div key={index} style={{ marginLeft: '8px' }}>
                        <label>{recurrence.actionLabel} :</label> <span title={i18n.t("Date de réalisation")}>{DatesUtil.getFormattedLocaleDateString(recurrence.validationDate)}</span>
                    </div>)}
            </div>
        );
    }

    renderActionsToDo = (actionsToDo) => {
        const { isDarkTheme } = this.props;

        return (
            <div style={{ flex: 1 }}>
                <Grid.Row>
                    <FontAwesomeIcon icon={faCalendarClock} style={{ marginRight: '7px', color: isDarkTheme ? 'white' : 'black' }} />
                    <label>{i18n.t("À venir")}</label>
                </Grid.Row>
                {actionsToDo.map((recurrence, index) =>
                    <div key={index} style={{ marginLeft: '8px' }}>
                        <label>
                            {recurrence.actionLabel} :</label> <span title={i18n.t("Date planifiée")}>{DatesUtil.getFormattedLocaleDateString(recurrence.date)}
                            {DatesUtil.convertUTCDateToDate(recurrence.date) < new Date() && <FontAwesomeIcon icon={faExclamationTriangle} title={i18n.t("En retard")} style={{ marginLeft: '5px', color: 'var(--red-100)' }} />}
                        </span>
                    </div>)}
            </div>
        );
    }

    setProperties = () => {
        const { project } = this.props;
        const properties = this.props.layer[0].feature.properties;
        const condition = this.props.conditions.find(x => x.id === properties.conditionId);
        const furnitureType = this.props.furnitureTypes.find(x => x.id === properties.typeId)?.label;
        // Tags
        let tags = [];
        if (properties.tagId && this.state.requiredFields.trees.tags && project?.tags) {
            properties.tagId.forEach(tId => {
                const tag = project.tags.find(x => x.id === tId)?.label;
                tags.push(tag);
            });
        }
        // Stations
        const stationsLayer = this.props.layers.find(l => l.label === i18n.t("Stations"));
        const layers = stationsLayer ? stationsLayer.getLayers() : [];
        const stationLabels = layers.filter(l => booleanPointInPolygon(this.props.layer[0].feature, l.feature)).map(l => l.feature.properties.label);

        this.setState({
            properties: { ...properties, condition, type: furnitureType, tags },
            customFields: this.props.customFields.filter(cf => (cf.category === 'Mobilier')),
            stationLabels
        });
    }

    getLatestActionsDone = () => {
        let recurrences = [];
        this.props.projectActions
            ?.filter(pa => pa.action.categories.includes('Mobilier'))
            .forEach(pa => {
                const pae = pa.projectActionElements.find(x => x.elementId === this.state.id);
                if (pae?.projectActionElementRecurrences?.length)
                    recurrences.push(...pae.projectActionElementRecurrences.filter(paer => !paer.status).map(paer => ({ ...paer, actionLabel: pa.action.label })));
            });

        recurrences.sort((a, b) => new Date(b.validationDate) - new Date(a.validationDate));
        return recurrences.slice(0, 5);
    }

    getActionsToDo = () => {
        let recurrences = [];
        this.props.projectActions
            ?.filter(pa => pa.action.categories.includes('Mobilier'))
            .forEach(pa => {
                const pae = pa.projectActionElements.find(x => x.elementId === this.state.id);
                if (pae?.projectActionElementRecurrences?.length)
                    recurrences.push(...pae.projectActionElementRecurrences.filter(paer => !paer.validationDate).map(paer => ({ ...paer, actionLabel: pa.action.label })));
            });

        recurrences.sort((a, b) => new Date(a.date) - new Date(b.date));
        return recurrences.slice(0, 5);
    }
}

const mapStateToProps = (state) => {
    return {
        layer: state.layer,
        conditions: state.conditions,
        furnitureTypes: state.furnitureTypes,
        project: state.project,
        projectCollaborators: state.projectCollaborators,
        isOnline: state.isOnline,
        elementHistory: state.elementHistory,
        activeOrganization: state.activeOrganization,
        projections: state.projections,
        webSocketHubs: state.webSocketHubs,
        isDarkTheme: state.isDarkTheme,
        photosGalleries: state.photosGalleries,
        projectActions: state.projectActions,
        defaultFieldCategories: state.defaultFieldCategories,
        customFields: state.project
            ? [...state.customFields, ...state.organizationCustomFields || [], ...(state.projectsCustomFields[state.project?.id] || [])]
            : state.customFields,
        rights: state.rights,
    };
};

const mapDispatchToProps = {
    setPhotosGalleries
};

export default compose(withSize(), connect(mapStateToProps, mapDispatchToProps))(FurnitureDetail);