import AppSettings from "../AppSettings";
// Utils
import FormattersUtil from "./FormattersUtil";
import OfflineUtil from "./OfflineUtil";

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class CachesUtil {
    static executeSpecialCacheChange = async (request, response, props) => {
        const indexedDB = OfflineUtil.getIndexedDb();

        if (`${BACKEND_ENDPOINT}actions/` === FormattersUtil.getBaseURL(request.url)) {
            const guid = request.data?.id, id = response.data?.id;
            if (['POST', 'PUT'].includes(request.method)) {
                const guidIdList = [];
                if ((request.data.paeToAdd || request.data.projectActionElements) && response.data.projectActionElements?.length)
                    for (const pae of (request.data.paeToAdd || request.data.projectActionElements)) {
                        const responsePae = response.data.projectActionElements.find(p => p.elementId === pae.elementId);
                        if (responsePae)
                            for (let i = 0; i < pae.projectActionElementRecurrences.length; i++) {
                                const paerGuid = pae.projectActionElementRecurrences[i].id, paerId = responsePae.projectActionElementRecurrences[i].id;
                                guidIdList.push({ guid: paerGuid, id: paerId });
                                await indexedDB.guidLinks.add({ guid: paerGuid, id: paerId, type: 'action' });
                            }
                    }

                if ((response.data && !isNaN(id)) || request.method === 'PUT') {
                    if (request.method === 'POST') await indexedDB.guidLinks.add({ guid, id, type: 'action' });
                    const usedId = request.method === 'POST' ? guid : id;
                    OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'actions/projectActions/' + request.data.projectId, (body) => {
                        const elementIndex = body.findIndex(el => el.id === usedId);
                        let paString = JSON.stringify(body[elementIndex]);
                        if (guid && id) paString = paString.replaceAll(`"${guid}"`, id);
                        guidIdList.forEach(guidId => paString = paString.replaceAll(`"${guidId.guid}"`, guidId.id));
                        body[elementIndex] = JSON.parse(paString);
                        if (props.projectActions?.length) {
                            const projectActions = [...props.projectActions];
                            const index = projectActions.findIndex(pa => pa.id === usedId);
                            if (index !== -1) {
                                projectActions[index] = body[elementIndex];
                                props.setProjectActions(projectActions);
                            }
                        }
                        return body;
                    });
                }
            }
        } else if (`${BACKEND_ENDPOINT}events/` === FormattersUtil.getBaseURL(request.url)) {
            const guid = request.data?.id, id = response.data?.id;
            if (['POST', 'PUT'].includes(request.method)) {
                const guidIdList = [];
                if ((response.data && !isNaN(id)) || request.method === 'PUT') {
                    if (request.method === 'POST') await indexedDB.guidLinks.add({ guid, id, type: 'event' });
                    const usedId = request.method === 'POST' ? guid : id;
                    OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'events/projectEvents/' + request.data.projectId, (body) => {
                        const elementIndex = body.findIndex(el => el.id === usedId);
                        let peString = JSON.stringify(body[elementIndex]);
                        if (guid && id) peString = peString.replaceAll(`"${guid}"`, id);
                        guidIdList.forEach(guidId => peString = peString.replaceAll(`"${guidId.guid}"`, guidId.id));
                        body[elementIndex] = JSON.parse(peString);
                        if (props.projectEvents?.length) {
                            const projectEvents = [...props.projectEvents];
                            const index = projectEvents.findIndex(pe => pe.id === usedId);
                            if (index !== -1) {
                                projectEvents[index] = body[elementIndex];
                                props.setProjectEvents(projectEvents);
                            }
                        }
                        return body;
                    });
                }
            }
        } else if (request.method === 'POST' && `${BACKEND_ENDPOINT}fileInfos/` === FormattersUtil.getBaseURL(request.url)) {
            const guid = request.data.get('id'); // On récupère l'id temporaire
            const id = response.data?.id; // On récupère l'ID réel généré par EF
            if (!isNaN(id)) {
                await indexedDB.guidLinks.add({ guid, id, type: 'fileInfo' });
                if (response.data.type === 'photo') {
                    const photosGalleries = [...props.photosGalleries];
                    const index = photosGalleries.findIndex(photo => photo.id === guid);
                    if (index !== -1) photosGalleries[index].id = response.data.id;
                    props.setPhotosGalleries(photosGalleries);
                } else if (response.data.type === 'file') {
                    const filesGalleries = [...props.filesGalleries];
                    const index = filesGalleries.findIndex(file => file.id === guid);
                    if (index !== -1) filesGalleries[index].id = response.data.id;
                    props.setFilesGalleries(filesGalleries);
                }
            }
        }
    }
}