import React, { Component, createRef } from 'react';
// Composants
import { Button, Checkbox, Dimmer, Form, Grid, Input, Message, Ref } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import InfoIcon from '../Utils/InfoIcon';
import DatePicker from './DatePicker';
// Librairies
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';
import { isSameDay } from 'date-fns';
// Utils
import StylesUtil from '../../utils/StylesUtil';

class InputPopupForm extends Component {
    state = {
        isLoading: false,
        value: null,
        checked: false,
        error: false
    }

    render() {
        const { isDarkTheme, allowSameValue, showCheckBox, checkboxTitle, checkboxInfoIcon, message, placeholder, label, inputType, disabled, customDescription, allowNullValue } = this.props;
        const { isLoading, value, checked, error } = this.state;
        const isDisabled = disabled || isLoading || (value?.length === 0 && !allowNullValue) || (!allowSameValue && (inputType === 'date' ? isSameDay(value, this.props.value) : String(value) === String(this.props.value)));
        const showCurrentValue = [undefined, true].includes(this.props.showCurrentValue);

        return (
            <Dimmer
                active style={{ ...StylesUtil.getMapStyles().dimmerStyle, position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', zIndex: 9999 }}
                onClick={({ target }) => { if (target.classList.contains('dimmer')) this.props.cancel(); }}
            >
                <Grid style={{ height: '100%' }}>
                    <Grid.Row style={{ height: '100%' }} verticalAlign='middle'>
                        <Grid.Column textAlign='center'>
                            <Message className='input-popup-form' style={{ maxWidth: '400px' }}>
                                <Message.Header>{this.props.title || i18n.t("Renommer")}</Message.Header>
                                <Message.Content style={{ marginTop: '10px' }}>
                                    {showCurrentValue &&
                                        <div style={{ maxHeight: '300px', overflowY: 'auto', marginTop: '10px', marginBottom: '10px' }}>
                                            <div style={{ color: isDarkTheme ? 'rgba(255, 255, 255, 0.75)' : 'grey' }}>{this.props.value}</div>
                                        </div>}
                                    {customDescription && customDescription()}
                                    <Form style={{ display: 'flex', flexDirection: 'column' }}>
                                        {(!inputType || inputType === 'text') ?
                                            <Ref innerRef={this.inputRef}>
                                                <Form.Field
                                                    control={Input} placeholder={placeholder || i18n.t("Veuillez saisir un nom")} name='value' value={value || ''}
                                                    error={error} onChange={this.handleChange} style={{ marginBottom: '5px' }}
                                                />
                                            </Ref>
                                            : inputType === 'number' ?
                                                <Ref innerRef={this.inputRef}>
                                                    <Form.Field
                                                        control={Input} type='number' placeholder={placeholder || i18n.t("Veuillez saisir une valeur")} name='value' value={value || ''}
                                                        error={error} onChange={this.handleChange} style={{ marginBottom: '5px' }}
                                                    />
                                                </Ref>
                                                : inputType === 'date' &&
                                                <DatePicker
                                                    label={label} name='value' value={value || ''} error={error}
                                                    onChange={this.handleChange} style={{ marginBottom: '5px' }}
                                                />}
                                        {showCheckBox &&
                                            <div style={{ display: 'flex' }}>
                                                <Checkbox name='checked' label={checkboxTitle} checked={checked} onChange={this.handleCheckboxChange} style={{ marginBottom: '10px', alignSelf: 'flex-start' }} />
                                                {checkboxInfoIcon?.length && <InfoIcon content={checkboxInfoIcon} iconStyle={{ position: 'relative', top: 0, right: 0, marginLeft: '5px' }} />}
                                            </div>}
                                    </Form>
                                    {message && <Message style={{ margin: '0 0 7px 0', padding: '7px 10px' }} content={message} warning />}
                                    <Button color='grey' onClick={this.props.cancel}>
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                    </Button>
                                    <Button
                                        color='green' disabled={isDisabled ? true : false} loading={isLoading}
                                        onClick={this.handleSubmit}
                                    >
                                        <FontAwesomeIcon icon={this.props.submitButtonIcon || faSave} style={{ marginRight: '10px' }} />
                                        {this.props.submitButtonLabel || i18n.t("Sauvegarder")}
                                    </Button>
                                </Message.Content>
                            </Message>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Dimmer>
        );
    }

    componentDidMount = () => {
        this.selectText = this.props.selection === false ? false : true;
        this.inputRef = createRef();
        this.setState({ value: this.props.value || '', checked: this.props.checkboxValue || false });
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentDidUpdate = (_, prevState) => {
        if (prevState.value == null && this.state.value !== null) this.autoFocusAndSelect();
    }

    componentWillUnmount = () => document.removeEventListener('keydown', this.handleKeyDown);

    handleKeyDown = (e) => {
        const { value } = this.state;
        if (e.key?.toLowerCase() === 'enter' && value?.length && value !== this.props.value) {
            e.preventDefault();
            this.setState({ isLoading: true }, () => this.props.submit(value));
        }
    }

    autoFocusAndSelect = () => {
        if (this.inputRef.current) {
            const input = this.inputRef.current.querySelector('input');
            input.focus();

            if (input.type === "number") {
                input.type = "text";
                setTimeout(() => {
                    input.setSelectionRange(0, input.value.length);
                    input.type = "number";
                }, 10);
            } else input.setSelectionRange(0, input.value.length);

            this.selectText = false;
        }
    }

    handleChange = (_, { name, value }) => {
        const error = name === 'value' ? !value.length && !this.props.allowNullValue : this.state.error;
        this.setState(prevState => ({ ...prevState, [name]: value, error }));
    }

    handleCheckboxChange = (_, { name, checked }) => {
        this.setState({ [name]: checked });
    }

    handleSubmit = () => {
        const { value, checked } = this.state;

        if (this.props.inputType === 'date') {
            if ((!value && !this.props.allowNullValue) || value.getFullYear().toString().length !== 4) {
                this.setState({ error: true })
                return;
            }
        }

        this.setState({ isLoading: true }, () => this.props.submit(value, checked));
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        isDarkTheme: state.isDarkTheme
    };
};

export default connect(mapStateToProps)(InputPopupForm);