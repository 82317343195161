const initialState = {
    actions: [],
    activeOrganization: null,
    buttonStates: {
        actions: 0,
        events: 0,
        filters: 0,
        fullscreen: 0,
        geolocation: 0,
        lasso: 0,
        projectLabels: 0,
        references: 0,
        legend: 0,
        table: 0,
        timeline: 0,
        statistics: 0,
        history: 0
    },
    conditions: [],
    coverTypes: [],
    currencies: [],
    currentAction: '',
    customCharts: null,
    customFields: [],
    dashboardInfos: null,
    defaultFieldCategories: [],
    homeInfos: null,
    dominantCompositions: [],
    editedProperties: null,
    elementHistory: null,
    projectEvents: null,
    events: [],
    actionHistory: null,
    essences: [],
    favoriteTools: [],
    filesGalleries: [],
    filterFormState: null,
    filterLists: null,
    exitFormWithChanges: false,
    formulas: [],
    formulasResults: [],
    furnitureTypes: [],
    healthReviews: [],
    interactions: [],
    microHabitats: [],
    rootSymptoms: [],
    collarSymptoms: [],
    trunkSymptoms: [],
    branchSymptoms: [],
    leafSymptoms: [],
    pathogens: [],
    pests: [],
    epiphytes: [],
    isDarkTheme: false,
    isKeyboardOpen: false,
    isOnline: true,
    isToolbarExpanded: false,
    layer: null,
    locked: false,
    lockedElements: [],
    loginAsData: null,
    managementClasses: [],
    newProject: null,
    ontogenicStages: [],
    organizations: [],
    patrimonialCoefficients: [],
    photosGalleries: [],
    place: '',
    plantationCoefficients: [],
    plantationTypes: [],
    project: null,
    projectActions: null,
    projectCollaborators: null,
    projections: [],
    projectInvitations: null,
    projectListLoadingStatus: {
        rootBaseProjects1Status: 0,
        rootBaseProjects2Status: 0,
        subBaseProjectsStatus: 0,
    },
    projectListState: null,
    projects: null,
    projectsCustomFields: {},
    projectShortcut: null,
    redirectURL: null,
    request: null,
    rights: null,
    risks: [],
    roleTemplates: null,
    rootBaseProjectIds: null,
    tippingRisks: [],
    organCalibers: [],
    targets: [],
    runoffCoefficients: [],
    situationCoefficients: [],
    spaceFunctions: [],
    spaceTypes: [],
    spaceTypeCategories: [],
    sync: null,
    tableState: null,
    themes: null,
    priceLists: null,
    treePorts: [],
    tutorialTour: null,
    organizationCustomFields: null,
    customFieldCategories: null,
    userInfos: null,
    userProjects: null,
    viewProjectAsData: null,
    vigors: [],
    webSocketHubs: null,
    updateApp: false,
    defaultWmsServices: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USER_INFOS': return { ...state, userInfos: action.data };
        case 'SET_ESSENCES': return { ...state, essences: action.data };
        case 'SET_COVER_TYPES': return { ...state, coverTypes: action.data };
        case 'SET_INTERACTIONS': return { ...state, interactions: action.data };
        case 'SET_MICROHABITATS': return { ...state, microHabitats: action.data };
        case 'SET_ROOT_SYMPTOMS': return { ...state, rootSymptoms: action.data };
        case 'SET_COLLAR_SYMPTOMS': return { ...state, collarSymptoms: action.data };
        case 'SET_TRUNK_SYMPTOMS': return { ...state, trunkSymptoms: action.data };
        case 'SET_BRANCH_SYMPTOMS': return { ...state, branchSymptoms: action.data };
        case 'SET_LEAF_SYMPTOMS': return { ...state, leafSymptoms: action.data };
        case 'SET_PATHOGENS': return { ...state, pathogens: action.data };
        case 'SET_PESTS': return { ...state, pests: action.data };
        case 'SET_EPIPHYTES': return { ...state, epiphytes: action.data };
        case 'SET_VIGORS': return { ...state, vigors: action.data };
        case 'SET_HEALTH_REVIEWS': return { ...state, healthReviews: action.data };
        case 'SET_ONTOGENIC_STAGES': return { ...state, ontogenicStages: action.data };
        case 'SET_RISKS': return { ...state, risks: action.data };
        case 'SET_TIPPING_RISKS': return { ...state, tippingRisks: action.data };
        case 'SET_ORGAN_CALIBERS': return { ...state, organCalibers: action.data };
        case 'SET_TARGETS': return { ...state, targets: action.data };
        case 'SET_PLANTATION_TYPES': return { ...state, plantationTypes: action.data };
        case 'SET_PLANTATION_COEFFICIENTS': return { ...state, plantationCoefficients: action.data };
        case 'SET_SITUATION_COEFFICIENTS': return { ...state, situationCoefficients: action.data };
        case 'SET_PATRIMONIAL_COEFFICIENTS': return { ...state, patrimonialCoefficients: action.data };
        case 'SET_SPACE_FUNCTIONS': return { ...state, spaceFunctions: action.data };
        case 'SET_SPACE_TYPES': return { ...state, spaceTypes: action.data };
        case 'SET_SPACE_TYPE_CATEGORIES': return { ...state, spaceTypeCategories: action.data };
        case 'SET_RUNOFF_COEFFICIENTS': return { ...state, runoffCoefficients: action.data };
        case 'SET_MANAGEMENT_CLASSES': return { ...state, managementClasses: action.data };
        case 'SET_DEFAULT_WMS_SERVICES': return { ...state, defaultWmsServices: action.data };
        case 'SET_DOMINANT_COMPOSITIONS': return { ...state, dominantCompositions: action.data };
        case 'SET_CONDITIONS': return { ...state, conditions: action.data };
        case 'SET_FURNITURE_TYPES': return { ...state, furnitureTypes: action.data };
        case 'SET_EDITED_PROPERTIES':
            // Lorsqu'on qu'on remet les propriétés à undefined, on lock les modifications pour éviter que le 
            // willUnmount des formulaires remettent les propriétés juste après, le unlock sera appelé à l'affichage d'un formulaire
            if (!action.data) return { ...state, editedProperties: action.data, locked: true };
            else if (!state.locked) return { ...state, editedProperties: action.data };
            else return { ...state };
        case 'UNLOCK_EDITED_PROPERTIES': return { ...state, locked: false };
        case 'SET_ACTIONS': return { ...state, actions: action.data };
        case 'SET_PROJECT_ACTIONS': return { ...state, projectActions: action.data };
        case 'SET_LAYER': return { ...state, layer: action.data };
        case 'SET_CURRENT_ACTION': return { ...state, currentAction: action.data };
        case 'SET_PROJECT': return { ...state, project: action.data };
        case 'SET_PROJECTS': return { ...state, projects: action.data };
        case 'SET_RIGHTS': return { ...state, rights: action.data };
        case 'SET_REQUEST': return { ...state, request: action.data };
        case 'SET_IS_ONLINE': return { ...state, isOnline: action.data };
        case 'SET_SYNC': return { ...state, sync: action.data };
        case 'SET_PHOTOS_GALLERIES': return { ...state, photosGalleries: action.data };
        case 'SET_FILES_GALLERIES': return { ...state, filesGalleries: action.data };
        case 'SET_IS_KEYBOARD_OPEN': return { ...state, isKeyboardOpen: action.data };
        case 'SET_NEW_PROJECT': return { ...state, newProject: action.data };
        case 'SET_PLACE': return { ...state, place: action.data };
        case 'SET_TABLE_STATE': return { ...state, tableState: action.data };
        case 'SET_FILTER_FORM_STATE': return { ...state, filterFormState: action.data };
        case 'SET_FILTER_LISTS': return { ...state, filterLists: action.data };
        case 'SET_DEFAULT_FIELD_CATEGORIES': return { ...state, defaultFieldCategories: action.data };
        case 'SET_CUSTOM_CHARTS': return { ...state, customCharts: action.data };
        case 'SET_ELEMENT_HISTORY': return { ...state, elementHistory: action.data };
        case 'SET_ACTION_HISTORY': return { ...state, actionHistory: action.data };
        case 'SET_IS_DARK_THEME': return { ...state, isDarkTheme: action.data };
        case 'SET_DASHBOARD_INFOS': return { ...state, dashboardInfos: action.data };
        case 'SET_HOME_INFOS': return { ...state, homeInfos: action.data };
        case 'SET_TUTORIAL_TOUR': return { ...state, tutorialTour: action.data };
        case 'SET_FORMULAS': return { ...state, formulas: action.data };
        case 'SET_PROJECTIONS': return { ...state, projections: action.data };
        case 'SET_THEMES': return { ...state, themes: action.data };
        case 'SET_PRICE_LISTS': return { ...state, priceLists: action.data };
        case 'SET_WEB_SOCKET_HUBS': return { ...state, webSocketHubs: action.data };
        case 'SET_BUTTON_STATE': return { ...state, buttonStates: { ...state.buttonStates, [action.data.buttonName]: action.data.state } };
        case 'SET_FAVORITE_TOOLS': return { ...state, favoriteTools: action.data };
        case 'SET_CUSTOM_FIELDS': return { ...state, customFields: action.data };
        case 'SET_ORGANIZATION_CUSTOM_FIELDS': return { ...state, organizationCustomFields: action.data };
        case 'SET_CUSTOM_FIELD_CATEGORIES': return { ...state, customFieldCategories: action.data };
        case 'SET_PROJECT_CUSTOM_FIELDS':
            return { ...state, projectsCustomFields: { ...state.projectsCustomFields, [action.data.projectId]: action.data.customFields } };
        case 'SET_PROJECTS_CUSTOM_FIELDS': return { ...state, projectsCustomFields: action.data };
        case 'SET_LOCKED_ELEMENTS': return { ...state, lockedElements: action.data };
        case 'SET_PROJECT_LIST_STATE': return { ...state, projectListState: action.data };
        case 'SET_CURRENT_FOLDER_STATE': return { ...state, currentFolderState: action.data };
        case 'SET_USER_PROJECTS': return { ...state, userProjects: action.data };
        case 'SET_UPDATE_APP': return { ...state, updateApp: true };
        case 'SET_PROJECT_COLLABORATORS': return { ...state, projectCollaborators: action.data };
        case 'SET_TREE_PORTS': return { ...state, treePorts: action.data };
        case 'SET_FORMULAS_RESULTS': return { ...state, formulasResults: action.data };
        case 'SET_PROJECT_SHORTCUT': return { ...state, projectShortcut: action.data };
        case 'TOGGLE_TOOLBAR_EXPAND': return { ...state, isToolbarExpanded: !state.isToolbarExpanded }
        case 'SET_CURRENCIES': return { ...state, currencies: action.data };
        case 'SET_PROJECT_INVITATIONS': return { ...state, projectInvitations: action.data };
        case 'SET_REDIRECT_URL': return { ...state, redirectURL: action.data };
        case 'SET_LOGIN_AS_DATA': return { ...state, loginAsData: action.data };
        case 'SET_EXIT_FORM_WITH_CHANGES': return { ...state, exitFormWithChanges: action.data };
        case 'SET_ROLE_TEMPLATES': return { ...state, roleTemplates: action.data };
        case 'SET_VIEW_PROJECT_AS_DATA': return { ...state, viewProjectAsData: action.data };
        case 'SET_ACTIVE_ORGANIZATION': return { ...state, activeOrganization: action.data };
        case 'SET_ORGANIZATIONS': return { ...state, organizations: action.data };
        case 'SET_ROOT_BASE_PROJECT_ID': return { ...state, rootBaseProjectIds: action.data };
        case 'SET_PROJECT_LIST_LOADING_STATUS': return { ...state, projectListLoadingStatus: action.data };
        case 'SET_EVENTS': return { ...state, events: action.data };
        case 'SET_PROJECT_EVENTS': return { ...state, projectEvents: action.data };
        default: return state;
    }
};

export default reducer;
