import React, { Component } from 'react';
// Composants
import { Line } from 'react-chartjs-2';
import { Dimmer, Grid, Loader, Segment } from 'semantic-ui-react';
// Librairies
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';
import tinycolor from 'tinycolor2';
// Services
import TreesService from '../../services/TreesService';
import StatisticsService from '../../services/StatisticsService';
// Utils
import StylesUtil from '../../utils/StylesUtil';

class EvolutionCharts extends Component {
    state = {
        charts: [],
        isLoading: false
    };

    render() {
        const { charts, isLoading } = this.state;

        return (
            <div className='modal-content'>
                {isLoading ?
                    <Dimmer active style={StylesUtil.getMapStyles().dimmerStyle}>
                        <Loader content={i18n.t("Chargement en cours...")} />
                    </Dimmer>
                    :
                    <Grid className='modal-content-body' columns={2} style={{ padding: '10px', marginTop: 0, paddingTop: 0 }} >
                        {charts.map(chart => (
                            <Grid.Column style={{ padding: '7px' }}>
                                <Segment style={{ padding: 0 }}>
                                    <h3 style={{ marginTop: '7px', marginBottom: 0, textAlign: 'center' }}>{chart.title}</h3>
                                    <Line options={this.getOptions(chart)} data={this.getData(chart)} />
                                </Segment>
                            </Grid.Column>
                        ))}
                    </Grid>}
            </div>
        );
    }

    componentDidMount = () => {
        const { layer } = this.props;
        this.setState({ isLoading: true });
        const loadingFunctions = {
            'Arbre': TreesService.getTreeCharts,
            'Projet': StatisticsService.getProjectCharts
        };
        loadingFunctions[layer?.[0]?.feature.properties.category || 'Projet'](layer?.[0].feature.id || this.props.project.id).then(response => {
            this.setState({ charts: response || [], isLoading: false });
        });
    }

    getOptions = (chart) => {
        const isMultiAxis = chart.xValues.some(value => value.length > 1);
        return ({
            maintainAspectRatio: true,
            scales: {
                yAxes: [
                    {
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: (!isMultiAxis && chart.yLabels) ? chart.yLabels.length - 1 : undefined, //! undefined important
                            fontColor: 'white',
                            stepSize: !isMultiAxis && chart.yLabels ? 1 : undefined, //! undefined important
                            callback: (value) => (!isMultiAxis && chart.yLabels?.[value]) || value
                        },
                        scaleLabel: { display: isMultiAxis, labelString: i18n.t("Nombre d'éléments"), fontColor: this.props.isDarkTheme ? 'white' : 'black' },
                        gridLines: { color: '#666' }
                    }
                ],
                xAxes: [
                    {
                        ticks: { fontColor: 'white' },
                        scaleLabel: { display: false },
                        gridLines: { color: '#666' }
                    }
                ]
            },
            layout: { padding: { top: 20, right: 20 } },
            legend: {
                display: isMultiAxis,
                reverse: true,
                position: 'right',
                labels: { fontColor: this.props.isDarkTheme ? 'white' : 'black' }
            },
            tooltips: {
                mode: 'label',
                reverse: true,
                callbacks: chart.yLabels && !isMultiAxis ? { label: (tooltipItem) => chart.yLabels[tooltipItem.yLabel] } : {},
            }
        });
    }

    getRandomColor = () => (
        'rgba('
        + Math.floor(Math.random() * 256) + ', '
        + Math.floor(Math.random() * 256) + ', '
        + Math.floor(Math.random() * 256) + ', 1)'
    );

    getColorScale = (property) => {
        switch (property) {
            case 'healthReview': return ['rgb(153,153,255)', 'rgb(50, 50, 50)', 'rgb(255,0,0)', 'rgb(200,0,0)', 'rgb(255,192,0)', 'rgb(255,166,0)', 'rgb(255,255,0)', 'rgb(230,230,0)', 'rgb(146,208,80)', 'rgb(108,168,62)', 'rgb(0,176,80)', 'rgb(0,130,60)'];
            case 'vigor': return ['rgb(153,153,255)', 'rgb(50, 50, 50)', 'rgb(255,0,0)', 'rgb(255,192,0)', 'rgb(0,176,80)'];
            case 'risk': return ['rgb(153,153,255)', 'rgb(0,176,80)', 'rgb(255,192,0)', 'rgb(255,0,0)'];
            case 'ontogenicStage': return ['rgb(153,153,255)', 'rgb(99,190,123)', 'rgb(116,195,126)', 'rgb(133,200,130)', 'rgb(151,206,134)', 'rgb(168,211,137)', 'rgb(185,217,141)', 'rgb(203,222,145)', 'rgb(220,228,148)', 'rgb(237,233,152)', 'rgb(255,239,156)'];
        }
    }

    getData = (chart) => {
        const datasetOptions = {
            borderColor: 'rgb(126, 211, 33)',
            borderWidth: 2,
            pointRadius: 5,
            pointHoverRadius: 5,
            steppedLine: false,
            lineTension: 0,
            pointBackgroundColor: 'rgb(126, 211, 33)',
        };

        const colorScale = this.getColorScale(chart.property);

        return ({
            labels: chart.xLabels,
            datasets: chart.xValues.some(value => value.length > 1) ?
                chart.yLabels.map((label, i) => {
                    const color = colorScale?.[i] || this.getRandomColor();
                    return {
                        ...datasetOptions, label, hidden: !i, backgroundColor: color, fill: 'start',
                        data: chart.xValues.map(value => value[i]),
                        borderColor: tinycolor(color).darken(),
                        pointBackgroundColor: color
                    };
                })
                :
                [{ ...datasetOptions, data: chart.xValues.map(value => value[0]) }]
        });
    }
}

const mapStateToProps = (state) => ({
    layer: state.layer,
    project: state.project,
    isDarkTheme: state.isDarkTheme
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(EvolutionCharts);