import React, { Component } from 'react';
// Composants
import InfoIcon from '../../../Utils/InfoIcon';
import { Form, Select, Grid } from 'semantic-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { isMobile, isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import i18n from '../../../../locales/i18n';
// Ressources
import { faTree } from '@fortawesome/pro-solid-svg-icons';
// Utils
import FormattersUtil from '../../../../utils/FormattersUtil';
import FieldsUtil from '../../../../utils/FieldsUtil';

class Step4 extends Component {
    state = {
        properties: {
            coverTypeId: 0,
            interactionId: [],
            microHabitatId: [],
            plantationTypeId: 0,
        },
        error: {
            hidden: true,
            messages: [],
            plantingDate: false,
            age: false
        }
    }

    render() {
        const requiredFields = this.props.requiredFields.trees;
        const plantationTypes = this.props.plantationTypes.map(x => { return { text: x.label, value: x.id } });
        const coverTypes = this.props.coverTypes.map(x => { return { text: x.label, value: x.id } });
        const interactions = this.props.interactions.map(x => { return { text: x.label, value: x.id } });
        const microHabitats = this.props.microHabitats.map(x => { return { text: x.label, value: x.id } });

        const columnProps = {
            stretched: true,
            computer: 8,
            tablet: 8,
            mobile: 16,
            style: isMobileOnly ? { paddingTop: '4px', paddingBottom: '4px' } : null
        };

        return (
            <Grid id='cat--environment' style={{ margin: '14px 0 0 0', border: 'solid 2px var(--green-100)', borderRadius: '6px', order: FieldsUtil.getCategoryOrder(this.props.orderConfig, 'Arbre', 'Environnement') }}>
                {!isMobileOnly &&
                    <Grid.Row style={{ display: 'flex', padding: '14px 14px 0 14px', color: 'var(--green-100)', fontWeight: 'bold', fontSize: '14pt' }}>
                        <FontAwesomeIcon icon={faTree} style={{ alignSelf: 'center', marginRight: '5px' }} />
                        <span className='no-themed'>{i18n.t("Environnement")}</span>
                    </Grid.Row>}
                {requiredFields.plantationType &&
                    <Grid.Column {...columnProps}>
                        <Form.Field
                            control={Select} placeholder={i18n.t("Sélectionnez un type de plantation")}
                            label={<label>{i18n.t("Type de plantation")} <InfoIcon content={i18n.t("Précise le type de plantation")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                            name='plantationTypeId' options={plantationTypes}
                            value={this.props.properties.plantationTypeId || ''}
                            selectOnBlur={false} clearable
                            onChange={this.props.handleChange}
                        />
                    </Grid.Column>}
                {requiredFields.coverType &&
                    <Grid.Column {...columnProps}>
                        <Form.Field
                            control={Select} placeholder={i18n.t("Sélectionnez un type de couverture au sol")}
                            label={<label>{i18n.t("Type de couverture au sol")} <InfoIcon content={i18n.t("Décrit ce qui recouvre le sol à proximité de l'arbre")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                            name='coverTypeId' options={coverTypes} clearable disabled={this.props.properties.isEmpty}
                            value={!this.props.properties.isEmpty ? (this.props.properties.coverTypeId || '') : ''}
                            selectOnBlur={false} search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                            className='cover-type' error={this.props.error.coverTypeId} onChange={this.props.handleChange}
                        />
                    </Grid.Column>}
                {requiredFields.interactions &&
                    <Grid.Column {...columnProps}>
                        <Form.Field
                            control={Select} placeholder={i18n.t("Sélectionnez une ou plusieurs interactions")}
                            label={<label>{i18n.t("Interactions")} <InfoIcon content={i18n.t("Éléments avec lesquels l'arbre est en contact")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                            name='interactionId' options={interactions} clearable disabled={this.props.properties.isEmpty}
                            value={!this.props.properties.isEmpty ? (this.props.properties.interactionId || []) : []}
                            selectOnBlur={false} multiple selection search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                            onChange={this.props.handleChange}
                        />
                    </Grid.Column>}
                {requiredFields.microHabitats &&
                    <Grid.Column {...columnProps}>
                        <Form.Field
                            control={Select} placeholder={i18n.t("Sélectionnez un ou plusieurs dendro-microhabitats")}
                            label={<label>{i18n.t("Dendro-microhabitats")} :</label>}
                            name='microHabitatId' options={microHabitats} clearable disabled={this.props.properties.isEmpty}
                            value={!this.props.properties.isEmpty ? (this.props.properties.microHabitatId || []) : []}
                            selectOnBlur={false} multiple selection search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                            onChange={this.props.handleChange}
                        />
                    </Grid.Column>}
                {this.props.renderFields(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Environnement'))}
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        coverTypes: state.coverTypes,
        interactions: state.interactions,
        microHabitats: state.microHabitats,
        plantationTypes: state.plantationTypes,
        defaultFieldCategories: state.defaultFieldCategories
    };
};

export default connect(mapStateToProps)(Step4);